(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name navigation.controller:NavigationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('navigation')
    .controller('NavigationCtrl', NavigationCtrl);

  function NavigationCtrl($rootScope, $cookies, PermissionsUtilService, SettingsService, PARAMS) {
    var vm = this;
    $rootScope.username = $cookies.get('currentUsername');
    $rootScope.site = $cookies.get('currentSitename');

    vm.PermissionsUtilService = PermissionsUtilService;
    vm.navigationMenuDisableAutoExpand = SettingsService.get('navigationMenuDisableAutoExpand', false);
    $rootScope.expandSideNavBar = vm.navigationMenuDisableAutoExpand;
    vm.newDesignVersion = PARAMS.designVersion === 2 ? true : false;
    vm.navigation = [
      {
        name: 'dashboard',
        title: 'dashboard.views.dashboard',
        module: 'dashboard',
        icon: '/images/icons/dashboard.svg',
        enabled: true,
        roleNeeded: 'view-dashboard-module',
        permissionNeeded: 'access dashboard section'
      },
      {
        name: 'pos',
        title: 'pos.views.pos',
        module: 'pos',
        icon: '/images/icons/pos.svg',
        enabled: true,
        roleNeeded: 'view-pos-module',
        permissionNeeded: 'access point of sale section'
      },
      {
        name: 'fitness',
        title: 'fitness.views.fitness',
        module: 'fitness',
        icon: '/images/icons/fitness.svg',
        enabled: true,
        roleNeeded: 'view-fitness-module',
        permissionNeeded: 'access fitness section'
      },
      {
        name: 'swimming courses',
        title: 'swimmingpool.views.swimmingpool',
        module: 'swimcourse.calendar',
        icon: '/images/icons/swimmingpool.svg',
        enabled: true,
        roleNeeded: 'view-swimming-course-module',
        permissionNeeded: 'access swim course section'
      },
      {
        name: 'customers',
        title: 'customer.views.customers',
        module: 'customer',
        icon: '/images/icons/customers.svg',
        enabled: true,
        roleNeeded: 'view-customer-module',
        permissionNeeded: 'access customer section'
      },
      {
        name: 'accountancy',
        title: 'accountancy.views.accountancy',
        module: 'accountancy.list.pos',
        icon: '/images/icons/accountancy.svg',
        enabled: true,
        roleNeeded: 'view-accountancy-module',
        permissionNeeded: 'access accountancy section'
      },
      {
        name: 'reports',
        title: 'reports.views.reports',
        module: 'reports',
        icon: '/images/icons/reports.svg',
        enabled: true,
        roleNeeded: 'view-reports-module',
        permissionNeeded: 'access report section'
      },
      {
        name: 'settings',
        title: 'settings.views.settings',
        module: 'settings.overview',
        icon: '/images/icons/settings.svg',
        enabled: true,
        roleNeeded: 'view-settings-module',
        permissionNeeded: 'access setting section'
      }
    ];
  }
}());
