(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:FeaturesCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .controller('FeaturesCtrl', FeaturesCtrl);

  function FeaturesCtrl(
    $localStorage,
    $q,
    $state,
    SettingFactory,
    SettingsService,
    SiteFactory,
    ToastrNotificationService,
    $scope
  ) {
    var vm = this;
    vm.GLOBAL = 'global';
    vm.commitActive = false;
    vm.settings = {};
    vm.sites = {};
    vm.changed = [];
    vm.toRender = {
      sites: {},
      settings: {}
    };
    vm.commitChanges = commitChanges;
    vm.registerChange = registerChange;

    SiteFactory.manageable.customGETLIST('', {
      limit: 99,
      sort: 'label,ASC'
    }).then(function (result) {
      vm.sites = result;
      vm.sites.push({
        id: 'global',
        label: 'Global',
        global: true
      });

      SettingFactory.getList({
        'filter[]': ['system, false'],
        limit: 999
      }).then(function (response) {
        response.forEach(function (setting) {
          if (angular.isUndefined(vm.settings[setting.code])) {
            vm.settings[setting.code] = {
              id: setting.id,
              code: setting.code,
              sites: {
                global: {
                  label: 'Global',
                  global: true,
                  value: null
                }
              }
            };
          }

          if (angular.isDefined(setting.site)) {
            vm.settings[setting.code].sites[setting.site.id] = setting.site;
            vm.settings[setting.code].sites[setting.site.id].value = setting.value;
            vm.settings[setting.code].sites[setting.site.id].resourceId = setting.id;
          } else {
            vm.settings[setting.code].sites[vm.GLOBAL].value = setting.value;
            vm.settings[setting.code].sites[vm.GLOBAL].resourceId = setting.id;
          }
        });
        //alphabetical sort
        vm.settings = Object.keys(vm.settings)
        .sort(function (a, b) {
          a.localeCompare(b);
        })
        .reduce(function (acc, key) {
          acc[key] = vm.settings[key];
          return acc;
        }, {});
      });
    }).then(function () {
      vm.toRender.sites = vm.sites;
      vm.toRender.settings = vm.settings;
    });

    function commitChanges() {
      var promises = [];
      vm.commitActive = true;
      vm.changed.forEach(function (change) {
        var settingObject;
        change = change.split('@');

        if (angular.isUndefined(vm.settings[change[0]].sites[change[1]].resourceId)) {
          //create new setting
          settingObject = {
            code: change[0],
            value: vm.settings[change[0]].sites[change[1]].value,
            system: false
          };

          if (change[1] !== vm.GLOBAL) {
            settingObject.site = change[1];
          }

          promises.push(SettingFactory.post(settingObject).then(function (result) {
            console.log(result);
          }));
        } else if (vm.settings[change[0]].sites[change[1]].value === '') {
          // delete if new value is empty
          promises.push(SettingFactory.one(vm.settings[change[0]].sites[change[1]].resourceId).remove());
        } else {
          //update old one
          promises.push(SettingFactory.one(vm.settings[change[0]].sites[change[1]].resourceId).patch({
            value: vm.settings[change[0]].sites[change[1]].value
          }));
        }
      });

      return $q.all(promises).then(function () {
        vm.commitActive = false;
        ToastrNotificationService.showTranslatedNotification('success', 'app.success', 'settings.commitSuccess', {});

        SettingsService.reloadSettings().then(function () {
          $state.reload();
        });
      }, function () {
        vm.commitActive = false;
      });
    }

    function registerChange(code) {
      if (!vm.changed.includes(code)) {
        vm.changed.push(code);
      }
    }

    //filter function
    (function () {
      // var debFunc = _.debounce(function (search, prop) {
      //   if (search.trim().length > 0) {
      //     search = search.toLowerCase();
      //     if (prop === 'settings') {
      //       vm.toRender.settings = {};
      //       Object.keys(vm.settings).forEach(function (key) {
      //         if (key.toLowerCase().indexOf(search) !== -1) {
      //           vm.toRender.settings[key] = vm.settings[key];
      //         }
      //       });
      //     } else {
      //       vm.toRender[prop] = vm[prop].filter(function (obj) {
      //         return obj.label.toLowerCase().indexOf(search) !== -1 || obj.label === 'Global';
      //       });
      //     }
      //   } else {
      //     vm.toRender[prop] = vm[prop];
      //   }
      //   $scope.$apply();
      // }, 200);
      //
      // ['sites', 'settings'].forEach(function (prop) {
      //   angular.element('input#' + prop)[0].addEventListener('input', function (e) {
      //     var value = e.target.value;
      //     debFunc(value, prop);
      //   });
      // });

      function fltrFunc(search, prop) {
        if (search.trim().length > 0) {
          search = search.toLowerCase();
          if (prop === 'settings') {
            vm.toRender.settings = {};
            Object.keys(vm.settings).forEach(function (key) {
              if (key.toLowerCase().indexOf(search) !== -1) {
                vm.toRender.settings[key] = vm.settings[key];
              }
            });
          } else {
            vm.toRender[prop] = vm[prop].filter(function (obj) {
              return obj.label.toLowerCase().indexOf(search) !== -1 || obj.label === 'Global';
            });
          }
        } else {
          vm.toRender[prop] = vm[prop];
        }
        $scope.$apply();
      }

      angular.element('button#searchButtton')[0].addEventListener('click', function () {
        ['sites', 'settings'].forEach(function (prop) {
          fltrFunc(angular.element('input#' + prop)[0].value, prop);
        });
      });
    }());
  }
}());
