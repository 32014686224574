(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ScheduledReportUserContextFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .factory('ScheduledReportUserContextFactory', ScheduledReportUserContextFactory);

  function ScheduledReportUserContextFactory(Restangular) {
    return Restangular.service('scheduled_reports/user_contexts');
  }
}());
