(function () {
  'use strict';
  /*global moment*/
  /**
   * @ngdoc object
   * @name pos.controller:ShoppingCartCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('ShoppingCartCtrl', ShoppingCartCtrl);

  function ShoppingCartCtrl($scope,
                            $filter,
                            $modal,
                            $state,
                            $rootScope,
                            $q,
                            _,
                            LogService,
                            $timeout,
                            ProductFactory,
                            SaleFactory,
                            SaleStatusFactory,
                            CustomerFactory,
                            UtilService,
                            hwproxy,
                            ToastrNotificationService,
                            poller,
                            ProductUtilService,
                            CurrentUserContextFactory,
                            CurrentPosInstanceFactory,
                            PosSaleService,
                            ContactFactory,
                            SettingsService,
                            hotkeys,
                            Restangular,
                            DiscountFactory) {
    var vm = this,
        detailModalOpened = false,
        modalInstance,
        modalCustomerInstance,
        modalBillItemDetailsInstance,
        modalDiscounts;
    vm.newSaleStarted = false;
    vm.Math = Math;
    vm.billCustomer = null;
    vm.contactDetails = null;
    vm.saleIsDirty = false;
    vm.saleStatuses = {};
    vm.saleSessions = [];
    vm.billItems = [];
    vm.processingSubmit = false;
    vm.billItemsAreDirty = billItemsAreDirty;
    vm.billItemIsDirty = billItemIsDirty;
    vm.saleId = null;
    vm.saveSaleAsDraft = saveSaleAsDraft;
    vm.saveSaleSession = saveSaleSession;
    vm.loadSaleSession = loadSaleSession;
    vm.loadSaleStatuses = loadSaleStatuses;
    vm.addBillCustomer = addBillCustomer;
    vm.showCustomerContactEditModal = showCustomerContactEditModal;
    vm.loadUnfinishedSaleSessions = loadUnfinishedSaleSessions;
    vm.loggedInUserSiteId = CurrentUserContextFactory.getSiteId();

    // these are just the first ids we found for now, so fix this later!
    // vm.currentFacilityId = CurrentUserContextFactory.getUserContextCookies().siteFacilityId;
    // vm.loggedInUserSiteId = CurrentUserContextFactory.getUserContextCookies().siteId;
    // vm.currentPosSessionId = decodeURIComponent($cookies.get('currentPosSessionId'));

    vm.isSaleSaveNeeded = isSaleSaveNeeded;
    vm.showPurchaseDetailModal = showPurchaseDetailModal;
    vm.showCreateCustomerModal = showCreateCustomerModal;
    vm.showCustomerListModal = showCustomerListModal;
    vm.showExternalFormTemplateModal = showExternalFormTemplateModal;
    vm.addItemToBill = addItemToBill;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.returnOfModalCustomerInstance = returnOfModalCustomerInstance;
    vm.returnOfModalBillItemDetailsInstance = returnOfModalBillItemDetailsInstance;
    vm.cancelCurrentSale = cancelCurrentSale;
    vm.saleIsBeingCanceled = false;
    vm.clearBillCustomer = clearBillCustomer;
    vm.removeItemFromBill = removeItemFromBill;
    vm.clearBill = clearBill;
    vm.attemptSalePersist = attemptSalePersist;
    vm.paymentIsNotAllowed = paymentIsNotAllowed;
    vm.hasSaleId = hasSaleId;
    vm.payBill = payBill;
    vm.billTotal = billTotal;
    // Manually create the 3 possible VAT-rates
    vm.billTotalVAT6 = 0;
    vm.billTotalVAT12 = 0;
    vm.billTotalVAT21 = 0;
    vm.billTotalDiscount = 0;
    vm.billNetTotal = 0;
    vm.openCashDrawer = openCashDrawer;
    vm.isNotSubSaleItem = isNotSubSaleItem;
    vm.subSaleItemOfSaleItem = subSaleItemOfSaleItem;
    vm.sumOfDiscounts = sumOfDiscounts;
    vm.ProductUtilService = ProductUtilService;
    vm.itemRequirementsNotMet = false;
    vm.checkItemRequirements = checkItemRequirements;
    vm.loadContactDetails = loadContactDetails;
    vm.checkExternalCartEngine = checkExternalCartEngine;
    vm.importCartFromExternalCartEngine = importCartFromExternalCartEngine;
    vm.enableOpenCashdrawerButton = !SettingsService.get('pos.disableOpenCashdrawerButton', false);
    vm.enableSaveSaleButton = !SettingsService.get('pos.disableSaveSaleButton', false);
    vm.standaloneMode = SettingsService.get('pos.standaloneMode', false);
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.enableSalesLinking = SettingsService.get('pos.enableSalesLinking', false);
    vm.useExternalCart = SettingsService.get('pos.useExternalCartEngine', false);
    vm.cartDiscounts = SettingsService.get('pos.cartDiscounts', false);
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.approvalFlow34 = (SettingsService.get('pos.session.approvalFlowVersion') === '3' || SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.roundingProductCode = SettingsService.get('pos.roundingProductCode', null);
    vm.roundingEnabled = SettingsService.get('pos.roundingEnabled', false);
    vm.linkedSale = null;
    vm.persistingSale = false;
    vm.addLinkedSale = addLinkedSale;
    vm.clearLinkedSale = clearLinkedSale;
    vm.showLinkSaleModal = showLinkSaleModal;
    vm.discounts = discounts;
    initSaleData();
    hotKeysEnable();
    vm.commonDiscountPercentage = Number(SettingsService.get('pos.cartDiscountsDefaultValue', 10));
    vm.hideBirthDateInfo = SettingsService.get('pos.hideBirthDateInfo', false);
    vm.discountOnExclusivePrice = SettingsService.get('pos.discountOnExclusivePrice', false);
    vm.useExternalCartAutoAdd = SettingsService.get('pos.useExternalCartAutoAdd', false);
    vm.discountAddProductEnabled = SettingsService.get('pos.discountAddProductEnabled', false);
    vm.addDiscountProduct = addDiscountProduct;
    vm.discountProductCodeVAT0 = SettingsService.get('pos.discountProductCodeVAT0', false);
    vm.discountProductCodeVAT6 = SettingsService.get('pos.discountProductCodeVAT6', false);
    vm.discountProductCodeVAT21 = SettingsService.get('pos.discountProductCodeVAT21', false);
    vm.discountProductCodes = [vm.discountProductCodeVAT0, vm.discountProductCodeVAT6, vm.discountProductCodeVAT21];
    vm.updateDiscountProduct = updateDiscountProduct;
    vm.deleteDiscountProduct = deleteDiscountProduct;
    vm.cartDiscountInternalComments = null;
    vm.roundingSales = roundingSales;
    vm.discountsInternalCommentsRequiredDisabled = SettingsService.get('pos.discountsInternalCommentsRequiredDisabled', true);
    vm.customerLabel = SettingsService.get('pos.customerLabel', 'klant');
    vm.linkedWithSales = [];

    hwproxy.sendClearCommandToDisplay();

    $scope.$on('pos.loadSaleSession', function (event, sessionId) {
      vm.loadSaleSession(sessionId);
    });

    $scope.$on('pos.setSaleCustomer', function (event, data) {
      vm.billCustomer = {customer: data.customer, contact: data.contact};
      vm.loadContactDetails();
      vm.saleIsDirty = true;
      vm.newSaleStarted = true;
      vm.attemptSalePersist().then(function () {
        if (angular.isDefined(data.product)) {
          $rootScope.$broadcast('addProductToShoppingCartBroadcast', data.product);
        }
      });
    });

    $scope.$on('posGroupHasBeenChosenBroadcast', function () {
      if (vm.useExternalCart && !vm.standaloneMode) {
        vm.checkExternalCartEngine();
      }
    });

    $scope.$on('posCheckExternalCartEmit', function () {
      if (vm.useExternalCart && !vm.standaloneMode) {
        vm.checkExternalCartEngine();
      }
    });

    function openCashDrawer() {
      LogService.log('open cash drawer', 'debug');
      hwproxy.sendCashDrawerOpenCommandToPrinter();
    }

    function initSaleData() {
      vm.loadSaleStatuses();
      // .then(function () {
      //   vm.loadUnfinishedSaleSessions();
      // });
    }

    function loadSaleStatuses() {
      return new Promise(function (resolve) {
        vm.saleStatuses = {};
        // retrieve sale statuses
        SaleStatusFactory
          .getList({
            limit: 99
          })
          .then(function (resultSaleStatuses) {
            if (resultSaleStatuses.length) {
              angular.forEach(resultSaleStatuses, function (resultStatus) {
                vm.saleStatuses[resultStatus.code] = resultStatus;
              });
              resolve();
            } else {
              resolve();
            }
          });
      });
    }

    // first retrieve the ids for sale statuses, then find sales
    // which have status in_progress
    function loadUnfinishedSaleSessions() {
      var inProgressStatusId,
          savedStatusId,
          getParams = {
            limit: 99
          };

      if (CurrentPosInstanceFactory.isInstanceSelected()) {
        // clear statuses and sessions
        vm.saleSessions = [];
        LogService.log('loading unfinished sale sessions', 'debug');
        vm.loadSaleStatuses()
          .then(function () {
            if (vm.saleStatuses.hasOwnProperty('DRAFT') && vm.saleStatuses.hasOwnProperty('IN_PROGRESS')) {
              // retrieve sale which are draft or in_progress
              savedStatusId = vm.saleStatuses.DRAFT.id;
              inProgressStatusId = vm.saleStatuses.IN_PROGRESS.id;

              // get drafts
              getParams['filter[]'] = 'saleStatus.id,' + inProgressStatusId;
              SaleFactory.getList(getParams).then(function (resultSaleSessions) {
                angular.forEach(resultSaleSessions, function (saleSession) {
                  // don't list the current sale in the session list
                  if (saleSession.id !== vm.saleId) {
                    vm.saleSessions.push(saleSession);
                  }
                });
              }).then(function () {
                // get in progress
                getParams['filter[]'] = 'saleStatus.id,' + savedStatusId;
                SaleFactory.getList(getParams).then(function (resultSaleSessions) {
                  angular.forEach(resultSaleSessions, function (saleSession) {
                    // dont list the current sale in the session list
                    if (saleSession.id !== vm.saleId) {
                      vm.saleSessions.push(saleSession);
                    }
                  });
                });
              });
            } else {
              LogService.log('SALE STATUS IDS NOT INITIALIZED', 'debug');
            }
          });
      }
    }

    // function refreshCookies() {
    //   vm.currentFacilityId = CurrentUserContextFactory.getUserContextCookies().siteFacilityId;
    //   vm.loggedInUserSiteId = CurrentUserContextFactory.getUserContextCookies().siteId;
    //   vm.currentPosSessionId = decodeURIComponent($cookies.get('currentPosSessionId'));
    // }

    // broadcast responder for adding a new product to the shopping cart
    // $scope.$on('refreshCookiesBroadcast', function (event) {
    //   LogService.log('refreshing shopping cart cookies', 'debug');
    //   if (!event.defaultPrevented) {
    //     // stop the event
    //     event.preventDefault();
    //     vm.refreshCookies();
    //   }
    // });

    // broadcast responder for adding a new product to the shopping cart
    $scope.$on('addProductToShoppingCartBroadcast', function (event, product) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();

        if (vm.saleId !== null || (vm.saleId === null && vm.newSaleStarted === false)) {
          if (vm.saleId === null) {
            vm.newSaleStarted = true;
          }
          // Add the product to the cart
          if (!vm.saleIsBeingCanceled && !vm.billItemIsDirty(product.id)) {
            vm.addItemToBill(product);
          }
        }
      }
    });

    // broadcast responder for adding a new discount product to the shopping cart
    $scope.$on('addDiscountProductToShoppingCartBroadcast', function (event, saleItemId, discountPercentage, discountAmount, originalBillItem, discountType) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        vm.addDiscountProduct(saleItemId, discountPercentage, discountAmount, originalBillItem, discountType);
      }
    });

    // broadcast responder for updating discount product
    $scope.$on('updateDiscountProductBroadcast', function (event, billItem) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        vm.updateDiscountProduct(billItem);
      }
    });

    // broadcast responder for deleting discount product
    $scope.$on('deleteDiscountProductBroadcast', function (event, billItem) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        vm.deleteDiscountProduct(billItem);
      }
    });

    // emit responder for clearing the shopping cart
    $scope.$on('clearShoppingCartEmit', function (event, data) {
      LogService.log('clearShoppingCartEmit event received');
      console.log(data);
      LogService.log(data, 'debug');
      LogService.logToConsole(data);
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        // Clear the shopping cart after payments
        vm.clearBill();
        vm.newSaleStarted = false;
        vm.cartDiscountInternalComments = null;
      }
    });

    $scope.$on('checkDiffBetweenCartsEmit', function (event, args) {
      if (args.billItems) {
        checkDiffBetweenCarts(args.billItems, args.externalDeffer);
      }
    });

    function showCustomerListModal() {
      UtilService.showModal({
        templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
        controller: 'HwProxyMemberCardDataCtrl',
        controllerAs: 'HwProxyMemberCardDataCtrl',
        size: 'lg',
        resolve: {
          memberCardData: function () {
            return null;
          },
          customer: function () {
            return null;
          },
          customerContact: function () {
            return null;
          }
        }
      }, function () {
        hotKeysEnable();
      }, function () {
        hotKeysEnable();
      });
    }

    function showCreateCustomerModal() {
      if (detailModalOpened) {
        return;
      }

      modalCustomerInstance = $modal.open({
        templateUrl: 'customer/views/customer.pos.modal.add.tpl.html',
        controller: 'CustomerAddPosCtrl',
        controllerAs: 'customerAddPosCtrl',
        resolve: {
          billTotal: function () {
            return vm.billTotal();
          }
        }
      });

      detailModalOpened = true;
      vm.returnOfModalCustomerInstance();
    }

    function showPurchaseDetailModal(billItem) {
      if (detailModalOpened) {
        return;
      }

      // store original bill item object, so we can reset if persisting to backend fails later
      billItem.billItemBackup = angular.copy(billItem);

      LogService.log('purchase detail modal opening', 'debug');
      LogService.log(billItem, 'debug');
      LogService.logToConsole(billItem);
      modalBillItemDetailsInstance = $modal.open({
        templateUrl: 'pos/views/pos.billitem.modal.view.tpl.html',
        controller: 'BillItemCtrl',
        controllerAs: 'billItemCtrl',
        resolve: {
          billItem: function () {
            return billItem;
          }
        }
      });

      detailModalOpened = true;
      vm.returnOfModalBillItemDetailsInstance();
    }

    function showExternalFormTemplateModal(saleItem) {
      var templateId = null;

      if (detailModalOpened) {
        LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> modal already opened', 'debug');
        return;
      }

      if (saleItem.product.code === 'A0003') {
        templateId = '8a74264d-70ca-473a-96e0-68ddac8d47e6';
      }
      if (saleItem.product.code === 'A0005') {
        templateId = 'c283b9f4-22dc-47a6-aaa0-2c02fb315a3c';
      }
      if (saleItem.product.code === 'A0007') {
        templateId = 'cf3d38e6-05ed-46fd-afa2-5a209b01a1b0';
      }

      if (templateId === null) {
        LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> no template id', 'debug');
        return;
      }

      // hijack the dirty mechanism to keep pay button disabled during modal popup
      // this should not cause a problem, since the modal is blocking the UI
      saleItem.dirty = true;

      Restangular.one('form-template').one(templateId).customGET().then(function (templateData) {
        LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> opening modal', 'debug');

        $modal.open({
          templateUrl: 'pos/views/pos.external-form-template.modal.view.tpl.html',
          controller: ['$modalInstance', function ($modalInstance) {
            var saleItemPoller = null,
                formSubmitPromise = null,
                handleSaleItemPollerResponse = null,
                polling = false;

            this.schema = templateData.plain().content;

            this.polling = function () {
              return polling;
            };

            this.onNavigate = function (model, activeStep, isValid, promise) {
              formSubmitPromise = promise;

              if (isValid) {
                formSubmitPromise.resolve();
              } else {
                ToastrNotificationService.showNotification('error', $filter('uconlyfirst')($filter('translate')('form.invalid')));
                formSubmitPromise.reject();
              }
            };

            this.onSubmit = function (modelCopy, isValid, promise) {
              var saleItemId = null,
                  payload = {
                    templateLookupKey: templateData.lookupKey,
                    templateVersion: templateData.version,
                    content: modelCopy
                  };

              formSubmitPromise = promise;

              if (!isValid) {
                ToastrNotificationService.showNotification('error', $filter('uconlyfirst')($filter('translate')('form.invalid')));
                formSubmitPromise.reject();
                return;
              }

              LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> sending form data to external response API', 'debug');
              saleItemId = angular.isUndefined(saleItem.id) ? saleItem.saleItemId : saleItem.id;
              Restangular.one('sale_item').one(saleItemId).one('send_form_to_response_api').customPOST(payload).then(function () {
                // start polling sale item to check for change done by the external response API
                // which calls /sale_item/{saleItemId}/extra on the on the backend
                LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> form sent to external respone API, polling', 'debug');

                polling = true;
                saleItemPoller = poller.get(SaleFactory.one(vm.saleId).one('items').one(saleItemId), {
                  action: 'get',
                  delay: 1000
                });
                saleItemPoller.promise.then(null, null, handleSaleItemPollerResponse);
              }, function (errorResponse) {
                LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> external response API error: ', 'debug');
                LogService.log(errorResponse, 'debug');
                LogService.logToConsole(errorResponse);

                formSubmitPromise.reject();
                return;
              });
            };

            this.cancel = function () {
              LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> cancel button pressed, closing modal', 'debug');

              vm.removeItemFromBill(saleItem);

              polling = false;
              // stop and remove poller
              if (saleItemPoller !== null) {
                saleItemPoller.stop();
                saleItemPoller.remove();
              }
              // close modal
              if (formSubmitPromise !== null) {
                formSubmitPromise.resolve();
              }
              detailModalOpened = false;
              $modalInstance.close('close');
            };

            handleSaleItemPollerResponse = function (freshSaleItem) {
              var productInclPrice = null;

              if (angular.isDefined(freshSaleItem.parameters.extra) &&
                  !angular.equals(saleItem.parametersObject, freshSaleItem.parameters)) {
                LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> saleItem patch found, closing modal', 'debug');
                polling = false;
                // update sale item parameters so frontend does not rewrite them if there are any patch requests
                saleItem.parametersObject = freshSaleItem.parameters;
                // update sale item price if it is present in provided extra parameters
                if (angular.isDefined(freshSaleItem.parameters.extra.price)) {
                  saleItem.productExclPrice = freshSaleItem.price;
                  saleItem.amount = freshSaleItem.quantity;
                  productInclPrice = ProductUtilService.calculateInclPrice(saleItem.productExclPrice, saleItem.vat);
                  saleItem.productPrice = productInclPrice.inclPrice;
                  saleItem.vatPrice = productInclPrice.vatPrice;
                }
                saleItem.dirty = false;
                // stop and remove poller
                saleItemPoller.stop();
                saleItemPoller.remove();
                // close modal
                formSubmitPromise.resolve();
                detailModalOpened = false;
                $modalInstance.close('close');
              }
            };
          }],
          controllerAs: 'formTemplateRenderCtrl'
        });
      }, function () {
        LogService.log('ShoppingCartCtrl::showExternalFormTemplateModal() -> no template found for templateId' + templateId, 'debug');
        saleItem.dirty = true;
        vm.removeItemFromBill(saleItem);
        detailModalOpened = false;
      });

      detailModalOpened = true;
    }

    function returnOfModalBillItemDetailsInstance() {
      modalBillItemDetailsInstance.result
        .then(changedBillItem, function (returnValue) {
          LogService.log('reason of closing: ' + returnValue, 'debug');
          LogService.logToConsole(returnValue);
          vm.checkItemRequirements();
          detailModalOpened = false;
        });
    }

    function returnOfModalCustomerInstance() {
      modalCustomerInstance.result.then(function (returnValue) {
        vm.billCustomer = returnValue;
        vm.loadContactDetails();
        if ((vm.saleId === null && vm.newSaleStarted === false)) {
          vm.newSaleStarted = true;
          vm.saleIsDirty = true;
          vm.attemptSalePersist();
          detailModalOpened = false;
        } else {
          LogService.log('check if sale exist', 'debug');
          if (vm.saleId === null && vm.newSaleStarted === true) {
            LogService.log('Cannot add client', 'debug');
            detailModalOpened = false;
            // Build something is to wait and add client if done
          } else {
            vm.saleIsDirty = true;
            vm.attemptSalePersist();
            detailModalOpened = false;
          }
        }
      }, function () {
        detailModalOpened = false;
      });
    }

    function returnOfModalInstance() {
      vm.processingSubmit = false;
      modalInstance.result.then(function () {
        detailModalOpened = false;
      }, function () {
        detailModalOpened = false;
      });
    }

    // load a previously stored sale session
    function loadSaleSession(saleSessionId) {
      var billItemObject,
          i,
          component,
          productSubscription;
      LogService.log('loading sale session', 'debug');

      // persist current sale as draft
      if (vm.billItems.length > 0) {
        LogService.log('marking current sale as dirty', 'debug');
        vm.saleIsDirty = true;
      }

      vm.attemptSalePersist(true).then(function () {
        // clear the bill, but don't delete it from the backend
        clearBill().then(function () {
          // retrieve the sale from the backend
          SaleFactory.one(saleSessionId).get().then(function (sale) {
            vm.saleReference = sale.reference;
            vm.saleId = sale.id;

            // load customer information
            if (sale.hasOwnProperty('customer') || sale.hasOwnProperty('customerContact')) {
              if (vm.billCustomer === null) {
                vm.billCustomer = {};
              }
              vm.billCustomer.customer = sale.hasOwnProperty('customer') ? sale.customer : null;

              // contact needs to be retrieved seperately
              if (sale.hasOwnProperty('customerContact')) {
                CustomerFactory.one(sale.customer.id).get().then(function (customer) {
                  if (customer.customerContacts.length > 0) {
                    for (i = 0; i < customer.customerContacts.length; ++i) {
                      if (customer.customerContacts[i].id === sale.customerContact.id) {
                        vm.billCustomer.contact = customer.customerContacts[i];
                        break;
                      }
                    }
                  }
                });
              }
            }

            LogService.log('sale retrieved, loading bill items', 'debug');
            vm.billItems = [];
            // !! this will be exposed by default later, so this call will become uneccessary
            angular.forEach(sale.saleItems, function (saleItem) {
              SaleFactory.one(saleSessionId).one('items').one(saleItem.id).get()
                .then(function (saleItemDetail) {
                  LogService.log('saleItemParams' + saleItemDetail, 'debug');
                  LogService.logToConsole(saleItemDetail);
                  billItemObject = {
                    saleItemId: saleItemDetail.id,
                    dirty: false,
                    amount: saleItemDetail.quantity,
                    product: saleItemDetail.product,
                    // keep whole parameters object, so parameters which we don't edit here don't get lost
                    parametersObject: saleItemDetail.parameters || {},
                    discountObject: saleItemDetail.discount || null,
                    discount: saleItemDetail.parameters.discount || false,
                    discountType: saleItemDetail.parameters.discountType || null,
                    discountAmount: saleItemDetail.parameters.discountAmount || null,
                    discountPercentage: saleItemDetail.parameters.discountPercentage || null,
                    productExclPrice: angular.isNumber(saleItemDetail.price) ? saleItemDetail.price : null,
                    productDescription: saleItemDetail.comments ? saleItemDetail.comments : '',
                    internalComments: saleItemDetail.internalComments ? saleItemDetail.internalComments : '',
                    descriptionRequired: saleItemDetail.parameters.descriptionRequired || false,
                    isGroupProduct: saleItemDetail.parameters.isGroupProduct ? true : false,
                    isSubSaleItem: saleItemDetail.parameters.parentSaleItem ? true : false,
                    parentSaleItem: saleItemDetail.parameters.parentSaleItem ? saleItemDetail.parameters.parentSaleItem : null,
                    invoiceNumber: saleItemDetail.invoiceNumber ? saleItemDetail.invoiceNumber : null,
                    gfpCustomer: saleItemDetail.gfpCustomer ? saleItemDetail.gfpCustomer : null,
                    invoiceDate: saleItemDetail.invoiceDate ? new Date(saleItemDetail.invoiceDate) : null,
                    firstName: saleItemDetail.firstName ? saleItemDetail.firstName : null,
                    lastName: saleItemDetail.lastName ? saleItemDetail.lastName : null
                  };
                  billItemObject.startDate = angular.isUndefined(saleItemDetail.parameters.startDate) ? null : new Date(saleItemDetail.parameters.startDate);
                  productSubscription = billItemObject.product.productComponents.filter(function (pc) {
                    return pc.type === 'subscription_contract';
                  });
                  if (productSubscription) {
                    billItemObject.product.productSubscription = productSubscription;
                    billItemObject.subscriptionDuration = saleItemDetail.parameters.subscriptionDuration;
                    billItemObject.subscriptionPaymentInterval = saleItemDetail.parameters.subscriptionPaymentInterval;
                    billItemObject.subscriptionProRata = saleItemDetail.parameters.subscriptionProRata;
                    billItemObject.subscriptionDate = new Date(saleItemDetail.parameters.startCollectionDate);
                  }
                  // fill in the price details
                  for (i = 0; i < saleItemDetail.product.productComponents.length; ++i) {
                    component = saleItemDetail.product.productComponents[i];
                    if (component.type === 'price') {
                      if (billItemObject.productExclPrice === null) {
                        billItemObject.productExclPrice = Math.round(component.price * 100) / 100;
                      }
                      billItemObject.product.price = component;
                      billItemObject.vat = component.vatRate.percentage;
                      billItemObject.vatPrice = ProductUtilService.calculateInclPrice(billItemObject.productExclPrice, billItemObject.vat).vatPrice;
                      billItemObject.productPrice = ProductUtilService.calculateInclPrice(billItemObject.productExclPrice, billItemObject.vat).inclPrice;
                    }
                  }
                  vm.billItems.push(billItemObject);
                });
            });
          });
        });
      });
      // .then(function () {
      //   loadUnfinishedSaleSessions();
      // });
    }

    function checkExternalCartEngine() {
      var loadingModal;
      LogService.log('ShoppingCartCtrl::checkExternalCartEngine() -> Loading active cart data: ', 'debug');
      return Restangular.one('active-cart').get({pointOfSaleSessionId: CurrentPosInstanceFactory.getPosSessionId()}).then(function (cartData) {
        LogService.log(cartData, 'debug');
        LogService.logToConsole(cartData);

        if (UtilService.isNotEmpty(cartData) && UtilService.isNotEmpty(cartData.products)) {
          LogService.log('ShoppingCartCtrl::checkExternalCartEngine() -> Invoking import shopping cart confirmation modal.', 'debug');
          if (vm.useExternalCartAutoAdd && cartData.productsExistInCurrentSite) {
            loadingModal = $modal.open({
              template: '<div class="modal-body">\n' +
                '  {{ "app.loading" | translate | uconlyfirst }}\n' +
                '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
                '          <span class="icons">\n' +
                '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
                '          </span>\n' +
                '      </span>\n' +
                '</div>',
              size: 'sm'
            });
            return vm.importCartFromExternalCartEngine(cartData).then(function () {
              loadingModal.close();
            });
          }
          UtilService.showModal({
            templateUrl: 'pos/views/pos.shopping-cart-import.modal.tpl.html',
            controller: ['$modalInstance', function ($modalInstance) {
              this.cartData = cartData;
              this.modal = $modalInstance;
              this.automaticApprovalFlow = vm.automaticApprovalFlow;
              this.approvalFlow34 = vm.approvalFlow34;
              this.import = function () {
                return vm.importCartFromExternalCartEngine(cartData, $modalInstance);
              };
              this.cancel = function () {
                return vm.cancelCurrentSale().then(function () {
                  $modalInstance.close('import cancelled');
                });
              };
              this.closePOS = function () {
                $state.go('dashboard');
                $rootScope.$broadcast('showUserContextSelectionModal');
              };
              this.closable = true;
            }],
            controllerAs: 'shoppingCartImportCtrl'
          },
          function (returnValue) {
            LogService.log('ShoppingCartCtrl::checkExternalCartEngine() -> Shopping cart confirmation modal closed with return value: ' + returnValue, 'debug');
          });
        }
      }, function () {
        $state.go('dashboard');
      });
    }

    function importCartFromExternalCartEngine(cartData, $modalInstance) {
      var defer = $q.defer(), queue = $q.defer();
      if (UtilService.isEmpty(cartData) || UtilService.isEmpty(cartData.products)) {
        defer.reject();
        if ($modalInstance) {
          $modalInstance.close('no products in cart');
        }
      }

      LogService.log('ShoppingCartCtrl::importCartFromExternalCartEngine() -> clearing cart', 'debug');

      cancelCurrentSale(false, true).then(function () {
        LogService.log('ShoppingCartCtrl::importCartFromExternalCartEngine() -> creating new sale', 'debug');
        // we want to save an empty sale first, because calling vm.addItemToBill multiple times would create multiple empty sales on backend
        vm.saleIsDirty = true;
        vm.attemptSalePersist().then(function () {
          var itemsInCartExist = vm.billItems.length > 0 ? true : false;
          queue.resolve();
          queue = queue.promise;
          Promise.all(cartData.products.map(function (cartProduct) {
            LogService.log('ShoppingCartCtrl::importCartFromExternalCartEngine() -> loading product by code: ' + cartProduct.articleCode, 'debug');
            queue = queue.then(function () {
              return persistProductFromExCartAndAddtoBill(cartProduct, itemsInCartExist);
            });
          })).then(function () {
            // it would be better to resolve after all bill items are added, but there is no callback option right now
            defer.resolve();
            if ($modalInstance) {
              $modalInstance.close('imported');
            }
          });
        }, function (errorMsg) {
          defer.reject();
          if ($modalInstance) {
            $modalInstance.close(errorMsg);
          }
        });
      }, function (errorMsg) {
        defer.reject();
        if ($modalInstance) {
          $modalInstance.close(errorMsg);
        }
      });

      return defer.promise;
    }

    function persistProductFromExCartAndAddtoBill(cartProduct, itemsInCartExist) {
      return new Promise(function (resolve, reject) {
        ProductFactory.getOneByCode(cartProduct.articleCode).then(function (product) {
          var productData = ProductUtilService.getProductDetails(product);
          // store external cart product ID
          productData.externalApiId = cartProduct.id;
          // store external cart product description
          if (angular.isDefined(cartProduct.description) && cartProduct.description) {
            productData.externalApiDescription = cartProduct.description;
          }
          // update sale item unit price if different than on the external cart product
          if (angular.isDefined(cartProduct.unitPrice) && angular.isDefined(productData.price) &&
            cartProduct.unitPrice !== productData.price.price
          ) {
            productData.price.price = cartProduct.unitPrice;
          }

          if (vm.approvalFlow34 && angular.isDefined(cartProduct.details.invoiceNumber)) {
            productData.invoiceNumber = cartProduct.details.invoiceNumber;
          }

          if (vm.approvalFlow34 && angular.isDefined(cartProduct.details.GFPCustomer)) {
            productData.gfpCustomer = cartProduct.details.GFPCustomer;
          }

          if (vm.approvalFlow34 && angular.isDefined(cartProduct.details.invoiceDate)) {
            productData.invoiceDate = cartProduct.details.invoiceDate;
          }

          if (vm.approvalFlow34 && angular.isDefined(cartProduct.identification) && angular.isDefined(cartProduct.identification.firstName)) {
            productData.firstName = cartProduct.identification.firstName;
          }

          if (vm.approvalFlow34 && angular.isDefined(cartProduct.identification) && angular.isDefined(cartProduct.identification.lastName)) {
            productData.lastName = cartProduct.identification.lastName;
          }

          if (vm.discountAddProductEnabled && angular.isDefined(cartProduct.accountancy) && angular.isDefined(cartProduct.accountancy.discountArticleCode)) {
            productData.discountArticleCode = cartProduct.accountancy.discountArticleCode;
          }

          vm.addItemToBill(productData, cartProduct.quantity, true, itemsInCartExist);
          resolve();
        }, function (errorMsg) {
          // not found products should be removed from the external cart
          LogService.log('ShoppingCartCtrl::importCartFromExternalCartEngine() -> could not load product: ' + errorMsg, 'debug');
          LogService.logToConsole(errorMsg);
          reject(errorMsg);
        });
      });
    }

    function cancelCurrentSale(discard, noExternalCartClear) {
      var defer = $q.defer(),
          externalCartClearRequest = null;

      vm.saleIsBeingCanceled = true;
      if (vm.useExternalCart && !vm.standaloneMode && (angular.isUndefined(noExternalCartClear) || noExternalCartClear !== true)) {
        LogService.log('ShoppingCartCtrl::cancelCurrentSale() -> Removing products from external cart', 'debug');

        vm.saleIsDirty = true;
        angular.forEach(vm.billItems, function (billItem) {
          billItem.dirty = true;
        });
        externalCartClearRequest = Restangular.one('active-cart').one('clear');
        if (vm.saleId !== null) {
          externalCartClearRequest = externalCartClearRequest.one(vm.saleId);
        }
        externalCartClearRequest.customGET().then(function (cartData) {
          LogService.log(cartData, 'debug');
          LogService.logToConsole(cartData);
          clearBill(discard).then(function () {
            defer.resolve();
            vm.saleIsBeingCanceled = false;
          });
        }, function () {
          vm.saleIsDirty = false;
          angular.forEach(vm.billItems, function (billItem) {
            billItem.dirty = false;
          });
          defer.reject();
          vm.saleIsBeingCanceled = false;
        });
      } else {
        clearBill(discard).then(function () {
          defer.resolve();
          vm.saleIsBeingCanceled = false;
        });
      }

      return defer.promise;
    }

    function clearBill(discard) {
      return $q(function (resolve, reject) {
        LogService.log('clearing sale from frontend', 'debug');
        if (angular.isDefined(discard) && discard === true && vm.saleId !== null) {
          LogService.log('sale status is DISCARDED', 'debug');
          vm.newSaleStarted = false;
          SaleFactory.one(vm.saleId).patch({
            saleStatus: vm.saleStatuses.DISCARDED.id,
            completedAt: null,
            linkedWithSales: []
          })
          .then(function () {
            vm.clearBillCustomer();
            vm.clearLinkedSale();
            vm.saleId = null;
            vm.billItems = [];
            vm.saleReference = '';
            resolve();
          }, function () {
            reject();
          });
        } else {
          vm.clearBillCustomer();
          vm.clearLinkedSale();
          vm.saleId = null;
          vm.billItems = [];
          vm.saleReference = '';
          resolve();
        }
      });
    }

    function clearBillCustomer(clearFromBackend) {
      hwproxy.sendClearCommandToDisplay();

      // Clear the billCustomer
      vm.billCustomer = null;
      vm.contactDetails = null;
      LogService.log('clear bill customer', 'debug');
      if (angular.isDefined(clearFromBackend) && clearFromBackend) {
        LogService.log('clear customer from backend flagged, persisting sale', 'debug');
        vm.saleIsDirty = true;
        vm.attemptSalePersist();
      }
    }

    function addBillCustomer(billCustomer) {
      if (!vm.billCustomer) {
        LogService.log('Shopping Cart: Adding customer to bill => customer: ' + billCustomer, 'debug');
        LogService.logToConsole(billCustomer);
        vm.billCustomer = billCustomer;
        if (vm.saleId === null && vm.newSaleStarted === false) {
          vm.newSaleStarted = true;
        }
        vm.saleIsDirty = true;
        vm.attemptSalePersist();

        return true;
      }

      LogService.log('Shopping Cart: Skipped adding customer to bill due to bill customer already being set', 'debug');

      return false;
    }

    function showCustomerContactEditModal() {
      ContactFactory.one(vm.billCustomer.contact.contact.id)
        .get()
        .then(function (contactDetails) {
          vm.billCustomer.contact.completeContact = contactDetails;
        })
        .then(function () {
          UtilService.showModal({
            templateUrl: 'contact/views/contact.modal.addedit.tpl.html',
            controller: 'ContactAddEditCtrl',
            controllerAs: 'contactAddEditCtrl',
            size: 'lg',
            resolve: {
              customer: function () {
                return vm.billCustomer.customer;
              },
              customerContact: function () {
                return vm.billCustomer.contact;
              }
            }
          }, function () {
            vm.loadContactDetails();
          });
        });
    }

    function removeItemFromBill(billItem) {
      var billItemCopy = angular.copy(billItem),
          itemId = vm.billItems[vm.billItems.indexOf(billItem)].saleItemId,
          subProducts = [],
          billItemWithDiscount,
          discountItem;

      // when delete item and item has discount, then lock remove button for discount item
      if (vm.discountAddProductEnabled && billItem.discountSaleItemId) {
        discountItem = vm.billItems.filter(function (bi) {
          return bi.saleItemId === billItem.discountSaleItemId;
        });

        if (angular.isDefined(discountItem[0])) {
          discountItem[0].lockRemoveButton = true;
        }
      }
      // flag bill item and it's sub-items as dirty to disable the pay button
      billItem.dirty = true;
      PosSaleService.saleIsCompleted(vm.saleId, true).then(function (isCompleted) {
        if (!isCompleted) {
          if (billItemCopy.isGroupProduct) {
            angular.forEach(vm.billItems, function (subItem) {
              if (subItem.parentSaleItem === billItem.saleItemId) {
                subItem.dirty = true;
              }
            });
          }

          // Show negative amounts on display
          billItemCopy.amount *= -1;
          if (angular.isUndefined(billItem.isSubSaleItem) ||
            (angular.isDefined(billItem.isSubSaleItem) && !billItem.isSubSaleItem)) {
            hwproxy.sendSaleItemInfoToDisplay(billItemCopy);
          }

          //if we remove discount item then remove also patch the item to which current item is a discount
          if (vm.discountAddProductEnabled && vm.discountProductCodes.indexOf(billItem.product.code) !== -1) {
            billItemWithDiscount = vm.billItems.filter(function (bi) {
              return angular.isDefined(bi.discountSaleItemId) && bi.discountSaleItemId === billItem.saleItemId;
            });
            if (angular.isDefined(billItemWithDiscount[0])) {
              SaleFactory.one(vm.saleId).one('items').one(billItemWithDiscount[0].saleItemId).patch({discountSaleItem: null});
            }
          }

          // remove the sale item in the backend
          SaleFactory.one(vm.saleId)
            .one('items').one(itemId)
            .remove()
            .then(function () {
              //if we remove discount item then remove also data from item to which current item is a discount
              if (vm.discountAddProductEnabled && vm.discountProductCodes.indexOf(billItem.product.code) !== -1) {
                if (angular.isDefined(billItemWithDiscount[0])) {
                  billItemWithDiscount[0].discount = false;
                  billItemWithDiscount[0].discountPercentage = null;
                  billItemWithDiscount[0].discountAmount = null;
                  billItemWithDiscount[0].discountType = null;
                  billItemWithDiscount[0].discountObject = null;
                  billItemWithDiscount[0].discountSaleItemId = null;
                }
              }
              // when delete item and item has discount, then delete discount item also
              if (vm.discountAddProductEnabled && billItem.discountSaleItemId) {
                discountItem = vm.billItems.filter(function (bi) {
                  return bi.saleItemId === billItem.discountSaleItemId;
                });

                if (angular.isDefined(discountItem[0])) {
                  discountItem[0].dirty = true;
                  SaleFactory.one(vm.saleId)
                    .one('items').one(discountItem[0].saleItemId)
                    .remove()
                    .then(function () {
                      // Remove 1 item of the billItems array
                      vm.billItems.splice(vm.billItems.indexOf(discountItem[0]), 1);
                    }, function () {
                      // remove dirty flags from bill item
                      discountItem[0].dirty = false;
                    });
                }
              }

              // Remove 1 item of the billItems array
              vm.billItems.splice(vm.billItems.indexOf(billItem), 1);

              // Get item-index of the billItem that has to be deleted
              if (billItemCopy.isGroupProduct) {
                subProducts = vm.billItems.filter(function (bi) {
                  return bi.parentSaleItem === billItemCopy.saleItemId;
                });
                angular.forEach(subProducts, function (subProduct) {
                  vm.removeItemFromBill(subProduct);
                });
              }
            }, function () {
              // remove dirty flags from bill item and it's sub-items on error
              billItem.dirty = false;
              if (billItemCopy.isGroupProduct) {
                angular.forEach(vm.billItems, function (subItem) {
                  if (subItem.parentSaleItem === billItem.saleItemId) {
                    subItem.dirty = false;
                  }
                });
              }
            });
        } else {
          vm.clearBill();
          LogService.log('Sale is already completed', 'debug');
        }
      }, function () {
        LogService.log('something went wrong', 'debug');
      });
    }

    // checks wether a sale objects needs to be created in the backend
    function isSaleSaveNeeded() {
      return ((vm.saleId === null && vm.billItems.length > 0) || vm.saleIsDirty);
    }

    // create a sale entity in the backend if it does not exist yet
    function attemptSalePersist(saveAsDraft) {
      var saleRestObject, returnObject = $q(function () {});

      LogService.log('is started', 'debug');

      return PosSaleService.saleIsCompleted(vm.saleId, true).then(function (isCompleted) {
        if (!isCompleted) {
          LogService.log('is Not completed', 'debug');
          returnObject = $q(function (resolve) {
            // vm.refreshCookies();
            if (CurrentPosInstanceFactory.isPosSessionCookieSet() && CurrentPosInstanceFactory.getPosSessionId() !== 'undefined') {
              if (vm.isSaleSaveNeeded()) {
                LogService.log('sale persist: save is needed', 'debug');
                if (vm.saleId === null) {
                  LogService.log('sale id is null, posting', 'debug');
                  saleRestObject = getSaleRestObject(true, saveAsDraft);
                  LogService.log(saleRestObject, 'debug');
                  LogService.logToConsole(saleRestObject);
                  SaleFactory.post(saleRestObject)
                    .then(function (resultObject) {
                      vm.newSaleStarted = false;
                      vm.saleReference = resultObject.reference;
                      vm.saleId = resultObject.id;
                      vm.saleIsDirty = false;
                      LogService.log('created sale ' + vm.saleId, 'debug');
                      resolve('Sale created!');
                    }, function (errorMsg) {
                      console.error(errorMsg);
                      resolve('Sale error');
                    });
                } else {
                  LogService.log('sale id not null, patching', 'debug');
                  saleRestObject = getSaleRestObject(false, saveAsDraft);
                  LogService.log(saleRestObject, 'debug');
                  LogService.logToConsole(saleRestObject);
                  SaleFactory.one(vm.saleId).patch(saleRestObject)
                    .then(function () {
                      vm.saleIsDirty = false;
                      LogService.log('patched sale ' + vm.saleId, 'debug');
                      resolve('Sale patched!');
                    }, function (errorMsg) {
                      console.error(errorMsg);
                      resolve('Sale error');
                    });
                }
              } else {
                resolve('Sale already exists!');
              }
            } else {
              LogService.log('no current session id!', 'debug');
              $rootScope.$broadcast('checkPosSessionBroadCast');
            }
          });
        } else {
          vm.clearBill();
          LogService.log('Sale is already completed', 'debug');
        }
        vm.persistingSale = false;
        return returnObject;
      }, function () {
        LogService.log('something went wrong', 'debug');
        return returnObject;
      });
    }

    // get a sale rest object
    function getSaleRestObject(includeSiteId, saveAsDraft) {
      var i,
          j,
          cc,
          cd,
          hasBillingContact = false,
          hasVat = false,
          restObject;

      restObject = {
        facility: CurrentUserContextFactory.getSiteFacilityId(),
        customer: vm.billCustomer === null ? null : vm.billCustomer.customer.id,
        customerContact: vm.billCustomer === null || !vm.billCustomer.contact ? null : vm.billCustomer.contact.id,
        pointOfSaleSession: CurrentPosInstanceFactory.getPosSessionId()
      };
      // determine wether invoice was requested
      if (vm.billCustomer !== null) {
        for (i = 0; i < vm.billCustomer.customer.customerContacts.length; ++i) {
          cc = vm.billCustomer.customer.customerContacts[i];
          if (angular.isDefined(cc.customerContactType) && cc.customerContactType.code === 'BILLING') {
            hasBillingContact = true;
            LogService.log('the contact of getSaleRestObject:' + cc, 'debug');
            for (j = 0; j < cc.contact.contactData.length; ++j) {
              cd = cc.contact.contactData[j];
              if (cd.contactDataType.code === 'VAT_NUMBER') {
                hasVat = true;
                break;
              }
            }
            break;
          }
        }
      }

      if (vm.linkedWithSales.length > 0) {
        restObject.linkedWithSales = vm.linkedWithSales.map(function (linkSale) {
          return linkSale.id;
        });
      }

      if (hasBillingContact && hasVat) {
        LogService.log('has billing contact with vat number, invoice requested -> true', 'debug');
        restObject.invoiceRequested = true;
      } else {
        restObject.invoiceRequested = false;
      }

      if (angular.isDefined(saveAsDraft) && saveAsDraft) {
        LogService.log('constructing sale rest object as draft', 'debug');
        restObject.saleStatus = vm.saleStatuses.DRAFT.id;
      } else {
        LogService.log('constructing sale rest object as in progress', 'debug');
        restObject.saleStatus = vm.saleStatuses.IN_PROGRESS.id;
      }

      if (includeSiteId) {
        restObject.site = CurrentUserContextFactory.getSiteId();
      }

      return restObject;
    }

    function saveSaleAsDraft() {
      vm.saleIsDirty = true;
      saveSaleSession(true);
      vm.billItems = [];
      vm.saleId = null;
      vm.clearBillCustomer(false);
      vm.saleReference = '';
      // vm.loadUnfinishedSaleSessions();
    }

    // write sale item changes to the backend
    function saveSaleSession(saveAsDraft) {
      var saleItemRestObject,
          lastDirtyBillItem = null,
          returnObject = null,
          saleItemPostRequest = null,
          discountItem,
          billItemWhichHasDiscount;
      PosSaleService.saleIsCompleted(vm.saleId, true).then(function (isCompleted) {
        if (!isCompleted) {
          if (CurrentPosInstanceFactory.isPosSessionCookieSet()) {
            returnObject = $q(function (resolve) {
              if (vm.isSaleSaveNeeded()) {
                vm.attemptSalePersist(saveAsDraft).then(function () {
                  return vm.saveSaleSession(saveAsDraft);
                });
              } else {
                angular.forEach(vm.billItems, function (bi) {
                  if (bi.dirty && !bi.isBeingSaved) {
                    bi.isBeingSaved = true;
                    lastDirtyBillItem = bi;
                    saleItemRestObject = getSaleItemRestObjectFromBillItem(bi);
                    if (bi.saleItemId === null) {
                      saleItemPostRequest = SaleFactory.one(vm.saleId).one('items');
                      // turn off external cart persist when doing an import from it
                      if (bi.externalApiId) {
                        saleItemPostRequest = saleItemPostRequest.one('1');
                      }
                      saleItemPostRequest.customPOST(saleItemRestObject)
                        .then(function (resultSaleItem) {
                          bi.saleItemId = resultSaleItem.id;
                          // update parameters, they can be changed by backend
                          bi.parametersObject = resultSaleItem.parameters;
                          bi.dirty = false;
                          bi.isBeingSaved = false;
                          // Open bill Item
                          if (bi.needsPopup) {
                            vm.showPurchaseDetailModal(bi);
                            bi.needsPopup = false;
                            bi.hasPoppedUp = true;
                          }
                          // temporarily hard-coded form engine modal for demo purposes
                          /*
                          if (!bi.externalApiId && SettingsService.get('pos.useExternalFormTemplateEngine', false) && !vm.standaloneMode) {
                            vm.showExternalFormTemplateModal(bi);
                          }
                          */
                          // if we persist product and it's a discount product, then specify that item has discount
                          if (vm.discountAddProductEnabled && angular.isDefined(bi.discountArticleCode)) {
                            billItemWhichHasDiscount = vm.billItems.filter(function (billItem) {
                              return billItem.product.code === bi.discountArticleCode;
                            });

                            if (angular.isDefined(billItemWhichHasDiscount[0])) {
                              billItemWhichHasDiscount[0].discountObject = true;
                              if (billItemWhichHasDiscount[0].saleItemId) {
                                billItemWhichHasDiscount[0].discountSaleItemId = bi.saleItemId;
                                //also patch existing sale item, because when we load products from external cart then we don't have a relation
                                SaleFactory.one(vm.saleId).one('items').one(billItemWhichHasDiscount[0].saleItemId).patch({discountSaleItem: bi.saleItemId});
                              }
                            }
                          }

                          // create Sub Sale Items
                          if (bi.isGroupProduct && bi.isNewGroupProduct) {
                            bi.isNewGroupProduct = false;
                            ProductFactory.one(bi.product.uuid).get()
                              .then(function (groupProduct) {
                                groupProduct.data.subProductsComponent = groupProduct.data.productComponents.filter(function (pc) {
                                  return pc.type === 'products';
                                })[0];
                                addSubSaleItems(bi, groupProduct.data.subProductsComponent.products);
                              });
                          }
                          LogService.log('posted sale item ' + bi.saleItemId, 'debug');
                        }, function () {
                          if (bi.isGroupProduct) {
                            vm.billItems.forEach(function (bis) {
                              if (bis.parentSaleItem === bi.saleItemId) {
                                vm.billItems.splice(vm.billItems.indexOf(bis), 1);
                              }
                            });
                          }

                          vm.billItems.splice(vm.billItems.indexOf(bi), 1);
                        });
                    } else {
                      SaleFactory.one(vm.saleId).one('items').one(bi.saleItemId).patch(saleItemRestObject)
                        .then(function () {
                          if (bi.needRemoveDiscount) {
                            DiscountFactory.one(bi.needRemoveDiscount).remove().then(function () {
                              LogService.log('Remove discount with id: ' + bi.needRemoveDiscount);
                            }, function () {
                              LogService.log(' Can\'t remove discount with id: ' + bi.needRemoveDiscount);
                            });
                          }
                          // recalculate existing discount if need
                          if (vm.discountAddProductEnabled && angular.isDefined(bi.discountSaleItemId) && (angular.isDefined(bi.quantityChanged) && bi.quantityChanged)) {
                            discountItem = vm.billItems.filter(function (billItem) {
                              return billItem.saleItemId === bi.discountSaleItemId;
                            });
                            if (angular.isDefined(discountItem[0])) {
                              discountItem[0].dirty = true;
                              SaleFactory.one(vm.saleId).one('addDiscountSaleItem').one('1').customPOST({discount: true, saleItemId: bi.saleItemId})
                                .then(function (resultSaleItem) {
                                  bi.quantityChanged = false;
                                  bi.discountObject = true;
                                  discountItem[0].productExclPrice = resultSaleItem.price;
                                  discountItem[0].productPrice = ProductUtilService.calculateInclPrice(discountItem[0].productExclPrice, discountItem[0].vat).inclPrice;
                                  discountItem[0].vatPrice = ProductUtilService.calculateInclPrice(discountItem[0].productExclPrice, discountItem[0].vat).vatPrice;
                                  discountItem[0].amount = resultSaleItem.quantity;
                                  hwproxy.sendSaleItemInfoToDisplay(discountItem[0]);
                                  discountItem[0].dirty = false;
                                });
                            }
                          }
                          bi.dirty = false;
                          bi.isBeingSaved = false;
                          bi.billItemBackup = undefined;
                          LogService.log('patched sale item ' + bi.saleItemId, 'debug');
                        }, function () {
                          if (angular.isDefined(bi.billItemBackup)) {
                            angular.copy(bi.billItemBackup, bi);
                          }
                          bi.billItemBackup = undefined;
                        });
                    }
                  }
                  resolve('sale items save run completed');
                });

                if (lastDirtyBillItem) {
                  if (angular.isUndefined(lastDirtyBillItem.isSubSaleItem) || (angular.isDefined(lastDirtyBillItem.isSubSaleItem) && !lastDirtyBillItem.isSubSaleItem)) {
                    hwproxy.sendSaleItemInfoToDisplay(lastDirtyBillItem);
                  }
                }
              }
            }).then(function () {
              if (angular.isDefined(saveAsDraft) && saveAsDraft) {
                // clear the bill without purging data from the backend
                vm.clearBill();
              }
              // mark the sale as being saved
              vm.saleIsDirty = false;
            });
          } else {
            returnObject = null;
            LogService.log('no current sessions!', 'debug');
          }
        } else {
          LogService.log('sale was already completed', 'debug');
          vm.clearBill();
        }
        return returnObject;
      }, function () {
        LogService.log('something went wrong with getting the sale', 'debug');
        return null;
      });
    }

    function addSubSaleItems(bi, products) {
      var counter = 0,
          subItemStartDate = null,
          subItemNeedsPopup = false,
          productSubscription;
      angular.forEach(products, function (subProduct) {
        counter++;
        ProductFactory.one(subProduct.uuid).get()
          .then(function (resultSubProduct) {
            // billItemObject.subProducts.push(resultSubProduct.data);
            resultSubProduct.data.price = resultSubProduct.data.productComponents.filter(function (pc) {
              return pc.type === 'price';
            })[0];
            resultSubProduct.data.vat = resultSubProduct.data.price.vatRate.percentage;
            resultSubProduct.data.price.vatPrice = ProductUtilService.calculateInclPrice(resultSubProduct.data.price.price, resultSubProduct.data.vat).vatPrice;
            resultSubProduct.data.price.productPrice = ProductUtilService.calculateInclPrice(resultSubProduct.data.price.price, resultSubProduct.data.vat).inclPrice;
            resultSubProduct.data.isSubSaleItem = true;
            resultSubProduct.data.parentSaleItem = bi.saleItemId;
            // filter the components for the productSubscription item
            productSubscription = resultSubProduct.data.productComponents.filter(function (pc) {
              return pc.type === 'subscription_contract';
            });
            if (!subItemStartDate) {
              subItemStartDate = resultSubProduct.data.productComponents.filter(function (pc) {
                return pc.type === 'start_date';
              }).length > 0 || bi.startDate ? new Date() : null;
            }
            if (!subItemNeedsPopup) {
              subItemNeedsPopup = resultSubProduct.data.inputRequired && resultSubProduct.data.inputRequired === true;
            }
            if (productSubscription && productSubscription.length > 0) {
              resultSubProduct.data.productSubscription = productSubscription;
            }
            addNewBill(resultSubProduct.data);
            notifyFinishedGettingSubProducts();
          });
      });

      function notifyFinishedGettingSubProducts() {
        counter--;
        if (counter === 0) {
          bi.startDate = subItemStartDate;
          if (!bi.hasPoppedUp && subItemNeedsPopup) {
            vm.showPurchaseDetailModal(bi);
          }
          bi.dirty = true;
          vm.saveSaleSession();
        }
      }
    }

    // get a sale item rest object
    function getSaleItemRestObjectFromBillItem(billItem) {
      var restObject;
      // add price overriding, discount refs later
      restObject = {
        product: billItem.product.id,
        quantity: billItem.amount,
        price: billItem.productExclPrice,
        comments: billItem.productDescription,
        discount: billItem.discountObject ? billItem.discountObject.id : null,
        internalComments: billItem.internalComments,
        invoiceNumber: billItem.invoiceNumber,
        gfpCustomer: billItem.gfpCustomer,
        invoiceDate: billItem.invoiceDate,
        firstName: billItem.firstName,
        lastName: billItem.lastName
      };

      restObject.parameters = billItem.parametersObject ? billItem.parametersObject : {};
      restObject.parameters.discount = billItem.discount;
      restObject.parameters.discountAmount = billItem.discountAmount;
      restObject.parameters.discountPercentage = billItem.discountPercentage;
      restObject.parameters.discountType = billItem.discountType;
      restObject.parameters.descriptionRequired = billItem.descriptionRequired;
      if (billItem.product.productSubscription) {
        restObject.parameters.subscriptionDuration = billItem.subscriptionDuration;
        restObject.parameters.subscriptionPaymentInterval = billItem.subscriptionPaymentInterval;
        restObject.parameters.subscriptionProRata = billItem.subscriptionProRata;
        restObject.parameters.startCollectionDate = moment(billItem.subscriptionDate).format().substr(0, 10);
      }
      if (billItem.isGroupProduct) {
        restObject.parameters.isGroupProduct = true;
      } else {
        restObject.parameters.isGroupProduct = false;
      }
      if (billItem.parentSaleItem) {
        restObject.parameters.parentSaleItem = billItem.parentSaleItem;
      }
      /* comments: billItem.productDescription */
      if (billItem.startDate !== null) {
        restObject.parameters.startDate = billItem.startDate.toISOString().substr(0, 10);
      }
      if (billItem.externalApiId) {
        restObject.parameters.externalApiId = billItem.externalApiId;
      }

      return restObject;
    }

    function addItemToBill(product, amount, loadedFromCart, itemsInCartExist) {
      var counter = 0, productExists = false, subProducts, existedBillItem;
      LogService.log('Product:' + product, 'debug');
      LogService.logToConsole(product);
      if (product.grouped) {
        for (counter; counter < vm.billItems.length; counter++) {
          if (vm.billItems[counter].product.id === product.id) {
            vm.billItems[counter].amount++;
            vm.billItems[counter].dirty = true;
            productExists = true;
            terminateAddBill();
            if (vm.billItems[counter].isGroupProduct) {
              // Update subProducts
              /*eslint-disable no-loop-func*/
              subProducts = vm.billItems.filter(function (bi) {
                return bi.parentSaleItem === vm.billItems[counter].saleItemId;
              });
              angular.forEach(subProducts, function (subProduct) {
                subProduct.amount++;
                subProduct.dirty = true;
                terminateAddBill();
              });
              /*eslint-enable no-loop-func*/
            }
            break;
          }
          if (counter === vm.billItems.length - 1 && !productExists) {
            addNewBill(product, amount);
            break;
          }
        }
        if (vm.billItems.length === 0) {
          addNewBill(product, amount);
        }
      } else {
        existedBillItem = vm.billItems.filter(function (billItem) {
          return billItem.product.id === product.id;
        });

        if (angular.isDefined(existedBillItem[0]) &&
          (angular.isDefined(loadedFromCart) && loadedFromCart) &&
          (angular.isDefined(itemsInCartExist) && itemsInCartExist) &&
          existedBillItem[0].amount !== amount
        ) {
          existedBillItem[0].amount = amount;
          existedBillItem[0].dirty = true;
          terminateAddBill();
        } else {
          addNewBill(product, amount);
        }
      }
    }

    function addNewBill(saleItemProduct, amount) {
      var billItemObject = {
            saleItemId: null,
            dirty: true,
            amount: angular.isUndefined(amount) ? 1 : amount,
            product: saleItemProduct,
            discount: false,
            discountAmount: null,
            discountPercentage: null,
            discountType: null,
            productPrice: saleItemProduct.productSubProducts ? 0 : ProductUtilService.calculateInclPrice(saleItemProduct.price.price, saleItemProduct.price.vatRate.percentage).inclPrice,
            vat: saleItemProduct.price.vatRate.percentage,
            productExclPrice: saleItemProduct.productSubProducts ? 0 : saleItemProduct.price.price,
            vatPrice: saleItemProduct.productSubProducts ? 0 : ProductUtilService.calculateInclPrice(saleItemProduct.price.price, saleItemProduct.price.vatRate.percentage).vatPrice,
            productDescription: angular.isDefined(saleItemProduct.externalApiDescription) ? saleItemProduct.externalApiDescription : '',
            descriptionRequired: UtilService.isNotEmpty(saleItemProduct.inputRequired) && saleItemProduct.inputRequired === true,
            startDate: $filter('filter')(saleItemProduct.productComponents, function (pc) {
              return pc.type === 'start_date';
            }).length > 0 ? new Date() : null,
            needsPopup: UtilService.isNotEmpty(saleItemProduct.inputRequired) && saleItemProduct.inputRequired === true,
            isGroupProduct: saleItemProduct.productSubProducts ? true : false,
            isNewGroupProduct: saleItemProduct.productSubProducts ? true : false,
            isSubSaleItem: saleItemProduct.isSubSaleItem,
            parentSaleItem: saleItemProduct.isSubSaleItem ? saleItemProduct.parentSaleItem : null,
            externalApiId: angular.isDefined(saleItemProduct.externalApiId) ? saleItemProduct.externalApiId : false,
            invoiceNumber: angular.isDefined(saleItemProduct.invoiceNumber) ? saleItemProduct.invoiceNumber : null,
            gfpCustomer: angular.isDefined(saleItemProduct.gfpCustomer) ? saleItemProduct.gfpCustomer : null,
            invoiceDate: angular.isDefined(saleItemProduct.invoiceDate) ? new Date(saleItemProduct.invoiceDate) : null,
            firstName: angular.isDefined(saleItemProduct.firstName) ? saleItemProduct.firstName : null,
            lastName: angular.isDefined(saleItemProduct.lastName) ? saleItemProduct.lastName : null
          },
          billItemWithDiscount;

      // define the discountArticleCode property only for discount items
      if (angular.isDefined(saleItemProduct.discountArticleCode)) {
        billItemObject.discountArticleCode = saleItemProduct.discountArticleCode;
        billItemWithDiscount = vm.billItems.filter(function (billItem) {
          return billItem.product.code === billItemObject.discountArticleCode;
        });

        if (angular.isDefined(billItemWithDiscount[0])) {
          // insert discount item after the item for which current item is the discount
          vm.billItems.splice(vm.billItems.indexOf(billItemWithDiscount[0]) + 1, 0, billItemObject);
          vm.billItems.join();
        } else {
          vm.billItems.push(billItemObject);
        }
      } else {
        // create custom object for bill
        // Discount is set to 0 => this should be dependent on Customer/Contact/product
        vm.billItems.push(billItemObject);
      }

      if (saleItemProduct.productSubProducts) {
        vm.billItems.productSubProducts = saleItemProduct.productSubProducts;
      }

      if (saleItemProduct.productSubscription && saleItemProduct.productSubscription.length > 0) {
        billItemObject.subscriptionDuration = saleItemProduct.productSubscription[0].duration;
        billItemObject.subscriptionPaymentInterval = saleItemProduct.productSubscription[0].paymentInterval;
        billItemObject.subscriptionProRata = saleItemProduct.productSubscription[0].proRata;
        billItemObject.subscriptionDate = ProductUtilService.calculateSubscriptionDate(billItemObject);
        billItemObject.monthlyPrice = ProductUtilService.calculateDomMonthlyPrice(billItemObject, billItemObject.productExclPrice);
        billItemObject.productExclPrice = ProductUtilService.calculateProRataPrice(billItemObject, billItemObject.productExclPrice);
        billItemObject.vatPrice = ProductUtilService.calculateInclPrice(billItemObject.productExclPrice, billItemObject.vat).vatPrice;
        billItemObject.productPrice = ProductUtilService.calculateInclPrice(billItemObject.productExclPrice, billItemObject.vat).inclPrice;
      }
      terminateAddBill();
    }

    function terminateAddBill() {
      $timeout(function () {
        // scroll bill items to bottom
        angular.element('.pos__bill__items').scrollTo(0, angular.element('.pos__bill__items table').height());
        vm.checkItemRequirements();
      });
      vm.saveSaleSession();
    }

    function payBill() {
      var productsWhichRequireContact = [],
          productsWhichRequireLinkedSale,
          defer = $q.defer();

      vm.processingSubmit = true;
      $rootScope.$emit('PayBillEvent');
      checkDiffBetweenCarts(vm.billItems, defer).then(function (out) {
        if (angular.isUndefined(out) || !out.needReload) {
          PosSaleService.saleIsCompleted(vm.saleId, true).then(function (isCompleted) {
            if (!isCompleted) {
              LogService.log('check bill items for contactRequired', 'debug');
              productsWhichRequireContact = $filter('filter')(vm.billItems, function (bi) {
                return bi.product.contactRequired && bi.product.contactRequired === true;
              });
              // with alternate customer form, make customer contact required also for negative sales
              // But not needed for AGSO, when approvalFlowVersion = 3
              if ((productsWhichRequireContact.length > 0 || (vm.useAlternateCustomerForm && vm.billTotal() < 0 && !vm.approvalFlow34)) && !vm.billCustomer) {
                ToastrNotificationService.showNotification(
                  'error',
                  $filter('uconlyfirst')($filter('translate')('app.sale-needs-contact')));
                defer.resolve();
                vm.processingSubmit = false;
                return;
              }

              if (vm.billTotal() < 0 && vm.enableSalesLinking && vm.linkedWithSales.length === 0) {
                LogService.log('check bill items for linkedSaleRequired', 'debug');
                productsWhichRequireLinkedSale = $filter('filter')(vm.billItems, function (bi) {
                  return bi.product.linkedSaleRequired && bi.product.linkedSaleRequired === true;
                });
                if (productsWhichRequireLinkedSale.length > 0) {
                  ToastrNotificationService.showNotification(
                    'error',
                    $filter('uconlyfirst')($filter('translate')('app.sale-needs-linked-sale')));
                  defer.resolve();
                  return;
                }
              }

              if (detailModalOpened) {
                defer.resolve();
                return;
              }
              // this finishes a 0 amount sale
              // see also pos-payment-controller.js (around r. 160) how the payment modal behaves in this case
              if (vm.billTotal() === 0 && angular.isDefined(vm.saleStatuses.COMPLETED)) {
                SaleFactory.one(vm.saleId).patch({
                  saleStatus: vm.saleStatuses.COMPLETED.id,
                  completedAt: (new Date()).toISOString()
                });
              }

              modalInstance = $modal.open({
                templateUrl: 'pos/views/pos.payment.modal.view.tpl.html',
                controller: 'PosPaymentCtrl',
                controllerAs: 'posPaymentCtrl',
                backdrop: 'static',
                keyboard: false,
                scope: $scope,
                size: 'lg',
                resolve: {
                  billTotal: function () {
                    return vm.billTotal();
                  },
                  saleId: function () {
                    return vm.saleId;
                  },
                  billItems: function () {
                    return vm.billItems;
                  },
                  billCustomer: function () {
                    return vm.billCustomer;
                  }
                }
              });

              detailModalOpened = true;
              vm.returnOfModalInstance();
              defer.resolve();
            } else {
              vm.clearBill();
              defer.resolve();
            }
          }, function () {
            defer.resolve();
            LogService.log('something went wrong', 'debug');
          });
        }
      }, function () {
        defer.resolve();
      });

      return defer.promise;
    }

    function checkDiffBetweenCarts(billItems, externalDeffer) {
      var defer = externalDeffer || $q.defer();
      if (vm.useExternalCart && !vm.standaloneMode) {
        PosSaleService.diffBetweenCarts(billItems).then(function (cartData) {
          if (cartData && cartData.products.length && !(cartData.products.length === 1 && isRoundingProduct(cartData.products[0]))) {
            // this promise will return externalDeffer. so on emit() or broadcast() you can wait unitll externalDeffer will
            // do some actions
            showModalOnDiffBetweenCarts(cartData, externalDeffer);
          } else {
            defer.resolve();
          }
        });
      } else {
        defer.resolve();
      }
      return defer.promise;
    }

    function isRoundingProduct(product) {
      return product.articleCode === vm.roundingProductCode;
    }

    function showModalOnDiffBetweenCarts(cartData, externalDeffer) {
      var existedBillItem;
      UtilService.showModal({
        templateUrl: 'pos/views/pos.shopping-cart-import.modal.tpl.html',
        controller: ['$modalInstance', function ($modalInstance) {
          this.cartData = cartData;
          this.modal = $modalInstance;
          this.automaticApprovalFlow = vm.automaticApprovalFlow;
          this.itemWithDiffQuantityExist = false;
          //check if we have the same product in shopping cart with different quantity
          angular.forEach(cartData.products, function (product) {
            existedBillItem = vm.billItems.filter(function (billItem) {
              return billItem.product.code === product.articleCode;
            });
            if (angular.isDefined(existedBillItem[0]) && existedBillItem[0].amount !== product.quantity) {
              this.itemWithDiffQuantityExist = true;
            }
          }, this);

          this.import = function () {
            var itemsInCartExist = vm.billItems.length > 0 ? true : false;
            return Promise.all(cartData.products.map(function (item) {
              return persistProductFromExCartAndAddtoBill(item, itemsInCartExist);
            })).then(function () {
              $modalInstance.close({needReload: true});
            });
          };
          this.cancel = function () {
            return Promise.all(cartData.products.map(function (item) {
              if (angular.isDefined(existedBillItem[0]) && this.itemWithDiffQuantityExist) {
                return Restangular.one('active-cart').one('clear').customGET().then(function (dataFromExternalCart) {
                  LogService.log(dataFromExternalCart, 'debug');
                  vm.clearBill();
                });
              }
              return Restangular.one('sales', item.saleId).one('external_item', item.id).remove();
            }, this)).then(function () {
              $modalInstance.close({needReload: true});
            });
          };
          this.cancelCorruptedSale = function () {
            return Promise.all(
              [cancelCurrentSale(true)]
            ).then(function () {
              $modalInstance.close({needReload: true});
            });
          };
          this.extraDialogue = true;
          this.isNoProductsInCartData = cartData.products.length === 0;
          this.foundProductsTitle = this.itemWithDiffQuantityExist ? 'app.extra_product_with_diff_quantity' : 'app.extra_products_found_long';
          this.dialogTitle = this.isNoProductsInCartData ? 'app.sale_is_corrupt' : 'app.extra_products_found';
          this.dialogDescription = this.isNoProductsInCartData ? 'app.sale_is_corrupt_long' : this.foundProductsTitle;
          this.closable = false;
        }],
        controllerAs: 'shoppingCartImportCtrl'
      },
      function (returnValue) {
        if (externalDeffer) {
          externalDeffer.resolve(returnValue);
        }
        LogService.log('ShoppingCartCtrl::checkExternalCartEngine() -> Shopping cart confirmation modal closed with return value: ' + returnValue, 'debug');
      });
    }

    function roundingSales() {
      var defer = $q.defer(), roundItem, totalBill = vm.billTotal(), diff = 0, roundNum = 0;
      roundNum = Math.round(totalBill / 0.05) * 0.05;
      diff = (totalBill - roundNum) * 100;
      if (vm.roundingEnabled && vm.roundingProductCode && Math.round(diff)) {
        SaleFactory.one(vm.saleId).one('round').get().then(function (sale) {
          roundItem = sale.saleItems.filter(function (saleItem) {
            return saleItem.product.code === vm.roundingProductCode;
          });
          defer.resolve(angular.isDefined(roundItem[0]) && (roundItem[0].price) ? roundItem[0].quantity * roundItem[0].price : 0);
        });
      } else {
        defer.resolve(0);
      }
      return defer.promise;
    }

    // README if you change something in this method, change also SalesService.php -> calculateSaleItemTotal() accordingly
    function billTotal(roundingPrice) {
      var totalWithoutDiscount = 0,
          totalWithDiscount = 0,
          unitPrice = 0,
          activeUnitPrice = 0,
          quantity = 0,
          itemSubtotalAmount = 0,
          itemVatAmount = 0,
          itemTotalAmount = 0,
          itemSubtotalDiscountAmount = 0,
          itemTotalDiscountAmount = 0,
          projectedDiscountPercentage = 0;

      vm.billTotalVAT6 = 0;
      vm.billTotalVAT12 = 0;
      vm.billTotalVAT21 = 0;
      vm.billTotalDiscount = 0;
      vm.billNetTotal = 0;

      angular.forEach(vm.billItems, function (billItem) {
        unitPrice = billItem.productExclPrice;
        activeUnitPrice = unitPrice;
        quantity = billItem.amount;

        // calculate VAT
        itemVatAmount = (activeUnitPrice * billItem.vat) / 100;

        // apply discount
        if (billItem.discount && !vm.discountAddProductEnabled) {
          switch (billItem.discountType.code) {
            case 'AMOUNT':
              projectedDiscountPercentage = (billItem.discountAmount / (activeUnitPrice + itemVatAmount)) * 100;
              activeUnitPrice -= (activeUnitPrice * projectedDiscountPercentage) / 100;

              if (activeUnitPrice < 0) {
                activeUnitPrice = 0.00;
              }
              // not change vat amount, see #47740
              if (!vm.discountOnExclusivePrice) {
                itemVatAmount -= (itemVatAmount * projectedDiscountPercentage) / 100;
              }

              if (itemVatAmount < 0) {
                itemVatAmount = 0;
              }
              break;

            case 'PERCENTAGE':
              activeUnitPrice -= (activeUnitPrice * billItem.discountPercentage) / 100;

              if (activeUnitPrice < 0) {
                activeUnitPrice = 0.00;
              }
              // not change vat amount, see #47740
              if (!vm.discountOnExclusivePrice) {
                itemVatAmount -= (itemVatAmount * billItem.discountPercentage) / 100;
              }

              if (itemVatAmount < 0) {
                itemVatAmount = 0;
              }
              break;

            default:
              break;
          }
        }

        // Calculate totals
        itemVatAmount = ProductUtilService.roundPrice(itemVatAmount) * quantity;

        itemSubtotalAmount = ProductUtilService.roundPrice(activeUnitPrice) * quantity;
        itemTotalAmount = itemVatAmount + itemSubtotalAmount;
        itemSubtotalDiscountAmount = (unitPrice - activeUnitPrice) * quantity;
        itemTotalDiscountAmount = itemSubtotalDiscountAmount + ((itemSubtotalDiscountAmount * billItem.vat) / 100);
        // see #47740
        if (vm.discountOnExclusivePrice) {
          itemTotalDiscountAmount = itemSubtotalDiscountAmount;
        }
        totalWithDiscount += ProductUtilService.roundPrice(itemTotalAmount);
        totalWithoutDiscount += ProductUtilService.roundPrice(itemTotalAmount) + ProductUtilService.roundPrice(itemTotalDiscountAmount);

        if (billItem.vat === 6) {
          vm.billTotalVAT6 += (quantity * ProductUtilService.roundPrice(billItem.vatPrice));
        }
        if (billItem.vat === 12) {
          vm.billTotalVAT12 += (quantity * ProductUtilService.roundPrice(billItem.vatPrice));
        }
        if (billItem.vat === 21) {
          vm.billTotalVAT21 += (quantity * ProductUtilService.roundPrice(billItem.vatPrice));
        }
        vm.billNetTotal += (itemTotalAmount + itemTotalDiscountAmount - (quantity * ProductUtilService.roundPrice(billItem.vatPrice)));
        vm.billTotalDiscount += itemTotalDiscountAmount;
      });

      vm.billTotalWithoutDiscount = totalWithoutDiscount;
      return ProductUtilService.roundPrice(totalWithDiscount) + ((roundingPrice) ? roundingPrice : 0);
    }

    function isNotSubSaleItem(object) {
      return object.parentSaleItem ? false : true;
    }

    function subSaleItemOfSaleItem(object) {
      return object.subSaleItem.parentSaleItem === object.billItem.saleItemId;
    }

    function sumOfDiscounts(item) {
      var discountTotal = 0,
          subItems = vm.billItems.filter(function (bi) {
            return bi.parentSaleItem === item.saleItemId;
          });
      angular.forEach(subItems, function (si) {
        discountTotal += ProductUtilService.calculateDiscountWithPercentage(si.productPrice, si.amount, si.discountPercentage);
      });
      return discountTotal;
    }

    function checkItemRequirements() {
      var i,
          currentItem,
          foundEmptyRequiredField = false;

      LogService.log('ShoppingCartCtrl::checkItemRequirements() -> Checking if all required fields are filled in.', 'debug');
      for (i = 0; i < vm.billItems.length; ++i) {
        currentItem = vm.billItems[i];

        if (UtilService.isNotEmpty(currentItem.descriptionRequired) && currentItem.descriptionRequired === true && UtilService.isEmpty(currentItem.productDescription)) {
          foundEmptyRequiredField = true;
          break;
        }
      }
      LogService.log('ShoppingCartCtrl::checkItemRequirements() -> All fields filled in:' + !foundEmptyRequiredField, 'debug');
      vm.itemRequirementsNotMet = foundEmptyRequiredField;
    }

    function billItemsAreDirty() {
      var dirty = false;

      angular.forEach(vm.billItems, function (billItem) {
        if (billItem.dirty === true) {
          dirty = true;
        }
      });

      return dirty;
    }

    function billItemIsDirty(productId) {
      var dirty = false;

      angular.forEach(vm.billItems, function (billItem) {
        if (billItem.product.id === productId && billItem.dirty === true) {
          dirty = true;
        }
      });

      return dirty;
    }

    function paymentIsNotAllowed() {
      return vm.billItems.length <= 0 || vm.saleIsDirty || vm.billItemsAreDirty() || vm.itemRequirementsNotMet || !vm.hasSaleId();
    }

    function hasSaleId() {
      return vm.saleId !== null;
    }

    function loadContactDetails() {
      ContactFactory.one(vm.billCustomer.contact.contact.id).get().then(function (contactDetails) {
        vm.contactDetails = contactDetails;

        angular.forEach(contactDetails.contactData, function (contactData) {
          switch (contactData.contactDataType.code) {
            case 'BIRTH_DATE':
              vm.contactDetails.age = moment().diff(moment(contactData.value), 'years');
              break;
            case 'BANK_ACCOUNT_NUMBER':
              vm.billCustomer.contact.completeContact.contactData.push(contactData);
              break;
            default:
              break;
          }
        });
      });
    }

    function showLinkSaleModal() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.link-sale.modal.html',
        controller: 'LinkSaleCtrl',
        controllerAs: 'linkSaleCtrl',
        size: 'lg',
        resolve: {}
      }, function (sale) {
        vm.addLinkedSale(sale);
      });
    }

    function addLinkedSale(sale) {
      vm.linkedWithSales.push(sale);

      if (vm.saleId === null && vm.newSaleStarted === false) {
        vm.newSaleStarted = true;
      }

      vm.saleIsDirty = true;
      vm.attemptSalePersist();

      return true;
    }

    function clearLinkedSale(clearFromBackend, sale) {
      if (angular.isDefined(sale) && sale) {
        vm.linkedWithSales.splice(vm.linkedWithSales.indexOf(sale), 1);
      } else {
        vm.linkedWithSales = [];
      }
      if (angular.isDefined(clearFromBackend) && clearFromBackend) {
        LogService.log('clear customer from backend flagged, persisting sale', 'debug');
        vm.saleIsDirty = true;
        vm.attemptSalePersist();
      }
    }

    function discounts() {
      modalDiscounts = $modal.open({
        templateUrl: 'pos/views/pos.billitem-discounts.modal.view.tpl.html',
        controller: ['$modalInstance', function ($modalInstance) {
          this.discountsInternalCommentsRequiredDisabled = vm.discountsInternalCommentsRequiredDisabled;
          this.discountPercentage = vm.commonDiscountPercentage === 0 ? null : vm.commonDiscountPercentage;
          this.internalComments = vm.cartDiscountInternalComments ? vm.cartDiscountInternalComments : null;
          this.cancel = function () {
            $modalInstance.close(null);
          };
          this.close = function () {
            $modalInstance.dismiss('cancel');
          };
          this.submit = function () {
            $modalInstance.close({discountPercentage: this.discountPercentage, internalComments: this.internalComments});
          };
        }],
        controllerAs: 'DiscountFormCtrl'
      });
      modalDiscounts.result.then(function (res) {
        vm.commonDiscountPercentage = res.discountPercentage;
        vm.cartDiscountInternalComments = res.internalComments;
        if (res) {
          angular.forEach(vm.billItems, function (billItem) {
            if ((angular.isUndefined(billItem.discountObject) || billItem.discountObject === null) && billItem.product.cartDiscountAllowed) {
              billItem.discount = (res !== null);
              billItem.discountType = DiscountFactory.getPercentageDiscountType();
              billItem.discountPercentage = res.discountPercentage;
              billItem.dirty = true;
              billItem.discountObject = true;
              billItem.internalComments = res.internalComments;
              vm.addDiscountProduct(billItem.saleItemId, res.discountPercentage, null, billItem, billItem.discountType);
            }
          });
        }
      });
    }

    function hotKeysEnable() {
      hotkeys.bindTo($scope)
      .add({
        combo: 'enter',
        description: 'Checkout',
        callback: function () {
          if (!vm.paymentIsNotAllowed() && vm.payBill()) {
            vm.payBill();
          }
        }
      });
    }

    function changedBillItem(returnedBillItem) {
      var subProducts;
      LogService.log('detected bill item change', 'debug');
      detailModalOpened = false;
      vm.checkItemRequirements();
      vm.saveSaleSession();
      if (returnedBillItem.isGroupProduct) {
        // Update subProducts
        subProducts = vm.billItems.filter(function (bi) {
          return bi.parentSaleItem === returnedBillItem.saleItemId;
        });
        angular.forEach(subProducts, function (subProduct) {
          subProduct.amount = returnedBillItem.amount;
          subProduct.dirty = true;
          if (subProduct.startDate) {
            subProduct.startDate = returnedBillItem.startDate;
            if (subProduct.subscriptionDate) {
              // Calculate ProRata
              subProduct.subscriptionDate = ProductUtilService.calculateSubscriptionDate(subProduct);
              subProduct.productExclPrice = ProductUtilService.calculateProRataPrice(subProduct, subProduct.product.price.price);
              subProduct.vatPrice = ProductUtilService.calculateInclPrice(subProduct.productExclPrice, subProduct.vat).vatPrice;
              subProduct.productPrice = ProductUtilService.calculateInclPrice(subProduct.productExclPrice, subProduct.vat).inclPrice;
              subProduct.monthlyPrice = ProductUtilService.calculateDomMonthlyPrice(subProduct, subProduct.product.price.price);
            }
          }
          subProduct.discount = returnedBillItem.discount;
          if (returnedBillItem.discount === true) {
            subProduct.discountType = returnedBillItem.discountType;
            subProduct.discountAmount = returnedBillItem.discountAmount;
            subProduct.discountPercentage = returnedBillItem.discountPercentage;
            subProduct.discountObject = returnedBillItem.discountObject;
          } else {
            subProduct.discountType = null;
            subProduct.discountPercentage = null;
            subProduct.discountAmount = null;
            subProduct.discountObject = null;
          }
        });

        vm.saveSaleSession();
      }
    }

    function createBillItemObject(saleItemProduct, amount, saleItemId, saleItemPrice) {
      var price = vm.discountAddProductEnabled && saleItemPrice ? saleItemPrice : saleItemProduct.price.price;

      return {
        saleItemId: angular.isUndefined(saleItemId) ? null : saleItemId,
        dirty: true,
        amount: angular.isUndefined(amount) ? 1 : amount,
        product: saleItemProduct,
        discount: false,
        discountAmount: null,
        discountPercentage: null,
        discountType: null,
        productPrice: saleItemProduct.productSubProducts ? 0 : ProductUtilService.calculateInclPrice(price, saleItemProduct.price.vatRate.percentage).inclPrice,
        vat: saleItemProduct.price.vatRate.percentage,
        productExclPrice: saleItemProduct.productSubProducts ? 0 : price,
        vatPrice: saleItemProduct.productSubProducts ? 0 : ProductUtilService.calculateInclPrice(price, saleItemProduct.price.vatRate.percentage).vatPrice,
        productDescription: angular.isDefined(saleItemProduct.externalApiDescription) ? saleItemProduct.externalApiDescription : '',
        descriptionRequired: UtilService.isNotEmpty(saleItemProduct.inputRequired) && saleItemProduct.inputRequired === true,
        startDate: $filter('filter')(saleItemProduct.productComponents, function (pc) {
          return pc.type === 'start_date';
        }).length > 0 ? new Date() : null,
        needsPopup: UtilService.isNotEmpty(saleItemProduct.inputRequired) && saleItemProduct.inputRequired === true,
        isGroupProduct: saleItemProduct.productSubProducts ? true : false,
        isNewGroupProduct: saleItemProduct.productSubProducts ? true : false,
        isSubSaleItem: saleItemProduct.isSubSaleItem,
        parentSaleItem: saleItemProduct.isSubSaleItem ? saleItemProduct.parentSaleItem : null,
        externalApiId: angular.isDefined(saleItemProduct.externalApiId) ? saleItemProduct.externalApiId : false,
        invoiceNumber: angular.isDefined(saleItemProduct.invoiceNumber) ? saleItemProduct.invoiceNumber : null,
        gfpCustomer: angular.isDefined(saleItemProduct.gfpCustomer) ? saleItemProduct.gfpCustomer : null,
        invoiceDate: angular.isDefined(saleItemProduct.invoiceDate) ? new Date(saleItemProduct.invoiceDate) : null,
        firstName: angular.isDefined(saleItemProduct.firstName) ? saleItemProduct.firstName : null,
        lastName: angular.isDefined(saleItemProduct.lastName) ? saleItemProduct.lastName : null
      };
    }

    function addDiscountProduct(saleItemId, discountPercentage, discountAmount, originalBillItem, discountType) {
      var saleItemPostRequest = SaleFactory.one(vm.saleId).one('addDiscountSaleItem'), billItemObj, product;
      saleItemPostRequest.customPOST({discount: true, saleItemId: saleItemId, discountPercentage: discountPercentage, discountAmount: discountAmount, discountType: discountType})
        .then(function (resultSaleItem) {
          if (angular.isDefined(resultSaleItem.id)) {
            product = ProductUtilService.getProductDetails(resultSaleItem.product);
            billItemObj = createBillItemObject(product, resultSaleItem.quantity, resultSaleItem.id, resultSaleItem.price);
            billItemObj.dirty = false;
            billItemObj.externalApiId = resultSaleItem.parameters.externalApiId;
            originalBillItem.discountSaleItemId = resultSaleItem.id;
            // insert discount item after the item for which current item is the discount
            vm.billItems.splice(vm.billItems.indexOf(originalBillItem) + 1, 0, billItemObj);
            vm.billItems.join();
            terminateAddBill();
          }
        });
    }

    function updateDiscountProduct(bi) {
      var discountSaleItemRestObject, discountItem;

      if (angular.isDefined(bi.discountSaleItemId) && bi.discountSaleItemId) {
        discountItem = vm.billItems.filter(function (billItem) {
          return billItem.saleItemId === bi.discountSaleItemId;
        });
        if (angular.isDefined(discountItem[0])) {
          discountSaleItemRestObject = getSaleItemRestObjectFromBillItem(discountItem[0]);
          discountSaleItemRestObject.parameters.discount = bi.discount;
          discountSaleItemRestObject.parameters.discountAmount = bi.discountAmount;
          discountSaleItemRestObject.parameters.discountPercentage = bi.discountPercentage;
          discountSaleItemRestObject.parameters.discountType = bi.discountType;
          SaleFactory.one(vm.saleId).one('items').one(discountItem[0].saleItemId).patch({parameters: discountSaleItemRestObject.parameters})
            .then(function () {
              recalculateDiscountProduct(bi);
            });
        }
      }
    }

    function recalculateDiscountProduct(bi) {
      var discountItem;
      // recalculate existing discount if need
      if (vm.discountAddProductEnabled && angular.isDefined(bi.discountSaleItemId)) {
        discountItem = vm.billItems.filter(function (billItem) {
          return billItem.saleItemId === bi.discountSaleItemId;
        });
        if (angular.isDefined(discountItem[0])) {
          discountItem[0].dirty = true;
          SaleFactory.one(vm.saleId).one('addDiscountSaleItem').one('1').customPOST({discount: true, saleItemId: bi.saleItemId})
            .then(function (resultSaleItem) {
              bi.quantityChanged = false;
              bi.discountObject = true;
              discountItem[0].productExclPrice = resultSaleItem.price;
              discountItem[0].productPrice = ProductUtilService.calculateInclPrice(discountItem[0].productExclPrice, discountItem[0].vat).inclPrice;
              discountItem[0].vatPrice = ProductUtilService.calculateInclPrice(discountItem[0].productExclPrice, discountItem[0].vat).vatPrice;
              discountItem[0].amount = resultSaleItem.quantity;
              hwproxy.sendSaleItemInfoToDisplay(discountItem[0]);
              discountItem[0].dirty = false;
            });
        }
      }
    }

    function deleteDiscountProduct(bi) {
      var discountItem;

      if (angular.isDefined(bi.discountSaleItemId) && bi.discountSaleItemId) {
        discountItem = vm.billItems.filter(function (billItem) {
          return billItem.saleItemId === bi.discountSaleItemId;
        });
        if (angular.isDefined(discountItem[0])) {
          SaleFactory.one(vm.saleId).one('items').one(bi.saleItemId).patch({discountSaleItem: null}).then(function () {
            SaleFactory.one(vm.saleId)
              .one('items').one(discountItem[0].saleItemId)
              .remove()
              .then(function () {
                // Remove 1 item of the billItems array
                vm.billItems.splice(vm.billItems.indexOf(discountItem[0]), 1);
              });
          });
        }
      }
    }
  }
}());
