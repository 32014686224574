(function () {
  'use strict';

  angular
    .module('pos')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('pos', {
        url: '/pos',
        templateUrl: 'pos/views/pos.tpl.html',
        controller: 'PosCtrl',
        controllerAs: 'posCtrl'
      })
      .state('survey_queries', {
        url: '/survey_queries',
        templateUrl: 'pos/views/survey_queries/survey_queries.tpl.html',
        controller: 'SurveyQueriesCtrl',
        controllerAs: 'surveyQueriesCtrl'
      })
      .state('survey_answers', {
        url: '/survey_queries/{queryId}/answers',
        templateUrl: 'pos/views/survey_queries/survey_answers.tpl.html',
        controller: 'SurveyAnswersCtrl',
        controllerAs: 'surveyAnswersCtrl'
      });
  }
}());
