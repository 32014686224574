(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:InvoiceViewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('InvoiceViewCtrl', InvoiceViewCtrl);

  function InvoiceViewCtrl(InvoiceFactory, SaleFactory, $modalInstance, invoice) {
    var vm = this;
    vm.invoice = invoice;
    vm.cancel = cancelModalInstance;
    vm.sales = [];
    vm.excludedSaleItemIds = [];

    angular.forEach(vm.invoice.excludedSaleItems, function (saleItem) {
      vm.excludedSaleItemIds.push(saleItem.id);
    });

    InvoiceFactory.one(invoice.id).get().then(function (resultInvoice) {
      SaleFactory.getList({'filter[]': 'invoices.id,' + resultInvoice.id, sort: 'createdAt,DESC'})
        .then(function (resultSales) {
          angular.forEach(resultSales, function (sale, key) {
            SaleFactory.one(sale.id).get().then(function (resultSale) {
              vm.sales[key] = resultSale;
            });
          });
        });
    });

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
