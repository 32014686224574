(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosSessionsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosSessionsCtrl', PosSessionsCtrl);

  function PosSessionsCtrl() {
    var vm = this;
    vm.ctrlName = 'PosSessionsCtrl';
  }
}());
