(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.factory:FacilityFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .factory('FacilityFactory', FacilityFactory);

  function FacilityFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'facility'});
    })
    .service('facilities');
  }
}());
