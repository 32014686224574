(function () {
  'use strict';

  angular
    .module('sportoase')
    .config(routeConfig)
    .config(translationConfig);

  function routeConfig($urlRouterProvider) {
    $urlRouterProvider.otherwise('/dashboard');
  }

  function translationConfig($translateProvider, LOCALES) {
    // get warnings in the developer console regarding forgotten IDs in translations
    $translateProvider.useMissingTranslationHandlerLog();

    $translateProvider.useStaticFilesLoader({
      // path to translation files
      prefix: 'resources/locale-',
      suffix: '.json'
    });
    $translateProvider.useSanitizeValueStrategy('escape');
    $translateProvider.preferredLanguage(LOCALES.preferredLanguage);
    $translateProvider.useLocalStorage();
  }
}());
