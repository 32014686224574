(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:NewsItemAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('dashboard')
    .controller('NewsItemAddEditCtrl', NewsItemAddEditCtrl);

  function NewsItemAddEditCtrl(
    $modalInstance,
    $filter,
    newsItem,
    reloadType,
    NewsItemFactory,
    ToastrNotificationService,
    SiteFactory,
    RoleFactory
    ) {
    var vm = this;
    vm.newsItem = angular.copy(newsItem);
    vm.save = save;
    vm.isEdit = isEdit;

    vm.modalTitle = vm.isEdit() ? 'app.edit_item' : 'app.add_item';

    vm.showSavedNotification = showSavedNotification;
    vm.cancel = cancelModalInstance;
    vm.init = init;
    vm.sites = [];
    vm.roles = [];

    vm.tags = [
      'important',
      'info'
    ];

    if (vm.newsItem === null) {
      vm.newsItem = {
        id: null,
        label: '',
        comments: '',
        tags: [],
        published: false,
        sites: [],
        roles: [],
        startDate: '',
        endDate: ''
      };
    } else {
      angular.forEach(vm.newsItem.sites, function (site, key) {
        vm.newsItem.sites[key] = site.id;
      });

      angular.forEach(vm.newsItem.roles, function (role, key) {
        vm.newsItem.roles[key] = role.id;
      });
    }

    function save() {
      var restObject = {
        label: vm.newsItem.label,
        published: vm.newsItem.published,
        tags: vm.newsItem.tags,
        sites: vm.newsItem.sites,
        roles: vm.newsItem.roles,
        comments: vm.newsItem.comments,
        startDate: vm.newsItem.startDate,
        endDate: vm.newsItem.endDate
      };

      if (vm.isEdit()) {
        // If edit mode
        NewsItemFactory.one(vm.newsItem.id).patch(restObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      } else {
        // If not edit mode
        NewsItemFactory.post(restObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      }
    }

    function init() {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };

      vm.sites = [];
      vm.roles = [];

      SiteFactory.getPermittedSitesForAction('news').then(function (results) {
        vm.sites = results;
      });

      RoleFactory.getList(params).then(function (results) {
        vm.roles = results;
      });

      if (vm.newsItem.startDate) {
        vm.newsItem.startDate = new Date(vm.newsItem.startDate);
      } else {
        vm.newsItem.startDate = '';
      }
      if (vm.newsItem.endDate) {
        vm.newsItem.endDate = new Date(vm.newsItem.endDate);
      } else {
        vm.newsItem.endDate = '';
      }
    }

    function isEdit() {
      return vm.newsItem && (vm.newsItem.id !== null);
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.news_item')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.news_item')
            )
          )
        );
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
    /* ----- PARAMS ----- */
    vm.startDate = new Date();
    vm.endDate = new Date((new Date()).setMonth((new Date()).getMonth() + 1));
    vm.datePickerIsOpened = false;
    vm.endDatePickerIsOpened = false;
    vm.minDate = new Date();
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openDatePicker = openDatePicker;
    /* ----- END FUNCTIONS ----- */

    /* ----- DATE PICKER ----- */
    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }
    /* ----- END DATE PICKER ----- */
  }
}());
