(function () {
  'use strict';

  angular
    .module('promotion')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('promotions', {
        url: '/promotions',
        views: {
          '@': {
            templateUrl: 'promotion/views/promotion.tpl.html'
          },
          'nav@promotions': {
            templateUrl: 'promotion/views/promotion.nav.tpl.html'
          },
          'content@promotions': {
            templateUrl: 'promotion/views/promotion.list.modal.tpl.html',
            controller: [
              'PromotionFactory',
              function (PromotionFactory) {
                var vm = this,
                    params = {
                      limit: 99,
                      sort: 'label,ASC'
                    };

                PromotionFactory.getList(params).then(function (result) {
                  vm.promotions = result;
                });
              }
            ],
            controllerAs: 'promotionCtrl'
          }
        }
      })
      .state('promotions.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'promotion/views/promotion.add.edit.modal.tpl.html',
            controller: 'PromotionCtrl',
            controllerAs: 'promotionCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('promotions.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'promotion/views/promotion.add.edit.modal.tpl.html',
            controller: 'PromotionCtrl',
            controllerAs: 'promotionCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('promotions.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'promotion/views/promotion.delete.modal.tpl.html',
            controller: 'PromotionCtrl',
            controllerAs: 'promotionCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
