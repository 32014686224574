(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .factory('ProductComponentFactory', ProductComponentFactory);

  // because of product versioning we require the response headers after each change
  // so we can get the new product id, therefore we set full response to true
  function ProductComponentFactory(Restangular) {
    var productService = Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'productComponent'});
    }).service('products/components');

    return productService;
  }
}());
