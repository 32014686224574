(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:StartPosShiftCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('StartPosShiftCtrl', StartPosShiftCtrl);

  function StartPosShiftCtrl(
    $modalInstance,
    PosGroupFactory,
    LogService,
    PosSessionFactory,
    CurrentPosInstanceFactory,
    ToastrNotificationService,
    UtilService
  ) {
    var vm = this;
    vm.posGroupId = null;
    vm.posGroupInstanceId = null;
    vm.startNewPosSession = startNewPosSession;
    vm.cancel = cancelModalInstance;
    vm.loadData = loadData;
    vm.startAmount = 0;

    vm.loadData();

    function loadData() {
      var instanceIds;

      if (CurrentPosInstanceFactory.isInstanceSelected()) {
        LogService.log('StartPosShiftCtrl::loadData() -> Instance cookies found, searching for session.', 'debug');
        instanceIds = CurrentPosInstanceFactory.getInstanceCookies();
        vm.posGroupId = instanceIds.posGroupId;
        vm.posGroupInstanceId = instanceIds.posInstanceId;

        // check if we have a currently active session
        PosSessionFactory.getActivePosSession()
        .then(function (active) {
          if (active === null) {
            // if we're supposed to show the start session modal, do so
            LogService.log('StartPosShiftCtrl::loadData() -> No active session found', 'debug');
            PosGroupFactory.one(instanceIds.posGroupId).get()
            .then(function (pg) {
              if (UtilService.isNotEmpty(pg) && pg.hasOwnProperty('defaultPointOfSaleSessionStartAmount')) {
                LogService.log('StartPosShiftCtrl::loadData() -> Setting default start amount' + pg.defaultPointOfSaleSessionStartAmount, 'debug');
                vm.startAmount = Number(pg.defaultPointOfSaleSessionStartAmount);
              }
            });
          } else {
            LogService.log('StartPosShiftCtrl::loadData() -> Cannot start new session because I found previously active session' + active.id, 'debug');
            vm.cancel();
          }
        });
      } else {
        LogService.log('StartPosShiftCtrl::loadData() -> No pos instance selected.', 'debug');
        // we were supposed to close down a session, but no active session was found
        vm.cancel();
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.no_session_short',
          'app.no_session_long'
        );
      }
    }

    function startNewPosSession() {
      return PosSessionFactory.startNewPosSession(vm.posGroupId, vm.posGroupInstanceId, vm.startAmount)
      .then(function () {
        ToastrNotificationService.showTranslatedNotification(
          'info', 'app.session_started', 'app.session_started_long'
        );
        $modalInstance.close();
      }, function () {
        vm.cancel(true);
        return Promise.reject();
      });
    }

    function cancelModalInstance(redirectToDashboard) {
      // wait until the modal has finished opening before trying to close it
      $modalInstance.opened
      .then(function () {
        $modalInstance.dismiss(redirectToDashboard);
      });
    }
  }
}());
