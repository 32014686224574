(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ScheduledReportCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('ScheduledReportCtrl', ScheduledReportCtrl);

  function ScheduledReportCtrl(
    $stateParams,
    $modalInstance,
    ScheduledReportFactory,
    RestUtilService,
    ScheduledReportUserContextFactory,
    ScheduledReportTypeFactory,
    CurrentUserContextFactory,
    $filter,
    _
  ) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.scheduledReportTypes = [];
    vm.userContexts = [];

    vm.id = null;
    vm.selectedReportType = null;
    vm.selectedInterval = null;
    vm.selectedUserContext = null;
    vm.enabled = false;

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;
    vm.setSelectedInterval = setSelectedInterval;
    vm.acronimReportSites = [];
    vm.selectedSite = [];
    vm.getAsyncUser = getAsyncUser;
    vm.scheduledReportIntervals = [
      {
        interval: 'P1D',
        label: $filter('translate')('app.daily'),
        datetimeCondition: null,
        datetimeConditionOffset: null
      },
      {
        interval: 'P7D',
        label: $filter('translate')('app.weekly'),
        datetimeCondition: null,
        datetimeConditionOffset: null
      },
      {
        interval: 'P1M',
        label: $filter('translate')('app.monthly'),
        datetimeCondition: null,
        datetimeConditionOffset: null
      },
      {
        interval: 'P6M',
        label: $filter('translate')('app.sixmonthly'),
        datetimeCondition: null,
        datetimeConditionOffset: null
      },
      {
        interval: 'P1D',
        label: $filter('translate')('scheduled_reports.first_day_of_month'),
        datetimeCondition: 'first day of this month',
        datetimeConditionOffset: null
      },
      {
        interval: 'P1D',
        label: $filter('translate')('scheduled_reports.last_day_of_month'),
        datetimeCondition: 'last day of this month',
        datetimeConditionOffset: null
      },
      {
        interval: 'P1D',
        label: $filter('translate')('scheduled_reports.15th_day_of_month'),
        datetimeCondition: 'first day of this month',
        datetimeConditionOffset: '+14 days'
      }
    ];

    function reportFormatToLabelPostfix(format) {
      if (format === 'csv_txt') {
        format = 'csv';
      }

      return format.toUpperCase();
    }

    function save() {
      var acronymSites = vm.selectedSite.map(function (element) {
            return element.label;
          }), item = {
            userContext: vm.selectedUserContext.id,
            enabled: vm.enabled,
            parameters: {
              format: vm.selectedReportType.format,
              sites: acronymSites.toString()
            },
            interval: vm.selectedInterval.interval,
            datetimeCondition: vm.selectedInterval.datetimeCondition,
            datetimeConditionOffset: vm.selectedInterval.datetimeConditionOffset
          };
      if (acronymSites.toString() === '') {
        delete item.parameters.sites;
      }
      if (vm.isEdit === true) {
        ScheduledReportFactory.one(vm.id).patch(item).then(function () {
          vm.close();
        });
      } else {
        item.site = CurrentUserContextFactory.getSiteId();
        ScheduledReportFactory.one(vm.selectedReportType.type).customPOST(item).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      ScheduledReportFactory.one(vm.id).remove().then(function () {
        vm.close();
      });
    }

    function getAsyncUser(viewValue) {
      var searchText = viewValue.toLowerCase(),
          users = vm.userContexts.filter(function (userContext) {
            if (angular.isDefined(userContext.label) && userContext.label.toLowerCase().indexOf(searchText) >= 0) {
              return userContext;
            }
          });

      return angular.isDefined(users[0]) ? users : null;
    }

    function init() {
      ScheduledReportFactory.one('sites').one('acronym').get().then(function (data) {
        vm.acronimReportSites = data;
      });

      RestUtilService.getFullList(ScheduledReportTypeFactory).then(function (results) {
        angular.forEach(results, function (result) {
          vm.scheduledReportTypes.push({
            label: $filter('translate')('reports.type.' + result.type) + ' ' + reportFormatToLabelPostfix(result.format),
            type: result.type,
            format: result.format
          });
        });

        RestUtilService.getFullList(ScheduledReportUserContextFactory).then(function (userContextResults) {
          vm.userContexts = userContextResults;

          angular.forEach(vm.userContexts, function (v) {
            v.label = $filter('vsprintf')('%s (%s)', [v.user.username, v.site.label]);
          });
        });

        if (angular.isDefined($stateParams.id)) {
          vm.title = 'app.edit';
          vm.isEdit = true;
          vm.id = $stateParams.id;
        }

        if (vm.isEdit === true) {
          ScheduledReportFactory.one(vm.id).get().then(function (result) {
            vm.setSelectedInterval({
              interval: result.interval,
              datetimeCondition: result.datetimeCondition,
              datetimeConditionOffset: result.datetimeConditionOffset
            });

            vm.selectedUserContext = result.userContext;
            vm.selectedUserContext.label = $filter('vsprintf')('%s (%s)', [result.userContext.user.username, result.userContext.site.label]);
            vm.selectedReportType = _.find(vm.scheduledReportTypes, {
              type: result.scheduledReportType,
              format: result.parameters.format
            });
            vm.enabled = result.enabled;
          });
        } else {
          vm.enabled = false;
        }
      });
    }

    function setSelectedInterval(data) {
      var interval, itemKey, match;

      for (interval in vm.scheduledReportIntervals) {
        if (vm.scheduledReportIntervals.hasOwnProperty(interval)) {
          match = true;

          for (itemKey in data) {
            if (data.hasOwnProperty(itemKey)) {
              if (angular.isUndefined(data[itemKey])) {
                data[itemKey] = null;
              }

              if (!vm.scheduledReportIntervals[interval].hasOwnProperty(itemKey) ||
                  vm.scheduledReportIntervals[interval][itemKey] !== data[itemKey]) {
                match = false;
              }
            }
          }

          if (match) {
            vm.selectedInterval = vm.scheduledReportIntervals[interval];
          }
        }
      }
    }

    function isValid() {
      return vm.selectedUserContext !== null &&
        vm.selectedReportType !== null &&
        vm.selectedInterval !== null;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
