(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:DigipolisOrderFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .factory('DigipolisOrderFactory', DigipolisOrderFactory);

  function DigipolisOrderFactory(Restangular) {
    var orderService = Restangular.service('digipolis_orders');

    orderService.getTransactionsList = function (queryParams, typeAndSaleFiltersExist) {
      if (angular.isUndefined(queryParams['filter[]'])) {
        queryParams['filter[]'] = [];
      }

      if (!typeAndSaleFiltersExist) {
        queryParams['filter[]'].push('type,EQ banktransfer');
        queryParams['filter[]'].push('sale.id,NULL');
      }

      return orderService.getList(queryParams);
    };

    orderService.getRePaymentsList = function (queryParams) {
      if (angular.isUndefined(queryParams['filter[]'])) {
        queryParams['filter[]'] = [];
      }
      queryParams['filter[]'].push('type,EQ repayment');

      return orderService.getList(queryParams);
    };

    orderService.getBankTransfersList = function (queryParams) {
      if (angular.isUndefined(queryParams['filter[]'])) {
        queryParams['filter[]'] = [];
      }
      queryParams['filter[]'].push('type,EQ banktransfer');
      queryParams['filter[]'].push('sale.id,NOT NULL');

      return orderService.getList(queryParams);
    };

    orderService.startManagement = function (orderId) {
      return orderService.one(orderId).one('management').post();
    };

    return orderService;
  }
}());
