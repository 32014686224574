(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SubscriptionsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SubscriptionsCtrl', SubscriptionsCtrl);

  function SubscriptionsCtrl(
    LevelFactory,
    UtilService,
    SwimCourseManagementFactory,
    SwimCourseSetFactory,
    ServiceFactory,
    LogService,
    $filter,
    $timeout,
    $scope,
    $modal,
    $cookies,
    ToastrNotificationService,
    moment
  ) {
    var vm = this;
    vm.leftWaitingList = [];
    vm.rightWaitingList = [];
    vm.leftGroups = [];
    vm.rightGroups = [];
    vm.leftSubscriptionList = [];
    vm.rightSubscriptionList = [];
    vm.leftSets = [];
    vm.rightSets = [];
    vm.leftGroupSlotsAvailable = false;
    vm.rightGroupSlotsAvailable = false;
    vm.rightSelectedGroup = null;
    vm.leftSelectedGroup = null;
    vm.rightSelectedLevel = null;
    vm.leftSelectedLevel = null;
    vm.leftSelectedSet = null;
    vm.rightSelectedSet = null;
    vm.clearSetAndGroup = clearSetAndGroup;
    vm.loadWaitingList = loadWaitingList;
    vm.loadGroups = loadGroups;
    vm.loadSet = loadSet;
    vm.loadData = loadData;
    vm.loadPupils = loadPupils;
    vm.transferSubscriptionsToGroup = transferSubscriptionsToGroup;
    vm.moveToGroup = moveToGroup;
    vm.transferSubscriptionsToWaitingList = transferSubscriptionsToWaitingList;
    vm.moveToWaitingList = moveToWaitingList;
    vm.getSelectedSubscriptions = getSelectedSubscriptions;
    vm.getCookies = getCookies;
    vm.setCookies = setCookies;
    vm.isConfiguredForTransfer = isConfiguredForTransfer;
    vm.reloadAll = reloadAll;
    vm.waitingListButtonsAvailable = waitingListButtonsAvailable;
    vm.subscriptionListButtonsAvailable = subscriptionListButtonsAvailable;
    vm.loadData();
    vm.loadContactDetails = loadContactDetails;
    vm.showSubscriptionCommentModal = showSubscriptionCommentModal;

    // waiting list confirmation modal
    vm.showingWaitingListConfirmationModal = false;
    vm.showWaitingListConfirmationModal = showWaitingListConfirmationModal;
    vm.returnOfWaitingListConfirmationModalInstance = returnOfWaitingListConfirmationModalInstance;
    vm.waitingListConfirmationModalInstance = null;

    vm.getCookies();

    function waitingListButtonsAvailable() {
      return vm.leftSelectedSet !== null && vm.rightSelectedSet !== null;
    }

    function subscriptionListButtonsAvailable() {
      return vm.leftSelectedGroup !== null && vm.rightSelectedGroup !== null;
    }

    function getCookies() {
      vm.initialDataSubscriptionLeftGroup = angular.isDefined($cookies.get('opensportSwimcourseSubscriptionLeftGroup'));
      vm.initialDataSubscriptionRightGroup = angular.isDefined($cookies.get('opensportSwimcourseSubscriptionRightGroup'));
      vm.initialDataSubscriptionLeftSet = angular.isDefined($cookies.get('opensportSwimcourseSubscriptionLeftSet'));
      vm.initialDataSubscriptionRightSet = angular.isDefined($cookies.get('opensportSwimcourseSubscriptionRightSet'));
      vm.initialDataSubscriptionLeftLevel = angular.isDefined($cookies.get('opensportSwimcourseSubscriptionLeftLevel'));
      vm.initialDataSubscriptionRightLevel = angular.isDefined($cookies.get('opensportSwimcourseSubscriptionRightLevel'));
    }

    function setCookies() {
      if (vm.leftSelectedLevel) {
        $cookies.put('opensportSwimcourseSubscriptionLeftLevel', vm.leftSelectedLevel.id);
      }
      if (vm.rightSelectedLevel) {
        $cookies.put('opensportSwimcourseSubscriptionRightLevel', vm.rightSelectedLevel.id);
      }
      if (vm.rightSelectedGroup) {
        $cookies.put('opensportSwimcourseSubscriptionRightGroup', vm.rightSelectedGroup.id);
      }
      if (vm.leftSelectedGroup) {
        $cookies.put('opensportSwimcourseSubscriptionLeftGroup', vm.leftSelectedGroup.id);
      }
      if (vm.rightSelectedSet) {
        $cookies.put('opensportSwimcourseSubscriptionRightSet', vm.rightSelectedSet.id);
      }
      if (vm.leftSelectedSet) {
        $cookies.put('opensportSwimcourseSubscriptionLeftSet', vm.leftSelectedSet.id);
      }
    }

    function loadData() {
      var params = {
            limit: 99
          },
          leftSelectedLevelId = $cookies.get('opensportSwimcourseSubscriptionLeftLevel'),
          rightSelectedLevelId = $cookies.get('opensportSwimcourseSubscriptionRightLevel');

      vm.rightSelectedGroup = null;
      vm.leftSelectedGroup = null;
      vm.rightSelectedLevel = null;
      vm.leftSelectedLevel = null;
      vm.leftSelectedSet = null;
      vm.rightSelectedSet = null;

      vm.levels = [];
      params.sort = 'weight,ASC';
      LevelFactory.getList(params).then(function (result) {
        vm.levels = result;
        if (vm.initialDataSubscriptionLeftLevel && leftSelectedLevelId) {
          vm.leftSelectedLevel = vm.levels.filter(function (lvl) {
            return (lvl.id === leftSelectedLevelId);
          });
          if (angular.isArray(vm.leftSelectedLevel) && vm.leftSelectedLevel.length > 0) {
            vm.leftSelectedLevel = vm.leftSelectedLevel[0];
          }
          vm.initialDataSubscriptionLeftLevel = false;
          vm.loadSet('left');
        }
        if (vm.initialDataSubscriptionRightLevel && rightSelectedLevelId) {
          vm.rightSelectedLevel = vm.levels.filter(function (lvl) {
            return (lvl.id === rightSelectedLevelId);
          });
          if (angular.isArray(vm.rightSelectedLevel) && vm.rightSelectedLevel.length > 0) {
            vm.rightSelectedLevel = vm.rightSelectedLevel[0];
          }
          vm.initialDataSubscriptionRightLevel = false;
          vm.loadSet('right');
        }
      });
    }

    $scope.$on('refreshSubscriptionsMatrix', function (event) {
      LogService.log('refresh subscription matrix event received, refreshing matrix', 'debug');
      event.preventDefault();
      vm.loadData();
    });

    function loadWaitingList(side) {
      if (UtilService.isNotEmpty(side)) {
        switch (side) {
          case 'left':
            if (vm.isConfiguredForTransfer(side, false)) {
              SwimCourseManagementFactory.getSwimCourseWaitingList(vm.leftSelectedSet.id, vm.leftSelectedLevel.id)
              .then(function (waitingList) {
                LogService.log('Loading left waiting list', 'debug');
                vm.leftWaitingList = vm.loadContactDetails(waitingList);
              });
            }
            break;
          case 'right':
            if (vm.isConfiguredForTransfer(side, false)) {
              SwimCourseManagementFactory.getSwimCourseWaitingList(vm.rightSelectedSet.id, vm.rightSelectedLevel.id)
              .then(function (waitingList) {
                LogService.log('Loading right waiting list', 'debug');
                vm.rightWaitingList = vm.loadContactDetails(waitingList);
              });
            }
            break;
          default:
            break;
        }
      }
    }

    function getSelectedSubscriptions(subscriptionLists) {
      var result = [], elementsToCheck = [];
      angular.forEach(subscriptionLists, function (subscriptionList) {
        elementsToCheck = [];
        // take the possiblity of a multidimensional array argument into account
        if (angular.isArray(subscriptionList)) {
          elementsToCheck = subscriptionList;
        } else {
          // subscriptionList is really just a single subscription in this case
          elementsToCheck.push(subscriptionList);
        }

        angular.forEach(elementsToCheck, function (subscription) {
          if (UtilService.isNotEmpty(subscription.selected) && subscription.selected) {
            result.push(subscription);
          }
        });
      });
      return result;
    }

    function transferSubscriptionsToGroup(subscriptions, group, groupSubscriptionList, direction) {
      if (group.maximumMembers - groupSubscriptionList.length - subscriptions.length >= 0) {
        UtilService.promiseLoop(subscriptions, function (pupil) {
          return SwimCourseManagementFactory.registerSubscriptionToGroup(
            pupil,
            group.setId,
            group.levelId,
            group.id,
            false,
            true
          );
        })
        .then(function () {
          if (direction === 'up') {
            vm.showWaitingListConfirmationModal(subscriptions);
          } else {
            vm.reloadAll();
          }
        });
      } else {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'info',
          'subscriptions.cannot_perform_transfer',
          '',
          'subscriptions.not_enough_slots',
          group.swimCourseGroup.label
        );
      }
    }

    function transferSubscriptionsToWaitingList(subscriptions, setId, levelId) {
      UtilService.promiseLoop(subscriptions, function (subscription) {
        return SwimCourseManagementFactory.registerSubscriptionToWaitingList(
          subscription,
          setId,
          levelId,
          false
        );
      })
      .then(function () {
        vm.reloadAll();
      });
    }

    // check if all the required selections have been made to perform a transfer
    function isConfiguredForTransfer(side, destinedForGroup) {
      var result = false;
      switch (side) {
        case 'left':
          if (UtilService.isNotEmpty(destinedForGroup) && destinedForGroup) {
            // suscribe to group
            result = (UtilService.isNotEmpty(vm.leftSelectedSet) &&
              UtilService.isNotEmpty(vm.leftSelectedLevel) &&
              UtilService.isNotEmpty(vm.leftSelectedGroup)
            );
          } else {
            // waiting list
            result = (UtilService.isNotEmpty(vm.leftSelectedSet) && UtilService.isNotEmpty(vm.leftSelectedLevel));
          }
          break;
        case 'right':
          if (UtilService.isNotEmpty(destinedForGroup) && destinedForGroup) {
            // suscribe to group
            result = (UtilService.isNotEmpty(vm.rightSelectedSet) &&
              UtilService.isNotEmpty(vm.rightSelectedLevel) &&
              UtilService.isNotEmpty(vm.rightSelectedGroup)
            );
          } else {
            // waiting list
            result = (UtilService.isNotEmpty(vm.rightSelectedSet) &&
              UtilService.isNotEmpty(vm.rightSelectedLevel)
            );
          }
          break;
        default:
          break;
      }
      return result;
    }

    function clearSetAndGroup(side) {
      switch (side) {
        case 'left':
          vm.leftSelectedSet = null;
          vm.leftSelectedGroup = null;
          vm.leftSubscriptionList = [];
          break;
        case 'right':
          vm.rightSelectedSet = null;
          vm.rightSelectedGroup = null;
          vm.rightSubscriptionList = [];
          break;
        default:
          break;
      }
    }

    function loadSet(side) {
      var leftSelectedSetId = $cookies.get('opensportSwimcourseSubscriptionLeftSet'),
          rightSelectedSetId = $cookies.get('opensportSwimcourseSubscriptionRightSet');
      vm.rightGroupSlotsAvailable = false;
      vm.leftGroupSlotsAvailable = false;
      switch (side) {
        case 'left':

          ServiceFactory.getSwimCourseSetsByLevel(vm.leftSelectedLevel.id)
          .then(function (retrievedSets) {
            vm.leftSets = retrievedSets;
            if (vm.initialDataSubscriptionLeftSet && leftSelectedSetId) {
              vm.leftSelectedSet = vm.leftSets.filter(function (set) {
                return (set.id === leftSelectedSetId);
              });
              if (angular.isArray(vm.leftSelectedSet) && vm.leftSelectedSet.length > 0) {
                vm.leftSelectedSet = vm.leftSelectedSet[0];
              }
              vm.initialDataSubscriptionLeftSet = false;
              vm.loadWaitingList('left');
              vm.loadGroups('left');
            }
          });

          break;
        case 'right':
          ServiceFactory.getSwimCourseSetsByLevel(vm.rightSelectedLevel.id)
          .then(function (retrievedSets) {
            vm.rightSets = retrievedSets;
            if (vm.initialDataSubscriptionRightSet && rightSelectedSetId) {
              vm.rightSelectedSet = vm.rightSets.filter(function (set) {
                return (set.id === rightSelectedSetId);
              });
              if (angular.isArray(vm.rightSelectedSet) && vm.rightSelectedSet.length > 0) {
                vm.rightSelectedSet = vm.rightSelectedSet[0];
              }
              vm.initialDataSubscriptionRightSet = false;
              vm.loadWaitingList('right');
              vm.loadGroups('right');
            }
          });
          break;
        default:
          break;
      }
    }

    function loadGroups(side) {
      var leftSelectedGroupId = $cookies.get('opensportSwimcourseSubscriptionLeftGroup'),
          rightSelectedGroupId = $cookies.get('opensportSwimcourseSubscriptionRightGroup');
      vm.rightGroupSlotsAvailable = false;
      vm.leftGroupSlotsAvailable = false;
      switch (side) {
        case 'left':
          if (vm.isConfiguredForTransfer('left', false)) {
            vm.leftGroups = [];
            ServiceFactory.getSwimCourseSeriesGroupByLevelAndSet(vm.leftSelectedLevel.id, vm.leftSelectedSet.id, true)
            .then(function (retrievedGroups) {
              vm.leftGroups = retrievedGroups;
              if (vm.initialDataSubscriptionLeftGroup && leftSelectedGroupId) {
                vm.leftSelectedGroup = vm.leftGroups.filter(function (group) {
                  return (group.id === leftSelectedGroupId);
                });
                if (angular.isArray(vm.leftSelectedGroup) && vm.leftSelectedGroup.length > 0) {
                  vm.leftSelectedGroup = vm.leftSelectedGroup[0];
                }
                vm.initialDataSubscriptionLeftGroup = false;
                vm.loadPupils('left');
              }
              angular.forEach(vm.leftGroups, function (group) {
                group.swimCourseGroup.labelWithPeriod = SwimCourseManagementFactory.getSwimCourseGroupLabelWithPeriod(group);
              });
            });
            vm.leftSubscriptionList = [];
          }
          break;
        case 'right':
          if (vm.isConfiguredForTransfer('right', false)) {
            vm.rightGroups = [];
            ServiceFactory.getSwimCourseSeriesGroupByLevelAndSet(vm.rightSelectedLevel.id, vm.rightSelectedSet.id, true)
            .then(function (retrievedGroups) {
              vm.rightGroups = retrievedGroups;
              if (vm.initialDataSubscriptionRightGroup && rightSelectedGroupId) {
                vm.rightSelectedGroup = vm.rightGroups.filter(function (group) {
                  return (group.id === rightSelectedGroupId);
                });
                if (angular.isArray(vm.rightSelectedGroup) && vm.rightSelectedGroup.length > 0) {
                  vm.rightSelectedGroup = vm.rightSelectedGroup[0];
                }
                vm.initialDataSubscriptionRightGroup = false;
                vm.loadPupils('right');
              }
              angular.forEach(vm.rightGroups, function (group) {
                group.swimCourseGroup.labelWithPeriod = SwimCourseManagementFactory.getSwimCourseGroupLabelWithPeriod(group);
              });
            });
            vm.rightSubscriptionList = [];
          }
          break;
        default:
          break;
      }
    }

    function loadPupils(side) {
      switch (side) {
        case 'left':
          if (vm.isConfiguredForTransfer('left', true)) {
            // attach ids to make transfering easier
            vm.leftSelectedGroup.setId = vm.leftSelectedSet.id;
            vm.leftSelectedGroup.levelId = vm.leftSelectedLevel.id;
            vm.leftSelectedGroup.seriesGroupId = vm.leftSelectedGroup.id;

            // load the list of subscriptions
            SwimCourseManagementFactory.getSwimCoursePupils(
              vm.leftSelectedSet.id,
              vm.leftSelectedLevel.id,
              vm.leftSelectedGroup.id
            )
            .then(function (pupils) {
              vm.leftSubscriptionList = vm.loadContactDetails(pupils);
              vm.leftGroupSlotsAvailable = vm.leftSelectedGroup && (vm.leftSelectedGroup.maximumMembers - vm.leftSubscriptionList.length) > 0;
            });
          }
          break;

        case 'right':
          if (vm.isConfiguredForTransfer('right', true)) {
            // attach ids to make transfering easier
            vm.rightSelectedGroup.setId = vm.rightSelectedSet.id;
            vm.rightSelectedGroup.levelId = vm.rightSelectedLevel.id;
            vm.rightSelectedGroup.seriesGroupId = vm.rightSelectedGroup.id;

            // load the list of subscriptions
            SwimCourseManagementFactory.getSwimCoursePupils(
              vm.rightSelectedSet.id,
              vm.rightSelectedLevel.id,
              vm.rightSelectedGroup.id
            )
            .then(function (pupils) {
              vm.rightSubscriptionList = vm.loadContactDetails(pupils);
              vm.rightGroupSlotsAvailable = vm.rightSelectedGroup && (vm.rightSelectedGroup.maximumMembers - vm.rightSubscriptionList.length) > 0;
            });
          }
          break;
        default:
          break;
      }
    }

    function reloadAll() {
      vm.loadPupils('left');
      vm.loadWaitingList('left');
      vm.loadPupils('right');
      vm.loadWaitingList('right');
    }

    function moveToWaitingList(direction) {
      var pupilList, leftPupilList, rightPupilList;
      if (UtilService.isNotEmpty(direction)) {
        switch (direction) {
          case 'right':
            if (vm.isConfiguredForTransfer('right', false)) {
              pupilList = vm.getSelectedSubscriptions([vm.leftSubscriptionList, vm.leftWaitingList]);
              vm.transferSubscriptionsToWaitingList(pupilList, vm.rightSelectedSet.id, vm.rightSelectedLevel.id);
            }
            break;

          case 'left':
            if (vm.isConfiguredForTransfer('left', false)) {
              pupilList = vm.getSelectedSubscriptions([vm.rightSubscriptionList, vm.rightWaitingList]);
              vm.transferSubscriptionsToWaitingList(pupilList, vm.leftSelectedSet.id, vm.leftSelectedLevel.id);
            }
            break;

          case 'down':
            leftPupilList = [];
            rightPupilList = [];

            if (vm.isConfiguredForTransfer('left', false)) {
              leftPupilList = vm.getSelectedSubscriptions(vm.leftSubscriptionList);
              vm.transferSubscriptionsToWaitingList(leftPupilList, vm.leftSelectedSet.id, vm.leftSelectedLevel.id);
            }

            if (vm.isConfiguredForTransfer('right', false)) {
              rightPupilList = vm.getSelectedSubscriptions(vm.rightSubscriptionList);
              vm.transferSubscriptionsToWaitingList(rightPupilList, vm.rightSelectedSet.id, vm.rightSelectedLevel.id);
            }
            break;

          default:
            break;
        }
      }
    }

    function moveToGroup(direction) {
      var pupilList, leftWaitingList, rightWaitingList;
      if (UtilService.isNotEmpty(direction)) {
        switch (direction) {
          case 'right':
            if (vm.isConfiguredForTransfer('right', true)) {
              pupilList = vm.getSelectedSubscriptions([vm.leftSubscriptionList, vm.leftWaitingList]);
              vm.transferSubscriptionsToGroup(pupilList, vm.rightSelectedGroup, vm.rightSubscriptionList, direction);
            }
            break;

          case 'left':
            if (vm.isConfiguredForTransfer('left', true)) {
              pupilList = vm.getSelectedSubscriptions([vm.rightSubscriptionList, vm.rightWaitingList]);
              vm.transferSubscriptionsToGroup(pupilList, vm.leftSelectedGroup, vm.leftSubscriptionList, direction);
            }
            break;

          case 'up':
            if (vm.isConfiguredForTransfer('left', true)) {
              leftWaitingList = vm.getSelectedSubscriptions(vm.leftWaitingList);
              vm.transferSubscriptionsToGroup(leftWaitingList, vm.leftSelectedGroup, vm.leftSubscriptionList, direction);
            }

            if (vm.isConfiguredForTransfer('right', true)) {
              rightWaitingList = vm.getSelectedSubscriptions(vm.rightWaitingList);
              vm.transferSubscriptionsToGroup(rightWaitingList, vm.rightSelectedGroup, vm.rightSubscriptionList, direction);
            }
            break;
          default:
            break;
        }
      }
    }

    function showWaitingListConfirmationModal(subscriptions) {
      if (vm.showingWaitingListConfirmationModal) {
        return;
      }

      vm.waitingListConfirmationModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/series.levels.waitinglist.confirmation.modal.tpl.html',
        controller: 'WaitingListConfirmationCtrl',
        controllerAs: 'waitingListConfirmationCtrl',
        size: 'sm',
        resolve: {
          subscription: function () {
            return subscriptions;
          }
        }
      });

      vm.showingWaitingListConfirmationModal = true;
      vm.returnOfWaitingListConfirmationModalInstance(subscriptions);
    }

    function returnOfWaitingListConfirmationModalInstance(subscriptions) {
      vm.waitingListConfirmationModalInstance.result.then(function (returnValue) {
        LogService.log('Waiting list confirmation modal: Closed => reason: ' + returnValue, 'debug');
        if (returnValue === 'yes') {
          UtilService.promiseLoop(subscriptions, function (subscription) {
            return ServiceFactory.deleteWaitingListBySubscribedSubscription(subscription.id).then(function () {
              vm.waitingListConfirmationModalInstance = null;
              vm.showingWaitingListConfirmationModal = false;
            }, function (errorMsg) {
              vm.waitingListConfirmationModalInstance = null;
              vm.showingWaitingListConfirmationModal = false;
              console.error('error: ' + errorMsg);
            });
          }).then(function () {
            vm.waitingListConfirmationModalInstance = null;
            vm.showingWaitingListConfirmationModal = false;
            vm.reloadAll();
          });
        } else {
          vm.waitingListConfirmationModalInstance = null;
          vm.showingWaitingListConfirmationModal = false;
          vm.reloadAll();
        }
      }, function (returnValue) {
        LogService.log('Waiting list confirmation modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.waitingListConfirmationModalInstance = null;
        vm.showingWaitingListConfirmationModal = false;
        vm.reloadAll();
      });
    }

    function loadContactDetails(objects) {
      angular.forEach(objects, function (object, index) {
        objects[index].contact.age = 0;
        angular.forEach(objects[index].contact.contactData, function (contactData) {
          switch (contactData.contactDataType.code) {
            case 'BIRTH_DATE':
              objects[index].contact.age = moment().diff(moment(contactData.value), 'years');
              break;
            default:
              break;
          }
        });
      });

      return objects;
    }

    function showSubscriptionCommentModal(subscription) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/pupils/pupil.subscription.comment.addedit.modal.tpl.html',
          controller: 'SubscriptionCommentEditCtrl',
          controllerAs: 'subscriptionCommentEditCtrl',
          resolve: {
            subscription: function () {
              return subscription;
            }
          }
        },
        function () {
          vm.reloadAll();
        },
        null,
        true
      );
    }
  }
}());
