(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:UserActivationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('UserActivationCtrl', UserActivationCtrl);

  function UserActivationCtrl($stateParams, $rootScope, Restangular) {
    var vm = this;
    vm.errorMessage = null;

    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['user-activation', 'login']
    };

    vm.entity = null;
    vm.item = {
      token: $stateParams.token,
      plainPassword: null,
      plainPasswordVerification: null
    };

    vm.isValid = isValid;
    vm.activate = activate;

    function isValid() {
      return angular.isDefined(vm.item) &&
        angular.isDefined(vm.item.token) &&
        angular.isString(vm.item.token) &&
        vm.item.token.length &&
        angular.isDefined(vm.item.plainPassword) &&
        angular.isString(vm.item.plainPassword) &&
        vm.item.plainPassword.length &&
        angular.isDefined(vm.item.plainPasswordVerification) &&
        angular.isString(vm.item.plainPasswordVerification) &&
        vm.item.plainPasswordVerification.length &&
        (vm.item.plainPassword === vm.item.plainPasswordVerification);
    }

    function activate() {
      var object = {
        token: vm.item.token
      };

      vm.errorMessage = null;

      Restangular.all('open/users/activate').customPOST(object).then(function (actResult) {
        vm.item.token = actResult.token;
        object.token = vm.item.token;
        object.plainPassword = vm.item.plainPassword;

        Restangular.all('open/users/password/reset/confirm').customPOST(object).then(function (pwResult) {
          vm.entity = pwResult;
        }, function () {
          vm.errorMessage = 'app.errors.could-not-set-password';
        });
      }, function () {
        vm.errorMessage = 'app.errors.could-not-activate-account';
      });
    }
  }
}());
