(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.controller:SelectJournalController
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('kiosk')
    .controller('SelectJournalCtrl', SelectJournalCtrl);

  function SelectJournalCtrl(
    $filter,
    LogService,
    $q,
    $modalInstance,
    JournalActionFactory,
    JournalFactory,
    journals,
    selectedEvent,
    ToastrNotificationService,
    CurrentUserContextFactory
  ) {
    var vm = this;

    // functions
    vm.cancel = cancel;
    vm.confirm = confirm;

    // variables
    vm.facility = {
      id: CurrentUserContextFactory.getSiteFacilityId()
    };

    // only include journals which are eligible (otherwise you would be able to confirm
    // with journals of a completely different type)
    vm.journals = [];
    angular.forEach(journals, function (currentJournal) {
      if (selectedEvent.eligibleJournalTypeIds.indexOf(currentJournal.journalType.id) > -1) {
        vm.journals.push(currentJournal);
      }
    });
    vm.selectedEvent = selectedEvent;

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function confirm(journal) {
      var journalItemForm,
          journalActionParams = {
            limit: 1
          },
          deferred = $q.defer();

      LogService.log('consume journal:' + journal, 'debug');

      journalActionParams['filter[]'] = 'code,CONSUMPTION';
      JournalActionFactory.getList(journalActionParams).then(function (journalActions) {
        journalItemForm = {
          journalAction: journalActions[0].id,
          facility: vm.facility.id
        };
        if (angular.isDefined(journal.credits) && journal.credits > 0) {
          journalItemForm.credits = 1;
        } else if (angular.isDefined(journal.credits)) {
          ToastrNotificationService.showNotification('error',
            $filter('uconlyfirst')($filter('translate')('app.entrance-not-allowed')));
          deferred.reject();
        }

        JournalFactory.one(journal.id).post('items', journalItemForm).then(function () {
          $modalInstance.close({
            success: true,
            journal: journal,
            event: vm.selectedEvent
          });
          deferred.resolve();
        }, function () {
          ToastrNotificationService.showNotification('error',
            $filter('uconlyfirst')($filter('translate')('app.entrance-not-allowed')));
          deferred.reject();
        });
      });

      return deferred.promise;
    }
  }
}());
