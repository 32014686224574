(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name subscriptionContract.controller:SubscriptionContractEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('subscriptionContract')
    .controller('SubscriptionContractEditCtrl', SubscriptionContractEditCtrl);

  function SubscriptionContractEditCtrl(
      subscriptionContract,
      SubscriptionContractFactory,
      $modalInstance,
      LogService
      ) {
    var vm = this;
    vm.subscriptionContract = subscriptionContract;
    vm.cancel = cancelModalInstance;
    vm.saveContract = saveContract;
    vm.yearExclPrice = angular.copy(vm.subscriptionContract.price);
    vm.yearInclPrice = angular.copy(vm.subscriptionContract.inclPrice);
    vm.monthlyPrice = angular.copy(vm.subscriptionContract.monthlyPrice);
    vm.discount = vm.subscriptionContract.discountPercentage ? true : false;
    vm.discountPercentage = angular.copy(vm.subscriptionContract.discountPercentage);
    vm.calculatePrice = calculatePrice;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveContract() {
      var subscriptionObject = {
        price: vm.yearExclPrice
      };
      subscriptionObject.discountPercentage = vm.discount ? vm.discountPercentage : 0;
      // Update subscription subscription contract
      SubscriptionContractFactory.one(vm.subscriptionContract.id).patch(subscriptionObject).then(function () {
        LogService.log('Data patched', 'debug');
        // Close the modal view
        $modalInstance.close('list');
      });
    }

    function calculatePrice() {
      vm.yearInclPrice = Math.round(vm.monthlyPrice * vm.subscriptionContract.minimumDuration * 100) / 100;
      vm.yearExclPrice = Math.round(vm.yearInclPrice / (vm.subscriptionContract.vatPercentage / 100 + 1) * 100) / 100;
    }
  }
}());
