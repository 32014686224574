(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:RoleCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('RoleCtrl', RoleCtrl);

  function RoleCtrl($stateParams, LogService, _, RoleFactory, ToastrNotificationService, SettingsService, UtilService, PermissionsUtilService, PermissionFactory, RestUtilService) {
    var vm = this;
    vm.item = {};
    vm.entities = [];
    vm.permissions = [];
    vm.roles = [];
    vm.defaultRoleId = null;
    vm.init = init;
    vm.roleSearchQuery = '';
    vm.loadRoles = loadRoles;
    vm.showMakeDefaultModal = showMakeDefaultModal;
    vm.loadDefaultRole = loadDefaultRole;

    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };
    vm.init();

    function loadDefaultRole() {
      vm.defaultRoleId = SettingsService.get('user.defaultRoleId', null);
    }

    function loadRoles(reload) {
      var queryParams;

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      vm.loadDefaultRole();
      if (angular.isUndefined(vm.roles[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage
        };

        if (UtilService.isNotEmpty(vm.roleSearchQuery)) {
          queryParams['filter[]'] = 'label,LIKE ' + vm.roleSearchQuery;
        }

        RoleFactory.getList(queryParams)
        .then(function (data) {
          vm.roles[vm.pagination.currentPage] = data;
          vm.pagination.totalItems = data.count;
        });
      }
    }

    // make a role the default selected option
    function showMakeDefaultModal(role) {
      UtilService.showFormattedConfirmationModal(
        'confirm.set_default_short', '',
        'confirm.set_default_long', '"' + role.translatedLabel + '"',
        function (result) {
          if (result === true) {
            SettingsService.set('user.defaultRoleId', role.id, null)
            .then(function () {
              ToastrNotificationService.showTranslatedAndFormattedNotification(
                'success',
                '', '',
                'app.item-successfully-updated', 'app.default'
              );

              vm.loadDefaultRole();
            });
          }
        }
      );
    }

    function init() {
      RestUtilService.getFullList(PermissionFactory).then(function (results) {
        vm.permissions = results;
      });

      vm.loadRoles();
    }
  }
}());
