(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:DigipolisBankTransfersCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('DigipolisBankTransfersCtrl', DigipolisBankTransfersCtrl);

  function DigipolisBankTransfersCtrl(
    _,
    $filter,
    $state,
    $stateParams,
    DigipolisOrderFactory,
    LogService,
    ProductUtilService,
    SettingsService,
    UtilService,
    OAuthToken,
    $window,
    Restangular,
    $modal,
    UserMeFactory
  ) {
    var vm = this;
    vm.bankTransfers = [];
    vm.loadBankTransfers = loadBankTransfers;
    vm.loadSingleBankTransfersPage = loadSingleBankTransfersPage;
    vm.filterChanged = filterChanged;
    vm.toggleSort = toggleSort;
    vm.paymentStatusChanged = paymentStatusChanged;
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.ProductUtilService = ProductUtilService;
    vm.generateBankTransferReport = generateBankTransferReport;
    vm.enableAllSite = false;
    vm.checkExistRoles = checkExistRoles;
    vm.checkRoles = ['KASSA2.0_LOKETMANAGER', 'KASSA2.0_FINANCE', 'KASSA2.0_ADMINISTRATOR'];
    vm.roles = [];
    vm.startDebtorManagement = startDebtorManagement;
    vm.debtorManagementEnabled = SettingsService.get('debtorManagementEnabled', false);
    setDefaultPaginationOptions();

    vm.filters = {
      beginDate: {
        val: '',
        field: 'createdAt',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.beginDate.isOpen = true;
        }
      },
      endDate: {
        val: '',
        field: 'createdAt',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        allowNullIfSet: true,
        open: function () {
          vm.filters.endDate.isOpen = true;
        }
      },
      orderId: {
        val: '',
        field: 'orderId',
        cond: 'LIKE'
      },
      ogm: {
        val: '',
        field: 'ogm',
        cond: 'LIKE'
      },
      saleReference: {
        val: '',
        field: 'sale.reference',
        cond: 'LIKE'
      },
      paymentStatus: {
        val: '',
        field: 'payment.status',
        cond: 'IN'
      }
    };
    vm.sort = {
      paymentStatus: null
    };

    if (angular.isDefined($stateParams.tab) && $stateParams.tab === 'digipolisBankTransfers') {
      vm.loadBankTransfers();
    }

    UserMeFactory.one().get().then(function (result) {
      vm.roles = result.activeUserContext.roles;
    });

    function checkExistRoles() {
      return vm.roles.filter(function (element) {
        return vm.checkRoles.indexOf(element.label) !== -1;
      }).length > 0;
    }

    function setDefaultPaginationOptions() {
      vm.pagination = {
        totalItems: null,
        maxPages: 10,
        currentPage: 1,
        itemsPerPage: 20
      };
    }

    function loadSingleBankTransfersPage(page) {
      var value, queryParams = {}, condition = '', loadingModal;

      queryParams = {
        offset: (page - 1) * vm.pagination.itemsPerPage,
        limit: vm.pagination.itemsPerPage,
        sort: 'createdAt,DESC',
        partial: 1
      };

      loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });

      _.each(vm.filters, function (filter, index) {
        if (angular.isDefined(filter.val) && filter.val) {
          switch (index) {
            case 'beginDate':
              filter.val.setHours(0);
              filter.val.setMinutes(0);
              filter.val.setSeconds(0);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'endDate':
              filter.val.setHours(23);
              filter.val.setMinutes(59);
              filter.val.setSeconds(59);
              value = $filter('date')(filter.val, filter.format);
              break;
            default:
              value = filter.val;
              break;
          }

          condition = '';
          if (UtilService.isNotEmpty(filter.cond)) {
            condition = filter.cond + ' ';
          }

          if (filter.hasOwnProperty('isParam') && filter.isParam) {
            queryParams[index] = value;
          } else {
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            if (angular.isDefined(filter.allowNullIfSet) && filter.allowNullIfSet === true) {
              queryParams['filter[' + index + ']'] = 'OR,' + queryParams['filter[' + index + ']'] + ',' + filter.field + ',NULL';
            }
          }
        }
      });

      _.each(vm.sort, function (sort, index) {
        if (sort === null) {
          return;
        }
        switch (index) {
          case 'paymentStatus':
            queryParams.sort = 'payment.status,' + sort;
            break;
          default:
            queryParams.sort = index + ',' + sort;
            break;
        }
      });

      if (vm.enableAllSite === true) {
        queryParams.displayAllSalesForCurrentUserByHisSites = true;
      }

      LogService.log('DigipolisBankTransfersCtrl::loadSingleBankTransfersPage() -> Loading page: ' + page, 'debug');

      return DigipolisOrderFactory.getBankTransfersList(queryParams)
        .then(function (data) {
          angular.forEach(data, function (result) {
            if (angular.isUndefined(result.sale) || angular.isUndefined(result.sale.customer) || angular.isUndefined(result.sale.customer.customerContacts)) {
              return;
            }
            angular.forEach(result.sale.customer.customerContacts, function (customerContact) {
              var ibanContactData = customerContact.contact.contactData.filter(function (cd) {
                    return cd.contactDataType.code === 'BANK_ACCOUNT_NUMBER';
                  }), ssnContactData = customerContact.contact.contactData.filter(function (cd) {
                    return cd.contactDataType.code === 'SOCIAL_SECURITY_NUMBER';
                  }),
                  companyNumberContactData = customerContact.contact.contactData.filter(function (cd) {
                    return cd.contactDataType.code === 'COMPANY_NUMBER';
                  });
              if (ibanContactData.length > 0) {
                result.sale.customer.ibanContactData = ibanContactData[0];
              }
              if (ssnContactData.length > 0 && angular.isDefined(result.sale.customer.customerType) && result.sale.customer.customerType.code === 'PERSON') {
                result.sale.customer.ssnContactData = 'P / ' + ssnContactData[0].value;
              }
              if (companyNumberContactData.length > 0 && angular.isDefined(result.sale.customer.customerType) && result.sale.customer.customerType.code === 'COMPANY') {
                result.sale.customer.companyNumberContactData = 'O / ' + companyNumberContactData[0].value;
              }
            });
          });
          vm.bankTransfers[page] = data;
          vm.pagination.totalItems = data.count;
          loadingModal.close();
        });
    }

    function loadBankTransfers(reload) {
      LogService.log('DigipolisBankTransfersCtrl::loadBankTransfers() -> Loading Digipolis bank transfers.', 'debug');

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.bankTransfers[vm.pagination.currentPage]) || (reload === true)) {
        vm.loadSingleBankTransfersPage(vm.pagination.currentPage);
      }
    }

    //It is necessary to change the pagination optios when changing payment methods, so that there is no strange behavior,
    // as described in the ticket #46225
    function paymentStatusChanged() {
      setDefaultPaginationOptions();
      filterChanged();
    }

    function filterChanged() {
      vm.loadBankTransfers(true);
    }

    function toggleSort(property) {
      if (angular.isUndefined(vm.sort[property])) {
        return;
      }
      if (property === 'paymentStatus' && vm.filters.paymentStatus.val !== '') {
        return;
      }
      _.each(vm.sort, function (filter, index) {
        if (index !== property) {
          vm.sort[index] = null;
        }
      });

      if (vm.sort[property] === 'ASC') {
        vm.sort[property] = 'DESC';
      } else if (vm.sort[property] === 'DESC') {
        vm.sort[property] = null;
      } else {
        vm.sort[property] = 'ASC';
      }

      return vm.filterChanged();
    }

    function generateBankTransferReport() {
      /*global URI*/
      var path = new URI(Restangular.configuration.baseUrl + '/services/reports/bank_transfer_export'),
          link = null, value = '', queryParams = {}, condition = null;
      if (angular.isUndefined(queryParams['filter[]'])) {
        queryParams['filter[]'] = [];
      }
      queryParams['filter[]'].push('type,EQ banktransfer');
      queryParams['filter[]'].push('sale.id,NOT NULL');
      _.each(vm.filters, function (filter, index) {
        if (angular.isDefined(filter.val) && filter.val) {
          switch (index) {
            case 'beginDate':
              filter.val.setHours(0);
              filter.val.setMinutes(0);
              filter.val.setSeconds(0);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'endDate':
              filter.val.setHours(23);
              filter.val.setMinutes(59);
              filter.val.setSeconds(59);
              value = $filter('date')(filter.val, filter.format);
              break;
            default:
              value = filter.val;
              break;
          }
          condition = '';
          if (UtilService.isNotEmpty(filter.cond)) {
            condition = filter.cond + ' ';
          }
          if (filter.hasOwnProperty('isParam') && filter.isParam) {
            queryParams[index] = value;
          } else {
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            if (angular.isDefined(filter.allowNullIfSet) && filter.allowNullIfSet === true) {
              queryParams['filter[' + index + ']'] = 'OR,' + queryParams['filter[' + index + ']'] + ',' + filter.field + ',NULL';
            }
          }
        }
      });
      _.each(queryParams, function (result, index) {
        var abcd = {};
        abcd[index] = result;
        path.addQuery(abcd);
      });
      /* eslint-disable camelcase */
      path.addQuery({
        access_token: OAuthToken.getAccessToken(),
        format: 'xlsx',
        // limit: 20,
        // offset: 0,
        partial: 1,
        sort: 'createdAt,DESC'
      });
      link = path.toString();
      LogService.log('generate report: ' + link, 'debug');
      // open in new tab
      $window.open(link, '_blank');
    }

    function startDebtorManagement(orderId) {
      return DigipolisOrderFactory.startManagement(orderId).then(function () {
        vm.loadBankTransfers(true);
      });
    }
  }
}());
