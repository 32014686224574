(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:CustomerJournalHistoryCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('CustomerJournalHistoryCtrl', CustomerJournalHistoryCtrl);

  function CustomerJournalHistoryCtrl($rootScope,
                                      $modalInstance,
                                      journal,
                                      JournalFactory,
                                      JournalManagementFactory,
                                      LogService,
                                      UtilService) {
    var vm = this;
    vm.modalOpened = false;
    vm.modalInstance = null;
    vm.journal = journal;
    vm.journalItems = [];
    vm.showSuspensionEditModal = showSuspensionEditModal;
    vm.showSuspensionDeleteModal = showSuspensionDeleteModal;
    vm.cancel = cancelModalInstance;
    vm.loadData = loadData;
    vm.loadData();

    function loadData() {
      JournalManagementFactory.getJournalItems(vm.journal.id)
        .then(function (resultItems) {
          vm.journalItems = resultItems;
        });
    }

    function showSuspensionDeleteModal(suspension) {
      UtilService.showModal({
        templateUrl: 'journal/views/customer.journal.suspension.modal.delete.tpl.html',
        controller: 'SuspensionEditCtrl',
        controllerAs: 'suspensionEditCtrl',
        resolve: {
          journal: function () {
            return vm.journal;
          },
          suspension: function () {
            return suspension;
          }
        }
      }, function () {
        JournalFactory.one(vm.journal.id).get()
          .then(function (result) {
            vm.journal = result;
          });
      }, null, true);
    }

    function showSuspensionEditModal(suspension) {
      UtilService.showModal({
        templateUrl: 'journal/views/customer.journal.suspension.modal.edit.tpl.html',
        controller: 'SuspensionEditCtrl',
        controllerAs: 'suspensionEditCtrl',
        resolve: {
          journal: function () {
            return vm.journal;
          },
          suspension: function () {
            return suspension;
          }
        }
      }, function () {
        JournalFactory.one(vm.journal.id).get()
          .then(function (result) {
            vm.journal = result;
          });
      }, null, true);
    }

    function cancelModalInstance() {
      $rootScope.$broadcast('refreshJournals');
      $modalInstance.dismiss('cancel');
    }
  }
}());
