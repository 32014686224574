(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('ContactDataContextFactory', ContactDataContextFactory);

  function ContactDataContextFactory(Restangular) {
    return Restangular.service('contacts/data/contexts');
  }
}());
