(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accountancy.factory:SaleStatusFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .factory('SaleStatusFactory', SaleStatusFactory);

  function SaleStatusFactory(
    Restangular,
    UtilService,
    _
  ) {
    var statusService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'saleStatus'});
    })
    .service('sales/statuses');

    return {
      getList: function (params) {
        return statusService.getList(params);
      },
      one: function (fragment) {
        return statusService.one(fragment);
      },
      getStatusByCode: function (code) {
        var params = {
          limit: 99
        };

        if (UtilService.isNotEmpty(code)) {
          params['filter[]'] = ['code,' + code.toUpperCase()];

          return this.getList(params)
          .then(function (statuses) {
            return _.first(statuses);
          });
        }
        return Promise.reject('SaleStatusFactory::getStatusByCode() called without code parameter');
      }
    };
  }
}());
