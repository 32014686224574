(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosInstanceAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosInstanceAddEditCtrl', PosInstanceAddEditCtrl);

  function PosInstanceAddEditCtrl(
     PosGroupFactory,
     $modalInstance,
     LogService,
     PaymentEngineFactory,
     posGroup,
     posInstance,
     SettingsService,
     UtilService,
     hwproxy,
     _
   ) {
    var vm = this;
    vm.posGroup = UtilService.isNotEmpty(posGroup) ? posGroup : {};
    vm.posInstance = UtilService.isNotEmpty(posInstance) ? posInstance : {};
    vm.piLabel = '';
    vm.piHwProxy = '';
    vm.piHwProxyPosPrinterType = null;
    vm.yomaniDeviceId = null;
    vm.kioskDeviceId = null;
    vm.ledgerAccount = null;
    vm.ledgerAccountBC = null;
    vm.documentType = null;
    vm.costCenter = null;
    vm.functionArea = null;
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.getPosInstanceRestObject = getPosInstanceRestObject;
    vm.savePosInstance = savePosInstance;
    vm.deletePosInstance = deletePosInstance;
    vm.loadData = loadData;
    vm.posPrinterTypes = _.keys(hwproxy.options.posPrinter.types);
    vm.isPaymentEngineEnabled = PaymentEngineFactory.enabled();
    vm.isHwProxyEnabled = hwproxy.enabled();
    vm.approvalFlowVersion4Enabled = (SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.approvalFlowVersion4 = false;
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3' ||
      SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.enableNetworkTicketPrint = SettingsService.get('pos.enableNetworkTicketPrint', false);
    vm.posPrinterDeviceId = null;
    vm.posInstanceAdministrativePropertyEnabled = SettingsService.get('posInstanceAdministrativePropertyEnabled', false);
    vm.administrative = false;
    vm.loadData();
    function cancelModalInstance() {
      // Dismiss the modal view
      LogService.log('cancel modal instance', 'debug');
      $modalInstance.dismiss('cancel');
    }

    function loadData() {
      if (UtilService.isNotEmpty(vm.posInstance)) {
        if (vm.posInstance.hasOwnProperty('label')) {
          vm.piLabel = vm.posInstance.label;
        }
        if (vm.posInstance.hasOwnProperty('hardwareProxyUrl')) {
          vm.piHwProxy = vm.posInstance.hardwareProxyUrl;
        }
        if (vm.posInstance.hasOwnProperty('hardwareProxyPosPrinterType')) {
          vm.piHwProxyPosPrinterType = vm.posInstance.hardwareProxyPosPrinterType;
        }
        if (vm.posInstance.hasOwnProperty('paymentDeviceIds')) {
          if (vm.posInstance.paymentDeviceIds.hasOwnProperty('yomani')) {
            vm.yomaniDeviceId = vm.posInstance.paymentDeviceIds.yomani;
          }
          if (vm.posInstance.paymentDeviceIds.hasOwnProperty('kiosk')) {
            vm.kioskDeviceId = vm.posInstance.paymentDeviceIds.kiosk;
          }
          if (vm.posInstance.paymentDeviceIds.hasOwnProperty('pos_printer')) {
            vm.posPrinterDeviceId = vm.posInstance.paymentDeviceIds.pos_printer;
          }
        }
        if (vm.posInstance.hasOwnProperty('ledgerAccount')) {
          vm.ledgerAccount = vm.posInstance.ledgerAccount;
        }
        if (vm.posInstance.hasOwnProperty('ledgerAccountBC')) {
          vm.ledgerAccountBC = vm.posInstance.ledgerAccountBC;
        }
        if (vm.posInstance.hasOwnProperty('documentType')) {
          vm.documentType = vm.posInstance.documentType;
        }
        if (vm.posInstance.hasOwnProperty('costCenter')) {
          vm.costCenter = vm.posInstance.costCenter;
        }
        if (vm.posInstance.hasOwnProperty('functionArea')) {
          vm.functionArea = vm.posInstance.functionArea;
        }
        if (vm.posInstance.hasOwnProperty('approvalFlowVersion4')) {
          vm.approvalFlowVersion4 = vm.posInstance.approvalFlowVersion4;
        }
        if (vm.posInstance.hasOwnProperty('administrative')) {
          vm.administrative = vm.posInstance.administrative;
        }
      }
    }

    function getPosInstanceRestObject() {
      var restObject = {
        label: vm.piLabel,
        hardwareProxyUrl: vm.piHwProxy,
        hardwareProxyPosPrinterType: vm.piHwProxyPosPrinterType,
        approvalFlowVersion4: vm.approvalFlowVersion4,
        paymentDeviceIds: {
          yomani: (vm.yomaniDeviceId !== '') ? vm.yomaniDeviceId : null,
          kiosk: (vm.kioskDeviceId !== '') ? vm.kioskDeviceId : null,
          pos_printer: (vm.posPrinterDeviceId !== '') ? vm.posPrinterDeviceId : null
        },
        ledgerAccount: vm.ledgerAccount,
        ledgerAccountBC: vm.ledgerAccountBC,
        documentType: vm.documentType,
        costCenter: vm.costCenter,
        functionArea: vm.functionArea,
        administrative: vm.administrative
      };

      return restObject;
    }

    function deletePosInstance() {
      if (isEdit()) {
        PosGroupFactory.one(vm.posGroup.id).one('instances').one(vm.posInstance.id).remove()
        .then(function () {
          // Close the modal view
          $modalInstance.close();
        });
      } else {
        // Close the modal view
        $modalInstance.close();
      }
    }

    function savePosInstance() {
      var reloadOption = 'reloadListData', posInstanceObject;

      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        posInstanceObject = getPosInstanceRestObject();
        // Patch the level
        PosGroupFactory.one(vm.posGroup.id).one('instances').one(vm.posInstance.id)
        .patch(posInstanceObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        posInstanceObject = getPosInstanceRestObject();
        // Create the level
        PosGroupFactory.one(vm.posGroup.id).post('instances', posInstanceObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function isEdit() {
      return UtilService.isNotEmpty(vm.posInstance);
    }
  }
}());
