(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactDetailCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('ContactDetailCtrl', ContactDetailCtrl);

  function ContactDetailCtrl($scope,
                             ContactFactory,
                             CustomerFactory,
                             ImageService,
                             LogService,
                             PermissionsUtilService,
                             moment,
                             SettingsService,
                             ToastrNotificationService,
                             UtilService) {
    var vm = this;
    vm.contactMobileNumbers = [];
    vm.contactPhoneNumbers = [];
    vm.contactFaxNumbers = [];
    vm.contactWebsites = [];
    vm.contactEmails = [];
    vm.contactComments = [];
    vm.contactSocialSecurityNumbers = [];
    vm.contactBirthDates = [];
    vm.contactGenders = [];
    vm.contactBankAccounts = [];
    vm.customerContact = [];
    vm.getContactDetails = getContactDetails;
    vm.isOrganization = isOrganization;
    vm.isBilling = isBilling;
    vm.isCustomer = isCustomer;
    vm.getContactImage = getContactImage;
    vm.getContactFitnessCredits = getContactFitnessCredits;
    vm.contactHasCredits = false;
    vm.showModalAddEditView = showModalAddEditView;
    vm.createContactData = createContactData;
    vm.showModalCreateUser = showModalCreateUser;
    vm.exportToMywellness = exportToMywellness;
    vm.isUserDefined = isUserDefined;
    vm.showOnlineAccessInfo = !SettingsService.get('customers.hideCreateOnlineAccessButton', false);
    vm.mywellnessIntegrationEnabled = SettingsService.get('customers.enableMywellnessIntegration', false);
    vm.hideGender = SettingsService.get('customer.hideGender', false);
    vm.exportingToMywellness = false;
    vm.hideBirthDateInfo = SettingsService.get('customer.hideBirthDateInfo', false);
    vm.deleteFromMywellness = deleteFromMywellness;

    // init scope function for 'ng-init' from template
    $scope.init = function (customer, contact, needsReload) {
      vm.customer = customer;
      if (contact && !needsReload) {
        LogService.log('getData', 'debug');
        vm.customerContact = contact;
        if (!vm.isCustomer()) {
          vm.getContactImage();
        }
        if (PermissionsUtilService.userHasPermission('access fitness section')) {
          vm.getContactFitnessCredits();
        }
        vm.createContactData();
      } else {
        LogService.log('getDetails', 'debug');
        // Get the complete contactDetails
        vm.getContactDetails(contact);
      }
    };

    function getContactDetails(contact) {
      if (angular.isUndefined(contact) || angular.isUndefined(vm.customer)) {
        return;
      }
      LogService.log(contact, 'debug');
      // Get first customerContact of a customer
      CustomerFactory.one(vm.customer.id)
        .get()
        .then(function (newCustomer) {
          vm.customer = newCustomer;
        });
      CustomerFactory.one(vm.customer.id)
        .one('contacts').one(contact.id)
        .get()
        .then(function (completeCustomerContact) {
          vm.customerContact = completeCustomerContact;

          // Get a specific Contact
          ContactFactory.one(vm.customerContact.contact.id).get().then(function (contactDetails) {
            vm.customerContact.completeContact = contactDetails;
            if (PermissionsUtilService.userHasPermission('access fitness section')) {
              vm.getContactFitnessCredits();
            }
            vm.createContactData();
            // Check if the contact has a photo
            if (vm.customerContact.completeContact.contactPhotos.length > 0) {
              // Get Photo from backend
              ImageService.getImageFromBackend(vm.customerContact.completeContact.contactPhotos[0].photo.id).then(function (image) {
                vm.customerContact.completeContact.photo = image;
              });
              vm.customerContact.completeContact.hasPhoto = true;
            }
          });
        });
    }

    function getContactFitnessCredits() {
      if (!vm.customerContact.completeContact) {
        vm.customerContact.completeContact = {};
      }
      ContactFactory.one(vm.isCustomer() ? vm.customerContact.contact.id : vm.customerContact.id).one('fitness_credits').customGET().then(function (resultCredits) {
        LogService.log('fitness credits' + resultCredits, 'debug');
        if (!angular.isArray(resultCredits)) {
          vm.contactFitnessCredits = resultCredits.credits;
          vm.contactHasCredits = true;
        } else {
          vm.contactHasCredits = false;
        }
      });
    }

    function getContactImage() {
      // Check if the contact has a photo
      if (vm.customerContact.contactPhotos.length > 0) {
        if (!vm.customerContact.completeContact) {
          vm.customerContact.completeContact = {};
        }
        // Get Photo from backend
        ImageService.getImageFromBackend(vm.customerContact.contactPhotos[0].photo.id).then(function (photo) {
          vm.customerContact.completeContact.photo = photo;
          vm.customerContact.completeContact.hasPhoto = true;
        });
      }
    }

    function createContactData() {
      vm.contactMobileNumbers = [];
      vm.contactPhoneNumbers = [];
      vm.contactFaxNumbers = [];
      vm.contactWebsites = [];
      vm.contactEmails = [];
      vm.contactComments = [];
      vm.contactSocialSecurityNumbers = [];
      vm.contactBirthDates = [];
      vm.contactCompanyNumbers = [];
      vm.contactGenders = [];
      vm.contactVATNumbers = [];
      vm.contactBankAccounts = [];
      // Loop the contact data and add them to a list depending on the Data Type
      angular.forEach(vm.isCustomer() ? vm.customerContact.completeContact.contactData : vm.customerContact.contactData, function (contactData) {
        contactData.important = angular.isDefined(contactData.tags) && contactData.tags.includes('important');

        switch (contactData.contactDataType.code) {
          case 'WEBSITE':
            vm.contactWebsites.push(contactData);
            break;

          case 'PHONE_NUMBER':
            vm.contactPhoneNumbers.push(contactData);
            break;

          case 'MOBILE_NUMBER':
            vm.contactMobileNumbers.push(contactData);
            break;

          case 'FAX_NUMBER':
            vm.contactFaxNumbers.push(contactData);
            break;

          case 'COMMENT':
            vm.contactComments.push(contactData);
            break;

          case 'EMAIL':
            vm.contactEmails.push(contactData);
            break;

          case 'SOCIAL_SECURITY_NUMBER':
            vm.contactSocialSecurityNumbers.push(contactData);
            break;

          case 'VAT_NUMBER':
            vm.contactVATNumbers.push(contactData);
            break;

          case 'COMPANY_NUMBER':
            vm.contactCompanyNumbers.push(contactData);
            break;

          case 'BIRTH_DATE':
            contactData.today = moment(contactData.value).format('DD-MM') === moment().format('DD-MM');
            vm.contactBirthDates.push(contactData);
            break;

          case 'GENDER':
            if (!vm.hideGender) {
              vm.contactGenders.push(contactData);
            }
            break;

          case 'BANK_ACCOUNT_NUMBER':
            vm.contactBankAccounts.push(contactData);
            break;

          default:
            LogService.log(contactData.contactDataType.code + ' contact data type not found', 'error');
        }
      });
    }

    function showModalAddEditView() {
      // Open a modal view from specifi c template and controller. A customer object and customerContact object are added to the controller
      UtilService.showModal(
        {
          templateUrl: 'contact/views/contact.modal.addedit.tpl.html',
          controller: 'ContactAddEditCtrl',
          controllerAs: 'contactAddEditCtrl',
          size: 'lg',
          resolve: {
            customer: function () {
              return vm.customer;
            },
            customerContact: function () {
              return vm.customerContact;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler);
    }

    function isOrganization() {
      var returnValue = false;
      if (vm.customerContact) {
        if (vm.isCustomer()) {
          // LogService.log('is customer and check for organization', 'debug');
          if (vm.customerContact.completeContact) {
            returnValue = vm.customerContact.completeContact.contactType.code === 'ORGANIZATION';
          }
        } else {
          // LogService.log('is customer and check for organization', 'debug');
          returnValue = vm.customerContact.contactType.code === 'ORGANIZATION';
        }
      }
      return returnValue;
    }

    function isBilling() {
      var returnValue = false;
      if (vm.isCustomer() && vm.customerContact) {
        returnValue = vm.customerContact.customerContactType.code === 'BILLING';
      }
      return returnValue;
    }

    function modalResultHandler(returnValue) {
      LogService.log('reason of closing: ' + returnValue, 'debug');
      // Reload contact data
      vm.getContactDetails(vm.customerContact);
      if (vm.isCustomer()) {
        LogService.log('start reload Customers', 'debug');
        $scope.$emit('reloadCustomerContactsEmit');
      }
    }

    function modalFailureHandler(returnValue) {
      LogService.log('reason of closing: ' + returnValue, 'debug');
    }

    function isCustomer() {
      if (vm.customer !== null) {
        return true;
      }
      return false;
    }

    function showModalCreateUser() {
      UtilService.showModal(
        {
          templateUrl: 'contact/views/contact.user.add.edit.modal.tpl.html',
          controller: 'ContactUserCtrl',
          controllerAs: 'contactUserCtrl',
          size: 'lg',
          resolve: {
            contact: function () {
              return vm.customerContact;
            },
            customer: function () {
              return vm.customer;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler);
    }

    function isUserDefined() {
      if (angular.isDefined(vm.customerContact.contact)) {
        if (angular.isDefined(vm.customerContact.contact.user)) {
          return angular.isDefined(vm.customerContact.contact.user.id);
        }
      }
      return false;
    }

    function exportToMywellness() {
      if (vm.exportingToMywellness === true) {
        return;
      }
      vm.exportingToMywellness = true;
      CustomerFactory.one(vm.customer.id)
        .one('export-to-mywellness')
        .post()
        .then(function (response) {
          if (angular.isDefined(response) && angular.isDefined(response.mywellnessToken)) {
            vm.customer.mywellnessToken = response.mywellnessToken;
            ToastrNotificationService.showTranslatedNotification('success', 'app.success', 'mywellness.customer_exported', {});
          }
          vm.exportingToMywellness = false;
        }, function () {
          vm.exportingToMywellness = false;
        });
    }

    function deleteFromMywellness() {
      CustomerFactory.one(vm.customer.id)
        .one('delete-from-mywellness')
        .remove()
        .then(function (response) {
          if (response) {
            ToastrNotificationService.showTranslatedNotification('success', 'app.success', 'mywellness.data_deleted', {});
          }
        }, function (error) {
          ToastrNotificationService.showTranslatedNotification('success', 'app.error', error, {});
        });
    }

    $scope.$on('reloadCustomerContactsBroadcast', function (event) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        LogService.log('Reload CONTACT', 'debug');
        vm.getContactDetails(vm.customerContact);
      }
    });
  }
}());
