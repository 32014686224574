(function () {
  'use strict';

  angular
    .module('swimcourse')
    .config(config);

  function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider
      .when('/swimcourse', '/swimcourse/calendar');

    $stateProvider
      .state('swimcourse', {
        url: '/swimcourse',
        abstract: true,
        templateUrl: 'swimcourse/views/swimcourse.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.calendar', {
        url: '/calendar?year&week',
        templateUrl: 'swimcourse/views/calendar/calendar.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.sets', {
        url: '/sets',
        templateUrl: 'swimcourse/views/sets/sets.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.pupils', {
        url: '/pupils',
        templateUrl: 'swimcourse/views/pupils/pupils.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.subscriptions', {
        url: '/subscriptions',
        templateUrl: 'swimcourse/views/subscriptions/subscriptions.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.levels', {
        url: '/levels',
        templateUrl: 'swimcourse/views/levels/levels.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.skills', {
        url: '/skills',
        templateUrl: 'swimcourse/views/skills/skills.tpl.html',
        controller: 'SwimcourseCtrl',
        controllerAs: 'swimcourse'
      })
      .state('swimcourse.series', {
        url: '/series/view?setId&serId',
        templateUrl: 'swimcourse/views/sets/series/series.detail.tpl.html',
        controller: 'SeriesCtrl',
        controllerAs: 'seriesCtrl'
      })
      .state('swimcourse.groups', {
        url: '/session/view?setId&serId&groId&year&week',
        templateUrl: 'swimcourse/views/sets/series/levels/group/group.detail.tpl.html',
        controller: 'SeriesGroupCtrl',
        controllerAs: 'seriesGroupCtrl'
      })
      .state('swimcourse.attendances', {
        url: '/attendances',
        templateUrl: 'swimcourse/views/attendances/attendances.tpl.html',
        controller: 'AttendancesCtrl',
        controllerAs: 'attendancesCtrl'
      });
  }
}());
