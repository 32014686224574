(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.controller:KioskCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('kiosk')
    .controller('KioskCtrl', KioskCtrl);

  function KioskCtrl(
      $filter,
      $modal,
      $q,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      $interval,
      $window,
      hotkeys,
      ContactFactory,
      CurrentUserContextFactory,
      CustomerFactory,
      EventFactory,
      EventParticipantFactory,
      EventParticipantStatusFactory,
      FitnessFactory,
      hwproxy,
      Idle,
      JournalManagementFactory,
      LogService,
      moment,
      OAuth,
      OAuthToken,
      RestUtilService,
      SettingsService,
      ToastrNotificationService,
      UserMeFactory,
      UtilService
    ) {
    var vm = this;

    vm.closeLogoutWarningModal = closeLogoutWarningModal;
    vm.changeStatus = changeStatus;
    vm.dateToSeconds = dateToSeconds;
    vm.displayJournalModal = displayJournalModal;
    vm.isVisible = isVisible;
    vm.loadCustomerData = loadCustomerData;
    vm.loadFitnessCredits = loadFitnessCredits;
    vm.loadFitnessEvents = loadFitnessEvents;
    vm.loadJournalData = loadJournalData;
    vm.login = login;
    vm.logout = logout;
    vm.selectDate = selectDate;
    vm.selectCategory = selectCategory;
    vm.showButton = showButton;
    vm.showCalendar = showCalendar;
    vm.toggleHistory = toggleHistory;
    vm.updateCategories = updateCategories;
    vm.checkJournalTypeByCode = checkJournalTypeByCode;

    vm.contact = {};
    vm.contactHasCredits = false;
    vm.contactFitnessCredits = null;
    vm.calendar = {};
    vm.categories = {};
    vm.customer = {
      id: $stateParams.customerId
    };
    vm.customerContact = {};
    vm.fitnessEvents = [];
    vm.participationHistory = [];
    vm.journalModalInstance = null;
    vm.journals = [];
    vm.journalTypes = [];
    vm.logoutWarningModalInstance = null;
    vm.selectedDate = false;
    vm.selectedCategory = false;
    vm.showingJournalModal = false;
    vm.openMemberCardModal = openMemberCardModal;
    vm.hotKeysEnable = hotKeysEnable;
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };
    vm.calendarIsVisible = false;
    vm.historyIsVisible = false;
    vm.enabled = SettingsService.get('kiosk.enableModule', true);
    vm.enableTurnConsumption = SettingsService.get('kiosk.enableTurnConsumption', false);
    vm.loadCompleteDataForCustomer = loadCompleteDataForCustomer;
    vm.filterEvents = filterEvents;
    vm.filters = {
      startDate: {
        val: new Date(),
        field: 'startsAt',
        cond: 'GTE ',
        format: 'DD/MM/YYYY',
        isOpen: false,
        open: function () {
          vm.filters.startDate.isOpen = true;
        }
      }
    };

    hotKeysEnable();

    function filterEvents() {
      vm.loadCompleteDataForCustomer();
    }
    if (!vm.enabled) {
      $window.location.href = '/';
    }

    RestUtilService.getFullList(EventParticipantStatusFactory, {limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
      vm.participantStatuses = resultStatuses;
    });
    EventFactory.one('participants').getList('types', {limit: 99, sort: 'label,asc'}).then(function (resultTypes) {
      vm.participantTypes = resultTypes;
    });

    if (angular.isUndefined($rootScope.kioskTokenReloadInterval)) {
      $rootScope.kioskTokenReloadInterval = $interval(function () {
        return UserMeFactory.one().get().then(function () {
          LogService.log('Reloaded the token !!!');
        });
      }, 600000);
    }

    // set root UI variables
    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['kiosk']
    };

    LogService.log('customer id: ' + $stateParams.customerId, 'debug');
    if ($state.current.name === 'kiosk.customer') {
      vm.loadCustomerData()
      .then(function () {
        var startDate = vm.filters.startDate.val.toISOString().substr(0, 10);
        vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
          return cc.customerContactType.code === 'USER';
        })[0];

        FitnessFactory.loadCompleteFitnessData(vm.customer, vm.customerContact, true, startDate)
        .then(function (fitnessData) {
          var siteId = CurrentUserContextFactory.getSiteId();
          LogService.log('KioskCtrl::loadFitnessEvents() -> FitnessData:' + fitnessData, 'debug');
          vm.journals = fitnessData.activeJournals.filter(function (journal) {
            return (journal.originSite.id === siteId);
          });
          vm.addJournalsMetadata();
          vm.fitnessEvents = fitnessData.fitnessEventInstances;

          vm.calendar = fitnessData.calendar;
          vm.categories = fitnessData.categories;
          vm.contactFitnessCredits = fitnessData.credits;

          if (vm.contactFitnessCredits > 0) {
            vm.contactHasCredits = true;
          }
        });
      });
    }

    $scope.$on(hwproxy.options.cardReader.cardDataTopic, function () {
      LogService.log('Member Card Scanned', 'debug');
    });

    $scope.$on(hwproxy.options.cardReader.unknownCardEventName, function (event, data) {
      // vm.memberCard = null;
      LogService.log('Unknown card: ' + data, 'debug');
      $state.go('kiosk');
    });

    $scope.$on(hwproxy.options.cardReader.knownCardEventName, function (event, data, memberCard) {
      // vm.memberCard = memberCard;
      LogService.log('membercard: ' + memberCard, 'debug');
      $state.go('kiosk.customer', {customerId: memberCard.customer.id});
    });

    // register automatic logout events
    $scope.$on('IdleStart', function () {
      vm.logoutWarningModalInstance = $modal.open({
        templateUrl: 'kiosk/views/automatic-logout.modal.tpl.html',
        controller: function () {
          this.logoutTimeout = Idle.getTimeout();
        },
        controllerAs: 'modalCtrl',
        windowClass: 'kiosk-automatic-logout-modal'
      });
    });
    $scope.$on('IdleEnd', function () {
      vm.closeLogoutWarningModal();
    });
    $scope.$on('IdleTimeout', function () {
      vm.closeLogoutWarningModal();
      vm.logout();
    });

    // start/stop automatic logout watch
    if (['kiosk.customer', 'kiosk.contact'].indexOf($state.current.name) !== -1) {
      Idle.watch();
    }
    if (['kiosk'].indexOf($state.current.name) !== -1) {
      Idle.unwatch();
    }

    function closeLogoutWarningModal() {
      if (vm.logoutWarningModalInstance) {
        vm.logoutWarningModalInstance.close();
        vm.logoutWarningModalInstance = null;
      }
    }

    function changeStatus(status, event, modalDeferred) {
      var deferred = $q.defer();

      LogService.log('status: ' + status, 'debug');
      LogService.log('Kiosk: event: ' + event, 'debug');
      LogService.log('Kiosk: my participation' + event.participation.myParticipation, 'debug');
      switch (status) {
        case 'confirm':
          event.participation.confirm().then(function () {
            vm.loadFitnessCredits(deferred);
            LogService.log('Kiosk: event participation confirmed', 'debug');
            if (angular.isDefined(modalDeferred)) {
              modalDeferred.resolve();
            }
          }, function () {
            LogService.log('Kiosk: event participation confirmation failed', 'debug');
            deferred.reject();
            if (angular.isDefined(modalDeferred)) {
              modalDeferred.reject();
            }
          });
          break;
        case 'cancel':
        case 'cancel-waitinglist':
          event.participation.cancel().then(function () {
            vm.loadFitnessCredits(deferred);
            LogService.log('Kiosk: event participation cancelled', 'debug');
          }, function () {
            LogService.log('Kiosk: event participation cancel failed', 'debug');
            deferred.reject();
          });
          break;
        case 'subscribe':
          event.participation.subscribe().then(function () {
            vm.loadFitnessCredits(deferred);
            LogService.log('Kiosk: event participation subscription', 'debug');
          }, function () {
            LogService.log('Kiosk: event participation subscription failed', 'debug');
            deferred.reject();
          });
          break;
        default:
          deferred.reject();
      }

      return deferred.promise;
    }

    function dateToSeconds(date) {
      return moment(date).unix();
    }

    function displayJournalModal(selectedEvent) {
      var deferred = $q.defer();

      UtilService.showModal({
        templateUrl: 'kiosk/views/journals.modal.tpl.html',
        controller: 'SelectJournalCtrl',
        controllerAs: 'selectJournalCtrl',
        size: 'lg',
        resolve: {
          journals: function () {
            return vm.journals;
          },
          selectedEvent: function () {
            return selectedEvent;
          }
        }
      },
      function (returnValue) {
        LogService.log('status: ' + returnValue.success, 'debug');
        LogService.log('modal return journal: ' + returnValue.journal, 'debug');
        LogService.log('modal return event: ' + returnValue.event, 'debug');

        if (returnValue.success) {
          vm.loadJournalData();
          vm.changeStatus('confirm', returnValue.event, deferred);
        }
      },
      function () {
        deferred.reject();
      });

      return deferred.promise;
    }

    function isVisible(event) {
      LogService.log('show visibility for: ' + event, 'debug');
      return event.event.eventCategories.filter(function (eventCategory) {
        return eventCategory.code === vm.selectedCategory || vm.selectedCategory === false;
      }).length;
    }

    function loadCustomerData() {
      return CustomerFactory.one(vm.customer.id)
      .get()
      .then(function (customer) {
        vm.customer = customer;

        vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
          return cc.customerContactType.code === 'USER';
        })[0];
        vm.contact = vm.customerContact.contact;
      });
    }

    function loadFitnessCredits(statusDeferred) {
      return ContactFactory.one(vm.contact.id).one('fitness_credits').customGET().then(function (resultCredits) {
        LogService.log('fitness credits' + resultCredits, 'debug');
        if (!angular.isArray(resultCredits)) {
          vm.contactFitnessCredits = resultCredits.credits;
          vm.contactHasCredits = true;
        } else {
          vm.contactHasCredits = false;
        }
      })
      .then(function () {
        if (angular.isDefined(statusDeferred)) {
          statusDeferred.resolve();
        }
      });
    }

    function loadFitnessEvents() {
      return FitnessFactory.loadFitnessEvents(vm.journals, vm.customerContact).then(function (results) {
        LogService.log('FitnessEvents: ' + results, 'debug');
        vm.fitnessEvents = results.events;
        vm.calendar = results.calendar;
        vm.categories = results.categories;
      });
    }

    function loadJournalData() {
      return FitnessFactory.loadJournals(vm.customer, vm.customerContact).then(function (fitnessData) {
        var siteId = CurrentUserContextFactory.getSiteId();
        LogService.log('KioskCtrl::loadJournalData() -> FitnessData:' + fitnessData, 'debug');
        vm.journals = fitnessData.activeJournals.filter(function (journal) {
          return (journal.originSite.id === siteId);
        });
        return vm.addJournalsMetadata();
      });
    }

    vm.addJournalsMetadata = function () {
      vm.journals.forEach(function (journal) {
        journal.swimmingPoolAccess = false;
        if (UtilService.isNotEmpty(journal.journalType.facilityTypes)) {
          journal.journalType.facilityTypes.forEach(function (facilityType) {
            if (facilityType.code === 'ZWEMBAD') {
              journal.swimmingPoolAccess = true;
            }
          });
        }
      });
    };

    vm.consumeJournalTurn = function (journal) {
      var type, journalItemForm;

      if ((journal.credits && journal.credits > 0) || angular.isUndefined(journal.credits)) {
        journalItemForm = {
          facility: CurrentUserContextFactory.getSiteFacilityId()
        };

        if (angular.isDefined(journal.credits) && journal.credits > 0 && journal.credits >= 1) {
          journalItemForm.credits = 1;
          type = 'credit';
        } else if (angular.isUndefined(journal.credits)) {
          type = 'journal';
        } else {
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.entrance-not-allowed',
            ''
          );
          return Promise.reject();
        }

        return JournalManagementFactory.postJournalItem(journal.id, 'consumption', journalItemForm)
          .then(function () {
            hwproxy.sendJournalConsumptionInfoToPrinter(vm.customerContact, journal, type, 1);
            ToastrNotificationService.showTranslatedNotification(
              'success',
              'app.entrance-allowed',
              'app.consumption-registered'
            );
            return vm.loadJournalData().then(function () {
              vm.logout();
            });
          });
      }

      ToastrNotificationService.showTranslatedNotification(
        'error',
        'app.entrance-not-allowed',
        ''
      );
      return Promise.reject();
    };

    function login() {
      var oldToken = OAuthToken.getToken();

      OAuth.getAccessToken(vm.credentials).then(function (response) {
        LogService.log('KIOSK: Form authentication successful' + response, 'debug');

        UserMeFactory.one().get().then(function (user) {
          LogService.log('KIOSK: Fetched user' + user, 'debug');

          CustomerFactory.getList({
            limit: 1,
            'filter[]': ['customerContacts.contact.user.id,' + user.id]
          }).then(function (customers) {
            if (customers.count) {
              LogService.log('KIOSK: Fetched customer linked to user' + customers[0], 'debug');
              $state.go('kiosk.customer', {customerId: customers[0].id});
            } else {
              LogService.log('KIOSK: No customer found linked to user' + user, 'debug');
            }
          }, function (errorResponse) {
            LogService.log('KIOSK: Failed to fetch customer linked to user' + errorResponse, 'debug');
          });

          // Restore kiosk token data
          OAuthToken.setToken(oldToken);
        }, function (errorResponse) {
          LogService.log('KIOSK: Failed to fetch user' + errorResponse, 'debug');

          // Restore kiosk token data
          OAuthToken.setToken(oldToken);
        });
      }, function (errorResponse) {
        LogService.log('KIOSK: Form authentication failed' + errorResponse, 'debug');

        // Restore kiosk token data
        OAuthToken.setToken(oldToken);
      });
    }

    function logout() {
      vm.customerContact = {};
      vm.customer = {};
      vm.contact = {};
      vm.contactHasCredits = false;
      vm.contactFitnessCredits = null;
      $state.go('kiosk');
    }

    function openMemberCardModal() {
      UtilService.showModal({
        templateUrl: 'kiosk/views/kiosk.member-card.modal.tpl.html',
        controller: ['$modalInstance', function ($modalInstance) {
          var that = this;
          this.modal = $modalInstance;
          this.memberCardNumber = null;
          this.customerNotFound = false;
          this.cancel = function () {
            $modalInstance.close();
          };
          this.searchCustomerByMemberCard = function () {
            CustomerFactory.getList({
              limit: 1,
              'filter[]': ['memberCards.label,LIKE ' + this.memberCardNumber]
            }).then(function (customers) {
              if (customers.count) {
                LogService.log('KIOSK: Fetched customer linked to user' + customers[0], 'debug');
                $state.go('kiosk.customer', {customerId: customers[0].id});
                $modalInstance.close();
              } else {
                that.customerNotFound = true;
                LogService.log('KIOSK: No customer found linked to user' + that.memberCardNumber, 'debug');
              }
            }, function (errorResponse) {
              LogService.log('KIOSK: Failed to fetch customer linked to user' + errorResponse, 'debug');
            });
          };
        }],
        controllerAs: 'kioskCtrlSearch',
        size: 'lg'
      });
    }

    // key shortcut 'shift + k' will show the modal window for search a member card
    function hotKeysEnable() {
      hotkeys.bindTo($scope)
        .add({
          combo: 'shift+k',
          description: 'Scan card',
          callback: function () {
            vm.openMemberCardModal();
          }
        });
    }

    function selectDate(date) {
      vm.selectedDate = date;
      vm.showCalendar(false);
    }

    function selectCategory(category) {
      vm.selectedCategory = vm.selectedCategory === category.code ? false : category.code;
    }

    function showButton(action, eventInstance) {
      var isTentative = false,
          isAccepted = false,
          isPending = false,
          participates = angular.isDefined(eventInstance.participation.myParticipation),
          attends = angular.isDefined(eventInstance.participation.myParticipation) &&
            angular.isDefined(eventInstance.participation.myParticipation.eventParticipantStatus.code) &&
            eventInstance.participation.myParticipation.eventParticipantStatus.code === 'ATTENDED';

      if (eventInstance.participation.myParticipation) {
        isTentative = eventInstance.participation.myParticipation.eventParticipantStatus.code === 'TENTATIVE';
        isAccepted = eventInstance.participation.myParticipation.eventParticipantStatus.code === 'ACCEPTED';
        isPending = eventInstance.participation.myParticipation.eventParticipantStatus.code === 'PENDING';
      }
      // LogService.log('action: ' + action, 'debug');
      // LogService.log('event: ' + event, 'debug');
      switch (action) {
        case 'subscribe':
          if (UtilService.isNotEmpty(vm.journals) && (!participates || isTentative) && ((vm.contactFitnessCredits > 0 && !isTentative) || (isTentative && eventInstance.availablePlaces > 0)) && !attends && vm.now.seconds < vm.dateToSeconds(eventInstance.startsAt)) {
            return true;
          }
          break;
        case 'confirm':
          if (participates &&
            isAccepted &&
            vm.now.seconds >= vm.dateToSeconds(eventInstance.confirmationDate) &&
            vm.now.seconds < vm.dateToSeconds(eventInstance.maxConfirmationDate)
          ) {
            return true;
          }
          break;
        case 'cancel':
          if (participates &&
            (isAccepted || isTentative) &&
            vm.now.seconds < vm.dateToSeconds(eventInstance.confirmationDate)
          ) {
            return true;
          }
          break;
        case 'cancel-waitinglist':
          if (participates &&
            isPending
          ) {
            return true;
          }
          break;
        default:
      }
      return false;
    }

    function showCalendar(status) {
      vm.calendarIsVisible = angular.isDefined(status) ? status : true;
    }

    function toggleHistory() {
      var parameters = {
        limit: 99
      };

      vm.historyIsVisible = !vm.historyIsVisible;

      if (vm.historyIsVisible) {
        parameters['filter[]'] = ['contact.id,' + vm.customerContact.id];
        parameters['filter[]'].push('eventParticipantStatus.code,ATTENDED');
        LogService.log(parameters, 'debug');

        RestUtilService.getFullList(EventParticipantFactory, parameters).then(function (participationHistory) {
          LogService.log(participationHistory, 'debug');
          vm.participationHistory = participationHistory;
        });
      } else {
        vm.participationHistory = [];
      }
    }

    function updateCategories(category) {
      category.displayFlag = !category.displayFlag;
      vm.fitnessEvents.forEach(function (event) {
        event.fitnessEvent.eventCategories.forEach(function (eventCategory) {
          if (eventCategory.code === category.code) {
            eventCategory.displayFlag = category.displayFlag;
          }
        });
      });
      return category;
    }

    function checkJournalTypeByCode(code) {
      return code.toLowerCase().includes('groepslessen');
    }

    function loadCompleteDataForCustomer() {
      var startDate = vm.filters.startDate.val.toISOString().substr(0, 10);
      vm.loadCustomerData()
        .then(function () {
          vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
            return cc.customerContactType.code === 'USER';
          })[0];

          FitnessFactory.loadCompleteFitnessData(vm.customer, vm.customerContact, true, startDate)
            .then(function (fitnessData) {
              var siteId = CurrentUserContextFactory.getSiteId();
              LogService.log('KioskCtrl::loadFitnessEvents() -> FitnessData:' + fitnessData, 'debug');
              vm.journals = fitnessData.activeJournals.filter(function (journal) {
                return (journal.originSite.id === siteId);
              });
              vm.addJournalsMetadata();
              vm.fitnessEvents = fitnessData.fitnessEventInstances;

              vm.calendar = fitnessData.calendar;
              vm.categories = fitnessData.categories;
              vm.contactFitnessCredits = fitnessData.credits;

              if (vm.contactFitnessCredits > 0) {
                vm.contactHasCredits = true;
              }
            });
        });
    }
  }
}());
