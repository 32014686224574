(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name documentation.controller:DocumentationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('documentation')
    .controller('DocumentationCtrl', DocumentationCtrl);

  function DocumentationCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    DocumentationFactory,
    $modal,
    $sce,
    SiteFactory
  ) {
    var vm = this,
        generateTabFirst = true;

    vm.site = null;
    vm.sites = [];
    vm.documentation = '';

    vm.tabs = {
      general: {
        label: 'app.documentation.general',
        template: 'documentation/views/documentation.content.tab.tpl.html',
        active: false
      },
      modules: {
        label: 'app.documentation.modules',
        template: 'documentation/views/documentation.content.tab.tpl.html',
        active: false
      },
      faq: {
        label: 'app.documentation.faq',
        template: 'documentation/views/documentation.content.tab.tpl.html',
        active: false
      },
      releases: {
        label: 'app.documentation.releases',
        template: 'documentation/views/documentation.content.tab.tpl.html',
        active: false
      },
      sites: {
        label: 'app.sites',
        template: 'documentation/views/documentation.content.tab.tpl.html',
        active: false
      }
    };
    vm.activeTab = $stateParams.tab && vm.tabs[$stateParams.tab] ? $stateParams.tab : 'general';
    vm.activeModule = angular.isDefined($stateParams.activeModule) ? $stateParams.activeModule : 'modules_kassa';
    vm.filterChanged = filterChanged;
    vm.init = init;

    SiteFactory.getList({
      limit: 99,
      sort: 'label,ASC'
    }).then(function (result) {
      vm.sites = result;
    });

    vm.init();

    if ($stateParams.tab && vm.tabs[$stateParams.tab]) {
      vm.tabs[$stateParams.tab].active = true;
    } else if (generateTabFirst) {
      vm.tabs.general.active = true;
    }

    vm.setActiveTab = function (tab) {
      angular.forEach(vm.tabs, function (value, key) {
        value.active = key === tab;
      });

      $state.go($state.current, {tab: tab}, {reload: false});
    };

    function init() {
      var documentationPart = vm.activeTab, loadingModal, label;

      if (documentationPart === 'sites' && !vm.site) {
        return;
      }

      loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });

      if (documentationPart === 'modules') {
        documentationPart = vm.activeModule;
      }

      if (documentationPart === 'sites' && vm.site) {
        label = vm.site.label.toLowerCase();
        documentationPart += '_' + label.replace(/ /g, '_');
      }

      DocumentationFactory.one(documentationPart).get().then(function (data) {
        vm.documentation = $sce.trustAsHtml(data);
        loadingModal.close();
      }, function (errorMsg) {
        // not found
        LogService.logToConsole(errorMsg);
        loadingModal.close();
      });
    }

    function filterChanged() {
      vm.init();
    }
  }
}());
