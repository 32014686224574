(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:switchUserLocale
   * @restrict AEC
   */
  /* @ngInject */
  angular.module('sportoase').directive('switchUserLocale', function () {
    return {
      restrict: 'AEC',
      template: '<a ng-click="switchUserLocaleCtrl.openModal()">{{ "app.switch_language" | translate | uconlyfirst }}</a>',
      controllerAs: 'switchUserLocaleCtrl',
      controller: [
        'LocaleService',
        'UtilService',
        function (LocaleService, UtilService) {
          this.openModal = function () {
            UtilService.showModal({
              size: 'sm',
              templateUrl: 'auth/views/switch-user-locale.modal.tpl.html',
              controller: ['$scope', 'LOCALES', function ($scope, LOCALES) {
                $scope.locales = LOCALES.locales;
              }]
            }, function (locale) {
              LocaleService.setLocale(locale, true);
            });
          };
        }
      ]
    };
  });
}());
