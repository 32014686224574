(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:JournalTypeAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('JournalTypeAddEditCtrl', JournalTypeAddEditCtrl);

  function JournalTypeAddEditCtrl(
      FacilityTypeFactory,
      JournalTypeFactory,
      ToastrNotificationService,
      $filter,
      $modalInstance,
      journalType
    ) {
    var vm = this;
    vm.journalType = journalType;
    vm.selectedFacilityTypes = [];
    vm.facilityTypes = null;
    vm.journalTypeLabel = '';
    vm.journalTypeMultipleConsumptions = false;
    vm.journalTypeDefaultReservationDays = null;
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.saveJournalType = saveJournalType;
    vm.showSavedNotification = showSavedNotification;
    vm.selectedIds = selectedIds;

    FacilityTypeFactory.getList({limit: 99}).then(function (resultFacilityTypes) {
      vm.facilityTypes = resultFacilityTypes;
      // Check if it is a edit or an add
      if (vm.isEdit()) {
        vm.journalTypeLabel = vm.journalType.label;
        vm.journalTypeFrLabel = vm.journalType.frLabel;
        vm.journalTypeMultipleConsumptions = vm.journalType.multipleConsumptions;
        vm.journalTypeDefaultReservationDays = vm.journalType.defaultReservationDays;
        vm.selectedFacilityTypes = vm.selectedIds(vm.journalType.facilityTypes);
      }
    });

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveJournalType() {
      var journalTypeObject = {
        label: vm.journalTypeLabel,
        frLabel: vm.journalTypeFrLabel,
        code: angular.uppercase(vm.journalTypeLabel).replace(' ', '_'),
        multipleConsumptions: vm.journalTypeMultipleConsumptions,
        defaultReservationDays: vm.journalTypeDefaultReservationDays,
        facilityTypes: vm.selectedFacilityTypes
      };
      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        // Patch the journal type
        JournalTypeFactory.one(vm.journalType.id).patch(journalTypeObject)
        .then(function () {
          vm.showSavedNotification();
          // Close the modal view
          $modalInstance.close('');
        });
      } else {
        // post the journal type
        JournalTypeFactory.post(journalTypeObject)
        .then(function () {
          vm.showSavedNotification();
          // Close the modal view
          $modalInstance.close('');
        });
      }
    }

    function isEdit() {
      // Check for Edit mode
      return vm.journalType !== null;
    }

    function showSavedNotification() {
      // Show notification at successful save
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('journal.journaltype')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('journal.journaltype')
            )
          )
        );
    }

    function selectedIds(array) {
      var ids = [];
      angular.forEach(array, function (arrayObject) {
        ids.push(arrayObject.id);
      });
      return ids;
    }
  }
}());
