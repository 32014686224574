(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name hwproxy.directive:posShiftEnd
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
     <example module="hwproxy">
       <file name="index.html">
        <hw-proxy-connection></hw-proxy-connection>
       </file>
     </example>
   *
   */
  /* @ngInject */
  angular
    .module('navigation')
    .directive('posShiftEnd', posShiftEnd);

  function posShiftEnd() {
    return {
      restrict: 'AEC',
      template: '<a ng-show="posEndShiftModalLaunchCtrl.showButton()" ng-click="posEndShiftModalLaunchCtrl.showEndShiftModal()">{{ "pos.end_session" | translate | uconlyfirst}}</a>',
      controllerAs: 'posEndShiftModalLaunchCtrl',
      controller: [
        '$scope',
        '$element',
        '$state',
        'CurrentUserContextFactory',
        'LogService',
        'SettingsService',
        '$modal',
        '$timeout',
        'UtilService',
        function ($scope, $element, $state, CurrentUserContextFactory, LogService, SettingsService, $modal, $timeout, UtilService) {
          var vm = this;
          vm.posSessionButtonsDisplayOnlyOnPosScreen = SettingsService.get('pos.session.buttonsDisplayOnlyOnPosScreen', false);
          vm.showEndShiftModal = showEndShiftModal;
          vm.showButton = showButton;

          function showButton() {
            if (vm.posSessionButtonsDisplayOnlyOnPosScreen) {
              return $state.current.name === 'pos';
            }
            return true;
          }

          function showEndShiftModal() {
            if (CurrentUserContextFactory.isPosSessionActive() && !$state.is('pos')) {
              $modal.open({
                template:
                '<div class="modal-header">' +
                '  <h4 class="modal-title" id="myModalLabel">{{ \'pos.warning_text.close_session\' | translate }}</h4>' +
                '</div>' +
                '<div class="modal-footer">' +
                '  <button type="submit" class="btn btn-default" ng-click="$close(true)"> ok </button>' +
                '</div>'
              });
              return;
            }
            UtilService.showModal({
              templateUrl: 'pos/views/sessions/pos.endshift.modal.tpl.html',
              controller: 'EndPosShiftCtrl',
              controllerAs: 'endPosShiftCtrl',
              size: 'lg',
              resolve: {
                session: function () {
                  return null;
                }
              }
            },
            function () {
              if ($state.is('pos') || $state.is('dashboard')) {
                $state.go('dashboard', {}, {reload: true});
              }
            });
          }
        }
      ]
    };
  }
}());
