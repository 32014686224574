(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name site.controller:SiteDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('site')
    .controller('SiteDeleteCtrl', SiteDeleteCtrl);

  function SiteDeleteCtrl(
    SiteFactory,
    $modalInstance,
    $filter,
    site,
    reloadType,
    ToastrNotificationService
    ) {
    var vm = this;
    vm.site = site;
    vm.cancel = cancelModalInstance;
    vm.deleteSite = deleteSite;
    vm.showDeleteNotification = showDeleteNotification;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deleteSite() {
      // Delete a specific site
      SiteFactory.one(vm.site.id).remove().then(function () {
        // Close the modal view
        vm.showDeleteNotification();
        $modalInstance.close(reloadType);
      });
    }

    function showDeleteNotification() {
      // Show notification at successful remove
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-removed'),
            $filter('translate')('app.site')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-successfully-removed'),
            $filter('translate')('app.site')
            )
          )
        );
    }
  }
}());
