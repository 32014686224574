(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SeriesGroupCommentEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SeriesGroupCommentEditCtrl', SeriesGroupCommentEditCtrl);

  function SeriesGroupCommentEditCtrl(
    SubscriptionFactory,
    UtilService,
    $cookies,
    $modalInstance,
    SwimCourseSetFactory,
    set,
    series,
    seriesGroup
  ) {
    var vm = this;
    vm.set = set;
    vm.series = series;
    vm.seriesGroup = seriesGroup;
    vm.cancel = cancelModalInstance;
    vm.comment = UtilService.isNotEmpty(seriesGroup.comments) ? seriesGroup.comments : '';
    vm.saveSeriesGroupComment = saveSeriesGroupComment;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveSeriesGroupComment() {
      SwimCourseSetFactory.one(vm.set.id)
      .one('series')
      .one(vm.series.id)
      .one('groups')
      .one(vm.seriesGroup.id).patch({
        comments: vm.comment
      }).then(function (result) {
        $modalInstance.close(result);
      });
    }
  }
}());
