(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.service:SkillService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SkillService', SkillService);

  function SkillService(
    SkillFactory
    ) {
    return {
      getAll: function () {
        return SkillFactory.getList();
      }
    };
  }
}());
