(function () {
  'use strict';

    /**
     * @ngdoc object
     * @name accountancy.controller:StuckSaleCustomerListCtrl
     *
     * @description
     *
     */
    /* @ngInject */
  angular
      .module('accountancy')
      .controller('StuckSaleCustomerListCtrl', StuckSaleCustomerListCtrl);

  function StuckSaleCustomerListCtrl(
      CustomerFactory,
      $modalInstance,
      $q,
    //   customerId,
    //   customerContactTypeId,
      SettingsService,
      UtilService
      ) {
    /* ----- PARAMS/FUNCTIONS ----- */
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.contacts = [];
    vm.customerSearchQuery = '';
    vm.getCustomerContacts = getCustomerContacts;
    vm.getSearchParams = getSearchParams;
    vm.contactsAreLoading = false;
    vm.addCustomerToSale = addCustomerToSale;
    vm.insertCustomerContacts = insertCustomerContacts;
    vm.hideCustomerGroupColumn = SettingsService.get('customers.posSearch.hideGroupColumn', false);
    vm.showContactType = SettingsService.get('customers.posSearch.showContactType', false);
    vm.showSSN = SettingsService.get('customers.posSearch.showSSN', false);
    vm.showVatNumber = SettingsService.get('customers.posSearch.showVatNumber', false);
    vm.showCompanyNumber = SettingsService.get('customers.posSearch.showCompanyNumber', false);
    vm.showLoadMoreButton = false;
    /* ----- END PARAMS/FUNCTIONS ----- */

    function getCustomerContacts(more) {
      vm.contacts = [];

      if (!UtilService.isNotEmpty(vm.customerSearchQuery)) {
        return;
      }

      vm.contactsAreLoading = true;
      vm.showLoadMoreButton = false;

      CustomerFactory.getList(vm.getSearchParams(more))
          .then(function (resultCustomers) {
            vm.insertCustomerContacts(resultCustomers);
            if (resultCustomers.count === 20) {
              vm.showLoadMoreButton = true;
            }
          }, function (errorMsg) {
            console.error('error: ' + errorMsg);
          });
    }

    function getSearchParams(more) {
        // Add Filter param for fulltext search
      var filter = [
        'OR,label,LIKE ' + vm.customerSearchQuery +
            ',customerContacts.contact.label,LIKE ' + vm.customerSearchQuery
      ];
      if (/\d/.test(vm.customerSearchQuery)) {
        filter[0] += ',memberCards.label,LIKE ' + vm.customerSearchQuery;
      }
      return {
        'filter[]': filter,
        limit: (angular.isDefined(more) && more === true) ? 99 : 20,
        hidden: 0,
        partial: 1
      };
    }

    function insertCustomerContacts(customersToProcess) {
      if (!customersToProcess) {
        vm.contactsAreLoading = false;
        return;
      }
        // Loop customers in customersToProcess, ignoring non-billing contacts
      angular.forEach(customersToProcess, function (customer) {
        customer.customerContacts.filter(function (cc) {
          return cc.customerContactType.code !== 'BILLING';
        }).map(function (nonBillingContact) {
          return {
            customer: customer,
            contact: nonBillingContact
          };
        }).forEach(function (item) {
          if (vm.contacts.findIndex(function (x) {
            return x.customer.id === item.customer.id && x.contact.id === item.contact.id;
          }) < 0) {
            UtilService.retrieveContactData(item.contact.contact.contactData);
            vm.contacts.push(item);
          }
        });
      });
      vm.contactsAreLoading = false;
    }

    function addCustomerToSale(combinedCustomerObject) {
        // Close the modal instance and add the contact
      console.log(combinedCustomerObject);
      $modalInstance.close(combinedCustomerObject);
    }

    function cancelModalInstance() {
        // Dismiss the modal instance
      $modalInstance.dismiss('cancel');
    }
  }
}());

