(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.service:FormRendererUtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .service('FormRendererUtilService', FormRendererUtilService);

  function FormRendererUtilService(
    frFieldConfig
  ) {
    var vm = this;
    vm.modalInstanceKeys = [];

    vm.setUpFieldTypes = function () {
      // set up form renderer field types
      frFieldConfig.setType([{
        name: 'form-renderer-text-control',
        templateUrl: '/pos/views/fr_templates/text.html'
      }, {
        name: 'text',
        templateUrl: '/pos/views/fr_templates/text.html'
      }, {
        name: 'form-renderer-checkbox-control',
        templateUrl: '/pos/views/fr_templates/checkbox.html',
        controller: vm.checkboxController
      }, {
        name: 'checkbox',
        templateUrl: '/pos/views/fr_templates/checkbox.html',
        controller: vm.checkboxController
      }, {
        name: 'form-renderer-date-control',
        templateUrl: '/pos/views/fr_templates/date.html'
      }, {
        name: 'date',
        templateUrl: '/pos/views/fr_templates/date.html'
      }, {
        name: 'form-renderer-radio-control',
        templateUrl: '/pos/views/fr_templates/radio.html'
      }, {
        name: 'radio',
        templateUrl: '/pos/views/fr_templates/radio.html'
      }]);
    };

    vm.checkboxController = function ($scope) {
      var optionsWatcher,
          checkModel,
          checkMultiple;

      function initialize() {
        if (!checkModel()) {
          return;
        }
        $scope.field.to.multiple = checkMultiple();
        if ($scope.field.to.multiple && !$scope.field.to.valueOptions) {
          optionsWatcher = $scope.$watch('field.to.valueOptions', function (newValue) {
            if (newValue) {
              initialize();
              optionsWatcher();
            }
          });
          return;
        }
        prepareValueOptions($scope.ngModelInit);
      }

      checkModel = function () {
        if (angular.isUndefined($scope.ngModel)) {
          console.error('The fr-checkbox directive requires a model');
          return false;
        }
        return true;
      };

      checkMultiple = function () {
        return ($scope.field.to.valueOptions && $scope.field.to.valueOptions.length > 0);
      };

      function prepareValueOptions(modelInit) {
        var option = null;

        if (angular.isArray(modelInit)) {
          angular.forEach(modelInit, function (modelOption) {
            option = angular.find($scope.field.to.valueOptions, function (o) {
              return o.key === modelOption;
            });
            option.checked = option ? true : false;
          });
        }
      }

      function resetValueOptions(modelInit) {
        angular.forEach($scope.field.to.valueOptions, function (option) {
          option.checked = false;
        });
        prepareValueOptions(modelInit);
        return true;
      }

      $scope.toggleEditMode = function () {
        $scope.field.state.editMode = !$scope.field.state.editMode;
        // reset the model with the initial value
        $scope.ngModel = angular.cloneDeep($scope.ngModelInit);
        resetValueOptions($scope.ngModelInit);
      };

      $scope.updateModel = function (option) {
        var inModel = [];

        if (angular.isString($scope.ngModel)) {
          $scope.ngModel = [];
        }
        if (angular.isUndefined(option.checked)) {
          console.warn('can\'t update the checkbox list model, options checked = ' + option.checkbox);
        }
        inModel = $scope.ngModel.indexOf(option.key);
        if (option.checked) {
          if (inModel < 0) {
            $scope.ngModel.push(option.key);
          }
        } else if (!option.checked) {
          if (inModel > -1) {
            $scope.ngModel.splice(inModel, 1);
          }
        }
        return $scope.ngModel;
      };
      initialize();
    };
  }
}());
