(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductComponentDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .controller('ProductComponentDeleteCtrl', ProductComponentDeleteCtrl);

  function ProductComponentDeleteCtrl(ProductFactory, $modalInstance, product, productComponent) {
    var vm = this;
    vm.product = product;
    vm.productComponent = productComponent;
    vm.cancel = cancelModalInstance;
    vm.deleteProductComponent = deleteProductComponent;

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function deleteProductComponent(reloadOption) {
      ProductFactory.one(vm.product.id).one('components').one(vm.productComponent.id).remove().then(function () {
        $modalInstance.close(reloadOption);
      });
    }
  }
}());
