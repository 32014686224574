(function () {
  'use strict';

  angular
    .module('product')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('product', {
        url: '/product',
        templateUrl: 'product/views/product.tpl.html',
        controller: 'ProductCtrl',
        controllerAs: 'productCtrl'
      })
      .state('productdetail', {
        url: '/product/detail?id',
        templateUrl: 'product/views/product.detail.tpl.html',
        controller: 'ProductCtrl',
        controllerAs: 'productCtrl'
      })
      .state('product.categories', {
        url: '/categories',
        views: {
          '@': {
            templateUrl: 'product/views/categories/categories.tpl.html'
          },
          'nav@product.categories': {
            templateUrl: 'product/views/categories/categories.nav.tpl.html',
            controller: 'ProductCtrl',
            controllerAs: 'productCtrl'
          },
          'content@product.categories': {
            templateUrl: 'product/views/categories/categories.list.tpl.html',
            controller: [
              'ProductCategoryFactory',
              function (ProductCategoryFactory) {
                var vm = this;
                ProductCategoryFactory.getList({limit: 99}).then(function (resultData) {
                  vm.categories = resultData;
                });
              }
            ],
            controllerAs: 'productCategoriesListCtrl'
          }
        }
      })
      .state('product.categories.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'product/views/categories/categories.add.edit.modal.tpl.html',
            controller: 'ProductCategoriesCtrl',
            controllerAs: 'categoriesCtrl'
          }).result.then(function () {
            $state.go('product.categories', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('product.categories', {}, {reload: true});
            }
          });
        }]
      })
      .state('product.categories.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'product/views/categories/categories.add.edit.modal.tpl.html',
            controller: 'ProductCategoriesCtrl',
            controllerAs: 'categoriesCtrl'
          }).result.then(function () {
            $state.go('product.categories', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('product.categories', {}, {reload: true});
            }
          });
        }]
      })
      .state('product.categories.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'product/views/categories/categories.delete.modal.tpl.html',
            controller: 'ProductCategoriesCtrl',
            controllerAs: 'categoriesCtrl'
          }).result.then(function () {
            $state.go('product.categories', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('product.categories', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
