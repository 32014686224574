(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name consolidationCode.controller:ConsolidationCodeCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('consolidationCode')
    .controller('ConsolidationCodeCtrl', ConsolidationCodeCtrl);

  function ConsolidationCodeCtrl($stateParams, $modalInstance, ConsolidationCodeFactory, ConsolidationCodeTypesFactory) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.item = {};
    vm.consolidationCodeTypes = [];

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;

    function save() {
      var object = {};
      if (vm.isEdit === true) {
        object = {
          consolidationCodeType: vm.item.consolidationCodeType.id,
          comments: vm.item.comments,
          label: vm.item.label,
          frLabel: vm.item.frLabel,
          code: vm.item.code
        };

        ConsolidationCodeFactory.one(vm.item.id).customPUT(object).then(function () {
          vm.close();
        });
      } else {
        ConsolidationCodeFactory.post(vm.item).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      ConsolidationCodeFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'app.edit';
        vm.isEdit = true;
      }
      if (vm.isEdit === true) {
        ConsolidationCodeFactory.one($stateParams.id).get().then(function (item) {
          vm.item = item;
        });
      }
      // ConsolidationCodeFactory.getList({limit: 99}).then(function (resultCodes) {
      //   vm.consolidationCodes = resultCodes;
      // });
      ConsolidationCodeTypesFactory.getList({limit: 99}).then(function (resultTypes) {
        vm.consolidationCodeTypes = resultTypes;
      });
    }

    function isValid() {
      return angular.isDefined(vm.item.label) && vm.item.label !== '' &&
        angular.isDefined(vm.item.consolidationCodeType) && vm.item.consolidationCodeType !== null &&
        angular.isDefined(vm.item.code) && vm.item.code !== '';
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
