(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name journal.factory:JournalActionFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .factory('JournalActionFactory', JournalActionFactory);

  function JournalActionFactory(
    Restangular,
    UtilService,
    LogService,
    _
  ) {
    var actionService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'journalAction'});
    })
    .service('journals/actions');

    return {
      getList: function (params) {
        return actionService.getList(params);
      },
      one: function (fragment) {
        return actionService.one(fragment);
      },
      getJournalActionById: function (actionId) {
        return actionService.one(actionId).get();
      },
      getJournalActionByCode: function (code) {
        var action = null,
            params = {
              limit: 99
            };

        if (UtilService.isEmpty(code)) {
          LogService.log('JournalActionFactory::getJournalActionByCode() -> No code parameter supplied', 'debug');
        }

        if (!angular.isString(code)) {
          LogService.log('JournalActionFactory::getJournalActionByCode() -> Code parameter must be a string', 'debug');
        }

        code = code.toUpperCase();
        params['filter[]'] = 'code,' + code;

        // journalActions will be cached so this should only cause a delay once
        return actionService.getList(params)
        .then(function (journalActions) {
          action = _.find(journalActions, function (item) {
            return item.code === code;
          });
          return action;
        });
      }
    };
  }
}());
