(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ScheduledReportListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('ScheduledReportListCtrl', ScheduledReportListCtrl);

  function ScheduledReportListCtrl(
    _,
    $stateParams,
    ScheduledReportFactory,
    ScheduledReportTypeFactory,
    RestUtilService
  ) {
    var vm = this;
    vm.scheduledReports = [];
    vm.scheduledReportTypes = [];
    vm.loadData = loadData;

    if (angular.isDefined($stateParams.tab) && $stateParams.tab === 'scheduledReports') {
      vm.loadData();
    }

    function loadData() {
      RestUtilService.getFullList(ScheduledReportTypeFactory).then(function (typesResults) {
        angular.forEach(typesResults, function (result) {
          vm.scheduledReportTypes.push({
            type: result.type,
            format: result.format
          });
        });
        RestUtilService.getFullList(ScheduledReportFactory).then(function (results) {
          vm.scheduledReports = results;
          angular.forEach(results, function (result) {
            var foundInTypes = _.find(vm.scheduledReportTypes, {
              type: result.scheduledReportType,
              format: result.parameters.format
            });
            // do not allow edit and delete if the user does not have permissions for given type
            result.allowEditAndDelete = angular.isDefined(foundInTypes);
          });
        });
      });
    }
  }
}());
