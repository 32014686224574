(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name toastrNotification.service:ToastrNotificationService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('toastrNotification')
    .factory('ToastrNotificationService', ToastrNotificationService);

  function ToastrNotificationService(toastr, toastrConfig, $filter) {
    var showNotification, showTranslatedNotification, showTranslatedAndFormattedNotification;
    angular.extend(toastrConfig, {
      closeButton: true,
      progressBar: true,
      timeOut: 5000,
      extended: 5000
    });

    showNotification = function (type, title, msg, options) {
      switch (type) {
        case 'info':
          toastr.info(msg, title, options);
          break;

        case 'success':
          toastr.success(msg, title, options);
          break;

        case 'error':
          toastr.error(msg, title, options);
          break;

        case 'warning':
          toastr.warning(msg, title, options);
          break;

        default:
          toastr.info(msg, title, options);
          break;
      }
    };

    showTranslatedNotification = function (type, title, msg, options) {
      showNotification(type, $filter('uconlyfirst')($filter('translate')(title)), $filter('uconlyfirst')($filter('translate')(msg)), options);
    };

    showTranslatedAndFormattedNotification = function (type, titleFormat, title, msgFormat, msg, options) {
      showNotification(
        type,
        $filter('uconlyfirst')($filter('sprintf')($filter('translate')(titleFormat), $filter('translate')(title))),
        $filter('uconlyfirst')($filter('sprintf')($filter('translate')(msgFormat), $filter('translate')(msg))),
        options
      );
    };

    return {
      showNotification: showNotification,
      showTranslatedNotification: showTranslatedNotification,
      showTranslatedAndFormattedNotification: showTranslatedAndFormattedNotification
    };
  }
}());
