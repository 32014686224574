(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:AddPupilToWaitingListConfirmationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('AddPupilToWaitingListConfirmationCtrl', AddPupilToWaitingListConfirmationCtrl);

  function AddPupilToWaitingListConfirmationCtrl(
    $modalInstance,
    LogService,
    $q,
    SessionService,
    LevelService,
    UtilService,
    SwimCourseManagementFactory,
    SubscriptionFactory,
    moment,
    subscription
  ) {
    var vm = this,
        promises = {};
    vm.year = parseInt(moment().format('YYYY'), 10);
    vm.week = parseInt(moment().format('W'), 10);
    vm.weeks = UtilService.getWeeksForYear(vm.year);
    vm.sets = [];
    vm.selectedCourses = {};
    vm.subscription = subscription;
    vm.subscribe = subscribe;
    if (angular.isArray(vm.subscription)) {
      vm.subscriptionIsArray = true;
    }

    promises = {
      sessions: SessionService.getOverview(vm.year, vm.week, ['swimCourseSeries.swimCourseSeriesGroups.swimCourseGroup.swimCourseLevel.id,' + subscription.swimCourseLevel.id]),
      subscriptions: SubscriptionFactory.getList({'filter[]': ['contact.id,' + vm.subscription.contact.id]})
    };

    vm.loading = true;
    $q.all(promises).then(function (values) {
      angular.forEach(values.sessions.results, function (session) {
        var addSetToPossibilities = true;
        if (angular.isDefined(values.subscriptions)) {
          angular.forEach(values.subscriptions, function (sub) {
            if (sub.swimCourseSet.id === session.swimCourseSeries.swimCourseSet.id) {
              addSetToPossibilities = false;
            }
          });
        }
        if (addSetToPossibilities && vm.sets.indexOf(session.swimCourseSeries.swimCourseSet) === -1) {
          vm.sets.push(session.swimCourseSeries.swimCourseSet);
        }
      });
      vm.loading = false;
    });
    vm.cancel = cancelModalInstance;
    vm.returnAnswer = returnAnswer;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function subscribe() {
      var selectedSets = Object.keys(vm.selectedCourses);

      UtilService.promiseLoop(selectedSets,
        function (currentSet) {
          return SwimCourseManagementFactory.createNewSubscription(
              vm.subscription.contact.id,
              currentSet,
              vm.subscription.swimCourseLevel.id,
              vm.subscription.comments,
              vm.subscription.prioritized,
              null,
              vm.subscription.subscribedAt
          );
        }
      )
      .then(function () {
        $modalInstance.close('cancel');
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function returnAnswer(answer) {
      $modalInstance.close(answer);
    }
  }
}());
