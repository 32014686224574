(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name settings.service:LocaleService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .service('LocaleService', LocaleService);

  function LocaleService($state, $translate, LOCALES, $rootScope, amMoment, tmhDynamicLocale, $document, UserMeFactory) {
    var self = this,
        locales = Object.keys(LOCALES.locales);

    // set moment.js locale on page load
    amMoment.changeLocale($translate.use());

    // on successful applying translations by angular-translate
    $rootScope.$on('$translateChangeSuccess', function (event, data) {
      $document[0].documentElement.setAttribute('lang', data.language);

      // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
      tmhDynamicLocale.set(data.language.toLowerCase().replace(/_/g, '-'));
      // set moment.js locale on locale change
      amMoment.changeLocale(data.language);

      $state.reload();
    });

    self.setLocale = function (locale, persistToBackend) {
      var localeBackend;

      if (locales.indexOf(locale) === -1) {
        console.error('Locale name "' + locale + '" is invalid');
        return;
      }

      if (angular.isDefined(persistToBackend) && persistToBackend === true) {
        localeBackend = self.normalizeLocaleForBackend(locale);
        UserMeFactory.one('locale').one(localeBackend).post().then(function () {
          $translate.use(locale);
        });
      } else {
        $translate.use(locale);
      }
    };

    self.toggleLocale = function () {
      var currentLocale = $translate.use(),
          count = locales.length,
          index = locales.indexOf(currentLocale),
          newIndex = (index + 1 < count) ? index + 1 : 0;

      // asking angular-translate to load and apply proper translations
      $translate.use(locales[newIndex]);
    };

    self.isFrenchEnabled = function () {
      return (locales.indexOf('FR_FR') !== -1);
    };

    self.syncBackendLocale = function (currentBackendLocale) {
      var newBackendLocale = self.normalizeLocaleForBackend($translate.use());

      if (currentBackendLocale !== newBackendLocale) {
        UserMeFactory.one('locale').one(newBackendLocale).post();
      }
    };

    self.normalizeLocaleForBackend = function (locale) {
      var localeBackend = locale.toLowerCase();
      if (localeBackend.indexOf('_') > -1) {
        localeBackend = localeBackend.substring(0, localeBackend.indexOf('_'));
      }
      return localeBackend;
    };
  }
}());
