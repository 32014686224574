(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:MobileContentCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('MobileContentAddEditCtrl', MobileContentAddEditCtrl);

  function MobileContentAddEditCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    MobileCategoryFactory,
    MobileContentFactory,
    MobileLanguageFactory,
    SavingsCampaignFactory,
    mobileContent,
    $modalInstance
  ) {
    var vm = this;
    vm.mobileContent = mobileContent;
    vm.mobileContentCategories = [];
    vm.mobileContentSavingsCampaigns = [];
    vm.mobileContentCategory = null;
    vm.mobileContentLinkedTo = null;
    vm.mobileContentLanguages = [];
    vm.mobileContentLanguage = null;
    vm.mobileContentLabel = '';
    vm.mobileContentStartDate = new Date();
    vm.mobileContentEndDate = new Date();
    vm.mobileContentImageUrl = null;
    vm.mobileContentSummary = null;
    vm.mobileContentHtml = null;
    vm.mobileContentJson = null;
    vm.mobileContentType = null;
    vm.mobileContentVideoUrl = null;
    vm.mobileContentSavingCampaign = null;
    vm.mobileContentTypes = [
      {id: 1, label: 'type 1'},
      {id: 2, label: 'type 2'},
      {id: 3, label: 'type 3'}
    ];
    vm.minDate = new Date();
    vm.openEndDatePicker = openEndDatePicker;
    vm.openStartDatePicker = openStartDatePicker;
    vm.isEdit = isEdit;
    vm.setMobileContentData = setMobileContentData;
    vm.getMobileContentRestObject = getMobileContentRestObject;
    vm.loadCategories = loadCategories;
    vm.loadLanguages = loadLanguages;
    vm.saveMobileContent = saveMobileContent;
    vm.showSavedNotification = showSavedNotification;
    vm.loadSavingsCampaign = loadSavingsCampaign;

    vm.loadCategories();
    vm.loadLanguages();
    vm.loadSavingsCampaign();

    if (vm.isEdit()) {
      vm.setMobileContentData();
    }

    function loadCategories() {
      MobileCategoryFactory.getList({limit: 99}).then(function (result) {
        var categories = {};

        result.forEach(function (category) {
          categories[category.id] = category;
        });

        vm.mobileContentCategories = categories;
      }, function () {
        return {};
      });
    }

    function loadSavingsCampaign() {
      SavingsCampaignFactory.getList({limit: 99}).then(function (result) {
        var savingsCampaigns = {};
        console.log(result);
        result.forEach(function (savingCampaign) {
          savingsCampaigns[savingCampaign.id] = savingCampaign;
        });

        vm.mobileContentSavingsCampaigns = savingsCampaigns;
      }, function () {
        return {};
      });
    }

    function loadLanguages() {
      MobileLanguageFactory.getList({limit: 99}).then(function (result) {
        var languages = {};

        result.forEach(function (language) {
          languages[language.id] = language;
        });

        vm.mobileContentLanguages = languages;
      }, function () {
        return {};
      });
    }

    function openStartDatePicker() {
      vm.startDatePickerIsOpened = true;
    }

    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function getMobileContentRestObject() {
      return {
        label: vm.mobileContentLabel,
        category: vm.mobileContentCategory ? vm.mobileContentCategory.id : null,
        linkedTo: vm.mobileContentLinkedTo ? vm.mobileContentLinkedTo.id : null,
        language: vm.mobileContentLanguage ? vm.mobileContentLanguage.id : null,
        startDate: vm.mobileContentStartDate ? vm.mobileContentStartDate.toISOString().substring(0, 10) : null,
        endDate: vm.mobileContentEndDate ? vm.mobileContentEndDate.toISOString().substring(0, 10) : null,
        image: vm.mobileContentImageUrl,
        summary: vm.mobileContentSummary,
        html: vm.mobileContentHtml,
        json: vm.mobileContentJson,
        type: vm.mobileContentType ? vm.mobileContentType.id : null,
        savingCampaign: vm.mobileContentSavingCampaign ? vm.mobileContentSavingCampaign.id : null,
        video: vm.mobileContentVideoUrl
      };
    }

    function setMobileContentData() {
      var contentType;
      vm.mobileContentLabel = vm.mobileContent.label;
      vm.mobileContentCategory = vm.mobileContent.category;
      vm.mobileContentLinkedTo = vm.mobileContent.linkedTo;
      vm.mobileContentLanguage = vm.mobileContent.language;
      vm.mobileContentStartDate = vm.mobileContent.startDate ? new Date(vm.mobileContent.startDate) : new Date();
      vm.mobileContentEndDate = vm.mobileContent.endDate ? new Date(vm.mobileContent.endDate) : new Date();
      vm.mobileContentImageUrl = vm.mobileContent.image;
      vm.mobileContentSummary = vm.mobileContent.summary;
      vm.mobileContentHtml = vm.mobileContent.html;
      vm.mobileContentJson = vm.mobileContent.json;
      vm.mobileContentSavingCampaign = vm.mobileContent.savingCampaign;
      contentType = vm.mobileContentTypes.filter(function (type) {
        return type.id === vm.mobileContent.type;
      });
      vm.mobileContentType = angular.isDefined(contentType[0]) ? contentType[0] : null;
      vm.mobileContentVideoUrl = vm.mobileContent.video;
    }

    function isEdit() {
      return angular.isDefined(vm.mobileContent) && vm.mobileContent !== null;
    }

    function saveMobileContent() {
      var mobileContentRestObject = vm.getMobileContentRestObject();

      if (vm.isEdit()) {
        MobileContentFactory.one(vm.mobileContent.id).patch(mobileContentRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      } else {
        MobileContentFactory.post(mobileContentRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      }
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.mobile_content')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.mobile_content')
          )
        )
      );
    }
  }
}());
