(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:invoiceEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('InvoiceEditCtrl', invoiceEditCtrl);

  function invoiceEditCtrl($modalInstance,
                           invoice,
                           InvoiceFactory,
                           moment,
                           SaleFactory,
                           ToastrNotificationService,
                           UtilService
  ) {
    var vm = this;
    vm.currentInvoice = invoice;
    vm.invoiceComment = '';
    vm.invoiceDate = null;
    vm.invoiceDueDate = null;
    vm.invoiceSaleDate = null;
    vm.saveInvoice = saveInvoice;
    vm.invoiceDatePickerIsOpen = false;
    vm.invoiceSaleDatePickerIsOpen = false;
    vm.invoiceDueDatePickerIsOpen = false;
    vm.invoiceSaleDateModify = false;
    vm.cancel = cancelModalInstance;
    vm.loadData = loadData;
    vm.isSaleDateEditable = isSaleDateEditable;
    vm.showCustomerListModal = showCustomerListModal;
    vm.sales = [];
    loadData();

    function loadData() {
      var today = new Date(),
          then = new Date(),
          paymentDays;
      InvoiceFactory.one(vm.currentInvoice.id).get().then(function (result) {
        vm.currentInvoice = result;
        // sales
        SaleFactory.getList({'filter[]': 'invoices.id,' + vm.currentInvoice.id, sort: 'createdAt,DESC'})
          .then(function (resultSales) {
            vm.sales = resultSales;
          });
        // comments
        if (UtilService.isNotEmpty(vm.currentInvoice.comments)) {
          vm.invoiceComment = vm.currentInvoice.comments;
        }
        // date
        if (UtilService.isNotEmpty(vm.currentInvoice.date)) {
          vm.invoiceDate = new Date(vm.currentInvoice.date);
        } else {
          vm.invoiceDate = today;
        }
        // due date
        if (UtilService.isNotEmpty(vm.currentInvoice.dueDate)) {
          vm.invoiceDueDate = new Date(vm.currentInvoice.dueDate);
        } else if (UtilService.isNotEmpty(vm.currentInvoice.customer.invoicePaymentDays)) {
          paymentDays = vm.currentInvoice.customer.invoicePaymentDays;
          if (angular.isString(paymentDays)) {
            paymentDays = parseInt(paymentDays, 10);
          }
          vm.invoiceDueDate = new Date(then.setDate(then.getDate() + paymentDays));
        } else {
          vm.invoiceDueDate = new Date(then.setDate(then.getDate() + 30));
        }
      });
    }

    function saveInvoice() {
      var invoiceDate = moment(vm.invoiceDate),
          dateValid = true,
          params = {
            date: vm.invoiceDate.toISOString().substr(0, 10),
            dueDate: vm.invoiceDueDate.toISOString().substr(0, 10),
            comments: vm.invoiceComment,
            validated: true,
            customer: vm.currentInvoice.customer.id
          };

      // date check, sale completedAt dates have to be in the same month as invoice date
      angular.forEach(vm.sales, function (sale) {
        var saleCompletedDate = moment(sale.completedAt);
        if (!invoiceDate.isSame(saleCompletedDate, 'year') || !invoiceDate.isSame(saleCompletedDate, 'month')) {
          dateValid = false;
        }
      });
      if (!dateValid) {
        return ToastrNotificationService.showTranslatedNotification(
          'warning',
          'app.warning',
          'accountancy.invoice_date_check'
        );
      }

      if (vm.isSaleDateEditable() && vm.invoiceSaleDateModify && vm.invoiceSaleDate) {
        params.saleDate = moment(vm.invoiceSaleDate).format('YYYY-MM-DD');
      }

      InvoiceFactory.one(vm.currentInvoice.id).patch(params).then(function () {
        $modalInstance.close('reloadSaleListData');
      });
    }

    function isSaleDateEditable() {
      return vm.currentInvoice.isCreditNote;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function showCustomerListModal() {
      UtilService.showModal({
        templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
        controller: 'HwProxyMemberCardDataCtrl',
        controllerAs: 'HwProxyMemberCardDataCtrl',
        size: 'lg',
        resolve: {
          memberCardData: function () {
            return null;
          },
          customer: function () {
            return null;
          },
          customerContact: function () {
            return null;
          }
        }
      }, function (result) {
        vm.currentInvoice.customer = result;
      }, function (fail) {
        console.log('fail' + fail);
      }, true);
    }
  }
}());
