(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard
   * @description
   *
   */
  angular
    .module('dashboard', [
      'ui.router',
      'chart.js'
    ]);
}());
