(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:SelectPosInstanceCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('SelectPosInstanceCtrl', SelectPosInstanceCtrl);

  function SelectPosInstanceCtrl(
    $modalInstance,
    CurrentPosInstanceFactory,
    PosGroupService,
    SettingsService
   ) {
    var vm = this;
    vm.posInstanceAdministrativePropertyEnabled = SettingsService.get('posInstanceAdministrativePropertyEnabled', false);
    vm.posGroups = [];

    vm.loadData = function () {
      // Load the posgroups and instances
      PosGroupService.getGroupsAndInstances(true)
      .then(function (result) {
        result.forEach(function (group) {
          group.instances.sort(function (a, b) {
            if (a.label < b.label) {
              return -1;
            }
            return (a.label > b.label) ? 1 : 0;
          });
        });
        vm.posGroups = result;
      });
    };

    vm.selectInstance = function (posInstance, posGroup) {
      CurrentPosInstanceFactory.setPosInstanceCookies(posGroup.id, posInstance.id);
      vm.closeModal();
    };

    vm.closeModal = function () {
      $modalInstance.close();
    };

    vm.selectNoInstance = function () {
      CurrentPosInstanceFactory.setNoInstanceCookie();
      vm.closeModal();
    };

    vm.cancelModalInstance = function () {
      $modalInstance.dismiss('cancel');
    };

    vm.loadData();
  }
}());
