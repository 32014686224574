(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerHideCtrl', CustomerHideCtrl);

  function CustomerHideCtrl(CustomerFactory, $modalInstance, customer, $scope) {
    var vm = this;
    vm.customer = customer;
    $scope.anonymize = false;
  }
}());
