(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:EventCategoryCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('event')
    .controller('EventCategoryCtrl', EventCategoryCtrl);

  function EventCategoryCtrl($stateParams, $modalInstance, EventCategoryFactory) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.item = {};
    vm.eventCategories = [];

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;

    function save() {
      var object = {
        label: vm.item.label,
        frLabel: vm.item.frLabel,
        code: vm.item.code
      };

      if (vm.isEdit === true) {
        EventCategoryFactory.one(vm.item.id).customPUT(object).then(function () {
          vm.close();
        });
      } else {
        EventCategoryFactory.post(object).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      EventCategoryFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'app.edit';
        vm.isEdit = true;
      }

      if (vm.isEdit === true) {
        EventCategoryFactory.one($stateParams.id).get().then(function (result) {
          vm.item = result;
        });
      }
    }

    function isValid() {
      return angular.isDefined(vm.item.label) && vm.item.label !== '' && angular.isDefined(vm.item.code) && vm.item.code !== '';
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
