(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:PosSessionListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('AddPosSessionCtrl', AddPosSessionCtrl);

  function AddPosSessionCtrl($modalInstance,
                             $scope,
                             $state,
                             $q,
                             CurrentUserContextFactory,
                             JournalTypeFactory,
                             LogService,
                             PosGroupFactory,
                             PosSessionFactory,
                             SettingsService) {
    var vm = this;

    vm.cancel = cancelModalInstance;
    vm.closeModalInstance = closeModalInstance;
    vm.getRestObject = getRestObject;
    vm.groupChanged = groupChanged;
    vm.save = save;
    vm.loadActivePosSessionInstanceIds = loadActivePosSessionInstanceIds;

    vm.newSession = vm.getRestObject();
    vm.activePosSessionInstanceIds = [];
    vm.posGroup = null;
    vm.posInstance = null;
    vm.posInstanceAdministrativePropertyEnabled = SettingsService.get('posInstanceAdministrativePropertyEnabled', false);

    vm.loadActivePosSessionInstanceIds();

    // form choices
    PosGroupFactory.getList({limit: 99}).then(function (resultTypes) {
      vm.posGroups = resultTypes;
    });
    vm.posInstances = null;

    function groupChanged() {
      vm.posGroup.getList('instances', {limit: 99, sort: 'label,ASC'}).then(function (result) {
        vm.posInstances = [];
        angular.forEach(result, function (posInstance) {
          if (vm.activePosSessionInstanceIds.indexOf(posInstance.id) === -1) {
            if (vm.posInstanceAdministrativePropertyEnabled) {
              if (posInstance.administrative) {
                vm.posInstances.push(posInstance);
              }
            } else {
              vm.posInstances.push(posInstance);
            }
          }
        });
      });
    }

    function getRestObject() {
      var object;
      object = {
        startAmount: 0,
        endAmount: null
      };

      return object;
    }

    function loadActivePosSessionInstanceIds() {
      return PosSessionFactory.getActiveSessions(true)
      .then(function (activePosSessions) {
        angular.forEach(activePosSessions, function (activePosSession) {
          vm.activePosSessionInstanceIds.push(activePosSession.pointOfSaleInstance.id);
        });
      });
    }

    function save() {
      PosGroupFactory.one(vm.posGroup.id)
        .one('instances').one(vm.posInstance.id)
        .post('sessions', vm.getRestObject())
        .then(function (session) {
          LogService.log('Created pos session' + session.id, 'debug');
        }, function () {
          LogService.log('Could not create new pos session', 'debug');
        }).then(function () {
          vm.closeModalInstance();
        });
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
      return 'saved ';
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
