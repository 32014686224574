(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:TicketFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('TicketFactory', TicketFactory);

  function TicketFactory(
    $document,
    $filter,
    $q,
    $window,
    hwproxy,
    ContactFactory,
    CurrentUserContextFactory,
    CustomerFactory,
    LogService,
    PaymentMethodsFactory,
    PosGroupFactory,
    OAuthToken,
    Restangular,
    SaleFactory,
    SettingsService,
    SiteFactory,
    UtilService
  ) {
    var obj = {
      printEndSessionTicket: function (groupId, instanceId, session, countedBills) {
        var headerInfo = {},
            balanceInfo = {
              projectedTotal: 0,
              entered: [],
              balance: []
            },
            paymentMethods = [];

        return new Promise(function (resolve, reject) {
          return PaymentMethodsFactory.getAccountancyList({
            limit: 99,
            'filter[]': [
              'site.id,' + CurrentUserContextFactory.getSiteId()
            ],
            sort: 'weight,asc'
          }).then(function (paymentMethodsResult) {
            angular.forEach(paymentMethodsResult, function (paymentMethod) {
              paymentMethods.push({
                code: paymentMethod.code,
                label: ((paymentMethod.code === 'MANUAL_ELECTRONIC_PAYMENT') ?
                  $filter('translate')('abbr.' + paymentMethod.code.toLowerCase()) :
                  paymentMethod.translatedLabel)
              });
            });

            // do some processing on the balance data to make it more readable
            if (session.hasOwnProperty('balance')) {
              // total as expected from sales
              balanceInfo.projectedTotal = session.balance.projectedAmount.EUR;
              balanceInfo.balanceTotal = session.balance.balanceAmount.EUR;
              balanceInfo.startAmount = session.balance.startAmount.EUR;
              balanceInfo.enteredCashTotal = 0;

              if (!session.balance.hasOwnProperty('balancePerPaymentMethod')) {
                LogService.log('Print EoS-ticket -> Balance object has no balancePerPaymentMethod information!', 'debug');
                reject();
              }
              if (!session.balance.hasOwnProperty('enteredPerPaymentMethod')) {
                LogService.log('Print EoS-ticket -> Balance object has no enteredPerPaymentMethod information!', 'debug');
                reject();
              }

              angular.forEach(paymentMethods, function (pm) {
                if (session.balance.balancePerPaymentMethod.hasOwnProperty(pm.code) &&
                  session.balance.balancePerPaymentMethod[pm.code].hasOwnProperty('EUR')) {
                  balanceInfo.balance.push({
                    label: pm.label,
                    value: session.balance.balancePerPaymentMethod[pm.code].EUR
                  });
                } else {
                  LogService.log('Print EoS-ticket -> no EUR balance found for ' + pm.code, 'debug');
                }

                if (session.balance.enteredPerPaymentMethod.hasOwnProperty(pm.code) &&
                  session.balance.enteredPerPaymentMethod[pm.code].hasOwnProperty('EUR')) {
                  balanceInfo.entered.push({
                    label: pm.label,
                    value: session.balance.enteredPerPaymentMethod[pm.code].EUR
                  });

                  if (pm.code === 'CASH') {
                    balanceInfo.enteredCashTotal = session.balance.enteredPerPaymentMethod[pm.code].EUR;
                  }
                } else {
                  LogService.log('Print EoS-ticket -> no EUR entered amount found for ' + pm.code + ' - assuming 0', 'debug');
                  balanceInfo.entered.push({
                    label: pm.label,
                    value: 0
                  });
                }
              });
            } else {
              LogService.log('Print EoS-ticket -> You must attach the balance information to the session before calling printEndSessionTicket!', 'debug');
              reject();
            }

            // collect header info
            headerInfo.clerk = session.userContext.user.username;
            headerInfo.date = $filter('amDateFormat')(new Date(), 'DD/MM/YYYY HH:mm:ss');
            headerInfo.startDate = $filter('amDateFormat')(session.createdAt, 'DD/MM/YYYY HH:mm:ss');

            if (UtilService.isNotEmpty(session.endAmount)) {
              headerInfo.endDate = session.endAmount;
            }

            function headerFallBack() {
              return PosGroupFactory.one(groupId)
              .one('instances').one(instanceId)
              .get()
              .then(function (posInstance) {
                headerInfo.instanceLabel = posInstance.label;
                hwproxy.sendSessionCloseInfoToPrinter(headerInfo, balanceInfo, countedBills);
              })
              .then(function () {
                resolve();
              });
            }

            // grab location
            PosGroupFactory.one(groupId)
            .get()
            .then(function (group) {
              if (group.hasOwnProperty('site') && group.site.hasOwnProperty('id')) {
                SiteFactory.one(group.site.id)
                .get()
                .then(function (siteDetail) {
                  if (UtilService.isNotEmpty(siteDetail.contact)) {
                    ContactFactory.one(siteDetail.contact.id)
                    .get()
                    .then(function (contact) {
                      if (UtilService.isNotEmpty(contact.contactLocations)) {
                        headerInfo.address = contact.contactLocations[0].location;
                      }

                      angular.forEach(contact.contactData, function (contactData) {
                        if (contactData.code === 'PHONE_NUMBER') {
                          headerInfo.tel = contactData.value;
                        }
                      });

                      headerFallBack();
                    });
                  } else {
                    headerFallBack();
                  }
                });
              } else {
                headerFallBack();
              }
            });
          }, function () {
            reject();
          });
        });
      },

      printTicket: function (sale, duplicate) {
        var billCustomer = null,
            billItems = [],
            i,
            billItemObject = null,
            component = null,
            currentSale,
            billItemCounter = 0,
            sendTicketToHwProxy;

        if (SettingsService.get('pos.enableNetworkTicketPrint', false)) {
          return obj.printSaleTicketViaNetwork(sale.id, duplicate);
        }

        if (SettingsService.get('pos.downloadPdfRecipe', false)) {
          return obj.downloadPdfRecipe(sale.id, true);
        }

        sendTicketToHwProxy = function () {
          hwproxy.sendSaleInfoToPrinter({
            sale: currentSale,
            saleItems: billItems,
            payments: currentSale.payments.filter(function (pm) {
              return pm.paymentMethod.code !== 'DISCOUNT' && pm.amount > 0;
            }),
            customer: billCustomer,
            paidTotal: calculateTotal(currentSale.payments.filter(function (pm) {
              return pm.paymentMethod.code !== 'DISCOUNT' && pm.amount > 0;
            })),
            changeTotal: calculateTotal(currentSale.payments.filter(function (pm) {
              return pm.paymentMethod.code !== 'DISCOUNT' && pm.amount < 0;
            })),
            amountToPay: calculateTotal(currentSale.payments.filter(function (pm) {
              return pm.paymentMethod.code !== 'DISCOUNT';
            })),
            duplicate: duplicate
          });
        };

        LogService.log('printing ticket for sale ', 'debug');
        LogService.log(sale, 'debug');
        LogService.log('duplicate: ' + duplicate, 'debug');

        return SaleFactory.one(sale.id).get().then(function (returnedSale) {
          currentSale = returnedSale;
          function getBillItems() {
            return new Promise(function (resolve) {
              // !! this will be exposed by default later, so this call will become uneccessary
              angular.forEach(currentSale.saleItems, function (saleItem) {
                ++billItemCounter;
                SaleFactory.one(currentSale.id).one('items').one(saleItem.id).get()
                .then(function (saleItemDetail) {
                  LogService.log('saleItemParams' + saleItemDetail, 'debug');
                  billItemObject = {
                    saleItemId: saleItemDetail.id,
                    dirty: false,
                    amount: saleItemDetail.quantity,
                    product: saleItemDetail.product,
                    discount: saleItemDetail.parameters.discount || false,
                    discountType: saleItemDetail.parameters.discountType || null,
                    discountAmount: saleItemDetail.parameters.discountAmount || null,
                    discountPercentage: saleItemDetail.parameters.discountPercentage || null,
                    productExclPrice: saleItemDetail.price || saleItemDetail.price === 0 ? saleItemDetail.price : null,
                    productDescription: saleItemDetail.comments ? saleItemDetail.comments : '',
                    descriptionRequired: saleItemDetail.parameters.descriptionRequired || false,
                    isGroupProduct: saleItemDetail.parameters.isGroupProduct ? true : false,
                    isSubSaleItem: saleItemDetail.parameters.parentSaleItem ? true : false,
                    parentSaleItem: saleItemDetail.parameters.parentSaleItem ? saleItemDetail.parameters.parentSaleItem : null
                  };
                  billItemObject.startDate = angular.isUndefined(saleItemDetail.parameters.startDate) ? null : new Date(saleItemDetail.parameters.startDate);

                  // fill in the price details
                  for (i = 0; i < saleItemDetail.product.productComponents.length; ++i) {
                    component = saleItemDetail.product.productComponents[i];
                    if (component.type === 'price') {
                      if (billItemObject.productExclPrice === null) {
                        billItemObject.productExclPrice = component.price;
                      }
                      billItemObject.product.price = component;
                      billItemObject.vatPrice = billItemObject.productExclPrice * (component.vatRate.percentage / 100);
                      billItemObject.vat = component.vatRate.percentage;
                      billItemObject.productPrice = billItemObject.productExclPrice + billItemObject.vatPrice;
                      notifyBillItemsAdded();
                      break;
                    }
                  }
                  billItems.push(billItemObject);
                }, function () {
                  notifyBillItemsAdded();
                });
              });
              function notifyBillItemsAdded() {
                --billItemCounter;
                if (billItemCounter <= 0) {
                  LogService.log('notifyBillItemsAdded resolved', 'debug');
                  resolve('');
                }
              }
            });
          }
          function getBillCustomer() {
            return new Promise(function (resolve) {
              if (currentSale.hasOwnProperty('customer') || currentSale.hasOwnProperty('customerContact')) {
                if (billCustomer === null) {
                  billCustomer = {};
                }
                billCustomer.customer = currentSale.hasOwnProperty('customer') ? currentSale.customer : null;

                // contact needs to be retrieved seperately
                if (currentSale.hasOwnProperty('customerContact')) {
                  CustomerFactory.one(currentSale.customer.id).get().then(function (customer) {
                    if (customer.customerContacts.length > 0) {
                      for (i = 0; i < customer.customerContacts.length; ++i) {
                        if (customer.customerContacts[i].id === currentSale.customerContact.id) {
                          billCustomer.contact = customer.customerContacts[i];
                          resolve('');
                          break;
                        }
                      }
                    }
                  });
                } else {
                  resolve('');
                }
              } else {
                resolve('');
              }
            });
          }
          getBillItems().then(function () {
            getBillCustomer().then(function () {
              LogService.log('Send ticket to hwproxy', 'debug');
              sendTicketToHwProxy();
            });
          });
        });

        function calculateTotal(payments) {
          var totalPaid = 0;
          angular.forEach(payments, function (payment) {
            totalPaid += payment.amount;
          });
          return totalPaid;
        }
      },

      downloadPdfRecipe: function (saleId, duplicate) {
        var link = Restangular.configuration.baseUrl + '/sales/' + saleId +
            '/recipe-frame?access_token=' +
            OAuthToken.getAccessToken() + (duplicate ? '&duplicate=1' : '') +
            (SettingsService.get('pos.pdfRecipeNoAutoPrint', false) ? '&noprint=1' : '');

        //Open it in a new window on firefox - PDF.js lives on a separate domain
        //there, so we can't just call window.print() on an iframe there.
        // Also do it if auto-print is turned off
        if ($window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
          SettingsService.get('pos.pdfRecipeNoAutoPrint', false)
        ) {
          $window.open(link);
          return $q.resolve();
        }

        //Render inline and directly print in other browsers, then remove the
        //iframe after receiving a postMessage() from the iframe.
        return new $q(function (resolve) {
          var $iframe = angular.element('<iframe>').attr('src', link).hide();
          $iframe.bind('load', function () {
            resolve($iframe);
          });
          $document[0].body.append($iframe[0]);
        }).then(function ($iframe) {
          $window.addEventListener('message', function removeIframe(e) {
            if (e.data === 'ticket-pdf-print') {
              $iframe.remove();
              $window.removeEventListener('message', removeIframe);
            }
          });
        });
      },

      printSaleTicketViaNetwork: function (saleId, duplicate) {
        Restangular.one('sales', saleId).one('print-ticket').get({duplicate: duplicate});
        return $q.resolve();
      }
    };
    return obj;
  }
}());
