(function () {
  'use strict';

  /**
   * @ngdoc factory
   * @name hwproxy.factory:PaymentEngineFactory
   *
   * @description Handles communication with payment engine via socket.io
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PaymentEngineFactory', PaymentEngineFactory);

  function PaymentEngineFactory(
    $document,
    $timeout,
    $translate,
    $q,
    $window,
    LogService,
    SaleFactory,
    SettingsService,
    ToastrNotificationService
  ) {
    var io = $window.io;

    return {
      engines: [
        {
          key: 'yomani',
          url: SettingsService.get('payment.yomaniPaymentServiceURL', 'ws://localhost:3001'),
          paymentMethodCode: 'ELECTRONIC_PAYMENT',
          socket: null,
          connected: null,
          deviceStatus: null,
          ctep: SettingsService.get('payment.yomaniCTEPProtocol', false)
        },
        {
          key: 'kiosk',
          url: SettingsService.get('payment.kioskPaymentServiceURL', 'ws://localhost:3002'),
          paymentMethodCode: 'PAYMENT_KIOSK',
          socket: null,
          connected: null,
          deviceStatus: null
        }
      ],
      pendingPayments: [],

      enabled: function () {
        return (SettingsService.get('payment.yomaniPaymentServiceURL') || SettingsService.get('payment.kioskPaymentServiceURL'));
      },

      getEngine: function (engineKey) {
        var engine;

        angular.forEach(this.engines, function (tmp) {
          if (tmp.key === engineKey) {
            engine = tmp;
          }
        });

        return engine;
      },

      getEngineByPaymentMethod: function (code) {
        var engine = null;

        angular.forEach(this.engines, function (tmp) {
          if (tmp.paymentMethodCode === code) {
            engine = tmp;
          }
        });

        return engine;
      },

      connect: function (posInstance) {
        var that = this;

        angular.forEach(this.engines, function (engine) {
          if (that.isConnected(engine.key)) {
            that.disconnect(engine.key);
          }
        });

        angular.forEach(that.engines, function (engine) {
          LogService.log('PaymentEngine: Connecting to ' + engine.key + ' payment service on ' + engine.url, 'info');
          engine.socket = io.connect(engine.url, { transports: ['websocket'] });
          engine.socket.on('connect', function () {
            LogService.log('PaymentEngine: Connected to ' + engine.key + ' payment service on ' + engine.url, 'info');
            engine.socket.emit('authenticate', {
              socketType: 'Frontend'
            }, function () {
              LogService.log('PaymentEngine: Authenticated ' + engine.key + ' payment service');

              engine.socket.on('message', function (messageKey, message) {
                LogService.log('PaymentEngine: Received message with key ' + messageKey + ' via ' + engine.key + ' payment service', 'info');
                LogService.log(message, 'info');
                LogService.logToConsole(message);
                console.log(message);
                that.handleMessage(engine.key, messageKey, message);
              });

              $timeout(function () {
                engine.connected = true;
              });
              that.checkDeviceStatus(engine.key, posInstance.paymentDeviceIds[engine.key]);
            });
          });

          engine.socket.on('connect_error', function (error) {
            $timeout(function () {
              engine.connected = false;
            });
            LogService.log('PaymentEngine: Connection error for ' + engine.key);
            LogService.log(error);
            LogService.logToConsole(error);
          });

          engine.socket.on('disconnect', function () {
            engine.connected = null;
            LogService.log('PaymentEngine: Disconnecting from ' + engine.key + ' payment service');
          });

          // re-subscribe for pending payments messages
          engine.socket.on('reconnect', function () {
            LogService.log('PaymentEngine: Reconnected to ' + engine.key + ' payment service');
            angular.forEach(that.pendingPayments, function (payment) {
              if (payment.engineKey === engine.key) {
                LogService.log('PaymentEngine: Re-subscribing to messages for sale ' + payment.saleReference + ' on ' + engine.key + ' payment service');
                that.getEngine(engine.key).socket.emit('subscribe', payment.saleReference);
              }
            });
          });
        });
      },

      isConnected: function (engineKey) {
        return this.getEngine(engineKey).connected;
      },

      isDeviceAvailable: function (engineKey) {
        return this.getEngine(engineKey).deviceStatus === 'Available';
      },

      disconnect: function (engineKey) {
        if (angular.isUndefined(engineKey)) {
          angular.forEach(this.engines, function (engine) {
            if (engine.socket !== null) {
              engine.socket.disconnect();
              engine.socket = null;
            }
            engine.connected = null;
          });
        } else {
          if (this.getEngine(engineKey).socket !== null) {
            this.getEngine(engineKey).socket.disconnect();
            this.getEngine(engineKey).socket = null;
          }
          this.getEngine(engineKey).connected = null;
        }
      },

      checkDeviceStatus: function (engineKey, deviceId) {
        var that = this;

        LogService.log('PaymentEngine: Checking device ' + deviceId + ' status on ' + engineKey + ' payment service');
        this.getEngine(engineKey).socket.emit('devices', [deviceId], function (statuses) {
          that.getEngine(engineKey).deviceStatus = statuses[deviceId];

          LogService.log('PaymentEngine: Subscribing to messages for device ' + deviceId + ' on ' + engineKey + ' payment service');
          that.getEngine(engineKey).socket.emit('subscribe', 'device-' + deviceId);
        });
      },

      subscribeToPayment: function (engineKey, saleId, saleReference, amount, currency) {
        var that = this;

        saleReference = parseInt(saleReference, 10);

        LogService.log('PaymentEngine: Subscribing to messages for sale ' + saleReference + ' on ' + engineKey + ' payment service');
        return new Promise(function (resolve, reject) {
          if (that.isConnected(engineKey)) {
            that.pendingPayments[saleReference] = {resolve: resolve, reject: reject, engineKey: engineKey, saleReference: saleReference, saleId: saleId};
            ToastrNotificationService.showNotification('info', 'Payment info', $translate.instant('hwproxy.waiting_for_payment', {
              saleReference: saleReference,
              engineKey: engineKey
            }));
            that.getEngine(engineKey).socket.emit('subscribe', saleReference);

            // request the payment via backend
            LogService.log('PaymentEngine: Requesting payment via backend using payment flow version ' + SettingsService.get('payment.flowVersion', '1'));
            return SaleFactory.one(saleId).one('payment-engine-request').customPOST({
              amount: amount,
              currency: currency,
              reference: saleReference,
              engineKey: engineKey
            }).then(function (response) {
              LogService.log('PaymentEngine: Backend responded with:');
              LogService.log(response);
              LogService.logToConsole(response);
              console.log(response);
            }, function (error) {
              LogService.logToConsole(error);
              reject(error);
              that.getEngine(engineKey).socket.emit('unsubscribe', saleReference);
              delete that.pendingPayments[saleReference];
            });
          }
          LogService.log('PaymentEngine: ' + engineKey + ' payment service is not connected');
          reject('error');
        });
      },

      handleMessage: function (engineKey, messageKey, message) {
        var saleReference,
            rawMessage,
            that = this;

        if (messageKey.indexOf('device-') !== -1) {
          $timeout(function () {
            that.getEngine(engineKey).deviceStatus = message.status;
          });
        } else {
          saleReference = parseInt(messageKey, 10);
          rawMessage = angular.fromJson(message.raw);

          if (angular.isUndefined(this.pendingPayments[saleReference])) {
            LogService.log('PaymentEngine: There is no pending payment for sale ' + saleReference);
            return;
          }

          switch (message.type) {
            case 'pending':
              // do not download barcode pdf if kiosk version is 2, see #48062
              if (engineKey === 'kiosk') {
                if (SettingsService.get('payment.kioskVersion', '1') !== '2') {
                  this.downloadBarcodePdf(rawMessage.id);
                }
                this.pendingPayments[saleReference].paymentId = rawMessage.id;
              }
              ToastrNotificationService.showNotification('info', 'Payment info', $translate.instant('hwproxy.payment_issued', {
                saleReference: saleReference,
                engineKey: engineKey
              }));
              break;
            case 'accepted':
            case 'overpaid':
              LogService.log('PaymentEngine: Accepting payment for sale ' + saleReference + ' with status ' + message.type);
              ToastrNotificationService.showNotification('success', 'Payment info', $translate.instant('hwproxy.payment_accepted', {
                saleReference: saleReference,
                engineKey: engineKey,
                status: message.type
              }));
              this.pendingPayments[saleReference].resolve(message);
              this.getEngine(engineKey).socket.emit('unsubscribe', saleReference);
              delete this.pendingPayments[saleReference];
              break;
            case 'declined':
            case 'invalid':
            case 'timeout':
            case 'cancelled':
            case 'canceled':
              LogService.log('PaymentEngine: Rejecting payment for sale ' + saleReference + ' with status ' + message.type);
              ToastrNotificationService.showNotification('error', 'Payment info', $translate.instant('hwproxy.payment_cancelled', {
                saleReference: saleReference,
                engineKey: engineKey,
                status: message.type
              }));
              if (engineKey === 'yomani') {
                // set status canceled for sale
                SaleFactory.one(this.pendingPayments[saleReference].saleId).one('sale-cancel-status').customPOST().then(function (response) {
                  LogService.log('PaymentEngine: Backend responded with:');
                  LogService.log(response);
                  LogService.logToConsole(response);
                  console.log(response);
                  return Promise.resolve();
                });
              }
              this.pendingPayments[saleReference].reject(message);
              this.getEngine(engineKey).socket.emit('unsubscribe', saleReference);
              delete this.pendingPayments[saleReference];
              break;
            default:
              // nothing here, but linter complains
              break;
          }
        }
      },

      cancelPayment: function (saleReference) {
        LogService.log('PaymentEngine: cancelling payment for sale ' + saleReference);

        saleReference = parseInt(saleReference, 10);

        if (angular.isUndefined(this.pendingPayments[saleReference])) {
          ToastrNotificationService.showNotification('error', 'Payment info', $translate.instant('hwproxy.no_pending_payment', {
            saleReference: saleReference
          }));
          return Promise.reject();
        }

        if (this.pendingPayments[saleReference].engineKey !== 'kiosk') {
          ToastrNotificationService.showNotification('error', 'Payment info', 'Cancelling of kiosk payment only is supported.');
          return Promise.reject();
        }

        if (angular.isUndefined(this.pendingPayments[saleReference].paymentId)) {
          ToastrNotificationService.showNotification('error', 'Payment info', 'PaymentId not found, cannot cancel payment.');
          return Promise.reject();
        }

        // request payment cancellation via backend
        return SaleFactory.one(this.pendingPayments[saleReference].saleId).one('payment-cancel-request').customPOST({
          engineKey: this.pendingPayments[saleReference].engineKey,
          paymentId: this.pendingPayments[saleReference].paymentId
        }).then(function (response) {
          LogService.log('PaymentEngine: Backend responded with:');
          LogService.log(response);
          LogService.logToConsole(response);
          console.log(response);
          return Promise.resolve();
        }, function (error) {
          LogService.logToConsole(error);
          return Promise.reject(error);
        });
      },

      downloadBarcodePdf: function (paymentId) {
        var baseUrl = this.getEngine('kiosk').url.replace('ws://', 'http://').replace('wss://', 'https://'),
            link = baseUrl + '/payments/' + paymentId + '/barcode/iframe' +
            (SettingsService.get('pos.pdfRecipeNoAutoPrint', false) ? '?noprint=1' : '');

        //Open it in a new window on firefox - PDF.js lives on a separate domain
        //there, so we can't just call window.print() on an iframe there.
        // Also do it if auto-print is turned off
        if ($window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
          SettingsService.get('pos.pdfRecipeNoAutoPrint', false)
        ) {
          $window.open(link);
          return $q.resolve();
        }

        //Render inline and directly print in other browsers, then remove the
        //iframe after receiving a postMessage() from the iframe.
        return new $q(function (resolve) {
          var $iframe = angular.element('<iframe>').attr('src', link).hide();
          $iframe.bind('load', function () {
            resolve($iframe);
          });
          $document[0].body.append($iframe[0]);
        }).then(function ($iframe) {
          $window.addEventListener('message', function removeIframe(e) {
            if (e.data === 'ticket-pdf-print') {
              $iframe.remove();
              $window.removeEventListener('message', removeIframe);
            }
          });
        });
      }
    };
  }
}());
