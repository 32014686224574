(function () {
  'use strict';

  angular
    .module('posSettings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('pos-settings', {
        url: '/settings/pos',
        views: {
          '': {
            templateUrl: 'pos-settings/views/pos-settings.tpl.html'
          },
          '@pos-settings': {
            template: '<a class="btn btn-lg btn-default" ui-sref="pos-settings.screens">{{ "app.settings.pos.screens" | translate | uconlyfirst }}</a>'
          }
        }
      })
      .state('pos-settings.groups', {
        url: '/groups',
        controller: 'PosGroupListCtrl',
        controllerAs: 'posGroupListCtrl',
        templateUrl: 'pos-settings/views/pos-settings.groups.tpl.html'
      })
      .state('pos-settings.screens', {
        url: '/screens',
        views: {
          'nav@pos-settings': {
            templateUrl: 'pos-settings/views/pos-settings.nav.tpl.html'
          },
          '@pos-settings': {
            templateUrl: 'pos-settings/views/pos-settings.screens.tpl.html',
            controller: 'PosSettingsCtrl',
            controllerAs: 'posSettingsCtrl'
          }
        }
      })
      .state('pos-settings.payments', {
        url: '/payments',
        views: {
          '@pos-settings': {
            templateUrl: 'pos-settings/views/pos-settings.payments.tpl.html',
            controller: 'PosPaymentSettingsCtrl',
            controllerAs: 'posPaymentSettingsCtrl'
          }
        }
      })
      .state('pos-settings.screens.new', {
        url: '/new',
        onEnter: ['$state', '$modal', function ($state, $modal) {
          $modal.open({
            templateUrl: 'pos-settings/views/pos-settings.modal.new.tpl.html',
            controller: 'PosScreenNewCtrl',
            controllerAs: 'posScreenNewCtrl'
          }).result.then(function (data) {
            $state.go('pos-settings.screens.detail.edit', {sId: data.sId});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^');
            }
          });
        }]
      })
      .state('pos-settings.screens.detail', {
        url: '/:sId',
        views: {
          '@pos-settings': {
            templateUrl: 'pos-settings/views/pos-settings.screens.detail.tpl.html',
            resolve: {
              screen: function ($stateParams, PosScreenFactory) {
                return PosScreenFactory.one($stateParams.sId).get().then(function (resultScreen) {
                  return resultScreen;
                });
              }
            },
            controller: 'PosScreenViewCtrl',
            controllerAs: 'posScreenViewCtrl'
          }
        }
      })
      .state('pos-settings.screens.detail.edit', {
        url: '/edit',
        views: {
          '@pos-settings': {
            templateUrl: 'pos-settings/views/pos-settings.screens.edit.tpl.html',
            resolve: {
              screen: function ($stateParams, PosScreenFactory) {
                return PosScreenFactory.one($stateParams.sId).get().then(function (resultScreen) {
                  return resultScreen;
                });
              }
            },
            controller: 'PosScreenEditCtrl',
            controllerAs: 'posScreenEditCtrl'
          }
        }
      })
      .state('pos-settings.screens.detail.delete', {
        url: '/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'pos-settings/views/pos-settings.modal.delete.tpl.html',
            resolve: {
              screen: function () {
                var item = {
                  id: $stateParams.sId
                };
                return item;
              }
            },
            controller: 'PosScreenDeleteCtrl',
            controllerAs: 'posScreenDeleteCtrl'
          }).result.then(function () {
            $state.go('pos-settings.screens', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^');
            }
          });
        }]
      })
      .state('pos-settings.screens.detail.item', {
        url: '/item',
        abstract: true
      })
      .state('pos-settings.screens.detail.item.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', 'PosScreenFactory', function ($stateParams, $state, $modal, PosScreenFactory) {
          $modal.open({
            templateUrl: 'pos-settings/views/pos-settings.modal.item.edit.tpl.html',
            resolve: {
              item: function () {
                return {screen: $stateParams.sId};
              },
              screen: function () {
                return PosScreenFactory.one($stateParams.sId).customGET().then(function (resultItem) {
                  return resultItem;
                });
              }
            },
            controller: 'PosScreenItemCtrl',
            controllerAs: 'posScreenItemCtrl'
          }).result.then(function () {
            $state.go('pos-settings.screens.detail', {sId: $stateParams.sId}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('pos-settings.screens.detail', {sId: $stateParams.sId}, {reload: true});
            }
          });
        }]
      })
      .state('pos-settings.screens.detail.item.edit', {
        url: '/:iId/edit',
        onEnter: ['$stateParams', '$state', '$modal', 'PosScreenFactory', function ($stateParams, $state, $modal, PosScreenFactory) {
          $modal.open({
            templateUrl: 'pos-settings/views/pos-settings.modal.item.edit.tpl.html',
            resolve: {
              item: function () {
                return PosScreenFactory.one($stateParams.sId).one('items').one($stateParams.iId).customGET().then(function (resultItem) {
                  resultItem.color = resultItem.tags.length ? resultItem.tags[0] : false;
                  return resultItem;
                });
              },
              screen: function () {
                return PosScreenFactory.one($stateParams.sId).customGET().then(function (resultItem) {
                  return resultItem;
                });
              }
            },
            controller: 'PosScreenItemCtrl',
            controllerAs: 'posScreenItemCtrl'
          }).result.then(function () {
            $state.go('pos-settings.screens.detail', {sId: $stateParams.sId}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('pos-settings.screens.detail', {sId: $stateParams.sId}, {reload: true});
            }
          });
        }]
      })
      .state('pos-settings.screens.detail.item.delete', {
        url: '/:iId/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'pos-settings/views/pos-settings.modal.item.delete.tpl.html',
            resolve: {
              item: function () {
                var item = {
                  screen: $stateParams.sId
                };
                return item;
              },
              screen: null
            },
            controller: 'PosScreenItemCtrl',
            controllerAs: 'posScreenItemCtrl'
          }).result.then(function () {
            $state.go('pos-settings.screens.detail', {sId: $stateParams.sId}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('pos-settings.screens.detail', {sId: $stateParams.sId}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
