(function () {
  'use strict';

  /*
   * @ngdoc object
   * @name hwproxy
   * @description
   */
  angular
    .module('hwproxy', [
      'ui.router'
    ]);
}());
