(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name promotions.controller:PromotionCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('promotion')
    .controller('PromotionCtrl', PromotionCtrl);

  function PromotionCtrl($stateParams, $modalInstance, CurrentUserContextFactory, PromotionFactory, $filter, ProductCategoryFactory, ProductFactory) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.item = {};
    vm.promotions = [];

    vm.strategyOptions = [
      {
        code: 'products',
        label: $filter('translate')('app.product')
      },
      {
        code: 'category',
        label: $filter('translate')('app.category')
      }
    ];


    vm.selectedType = null;
    vm.selectTypeOptions = [
      {
        code: 'percent',
        label: $filter('translate')('app.percent')
      },
      {
        code: 'amount',
        label: $filter('translate')('app.amount')
      }
    ];


    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;
    vm.label = null;
    vm.beginDate = null;
    vm.endDate = null;
    vm.type = null;
    vm.value = null;
    vm.expiration = null;
    vm.products = null;
    vm.productCategories = null;
    vm.categoriesOptions = [];
    vm.strategy = vm.strategyOptions[0];
    vm.code = null;

    vm.productOptions = [];

    vm.datePickerIsOpened = false;
    vm.endDatePickerIsOpened = false;
    vm.expDatePickerIsOpened = false;
    vm.minDate = new Date();
    vm.cancel = cancel;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openDatePicker = openDatePicker;
    vm.openExpDatePicker = openExpDatePicker;
    vm.loadProducts = loadProducts;

    ProductCategoryFactory.getList({limit: 99}).then(function (resultCategories) {
      vm.categoriesOptions = resultCategories;
    });


    function loadProducts(search) {
      var params = [];
      params['filter[]'] = [];
      params['filter[]'].push('label,LIKE ' + search);
      params['filter[]'].push('mostRecent,true');
      params.limit = 10;

      ProductFactory.getList(params).then(function (result) {
        vm.productOptions = result.data;
      });
    }

    function save() {
      var object = {
        beginDate: vm.item.beginDate.toISOString().substring(0, 10),
        endDate: vm.item.endDate.toISOString().substring(0, 10),
        type: vm.selectedType.code,
        value: vm.item.value,
        expiration: vm.item.expiration.toISOString().substring(0, 10),
        label: vm.item.label,
        strategy: vm.item.strategy.code,
        code: vm.item.code
      };

      if (object.strategy === 'products') {
        object.products = vm.item.products;
      } else {
        object.productCategories = vm.item.productCategories;
      }

      if (vm.isEdit === true) {
        PromotionFactory.one(vm.item.id).customPUT(object).then(function () {
          vm.close();
        });
      } else {
        object.site = CurrentUserContextFactory.getSiteId();
        PromotionFactory.post(object).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      PromotionFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'app.edit';
        vm.isEdit = true;
      }

      if (vm.isEdit === true) {
        PromotionFactory.one($stateParams.id).get().then(function (result) {
          vm.item = result;
          vm.item.beginDate = vm.item.beginDate ? new Date(vm.item.beginDate) : new Date();
          vm.item.endDate = vm.item.endDate ? new Date(vm.item.endDate) : new Date();
          vm.item.expiration = vm.item.expiration ? new Date(vm.item.expiration) : new Date();
          vm.selectedType = vm.selectTypeOptions.filter(function (option) {
            return option.code === vm.item.type;
          })[0];
          vm.item.strategy = vm.strategyOptions.filter(function (item) {
            return item.code === vm.item.strategy;
          })[0];
        });
      }
    }

    function isValid() {
      return angular.isDefined(vm.item.beginDate) && vm.item.beginDate !== '' && angular.isDefined(vm.item.endDate) && vm.item.endDate !== '' &&
        vm.selectedType && vm.selectedType.label !== '' && angular.isDefined(vm.item.value) && vm.item.value !== '' &&
        angular.isDefined(vm.item.expiration) && vm.item.expiration !== '';
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }

    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function openExpDatePicker() {
      vm.expDatePickerIsOpened = true;
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
