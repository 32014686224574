(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name documentation.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('documentation')
    .factory('DocumentationFactory', DocumentationFactory);

  function DocumentationFactory(Restangular) {
    return Restangular.service('documentation');
  }
}());
