(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('ContactService', ContactService);

  function ContactService(
    ContactFactory,
    CurrentUserContextFactory,
    ImageService,
    LogService,
    ScheduledReportFactory
    ) {
    return {
      getContact: function (contactId) {
        return ContactFactory.one(contactId).get();
      },
      getPhoto: function (contact) {
        return ImageService.getImageFromBackend(contact.contactPhotos[0].photo.id);
      },
      getContactsWithName: function (firstname, lastname) {
        var params = {'filter[]': ['firstName,' + firstname, 'lastName,' + lastname]};
        return ContactFactory.getList(params);
      },
      getContactsReport: function (filters) {
        var scheduledReport = {
              userContext: CurrentUserContextFactory.getUserContextId(),
              enabled: true,
              parameters: {
                format: 'xlsx'
              },
              interval: 'P1D',
              ephemeral: true,
              site: CurrentUserContextFactory.getSiteId()
            }, path = 'customer_details_report';
        angular.forEach(filters, function (filter, key) {
          if (angular.isUndefined(filter.type)) {
            if (filter.val || filter.val === 0) {
              switch (key) {
                case 'customer':
                  scheduledReport.parameters.customerLabel = filter.val;
                  break;
                case 'contact':
                  scheduledReport.parameters.contactLabel = filter.val;
                  break;
                case 'street':
                  scheduledReport.parameters.locationStreet = filter.val;
                  break;
                case 'city':
                  scheduledReport.parameters.locationCity = filter.val;
                  break;
                case 'memberCardNumber':
                  scheduledReport.parameters.memberCardLabel = filter.val;
                  break;
                case 'externalDebtorNumber':
                  scheduledReport.parameters.customerExternalDebtorNumber = filter.val;
                  break;
                case 'hidden':
                  if (filter.val) {
                    scheduledReport.parameters.hidden = filter.val;
                  }
                  break;
                default:
                  break;
              }
            }
          } else {
            LogService.log('Contact Service: Checking what type of filter is send to contacts report' + filter, 'debug');
            if (filter.type === 'RADIO') {
              switch (key) {
                case 'activeInactiveCustomers':
                  angular.forEach(filter.items, function (item) {
                    if (item.code === filter.selectedCode) {
                      switch (item.code) {
                        case 'ACTIVE':
                          scheduledReport.parameters.customerIsActive = true;
                          break;
                        case 'INACTIVE':
                          scheduledReport.parameters.customerIsInactive = true;
                          break;
                        default:
                          break;
                      }
                    }
                  });
                  break;
                default:
                  break;
              }
            }
            if (filter.type === 'INPUT') {
              switch (key) {
                case 'customerTag':
                  if (filter.val.length > 0) {
                    scheduledReport.parameters.customerTag = filter.val;
                  }
                  break;
                default:
                  break;
              }
            }
          }
        });
        return ScheduledReportFactory.one(path).customPOST(scheduledReport);
      }
    };
  }
}());
