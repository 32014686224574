(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accountancy.factory:DebitCollectionPeriods
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .factory('DebitCollectionPeriodFactory', DebitCollectionPeriodFactory);

  function DebitCollectionPeriodFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'debitCollectionPeriod'});
    })
    .service('debit_collection_periods');
  }
}());
