(function () {
  'use strict';

  angular
    .module('ledger')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('ledger', {
        url: '/ledger',
        views: {
          '@': {
            templateUrl: 'ledger/views/ledger.tpl.html'
          },
          'nav@ledger': {
            templateUrl: 'ledger/views/ledger.nav.tpl.html'
          },
          'content@ledger': {
            templateUrl: 'ledger/views/ledger.list.tpl.html',
            controller: [
              'RestUtilService',
              'LedgerFactory',
              function (RestUtilService, LedgerFactory) {
                var vm = this;
                RestUtilService.getFullList(LedgerFactory, {sort: 'code,ASC'}).then(function (resultData) {
                  vm.ledgers = resultData;
                });
              }
            ],
            controllerAs: 'ledgerCtrl'
          }
        }
      })
      .state('ledger.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'ledger/views/ledger.add.edit.modal.tpl.html',
            controller: 'LedgerCtrl',
            controllerAs: 'ledgerCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('ledger.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'ledger/views/ledger.add.edit.modal.tpl.html',
            controller: 'LedgerCtrl',
            controllerAs: 'ledgerCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('ledger.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'ledger/views/ledger.delete.modal.tpl.html',
            controller: 'LedgerCtrl',
            controllerAs: 'ledgerCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
