(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerAddEditBalanceCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerAddEditBalanceCtrl', CustomerAddEditBalanceCtrl);

  function CustomerAddEditBalanceCtrl(
      LogService,
      $modalInstance,
      CustomerFactory,
      balance,
      customer
    ) {
    var vm = this;

    vm.object = balance;
    if (angular.isUndefined(vm.object)) {
      vm.object = {
        amount: 0,
        currency: 'EUR',
        comments: null
      };
    }

    vm.customer = customer;

    vm.cancel = cancelModalInstance;
    vm.saveBalance = saveBalance;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveBalance() {
      var restObject = vm.object;

      CustomerFactory.saveCustomerBalance(vm.customer.id, restObject).then(function () {
        // Close the modal view
        $modalInstance.close('reloadCustomerBalances');
      }, function (errorMsg) {
        LogService.log('error: ' + errorMsg, 'error');
      });
    }
  }
}());
