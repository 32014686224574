(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:JournalAccessControlCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
  .module('journal')
  .controller('JournalAccessControlCtrl', JournalAccessControlCtrl);

  function JournalAccessControlCtrl($stateParams, Restangular) {
    var vm = this;
    vm.submit = submit;
    vm.use = null;
    vm.formData = {
      squash: {
        deviceId: 112,
        lane_selection: {
          onlyCheckboxes: true,
          label: 'lane selection',
          value: null,
          checkboxes: {
            showValue: true,
            values: {lane: [1, 2, 3]}
          },
          checkboxText: 'lane'
        },
        duration: {
          type: 'number',
          hide: false,
          value: null,
          label: 'duration',
          checkboxes: {
            showValue: true,
            values: {minute: [30, 60, 90]}
          }
        },
        delay: {
          type: 'number',
          hide: false,
          value: null,
          label: 'delay'
        }
      },
      tanningBed: {
        deviceId: 113,
        zonnebanks: {
          type: 'selectedlist',
          hide: false,
          value: null,
          label: 'Zonnebank',
          showValue: true,
          values: [
            {name: 'Zonebank 1', deviceId: 200},
            {name: 'Zonebank 2', deviceId: 201}
          ]
        },
        duration: {
          type: 'number',
          hide: false,
          value: null,
          label: 'duration',
          checkboxes: {
            showValue: true,
            values: {minute: [3, 5, 10, 15]}
          }
        },
        delay: {
          type: 'number',
          hide: false,
          value: null,
          label: 'delay'
        }
      },
      accessControl: {
        deviceId: null,
        delay: {
          type: 'number',
          hide: false,
          value: null,
          label: 'delay'
        },
        doors: {
          onlyCheckboxes: true,
          label: 'open doors',
          value: null,
          // hardcoded for now
          checkboxes: {
            values: {
              'swimming pool door': [201],
              'fitness tourniquette': [202],
              'fitness door': [203]
            }
          }
        }
      }
    };

    vm.zonnebankDeviceId = 200;
    if ($stateParams.tab && vm.formData.hasOwnProperty($stateParams.tab)) {
      vm.use = $stateParams.tab;
    }

    function submit() {
      var data = {};
      preSubmit();
      if (vm.formData[vm.use].deviceId) {
        data = generateRestObj();
        data.deviceId = vm.formData[vm.use].deviceId;
        Restangular.one('access_control/item/' + vm.use).customPOST(data);
      }
    }

    function preSubmit() {
      if (vm.use === 'accessControl') {
        vm.formData[vm.use].deviceId = vm.formData[vm.use].doors.value;
      }
      if (vm.use === 'tanningBed') {
        vm.formData[vm.use].deviceId = vm.zonnebankDeviceId;
      }
    }

    function generateRestObj() {
      var field = ['duration', 'delay'],
          restObj = {};
      field.forEach(function (filed) {
        if (vm.formData[vm.use].hasOwnProperty(filed) && vm.formData[vm.use][filed].value) {
          restObj[filed] = vm.formData[vm.use][filed].value;
        }
      });
      return restObj;
    }
  }
}());
