(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:switchUserContext
   * @restrict AEC
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('switchUserContext', switchUserContext);

  function switchUserContext() {
    return {
      restrict: 'AEC',
      template: '<a ng-click="userContextSelectionDirectiveCtrl.showUserContextSelectionModalWithoutContextElection()">{{ "app.select_site" | translate | uconlyfirst}}</a>',
      controllerAs: 'userContextSelectionDirectiveCtrl',
      controller: [
        '$modal',
        '$modalStack',
        '$rootScope',
        '$state',
        '$timeout',
        'CurrentUserContextFactory',
        'FacilityFactory',
        'hwproxy',
        'LocaleService',
        'LogService',
        'OAuth',
        'PaymentEngineFactory',
        'SettingsService',
        'ToastrNotificationService',
        'UserMeFactory',
        '$window',
        function (
          $modal,
          $modalStack,
          $rootScope,
          $state,
          $timeout,
          CurrentUserContextFactory,
          FacilityFactory,
          hwproxy,
          LocaleService,
          LogService,
          OAuth,
          PaymentEngineFactory,
          SettingsService,
          ToastrNotificationService,
          UserMeFactory,
          $window
        ) {
          var vm = this;
          vm.applicablePermissions = [];

          // select pos instance modal
          vm.showingUserContextSelectionModal = false;
          vm.showUserContextSelectionModal = showUserContextSelectionModal;
          vm.showUserContextSelectionModalWithoutContextElection = showUserContextSelectionModalWithoutContextElection;
          vm.userContextSelectionModalInstance = null;
          vm.selectUserContext = selectUserContext;
          vm.electDefaultFacility = electDefaultFacility;
          vm.checkUserContextCookies = checkUserContextCookies;

          //Note: The storage event is only triggered when a window other than itself makes the changes. see #59731
          $window.addEventListener('storage', function (e) {
            if (e.key === 'siteName') {
              $rootScope.site = e.newValue;
              //see #61292
              if ($state.is('pos')) {
                $state.reload();
              }
            }
          });

          $rootScope.$on('$viewContentLoaded', function () {
            if (!$state.is('login') && OAuth.isAuthenticated()) {
              vm.checkUserContextCookies();
            }
          });

          $rootScope.$on('showUserContextSelectionModal', function () {
            vm.showUserContextSelectionModalWithoutContextElection();
          });

          function checkUserContextCookies() {
            LogService.log('Checking user context cookies', 'debug');

            if (!CurrentUserContextFactory.isUserContextSelected()) {
              LogService.log('no user context cookies set', 'debug');
              // Call to backend ME
              UserMeFactory.one()
              .get()
              .then(function (userMeObject) {
                if (userMeObject.userContexts.length === 0) {
                  LogService.log('Received No user contexts!!', 'warn');
                } else if (userMeObject.userContexts.length > 1) {
                  LogService.log('Received ' + userMeObject.userContexts.length + ' user contexts + forcing selection.', 'debug');
                  vm.showUserContextSelectionModal(userMeObject.username, userMeObject.userContexts);
                } else {
                  LogService.log('Received one user context, auto-selecting...', 'debug');
                  vm.selectUserContext(userMeObject.username, userMeObject.userContexts[0]);
                }
                LocaleService.syncBackendLocale(userMeObject.locale);
              });
            } else {
              $rootScope.standaloneMode = SettingsService.get('pos.standaloneMode', false);
              $rootScope.enablePaymentEngineConnection = PaymentEngineFactory.enabled() && !$rootScope.standaloneMode;
              $rootScope.enableHwproxyConnection = hwproxy.enabled();
              $timeout(function () {
                $rootScope.$broadcast('userLoaded');
              });
            }
          }

          function electDefaultFacility() {
            var params = {};

            LogService.log('Electing default facility.', 'debug');
            // find facilities which are linked to this site in order to set a default facility id
            params['filter[]'] = [];
            params['filter[]'].push('site.id,' + CurrentUserContextFactory.getSiteId());
            params['filter[]'].push('facilityType.code,SITE');
            params['filter[]'].push('parentFacility.id,NULL');

            FacilityFactory.getList(params)
            .then(function (facilities) {
              if (facilities.length > 0) {
                if (facilities.length > 1) {
                  LogService.log('Found ' + facilities.length + ' facilities of type "SITE" with no parent facility set. Selecting the first.', 'debug');
                }

                CurrentUserContextFactory.setUserContextCookies(null, null, null, null, facilities[0].id);
              } else {
                // no default facility to elect - no bueno!
                CurrentUserContextFactory.clearSiteFacilityIdCookie();
                ToastrNotificationService.showTranslatedNotification(
                  'error',
                  'error.no_default_facility_short',
                  'error.no_default_facility_long'
                );
              }
            });
          }

          function selectUserContext(username, userContext) {
            var params = {};

            LogService.log('UserContext selected, storing user context cookies.', 'debug');

            $rootScope.username = username;
            $rootScope.site = userContext.site.label;
            CurrentUserContextFactory.setUserContextCookies(username, userContext.site.label, userContext.id, userContext.site.id, null);

            params.activeUserContext = userContext.id;
            UserMeFactory.one().patch(params).then(function () {
              vm.electDefaultFacility();
              // retrieve the flat array of all applicable permissions and settings
              SettingsService.reloadSettings(userContext);
            });
          }

          function showUserContextSelectionModalWithoutContextElection() {
            LogService.log('Showing user context selection modal without context election procedure.', 'debug');
            UserMeFactory.one()
            .get()
            .then(function (userMeObject) {
              if (!$state.is('login')) {
                vm.showUserContextSelectionModal(userMeObject.username, userMeObject.userContexts);
              }
            });
          }

          function showUserContextSelectionModal(username, userContexts) {
            if (vm.showingUserContextSelectionModal) {
              return;
            }

            $modalStack.dismissAll();
            vm.userContextSelectionModalInstance = $modal.open({
              templateUrl: 'auth/views/usercontext.modal.tpl.html',
              controller: 'UserContextSelectCtrl',
              controllerAs: 'userContextSelectCtrl',
              resolve: {
                username: function () {
                  return username;
                },
                userContexts: function () {
                  return userContexts;
                }
              }
            });

            vm.showingUserContextSelectionModal = true;
            vm.userContextSelectionModalInstance.result
            .then(function (selectedContext) {
              vm.showingUserContextSelectionModal = false;
              vm.selectUserContext(username, selectedContext);
            });
          }
        }
      ]
    };
  }
}());
