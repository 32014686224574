(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosCtrl', PosCtrl);

  function PosCtrl(
    SaleFactory,
    PosSessionFactory,
    UtilService,
    CurrentPosInstanceFactory,
    ToastrNotificationService,
    CurrentUserContextFactory,
    PaymentEngineFactory,
    $scope,
    $rootScope,
    $cookies,
    LogService,
    SettingsService,
    $state,
    $modal,
    $window
  ) {
    var vm = this;
    vm.checkForExistingPosSession = checkForExistingPosSession;
    vm.posInstanceId = null;
    vm.posGroupId = null;
    vm.selectedInstance = null;
    vm.hideStartAmount = SettingsService.get('pos.hideStartAmount', false);
    vm.isActive = CurrentUserContextFactory.isPosSessionActive();
    vm.unload = function () {
      if (!vm.isActive) {
        CurrentUserContextFactory.removePosSessionIsActiveCookie();
      }
    };
    vm.checkIfPosIsActive = checkIfPosIsActive;
    vm.takeOverSession = takeOverSession;
    vm.broadCastInstanceChosen = broadCastInstanceChosen;
    vm.getSessionSales = getSessionSales;
    vm.loadActiveSaleFromSession = loadActiveSaleFromSession;
    vm.showSessionTakeOverModal = showSessionTakeOverModal;
    vm.showStartShiftModal = showStartShiftModal;
    vm.loadData = loadData;
    vm.posMergeSalesEnabled = SettingsService.get('pos.mergeSalesEnabled', true);

    vm.checkIfPosIsActive();

    if (UtilService.isEmpty(CurrentUserContextFactory.getSiteFacilityId())) {
      ToastrNotificationService.showTranslatedNotification(
        'error',
        'error.no_default_facility_short',
        'error.no_default_facility_long'
      );
      $state.go('dashboard');
      return;
    }

    $scope.$on('$stateChangeStart', function () {
      vm.unload();
    });

    if (!vm.isActive) {
      vm.loadData();
      vm.checkForExistingPosSession();
    }

    $scope.$on('checkPosSessionBroadCast', function () {
      vm.checkForExistingPosSession();
    });

    function checkIfPosIsActive() {
      if (vm.isActive) {
        vm.unload = function () {};
        $modal.open({
          template:
          '<div class="modal-header">' +
          '  <h4 class="modal-title" id="myModalLabel">{{\' pos.warning_text.2nd_pos\' | translate}}</h4>' +
          '</div>' +
          '<div class="modal-footer">' +
          '  <button type="submit" class="btn btn-default" ng-click="$close(true)"> ok </button>' +
          '</div>'
        }).result.then(function () {
          $state.go('dashboard');
        });
      } else {
        CurrentUserContextFactory.setPosSessionIsActiveCookie();
      }
      $window.addEventListener('unload', vm.unload);
    }

    function loadData() {
      CurrentPosInstanceFactory.getCurrentPosInstance()
      .then(function (instance) {
        if (instance) {
          vm.selectedInstance = instance;
        } else {
          $rootScope.$broadcast('showPosInstanceSelectionModal');
        }
      });
    }

    function broadCastInstanceChosen() {
      CurrentPosInstanceFactory.getCurrentPosInstance()
      .then(function (instance) {
        if (instance) {
          LogService.log('Broadcasting refresh cookies message', 'debug');
          $rootScope.$broadcast('posGroupHasBeenChosenBroadcast', instance);
          $rootScope.$broadcast('refreshCookiesBroadcast');
        }
      });
    }

    function takeOverSession(session) {
      PosSessionFactory.takeOverPosSession(session.posInstanceId, session.id)
      .then(function () {
        UtilService.reloadState();
        ToastrNotificationService.showTranslatedNotification(
          'success',
          'pos.session_takeover_successfull_short',
          'pos.session_takeover_successfull_long'
        );
      });
    }

    function getSessionSales(sessionId, saleStatusCode) {
      var params = {
        limit: 99
      };

      params['filter[]'] = [
        'saleStatus.code,' + saleStatusCode,
        'pointOfSaleSession.id,' + sessionId
      ];

      return SaleFactory.getList(params);
    }

    function loadActiveSaleFromSession(sessionId) {
      return vm.getSessionSales(sessionId, 'IN_PROGRESS')
      .then(function (salesInProgress) {
        if (salesInProgress.length) {
          LogService.log('Should open an active sale, but it is disabled at this moment.', 'debug');
          // Disabled for testing purposes.
          // $rootScope.$broadcast('pos.loadSaleSession', salesInProgress[0].id);
        }
      });
    }

    function checkForExistingPosSession() {
      var instanceIds = CurrentPosInstanceFactory.getInstanceCookies();

      if (CurrentUserContextFactory.isUserContextSelected()) {
        // don't chain this condition because we only want to redirect to dashboard if no context is selected here
        if (CurrentPosInstanceFactory.isInstanceSelected()) {
          PosSessionFactory.getActivePosSession()
          .then(function (active) {
            if (active) {
              if (active.posInstanceId !== instanceIds.posInstanceId) {
                // session found from another instance
                vm.showSessionTakeOverModal(active);
              } else {
                // session found from this instance
                $cookies.put('currentPosSessionId', active.id);
                ToastrNotificationService.showTranslatedNotification('info', 'app.session_continued', 'app.session_continued_long');
                vm.loadActiveSaleFromSession(active.id);
                vm.broadCastInstanceChosen();
              }
            } else if (!vm.hideStartAmount) {
              vm.showStartShiftModal();
            } else {
              // start the session directly with 0 start amount
              return PosSessionFactory.startNewPosSession(instanceIds.posGroupId, instanceIds.posInstanceId, 0)
              .then(function () {
                ToastrNotificationService.showTranslatedNotification(
                  'info', 'app.session_started', 'app.session_started_long'
                );
                vm.broadCastInstanceChosen();
              }, function () {
                $state.go('dashboard');
              });
            }
          });
        }
      } else {
        $state.go('dashboard');
      }
    }

    // TAKE OVER SESSION
    function showSessionTakeOverModal(session) {
      UtilService.showFormattedConfirmationModal(
        'app.take_over_session', '', vm.posMergeSalesEnabled ? 'confirm.take_over_session' : 'confirm.take_over_session_without_merge_sales', 'app.level',
        function (returnValue) {
          if (returnValue) {
            vm.takeOverSession(session);
          } else if (vm.posMergeSalesEnabled) {
            $state.go('dashboard');
          } else {
            PosSessionFactory.getActivePosSession()
              .then(function (active) {
                if (active) {
                  // session found from this instance
                  $cookies.put('currentPosSessionId', active.id);
                  $cookies.put('currentPosInstanceId', active.pointOfSaleInstance.id);
                  ToastrNotificationService.showTranslatedNotification('info', 'app.session_continued', 'app.session_continued_long');
                  vm.loadData();
                  vm.loadActiveSaleFromSession(active.id);
                  vm.broadCastInstanceChosen();
                }
              });
          }
        }
      );
    }

    // START POS SHIFT
    function showStartShiftModal() {
      LogService.log('PosCtrl::showStartShiftModal() -> Invoking start pos shift modal.', 'debug');

      UtilService.showModal({
        templateUrl: 'pos/views/sessions/pos.startshift.modal.tpl.html',
        controller: 'StartPosShiftCtrl',
        controllerAs: 'startPosShiftCtrl',
        size: 'lg'
      },
      function () {
        // handle close
        vm.broadCastInstanceChosen();
      },
      function (returnValue) {
        // handle dismiss
        if (returnValue) {
          LogService.log('PosCtrl::showStartShiftModal() -> Modal dismissed, redirecting to dashboard.', 'debug');
          $state.go('dashboard');
        }
      });
    }

    // Emit function to add product from PosProductCtrl to the shopping Cart of ShoppingCartCtrl
    $scope.$on('addProductToShoppingCartEmit', function (event, product) {
      if (!event.defaultPrevented) {
        // Stop the event
        event.stopPropagation();
        // broadcast the product to the shopping cart (ShoppingCartCtrl)
        $scope.$broadcast('addProductToShoppingCartBroadcast', product);
      }
    });

    $scope.$on('$destroy', function () {
      PaymentEngineFactory.disconnect();
      // vm.unload();
    });
  }
}());
