(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosSettingsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosSettingsCtrl', PosSettingsCtrl);

  function PosSettingsCtrl(
    PosScreenFactory,
    RestUtilService,
    _,
    LogService
  ) {
    var vm = this;
    vm.posGroups = [];
    vm.posScreens = [];

    RestUtilService.getFullList(PosScreenFactory, {limit: 99}).then(function (resultScreens) {
      angular.forEach(resultScreens, function (posScreen) {
        LogService.log('pos screen' + posScreen, 'debug');
        PosScreenFactory.one(posScreen.id).get().then(function (posScreenDetail) {
          LogService.log('pos screen details' + posScreenDetail, 'debug');
          vm.posScreens.push(posScreenDetail);
        });
      });
    });
  }
}());
