(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .factory('ProductCategoryFactory', ProductCategoryFactory);

  function ProductCategoryFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'productCategory'});
    })
    .service('products/categories');
  }
}());
