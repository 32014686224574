(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name memberCard.controller:MemberCardCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('memberCard')
    .controller('MemberCardCtrl', MemberCardCtrl);

  function MemberCardCtrl(MemberCardFactory, memberCard, $modalInstance) {
    var vm = this;
    vm.memberCard = memberCard;
    vm.cancel = cancelModalInstance;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }
  }
}());
