(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:EmployeeContactCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('EmployeeContactCtrl', EmployeeContactCtrl);

  function EmployeeContactCtrl($cookies,
                               $filter,
                               ContactFactory,
                               ContactTypeFactory,
                               CurrentUserContextFactory,
                               LogService,
                               SiteFactory,
                               UtilService) {
    var vm = this,
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId(),
        contactParams = {
          limit: 99
        };
    vm.reloadListData = reloadListData;
    vm.showModalView = showModalView;

    SiteFactory.one(currentLoggedInUserSiteId).get().then(function (resultSite) {
      vm.site = resultSite;
      vm.reloadListData();
    });

    function reloadListData() {
      contactParams['filter[]'] = ['childContactRelations.parentContact.id,' + vm.site.contact.id];
      contactParams['filter[]'].push('childContactRelations.contactRelationType.code,EMPLOYMENT');
      ContactFactory.getList(contactParams).then(function (resultContacts) {
        vm.contacts = resultContacts;
      });
    }

    function showModalView(returnType, type, contact) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'contact/views/employee.contact.modal.addedit.tpl.html',
              controller: 'EmployeeContactAddEditCtrl',
              controllerAs: 'employeeContactAddEditCtrl',
              resolve: {
                contact: function () {
                  return contact;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function modalResultHandler(returnValue) {
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'page':
          break;

        case 'list':
          vm.reloadListData();
          break;

        default:
          break;
      }
    }
  }
}());
