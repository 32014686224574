(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosProductsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosProductsCtrl', PosProductsCtrl);

  function PosProductsCtrl($cookies,
                           $scope,
                           $rootScope,
                           $window,
                           Restangular,
                           ToastrNotificationService,
                           CurrentPosInstanceFactory,
                           PeopleCountFactory,
                           PosGroupFactory,
                           PosScreenFactory,
                           ProductUtilService,
                           RestUtilService,
                           SettingsService,
                           UtilService,
                           $interval,
                           $timeout) {
    var vm = this;
    vm.Math = Math;
    vm.ProductUtilService = ProductUtilService;
    vm.addProductToShoppingCart = addProductToShoppingCart;
    vm.getPosScreens = getPosScreens;
    vm.showScreen = showScreen;
    vm.goToScreen = goToScreen;
    vm.posItemClicked = posItemClicked;
    vm.initMyPhoto = initMyPhoto;
    vm.filterPointOfSaleItems = filterPointOfSaleItems;
    vm.getPosScreenRowItems = getPosScreenRowItems;
    vm.hasSubscriptionContract = hasSubscriptionContract;
    vm.getDomProductMonthlyPrice = getDomProductMonthlyPrice;
    vm.addToBreadCrumbs = addToBreadCrumbs;
    vm.addToPosScreens = addToPosScreens;
    vm.filterChanged = filterChanged;
    vm.clearFilters = clearFilters;
    vm.getScreenById = getScreenById;
    vm.shouldItemBeVisible = shouldItemBeVisible;
    vm.getFilteredItemsOfScreen = getFilteredItemsOfScreen;
    vm.buildScreenFromItems = buildScreenFromItems;
    vm.buildScreenWithEmptyItems = buildScreenWithEmptyItems;
    vm.clonePosItem = clonePosItem;
    vm.buildBreadCrumbsTree = buildBreadCrumbsTree;
    vm.addProductByPressEnter = addProductByPressEnter;
    vm.enablePosDisplayAccessControlButtons = SettingsService.get('posDisplayAccessControlButtons', false);
    vm.enablePosDisplayAccessControlWristbandButtons = SettingsService.get('posDisplayAccessControlWristbandButtons', false);
    vm.peopleCounterVisible = SettingsService.get('pos.peoplecounterVisible', false);
    vm.posEnableItegrationAKaartButton = SettingsService.get('posEnableItegrationAKaartButton', false);
    vm.posScreens = {};
    vm.visibleScreen = null;
    vm.paymentModelOpen = false;
    vm.breadCrumbs = [];
    vm.filters = {
      label: {
        val: '',
        field: '',
        cond: ''
      }
    };
    vm.saldo = 0;
    vm.allowFiltering = !!SettingsService.get('pos.allowProductsFiltering', false);
    vm.showSaldo = SettingsService.get('pos.showSaldo', false);
    vm.recordingBarcode = false;
    vm.simulatedBarcode = '';

    // Barcode scan detection
    vm.barcodeTimeoutHandler = 0;
    vm.barcodeInputString = '';

    vm.peopleCountsPerFacility = [];
    vm.peopleCountInterval = null;
    vm.loadPeopleCount = loadPeopleCount;

    if (vm.peopleCounterVisible) {
      vm.loadPeopleCount();
    }

    function barcodeKeyEvent(e) {
      var ignoredKeyCodes = [13, 16];
      if (vm.barcodeTimeoutHandler) {
        $timeout.cancel(vm.barcodeTimeoutHandler);
        if (ignoredKeyCodes.indexOf(e.keyCode) === -1) {
          vm.barcodeInputString += String.fromCharCode(e.keyCode);
        }
        if (e.keyCode === 13 && vm.barcodeInputString.length > 7) {
          e.stopPropagation();
        }
      }
      vm.barcodeTimeoutHandler = $timeout(function () {
        if (vm.barcodeInputString.length <= 7) {
          vm.barcodeInputString = '';
          return;
        }
        console.log('Barcode scanned: ' + vm.barcodeInputString);
        if (!vm.paymentModelOpen) {
          addProductToCartByBarcode(vm.barcodeInputString);
        }
        vm.barcodeInputString = '';
      }, 50);
    }

    $window.addEventListener('keypress', barcodeKeyEvent);
    if (vm.barcodeTimeoutHandler) {
      $timeout.cancel(vm.barcodeTimeoutHandler);
    }

    vm.barcodeTimeoutHandler = $timeout(function () {
      vm.barcodeInputString = '';
    }, 50);

    // find product by barcode and add product to cart
    function addProductToCartByBarcode(barcode) {
      var productData = null;
      Restangular.one('products').one('barcode', barcode).get().then(function (product) {
        productData = ProductUtilService.getProductDetails(product);
        addProductToShoppingCart(productData);
      }).catch(function () {
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'Error',
          'Product with barcode ' + barcode + ' was not found'
        );
      });
    }

    function addProductByPressEnter(e) {
      var products;
      if (!vm.barcodeTimeoutHandler) {
        if (e.keyCode === 13 && vm.filters.label.val.length !== 0) {
          products = vm.getFilteredItemsOfScreen(vm.visibleScreen);
          //get first product
          if (products.length) {
            vm.posItemClicked(products[0]);
          }
        }
      }
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('keypress', barcodeKeyEvent);
      if (vm.peopleCountInterval !== null) {
        $interval.cancel(vm.peopleCountInterval);
      }
    });

    // Get the Pos Products from the backend
    function getPosScreens() {
      var currentPosGroupId = $cookies.get('currentPosGroupId'),
          i,
          params = {
            limit: 99
          },
          promises = [];

      // Get the Pos-groups screens
      params['filter[]'] = ['pointOfSaleGroups.id,' + currentPosGroupId];
      RestUtilService.getFullList(PosScreenFactory, params).then(function (resultScreens) {
        // Loop all the screens from a group
        angular.forEach(resultScreens, function (screen) {
          // Get the individual screen details
          promises.push(PosScreenFactory.one(screen.id).one('v2').customGET().then(function (resultScreen) {
            var rowItems = [],
                biggestRow = 0,
                posScreen = {
                  label: resultScreen.label,
                  id: resultScreen.id,
                  master: resultScreen.master,
                  rows: []
                };

            // Add the posScreen to the posScreens-array
            vm.addToPosScreens(posScreen);

            // If the screen is master, set that screen as the current visible screen and as first breadcrumb-item
            if (resultScreen.master) {
              vm.showScreen(posScreen);
              vm.addToBreadCrumbs(posScreen);
            }

            // Check if screen has point of sale items
            if (resultScreen.pointOfSaleItems.length > 0) {
              // Get the highest row value
              biggestRow = Math.max.apply(null, resultScreen.pointOfSaleItems.map(function (o) {
                return o.row;
              }));

              // Loop all the rows until the biggestRow
              for (i = 1; i <= biggestRow; i++) {
                // Select the items where row is equal to 'i'
                rowItems = vm.filterPointOfSaleItems(resultScreen.pointOfSaleItems, i);
                // Check if the row exists
                if (rowItems && rowItems.length > 0) {
                  posScreen.rows.push({
                    rowIndex: i,
                    items: vm.getPosScreenRowItems(rowItems)
                  });
                } else {
                  // If not exist, create a empty row with empty object
                  posScreen.rows.push({
                    rowIndex: i,
                    items: [{
                      itemType: 'E',
                      label: ''
                    }]
                  });
                }
              }
            }
          }));
        });

        Promise.all(promises).finally(function () {
          angular.forEach(vm.posScreens, function (screen) {
            angular.forEach(screen.rows, function (row) {
              angular.forEach(row.items, function (item) {
                var posScreen;
                if (item.itemType === 'S') {
                  posScreen = vm.getScreenById(item.targetScreenId);
                  if (angular.isDefined(posScreen)) {
                    posScreen.parent = screen.id;
                  }
                }
              });
            });
          });
        });
      });
    }

    function getPosScreenRowItems(rowItems) {
      var resultItems = [];

      angular.forEach(rowItems, function (rowItem) {
        // Check if rowItem is a product or a targetScreen or something else
        if (rowItem.product) {
          // add the Product to the array
          resultItems.push({
            columnIndex: rowItem.column,
            itemType: 'P',
            tags: rowItem.tags,
            label: rowItem.product.translatedLabel,
            product: ProductUtilService.getProductDetails(rowItem.product),
            disabled: rowItem.disabled
          });
        } else if (rowItem.targetScreen) {
          // add the Screen to the array
          resultItems.push({
            columnIndex: rowItem.column,
            itemType: 'S',
            tags: rowItem.tags,
            label: rowItem.targetScreen.label,
            targetScreenId: rowItem.targetScreen.id
          });
        } else {
          // add a empty object to the array
          resultItems.push({
            columnIndex: rowItem.column,
            itemType: 'E',
            label: ''
          });
        }
      });

      return resultItems;
    }

    // Function when you click of a Pos-Sale-item
    function posItemClicked(posItem) {
      var targetScreen;
      // Check what item it is: S = Screen, P = Product
      switch (posItem.itemType) {
        case 'S':
          vm.clearFilters(false);
          targetScreen = vm.getScreenById(posItem.targetScreenId);
          if (angular.isUndefined(targetScreen)) {
            break;
          }
          vm.showScreen(targetScreen);
          vm.addToBreadCrumbs(targetScreen);
          break;
        case 'P':
          vm.clearFilters();
          vm.addProductToShoppingCart(posItem.product);
          break;
        default:
          break;
      }
    }

    function addToBreadCrumbs(screen) {
      vm.breadCrumbs = vm.buildBreadCrumbsTree(screen);
    }

    function addToPosScreens(screen) {
      vm.posScreens[screen.id] = screen;
    }

    function getScreenById(screenId) {
      return vm.posScreens[screenId];
    }

    function filterPointOfSaleItems(list, i) {
      return list.filter(function (posi) {
        return posi.row === i;
      });
    }

    function hasSubscriptionContract(product) {
      return angular.isArray(product.productSubscription) && product.productSubscription.length > 0;
    }

    function getDomProductMonthlyPrice(product) {
      return ProductUtilService.calculateDomMonthlyPrice(
        {subscriptionDuration: product.productSubscription[0].duration},
        ProductUtilService.calculateInclPrice(product.price.price, product.price.vat).inclPrice
      );
    }

    function goToScreen(breadCrumb) {
      // Loop while the clicked breadcrumb isn't the last breadcrumb, pop the breadcrumbs until a match
      while (breadCrumb.id !== vm.breadCrumbs[vm.breadCrumbs.length - 1].id) {
        vm.breadCrumbs.pop();
      }
      vm.clearFilters(false);
      vm.showScreen(breadCrumb);
    }

    function showScreen(screen) {
      vm.visibleScreen = screen;
    }

    function initMyPhoto(product) {
      // Function that puts the photo to the product
      var productPhoto = product.productHasPhoto ? product.productPhoto : 'images/no-image.png';
      return {
        'background-image': 'url(' + productPhoto + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center'
      };
    }

    function addProductToShoppingCart(product) {
      $scope.$emit('addProductToShoppingCartEmit', product);
    }

    function filterChanged() {
      var rowMax, colMax, filtered, newScreen, screen = vm.getScreenById(vm.visibleScreen.id);

      if (vm.filters.label.val.length === 0) {
        vm.showScreen(screen);
        return;
      }

      if (screen.master) {
        //Get flat array of filtered items from subscreens
        filtered = [].concat.apply(
          [],
          Object.values(vm.posScreens).map(function (x) {
            return vm.getFilteredItemsOfScreen(x);
          })
        );

        colMax = 5;
        rowMax = Math.max(5, Math.ceil(filtered.length / colMax));
      } else {
        filtered = vm.getFilteredItemsOfScreen(screen);

        rowMax = Math.max.apply(null, screen.rows.map(function (row) {
          return row.rowIndex;
        }));
        colMax = Math.max.apply(null, screen.rows.map(function (row) {
          return Math.max.apply(null, row.items.map(function (item) {
            return item.columnIndex;
          }));
        }));
      }

      newScreen = vm.buildScreenFromItems(screen.id, screen.label, filtered, rowMax, colMax);
      vm.showScreen(newScreen);
    }

    function buildScreenFromItems(id, label, items, rowMax, colMax) {
      var i, newItem, resultScreen;

      resultScreen = vm.buildScreenWithEmptyItems(id, label, rowMax, colMax);

      for (i = 0; i < items.length; ++i) {
        newItem = vm.clonePosItem(items[i]);
        newItem.columnIndex = i % colMax + 1;
        resultScreen.rows[Math.floor(i / rowMax)].items[newItem.columnIndex - 1] = newItem;
      }

      return resultScreen;
    }

    function buildScreenWithEmptyItems(id, label, rowMax, colMax) {
      var r,
          c,
          row,
          resultScreen = {
            id: id,
            label: label,
            rows: []
          };

      for (r = 1; r <= rowMax; ++r) {
        row = {
          rowIndex: r,
          items: []
        };
        resultScreen.rows.push(row);

        for (c = 1; c <= colMax; ++c) {
          row.items.push({
            columnIndex: c,
            itemType: 'E',
            label: ''
          });
        }
      }

      return resultScreen;
    }

    function clonePosItem(oldItem) {
      var newItem = {
        columnIndex: oldItem.columnIndex,
        itemType: oldItem.itemType,
        tags: oldItem.tags,
        label: oldItem.label,
        disabled: oldItem.disabled
      };

      if (newItem.itemType === 'P') {
        newItem.product = oldItem.product;
      } else if (newItem.itemType === 'S') {
        newItem.targetScreenId = oldItem.targetScreenId;
      }

      return newItem;
    }

    function getFilteredItemsOfScreen(screen) {
      var row, r, c, resultItems = [];

      for (r = 0; r < screen.rows.length; ++r) {
        row = screen.rows[r];
        for (c = 0; c < row.items.length; ++c) {
          if (vm.shouldItemBeVisible(row.items[c])) {
            resultItems.push(row.items[c]);
          }
        }
      }

      return resultItems;
    }

    function shouldItemBeVisible(posItem) {
      if (angular.isDefined(posItem.product)) {
        return posItem.label.toLowerCase().indexOf(vm.filters.label.val.toLowerCase()) !== -1 ||
          posItem.product.code.toLowerCase().indexOf(vm.filters.label.val.toLowerCase()) !== -1 ||
          posItem.product.barcode.toLowerCase().indexOf(vm.filters.label.val.toLowerCase()) !== -1;
      }

      return posItem.label.toLowerCase().indexOf(vm.filters.label.val.toLowerCase()) !== -1;
    }

    function clearFilters(reloadScreen) {
      vm.filters.label.val = '';

      if (angular.isUndefined(reloadScreen) || reloadScreen) {
        vm.filterChanged();
      }
    }

    $scope.$on('posGroupHasBeenChosenBroadcast', function () {
      vm.getPosScreens();
      vm.updateSaldo();
    });

    $rootScope.$on('posGoToHomeScreenBroadcast', function () {
      if (UtilService.isNotEmpty(vm.breadCrumbs)) {
        vm.goToScreen(vm.breadCrumbs[0]);
      }
    });

    $rootScope.$on('PayBillEvent', function () {
      vm.paymentModelOpen = true;
    });

    $rootScope.$on('CancelPayment', function () {
      vm.paymentModelOpen = false;
    });

    $rootScope.$on('clearShoppingCartEmit', function () {
      vm.updateSaldo();
    });

    function buildBreadCrumbsTree(screen) {
      var tree = [];
      if (screen) {
        if (screen.master === true) {
          return [screen];
        }

        if (angular.isDefined(vm.getScreenById(screen.parent))) {
          tree = buildBreadCrumbsTree(vm.getScreenById(screen.parent));
        } else {
          // fallback to first master screen
          angular.forEach(vm.posScreens, function (s) {
            if (s.master === true) {
              tree = buildBreadCrumbsTree(s);
            }
          });
        }
        tree.push(screen);
        return tree;
      }
    }

    vm.updateSaldo = function () {
      var posCookies;

      if (vm.showSaldo) {
        posCookies = CurrentPosInstanceFactory.getInstanceCookies();
        return PosGroupFactory.one(posCookies.posGroupId)
          .one('instances').one(posCookies.posInstanceId)
          .one('sessions').one(posCookies.posSessionId)
          .one('balance')
          .get()
          .then(function (balance) {
            if (angular.isDefined(balance.totalKas.EUR)) {
              vm.saldo = balance.startAmount.EUR + balance.totalKas.EUR;
            } else if (angular.isDefined(balance.startAmount.EUR)) {
              vm.saldo = balance.startAmount.EUR;
            }
          });
      }
    };

    function loadPeopleCount() {
      PeopleCountFactory.one().get().then(function (peopleCountsPerFacility) {
        vm.peopleCountsPerFacility = peopleCountsPerFacility.results;
      });
    }

    if (vm.peopleCounterVisible) {
      vm.peopleCountsPerFacility = [];
      vm.peopleCountInterval = $interval(function () {
        vm.loadPeopleCount();
      }, 10000);
    }
  }
}());
