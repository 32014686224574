(function () {
  'use strict';

  angular
    .module('facility')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('facility', {
        url: '/facility',
        views: {
          '': {
            templateUrl: 'facility/views/facility.tpl.html'
          },
          // 'nav@facility': {
          //   templateUrl: 'facility/views/facility.nav.tpl.html',
          //   controller: 'FacilityCtrl',
          //   controllerAs: 'facilityCtrl'
          // },
          'content@facility': {
            templateUrl: 'facility/views/facility.list.tpl.html',
            controller: 'FacilityCtrl',
            controllerAs: 'facilityCtrl'
          }
        }
      })
      .state('facility.overview', {
        url: '/overview',
        views: {
          'content@facility': {
            templateUrl: 'facility/views/facility.overview.tpl.html',
            controller: 'FacilityOverviewCtrl',
            controllerAs: 'facilityOverviewCtrl'
          }
        }
      })
      .state('facilitytype', {
        url: '/facilitytype',
        templateUrl: 'facility/views/facility.type.tpl.html',
        controller: 'FacilityTypeCtrl',
        controllerAs: 'facilityTypeCtrl'
      });
  }
}());
