(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name billingDivision.controller:BillingDivisionCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('billingDivision')
    .controller('BillingDivisionCtrl', BillingDivisionCtrl);

  function BillingDivisionCtrl($stateParams, $modalInstance, BillingDivisionFactory, SiteFactory) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.item = {};
    vm.sites = [];

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;

    function save() {
      var billingDivisionObj = {
        label: vm.item.label,
        invoiceReferencePrefix: vm.item.invoiceReferencePrefix,
        invoiceReferenceCounter: vm.item.invoiceReferenceCounter
      };

      if (vm.isEdit === true) {
        BillingDivisionFactory.one(vm.item.id).patch(billingDivisionObj).then(function () {
          vm.close();
        });
      } else {
        billingDivisionObj.site = vm.item.site.id;
        BillingDivisionFactory.post(billingDivisionObj).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      BillingDivisionFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'app.edit';
        vm.isEdit = true;
      }

      if (vm.isEdit === true) {
        BillingDivisionFactory.one($stateParams.id).get().then(function (item) {
          vm.item = item;
        });
      }

      SiteFactory.getList({limit: 99}).then(function (resultSites) {
        vm.sites = resultSites;
      });
    }

    function isValid() {
      if (angular.isDefined(vm.item.label)) {
        if (vm.item.label !== '') {
          if (vm.item.site && vm.item.site.id) {
            if (vm.item.site.id !== '') {
              return true;
            }
          }
        }
      }
      return false;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
