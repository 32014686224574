(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:DirectDebitContractCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('DirectDebitContractCtrl', DirectDebitContractCtrl);

  function DirectDebitContractCtrl(
      DirectDebitFactory,
      $modalInstance,
      customer,
      contracts,
      UtilService,
      CurrentUserContextFactory
    ) {
    var vm = this,
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.customer = customer;
    vm.contracts = contracts;
    vm.cancel = cancelModalInstance;
    vm.save = saveContract;
    vm.form = {
      mandateDate: {
        format: 'yyyy-MM-dd',
        isOpen: false,
        open: function () {
          vm.form.mandateDate.isOpen = true;
        }
      }
    };

    vm.contract = {
      bankAccountHolderName: '',
      bankAccountNumber: '',
      bankAccountBicCode: '',
      customer: customer.id,
      mandateDate: '',
      site: currentLoggedInUserSiteId
    };

    function postContractObject() {
      var contractObject = angular.copy(vm.contract);
      contractObject.mandateDate = contractObject.mandateDate.toISOString();

      DirectDebitFactory.post(contractObject).then(function () {
        $modalInstance.close('reloadDirectDebits');
      }, function () {
      });
    }

    function saveContract() {
      var contractsToCloseList = [],
          today = (new Date()).toISOString();

      angular.forEach(vm.contracts, function (contract) {
        if (!(contract.hasOwnProperty('terminated') && contract.terminated)) {
          contractsToCloseList.push(contract);
        }
      });

      vm.contractsToCloseCount = contractsToCloseList.length;
      if (vm.contractsToCloseCount === 0) {
        postContractObject();
      } else {
        // close all the contracts before posting the new contract object
        UtilService.promiseLoop(contractsToCloseList,
          function (contract) {
            return contract.patch({
              termination: today
            });
          }
        )
        .then(function () {
          postContractObject();
        });
      }
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }
  }
}());
