(function () {
  'use strict';

  angular
    .module('mobile')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('mobile', {
        url: '/mobile',
        templateUrl: 'mobile/views/mobile.tpl.html',
        controller: 'MobileContentCtrl',
        controllerAs: 'mobileContentCtrl'
      })
      .state('mobile.content', {
        url: '/content/overview',
        views: {
          '@mobile': {
            templateUrl: 'mobile/views/mobile.content.overview.tpl.html',
            controller: 'MobileContentCtrl',
            controllerAs: 'mobileContentCtrl'
          }
        }
      })
      .state('mobile.category', {
        url: '/category/overview',
        views: {
          '@mobile': {
            templateUrl: 'mobile/views/mobile.category.overview.tpl.html',
            controller: 'MobileCategoryCtrl',
            controllerAs: 'mobileCategoryCtrl'
          }
        }
      })
      .state('mobile.savings_campaign', {
        url: '/savings_campaign/overview',
        views: {
          '@mobile': {
            templateUrl: 'mobile/views/savings_campaign.overview.tpl.html',
            controller: 'SavingsCampaignCtrl',
            controllerAs: 'savingsCampaignCtrl'
          }
        }
      })
    ;
  }
}());
