(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .factory('ScheduledReportFactory', ScheduledReportFactory);

  function ScheduledReportFactory(Restangular) {
    return Restangular.service('scheduled_reports');
  }
}());
