(function () {
  'use strict';

  angular
    .module('analyticalAccount')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('analytical_account', {
        url: '/analytical_account',
        views: {
          '@': {
            templateUrl: 'analytical-account/views/analytical-account.tpl.html'
          },
          'nav@analytical_account': {
            templateUrl: 'analytical-account/views/analytical-account.nav.tpl.html'
          },
          'content@analytical_account': {
            templateUrl: 'analytical-account/views/analytical-account.list.tpl.html',
            controller: [
              'AnalyticalAccountFactory',
              function (AnalyticalAccountFactory) {
                var vm = this;
                AnalyticalAccountFactory.getList({limit: 99, sort: 'code,ASC'}).then(function (resultData) {
                  vm.analyticalAccounts = resultData;
                });
              }
            ],
            controllerAs: 'analyticalAccountCtrl'
          }
        }
      })
      .state('analytical_account.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'analytical-account/views/analytical-account.add.edit.modal.tpl.html',
            controller: 'AnalyticalAccountCtrl',
            controllerAs: 'analyticalAccountCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('analytical_account.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'analytical-account/views/analytical-account.add.edit.modal.tpl.html',
            controller: 'AnalyticalAccountCtrl',
            controllerAs: 'analyticalAccountCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('analytical_account.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'analytical-account/views/analytical-account.delete.modal.tpl.html',
            controller: 'AnalyticalAccountCtrl',
            controllerAs: 'analyticalAccountCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
