(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosGroupFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PosInstanceFactory', PosInstanceFactory);

  function PosInstanceFactory(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'posGroup'});
      })
      .service('points_of_sale/groups');
  }
}());
