(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:AttendancesCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('AttendancesCtrl', AttendancesCtrl);

  function AttendancesCtrl(
    $filter,
    AttachmentUtilService,
    LevelService,
    moment,
    RestUtilService,
    SwimCourseSetFactory,
    UtilService
  ) {
    var vm = this;
    vm.startDate = null;
    vm.startDateIsOpen = false;
    vm.endDate = null;
    vm.endDateIsOpen = false;
    vm.generateAttendancesReport = generateAttendancesReport;
    vm.clearParameters = clearParameters;
    vm.openEndDate = openEndDate;
    vm.openStartDate = openStartDate;
    vm.levels = [];
    vm.sets = null;
    vm.days = [
      {
        val: $filter('translate')('app.Monday'),
        code: 2
      },
      {
        val: $filter('translate')('app.Tuesday'),
        code: 3
      },
      {
        val: $filter('translate')('app.Wednesday'),
        code: 4
      },
      {
        val: $filter('translate')('app.Thursday'),
        code: 5
      },
      {
        val: $filter('translate')('app.Friday'),
        code: 6
      },
      {
        val: $filter('translate')('app.Saturday'),
        code: 7
      },
      {
        val: $filter('translate')('app.Sunday'),
        code: 1
      }
    ];

    LevelService.getAll().then(function (results) {
      vm.levels = results;
    });

    RestUtilService.getFullList(SwimCourseSetFactory, {})
    .then(function (retrievedSets) {
      vm.sets = retrievedSets;
    });

    function openStartDate() {
      vm.startDateIsOpen = true;
    }

    function openEndDate() {
      vm.endDateIsOpen = true;
    }

    function clearParameters() {
      vm.selectedLevel = null;
      vm.endDate = null;
      vm.startDate = null;
      vm.selectedDay = null;
    }

    function generateAttendancesReport(type) {
      var seriesIds = [],
          endDate = null,
          downloadParams = {
            format: type,
            type: 'attendance_sheets'
          };
      if (type === 'xlsx') {
        downloadParams.merge = true;
      }
      if (angular.isDefined(vm.endDate) && vm.endDate !== null) {
        endDate = new Date();
        endDate.setDate(vm.endDate.getDate() + 1);
        downloadParams.swimCourseSessionToDate = moment(endDate).format('YYYY-MM-DD');
      }

      if (angular.isDefined(vm.startDate) && vm.startDate !== null) {
        downloadParams.swimCourseSessionFromDate = moment(vm.startDate).format('YYYY-MM-DD');
      }

      if (vm.selectedLevel) {
        downloadParams.swimCourseLevels = [vm.selectedLevel.id];
      }

      if (angular.isDefined(vm.selectedDay) && vm.selectedDay !== null) {
        downloadParams.swimCourseSessionDayOfWeek = vm.selectedDay.code;
      }

      angular.forEach(vm.sets, function (set) {
        if (UtilService.isEmpty(set.swimCourseSeries)) {
          return;
        }
        angular.forEach(set.swimCourseSeries, function (series) {
          seriesIds.push(series.id);
        });
      });
      downloadParams.swimCourseSeries = seriesIds;

      return AttachmentUtilService.postDownloadPdf(
        'services/swim_course_series_groups_export',
        downloadParams,
        'swim_course_series_groups_attendance_sheets_' + moment().format('YYYY-MM-DD_H:i:s') + '.' + type
      );
    }
  }
}());
