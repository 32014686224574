(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:CustomerContactDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('CustomerContactDeleteCtrl', CustomerContactDeleteCtrl);

  function CustomerContactDeleteCtrl(CustomerFactory,
    $modalInstance,
    contact,
    customer,
    ToastrNotificationService
    ) {
    var vm = this;
    vm.contact = contact;
    vm.deleteCustomerContact = deleteCustomerContact;
    vm.cancel = cancelModalInstance;
    vm.showDeleteNotification = showDeleteNotification;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deleteCustomerContact(reloadOption) {
      // Delete specific customer contact (delete the link between contact and customer)
      CustomerFactory.one(customer.id).one('contacts', contact.id).remove().then(function () {
        // Close the modal view
        $modalInstance.close(reloadOption);
        // Show notification at successful remove
        vm.showDeleteNotification();
      });
    }

    function showDeleteNotification() {
      // Show notification at successful remove
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-removed',
        'app.contact',
        'app.item-successfully-removed',
        'app.contact'
      );
    }
  }
}());
