(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name image
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('image')
    .factory('ImageFactory', ImageFactory);

  function ImageFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'photo'});
    })
    .service('photos');
  }
}());
