(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:LevelFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('LevelFactory', LevelFactory);

  function LevelFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseLevel'});
    })
    .service('swim_courses/levels');
  }
}());
