(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.factory:PeopleCountFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .factory('PeopleCountFactory', PeopleCountFactory);

  function PeopleCountFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'peopleCount'});
    })
    .service('people_count');
  }
}());
