(function () {
  'use strict';
  /* global moment */
  /**
   * @ngdoc object
   * @name utils.factory:ProductUtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('ProductUtilService', ProductUtilService);

  function ProductUtilService($filter, _, LogService, ImageFactory) {
    return {
      calculateDomMonthlyPrice: function (billItemObject, startPrice) {
        return startPrice / billItemObject.subscriptionDuration;
      },
      calculateProRataPrice: function (billItemObject, startPrice) {
        var proRataPrice = 0,
            proRataDays = 0,
            exclPrice = angular.copy(startPrice),
            subscriptionDate = angular.copy(billItemObject.subscriptionDate),
            daysInMonth = moment(billItemObject.startDate).endOf('month').format('DD');
        LogService.log('days in month' + daysInMonth, 'debug');
        // 1000 * 60 * 60 * 24 = 86400000
        if (billItemObject.startDate.getDate() < 20) {
          proRataDays = Math.ceil((subscriptionDate - billItemObject.startDate) / 86400000);
          proRataPrice = (exclPrice / (billItemObject.subscriptionDuration / billItemObject.subscriptionPaymentInterval) / daysInMonth) * proRataDays;
        } else {
          proRataDays = Math.ceil((subscriptionDate.subtract(1, 'month') - billItemObject.startDate) / 86400000);
          proRataPrice = (exclPrice / (billItemObject.subscriptionDuration / billItemObject.subscriptionPaymentInterval) / daysInMonth) * proRataDays;
          proRataPrice += exclPrice / billItemObject.subscriptionDuration;
        }
        return proRataPrice;
      },
      calculateSubscriptionDate: function (product) {
        var date;
        if (product.startDate.getDate() < 20) {
          date = moment(product.startDate).add(1, 'month').startOf('month');
        } else {
          date = moment(product.startDate).add(2, 'month').startOf('month');
        }
        return date;
      },
      calculateExclPrice: function (inclPrice, vat) {
        var exclPrice, vatPrice;
        exclPrice = inclPrice / ((vat / 100) + 1);
        vatPrice = inclPrice - exclPrice;
        return {
          exclPrice: exclPrice,
          vatPrice: vatPrice
        };
      },
      calculateInclPrice: function (exclPrice, vat) {
        var inclPrice, vatPrice;
        vatPrice = this.roundPrice(exclPrice * (vat / 100));
        inclPrice = this.roundPrice(exclPrice + vatPrice);
        return {
          inclPrice: inclPrice,
          vatPrice: vatPrice
        };
      },
      calculateVATOverviewForSale: function (saleVATAmounts) {
        // This function calculates the overview of the VAT payed on a complete sale.
        var VATAmount6 = 0,
            VATAmount21 = 0,
            that = this,
            VATAmounts = [];
        angular.forEach(saleVATAmounts, function (saleItem) {
          if (saleItem.percentage === 6) {
            VATAmount6 += (that.roundPrice(saleItem.vatPrice) * saleItem.amount);
          } else if (saleItem.percentage === 21) {
            VATAmount21 += (that.roundPrice(saleItem.vatPrice) * saleItem.amount);
          }
        });
        if (VATAmount6 > 0) {
          VATAmounts.push({percentage: 6, vatPrice: VATAmount6});
        }
        if (VATAmount21 > 0) {
          VATAmounts.push({percentage: 21, vatPrice: VATAmount21});
        }
        if (VATAmount6 > 0 || VATAmount21 > 0) {
          VATAmounts.push({total: VATAmount6 + VATAmount21});
        }
        return VATAmounts;
      },
      calculateDiscountWithPercentage: function (price, amount, discountPercentage) {
        var discount = discountPercentage / 100;
        return amount * (price * discount);
      },
      roundPrice: function (price) {
        // add fixes for problem with rounding float numbers, see #48013
        var priceToFixed = Number(Math.abs(price) * 100).toFixed(5);
        return Math.sign(price) * Math.round(priceToFixed) / 100;
      },
      calculateTotalPrice: function (price, amount, discountPercentage) {
        // check if discount percentage is not undefined
        discountPercentage = discountPercentage ? discountPercentage : 0;
        return price * amount * (100 - discountPercentage) / 100;
      },
      calculateTotalPriceDiscountPercentageAmount: function (price, amount, discountPercentage, discountAmount) {
        discountPercentage = discountPercentage ? discountPercentage : 0;
        discountAmount = discountAmount ? discountAmount : 0;

        return ((price * amount) - discountAmount) * (100 - discountPercentage) / 100;
      },
      getProductDetails: function (product) {
        var productPrice,
            productPhoto,
            productSubscription,
            productSubProducts;

        productPrice = $filter('filter')(product.productComponents, function (pc) {
          return pc.type === 'price';
        });
        // filter the components for the price item
        productPhoto = $filter('filter')(product.productComponents, function (pc) {
          return pc.type === 'photo';
        });
        // filter the components for the products item
        productSubProducts = $filter('filter')(product.productComponents, function (pc) {
          return pc.type === 'products';
        });
        // filter the components for the productSubscription item
        productSubscription = $filter('filter')(product.productComponents, function (pc) {
          return pc.type === 'subscription_contract';
        });

        // insert price param to the object (instead of accessing it via the productComponents)
        if (angular.isDefined(productPrice[0])) {
          product.price = productPrice[0];
          product.price.vat = productPrice[0].vatRate.percentage;
          product.price.vatPrice = this.calculateInclPrice(productPrice[0].price, productPrice[0].vatRate.percentage).vatPrice;
        }

        if (productSubscription && productSubscription.length > 0) {
          product.productSubscription = productSubscription;
        }

        // Check if contains images
        if (productPhoto && productPhoto.length > 0 && angular.isDefined(productPhoto[0].photo)) {
          ImageFactory.one(productPhoto[0].photo.id).get().then(function (image) {
            switch (image.encoding) {
              case 'base64':
                product.productPhoto = 'data:' + image.mimeType + ';';
                product.productPhoto += image.encoding + ',' + image.data;
                product.productHasPhoto = true;
                break;
              default:
                LogService.log('encoding ' + image.encoding + '  not supported', 'debug');
                break;
            }
          });
        }

        if (productSubProducts && productSubProducts.length > 0) {
          product.productSubProducts = productSubProducts[0];
        }

        return product;
      }
    };
  }
}());
