(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:AccountancyPeriodsAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('AccountancyPeriodsAddEditCtrl', AccountancyPeriodsAddEditCtrl);

  function AccountancyPeriodsAddEditCtrl(AccountancyPeriodFactory,
                                         ToastrNotificationService,
                                         CurrentUserContextFactory,
                                         $modalInstance,
                                         accountancyPeriod,
                                         openedAsEdit) {
    var vm = this,
        now = new Date();

    /* ----- PARAMS ----- */
    vm.period = accountancyPeriod;
    vm.closeAllOpened = false;
    vm.datePickerIsOpened = false;
    vm.endDatePickerIsOpened = false;
    vm.openedAsEdit = openedAsEdit;
    vm.minDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, -now.getTimezoneOffset() / 60);

    // Initialize period
    if (!vm.openedAsEdit) {
      vm.period = {
        startDate: new Date(now.getFullYear(), now.getMonth() + 1, 1, -now.getTimezoneOffset() / 60),
        endDate: new Date(now.getFullYear(), now.getMonth() + 2, 0, -now.getTimezoneOffset() / 60),
        isOpened: true
      };
    }

    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.isEdit = isEdit;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openDatePicker = openDatePicker;
    /* ----- END FUNCTIONS ----- */

    /* ----- DATE PICKER ----- */
    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function isEdit() {
      return vm.openedAsEdit;
    }

    /* ----- END DATE PICKER ----- */

    /* ----- OTHER ----- */
    vm.saveAccountancyPeriod = function () {
      var periodObject = {
        isOpened: vm.period.isOpened
      };

      if (vm.period.startDate) {
        periodObject.startDate = vm.period.startDate.toISOString().substring(0, 10);
      }

      if (vm.period.endDate) {
        periodObject.endDate = vm.period.endDate.toISOString().substring(0, 10);
      }

      if (vm.isEdit()) {
        // Save changes to contact
        return AccountancyPeriodFactory.one(vm.period.id).patch(periodObject).then(function () {
          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'success',
            'app.item-saved',
            'app.accountancy-period',
            'app.item-successfully-saved',
            'app.accountancy-period'
          );

          $modalInstance.close('reloadList');
        });
      }

      periodObject.site = CurrentUserContextFactory.getSiteId();

      return AccountancyPeriodFactory.post(periodObject).then(function () {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'success',
          'app.item-saved',
          'app.accountancy-period',
          'app.item-successfully-saved',
          'app.accountancy-period'
        );

        $modalInstance.close('reloadList');
      });
    };
    /* ----- END OTHER ----- */
  }
}());
