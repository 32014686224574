(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:UserContextCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('UserContextCtrl', UserContextCtrl);

  function UserContextCtrl(_,
                           $modalInstance,
                           UserFactory,
                           isEdit,
                           RoleFactory,
                           SiteFactory,
                           userId,
                           UserContextFactory,
                           userContextId) {
    var vm = this;
    vm.title = 'app.add';
    vm.item = {};
    vm.entities = [];
    vm.isEdit = isEdit;
    vm.roles = [];
    vm.sites = [];

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;
    vm.activate = activate;

    init();

    function save() {
      var object = {
        roles: vm.item.roles
      };

      if (isEdit) {
        UserContextFactory.one(vm.item.id).patch(object).then(function () {
          vm.close();
        });
      } else {
        object.site = vm.item.site;
        object.user = vm.item.user;
        object.active = true;

        UserContextFactory.post(object).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      UserContextFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      var roleParams = {
        sort: 'label,ASC',
        limit: 99
      };

      RoleFactory.getList(roleParams).then(function (results) {
        vm.roles = results;
      });

      if (isEdit) {
        vm.title = 'app.edit';
        UserContextFactory.one(userContextId).get().then(function (result) {
          vm.item = result;
          vm.item.user = vm.item.user.id;
          vm.item.site = vm.item.site.id;
          vm.item.roles = vm.item.roles.map(function (v) {
            return v.id;
          });
        });
      } else {
        UserFactory.one(userId).get().then(function (result) {
          var userParams = {
                limit: 99,
                sort: 'label,ASC'
              },
              usedSiteIds = [];

          vm.item.user = result.id;
          usedSiteIds = result.userContexts.map(function (v) {
            return v.site.id;
          });

          // Only use sites for which no userContext exists
          SiteFactory.getList(userParams).then(function (results) {
            vm.sites = results.filter(function (v) {
              return _.indexOf(usedSiteIds, v.id) === -1;
            });
          });
        });
      }
    }

    function isValid() {
      return angular.isDefined(vm.item.user) && angular.isDefined(vm.item.site);
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }

    function activate() {
      UserContextFactory.one(vm.item.id).patch({active: true}).then(function () {
        vm.close();
      });
    }
  }
}());
