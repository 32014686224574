(function () {
  'use strict';

  /* @ngdoc object
   * @name accountancy
   * @description
   *
   */
  angular
    .module('accountancy', [
      'ui.router',
      'restangular',
      'ui.router.tabs'
    ]);
}());
