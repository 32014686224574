(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosCustomerListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosCustomerListCtrl', PosCustomerListCtrl);

  function PosCustomerListCtrl(
    CustomerFactory,
    $modalInstance,
    $q,
    customerId,
    customerContactTypeId,
    SettingsService,
    UtilService
    ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.contacts = [];
    vm.customerSearchQuery = '';
    vm.getCustomerContacts = getCustomerContacts;
    vm.getSearchParams = getSearchParams;
    vm.contactsAreLoading = false;
    vm.addCustomerToSale = addCustomerToSale;
    vm.insertCustomerContacts = insertCustomerContacts;
    vm.hideCustomerGroupColumn = SettingsService.get('customers.posSearch.hideGroupColumn', false);
    vm.showContactType = SettingsService.get('customers.posSearch.showContactType', false);
    vm.showSSN = SettingsService.get('customers.posSearch.showSSN', false);
    vm.showVatNumber = SettingsService.get('customers.posSearch.showVatNumber', false);
    vm.showCompanyNumber = SettingsService.get('customers.posSearch.showCompanyNumber', false);
    vm.showLoadMoreButton = false;
    vm.disableAutoSearchCustomer = SettingsService.get('pos.disableAutoSearchCustomer', false);
    vm.customerSSNSearchQuery = '';
    vm.searchStarted = false;
    vm.customerVatNumberSearchQuery = '';
    vm.customerCompanyNumberSearchQuery = '';
    vm.customerLabel = SettingsService.get('pos.customerLabel', 'klant');
    vm.hideFilterSitesSearchField = SettingsService.get('customer.hideFilterSitesSearchField', true);
    vm.customerSearchAllSitesFilter = vm.hideFilterSitesSearchField;
    vm.searchOnEnterKey = searchOnEnterKey;

    // this will pre-load company contacts, otherwise only an empty input appears and people get confused
    if (customerId) {
      vm.contacts = [];

      vm.contactsAreLoading = true;

      CustomerFactory.getList({
        'filter[]': ['id,' + customerId],
        limit: 99,
        hidden: 0,
        partial: 1
      }).then(function (resultCustomers) {
        vm.insertCustomerContacts(resultCustomers);
      });
    }

    function getCustomerContacts(more) {
      vm.contacts = [];

      if (!UtilService.isNotEmpty(vm.customerSearchQuery) && !UtilService.isNotEmpty(vm.customerSSNSearchQuery) &&
        !UtilService.isNotEmpty(vm.customerVatNumberSearchQuery) && !UtilService.isNotEmpty(vm.customerCompanyNumberSearchQuery)) {
        return;
      }

      vm.contactsAreLoading = true;
      vm.showLoadMoreButton = false;
      vm.searchStarted = true;

      CustomerFactory.getList(vm.getSearchParams(more))
        .then(function (resultCustomers) {
          vm.insertCustomerContacts(resultCustomers);
          if (resultCustomers.count === 20) {
            vm.showLoadMoreButton = true;
          }
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
    }

    function getSearchParams(more) {
      // Add Filter param for fulltext search
      var filter = [
        'OR,label,LIKE ' + vm.customerSearchQuery +
          ',customerContacts.contact.label,LIKE ' + vm.customerSearchQuery
      ];
      if (/\d/.test(vm.customerSearchQuery)) {
        filter[0] += ',memberCards.label,LIKE ' + vm.customerSearchQuery;
      }

      if (UtilService.isNotEmpty(vm.customerVatNumberSearchQuery) && vm.showVatNumber) {
        filter = ['customerContacts.contact.contactData.ContactVatData.value,LIKE ' + vm.customerVatNumberSearchQuery];
      }

      if (UtilService.isNotEmpty(vm.customerCompanyNumberSearchQuery) && vm.showCompanyNumber) {
        filter = ['customerContacts.contact.contactData.ContactCompanyNumberData.value,LIKE ' + vm.customerCompanyNumberSearchQuery];
      }

      if (UtilService.isNotEmpty(vm.customerSSNSearchQuery) && vm.showSSN) {
        filter = ['customerContacts.contact.contactData.ContactStringData.value,LIKE ' + vm.customerSSNSearchQuery];
      }
      // Add customer ID filter if required
      if (customerId) {
        filter.push('id,' + customerId);
      }

      // Add customer contact type ID filter if required
      if (customerContactTypeId) {
        filter.push('customerContacts.customerContactType.id,' + customerContactTypeId);
      }

      return {
        'filter[]': filter,
        limit: (angular.isDefined(more) && more === true) ? 99 : 20,
        hidden: 0,
        partial: 1,
        siteFilter: !vm.customerSearchAllSitesFilter ? 1 : 0
      };
    }

    function insertCustomerContacts(customersToProcess) {
      if (!customersToProcess) {
        vm.contactsAreLoading = false;
        return;
      }
      // Loop customers in customersToProcess, ignoring non-billing contacts
      angular.forEach(customersToProcess, function (customer) {
        customer.customerContacts.filter(function (cc) {
          return cc.customerContactType.code !== 'BILLING';
        }).map(function (nonBillingContact) {
          return {
            customer: customer,
            contact: nonBillingContact
          };
        }).forEach(function (item) {
          if (vm.contacts.findIndex(function (x) {
            return x.customer.id === item.customer.id && x.contact.id === item.contact.id;
          }) < 0) {
            UtilService.retrieveContactData(item.contact.contact.contactData);
            vm.contacts.push(item);
          }
        });
      });
      vm.contactsAreLoading = false;
    }

    function addCustomerToSale(combinedCustomerObject) {
      // Close the modal instance and add the contact
      $modalInstance.close(combinedCustomerObject);
    }

    function cancelModalInstance() {
      // Dismiss the modal instance
      $modalInstance.dismiss('cancel');
    }

    function searchOnEnterKey(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        return vm.getCustomerContacts();
      }
    }
  }
}());
