/* eslint-disable no-unused-vars */
(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:StuckSaleCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('StuckSaleCtrl', StuckSaleCtrl);

  function StuckSaleCtrl(
    $filter,
    LogService,
    UtilService,
    ToastrNotificationService,
    StuckSalesEditSaleByIdFactory,
    CurrentUserContextFactory,
    DiscountFactory,
    ProductFactory,
    ProductUtilService,
    $timeout,
    SaleFactory,
    SettingsService,
    $q,
    SaleStatusFactory,
    PaymentMethodsFactory,
    StuckSalesCompleteSaleByIdFactory,
    StuckSalesDiscardSaleByIdFactory,
    StuckSalesGetSaleModificationsOfASale,
    PosGroupFactory
  ) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.stuckSale = null;
    vm.modalOpened = false;
    vm.saleId = null;
    vm.loadedSale = null;
    vm.selectedCustomer = null;
    vm.selectedCustomerId = null;
    vm.customerLabel = '';
    vm.pos_startTime = null;
    vm.pos_endTime = null;

    vm.newSaleStartTime = new Date();
    vm.newSaleEndTime = new Date();

    vm.saleProducts = [];
    vm.deletedSaleProducts = [];

    vm.loggedInUserSiteId = CurrentUserContextFactory.getUserContextCookies().siteId;
    vm.enableInternalComments = SettingsService.get('pos.saleItem.enableInternalComments');
    vm.enableASTD = SettingsService.get('accountancy.saleModificationTool.enableASTD', false);
    vm.completedAtDate = new Date();

    vm.paymentMethods = [];
    vm.payments = [];
    vm.addedPayments = [];
    vm.salePaymentMethod = null;
    vm.totalPaymentMethods = 0;
    vm.deletedPayments = [];
    vm.paymentMethodAmount = null;

    vm.remarks = '';
    vm.externalOrderId = '';

    vm.loadedSaleModifications = [];
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.checkFeatureFlag = checkFeatureFlag;
    vm.showStuckSaleModalAddView = showStuckSaleModalAddView;
    vm.loadStuckSaleModalSelectView = loadStuckSaleModalSelectView;
    vm.showCustomerListModal = showCustomerListModal;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.removeCustomer = removeCustomer;
    vm.saveSale = saveSale;
    vm.saleItems = saleItems;
    vm.checkStartEndTime = checkStartEndTime;
    vm.checkRemarksFieldNotEmpty = checkRemarksFieldNotEmpty;
    vm.getAsyncProduct = getAsyncProduct;
    vm.removeProduct = removeProduct;
    vm.addSelectedProduct = addSelectedProduct;
    vm.discountPercentageChanged = discountPercentageChanged;
    vm.discountAmountChanged = discountAmountChanged;
    vm.recountTotals = recountTotals;
    vm.getInvoiceProductIndex = getInvoiceProductIndex;
    vm.setPriceToProduct = setPriceToProduct;
    vm.getSaleItemRestObject = getSaleItemRestObject;
    vm.ProductUtilService = ProductUtilService;

    vm.scrubOldSaleItems = scrubOldSaleItems;
    vm.persistSale = persistSale;
    vm.persistSaleItems = persistSaleItems;
    vm.getSaleRestObject = getSaleRestObject;
    vm.isEdit = isEdit;
    vm.isPosSale = isPosSale;

    vm.savePaymentMethod = savePaymentMethod;
    vm.recountTotalsPaymentMethods = recountTotalsPaymentMethods;
    vm.addPaymentMethod = addPaymentMethod;
    vm.removePaymentMethod = removePaymentMethod;
    vm.getPaymentMethodIndex = getPaymentMethodIndex;

    vm.getSaleModifications = getSaleModifications;
    vm.posSessionBalance = posSessionBalance;
    vm.showModalDeleteView = showModalDeleteView;
    /* ----- END FUNCTIONS ----- */

    PaymentMethodsFactory.getAccountancyList({
      limit: 99,
      'filter[]': [
        'site.id,' + CurrentUserContextFactory.getSiteId()
      ],
      sort: 'weight,asc'
    }).then(function (paymentMethods) {
      vm.paymentMethods = paymentMethods;
    });

    function checkFeatureFlag() {
      return;
    }

    function showStuckSaleModalAddView() {
      resetWorkingArea();

      LogService.log('opening stuck sale add modal', 'debug');
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.stuck-sale.add.modal.tpl.html',
        controller: 'StuckSaleAddCtrl',
        controllerAs: 'stuckSaleAddCtrl'
      },
        modalResultHandlerAddStuckSale,
        modalFailureHandler
      );
    }

    // Load Sale
    function loadStuckSaleModalSelectView() {
      resetWorkingArea();

      LogService.log('opening stuck sale load sale modal', 'debug');
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.stuck-sale.load.modal.tpl.html',
        controller: 'StuckSaleSelectCtrl',
        controllerAs: 'StuckSaleSelectCtrl'
      },
        modalResultHandlerLoadStuckSale,
        modalFailureHandler
      );
    }

    // CUSTOMER FICHE SELECT
    function showCustomerListModal() {
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.stuck-sale.load-customer.modal.tpl.html',
        controller: 'StuckSaleCustomerListCtrl',
        controllerAs: 'StuckSaleCustomerListCtrl'
      },
        modalResultHandlerCustomerSelect,
        modalFailureHandler
      );
    }

    // Result handler add modal
    function modalResultHandlerAddStuckSale(addedSale) {
      vm.loadedSale = addedSale.sale;
      vm.saleId = addedSale.sale.id;
      vm.enableDiscounts = !SettingsService.get('pos.disableDiscounts', false);
      posSessionBalance(
        addedSale.sale.pointOfSaleSession.endedAt,
        addedSale.sale.pointOfSaleSession.pointOfSaleInstance.pointOfSaleGroup.id,
        addedSale.sale.pointOfSaleSession.id,
        addedSale.sale.pointOfSaleSession.pointOfSaleInstance.id
      );

      getSaleModifications(vm.saleId);

      setPosTime(addedSale);
      setTimepickers(addedSale);
    }

    // Result handler load modal
    function modalResultHandlerLoadStuckSale(loadedSale) {
      vm.loadedSale = loadedSale.sale;
      vm.saleId = loadedSale.sale.id;
      vm.externalOrderId = vm.loadedSale.externalOrderId;
      vm.enableDiscounts = !SettingsService.get('pos.disableDiscounts', false);

      // Get pos session balance
      posSessionBalance(
        loadedSale.sale.pointOfSaleSession.endedAt,
        loadedSale.sale.pointOfSaleSession.pointOfSaleInstance.pointOfSaleGroup.id,
        loadedSale.sale.pointOfSaleSession.id,
        loadedSale.sale.pointOfSaleSession.pointOfSaleInstance.id
      );

      // Get sale its salemodifications using saleId
      getSaleModifications(vm.saleId);

      // Show sale customer
      if (angular.isDefined(loadedSale.sale.customer)) {
        vm.selectedCustomer = loadedSale.sale.customer;
        vm.customerLabel = loadedSale.sale.customer.label;
        vm.selectedCustomerId = loadedSale.sale.customer.id;
      }

      // Set pos time for later check
      setPosTime(loadedSale);
      // Set timepicker values
      setTimepickers(loadedSale);
      loadSaleItems(loadedSale.sale);
      recountTotalsPaymentMethods();
    }

    // Result handler select different customer
    function modalResultHandlerCustomerSelect(selectedCustomer) {
      vm.selectedCustomer = selectedCustomer;
      vm.customerLabel = selectedCustomer.customer.label;
      vm.selectedCustomerId = selectedCustomer.customer.id;
    }

    function modalFailureHandler() {
      return;
    }

    function returnOfModalInstance() {
      // Check if dismiss or close action of modal view was called
      vm.modalInstance.result.then(function () {
        vm.modalOpened = false;
      }, function () {
        vm.modalOpened = false;
      });
    }

    function removeCustomer() {
      vm.selectedCustomer = null;
      vm.customerLabel = '';
      vm.selectedCustomerId = null;
      // CHECK IF SALE ITEM REQUIRES CUSTOMER
    }

    function setPosTime(sale) {
      vm.pos_startTime = new Date(sale.sale.pointOfSaleSession.createdAt);
      vm.pos_endTime = new Date(sale.sale.pointOfSaleSession.endedAt);
    }

    function setTimepickers(loadedSale) {
      vm.newSaleStartTime = new Date(loadedSale.sale.createdAt);
      vm.newSaleEndTime = new Date(loadedSale.sale.createdAt);
      if (angular.isDefined(loadedSale.sale.completedAt)) {
        vm.newSaleEndTime = new Date(loadedSale.sale.completedAt);
      }
    }

    function loadSaleItems(sale) {
      // iterate the sale items and change some of the data
      PaymentMethodsFactory.getAccountancyList({
        limit: 99,
        'filter[]': [
          'site.id,' + CurrentUserContextFactory.getSiteId()
        ],
        sort: 'weight,asc'
      }).then(function (paymentMethods) {
        vm.paymentMethods = paymentMethods;
      });

      vm.disableSalePaymentMethodEdit = SettingsService.get('accountancy.disableSalePaymentMethodEdit', false);


      SaleFactory.one(sale.id).get()
        .then(function (retrievedSale) {
          if (retrievedSale.hasOwnProperty('payments')) {
            $timeout(function () {
              // sale.payments = retrievedSale.payments;
              vm.loadedSale.payments = retrievedSale.payments;
              vm.payments = retrievedSale.payments;
              recountTotalsPaymentMethods();
            });
          }

          if (UtilService.isNotEmpty(retrievedSale.completedAt)) {
            vm.completedAtDate = new Date(retrievedSale.completedAt);
          }

          // csi = current sale item
          angular.forEach(retrievedSale.saleItems, function (csi) {
            // eslint-disable-next-line vars-on-top
            var currentProduct, i;
            currentProduct = csi.product;
            currentProduct.saleItemId = csi.id;
            currentProduct.comments = UtilService.isNotEmpty(csi.comments) ? csi.comments : '';
            currentProduct.internalComments = UtilService.isNotEmpty(csi.internalComments) ? csi.internalComments : '';
            // keep whole parameters object, so parameters which we don't edit here don't get lost
            currentProduct.parametersObject = csi.parameters || {};

            // make the price component more accessible
            for (i = 0; i < currentProduct.productComponents.length; ++i) {
              if (currentProduct.productComponents[i].type === 'price') {
                currentProduct.price = currentProduct.productComponents[i];
                break;
              }
            }

            // override price with sale item price if it exists (higher priority than product price)
            if (csi.hasOwnProperty('price')) {
              currentProduct.price.price = csi.price;
            }

            // apply discount from sale item
            if (csi.discount) {
              if (csi.discount.percentage) {
                currentProduct.discount = csi.discount.id;
                currentProduct.previousDiscountPercentage = csi.discount.percentage;
                currentProduct.discountPercentage = csi.discount.percentage;
                currentProduct.discountType = DiscountFactory.getPercentageDiscountType();
              } else if (csi.discount.price) {
                currentProduct.discount = csi.discount.id;
                currentProduct.discountAmount = csi.discount.price;
                currentProduct.discountType = DiscountFactory.getAmountDiscountType();
              }
            }

            // apply combi product parent/child parameters from sale item
            if (csi.parameters) {
              if (csi.parameters.isGroupProduct) {
                currentProduct.isGroupProduct = csi.parameters.isGroupProduct;
                currentProduct.subProducts = [];
              } else if (csi.parameters.parentSaleItem) {
                // this loop assumes that parent sale items are processed first, which should be the case everytime
                angular.forEach(vm.saleProducts, function (saleProduct) {
                  if (saleProduct.saleItemId === csi.parameters.parentSaleItem) {
                    currentProduct.isSubProduct = true;
                    currentProduct.parentSaleItem = saleProduct;
                    saleProduct.subProducts.push(currentProduct);
                  }
                });
              }
            }

            currentProduct.amount = csi.quantity;
            vm.saleProducts.push(currentProduct);
          });
        }).then(function () {
          vm.recountTotals();
        });
    }

    // these functions retrieve products and customers via ajax
    // for the typeahead field
    function getAsyncProduct(viewValue) {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };
      params['filter[]'] = ['label,LIKE ' + viewValue];
      params['filter[]'].push('mostRecent,TRUE');
      params['filter[]'].push('OR,hidden,FALSE,hidden,NULL');
      params['filter[]'].push('OR,blueprint,FALSE,blueprint,NULL');

      return ProductFactory.getList(params)
        .then(function (resultProducts) {
          return resultProducts.data;
        });
    }

    function savePaymentMethod(payment) {
      if (vm.isEdit()) {
        SaleFactory.one(vm.loadedSale.sale.id).one('payments').one(payment.id)
          .patch({
            paymentMethod: payment.paymentMethod.id
          });
      }
      recountTotalsPaymentMethods();
    }

    function removeProduct(product) {
      var productIndex;
      if (product.isGroupProduct) {
        product.subProducts.forEach(function (p) {
          vm.removeProduct(p);
        });
      }
      productIndex = vm.getInvoiceProductIndex(product.id);
      // if the product exists in the backend, add it to the list of deleted items
      if (vm.saleProducts[productIndex].hasOwnProperty('saleItemId')) {
        vm.deletedSaleProducts.push(vm.saleProducts[productIndex]);
      }
      vm.saleProducts.splice(productIndex, 1);
      vm.recountTotals();
    }

    // add the currently selected product to the list of sale products
    function addSelectedProduct(selectedProduct) {
      var i, deletedProduct, product, prIndex, subProduct;
      if (angular.isUndefined(selectedProduct) || selectedProduct === null) {
        selectedProduct = vm.selectedProduct;
      } else {
        selectedProduct.isSubProduct = true;
      }
      if (selectedProduct !== null && selectedProduct.hasOwnProperty('id')) {
        // retrieve the product components from the backend when adding the product
        // to the list so we can easily access the price
        ProductFactory.one(selectedProduct.id).get().then(function (p) {
          product = p.data;
          if (product.productComponents.length > 0) {
            for (i = 0; i < product.productComponents.length; ++i) {
              if (product.productComponents[i].type === 'price') {
                selectedProduct.price = product.productComponents[i];
              }
              if (product.productComponents[i].type === 'products') {
                selectedProduct.isGroupProduct = true;
                selectedProduct.subProducts = [];
                for (prIndex = 0; prIndex < product.productComponents[i].products.length; ++prIndex) {
                  subProduct = product.productComponents[i].products[prIndex];
                  vm.addSelectedProduct(subProduct);
                  selectedProduct.subProducts.push(subProduct);
                  subProduct.parentSaleItem = selectedProduct;
                }
              }
            }
            selectedProduct.price.priceIncl = ProductUtilService.calculateInclPrice(selectedProduct.price.price, selectedProduct.price.vatRate.percentage).inclPrice;
            selectedProduct.amount = 1;
            selectedProduct.discountPercentage = 0;
            selectedProduct.discountAmount = 0;
            selectedProduct.previousDiscountPercentage = 0;
            selectedProduct.previousDiscountAmount = 0;
            selectedProduct.price.originalPrice = selectedProduct.price.price;
            selectedProduct.price.originalPriceIncl = selectedProduct.price.priceIncl;
            vm.saleProducts.push(selectedProduct);

            // check if we previously deleted the product, if so - 'undelete' it!
            for (i = 0; i < vm.deletedSaleProducts.length; ++i) {
              deletedProduct = vm.deletedSaleProducts[i];
              if (deletedProduct.id === selectedProduct.id) {
                vm.deletedSaleProducts.splice(i, 1);
              }
            }
            vm.recountTotals();
            vm.selectedProduct = null;
          }
        });
      }
    }

    function getInvoiceProductIndex(productId) {
      var cp, i, foundIndex = -1;
      for (i = 0; i < vm.saleProducts.length; ++i) {
        cp = vm.saleProducts[i];
        if (cp.id === productId) {
          foundIndex = i;
          break;
        }
      }
      return foundIndex;
    }

    function isEdit() {
      return vm.loadedSale !== null;
    }

    function isPosSale(saleObject) {
      if (angular.isDefined(saleObject.hasOwnProperty('pointOfSaleSession'))) {
        return saleObject.hasOwnProperty('pointOfSaleSession');
      }
      return false;
    }

    function discountPercentageChanged() {
      angular.forEach(vm.saleProducts, function (product) {
        // If discount added, mandatory comment
        if (product.comments || angular.isDefined(product.parentSaleItem)) {
          if (product.discountPercentage !== product.previousDiscountPercentage) {
            if (!product.discount) {
              DiscountFactory.post({
                percentage: product.discountPercentage,
                site: vm.loggedInUserSiteId
              }).then(function (discountObject) {
                product.discountObject = discountObject;
                product.discount = discountObject.id;
              });
            } else {
              DiscountFactory.one(product.discount).patch({
                percentage: product.discountPercentage,
                price: null
              });
            }
            product.discountType = DiscountFactory.getPercentageDiscountType();
            if (product.subProducts) {
              if (product.discountPercentage !== product.previousDiscountPercentage) {
                angular.forEach(product.subProducts, function (subProduct) {
                  subProduct.discountPercentage = product.discountPercentage;
                  subProduct.discountType = DiscountFactory.getPercentageDiscountType();
                });
              }
            }
            product.previousDiscountPercentage = product.discountPercentage;
          }
        } else {
          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'error',
            'app.error',
            '',
            'accountancy.stuck-sale.mandatory-comment'
          );
        }
      });
      vm.recountTotals();
    }

    function discountAmountChanged() {
      angular.forEach(vm.saleProducts, function (product) {
        // If discount added, mandatory comment
        if (product.comments) {
          if (product.discountAmount !== product.previousDiscountAmount) {
            if (!product.discount) {
              DiscountFactory.post({
                price: product.discountAmount,
                site: vm.loggedInUserSiteId
              }).then(function (discountObject) {
                product.discountObject = discountObject;
                product.discount = discountObject.id;
              });
            } else {
              DiscountFactory.one(product.discount).patch({
                percentage: null,
                price: product.discountAmount
              });
            }
            product.discountType = DiscountFactory.getAmountDiscountType();
            if (product.subProducts) {
              if (product.discountAmount !== product.previousAmountPercentage) {
                angular.forEach(product.subProducts, function (subProduct) {
                  subProduct.discountAmount = product.discountAmount;
                  subProduct.discountType = DiscountFactory.getAmountDiscountType();
                });
              }
            }
            product.previousDiscountAmount = product.discountAmount;
          }
        } else {
          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'error',
            'app.error',
            '',
            'accountancy.stuck-sale.mandatory-comment'
          );
        }
      });
      vm.recountTotals();
    }


    function addPaymentMethod(selectedPaymentMethod) {
      var paymentObject;

      if (angular.isUndefined(selectedPaymentMethod) || selectedPaymentMethod === null) {
        selectedPaymentMethod = vm.selectedPaymentMethod;
      }

      if (selectedPaymentMethod !== null && vm.paymentMethodAmount !== null) {
        paymentObject = {
          amount: parseFloat(vm.paymentMethodAmount),
          currency: 'EUR',
          paymentMethod: vm.selectedPaymentMethod,
          reference: (((Number(vm.loadedSale.reference) + (new Date()).getTime()) % 4294967295) + 1).toString()
        };
        vm.payments.push(paymentObject);
        vm.addedPayments.push(paymentObject);
      }
      // Call recountTotalsPaymentMethods
      recountTotalsPaymentMethods();
      vm.selectedPaymentMethod = null;
      vm.paymentMethodAmount = 0;
    }

    function removePaymentMethod(paymentMethod) {
      var paymentIndex;

      paymentIndex = vm.getPaymentMethodIndex(paymentMethod.reference);
      vm.deletedPayments.push(vm.payments[paymentIndex]);
      vm.payments.splice(paymentIndex, 1);

      // Call recountTotalsPaymentMethods
      recountTotalsPaymentMethods();
    }

    function getPaymentMethodIndex(paymentReference) {
      var cpm, i, foundIndex = -1;
      for (i = 0; i < vm.payments.length; i++) {
        cpm = vm.payments[i];
        if (cpm.reference === paymentReference) {
          foundIndex = i;
          break;
        }
      }
      return foundIndex;
    }

    function recountTotalsPaymentMethods() {
      // Als payment method wordt toegevoegd, gewijzigd of verwijderd recount
      vm.totalPaymentMethods = 0;
      // angular.forEach(vm.loadedSale.payments, function (paymentMethod) {
      //   vm.totalPaymentMethods += paymentMethod.amount;
      // });
      angular.forEach(vm.payments, function (paymentMethod) {
        vm.totalPaymentMethods += paymentMethod.amount;
      });
    }

    function recountTotals() {
      var quantity;
      vm.totalPrice = 0;
      vm.totalPriceExclVAT = 0;
      angular.forEach(vm.saleProducts, function (product) {
        //we dont want to count root-products
        if (!product.isGroupProduct) {
          if (product.isSubProduct) {
            quantity = product.amount * product.parentSaleItem.amount;
          } else {
            quantity = product.amount;
          }
          if (angular.isUndefined(product.price.priceIncl)) {
            product.price.priceIncl = vm.ProductUtilService.calculateInclPrice(product.price.price, product.price.vatRate.percentage).inclPrice;
          }
          vm.totalPrice += vm.ProductUtilService.calculateTotalPriceDiscountPercentageAmount(product.price.priceIncl, quantity, product.discountPercentage, product.discountAmount);
          vm.totalPriceExclVAT += vm.ProductUtilService.calculateTotalPriceDiscountPercentageAmount(product.price.price, quantity, product.discountPercentage, product.discountAmount);
        }
      });
    }

    function setPriceToProduct(product) {
      var correctPrice, parentPrice;
      correctPrice = ProductUtilService.calculateExclPrice(product.price.priceIncl, product.price.vatRate.percentage);
      product.price.price = correctPrice.exclPrice;
      //if price of sub-product is changed, propagate it to root-product
      if (product.isSubProduct) {
        parentPrice = 0;
        product.parentSaleItem.subProducts.forEach(function (subProduct) {
          parentPrice += subProduct.price.priceIncl;
        });
        product.parentSaleItem.price.price = parentPrice;
      }
      vm.recountTotals();
    }

    // get a sale item rest object
    function getSaleItemRestObject(ip) {
      var restObject;
      // add price overriding, discount refs later
      restObject = {
        product: ip.id,
        quantity: ip.amount,
        // unit price for 1 product, excl VAT
        price: ip.price.price,
        comments: ip.comments,
        internalComments: ip.internalComments,
        discount: ip.discount || null
      };

      restObject.parameters = ip.parametersObject ? ip.parametersObject : {};
      restObject.parameters.discount = ip.discount !== null;
      restObject.parameters.discountPercentage = ip.discountPercentage || null;
      restObject.parameters.discountAmount = ip.discountAmount || null;
      restObject.parameters.discountType = ip.discountType || null;
      if (ip.isGroupProduct) {
        restObject.parameters.isGroupProduct = true;
        restObject.price = 0;
      } else {
        restObject.parameters.isGroupProduct = false;
      }
      if (ip.parentSaleItem) {
        restObject.parameters.parentSaleItem = ip.parentSaleItem.saleItemId;
        restObject.quantity = ip.parentSaleItem.amount;
      }

      return restObject;
    }

    function checkCustomerRequired() {
      var passed = true;
      vm.saleProducts.forEach(function (saleItem) {
        if (angular.isDefined(saleItem.contactRequired) && saleItem.contactRequired && (vm.selectedCustomer === null)) {
          passed = false;
          return;
        }
      });
      return passed;
    }

    function saveSale(status) {
      var queryParams, remarksFilled;

      remarksFilled = checkRemarksFieldNotEmpty();

      if (!remarksFilled) {
        return Promise.reject();
      }

      if (!checkCustomerRequired()) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'error',
          'app.error',
          '',
          'app.needs-contact'
        );
        return Promise.reject();
      }

      if (!checkStartEndTime()) {
        if (vm.newSaleStartTime < vm.pos_startTime || vm.newSaleStartTime > vm.pos_endTime) {
          vm.newSaleStartTime = vm.pos_startTime;
        }
        if (vm.newSaleEndTime > vm.pos_endTime || vm.newSaleEndTime < vm.pos_startTime) {
          vm.newSaleEndTime = vm.pos_endTime;
        }
      }
      // eslint-disable-next-line no-warning-comments
      // TODO: Check sale items need customer
      saleItems().then(function () {
        queryParams = {
          context: CurrentUserContextFactory.getUserContextId(),
          startTime: vm.newSaleStartTime,
          endTime: vm.newSaleEndTime,
          customer: vm.selectedCustomerId,
          deletedPayments: vm.deletedPayments,
          payments: vm.payments,
          remarks: vm.remarks,
          externalOrderId: vm.externalOrderId
        };

        switch (status) {
          case 'IN_PROGRESS':
            StuckSalesEditSaleByIdFactory.one(vm.saleId).patch(queryParams).then(function (editSale) {
              resetWorkingArea();
            });
            break;
          case 'COMPLETED':
            // var queryParams;
            StuckSalesCompleteSaleByIdFactory.one(vm.saleId).patch(queryParams).then(function (completeSale) {
              if (!completeSale[1]) {
                ToastrNotificationService.showTranslatedAndFormattedNotification(
                  'error',
                  'app.error',
                  '',
                  'accountancy.stuck-sale.status-error'
                );
              }
              resetWorkingArea();
            });
            break;
          case 'DISCARD':
            StuckSalesDiscardSaleByIdFactory.one(vm.saleId).patch(queryParams).then(function (discardSale) {
              resetWorkingArea();
            });
            break;
          default:
            StuckSalesEditSaleByIdFactory.one(vm.saleId).patch(queryParams).then(function (editSale) {
              resetWorkingArea();
            });
            break;
        }
      });

      // After save action success unload working area

      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-updated',
        'app.sale',
        'app.item-successfully-updated',
        'app.stuck-sale.success-loaded'
      );
      return Promise.resolve();
    }

    function saleItems() {
      LogService.log('Sale save sequence started', 'debug');
      return vm.persistSale()
        .then(function () {
          LogService.log('sale persisted', 'debug');
          return vm.scrubOldSaleItems();
        })
        .then(function () {
          LogService.log('Old sale items scrubbed', 'debug');
          if (vm.saleProducts.length === 0) {
            LogService.log('no sale items to persist - closing sale modal', 'debug');
          } else {
            // persist sale items and generate a draft invoice
            return vm.persistSaleItems();
          }
        });
    }

    function checkRemarksFieldNotEmpty() {
      if (vm.remarks === '') {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'error',
          'app.error',
          '',
          'accountancy.stuck-sale.remarks-error'
        );
        return false;
      }
      return true;
    }

    function checkStartEndTime() {
      var saleDate = new Date(vm.loadedSale.createdAt);
      vm.newSaleStartTime.setFullYear(saleDate.getFullYear());
      vm.newSaleStartTime.setMonth(saleDate.getMonth());
      vm.newSaleStartTime.setDate(saleDate.getDate());
      vm.newSaleStartTime.setSeconds(0);
      vm.newSaleStartTime.setMilliseconds(0);

      vm.newSaleEndTime.setFullYear(saleDate.getFullYear());
      vm.newSaleEndTime.setMonth(saleDate.getMonth());
      vm.newSaleEndTime.setDate(saleDate.getDate());
      vm.newSaleEndTime.setSeconds(0);
      vm.newSaleEndTime.setMilliseconds(0);

      vm.pos_startTime.setSeconds(0);
      vm.pos_startTime.setMilliseconds(0);
      vm.pos_endTime.setSeconds(0);
      vm.pos_endTime.setMilliseconds(0);

      // first check if pos session not empty
      if (angular.isDefined(vm.pos_startTime) && angular.isDefined(vm.pos_endTime)) {
        // Check start time in pos session
        if (vm.newSaleStartTime >= vm.pos_startTime && vm.newSaleStartTime <= vm.pos_endTime) {
          // Check end time in pos session and after start time
          if (vm.newSaleEndTime >= vm.pos_startTime && vm.newSaleEndTime <= vm.pos_endTime && vm.newSaleEndTime >= vm.newSaleStartTime) {
            return true;
          }
        }
      }
      return false;
    }

    function persistSale() {
      var isNewSale = !vm.isEdit();
      return vm.getSaleRestObject(isNewSale).then(function (saleRestObject) {
        if (!isNewSale) {
          return SaleFactory.one(vm.loadedSale.id).patch(saleRestObject);
        }
        saleRestObject.invoiceRequested = true;
        return SaleFactory.one('1').customPOST(saleRestObject).then(function (postedSale) {
          vm.loadedSale = postedSale;
        });
      });
    }

    function scrubOldSaleItems() {
      return Promise.all(vm.deletedSaleProducts.map(function (product) {
        return SaleFactory.one(vm.loadedSale.id).one('items').one(product.saleItemId).one('1').remove();
      }));
    }

    /*
     * Order of sale items in the create sale form matters to the client and it has to be the same on the invoice.
     * Sale items on the invoice are ordered by creation time in backend.
     * We could introduce some order property to the backend sale item entity, but we don't like that.
     * So instead we persists all items sequentially using a promise chain.
     */
    function persistSaleItems() {
      var promiseChain = $q.resolve();

      // persist the sale items sequentially
      LogService.log('Preparing to persist sale items', 'debug');
      vm.saleProducts.forEach(function (saleItem) {
        promiseChain = promiseChain.then(function () {
          var saleItemRestObject = vm.getSaleItemRestObject(saleItem);

          if (saleItem.hasOwnProperty('saleItemId')) {
            return SaleFactory.one(vm.loadedSale.id)
              .one('items').one(saleItem.saleItemId)
              // do not call external cart API
              .one('1')
              .patch(saleItemRestObject);
          }
          return SaleFactory.one(vm.loadedSale.id)
            .one('items')
            // do not call external cart API
            .one('1')
            .customPOST(saleItemRestObject)
            .then(function (result) {
              saleItem.saleItemId = result.id;
            });
        });
      });

      // finalize sale after all items have been persisted
      promiseChain = promiseChain.then(function () {
        LogService.log('Sale items persisted.', 'debug');
        if (!vm.finalizeAfterSubmit || !UtilService.isNotEmpty(vm.completedAtDate)) {
          // $modalInstance.close('reloadSaleListData');
          return;
        }

        SaleStatusFactory.getStatusByCode('completed').then(function (completedStatus) {
          var patchObject = { saleStatus: completedStatus.id };
          LogService.log('finalize immediately enabled, finalizing sale', 'debug');
          SaleFactory.one(vm.loadedSale.id).patch(patchObject).then(function () {
            if (vm.finalizeAfterSubmit) {
              LogService.log('Creating invoice.', 'debug');
              vm.persistInvoice();
            }
          });
        });
      });
      return promiseChain;
    }

    // get a sale rest object
    function getSaleRestObject(includeSiteId) {
      var restObject = {
            facility: vm.currentFacilityId
          },
          saleStatusCode = 'in_progress';

      if (UtilService.isNotEmpty(vm.completedAtDate) && includeSiteId) {
        restObject.completedAt = vm.completedAtDate;
        saleStatusCode = 'completed';
      }

      // pos sale -> keep sale status
      if (vm.isEdit() && vm.isPosSale(vm.loadedSale)) {
        // restObject.saleStatus = vm.saleStatuses[vm.currentSale.saleStatus.code];
        saleStatusCode = vm.loadedSale.saleStatus.code;
      }

      if (vm.saleCustomer !== null && angular.isDefined(vm.saleCustomer)) {
        restObject.customer = vm.saleCustomer.id;
      }

      if (includeSiteId) {
        restObject.site = vm.loggedInUserSiteId;
      }

      if (vm.selectedPos !== null && angular.isDefined(vm.selectedPos)) {
        restObject.pointOfSaleSession = vm.selectedPos.id;
      }

      restObject.invoiceAlreadyPaid = vm.invoiceAlreadyPaid;

      return SaleStatusFactory.getStatusByCode(saleStatusCode).then(function (saleStatus) {
        restObject.saleStatus = saleStatus.id;
        return restObject;
      });
    }

    function resetWorkingArea() {
      vm.newSaleStartTime = new Date();
      vm.newSaleEndTime = new Date();
      vm.selectedCustomerId = null;
      vm.customerLabel = '';
      vm.totalPriceExclVAT = 0;
      vm.totalPrice = 0;
      vm.totalPaymentMethods = 0;
      vm.saleProducts = [];
      vm.addedPayments = [];
      vm.deletedPayments = [];
      vm.payments = [];
      vm.remarks = '';
      vm.externalOrderId = '';
      vm.saleItems = null;
      vm.loadedSale = null;
    }

    function getSaleModifications(saleId) {
      var queryParams = saleId + '?context=' + CurrentUserContextFactory.getUserContextId();

      StuckSalesGetSaleModificationsOfASale.one().customGET(queryParams).then(function (saleModifications) {
        $timeout(function () {
          angular.forEach(saleModifications, function (saleMod, key) {
            saleMod.createdAt = new Date(saleMod.createdAt);
            saleMod.diff.createdAt = new Date(saleMod.diff.createdAt);
            saleMod.diff.completedAt = new Date(saleMod.diff.completedAt);
          }, saleModifications);
          vm.loadedSaleModifications = saleModifications;
        });
      });
    }

    function posSessionBalance(endedAt, groId, posId, insId) {
      var sessionEnded, paymentMethodMapping = {};

      if (angular.isDefined(endedAt) && endedAt !== null) {
        sessionEnded = true;
      }

      vm.paymentMethods.forEach(function (method) {
        paymentMethodMapping[method.code] = method.label;
      });

      PosGroupFactory.one(groId)
        .one('instances').one(insId)
        .one('sessions').one(posId)
        .one('balance')
        .get()
        .then(function (balance) {
          vm.paymentTotals = [];
          angular.forEach((sessionEnded ? balance.enteredPerPaymentMethod : balance.projectedPerPaymentMethod), function (amount, code) {
            if (amount.EUR || balance.balancePerPaymentMethod[code].EUR) {
              vm.paymentTotals.push({
                label: angular.isDefined(paymentMethodMapping[code]) ? paymentMethodMapping[code] : $filter('uconlyfirst')(code),
                entered: amount.EUR,
                balance: (sessionEnded ? balance.balancePerPaymentMethod[code].EUR : '-'),
                projectedPerPaymentMethod: angular.isDefined(balance.projectedPerPaymentMethod[code]) ? balance.projectedPerPaymentMethod[code].EUR : 0
              });
            }
          });
          vm.paymentTotals.push({
            label: $filter('uconlyfirst')($filter('translate')('app.start_amount')),
            entered: balance.startAmount.EUR,
            balance: '-',
            projectedPerPaymentMethod: balance.projectedPerPaymentMethod.EUR
          });
          vm.paymentTotals.push({
            label: $filter('uconlyfirst')($filter('translate')('app.total')),
            entered: balance.projectedAmount.EUR,
            balance: balance.balanceAmount.EUR,
            projectedPerPaymentMethod: balance.projectedPerPaymentMethod.EUR
          });
        });
    }

    function showModalDeleteView() {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.stuck-sale.add', 'app.delete-msg', 'app.this_sale',
        function (returnValue) {
          if (returnValue) {
            vm.saveSale('DISCARD');
          }
        });
    }
  }
}());
