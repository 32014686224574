(function () {
  'use strict';

  angular
    .module('fitness')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('fitness', {
        url: '/fitness',
        templateUrl: 'fitness/views/fitness.tpl.html',
        controller: 'FitnessCtrl',
        controllerAs: 'fitnessCtrl'
      }).state('fitnessdetail', {
        url: '/fitness/detail/:id',
        templateUrl: 'fitness/views/fitness.detail.tpl.html',
        controller: 'FitnessCtrl',
        controllerAs: 'fitnessCtrl'
      }).state('fitnessinstance', {
        url: '/fitness/:courseId/detail/:eventId/instance/detail/:id',
        templateUrl: 'fitness/views/fitness.instance.detail.tpl.html',
        controller: 'FitnessInstanceCtrl',
        controllerAs: 'fitnessInstanceCtrl'
      });
  }
}());
