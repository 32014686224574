(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.factory:ContactRelationTypeFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('ContactRelationTypeFactory', ContactRelationTypeFactory);

  function ContactRelationTypeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'contactRelationType'});
    })
    .service('contacts/relations/types');
  }
}());
