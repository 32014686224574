(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .controller('ProductDeleteCtrl', ProductDeleteCtrl);

  function ProductDeleteCtrl(ProductFactory, $modalInstance, product) {
    var vm = this;
    vm.product = product;
    vm.cancel = cancelModalInstance;
    vm.deleteProduct = deleteProduct;

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function deleteProduct(reloadOption) {
      ProductFactory.one(vm.product.uuid).remove().then(function () {
        $modalInstance.close(reloadOption);
      });
    }
  }
}());
