(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:PosSessionListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('PosSessionListCtrl', PosSessionListCtrl);

  function PosSessionListCtrl(
    PosGroupFactory,
    PosSessionFactory,
    PosSessionService,
    Restangular,
    SettingsService,
    TicketFactory,
    ToastrNotificationService,
    CurrentUserContextFactory,
    PaymentMethodSiteFactory,
    UtilService,
    LogService,
    $localStorage,
    $q,
    $timeout,
    $state,
    $stateParams,
    $filter,
    CurrentPosInstanceFactory,
    _,
    $modal
  ) {
    var vm = this;
    vm.sessions = [];
    vm.sums = [];
    vm.countedPaymentCodes = [];
    vm.pagination = {
      totalItems: null,
      maxPages: 10,
      currentPage: ($stateParams.currentPage) ? $stateParams.currentPage : 1,
      itemsPerPage: 20
    };
    vm.paymentsParams = {
      limit: 99,
      sort: 'weight,asc',
      'filter[]': ['site.id,' + CurrentUserContextFactory.getSiteId()]
    };
    vm.checkBoxState = {};
    vm.LOCAL_STORAGE_FILTERS_NAME = 'reports.sessionList.filters';
    vm.LOCAL_STORAGE_CHECKBOX_STATE_NAME = 'reports.sessionList.checkboxState.filters';

    if (angular.isDefined($stateParams.loadFilters) &&
      angular.isDefined($localStorage[vm.LOCAL_STORAGE_FILTERS_NAME])) {
      vm.filters = $localStorage[vm.LOCAL_STORAGE_FILTERS_NAME];
    } else {
      // copy filter object, so dont destroy it when filters are changed
      vm.filters = getDefaultFilters();
    }

    if (angular.isDefined($stateParams.loadCheckboxState) &&
      angular.isDefined($localStorage[vm.LOCAL_STORAGE_CHECKBOX_STATE_NAME])) {
      vm.checkBoxState = $localStorage[vm.LOCAL_STORAGE_CHECKBOX_STATE_NAME];
    }

    vm.extendedApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '2');
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.approvalFlowVersion4Enabled = (SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.approvalFlowVersion4 = false;
    vm.hideStartAmount = SettingsService.get('pos.hideStartAmount', false);
    vm.hideElectronicPaymentMethod = SettingsService.get('posSession.hideElectronicPaymentMethod', false);
    vm.showClosedSessionEditButton = !SettingsService.get('pos.session.disableClosedSessionEdit', false) &&
      !SettingsService.get('pos.hideShiftEndTotalsInputs', false);
    vm.showPrintEndOfSessionTicketButton = !SettingsService.get('pos.disableShiftEndTicket', false);
    vm.showSessionFilter = SettingsService.get('pos.session.showFilter', true);
    vm.standaloneMode = SettingsService.get('pos.standaloneMode', false);
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.posSessionService = PosSessionService;
    vm.reportsHideStartAmount = SettingsService.get('reports.hideStartAmount', false);
    vm.reportsDisplayCashAmount = SettingsService.get('reports.displayCashAmount', false);

    vm.addSessionsToOrderEngineTransaction = addSessionsToOrderEngineTransaction;
    vm.approveSessions = approveSessions;
    vm.editSessionTotals = editSessionTotals;
    vm.filterChanged = filterChanged;
    vm.getSelectedForApproval = getSelectedForApproval;
    vm.getSelectedForOrderEngineTransaction = getSelectedForOrderEngineTransaction;
    vm.isSelectingForApproval = isSelectingForApproval;
    vm.isSelectingForOrderEngineTransaction = isSelectingForOrderEngineTransaction;
    vm.loadData = loadData;
    vm.loadSingleSessionsPage = loadSingleSessionsPage;
    vm.printEosTicket = printEosTicket;
    vm.removeSession = removeSession;
    vm.resetFilters = resetFilters;
    vm.selectSessionsOnAllPages = selectSessionsOnAllPages;
    vm.selectSessionsOnPageForApproval = selectSessionsOnPageForApproval;
    vm.selectSessionsOnPageForOrderEngineTransaction = selectSessionsOnPageForOrderEngineTransaction;
    vm.sessionCanBeAddedToOrderEngineTransaction = sessionCanBeAddedToOrderEngineTransaction;
    vm.sessionCanBeApproved = sessionCanBeApproved;
    vm.showAddPosSessionModal = showAddPosSessionModal;
    vm.showDeleteNotification = showDeleteNotification;
    vm.unselectAllPages = unselectAllPages;
    vm.unselectPage = unselectPage;
    vm.viewSession = viewSession;
    vm.reloadBySelectedPropertyName = reloadBySelectedPropertyName;
    vm.showNegativeSessionModal = showNegativeSessionModal;
    vm.sessionsWithNegativeEndAmountExist = false;

    if (angular.isUndefined($stateParams.tab) || $stateParams.tab === 'sessions') {
      vm.loadData();
    }

    function loadSingleSessionsPage(page) {
      var value, queryParams = {}, condition = '', defer = $q.defer(), loadingModal;

      queryParams = {
        offset: (page - 1) * vm.pagination.itemsPerPage,
        limit: vm.pagination.itemsPerPage,
        sort: 'createdAt,DESC'
      };

      loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });

      _.each(vm.filters, function (filter, index) {
        if (angular.isDefined(filter.val) && filter.val) {
          switch (index) {
            case 'beginDate':
              if (angular.isString(filter.val)) {
                filter.val = new Date(filter.val);
              }

              filter.val.setHours(0);
              filter.val.setMinutes(0);
              filter.val.setSeconds(0);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'endDate':
              if (angular.isString(filter.val)) {
                filter.val = new Date(filter.val);
              }

              filter.val.setHours(23);
              filter.val.setMinutes(59);
              filter.val.setSeconds(59);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'approved':
              switch (filter.val) {
                case 'approved':
                  queryParams.approved = true;
                  break;
                case 'rejected':
                  queryParams.rejected = true;
                  queryParams.approved = false;
                  break;
                case 'blank':
                  queryParams.approved = false;
                  queryParams.rejected = false;
                  break;
                default:
                  break;
              }
              return;
            case 'cashierStatus':
              switch (filter.val) {
                case 'ok':
                  queryParams.cashierOk = true;
                  break;
                case 'notOk':
                  queryParams.cashierNotOk = true;
                  queryParams.cashierOk = false;
                  break;
                case 'empty':
                  queryParams.cashierEmpty = true;
                  break;
                case 'blank':
                  queryParams.cashierOk = false;
                  queryParams.cashierNotOk = false;
                  queryParams.cashierEmpty = false;
                  break;
                default:
                  break;
              }
              return;
            case 'orderEngineStatus':
              switch (filter.val) {
                case 'notNotified':
                  queryParams.addedToTransaction = false;
                  queryParams.requestedToBeNotified = false;
                  queryParams.notified = false;
                  break;
                case 'addedToTransaction':
                  queryParams.addedToTransaction = true;
                  queryParams.requestedToBeNotified = false;
                  queryParams.notified = false;
                  break;
                case 'pending':
                  queryParams.requestedToBeNotified = true;
                  queryParams.notified = false;
                  break;
                case 'notified':
                  queryParams.notified = true;
                  break;
                default:
                  break;
              }
              return;
            case 'invoiceSession':
              value = 2;
              break;
            default:
              value = filter.val;
              break;
          }

          condition = '';
          if (UtilService.isNotEmpty(filter.cond)) {
            condition = filter.cond + ' ';
          }

          if (filter.hasOwnProperty('isParam') && filter.isParam) {
            queryParams[index] = value;
          } else {
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            if (angular.isDefined(filter.allowNullIfSet) && filter.allowNullIfSet === true) {
              queryParams['filter[' + index + ']'] = 'OR,' + queryParams['filter[' + index + ']'] + ',' + filter.field + ',NULL';
            }
          }
        }
      });

      LogService.log('PosSessionListCtrl::loadSingleSessionPage() -> Loading page: ' + page, 'debug');

      PosSessionFactory.getList(queryParams)
        .then(function (data) {
          var sums = {endAmount: 0},
              countedPaymentCodes = {};

          PaymentMethodSiteFactory.getList(vm.paymentsParams).then(function (paymentMethodsResult) {
            angular.forEach(paymentMethodsResult, function (paymentMethod) {
              if (vm.hideElectronicPaymentMethod && angular.isUndefined(countedPaymentCodes[paymentMethod.paymentMethod.code])) {
                if (paymentMethod.paymentMethod.code !== 'ELECTRONIC_PAYMENT') {
                  countedPaymentCodes[paymentMethod.paymentMethod.code] = paymentMethod.paymentMethod.translatedLabel;
                }
              } else {
                countedPaymentCodes[paymentMethod.paymentMethod.code] = paymentMethod.paymentMethod.translatedLabel;
              }
            });
          }).then(function () {
            angular.forEach(data, function (session) {
              if (session.wasTallied) {
                sums.endAmount += session.endAmount;
                session.countedPayments = {};
                session.totalKas = 0;
                angular.forEach(session.payments, function (payment) {
                  if (payment.amount !== 0) {
                    if (angular.isUndefined(countedPaymentCodes[payment.paymentMethod.code])) {
                      countedPaymentCodes[payment.paymentMethod.code] = payment.paymentMethod.translatedLabel;
                    }

                    if (payment.paymentMethod.code === 'CASH' || payment.paymentMethod.code === 'CHEQUE' || payment.paymentMethod.code === 'PURCHASING') {
                      session.totalKas += payment.amount;
                    }

                    session.countedPayments[payment.paymentMethod.code] = payment;
                    if (angular.isUndefined(sums[payment.paymentMethod.code])) {
                      sums[payment.paymentMethod.code] = 0;
                    }
                    sums[payment.paymentMethod.code] += payment.amount;
                  }
                });
              }
            });
            defer.resolve();
          });
          loadingModal.close();
          $timeout(function () {
            vm.sums[page] = sums;
            vm.countedPaymentCodes[page] = countedPaymentCodes;
            vm.sessions[page] = data;
            vm.pagination.totalItems = data.count;
            setCheckboxStateForPage(data);
          });
        });

      return defer.promise;
    }

    function loadData(reload, page) {
      LogService.log('PosSessionListCtrl::loadData() -> Loading Pos sessions.', 'debug');
      if (angular.isUndefined(reload)) {
        reload = false;
      }
      if (angular.isUndefined(page)) {
        page = vm.pagination.currentPage;
      }

      // is page empty or items should be updated
      if (angular.isUndefined(vm.sessions[page]) || (reload === true)) {
        return vm.loadSingleSessionsPage(page);
      }

      // Pagination: redirect to page 1 after an update
      if (page > 1 && reload === true) {
        vm.pagination.currentPage = 1;
        return vm.loadSingleSessionsPage(vm.pagination.currentPage);
      }
    }

    if (vm.approvalFlowVersion4Enabled) {
      CurrentPosInstanceFactory.getCurrentPosInstance().then(function (instance) {
        if (instance) {
          vm.approvalFlowVersion4 = instance.approvalFlowVersion4;
        }
      });
    }

    function editSessionTotals(session) {
      var deferred = $q.defer();

      UtilService.showModal(
        {
          templateUrl: 'pos/views/sessions/pos.endshift.modal.tpl.html',
          controller: 'EndPosShiftCtrl',
          controllerAs: 'endPosShiftCtrl',
          size: 'lg',
          resolve: {
            session: function () {
              return session;
            }
          }
        },
        function () {
          // reload the current page on success to refresh the promise buttons
          vm.sessions[vm.pagination.currentPage] = null;
          vm.loadData(true);
        },
        function () {
          deferred.reject();
        });

      return deferred.promise;
    }

    function printEosTicket(session) {
      LogService.log('Printing EOS ticket for session ' + session.id, 'debug');
      return PosGroupFactory.one(session.posGroupId)
        .one('instances').one(session.posInstanceId)
        .one('sessions').one(session.id)
        .one('balance')
        .get()
        .then(function (balance) {
          LogService.log('Balance: ' + balance, 'debug');
          session.balance = balance;

          // last parameter is for counted bills, which were not persisted to the backend
          // when the session was closed, so just pass an empty array
          TicketFactory.printEndSessionTicket(
            session.posGroupId,
            session.posInstanceId,
            session,
            []
          );
        }, function () {
          LogService.log('Could not retrieve balance for this session!', 'debug');
        });
    }

    function viewSession(session) {
      // save filters before leaving state
      $localStorage[vm.LOCAL_STORAGE_FILTERS_NAME] = vm.filters;
      $localStorage[vm.LOCAL_STORAGE_CHECKBOX_STATE_NAME] = getCheckboxState();
      $state.go('sessiondetail', {
        groId: session.posGroupId,
        insId: session.posInstanceId,
        sesId: session.id,
        indexPage: vm.pagination.currentPage
      });
    }

    function setCheckboxStateForPage(page) {
      var key;
      angular.forEach(page, function (item) {
        if (vm.checkBoxState.hasOwnProperty(item.id)) {
          key = Object.keys(vm.checkBoxState[item.id]);
          item[key[0]] = vm.checkBoxState[item.id][key[0]];
        }
      });
    }

    function getCheckboxState() {
      var sessionCheckboxState = {}, ownProp = ['selectedForApproval', 'selectedForOrderEngineTransaction'];
      angular.forEach(vm.sessions, function (sessionItem) {
        angular.forEach(sessionItem, function (item) {
          angular.forEach(ownProp, function (key) {
            if (item.hasOwnProperty(key)) {
              sessionCheckboxState[item.id] = {};
              sessionCheckboxState[item.id][key] = item[key];
            }
          });
        });
      });

      return sessionCheckboxState;
    }

    function filterChanged() {
      vm.sessions = [];
      vm.loadData(true);
    }

    function showAddPosSessionModal() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.add.session.modal.tpl.html',
        controller: 'AddPosSessionCtrl',
        controllerAs: 'addPosSessionCtrl',
        size: 'lg'
      }, function () {
        vm.loadData(true);
      });
    }

    function sessionCanBeApproved(session) {
      return (vm.useExternalOrderEngine && vm.extendedApprovalFlow && !vm.standaloneMode &&
        session.wasTallied && !session.isApproved && !session.isCashierEmpty && session.numberOfSales > 0) ||
        (vm.useExternalOrderEngine && vm.approvalFlowVersion4 && !vm.standaloneMode &&
        session.wasTallied && !session.isApproved && !session.isCashierEmpty && session.numberOfSales > 0);
    }

    function sessionCanBeAddedToOrderEngineTransaction(session) {
      return (vm.useExternalOrderEngine && !vm.automaticApprovalFlow && !vm.standaloneMode &&
        session.isApproved && !session.isAddedToTransaction && !session.isRequestedToBeNotified &&
        !session.isNotified && !session.isCashierEmpty) ||
        (vm.useExternalOrderEngine && vm.approvalFlowVersion4 && !vm.standaloneMode &&
          session.isApproved && !session.isAddedToTransaction && !session.isRequestedToBeNotified &&
          !session.isNotified && !session.isCashierEmpty);
    }

    function getSelectedForApproval() {
      var ids = [];

      _.each(vm.sessions, function (page) {
        _.each(page, function (item) {
          if (item.hasOwnProperty('selectedForApproval') && item.selectedForApproval && !item.isApproved) {
            ids.push(item.id);
          }
        });
      });

      return ids;
    }

    function getSelectedForOrderEngineTransaction(checkTotal) {
      var ids = [], totalEndAmount = 0;

      _.each(vm.sessions, function (page) {
        _.each(page, function (item) {
          if (item.hasOwnProperty('selectedForOrderEngineTransaction') && item.selectedForOrderEngineTransaction &&
            item.isApproved && !item.isRequestedToBeNotified && !item.isNotified) {
            if (angular.isDefined(checkTotal) && checkTotal) {
              totalEndAmount += item.endAmount;
            }
            ids.push(item.id);
          }
        });
      });
      vm.sessionsWithNegativeEndAmountExist = false;
      // if total is negative don't send sessions to order engine
      if (angular.isDefined(checkTotal) && checkTotal && totalEndAmount < 0) {
        vm.showNegativeSessionModal();
        vm.sessionsWithNegativeEndAmountExist = true;
        ids = [];
      }

      return ids;
    }

    function isSelectingForApproval() {
      return UtilService.isNotEmpty(vm.getSelectedForApproval());
    }

    function isSelectingForOrderEngineTransaction() {
      return UtilService.isNotEmpty(vm.getSelectedForOrderEngineTransaction());
    }

    function selectSessionsOnPageForApproval(page) {
      if (angular.isUndefined(page)) {
        page = vm.pagination.currentPage;
      }

      vm.unselectAllPages('orderEngineTransaction');

      $timeout(function () {
        _.each(vm.sessions[page], function (item) {
          if (vm.sessionCanBeApproved(item)) {
            item.selectedForApproval = true;
          }
        });
      });
    }

    function selectSessionsOnPageForOrderEngineTransaction(page) {
      if (angular.isUndefined(page)) {
        page = vm.pagination.currentPage;
      }

      vm.unselectAllPages('approval');

      $timeout(function () {
        _.each(vm.sessions[page], function (item) {
          if (vm.sessionCanBeAddedToOrderEngineTransaction(item)) {
            item.selectedForOrderEngineTransaction = true;
          }
        });
      });
    }

    function selectSessionsOnAllPages(category) {
      var page,
          maxPages = 1,
          promises = [];

      vm.checkBoxState = {};
      // we have to load data for all pages first
      if (vm.pagination.totalItems !== null) {
        maxPages = Math.ceil(vm.pagination.totalItems / vm.pagination.itemsPerPage);
      }
      for (page = 1; page <= maxPages; page++) {
        promises.push(vm.loadData(false, page));
      }

      Promise.all(promises).then(function () {
        vm.unselectAllPages();
        for (page in vm.sessions) {
          if (vm.sessions.hasOwnProperty(page)) {
            switch (category) {
              case 'approval':
                vm.selectSessionsOnPageForApproval(page);
                break;
              case 'orderEngineTransaction':
                vm.selectSessionsOnPageForOrderEngineTransaction(page);
                break;
              default:
            }
          }
        }
      }, function () {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'warning',
          'app.warning',
          '',
          'app.general-error'
        );
      });
    }

    function unselectPage(category, page) {
      if (angular.isUndefined(page)) {
        page = vm.pagination.currentPage;
      }

      _.each(vm.sessions[page], function (item) {
        if (category !== 'orderEngineTransaction' && item.hasOwnProperty('selectedForApproval') && item.selectedForApproval) {
          item.selectedForApproval = false;
        }
        if (category !== 'approval' && item.hasOwnProperty('selectedForOrderEngineTransaction') && item.selectedForOrderEngineTransaction) {
          item.selectedForOrderEngineTransaction = false;
        }
      });
    }

    function unselectAllPages(category) {
      var page;
      vm.checkBoxState = {};
      for (page in vm.sessions) {
        if (vm.sessions.hasOwnProperty(page)) {
          vm.unselectPage(category, page);
        }
      }
    }

    function approveSessions() {
      var ids = [];

      LogService.log('PosSessionListCtrl::approveSessions() -> sending batch', 'debug');

      ids = vm.getSelectedForApproval();
      if (ids.length > 0) {
        return Restangular.all('points_of_sale/batch_approve').customPOST({sessions: ids}).then(function () {
          LogService.log('PosSessionListCtrl::approveSessions() -> success', 'debug');

          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'success',
            'app.success',
            '',
            'accountancy.pos_sessions_approved'
          );
          vm.reloadBySelectedPropertyName('selectedForApproval', 'isApproved');
        }, function () {
          UtilService.reloadState();
        });
      }

      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'warning',
        'app.warning',
        '',
        'accountancy.no_closed_pos_sessions_selected'
      );

      return Promise.reject();
    }

    function addSessionsToOrderEngineTransaction() {
      var ids = [];

      LogService.log('PosSessionListCtrl::addSessionsToOrderEngineTransaction() -> sending batch', 'debug');

      ids = vm.getSelectedForOrderEngineTransaction(true);
      if (ids.length > 0) {
        return Restangular.all('points_of_sale/batch_add_to_transaction').customPOST({sessions: ids}).then(function (transaction) {
          LogService.log('PosSessionListCtrl::addSessionsToOrderEngineTransaction() -> success, transaction: ', 'debug');
          LogService.log(transaction, 'debug');

          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'success',
            'app.success',
            '',
            'accountancy.sale_order_engine_notification_successfully_requested'
          );

          if (vm.approvalFlowVersion4Enabled) {
            Restangular.all('points_of_sale/confirm_transaction').one(transaction.id).get().then(function () {
              ToastrNotificationService.showTranslatedAndFormattedNotification(
                'success',
                'app.success',
                '',
                'accountancy.sale_order_engine_notification_successfully_requested'
              );
              vm.reloadBySelectedPropertyName('selectedForOrderEngineTransaction', 'isAddedToTransaction');
            });
          } else {
            $state.go('digipolis_order', {id: transaction.id});
          }
        });
      }

      if (!vm.sessionsWithNegativeEndAmountExist) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'warning',
          'app.warning',
          '',
          'accountancy.no_approved_pos_sessions_selected'
        );
      }

      return Promise.reject();
    }

    function reloadBySelectedPropertyName(propertyName, updateByIdentificator) {
      var pagesToReload = [], promises = [];

      // we have to reload all affected pages
      _.each(vm.sessions, function (page, pageIndex) {
        _.each(page, function (item) {
          console.log(item.selectedForOrderEngineTransaction);

          if (item.hasOwnProperty(propertyName) && item[propertyName] && !item[updateByIdentificator] &&
            pagesToReload.indexOf(pageIndex) === -1) {
            pagesToReload.push(pageIndex);
          }
        });
      });
      console.log(pagesToReload + '<--s-s-s-s-s');
      _.each(pagesToReload, function (page) {
        promises.push(vm.loadData(true, page));
      });

      return Promise.all(promises);
    }

    function removeSession(session) {
      UtilService.showFormattedConfirmationModal(
        'app.delete_item',
        'app.session',
        'app.delete-msg',
        session.userContext.user.username + ' (' + $filter('amDateFormat')(session.createdAt, 'DD/MM/YYYY HH:mm:ss') +
        function (returnValue) {
          if (returnValue) {
            PosSessionFactory.remove(session).then(function () {
              LogService.log('Session ' + session.id + ' removed', 'debug');
              vm.loadData(true);
              vm.showDeleteNotification();
            });
          }
        });
    }

    function showDeleteNotification() {
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-removed',
        'app.session',
        'app.item-successfully-removed',
        'app.session'
      );
    }

    /*
     * return new filter objec
     */
    function getDefaultFilters() {
      return {
        clerk: {
          val: '',
          field: 'userContext.user.username',
          cond: 'LIKE'
        },
        register: {
          val: '',
          field: 'pointOfSaleInstance.label',
          cond: 'LIKE'
        },
        beginDate: {
          val: '',
          field: 'createdAt',
          cond: 'GTE',
          format: 'yyyy-MM-dd HH:mm:ss',
          isOpen: false,
          open: function () {
            vm.filters.beginDate.isOpen = true;
          }
        },
        endDate: {
          val: '',
          field: 'endedAt',
          cond: 'LTE',
          format: 'yyyy-MM-dd HH:mm:ss',
          isOpen: false,
          allowNullIfSet: SettingsService.get('pos.session.approvalFlowVersion', false),
          open: function () {
            vm.filters.endDate.isOpen = true;
          }
        },
        approved: {
          val: ''
        },
        cashierStatus: {
          val: ''
        },
        orderEngineStatus: {
          val: ''
        },
        invoiceSession: {
          val: '',
          field: 'pointOfSaleSessionType',
          cond: 'EQ'
        }
      };
    }

    function resetFilters() {
      vm.filters = getDefaultFilters();
      vm.loadData(true);
    }

    function showNegativeSessionModal() {
      var negativeSessionModalInstance = $modal.open({
        templateUrl: 'reports/views/reports.session_negative.modal.tpl.html'
      });
      negativeSessionModalInstance.result.then(function () {}, function () {
        return [];
      });
    }
  }
}());
