(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('ContactCtrl', ContactCtrl);

  function ContactCtrl(
      ContactDataTypeFactory,
      ContactFactory,
      ContactTypeFactory,
      $cookies,
      $injector,
      $filter,
      $modalInstance,
      $templateRequest,
      $compile,
      $scope,
      contact,
      customer,
      SettingsService,
      $timeout
    ) {
    var vm = this;
    vm.showOnlineAccessInfo = !SettingsService.get('customers.hideCreateOnlineAccessButton', true);
    vm.customerContact = contact;
    vm.customer = customer;
    vm.cancel = cancelModalInstance;
    // Get the detail template of a contact
    $modalInstance.opened.then(function () {
      $templateRequest('contact/views/contact.detail.tpl.html').then(function (html) {
        $timeout(function () {
          var template = $compile(html)($scope);
          // Clear the contact-modal-detail-view on the page
          angular.element('#contact-modal-detail-view').contents().remove();
          // Compile and add the template to the page
          angular.element('#contact-modal-detail-view').append(template);
        });
      });
    });

    function cancelModalInstance() {
      // dismiss the modal view
      $modalInstance.dismiss('cancel');
    }
  }
}());
