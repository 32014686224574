(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:UserPasswordForgotCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('UserPasswordForgotCtrl', UserPasswordForgotCtrl);

  function UserPasswordForgotCtrl(
    $modalInstance,
    $rootScope,
    Restangular
  ) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.email = '';
    vm.errorMessage = null;
    vm.successMessage = null;
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.sendResetPasswordMail = sendResetPasswordMail;
    /* ----- END FUNCTIONS ----- */

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function sendResetPasswordMail() {
      var object = {
        email: vm.email,
        isOnlineReservation: false
      };
      vm.errorMessage = null;
      vm.successMessage = null;

      Restangular.one('open/users/password/reset').customPOST(object).then(function () {
        // When success
        vm.successMessage = 'app.success.reset-password-mail-sended';
      }, function () {
        // When error
        vm.errorMessage = 'app.errors.could-not-find-account-with-email';
      });
    }
  }
}());
