(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.controller:FitnessInstanceAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .controller('FitnessInstanceAddEditCtrl', FitnessInstanceAddEditCtrl);

  function FitnessInstanceAddEditCtrl(
    $modalInstance,
    LogService,
    $filter,
    $scope,
    eventInstance,
    fitnessEvent,
    reloadType,
    ContactFactory,
    FacilityFactory,
    EventFactory,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    CurrentUserContextFactory,
    SiteFactory,
    ToastrNotificationService,
    UtilService
    ) {
    var vm = this,
        contactParams = {
          limit: 99
        },
        participantTypeParams = {
          limit: 99
        },
        participantStatusParams = {
          limit: 99
        },
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.eventInstance = eventInstance;
    vm.isEdit = isEdit;
    vm.showSavedNotification = showSavedNotification;
    vm.saveFitnessEventInstance = saveFitnessEventInstance;
    vm.saveFitnessEventInstances = saveFitnessEventInstances;
    vm.cancel = cancelModalInstance;
    vm.eventIsActive = false;
    vm.startDate = new Date();
    vm.startTime = new Date();
    vm.endDate = new Date();
    vm.endTime = new Date();
    vm.dateStartPickerIsOpened = false;
    vm.dateEndPickerIsOpened = false;
    vm.trainers = [];
    vm.selectedTrainer = null;
    vm.participantTrainerType = null;
    vm.participantAttendedStatus = null;
    vm.participantRejectedStatus = null;
    vm.currentTrainerParticipant = null;
    vm.datesValid = false;
    vm.openDatePicker = openDatePicker;
    vm.saveTrainer = saveTrainer;
    vm.addHighLightedDates = addHighLightedDates;
    vm.selectedDates = [];
    // vm.selectedStartDateOffsetUnit = null;
    vm.selectedDateOffsetUnit = null;
    vm.duplicateLastEntry = duplicateLastEntry;
    vm.removeSelectedDate = removeSelectedDate;
    vm.checkDates = checkDates;
    vm.reminderMailHoursBeforeEvent = null;
    vm.onlineEvent = false;
    vm.dateOffsetUnits = [
      {
        label: $filter('translate')('app.days'),
        type: 'day'
      },
      {
        label: $filter('translate')('app.weeks'),
        type: 'week'
      },
      {
        label: $filter('translate')('app.months'),
        type: 'month'
      }
    ];

    function removeSelectedDate(date) {
      var i,
          currentDate,
          foundIndex = -1;

      for (i = 0; i < vm.selectedDates.length; ++i) {
        currentDate = vm.selectedDates[i];

        if (currentDate.startDate.getTime() === date.startDate.getTime() &&
          currentDate.endDate.getTime() === date.endDate.getTime()) {
          foundIndex = i;
          break;
        }
      }

      if (foundIndex > -1) {
        vm.selectedDates.splice(foundIndex, 1);
      }
    }

    function duplicateLastEntry() {
      var index,
          newStartDate,
          newEndDate;

      if (UtilService.isNotEmpty(vm.selectedDates)) {
        index = vm.selectedDates.length - 1;
        newStartDate = new Date(vm.selectedDates[index].startDate.valueOf());
        newEndDate = new Date(vm.selectedDates[index].endDate.valueOf());

        if (UtilService.isNotEmpty(vm.selectedDateOffset) &&
          UtilService.isNotEmpty(vm.selectedDateOffsetUnit)) {
          switch (vm.selectedDateOffsetUnit.type) {
            case 'day':
              newStartDate.setDate(newStartDate.getDate() + vm.selectedDateOffset);
              newEndDate.setDate(newEndDate.getDate() + vm.selectedDateOffset);
              break;
            case 'week':
              newStartDate.setDate(newStartDate.getDate() + (vm.selectedDateOffset * 7));
              newEndDate.setDate(newEndDate.getDate() + (vm.selectedDateOffset * 7));
              break;
            case 'month':
              newStartDate.setMonth(newStartDate.getMonth() + vm.selectedDateOffset);
              newEndDate.setMonth(newEndDate.getMonth() + vm.selectedDateOffset);
              break;
            default:
              break;
          }
        }

        // if (UtilService.isNotEmpty(vm.selectedTimeOffset) &&
        //   UtilService.isNotEmpty(vm.selectedTimeOffsetUnit)) {
        //   switch (vm.selectedTimeOffsetUnit.type) {
        //     case 'minute':
        //       newDate.setMinutes(newDate.getMinutes() + vm.selectedTimeOffset);
        //       break;
        //     case 'hour':
        //       newDate.setHours(newDate.getHours() + vm.selectedTimeOffset);
        //       break;
        //     default:
        //       break;
        //   }
        // }

        vm.selectedDates.push({
          startDate: newStartDate,
          endDate: newEndDate
        });
      }
    }

    participantTypeParams['filter[]'] = ['code,TRAINER'];
    participantStatusParams['filter[]'] = ['OR,code,ATTENDED,code,REJECTED'];
    participantStatusParams.sort = ['code,asc'];
    EventParticipantStatusFactory.getList(participantStatusParams).then(function (resultStatuses) {
      vm.participantAttendedStatus = resultStatuses.filter(function (status) {
        return status.code === 'ATTENDED';
      })[0];
      vm.participantRejectedStatus = resultStatuses.filter(function (status) {
        return status.code === 'REJECTED';
      })[0];

      EventFactory.one('participants').getList('types', participantTypeParams).then(function (resultTypes) {
        vm.participantTrainerType = resultTypes[0];

        FacilityFactory.getList({limit: 99, sort: 'label,asc'}).then(function (returnFacilities) {
          vm.facilities = returnFacilities;

          SiteFactory.one(currentLoggedInUserSiteId).get().then(function (resultSite) {
            vm.site = resultSite;

            contactParams['filter[]'] = ['childContactRelations.parentContact.id,' + resultSite.contact.id];
            contactParams['filter[]'].push('childContactRelations.contactRelationType.code,EMPLOYMENT');
            ContactFactory.getList(contactParams).then(function (resultContacts) {
              vm.trainers = resultContacts;
              if (vm.isEdit() && angular.isDefined(vm.eventInstance)) {
                if (UtilService.isNotEmpty(vm.eventInstance.currentTrainerParticipant)) {
                  vm.currentTrainerParticipant = vm.eventInstance.currentTrainerParticipant;
                  vm.selectedTrainer = vm.eventInstance.currentTrainerParticipant.contact;
                } else {
                  angular.forEach(vm.eventInstance.eventParticipants, function (participant) {
                    // EventParticipantFactory.one(participant.id).get().then(function (resultParticipant) {
                    if (participant.eventParticipantType.code === 'TRAINER' && participant.eventParticipantStatus.code === 'ATTENDED') {
                      vm.currentTrainerParticipant = participant;
                      vm.selectedTrainer = vm.currentTrainerParticipant.contact;
                    }
                    // });
                  });
                }
                vm.startDate = angular.copy(new Date(vm.eventInstance.startsAt));
                vm.startTime = angular.copy(new Date(vm.eventInstance.startsAt));
                vm.endDate = angular.copy(new Date(vm.eventInstance.endsAt));
                vm.endTime = angular.copy(new Date(vm.eventInstance.endsAt));
                vm.facility = vm.eventInstance.facility;
                vm.maxParticipants = vm.eventInstance.maximumMembers;
                vm.eventIsOnline = vm.eventInstance.displayOnWebsite;
                vm.reminderMailHoursBeforeEvent = vm.eventInstance.reminderMailHoursBeforeEvent;
                vm.onlineEvent = vm.eventInstance.onlineEvent;
              }
            });
          });
        });
      });
    });

    function addHighLightedDates() {
      var startDateTime, endDateTime;

      if (UtilService.isNotEmpty(vm.startDate) && UtilService.isNotEmpty(vm.startTime) &&
        UtilService.isNotEmpty(vm.endDate) && UtilService.isNotEmpty(vm.endTime)) {
        startDateTime = vm.startDate;
        startDateTime.setHours(vm.startTime.getHours());
        startDateTime.setMinutes(vm.startTime.getMinutes());
        endDateTime = vm.endDate;
        endDateTime.setHours(vm.endTime.getHours());
        endDateTime.setMinutes(vm.endTime.getMinutes());
        vm.selectedDates.push({
          startDate: startDateTime,
          endDate: endDateTime
        });
      }
    }

    function openDatePicker(type) {
      switch (type) {
        case 'end':
          vm.dateEndPickerIsOpened = true;
          break;

        case 'start':
          vm.dateStartPickerIsOpened = true;
          break;

        default:
          break;
      }
    }

    function isEdit() {
      return vm.eventInstance !== null;
    }

    function saveFitnessEventInstances() {
      UtilService.promiseLoop(vm.selectedDates,
        function (selectedDate) {
          return new Promise(function (resolve) {
            var instanceObject = {
              startsAt: selectedDate.startDate,
              endsAt: selectedDate.endDate,
              maximumMembers: vm.maxParticipants.toString().length > 0 ? vm.maxParticipants : null,
              displayOnWebsite: vm.eventIsOnline,
              facility: vm.facility.id,
              reminderMailHoursBeforeEvent: vm.reminderMailHoursBeforeEvent,
              onlineEvent: vm.onlineEvent
            };

            if (vm.isEdit()) {
              // Patch if edit mode
              EventFactory.one(fitnessEvent).one('instances').one(vm.eventInstance.id)
              .patch(instanceObject)
              .then(function () {
                vm.saveTrainer()
                .then(function () {
                  resolve();
                });
              });
            } else {
              // Post if not edit mode
              EventFactory.one(fitnessEvent).one('instances')
              .customPOST(instanceObject)
              .then(function (newInstance) {
                vm.eventInstance = newInstance;
                vm.saveTrainer()
                .then(function () {
                  resolve();
                });
              });
            }
          });
        }
      )
      .then(function () {
        // done saving the instances
        vm.showSavedNotification();
        $modalInstance.close(reloadType);
      });
    }

    function saveFitnessEventInstance() {
      // saving a single event instance?
      // -> just add one instance to the pile and call the function for saving multiple
      vm.selectedDates = [];
      vm.selectedDates.push({
        startDate: vm.startDate.toISOString().substr(0, 11) + vm.startTime.toISOString().substr(11, 13),
        endDate: vm.endDate.toISOString().substr(0, 11) + vm.endTime.toISOString().substr(11, 13)
      });
      vm.saveFitnessEventInstances();
    }

    function saveTrainer() {
      var participantObject;

      return new Promise(function (resolve) {
        function postNewTrainerParticipant() {
          LogService.log('Posting new trainer', 'debug');
          participantObject = {
            event: fitnessEvent,
            eventInstance: vm.eventInstance.id,
            contact: vm.selectedTrainer.id,
            site: CurrentUserContextFactory.getSiteId(),
            eventParticipantStatus: vm.participantAttendedStatus.id,
            eventParticipantType: vm.participantTrainerType.id
          };

          return EventParticipantFactory.post(participantObject);
        }

        if (vm.currentTrainerParticipant === null) {
          postNewTrainerParticipant()
          .then(function () {
            resolve();
          });
        } else {
          LogService.log('Check if selected trainer changed', 'debug');
          if (vm.currentTrainerParticipant.contact.id !== vm.selectedTrainer.id) {
            participantObject = {
              eventParticipantStatus: vm.participantRejectedStatus.id
            };
            EventParticipantFactory.one(vm.currentTrainerParticipant.id)
            .patch(participantObject)
            .then(function () {
              postNewTrainerParticipant()
              .then(function () {
                resolve();
              });
            });
          } else {
            // no save needed
            resolve();
          }
        }
      });
    }

    function showSavedNotification() {
      var messageString = vm.isEdit() ? 'app.item-successfully-updated' : 'app.item-successfully-saved';
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-saved',
        'app.event',
        messageString,
        'app.event'
      );
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function checkDates() {
      vm.datesValid = (vm.startDate <= vm.endDate);
      if (angular.isDefined($scope.FitnessInstanceAddEditForm) && angular.isDefined($scope.FitnessInstanceAddEditForm.startDate)) {
        $scope.FitnessInstanceAddEditForm.startDate.$setValidity('meaning', vm.datesValid);
      }
      if (angular.isDefined($scope.FitnessInstanceAddEditForm) && angular.isDefined($scope.FitnessInstanceAddEditForm.endDate)) {
        $scope.FitnessInstanceAddEditForm.endDate.$setValidity('meaning', vm.datesValid);
      }
    }
  }
}());
