(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SeriesGroupCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SeriesGroupCtrl', SeriesGroupCtrl);

  function SeriesGroupCtrl(
   $filter,
   LogService,
   $modal,
   $state,
   $stateParams,
   SwimCourseSetFactory,
   SkillFactory,
   SubscriptionFactory,
   UtilService,
   ToastrNotificationService,
   ContactFactory,
   AttachmentUtilService,
   SwimCourseManagementFactory,
   ContactService,
   ServiceFactory,
   moment,
   UiHelperService,
   SettingsService
 ) {
    var vm = this;
    vm.set = null;
    vm.series = null;
    vm.group = null;
    vm.levels = [];
    vm.skills = [];
    vm.comment = null;

    // define filters
    vm.filters = {
      dateFrom: {
        val: '',
        field: 'createdAt',
        cond: 'GTE ',
        format: 'yyyy-MM-dd',
        isOpen: false,
        open: function () {
          vm.filters.dateFrom.isOpen = true;
        }
      },
      dateTo: {
        val: '',
        field: 'createdAt',
        cond: 'LTE',
        format: 'yyyy-MM-dd',
        isOpen: false,
        open: function () {
          vm.filters.dateTo.isOpen = true;
        }
      }
    };
    vm.dates = [];
    vm.goBack = goBack;
    vm.goCalendar = goCalendar;
    vm.goContact = goContact;
    vm.goCustomer = goCustomer;
    vm.loadData = loadData;
    // vm.findLevelAndSwimCourseGroupIndex = findLevelAndSwimCourseGroupIndex;
    vm.loadPupils = loadPupils;
    vm.isPupilListFilled = isPupilListFilled;
    vm.toggleSubscription = toggleSubscription;
    vm.isSessionInFuture = isSessionInFuture;
    vm.swimCourseSeriesGroupId = null;
    vm.levelId = null;
    vm.showSubscriptionCommentModal = showSubscriptionCommentModal;
    vm.showSeriesGroupCommentModal = showSeriesGroupCommentModal;

    vm.showAddPupilToWaitingListsModal = showAddPupilToWaitingListsModal;
    vm.returnOfShowAddPupilToWaitingListsModalInstance = returnOfShowAddPupilToWaitingListsModalInstance;
    vm.showingPupilToWaitingListModal = false;
    vm.showingPupilToWaitingListModalInstance = null;

    vm.showSwitchGroupModal = showSwitchGroupModal;

    vm.valueTranslations = [
      $filter('translate')('app.failed'),
      $filter('translate')('app.needsWork'),
      $filter('translate')('app.passed')
    ];

    // attendance modal
    vm.showingAttendanceModal = false;
    vm.showAttendanceModal = showAttendanceModal;
    vm.returnOfAttendanceModalInstance = returnOfAttendanceModalInstance;
    vm.attendanceModalInstance = null;

    // pupil input method modal
    vm.showingPupilInputMethodModal = false;
    vm.pupilInputMethodModalInstance = null;
    vm.showPupilInputMethodModal = showPupilInputMethodModal;
    vm.returnOfPupilInputMethodModalInstance = returnOfPupilInputMethodModalInstance;

    vm.showingAddEditGroupModal = false;
    vm.showAddEditGroupModal = showAddEditGroupModal;
    vm.returnOfAddEditGroupModalInstance = returnOfAddEditGroupModalInstance;
    vm.addEditGroupModalInstance = null;

    // pupil view modal
    vm.showingPupilViewModal = false;
    vm.showPupilViewModal = showPupilViewModal;
    vm.pupilViewModalInstance = null;
    vm.returnOfPupilViewModalInstance = returnOfPupilViewModalInstance;

    vm.deactivateGroup = deactivateGroup;

    // new contact modal
    vm.showingNewContactModal = false;
    vm.newContactModalInstance = null;
    vm.showNewContactModal = showNewContactModal;

    // select contact modal
    vm.showingSelectContactModal = false;
    vm.showSelectContactModal = showSelectContactModal;
    vm.selectContactModalInstance = null;

    // view subscription modal
    vm.showingViewSubscriptionModal = false;
    vm.showViewSubscriptionModal = showViewSubscriptionModal;
    vm.viewSubscriptionModalInstance = null;

    // edit evaluations modal
    vm.showingEditEvaluationModal = false;
    vm.showEditEvaluationModal = showEditEvaluationModal;
    vm.returnOfEditEvaluationModalInstance = returnOfEditEvaluationModalInstance;
    vm.editEvaluationModalInstance = null;

    // waiting list confirmation modal
    vm.showingWaitingListConfirmationModal = false;
    vm.showWaitingListConfirmationModal = showWaitingListConfirmationModal;
    vm.returnOfWaitingListConfirmationModalInstance = returnOfWaitingListConfirmationModalInstance;
    vm.waitingListConfirmationModalInstance = null;

    vm.createSubscription = createSubscription;
    vm.deleteSubscription = deleteSubscription;

    vm.downloadPupilList = downloadPupilList;
    vm.downloadWaitingList = downloadWaitingList;
    vm.downloadAttendanceSheet = downloadAttendanceSheet;

    vm.showEditColorModal = showEditColorModal;
    vm.showJournalInfoViewModal = showJournalInfoViewModal;
    vm.swimmingSchoolEnableOnlinePayments = SettingsService.get('swimmingSchool.enableOnlinePayments', false);

    loadData();

    function loadData() {
      var params, firstDate, lastDate, i;
      // load the set
      SwimCourseSetFactory.one($stateParams.setId).get().then(function (resultSet) {
        vm.set = resultSet;

        // load skills
        SkillFactory.getList({limit: 99}).then(function (skills) {
          vm.skills = skills;
          vm.skills.sort(function (a, b) {
            if (a.translatedLabel === b.translatedLabel) {
              return 0;
            }
            return (a.translatedLabel > b.translatedLabel) ? 1 : -1;
          });

          // load the series
          SwimCourseSetFactory.one(vm.set.id).one('series').one($stateParams.serId).get()
            .then(function (series) {
              // get the first and last date
              firstDate = series.startsAt;
              lastDate = firstDate;
              vm.series = series;

              SwimCourseSetFactory.one(vm.set.id)
                .one('series').one(vm.series.id)
                .one('groups').one($stateParams.groId).get()
                .then(function (group) {
                  vm.group = group;
                  vm.groupColor = vm.group === null || (angular.isUndefined(vm.group.tags) || angular.isUndefined(vm.group.tags[0])) ? false : vm.group.tags[0];
                  // fallback to swim course group color
                  if (vm.groupColor === false) {
                    vm.groupColor = vm.group.swimCourseGroup === null || (angular.isUndefined(vm.group.swimCourseGroup.tags) || angular.isUndefined(vm.group.swimCourseGroup.tags[0])) ? false : vm.group.swimCourseGroup.tags[0];
                  }
                  vm.levelId = group.swimCourseGroup.swimCourseLevel.id;
                  LogService.log('group' + group, 'debug');
                  vm.comment = group.comments;

                  // get the swimcourseSeriesGroup id and level id
                  // TO DO make this less ugly
                  for (i = 0; i < vm.series.swimCourseSeriesGroups.length; ++i) {
                    if (vm.series.swimCourseSeriesGroups[i].swimCourseGroup.id === vm.group.swimCourseGroup.id) {
                      vm.swimCourseSeriesGroupId = vm.series.swimCourseSeriesGroups[i].id;
                      break;
                    }
                  }
                  angular.forEach(series.swimCourseSessions, function (session) {
                    // get the attendances
                    params = {
                      limit: 99
                    };

                    params['filter[]'] = [];
                    params['filter[]'].push('swimCourseSeriesGroup.id,' + vm.swimCourseSeriesGroupId);
                    params['filter[]'].push('swimCourseParticipantStatus.code,ATTENDED');
                    SwimCourseSetFactory.one(vm.set.id).one('series').one(series.id).one('sessions').one(session.id)
                      .getList('participants', params).then(function (attendances) {
                        session.attendedCounter = attendances.count;
                      });
                    if (session.startsAt > lastDate) {
                      lastDate = session.startsAt;
                    }
                    if (session.startsAt < firstDate) {
                      firstDate = session.startsAt;
                    }
                  });

                  loadPupils();
                });

              series.firstDate = firstDate;
              series.lastDate = lastDate;
            });
        });
      });
    }

    function showSubscriptionCommentModal(subscription) {
      if (vm.showingSubscriptionCommentModal) {
        return;
      }

      vm.subscriptionCommentModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/pupils/pupil.subscription.comment.addedit.modal.tpl.html',
        controller: 'SubscriptionCommentEditCtrl',
        controllerAs: 'subscriptionCommentEditCtrl',
        resolve: {
          subscription: function () {
            return subscription;
          }
        }
      });

      vm.showingSubscriptionCommentModal = true;
      vm.subscriptionCommentModalInstance.result.then(function (returnValue) {
        LogService.log('Subscription comment modal: Closed => reason: ' + returnValue, 'debug');
        vm.subscriptionCommentModalInstance = null;
        vm.showingSubscriptionCommentModal = false;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('Subscription comment modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.subscriptionCommentModalInstance = null;
        vm.showingSubscriptionCommentModal = false;
      });
    }

    function showSeriesGroupCommentModal() {
      if (vm.showingSeriesGroupCommentModal) {
        return;
      }
      vm.seriesGroupCommentModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/group/modals/series.group.comment.addedit.modal.tpl.html',
        controller: 'SeriesGroupCommentEditCtrl',
        controllerAs: 'seriesGroupCommentEditCtrl',
        resolve: {
          set: function () {
            return vm.set;
          },
          series: function () {
            return vm.series;
          },
          seriesGroup: function () {
            return vm.group;
          }
        }
      });

      vm.showingSeriesGroupCommentModal = true;
      vm.seriesGroupCommentModalInstance.result.then(function (returnValue) {
        LogService.log('SeriesGroup comment modal: Closed => reason: ' + returnValue, 'debug');
        vm.seriesGroupCommentModalInstance = null;
        vm.showingSeriesGroupCommentModal = false;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('SeriesGroup comment modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.SeriesGroupCommentModalInstance = null;
        vm.showingSeriesGroupCommentModal = false;
      });
    }

    function isSessionInFuture(session) {
      var then = new Date(session.startsAt),
          today = new Date();
      return then > today;
    }

    function isPupilListFilled() {
      if (vm.group && vm.group.subscriptions) {
        return vm.group.subscriptions.length >= vm.group.maximumMembers;
      }
      return false;
    }

    function loadSubscriptionEvaluations(subscriptions) {
      angular.forEach(subscriptions, function (cs) {
        // fill in all skills with evaluation zero
        cs.evaluations = {};
        angular.forEach(vm.skills, function (skill) {
          cs.evaluations[skill.id] = {
            id: null,
            skill: skill.id,
            skillLabel: skill.translatedLabel,
            level: null,
            comments: null
          };
        });
        // TO-DO check for existing evaluations
        SubscriptionFactory.one(cs.id).getList('evaluations', {limit: 99}).then(function (evaluations) {
          angular.forEach(evaluations, function (existingEvaluation) {
            console.log(existingEvaluation.swimCourseSkill.translatedLabel);
            cs.evaluations[existingEvaluation.swimCourseSkill.id] = {
              id: existingEvaluation.id,
              skill: existingEvaluation.swimCourseSkill.id,
              level: existingEvaluation.level,
              comments: existingEvaluation.comments
            };
          });
        });
      });
    }

    function loadPupils() {
      vm.group.subscriptions = [];
      vm.group.waitingList = [];
      SwimCourseManagementFactory.getSwimCoursePupils(
        vm.set.id,
        vm.group.swimCourseGroup.swimCourseLevel.id,
        vm.group.id
      )
      .then(function (subscriptions) {
        vm.group.memberCount = subscriptions.length;
        vm.group.subscriptions = subscriptions;
        loadSubscriptionEvaluations(vm.group.subscriptions);
      });
      SwimCourseManagementFactory.getSwimCourseWaitingList(
        vm.set.id,
        vm.group.swimCourseGroup.swimCourseLevel.id
      )
      .then(function (subscriptions) {
        vm.group.waitingList = subscriptions;
      });
    }

    function toggleSubscription(subscription) {
      // wrap this in a function so we don't have to write the post-toggle code twice
      function toggleAction() {
        if (subscription.hasOwnProperty('swimCourseSeriesGroup')) {
          // Add Modal and ask to subscribe on waiting List
          vm.showAddPupilToWaitingListsModal(subscription);

          // if we're subscribed to a group we're trying to toggle to waiting list
          return SwimCourseManagementFactory.registerSubscriptionToWaitingList(subscription, vm.set.id, vm.levelId, false);
        }
        // if not we're trying to toggle to a group
        vm.showWaitingListConfirmationModal(subscription);

        return SwimCourseManagementFactory.registerSubscriptionToGroup(subscription, vm.set.id, vm.levelId, vm.swimCourseSeriesGroupId, false);
      }

      toggleAction()
        .then(function () {
          vm.loadPupils();
        }, function (errorMsg) {
          LogService.log('error: ' + errorMsg, 'error');
        });
    }

    // Register attendance
    function showAttendanceModal(session) {
      if (vm.showingAttendanceModal) {
        return;
      }

      vm.attendanceModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/group/dates/groups.dates.attendances.modal.tpl.html',
        controller: 'AttendanceCtrl',
        controllerAs: 'attendanceCtrl',
        resolve: {
          set: function () {
            return vm.set;
          },
          series: function () {
            return vm.series;
          },
          seriesGroupId: function () {
            return vm.swimCourseSeriesGroupId;
          },
          group: function () {
            return vm.group.swimCourseGroup;
          },
          session: function () {
            return session;
          },
          subscriptions: function () {
            return vm.group.subscriptions;
          }
        }
      });

      vm.showingAttendanceModal = true;
      vm.returnOfAttendanceModalInstance();
    }

    function returnOfAttendanceModalInstance() {
      vm.attendanceModalInstance.result.then(function (returnValue) {
        LogService.log('Pupil attendance modal: Closed => reason: ' + returnValue, 'debug');
        vm.attendanceModalInstance = null;
        vm.showingAttendanceModal = false;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('Pupil attendance modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.attendanceModalInstance = null;
        vm.showingAttendanceModal = false;
      });
    }

    function showPupilInputMethodModal(subscribeTowaitingList) {
      if (vm.showingPupilInputMethodModal) {
        return;
      }
      vm.pupilInputMethodModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/pupils/pupil.inputmethod.modal.tpl.html',
        controller: 'PupilInputMethodCtrl',
        controllerAs: 'pupilInputMethodCtrl',
        size: 'md',
        resolve: {
          addNewContactIsAllowed: false,
          addExistingContactIsAllowed: true
        }
      });

      vm.showingPupilInputMethodModal = true;
      vm.returnOfPupilInputMethodModalInstance(subscribeTowaitingList);
    }

    function returnOfPupilInputMethodModalInstance(subscribeTowaitingList) {
      vm.pupilInputMethodModalInstance.result.then(function (returnValue) {
        vm.showingPupilInputMethodModal = false;
        switch (returnValue) {
          case 'new_contact':
            vm.showNewContactModal(subscribeTowaitingList);
            break;
          case 'select_contact':
            vm.showSelectContactModal(subscribeTowaitingList);
            break;
          default:
            break;
        }
      }, function () {
        vm.showingPupilInputMethodModal = false;
      });
    }

    // CREATE CONTACT
    function showNewContactModal(subscribeTowaitingList) {
      if (vm.showingNewContactModal) {
        return;
      }
      vm.newContactModalInstance = $modal.open({
        templateUrl: 'customer/views/customer.pos.modal.add.tpl.html',
        controller: 'CustomerAddPosCtrl',
        controllerAs: 'customerAddPosCtrl'
      });
      vm.showingNewContactModal = true;

      // handle return from modal instance
      vm.newContactModalInstance.result.then(function (returnValue) {
        LogService.log('Contact creation Modal: Closed => reason: ' + returnValue, 'debug');
        vm.showingNewContactModal = false;
        vm.newContactModalInstance = null;
        vm.customer = returnValue.customer;
        vm.customerContact = returnValue.contact;
        vm.showAddEditSubscriptionModal(returnValue.contact, subscribeTowaitingList);
      }, function (returnValue) {
        LogService.log('Contact creation Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.showingNewContactModal = false;
        vm.newContactModalInstance = null;
      });
    }

    // SELECT CONTACT
    function showSelectContactModal(subscribeTowaitingList) {
      if (vm.showingSelectContactModal) {
        return;
      }
      vm.selectContactModalInstance = $modal.open({
        templateUrl: 'pos/views/pos.customers.list.modal.tpl.html',
        controller: 'PosCustomerListCtrl',
        controllerAs: 'posCustomerListCtrl',
        size: 'lg',
        resolve: {
          customerId: function () {
            return null;
          },
          customerContactTypeId: function () {
            return null;
          }
        }
      });

      vm.showingSelectContactModal = true;

      // handle return from modal instance
      vm.selectContactModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.selectContactModalInstance = null;
        vm.showingSelectContactModal = false;
        vm.createSubscription(returnValue.contact, subscribeTowaitingList);
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.selectContactModalInstance = null;
        vm.showingSelectContactModal = false;
      });
    }

    // VIEW SUBSCRIPTION
    function showViewSubscriptionModal(subscription) {
      if (vm.showingViewSubscriptionModal) {
        return;
      }

      ContactFactory.one(subscription.contact.id)
      .get()
      .then(function (contact) {
        LogService.log('invoking view subscription modal', 'debug');
        vm.viewSubscriptionModalInstance = $modal.open({
          templateUrl: 'contact/views/contact.modal.view.tpl.html',
          controller: 'ContactCtrl',
          controllerAs: 'contactCtrl',
          size: 'lg',
          resolve: {
            contact: function () {
              return contact;
            },
            customer: function () {
              return null;
            },
            showOnlineAccessInfo: function () {
              return false;
            }
          }
        });

        vm.showingViewSubscriptionModal = true;

        vm.viewSubscriptionModalInstance.result.then(function (returnValue) {
          LogService.log('View subscription modal: Closed => reason: ' + returnValue, 'debug');
          vm.viewSubscriptionModalInstance = null;
          vm.showingViewSubscriptionModal = false;
        }, function (returnValue) {
          LogService.log('View subscription modal: Dismissed => reason: ' + returnValue, 'debug');
          vm.viewSubscriptionModalInstance = null;
          vm.showingViewSubscriptionModal = false;
        });
      });
    }

    // EDIT EVALUATION
    function showEditEvaluationModal(subscription) {
      if (vm.showingEditEvaluationModal) {
        return;
      }

      LogService.log('invoking edit evaluation modal', 'debug');
      vm.EditEvaluationModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/group/pupils/groups.pupils.evaluation.modal.tpl.html',
        controller: 'EvaluationCtrl',
        controllerAs: 'evaluationCtrl',
        resolve: {
          subscription: function () {
            return subscription;
          }
        }
      });

      vm.showingEditEvaluationModal = true;
      vm.returnOfEditEvaluationModalInstance();
    }

    function returnOfEditEvaluationModalInstance() {
      vm.EditEvaluationModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.EditEvaluationModalInstance = null;
        vm.showingEditEvaluationModal = false;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.EditEvaluationModalInstance = null;
        vm.showingEditEvaluationModal = false;
      });
    }

    function showAddPupilToWaitingListsModal(subscription) {
      if (vm.showingPupilToWaitingListModal) {
        return;
      }
      vm.showingPupilToWaitingListModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/group/registrations/registrations.unsubscribe.modal.tpl.html',
        controller: 'AddPupilToWaitingListConfirmationCtrl',
        controllerAs: 'addPupilToWaitingListConfirmationCtrl',
        size: 'sm',
        resolve: {
          subscription: function () {
            return angular.copy(subscription);
          }
        }
      });

      vm.showingPupilToWaitingListModal = true;
      vm.returnOfShowAddPupilToWaitingListsModalInstance();
    }

    function returnOfShowAddPupilToWaitingListsModalInstance() {
      vm.showingPupilToWaitingListModalInstance.result.then(function (returnValue) {
        LogService.log('Add pupil to Waiting list modal: Closed => reason: ' + returnValue, 'debug');
        vm.showingPupilToWaitingListModalInstance = null;
        vm.showingPupilToWaitingListModal = false;
      }, function (returnValue) {
        LogService.log('Add pupil to Waiting list modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.showingPupilToWaitingListModalInstance = null;
        vm.showingPupilToWaitingListModal = false;
      });
    }

    function showWaitingListConfirmationModal(subscription) {
      if (vm.showingWaitingListConfirmationModal) {
        return;
      }

      vm.waitingListConfirmationModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/series.levels.waitinglist.confirmation.modal.tpl.html',
        controller: 'WaitingListConfirmationCtrl',
        controllerAs: 'waitingListConfirmationCtrl',
        size: 'sm',
        resolve: {
          subscription: function () {
            return subscription;
          }
        }
      });

      vm.showingWaitingListConfirmationModal = true;
      vm.returnOfWaitingListConfirmationModalInstance(subscription);
    }

    function returnOfWaitingListConfirmationModalInstance(subscription) {
      vm.waitingListConfirmationModalInstance.result.then(function (returnValue) {
        LogService.log('Waiting list confirmation modal: Closed => reason: ' + returnValue, 'debug');
        if (returnValue === 'yes') {
          ServiceFactory.deleteWaitingListBySubscribedSubscription(subscription.id).then(function () {
            vm.waitingListConfirmationModalInstance = null;
            vm.showingWaitingListConfirmationModal = false;
          }, function (errorMsg) {
            console.error('error: ' + errorMsg);
          });
        } else {
          vm.waitingListConfirmationModalInstance = null;
          vm.showingWaitingListConfirmationModal = false;
        }
      }, function (returnValue) {
        LogService.log('Waiting list confirmation modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.waitingListConfirmationModalInstance = null;
        vm.showingWaitingListConfirmationModal = false;
      });
    }

    function createSubscription(contact, subscribeTowaitingList) {
      var restObject;

      restObject = {
        swimCourseSet: vm.set.id,
        swimCourseLevel: vm.group.swimCourseGroup.swimCourseLevel.id,
        contact: contact.contact.id,
        subscribedAt: (new Date()).toISOString()
      };

      if (!(UtilService.isNotEmpty(subscribeTowaitingList) && subscribeTowaitingList)) {
        restObject.swimCourseSeriesGroup = vm.group.id;
      }

      // Create the subscription
      SubscriptionFactory.post(restObject).then(function () {
        vm.loadPupils();
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function deleteSubscription(subscription) {
      SubscriptionFactory.one(subscription.id).remove().then(function () {
        vm.loadPupils();
      });
    }

    function goBack() {
      $state.go('swimcourse.series', {setId: vm.set.id, serId: vm.series.id});
    }

    function goCalendar() {
      $state.go('swimcourse.calendar', {year: $stateParams.year, week: $stateParams.week}, {reload: true});
    }

    function goContact(contact) {
      ContactService.getContact(contact.id).then(function (returnContact) {
        var customerContacts = returnContact.customerContacts.filter(function (filterContact) {
          return filterContact.customerContactType.code === 'USER';
        });

        switch (customerContacts.length) {
          case 0:
            LogService.log('no customerContact found', 'debug');
            break;
          case 1:
            goCustomer(customerContacts[0].customer);
            break;
          default:
            // For now select first customercontact, in the future: display mofal with choices
            goCustomer(customerContacts[0].customer);
            break;
        }
      });
    }

    function goCustomer(customer) {
      $state.go('customerdetail', {id: customer.id});
    }

    function downloadWaitingList() {
      if (!(vm.set && vm.levelId)) {
        return;
      }

      SwimCourseManagementFactory.downloadWaitingList(
        vm.set.id,
        vm.levelId
      );
    }

    function downloadPupilList() {
      if (!(vm.series && vm.swimCourseSeriesGroupId)) {
        return;
      }

      SwimCourseManagementFactory.downloadPupilList(
        vm.series.id,
        '',
        vm.swimCourseSeriesGroupId
      );
    }

    function downloadAttendanceSheet(type) {
      var restObject = {};
      if (!(vm.series && vm.swimCourseSeriesGroupId)) {
        return;
      }
      restObject = {
        format: type,
        type: 'attendance_sheets',
        'swimCourseSeriesGroups[]': vm.swimCourseSeriesGroupId,
        'swimCourseSeries[]': vm.series.id
      };

      if (type === 'xlsx') {
        restObject.merge = true;
      }

      if (vm.filters.dateFrom.val) {
        restObject.swimCourseSessionFromDate = moment(vm.filters.dateFrom.val).format('YYYY-MM-DD');
      }

      if (UtilService.isNotEmpty(vm.filters.dateTo.val)) {
        restObject.swimCourseSessionToDate = moment(vm.filters.dateTo.val).format('YYYY-MM-DD');
      }
      AttachmentUtilService.download('/services/swim_course_series_groups_export', restObject);
    }

    // ADD EDIT GROUP
    function showAddEditGroupModal(swimCourseGroup, seriesGroup) {
      if (vm.showingAddEditGroupModal) {
        return;
      }

      // Fix because the groupAddEditCtrl expects seriesGroupId
      seriesGroup.seriesGroupId = seriesGroup.id;
      vm.addEditGroupModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/series.levels.group.addedit.modal.tpl.html',
        controller: 'GroupAddEditCtrl',
        controllerAs: 'groupAddEditCtrl',
        size: 'sm',
        resolve: {
          set: function () {
            return vm.set;
          },
          series: function () {
            return vm.series;
          },
          swimCourseGroup: function () {
            return angular.isUndefined(vm.group.swimCourseGroup) ? null : swimCourseGroup;
          },
          seriesGroup: function () {
            return angular.isUndefined(seriesGroup) ? null : seriesGroup;
          }
        }
      });

      vm.showingAddEditGroupModal = true;
      vm.returnOfAddEditGroupModalInstance();
    }

    function returnOfAddEditGroupModalInstance() {
      vm.addEditGroupModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.addEditGroupModalInstance = null;
        vm.showingAddEditGroupModal = false;
        // vm.customer = returnValue.customer;
        // vm.customerContact = returnValue.contact;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.addEditGroupModalInstance = null;
        vm.showingAddEditGroupModal = false;
      });
    }

    function deactivateGroup(group) {
      if (group.subscriptions.length === 0) {
        UtilService.showTranslatedConfirmationModal('app.deactivate-swimcourse-group', 'app.deactivate-swimcourse-group-extended', function (returnValue) {
          if (returnValue) {
            SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id).one('groups')
            .one(group.id)
            .remove()
            .then(function () {
              ToastrNotificationService.showTranslatedNotification(
                'success',
                'app.deactivated',
                ''
              );
              vm.goCalendar();
            });
          }
        });
      } else {
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.series-group-cannot-be-removed-subscription',
          ''
        );
      }
    }

    function showEditColorModal() {
      UiHelperService.openDialog({
        templateUrl: 'swimcourse/views/sets/series/levels/group/modals/series.group.color.edit.modal.tpl.html',
        controller: 'GroupColorEditCtrl',
        controllerAs: 'groupColorEditCtrl',
        size: 'md',
        resolve: {
          set: function () {
            return vm.set;
          },
          series: function () {
            return vm.series;
          },
          seriesGroup: function () {
            return vm.group;
          }
        }
      }).then(function () {
        loadData();
      }, function () {
      });
    }

    function showSwitchGroupModal() {
      UiHelperService.openDialog({
        templateUrl: 'swimcourse/views/sets/series/levels/group/modals/series.group.switch-group.modal.tpl.html',
        controller: 'SeriesGroupSwitchCtrl',
        controllerAs: 'seriesGroupSwitchCtrl',
        size: 'md',
        resolve: {
          seriesGroup: function () {
            return vm.group;
          }
        }
      }).then(function () {
        loadData();
        ToastrNotificationService.showTranslatedNotification(
          'success',
          'app.group-successfully-transferred',
          ''
        );
      }, function () {
      });
    }

    function showPupilViewModal(subscription) {
      // Check if modal is already open
      if (vm.showingPupilViewModal) {
        return;
      }
      ContactFactory.one(subscription.contact.id).get().then(function (completePupil) {
        // Open modal view with specific template and controller, Add a customer and contact object to that controller
        vm.pupilViewModalInstance = $modal.open({
          templateUrl: 'contact/views/contact.modal.view.tpl.html',
          controller: 'ContactCtrl',
          controllerAs: 'contactCtrl',
          size: 'lg',
          resolve: {
            contact: function () {
              return completePupil;
            },
            customer: function () {
              return null;
            },
            showOnlineAccessInfo: function () {
              return false;
            }
          }
        });
        vm.showingPupilViewModal = true;
        vm.returnOfPupilViewModalInstance();
      });
    }

    function returnOfPupilViewModalInstance() {
      // Check if dismiss or close action of modal view was called
      vm.pupilViewModalInstance.result.then(function () {
        vm.showingPupilViewModal = false;
      }, function () {
        vm.showingPupilViewModal = false;
      });
    }

    function showJournalInfoViewModal(subscription) {
      if (vm.showingJournalInfoModal) {
        return;
      }

      vm.subscriptionJournalModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/group/registrations/registrations.subscription.journal.modal.tpl.html',
        controller: 'SubscriptionJournalCtrl',
        controllerAs: 'subscriptionJournalCtrl',
        resolve: {
          subscription: function () {
            return subscription;
          }
        }
      });
      vm.showingJournalInfoModal = true;
      vm.subscriptionJournalModalInstance.result.then(function (returnValue) {
        LogService.log('Subscription journal modal: Closed => reason: ' + returnValue, 'debug');
        vm.subscriptionJournalModalInstance = null;
        vm.showingJournalInfoModal = false;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('Subscription journal modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.subscriptionJournalModalInstance = null;
        vm.showingJournalInfoModal = false;
      });
    }
  }
}());
