(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SeriesGroupSwitchCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SeriesGroupSwitchCtrl', SeriesGroupSwitchCtrl);

  function SeriesGroupSwitchCtrl(
    $modalInstance,
    LogService,
    SwimCourseSetFactory,
    Restangular,
    seriesGroup
  ) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.seriesGroup = seriesGroup;
    vm.selectedGroup = null;
    vm.availableGroups = [];
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.getAvailableGroups = getAvailableGroups;
    vm.cancel = cancelModalInstance;
    vm.switchGroup = switchGroup;
    vm.groupCustomLabel = groupCustomLabel;
    /* ----- END FUNCTIONS ----- */

    /* ----- ON INIT ----- */
    vm.getAvailableGroups();
    /* ----- END ON INIT ----- */

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function groupCustomLabel(group) {
      return group.setLabel + ' - ' + group.swimCourseGroup.label + ' (' + group.trainerContact.label + ')';
    }

    function switchGroup() {
      var params = {
        sourceSwimCourseSeriesGroup: vm.seriesGroup.id,
        targetSwimCourseSeriesGroup: vm.selectedGroup.id
      };
      Restangular.service('services/swim_course_series_group_migration')
      .post(params)
      .then(function (result) {
        $modalInstance.close(result);
      });
    }

    function getAvailableGroups() {
      if (vm.seriesGroup && vm.seriesGroup.swimCourseGroup && vm.seriesGroup.swimCourseGroup.swimCourseLevel) {
        // Get available Groups
        SwimCourseSetFactory.getList({
          limit: 99,
          'filter[]': [
            'swimCourseSeries.swimCourseSeriesGroups.swimCourseGroup.swimCourseLevel.id,' + vm.seriesGroup.swimCourseGroup.swimCourseLevel.id,
            'swimCourseSeries.swimCourseSeriesGroups.swimCourseSubscriptions.id,NULL',
            'swimCourseSeries.swimCourseSeriesGroups.maximumMembers,GTE ' + vm.seriesGroup.maximumMembers
          ]
        }).then(function (results) {
          LogService.log('results' + results, 'debug');
          angular.forEach(results, function (scSet) {
            angular.forEach(scSet.swimCourseSeries, function (scSerie) {
              angular.forEach(scSerie.swimCourseSeriesGroups, function (scSeriesGroup) {
                LogService.log(scSeriesGroup, 'debug');
                if (scSeriesGroup.swimCourseSubscriptions.length === 0 &&
                  scSeriesGroup.maximumMembers >= vm.seriesGroup.maximumMembers &&
                  scSeriesGroup.swimCourseGroup.swimCourseLevel.id === vm.seriesGroup.swimCourseGroup.swimCourseLevel.id) {
                  scSeriesGroup.setLabel = scSet.label;
                  vm.availableGroups.push(scSeriesGroup);
                }
              });
            });
          });
        });
      }
    }
  }
}());
