(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosScreenEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosScreenEditCtrl', PosScreenEditCtrl);

  function PosScreenEditCtrl(
      $filter,
      $state,
      _,
      ColorFactory,
      LogService,
      PosGroupFactory,
      PosScreenFactory,
      ProductFactory,
      RestUtilService,
      screen
    ) {
    var vm = this;
    vm.init = init;
    vm.rows = [];
    vm.rowsAsJson = {};
    vm.save = save;
    vm.screen = screen;
    vm.posGroups = [];
    vm.posScreens = [];
    vm.items = [];
    vm.products = [];
    vm.item = {};
    vm.setColor = setColor;
    vm.setDestination = setDestination;
    vm.blueprintItem = {
      uid: null,
      label: $filter('uconlyfirst')($filter('translate')('pos.destinations.blank')),
      id: null,
      destination: 'blank',
      color: null,
      removable: false
    };
    vm.itemCount = 0;
    vm.destinations = [
      {id: 'product', label: $filter('uconlyfirst')($filter('translate')('pos.destinations.product'))},
      {id: 'screen', label: $filter('uconlyfirst')($filter('translate')('pos.destinations.screen'))},
      {id: 'blank', label: $filter('uconlyfirst')($filter('translate')('pos.destinations.blank'))}
    ];
    vm.destination = vm.setDestination;
    vm.posColors = ColorFactory.getColors();

    vm.addItem = addItem;
    vm.addRow = addRow;
    vm.createItem = createItem;
    vm.dragoverCallback = dragoverCallback;
    vm.dropCallback = dropCallback;
    vm.logEvent = logEvent;
    vm.logListEvent = logListEvent;
    vm.selectItem = selectItem;
    vm.updateScreen = updateScreen;
    vm.updateProduct = updateProduct;
    vm.updateBlank = updateBlank;
    vm.removeItem = removeItem;
    vm.loadScreens = loadScreens;
    vm.loadProducts = loadProducts;

    vm.screen.pointOfSaleGroups = vm.screen.pointOfSaleGroups.map(function (item) {
      return item.id;
    });

    RestUtilService.getFullList(PosGroupFactory, {limit: 10}).then(function (resultPosGroups) {
      _.each(resultPosGroups, function (posGroup) {
        vm.posGroups.push(posGroup);
      });
    });

    _.each(_.sortBy(screen.pointOfSaleItems, ['row', 'column']), function (pointOfSaleItem) {
      var item = vm.createItem();
      if (angular.isUndefined(vm.rows[--pointOfSaleItem.row])) {
        vm.rows[pointOfSaleItem.row] = {items: []};
      }
      if (angular.isDefined(pointOfSaleItem.tags[0])) {
        item.color = pointOfSaleItem.tags[0];
      }
      if (_.isObject(pointOfSaleItem.targetScreen)) {
        item.destination = 'screen';
        item.label = pointOfSaleItem.targetScreen.label;
        item.targetScreen = pointOfSaleItem.targetScreen;
      }
      if (_.isObject(pointOfSaleItem.product)) {
        item.destination = 'product';
        item.label = pointOfSaleItem.product.translatedLabel;
        item.product = pointOfSaleItem.product;
      }
      vm.rows[pointOfSaleItem.row].items.push(item);
    });

    function createItem() {
      var item = angular.copy(vm.blueprintItem);
      item.uid = vm.itemCount++;
      return item;
    }

    function loadScreens(input) {
      var params = {
            limit: 99,
            sort: 'label,ASC'
          },
          pointOfSaleGroupParam = '';
      params['filter[]'] = ['master,FALSE'];
      if (angular.isDefined(input)) {
        params['filter[]'].push('label,LIKE ' + input);
      }

      angular.forEach(vm.screen.pointOfSaleGroups, function (pointOfSaleGroup) {
        pointOfSaleGroupParam += ',pointOfSaleGroups.id,' + pointOfSaleGroup;
      });
      if (pointOfSaleGroupParam.length) {
        params['filter[]'].push('OR' + pointOfSaleGroupParam);
      }
      RestUtilService.getFullList(PosScreenFactory, params).then(function (resultScreens) {
        vm.posScreens = [];
        angular.forEach(resultScreens, function (posScreen) {
          vm.posScreens.push(posScreen);
        });
      });
    }

    function loadProducts(input) {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };
      params['filter[]'] = [
        'mostRecent,TRUE',
        'OR,hidden,NULL,hidden,FALSE',
        'OR,blueprint,NULL,blueprint,FALSE'
      ];
      if (angular.isDefined(input)) {
        params['filter[]'].push('label,LIKE ' + input);
      }
      RestUtilService.getFullList(ProductFactory, params).then(function (resultData) {
        LogService.log('products: ' + resultData, 'debug');
        vm.products = resultData;
      });
    }

    function updateScreen() {
      vm.item.label = vm.item.targetScreen.label;
      delete vm.item.product;
    }

    function updateProduct() {
      vm.item.label = vm.item.product.translatedLabel;
      delete vm.item.targetScreen;
    }

    function updateBlank() {
      vm.item.label = null;
      delete vm.item.product;
      delete vm.item.targetScreen;
    }

    function addItem() {
      if (vm.rows.length) {
        vm.rows[vm.rows.length - 1].items.push(vm.item);
      }
      vm.item = vm.createItem();
    }

    function addRow() {
      vm.rows.push({items: []});
    }

    function dragoverCallback(event, index, external, type) {
      vm.logListEvent('dragged over', event, index, external, type);
      // Disallow dropping in the third row. Could also be done with dnd-disable-if.
      return index < 10;
    }

    function dropCallback(event, index, item, external, type, allowedType) {
      vm.logListEvent('dropped at', event, index, external, type);
      if (external) {
        if (allowedType === 'itemType' && !item.label) {
          return false;
        }
        if (allowedType === 'containerType' && !angular.isArray(item)) {
          return false;
        }
      }
      return item;
    }

    function init() {
      vm.item = createItem();
    }

    function logEvent(message, event) {
      LogService.log(message + ' (triggered by the following' + event.type + 'event)', 'debug');
      LogService.log(event, 'debug');
    }

    function logListEvent(action, event, index, external, type) {
      var message = external ? 'External ' : '';
      message += type + ' element is ' + action + ' position ' + index;
      vm.logEvent(message, event);
    }

    function removeItem() {
      angular.forEach(vm.rows, function (row) {
        angular.forEach(row.items, function (item, key) {
          if (vm.item.uid === item.uid) {
            row.items.splice(key, 1);
          }
        });
      });
      vm.item = angular.copy(vm.blueprintItem);
    }

    function save() {
      var screenData = {
            label: vm.screen.label,
            master: vm.screen.master,
            pointOfSaleGroups: vm.screen.pointOfSaleGroups
          },
          promiseQueue = [];

      LogService.log(screenData, 'debug');
      return PosScreenFactory.one(screen.id).customPUT(screenData).then(function () {
        return RestUtilService.getFullList(PosScreenFactory.one(screen.id), {limit: 99}, 'items').then(function (items) {
          var column = 0, row = 0;

          angular.forEach(items, function (item) {
            LogService.log('delete item' + item, 'debug');
            promiseQueue.push(PosScreenFactory.one(screen.id).all('items').one(item.id).remove());
          });
          angular.forEach(vm.rows, function (itemRow) {
            row++;
            column = 0;
            angular.forEach(itemRow.items, function (item) {
              var screenItem = {
                column: ++column,
                row: row
              };
              if (item.color !== null) {
                screenItem.tags = [item.color];
              }
              if (item.destination === 'product') {
                screenItem.product = item.product.id;
              }
              if (item.destination === 'screen') {
                screenItem.targetScreen = item.targetScreen.id;
              }
              LogService.log('save item: ' + screenItem, 'debug');
              promiseQueue.push(PosScreenFactory.one(screen.id).all('items').post(screenItem));
            });
          });
          return Promise.all(promiseQueue).then(function () {
            $state.go('pos-settings.screens.detail', {sId: screen.id}, {reload: true});
          });
        });
      });
    }

    function selectItem(item) {
      if (vm.item !== item) {
        vm.item = item;
        vm.item.removable = true;
        return;
      }
      vm.item = vm.createItem();
    }

    function setColor(color) {
      vm.item.color = color;
    }

    function setDestination() {
      if (angular.isDefined(vm.item.targetScreen)) {
        return 'screen';
      } else if (angular.isDefined(vm.item.product)) {
        return 'product';
      }
      return 'blank';
    }
  }
}());
