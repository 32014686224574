(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:RoleAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('RoleAddEditCtrl', RoleCtrl);

  function RoleCtrl(_,
                    $modalInstance,
                    $stateParams,
                    $q,
                    LocaleService,
                    PermissionFactory,
                    RestUtilService,
                    RoleFactory,
                    UtilService) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.frenchEnabled = LocaleService.isFrenchEnabled();
    vm.item = {};
    vm.entities = [];
    vm.permissions = [];
    vm.roles = [];
    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.roleSearchQuery = '';
    vm.delete = remove;
    vm.isValid = isValid;
    vm.toggleAllPermissions = toggleAllPermissions;

    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    function save() {
      var object = {
        label: vm.item.label,
        frLabel: vm.item.frLabel,
        permissions: vm.item.permissions,
        childRoles: vm.item.childRoles,
        isFilter: vm.item.isFilter
      };

      if (vm.isEdit === true) {
        RoleFactory.one(vm.item.id).customPUT(object).then(function () {
          vm.close();
        });
      } else {
        RoleFactory.post(object).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      RoleFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function toggleAllPermissions() {
      if (UtilService.isNotEmpty(vm.item.permissions)) {
        vm.item.permissions = [];
      } else {
        vm.item.permissions = [];
        angular.forEach(vm.permissions, function (p) {
          vm.item.permissions.push(p.permission);
        });
        vm.item.permissions = _.uniq(vm.item.permissions);
      }
    }

    function init() {
      var promises = [], cleanPermissions = [];
      vm.permissions = [];
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'app.edit';
        vm.isEdit = true;
      }

      if (vm.isEdit === true) {
        promises.push(RoleFactory.one($stateParams.id).get().then(function (result) {
          vm.item = result;
          vm.item.permissions = _.values(vm.item.permissions);
          vm.item.childRoles = vm.item.childRoles ? vm.item.childRoles.map(function (v) {
            return v.id;
          }) : [];
        }));
      }

      promises.push(PermissionFactory.getList()
        .then(function (results) {
          vm.permissions = results;
        }));

      // filter old removed permissions
      $q.all(promises).then(function () {
        if (vm.item.permissions) {
          vm.item.permissions.forEach(function (permission) {
            if (vm.permissions.filter(function (p) {
              return p.permission === permission;
            }).length > 0) {
              cleanPermissions.push(permission);
            }
          });
          vm.item.permissions = cleanPermissions;
        }
      });

      RestUtilService.getFullList(RoleFactory).then(function (results) {
        vm.roles = results;
      });
    }

    function isValid() {
      return UtilService.isNotEmpty(vm.item.label) && (UtilService.isNotEmpty(vm.item.permissions) ||
        UtilService.isNotEmpty(vm.item.childRoles));
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
