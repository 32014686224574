(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name hwproxy.directive:paymentEngineConnection
   * @restrict E
   * @element
   *
   * @description
   */
  /* @ngInject */
  angular
    .module('navigation')
    .directive('paymentEngineConnection', PaymentEngineConnection);

  function PaymentEngineConnection() {
    return {
      restrict: 'E',
      rootscope: {
        posInstanceActive: '='
      },
      templateUrl: 'hwproxy/views/payment_engine.connection.tpl.html',
      replace: false,
      controllerAs: 'PaymentEngineConnectionCtrl',
      controller: [
        '$rootScope',
        'PaymentEngineFactory',
        'CurrentUserContextFactory',
        function ($rootScope, PaymentEngineFactory) {
          var vm = this;
          vm.PaymentEngineFactory = PaymentEngineFactory;

          $rootScope.$on('posGroupHasBeenChosenBroadcast', function (e, posInstance) {
            PaymentEngineFactory.connect(posInstance);
          });
        }
      ]
    };
  }
}());
