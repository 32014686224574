(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:reloadApplicationButton
   * @restrict AE
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('reloadApplicationButton', reloadApplicationButton);

  function reloadApplicationButton() {
    return {
      restrict: 'AE',
      scope: {},
      template: '<a ng-click="reloadApp()">{{ "app.reload_app" | translate | uconlyfirst }}</a>',
      controller: [
        '$scope',
        'SettingsService',
        function ($scope, SettingsService) {
          $scope.reloadApp = function () {
            SettingsService.reloadSettings();
          };
        }
      ]
    };
  }
}());
