(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:PupilListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('PupilListCtrl', PupilListCtrl);

  function PupilListCtrl(
      CurrentUserContextFactory,
      SubscriptionFactory,
      SwimCourseManagementFactory,
      UtilService,
      ContactFactory,
      $modal,
      $filter,
      LogService,
      $timeout,
      $state,
      _
    ) {
    var vm = this;
    vm.pupils = [];
    vm.deletePupil = deletePupil;
    vm.groupsInfo = {};
    vm.courseTreeLoaded = false;
    vm.setStatusFilter = setStatusFilter;

    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 20
    };

    vm.statusOptions = [
      {
        code: 'subscribed',
        label: $filter('translate')('app.subscribed')
      },
      {
        code: 'waitingList',
        label: $filter('translate')('app.waiting_list')
      }
    ];

    function setStatusFilter() {
      vm.loadPupils();
    }

    // pupil input method modal
    vm.showingPupilInputMethodModal = false;
    vm.showPupilInputMethodModal = showPupilInputMethodModal;
    vm.returnOfPupilInputMethodModalInstance = returnOfPupilInputMethodModalInstance;
    vm.pupilInputMethodModalInstance = null;

    // new contact modal
    vm.showingNewContactModal = false;
    vm.showNewContactModal = showNewContactModal;
    vm.returnOfNewContactModalInstance = returnOfNewContactModalInstance;
    vm.newContactModalInstance = null;

    // select contact modal
    vm.showingSelectContactModal = false;
    vm.showSelectContactModal = showSelectContactModal;
    vm.returnOfSelectContactModalInstance = returnOfSelectContactModalInstance;
    vm.selectContactModalInstance = null;

    // pupil view modal
    vm.showingPupilViewModal = false;
    vm.showPupilViewModal = showPupilViewModal;
    vm.pupilViewModalInstance = null;
    vm.returnOfPupilViewModalInstance = returnOfPupilViewModalInstance;

    // edit subscription date modal
    vm.showingEditSubscriptionDateModal = false;
    vm.showEditSubscriptionDateModal = showEditSubscriptionDateModal;
    vm.returnOfEditSubscriptionDateModalInstance = returnOfEditSubscriptionDateModalInstance;
    vm.editSubscriptionDateModalInstance = null;

    // add/edit subscription modal
    vm.showingAddEditSubscriptionModal = false;
    vm.showAddEditSubscriptionModal = showAddEditSubscriptionModal;
    vm.returnOfAddEditSubscriptionModalInstance = returnOfAddEditSubscriptionModalInstance;
    vm.addEditSubscriptionModalInstance = null;
    vm.loadPupils = loadPupils;
    vm.loadPupils();

    vm.showingSubscriptionCommentModal = false;
    vm.subscriptionCommentModalInstance = null;
    vm.showSubscriptionCommentModal = showSubscriptionCommentModal;
    vm.getFilterIndex = getFilterIndex;
    vm.redirectToSwimCourseGroup = redirectToSwimCourseGroup;

    vm.filters = [
      {
        key: 'name',
        val: '',
        field: 'contact.label',
        cond: 'LIKE'
      },
      {
        key: 'set',
        val: '',
        field: 'swimCourseSet.label',
        cond: 'LIKE'
      },
      {
        key: 'level',
        val: '',
        field: 'swimCourseLevel.label',
        cond: 'LIKE'
      },
      {
        key: 'group',
        val: '',
        field: 'swimCourseSeriesGroup.swimCourseGroup.label',
        cond: 'LIKE'
      }
    ];

    function getFilterIndex(key) {
      return _.findIndex(vm.filters, function (filter) {
        return filter.key === key;
      });
    }

    function loadPupils() {
      var filters = [], contactSubscribedFilters = [], contactWaitingFilters = [];
      angular.forEach(vm.filters, function (filter) {
        if (UtilService.isNotEmpty(filter.val)) {
          filters.push(filter.field + ',' + filter.cond + ' ' + filter.val);
        }
      });

      switch (vm.selectedStatusFilter) {
        case '':
        case null:
          break;
        case 'subscribed':
          filters.push('swimCourseSeriesGroup.id,NOT NULL');
          break;
        case 'waitingList':
          filters.push('swimCourseSeriesGroup.id,NULL');
          break;
        default:
          break;
      }

      vm.pupils[vm.pagination.currentPage] = [];
      SwimCourseManagementFactory.getSubscriptionsPage(
        vm.pagination.currentPage,
        vm.pagination.itemsPerPage,
        filters,
        'contact.label,ASC'
      )
      .then(function (returnObj) {
        $timeout(function () {
          vm.pupils[vm.pagination.currentPage] = returnObj.pupils;
          vm.pagination.totalItems = returnObj.total;
        });
      });
      angular.forEach(filters, function (filter) {
        contactSubscribedFilters.push('swimCourseSubscriptions.' + filter);
        contactWaitingFilters.push('swimCourseSubscriptions.' + filter);
      });
      contactSubscribedFilters.push('swimCourseSubscriptions.swimCourseSeriesGroup.id,NOT NULL');
      contactSubscribedFilters.push('swimCourseSubscriptions.swimCourseSet.site.id,' + CurrentUserContextFactory.getSiteId());
      contactWaitingFilters.push('swimCourseSubscriptions.id,NOT NULL');
      contactWaitingFilters.push('swimCourseSubscriptions.swimCourseSeriesGroup.id,NULL');
      contactWaitingFilters.push('swimCourseSubscriptions.swimCourseSet.site.id,' + CurrentUserContextFactory.getSiteId());

      ContactFactory.getList({'filter[]': contactWaitingFilters}).then(function (results) {
        vm.waitingTotal = results.count;
      });
      ContactFactory.getList({'filter[]': contactSubscribedFilters}).then(function (results) {
        vm.subscribedTotal = results.count;
      });
    }

    function deletePupil(pupil) {
      SubscriptionFactory.one(pupil.id).remove().then(function () {
        vm.loadPupils();
      });
    }

    function showPupilViewModal(pupil) {
      // Check if modal is already open
      if (vm.showingPupilViewModal) {
        return;
      }
      ContactFactory.one(pupil.contact.id).get().then(function (completePupil) {
        // Open modal view with specific template and controller, Add a customer and contact object to that controller
        vm.pupilViewModalInstance = $modal.open({
          templateUrl: 'contact/views/contact.modal.view.tpl.html',
          controller: 'ContactCtrl',
          controllerAs: 'contactCtrl',
          size: 'lg',
          resolve: {
            contact: function () {
              return completePupil;
            },
            customer: function () {
              return null;
            },
            showOnlineAccessInfo: function () {
              return false;
            }
          }
        });
        vm.showingPupilViewModal = true;
        vm.returnOfPupilViewModalInstance();
      });
    }

    function returnOfPupilViewModalInstance() {
      // Check if dismiss or close action of modal view was called
      vm.pupilViewModalInstance.result.then(function () {
        vm.showingPupilViewModal = false;
      }, function () {
        vm.showingPupilViewModal = false;
      });
    }

    function showPupilInputMethodModal() {
      if (vm.showingPupilInputMethodModal) {
        return;
      }
      vm.pupilInputMethodModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/pupils/pupil.inputmethod.modal.tpl.html',
        controller: 'PupilInputMethodCtrl',
        controllerAs: 'pupilInputMethodCtrl',
        size: 'md',
        resolve: {
          addNewContactIsAllowed: false,
          addExistingContactIsAllowed: true
        }
      });

      vm.showingPupilInputMethodModal = true;
      vm.returnOfPupilInputMethodModalInstance();
    }

    function returnOfPupilInputMethodModalInstance() {
      vm.pupilInputMethodModalInstance.result.then(function (returnValue) {
        vm.showingPupilInputMethodModal = false;
        switch (returnValue) {
          case 'new_contact':
            vm.showNewContactModal();
            break;
          case 'select_contact':
            vm.showSelectContactModal();
            break;
          default:
            break;
        }
      }, function () {
        vm.showingPupilInputMethodModal = false;
      });
    }

    function showSubscriptionCommentModal(subscription) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/pupils/pupil.subscription.comment.addedit.modal.tpl.html',
          controller: 'SubscriptionCommentEditCtrl',
          controllerAs: 'subscriptionCommentEditCtrl',
          resolve: {
            subscription: function () {
              return subscription;
            }
          }
        },
        function () {
          vm.loadPupils();
        },
        null,
        true
      );
    }

    // CREATE CONTACT
    function showNewContactModal() {
      if (vm.showingNewContactModal) {
        return;
      }
      vm.newContactModalInstance = $modal.open({
        templateUrl: 'customer/views/customer.pos.modal.add.tpl.html',
        controller: 'CustomerAddPosCtrl',
        controllerAs: 'customerAddPosCtrl'
      });

      vm.showingNewContactModal = true;
      vm.returnOfNewContactModalInstance();
    }

    function returnOfNewContactModalInstance() {
      vm.newContactModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.showingNewContactModal = false;
        vm.newContactModalInstance = null;
        vm.customer = returnValue.customer;
        vm.customerContact = returnValue.contact;
        vm.showAddEditSubscriptionModal(returnValue.contact);
        // vm.refreshJournals();
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.showingNewContactModal = false;
        vm.newContactModalInstance = null;
      });
    }

    // NAVIGATE TO SWIMSCHOOL GROUP
    function redirectToSwimCourseGroup(pupil) {
      var set = pupil.swimCourseSet.id,
          series = pupil.swimCourseSeriesGroup.swimCourseSeries.id,
          seriesGroup = pupil.swimCourseSeriesGroup.id;
      $state.go('swimcourse.groups', {setId: set, serId: series, groId: seriesGroup});
    }

    // SELECT CONTACT
    function showSelectContactModal() {
      if (vm.showingSelectContactModal) {
        return;
      }
      vm.selectContactModalInstance = $modal.open({
        templateUrl: 'pos/views/pos.customers.list.modal.tpl.html',
        controller: 'PosCustomerListCtrl',
        controllerAs: 'posCustomerListCtrl',
        size: 'lg',
        resolve: {
          customerId: function () {
            return null;
          },
          customerContactTypeId: function () {
            return null;
          }
        }
      });

      vm.showingSelectContactModal = true;
      vm.returnOfSelectContactModalInstance();
    }

    function returnOfSelectContactModalInstance() {
      vm.selectContactModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.selectContactModalInstance = null;
        vm.showingSelectContactModal = false;
        vm.showAddEditSubscriptionModal(returnValue.contact);
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.selectContactModalInstance = null;
        vm.showingSelectContactModal = false;
      });
    }

    // EDIT SUBSCRIPTION DATE
    function showEditSubscriptionDateModal(pupil) {
      if (vm.showingEditSubscriptionDateModal) {
        return;
      }
      vm.editSubscriptionDateModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/pupils/pupil.subscription-date.edit.modal.tpl.html',
        controller: 'SubscriptionDateEditCtrl',
        controllerAs: 'subscriptionDateEditCtrl',
        size: 'sm',
        resolve: {
          pupil: function () {
            return pupil;
          }
        }
      });

      vm.showingEditSubscriptionDateModal = true;
      vm.returnOfEditSubscriptionDateModalInstance();
    }

    function returnOfEditSubscriptionDateModalInstance() {
      vm.editSubscriptionDateModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.editSubscriptionDateModalInstance = null;
        vm.showingEditSubscriptionDateModal = false;
        vm.loadPupils();
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.editSubscriptionDateModalInstance = null;
        vm.showingEditSubscriptionDateModal = false;
      });
    }

    // ADD EDIT SUBSCRIPTION
    function showAddEditSubscriptionModal(contact) {
      if (vm.showingAddEditSubscriptionModal) {
        return;
      }
      vm.addEditSubscriptionModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/pupils/pupil.subscription.addedit.modal.tpl.html',
        controller: 'SubscriptionAddEditCtrl',
        controllerAs: 'subscriptionAddEditCtrl',
        size: 'sm',
        resolve: {
          contact: function () {
            return contact;
          },
          subscription: function () {
            return null;
          },
          set: function () {
            return null;
          },
          level: function () {
            return null;
          }
        }
      });

      vm.showingAddEditSubscriptionModal = true;
      vm.returnOfAddEditSubscriptionModalInstance();
    }

    function returnOfAddEditSubscriptionModalInstance() {
      vm.addEditSubscriptionModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.addEditSubscriptionModalInstance = null;
        vm.showingAddEditSubscriptionModal = false;
        vm.customer = returnValue.customer;
        vm.customerContact = returnValue.contact;

        vm.loadPupils();
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.addEditSubscriptionModalInstance = null;
        vm.showingAddEditSubscriptionModal = false;
      });
    }
  }
}());
