(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('dashboard')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl(
                         $interval,
                         $scope,
                         $timeout,
                         $document,
                         $window,
                         $translate,
                         CurrentUserContextFactory,
                         NewsItemFactory,
                         OAuth,
                         PeopleCountFactory,
                         PosSessionFactory,
                         RestUtilService,
                         SaleFactory,
                         SettingsService,
                         StatisticInfoFactory,
                         PermissionsUtilService,
                         UserMeFactory,
                         UtilService) {
    var vm = this, html5QrcodeScanner;
    vm.activePosSessions = [];
    vm.corruptSales = [];
    vm.paymentRequestedSales = [];
    vm.newsItems = [];
    vm.statistics = {
      values: [],
      labels: [],
      total: 0,
      options: {
        animation: false,
        colors: ['#007FC8',
          '#F4A50C',
          '#A4D025',
          '#09134D',
          '#F86C5C'],
        legend: {
          display: true,
          position: 'right'
        }
      }
    };
    vm.statistics2 = {
      values: [],
      labels: [],
      total: 0,
      options: {
        animation: false,
        colors: [
          '#F4A50C',
          '#007FC8',
          '#A4D025',
          '#09134D',
          '#F86C5C'],
        legend: {
          display: true,
          position: 'right'
        }
      }
    };

    vm.loadActivePosSessions = loadActivePosSessions;
    vm.loadCorruptSales = loadCorruptSales;
    vm.loadNewsItems = loadNewsItems;
    vm.suspiciuosPosSession = suspiciuosPosSession;
    vm.openPasswordModal = openPasswordModal;
    vm.loadPasswordExpired = loadPasswordExpired;
    vm.loadStatistics = loadStatistics;
    vm.loadPaymentRequestedtSales = loadPaymentRequestedtSales;
    vm.passwordExpired = false;
    vm.qrCodeReaderActive = false;
    vm.cameraIsOn = false;
    vm.webshopSale = null;
    vm.enableStatistics = SettingsService.get('dashboard.enableStatistics');
    vm.enableWebshop = SettingsService.get('products.enableProductPropertyVisibleInWebshop', false);
    vm.peopleCounterVisible = SettingsService.get('pos.peoplecounterVisible', false);
    vm.kioskQRCodeIncludeOTP = SettingsService.get('kiosk.QRCodeIncludeOTP', 'false');
    vm.peopleCountsPerFacility = [];
    vm.loadPeopleCount = loadPeopleCount;
    vm.peopleCountInterval = null;
    vm.hideHtml5QrcodeScanner = hideHtml5QrcodeScanner;
    vm.showHtml5QrcodeScanner = showHtml5QrcodeScanner;

    if (angular.isDefined(CurrentUserContextFactory.getUserContextId()) && OAuth.isAuthenticated()) {
      if (PermissionsUtilService.userHasPermission(['manage pos session'])) {
        vm.loadActivePosSessions();
      }

      if (PermissionsUtilService.userHasPermission(['manage sale', 'manage pos'], 'any')) {
        vm.loadCorruptSales();
      }

      if (PermissionsUtilService.userHasPermission(['manage sale', 'manage pos'], 'any') &&
        SettingsService.get('pos.saleStatusPaymentRequestedEnabled', false)) {
        vm.loadPaymentRequestedtSales();
      }

      if (PermissionsUtilService.userHasPermission(['view news', 'edit news', 'manage news'], 'any')) {
        vm.loadNewsItems();
      }

      if (vm.enableStatistics) {
        loadStatistics();
      }

      vm.loadPasswordExpired();

      if (vm.peopleCounterVisible) {
        vm.loadPeopleCount();
      }
    }

    function hideHtml5QrcodeScanner() {
      if (vm.kioskQRCodeIncludeOTP === 'true') {
        return true;
      }

      return false;
    }

    function showHtml5QrcodeScanner() {
      if (vm.kioskQRCodeIncludeOTP === 'true') {
        return true;
      }

      return false;
    }

    $document.ready(function () {
      var lastResult;

      if (vm.kioskQRCodeIncludeOTP === 'false') {
        return;
      }

      function onScanSuccess(decodedText, decodedResult) {
        if (decodedText !== lastResult) {
          lastResult = decodedText;
          console.log('Scan result ${decodedText}', decodedResult);

          SaleFactory.validateWebshopSale(decodedResult.decodedText).then(function (sale) {
            vm.webshopSale = sale;
          });
        }
      }

      html5QrcodeScanner = new $window.Html5QrcodeScanner('qr-reader', { fps: 10, qrbox: 250 });
      html5QrcodeScanner.render(onScanSuccess);
    });

    function loadPasswordExpired() {
      return UserMeFactory.one().get().then(function (me) {
        // If password changed more than approx. 6 months ago
        var before = new Date();
        before.setMonth(before.getMonth() - 6);
        $timeout(function () {
          vm.passwordExpired = new Date(me.lastPasswordChange) < before;
        });
      });
    }

    function openPasswordModal() {
      UtilService.showModal({
        templateUrl: 'auth/views/password-change.modal.tpl.html',
        controller: 'PasswordChangeCtrl',
        size: 'md'
      }, function () {
        vm.loadPasswordExpired();
      });
    }

    function loadActivePosSessions() {
      return PosSessionFactory.getActiveSessions()
        .then(function (activePosSessions) {
          $timeout(function () {
            vm.activePosSessions = activePosSessions;
          });
        });
    }

    function loadCorruptSales() {
      var params = {
        corrupt: 'true',
        sort: 'createdAt,ASC'
      };

      if (PermissionsUtilService.userHasPermission('manage sale', 'all')) {
        params['filter[]'] = ['site.id,' + CurrentUserContextFactory.getSiteId()];
      }

      return RestUtilService.getFullList(SaleFactory, params)
        .then(function (resultData) {
          $timeout(function () {
            vm.corruptSales = resultData;
          });
        });
    }

    function loadPaymentRequestedtSales() {
      var params = {
        payment_requested: 'true',
        sort: 'createdAt,ASC'
      };

      if (PermissionsUtilService.userHasPermission('manage sale', 'all')) {
        params['filter[]'] = ['site.id,' + CurrentUserContextFactory.getSiteId()];
      }

      return RestUtilService.getFullList(SaleFactory, params)
      .then(function (resultData) {
        $timeout(function () {
          vm.paymentRequestedSales = resultData;
        });
      });
    }

    function loadNewsItems() {
      var params = {
            limit: 5,
            sort: 'publishedAt,DESC',
            site: CurrentUserContextFactory.getSiteId()
          }, start = new Date().toISOString();
      params['filter[]'] = [
        'startDate,LTE ' + start,
        'endDate,GTE ' + start
      ];
      vm.newsItems = [];

      NewsItemFactory.getList(params)
        .then(function (result) {
          $timeout(function () {
            vm.newsItems = result;

            angular.forEach(vm.newsItems, function (newsItem) {
              if (!newsItem.tags) {
                newsItem.tags = [];
              }
            });
          });
        });
    }

    function suspiciuosPosSession(posSession) {
      return Math.round(new Date().getTime() / 1000) - Math.round(new Date(posSession.createdAt).getTime() / 1000) > 12 * 60 * 60;
    }

    function loadStatistics() {
      return StatisticInfoFactory.getList().then(function (results) {
        vm.statistics.values = [];
        vm.statistics.labels = [];
        vm.statistics.total = 0;
        results.forEach(function (statisticInfo) {
          vm.statistics.values.push(statisticInfo.value);
          vm.statistics.labels.push($translate.instant('app.statistics.' + statisticInfo.label.toLowerCase()) + ' - ' + statisticInfo.value);
          vm.statistics.total += Number(statisticInfo.value);
        });
      });
    }

    vm.activateQrCodeReader = function () {
      vm.qrCodeReaderActive = true;
      vm.webshopSale = null;
    };

    vm.onQrCodeSuccess = function (data) {
      vm.qrCodeReaderActive = false;
      SaleFactory.validateWebshopSale(data).then(function (sale) {
        vm.webshopSale = sale;
      });
    };

    vm.showCustomerPosModal = function () {
      if (vm.webshopSale === null) {
        return;
      }

      UtilService.showModal({
        templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
        controller: 'HwProxyMemberCardDataCtrl',
        controllerAs: 'HwProxyMemberCardDataCtrl',
        size: 'lg',
        resolve: {
          memberCardData: function () {
            return null;
          },
          customer: function () {
            return vm.webshopSale.customer;
          },
          customerContact: function () {
            return vm.webshopSale.customerContact;
          }
        }
      }, null, null, true);
    };

    vm.onQrCodeError = function (error) {
      console.log(error);
    };

    function loadPeopleCount() {
      var statistics2values = [], statistics2label = [];
      PeopleCountFactory.one().get().then(function (peopleCountsPerFacility) {
        vm.peopleCountsPerFacility = peopleCountsPerFacility.results;
        peopleCountsPerFacility.results.forEach(function (element) {
          statistics2values.push(element.value);
          statistics2label.push(element.facility.label.toLowerCase() + ' - ' + element.value);
        });
        vm.statistics2.values = statistics2values;
        vm.statistics2.labels = statistics2label;
      });
    }

    if (vm.peopleCounterVisible) {
      vm.peopleCountsPerFacility = [];
      vm.peopleCountInterval = $interval(function () {
        vm.loadPeopleCount();
      }, 10000);
    }

    $scope.$on('$destroy', function () {
      if (vm.peopleCountInterval !== null) {
        $interval.cancel(vm.peopleCountInterval);
      }
    });
  }
}());
