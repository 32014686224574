(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:ServiceFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('ServiceFactory', ServiceFactory);

  function ServiceFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'service'});
      })
      .service('services');

    return {
      getList: function (params) {
        return service.getList(params);
      },
      one: function (fragment) {
        return service.one(fragment);
      },
      post: function (params) {
        return service.post(params);
      },
      patch: function (params) {
        return service.patch(params);
      },
      put: function (params) {
        return service.put(params);
      },
      remove: function (params) {
        return service.remove(params);
      },
      removeWithSales: function (invoiceId) {
        return service.one(invoiceId).all('removeWithSales').remove();
      },
      getSwimCourseSetsByLevel: function (levelid) {
        return service.one('swim_courses').one('sets').one('by_level')
          .getList(levelid, {limit: 99});
      },
      getSwimCourseSeriesGroupByLevelAndSet: function (levelid, setid, showRecentlyExpired) {
        var params = {
          limit: 99
        };

        if (angular.isDefined(showRecentlyExpired) && showRecentlyExpired) {
          params.showRecentlyExpired = true;
        }

        return service.one('swim_courses').one('groups')
          .one(levelid)
          .getList(setid, params);
      },
      getSwimCourseSubscriptionsByCustomer: function (customerid) {
        return service.one('swim_courses').one('subscriptions')
          .getList(customerid, {limit: 99});
      },
      deleteWaitingListBySubscribedSubscription: function (subscriptionId) {
        return service.one('swim_courses').one('waitinglists').one(subscriptionId).remove();
      }
    };
  }
}());
