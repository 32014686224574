(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.controller:FacilityAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .controller('FacilityAddEditCtrl', FacilityAddEditCtrl);

  function FacilityAddEditCtrl(
    $modalInstance,
    facility,
    reloadType,
    FacilityFactory,
    FacilityTypeFactory,
    ContactLocationFactory,
    CurrentUserContextFactory,
    ToastrNotificationService,
    ContactFactory,
    SiteFactory,
    RestUtilService,
    UtilService,
    CountryFactory,
    _
    ) {
    var vm = this;

    if (facility !== null) {
      vm.facility = facility;
    }

    vm.facilities = [];
    vm.contacts = [];
    vm.facilityTypes = [];
    vm.facilityLocation = {
      id: null,
      countryCode: null,
      street: '',
      number: '',
      box: '',
      postalCode: '',
      city: ''
    };

    CountryFactory.getCountries()
    .then(function (countries) {
      vm.countries = countries;
    });

    vm.newChild = {};
    vm.availableChilds = [];
    vm.isUpdatedChilds = false;
    vm.currentUserSiteId = CurrentUserContextFactory.getSiteId();

    vm.isEdit = isEdit;
    vm.showSavedNotification = showSavedNotification;
    vm.saveFacility = saveFacility;
    vm.cancel = cancelModalInstance;
    vm.addChild = addChild;
    vm.removeChild = removeChild;
    vm.removeParent = removeParent;
    vm.clearContacts = clearContacts;
    vm.notifyAndCloseModal = notifyAndCloseModal;
    vm.saveLocation = saveLocation;
    vm.init = init;
    vm.init();

    function notifyAndCloseModal() {
      vm.showSavedNotification();
      $modalInstance.close(reloadType);
    }

    function saveLocation() {
      var locationObject = null,
      // country code is always filled in, so we can't just use isNotEmpty on the whole facilityLocation object
          locationDataPresent = UtilService.isNotEmpty(vm.facilityLocation.street) ||
          UtilService.isNotEmpty(vm.facilityLocation.box) ||
          UtilService.isNotEmpty(vm.facilityLocation.city) ||
          UtilService.isNotEmpty(vm.facilityLocation.number) ||
          UtilService.isNotEmpty(vm.facilityLocation.postalCode);

      return new Promise(function (resolve) {
        if (locationDataPresent) {
          locationObject = {
            street: vm.facilityLocation.street,
            number: vm.facilityLocation.number,
            box: vm.facilityLocation.box,
            postalCode: vm.facilityLocation.postalCode,
            city: vm.facilityLocation.city,
            countryCode: vm.facilityLocation.countryCode
          };
          if (vm.facilityLocation.id) {
            // Post location to backend
            ContactLocationFactory
            .one(vm.facilityLocation.id)
            .patch(locationObject)
            .then(function () {
              resolve();
            });
          } else {
            // Post location to backend
            ContactLocationFactory.post(locationObject)
            .then(function (createdLocation) {
              // Link location to contact
              vm.facilityLocation.id = createdLocation.id;
              resolve();
            });
          }
        } else {
          resolve();
        }
      });
    }

    function saveFacility() {
      var facilityObject = {
        label: vm.facility.label,
        facilityType: vm.facility.facilityType.id
      };

      vm.saveLocation()
      .then(function () {
        if (UtilService.isNotEmpty(vm.facilityLocation.id)) {
          facilityObject.locations = [vm.facilityLocation.id];
        }

        if (getParentFacility()) {
          // backend takes an array, but we pick a single facility on frontend
          facilityObject.parentFacility = getParentFacility();
        }

        if (angular.isDefined(vm.facility.contact) && vm.facility.contact !== '') {
          // backend takes an array, but we pick a single contact on frontend
          facilityObject.contacts = [vm.facility.contact];
        }

        if (vm.isEdit()) {
          // If edit mode
          ++vm.notifyCounter;
          FacilityFactory.one(vm.facility.id).customPUT(facilityObject)
          .then(function () {
            vm.notifyAndCloseModal();
          });
        } else {
          //  If not edit mode
          facilityObject.site = vm.currentUserSiteId;
          FacilityFactory.post(facilityObject).then(function (newFacility) {
            vm.facility = newFacility;
            vm.notifyAndCloseModal();
          });
        }

        //  looks like child cannot be updated from parent
        if (vm.isUpdatedChilds) {
          UtilService.promiseLoop(vm.facility.childFacilities,
            function (childFacility) {
              return FacilityFactory.one(childFacility.id)
              .patch({
                parentFacility: vm.facility.id
              });
            }
          )
          .then(function () {
            vm.notifyAndCloseModal();
          });
        }
      });
    }

    function showSavedNotification() {
      var messageString = vm.isEdit() ? 'app.item-successfully-updated' : 'app.item-successfully-saved';
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-saved',
        'app.facility',
         messageString,
        'app.facility'
      );
    }

    function init() {
      var facilityParams;

      if (vm.isEdit()) {
        // if edit - load full self object
        FacilityFactory.one(vm.facility.id).get().then(function (resultFacility) {
          vm.facility = resultFacility;
          if (UtilService.isNotEmpty(vm.facility.contacts)) {
            // multiple on backend, single on frontend
            vm.facility.contact = vm.facility.contacts[0].id;
          }

          if (UtilService.isNotEmpty(vm.facility.locations)) {
            vm.facilityLocation = vm.facility.locations[0];
          }
        });
      }

      vm.contacts = [];
      SiteFactory.one(vm.currentUserSiteId).get().then(function (site) {
        var params = {};

        if (site.contact.id) {
          params['filter[]'] = [
            'childContactRelations.parentContact.id,' + site.contact.id,
            'childContactRelations.contactRelationType.code,EMPLOYMENT'
          ];

          RestUtilService.getFullList(ContactFactory, params).then(function (contacts) {
            vm.contacts = contacts;
          });
        }
      });

      FacilityTypeFactory.getList({limit: 99}).then(function (resultFacilityTypes) {
        vm.facilityTypes = resultFacilityTypes;
      });

      facilityParams = {
        limit: 99,
        'filter[]': ['site.id,' + CurrentUserContextFactory.getSiteId()]
      };
      FacilityFactory.getList(facilityParams).then(function (resultFacilities) {
        vm.facilities = resultFacilities;
        //  if edit - exclude self from child/parent list
        _.each(vm.facilities, function (item, key) {
          vm.facilities[key].available = true;
          if (vm.isEdit() && item.id === vm.facility.id) {
            vm.facilities[key].available = false;
          }
        });
      });
    }

    function addChild() {
      var parentId = getParentFacility();
      if (angular.isUndefined(vm.facility.childFacilities)) {
        vm.facility.childFacilities = [];
      }
      if (parentId === vm.newChild.id) {
        delete vm.facility.parentFacility;
      }
      vm.facility.childFacilities.push(vm.newChild);
      vm.isUpdatedChilds = true;
      vm.newChild = '';
    }

    function removeChild(index) {
      vm.facility.childFacilities.splice(index, 1);
      vm.isUpdatedChilds = true;
    }

    function removeParent() {
      vm.facility.parentFacility = [];
    }

    function getParentFacility() {
      var id = false;
      if (angular.isDefined(vm.facility.parentFacility)) {
        if (angular.isDefined(vm.facility.parentFacility.id)) {
          id = vm.facility.parentFacility.id;
        }
      }
      return id;
    }

    function clearContacts() {
      vm.facility.contact = '';
    }

    function isEdit() {
      var edit = false;
      if (angular.isDefined(vm.facility)) {
        if (angular.isDefined(vm.facility.id)) {
          edit = true;
        }
      }
      return edit;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
