(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name image
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('image')
    .factory('ImageService', ImageService);

  function ImageService(ImageFactory) {
    return {
      getImageFromBackend: function (imageId) {
        return ImageFactory.one(imageId)
          .get()
          .then(function (image) {
            return image;
          });
      }
    };
  }
}());
