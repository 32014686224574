(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name history.service:HistoryService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .service('HistoryService', HistoryService);

  function HistoryService() {
    var vm = this;

    vm.stripVersionedData = stripVersionedData;
    vm.getVersionedData = getVersionedData;

    function stripVersionedData(data, stripKeys) {
      var key, changes = {};

      for (key in data) {
        if (data.hasOwnProperty(key) && !stripKeys.includes(key)) {
          changes[key] = data[key];
        }
      }

      return changes;
    }

    function getVersionedData(data, keys) {
      var key, changes = {};

      for (key in data) {
        if (data.hasOwnProperty(key) && keys.includes(key)) {
          changes[key] = data[key];
        }
      }

      return changes;
    }
  }
}());
