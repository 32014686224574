(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name journal.factory:Journal
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .factory('JournalFactory', JournalFactory);

  function JournalFactory(Restangular) {
    var journalService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'journal'});
    })
    .service('journals');

    return {
      getList: function (params) {
        return journalService.getList(params);
      },
      one: function (fragment) {
        return journalService.one(fragment);
      },
      getJournalById: function (journalId) {
        return journalService.one(journalId).get();
      },
      getJournalValidity: function (journalId) {
        return journalService.one(journalId).one('validity').get();
      }
    };
  }
}());
