(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name history.factory:HistoryFactory
   *
   * @description
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('HistoryFactory', HistoryFactory);

  function HistoryFactory(Restangular) {
    var historyFactory = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'resource_version'});
      })
      .service('resource/history');

    return {
      getList: function (params) {
        return historyFactory.getList(params);
      },
      one: function (fragment) {
        return historyFactory.one(fragment);
      }
    };
  }
}());
