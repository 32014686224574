(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.factories:NewsItemFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('NewsItemFactory', NewsItemFactory);

  function NewsItemFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'newsItem'});
    })
    .service('news/items');
  }
}());
