(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:uniqueSsnValidator
   * @restrict A
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('uniqueSsnValidator', uniqueSsnValidator);

  function uniqueSsnValidator(
    Restangular,
    SettingsService,
    $q
    ) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attr, ngModel) {
        //example: 79.05.13-313.21
        // - group 1 = birthday
        //      year.month.day
        //      month and day can be zero if the exact birthday is not known.
        //      bisnumbers: this is for temporary persons like frontier workers who need a number
        //        In this case the month can be add by 40 when the gender is known
        //        If the gender is not known then 20 is added.
        // - group 2 = sequence of born that day
        //    man: 001 till 997
        //    women: 002 till 998
        // - group 3 = controle modulo 97
        //    if the birthdate is after 1999-12-31 then you need to add + 2000000000 before calculating the mod97
        ngModel.$asyncValidators.ssnNotUnique = function (modelValue) {
          var deferred = $q.defer(),
              useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
          ngModel.$setValidity('invalidSsn', true);
          if (modelValue && useAlternateCustomerForm) {
            if (controlMod97(modelValue)) {
              Restangular.one('services').one('validate-unique-ssn', modelValue).one(attr.contactId).get().then(function (response) {
                if (response.data.valid) {
                  deferred.resolve();
                } else {
                  deferred.reject();
                }
              });
            } else {
              ngModel.$setValidity('invalidSsn', false);
              deferred.resolve();
            }
          } else {
            deferred.resolve();
          }
          return deferred.promise;
        };
      }
    };
  }
  function controlMod97(value) {
    var success = false,
        y = parseInt(value.slice(0, -2), 10),
        x = parseInt(value.slice(-2), 10);
    if (97 - (y % 97) === x) {
      success = true;
    } else {
      // if the birthdate is after 1999-12-31 then you need to add + 2000000000 before calculating the mod97
      success = (97 - ((y + 2000000000) % 97)) === x;
    }
    return success;
  }
}());
