(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:MultipleSubscribeCtrl
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .controller('MultipleSubscribeCtrl', MultipleSubscribeCtrl);

  function MultipleSubscribeCtrl($modalInstance) {
    var vm = this;
    vm.count = 0;
    vm.cancel = cancel;
    vm.result = 0;
    vm.sendCount = sendCount;
    vm.setCount = setCount;
    vm.error = null;
    vm.availableCreditsCounts = setAvailableCredits();

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function setAvailableCredits() {
      var result = [], i = 1;
      for (i; i <= $modalInstance.availableCredits; i++) {
        if (i > 9) {
          break;
        }
        result.push({count: i});
      }
      return result;
    }

    function setCount(number) {
      vm.count = number;
    }

    function sendCount() {
      if (vm.count > $modalInstance.availableCredits) {
        vm.error = 'insufficient funds';
      } else {
        $modalInstance.count = vm.count;
        $modalInstance.close();
      }
    }
  }
}());
