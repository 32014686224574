(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosGroupListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosGroupListCtrl', PosGroupListCtrl);

  function PosGroupListCtrl(
    $modal,
    hwproxy,
    PaymentEngineFactory,
    PosGroupFactory,
    SettingsService,
    UtilService,
    $window
    ) {
    var vm = this;
    vm.posGroups = [];
    vm.loadData = loadData;

    vm.isPaymentEngineEnabled = PaymentEngineFactory.enabled();
    vm.isHwProxyEnabled = hwproxy.enabled();

    // posgroup add/edit modal
    vm.showingPosGroupAddEditModal = false;
    vm.showPosGroupAddEditModal = showPosGroupAddEditModal;
    vm.returnOfPosGroupModalInstance = returnOfPosGroupModalInstance;
    vm.posGroupAddEditModalInstance = null;

    // posgroup delete modal
    vm.showingPosGroupDeleteModal = false;
    vm.showPosGroupDeleteModal = showPosGroupDeleteModal;
    vm.returnOfPosGroupDeleteModalInstance = returnOfPosGroupDeleteModalInstance;
    vm.posGroupDeleteModalInstance = null;

    // instance add/edit modal
    vm.showingPosInstanceAddEditModal = false;
    vm.showPosInstanceAddEditModal = showPosInstanceAddEditModal;
    vm.returnOfPosInstanceModalInstance = returnOfPosInstanceModalInstance;
    vm.posInstanceAddEditModalInstance = null;

    // instance delete modal
    vm.showingPosInstanceDeleteModal = false;
    vm.showPosInstanceDeleteModal = showPosInstanceDeleteModal;
    vm.returnOfPosInstanceDeleteModalInstance = returnOfPosInstanceDeleteModalInstance;
    vm.posInstanceDeleteModalInstance = null;
    vm.downloadPayconiqQrCode = downloadPayconiqQrCode;
    vm.payconiqPaymentsEnabled = SettingsService.get('payments.payconiqEnabled', false);
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3' ||
      SettingsService.get('pos.session.approvalFlowVersion') === '4');

    vm.loadData();

    function loadData() {
      var openGroups = [];
      // collect open groups
      angular.forEach(vm.posGroups, function (cg) {
        if (cg.hasOwnProperty('instancesShown') && cg.instancesShown) {
          openGroups.push(cg.id);
        }
      });

      vm.posGroups = [];
      PosGroupFactory.getList({limit: 99}).then(function (resultGroups) {
        angular.forEach(resultGroups, function (currentGroup) {
          // fold open if this group is in the list of open groups
          currentGroup.instancesShown = openGroups.indexOf(currentGroup.id) > -1;
          PosGroupFactory.one(currentGroup.id).getList('instances', {limit: 99})
          .then(function (resultInstances) {
            currentGroup.instances = resultInstances;
            vm.posGroups.push(currentGroup);
            // $timeout(function () {
            // });
          });
        });
      });
    }

    function showPosGroupAddEditModal(posGroup) {
      if (vm.showingPosGroupAddEditModal) {
        return;
      }

      vm.posGroupAddEditModalInstance = $modal.open({
        templateUrl: 'pos-settings/views/posgroup.addedit.modal.tpl.html',
        controller: 'PosGroupAddEditCtrl',
        controllerAs: 'posGroupAddEditCtrl',
        resolve: {
          posGroup: function () {
            return UtilService.isNotEmpty(posGroup) ? posGroup : null;
          }
        }
      });

      vm.showingPosGroupAddEditModal = true;
      vm.returnOfPosGroupModalInstance();
    }

    function returnOfPosGroupModalInstance() {
      vm.posGroupAddEditModalInstance.result.then(function () {
        vm.showingPosGroupAddEditModal = false;
        vm.loadData();
      }, function () {
        vm.showingPosGroupAddEditModal = false;
      });
    }

    function showPosInstanceAddEditModal(posGroup, posInstance) {
      if (vm.showingPosInstanceAddEditModal) {
        return;
      }

      vm.posInstanceAddEditModalInstance = $modal.open({
        templateUrl: 'pos-settings/views/posinstance.addedit.modal.tpl.html',
        controller: 'PosInstanceAddEditCtrl',
        controllerAs: 'posInstanceAddEditCtrl',
        resolve: {
          posGroup: function () {
            return UtilService.isNotEmpty(posGroup) ? posGroup : null;
          },
          posInstance: function () {
            return UtilService.isNotEmpty(posInstance) ? posInstance : null;
          }
        }
      });

      vm.showingPosInstanceAddEditModal = true;
      vm.returnOfPosInstanceModalInstance();
    }

    function returnOfPosInstanceModalInstance() {
      vm.posInstanceAddEditModalInstance.result.then(function () {
        vm.showingPosInstanceAddEditModal = false;
        vm.loadData();
      }, function () {
        vm.showingPosInstanceAddEditModal = false;
      });
    }

    function showPosGroupDeleteModal(posGroup) {
      if (vm.showingPosGroupDeleteModal) {
        return;
      }

      vm.posGroupDeleteModalInstance = $modal.open({
        templateUrl: 'pos-settings/views/posgroup.delete.modal.tpl.html',
        controller: 'PosGroupAddEditCtrl',
        controllerAs: 'posGroupAddEditCtrl',
        resolve: {
          posGroup: function () {
            return UtilService.isNotEmpty(posGroup) ? posGroup : null;
          }
        }
      });

      vm.showingPosGroupDeleteModal = true;
      vm.returnOfPosGroupDeleteModalInstance();
    }

    function returnOfPosGroupDeleteModalInstance() {
      vm.posGroupDeleteModalInstance.result.then(function () {
        vm.showingPosGroupDeleteModal = false;
        vm.loadData();
      }, function () {
        vm.showingPosGroupDeleteModal = false;
      });
    }

    function showPosInstanceDeleteModal(posGroup, posInstance) {
      if (vm.showingPosInstanceDeleteModal) {
        return;
      }

      vm.posInstanceDeleteModalInstance = $modal.open({
        templateUrl: 'pos-settings/views/posinstance.delete.modal.tpl.html',
        controller: 'PosInstanceAddEditCtrl',
        controllerAs: 'posInstanceAddEditCtrl',
        resolve: {
          posGroup: function () {
            return UtilService.isNotEmpty(posGroup) ? posGroup : null;
          },
          posInstance: function () {
            return UtilService.isNotEmpty(posInstance) ? posInstance : null;
          }
        }
      });

      vm.showingPosInstanceDeleteModal = true;
      vm.returnOfPosInstanceDeleteModalInstance();
    }

    function returnOfPosInstanceDeleteModalInstance() {
      vm.posInstanceDeleteModalInstance.result.then(function () {
        vm.showingPosInstanceDeleteModal = false;
        vm.loadData();
      }, function () {
        vm.showingPosInstanceDeleteModal = false;
      });
    }

    function downloadPayconiqQrCode(posGroup, instance) {
      var link = null;
      PosGroupFactory.one(posGroup.id).one('instances').one(instance.id).one('static-payconiq-qr-code').get().then(function (data) {
        link = data;
        $window.open(link, '_blank');
      });
      return link;
    }
  }
}());
