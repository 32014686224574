(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerDeleteCtrl', CustomerDeleteCtrl);

  function CustomerDeleteCtrl(CustomerFactory, $modalInstance, customer) {
    var vm = this;
    vm.customer = customer;

    vm.cancel = function () {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    };

    vm.deleteCustomer = function (reloadOption) {
      // Delete a specific customer
      CustomerFactory.remove(vm.customer.id)
      .then(function () {
        $modalInstance.close(reloadOption);
      });
    };
  }
}());
