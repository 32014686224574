(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:Sets
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SwimCourseSetFactory', SwimCourseSetFactory);

  function SwimCourseSetFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseSet'});
    })
    .service('swim_courses/sets');
  }
}());
