(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosSessionService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .service('PosSessionService', PosSessionService);

  function PosSessionService() {
    this.getCashierStatusLabel = function (session) {
      if (!session.isCashierOk && !session.isCashierNotOk && !session.isCashierEmpty) {
        return 'app.blank';
      }
      if (session.isCashierEmpty) {
        return 'app.empty';
      }
      if (session.isCashierOk) {
        return 'app.ok';
      }
      if (session.isCashierNotOk) {
        return 'app.not_ok';
      }
    };

    this.getStatusLabel = function (session) {
      if (!session.isApproved && !session.isRejected) {
        return 'app.blank';
      }
      if (!session.isApproved && session.isRejected) {
        return 'app.rejected';
      }
      if (session.isApproved) {
        return 'app.approved';
      }
    };
  }
}());
