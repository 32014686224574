(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:SavingsCampaignCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('SavingsCampaignCtrl', SavingsCampaignCtrl);

  function SavingsCampaignCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    SavingsCampaignFactory
  ) {
    var vm = this;
    vm.savingsCampaigns = [];
    vm.loadSavingsCampaigns = loadSavingsCampaigns;
    vm.showModalAddEditView = showModalAddEditView;
    vm.deleteSavingsCampaignRecord = deleteSavingsCampaignRecord;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    vm.showModalDeleteView = function (savingsCampaign) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.savings_campaign', 'app.delete-msg', 'app.this_savings_campaign',
        function (returnValue) {
          if (returnValue) {
            vm.deleteSavingsCampaignRecord(savingsCampaign);
          }
        });
    };

    vm.loadSavingsCampaigns();

    function loadSavingsCampaigns() {
      SavingsCampaignFactory.getList({limit: 99}).then(function (result) {
        vm.savingsCampaigns = result;
      }, function () {
        return {};
      });
    }

    function showModalAddEditView(savingsCampaign) {
      UtilService.showModal(
        {
          templateUrl: 'mobile/views/savings_campaign.addedit.modal.tpl.html',
          controller: 'SavingsCampaignAddEditCtrl',
          controllerAs: 'savingsCampaignAddEditCtrl',
          resolve: {
            savingsCampaign: function () {
              return savingsCampaign;
            }
          }
        },
        function () {
          vm.loadSavingsCampaigns();
        }
      );
    }

    function deleteSavingsCampaignRecord(savingsCampaignRecord) {
      if (savingsCampaignRecord) {
        SavingsCampaignFactory.one(savingsCampaignRecord.id).remove().then(function () {
          vm.loadSavingsCampaigns();
          ToastrNotificationService.showNotification(
            'success',
            $filter('uconlyfirst')(
              $filter('sprintf')(
                $filter('translate')('app.item-removed'),
                $filter('translate')('app.savings_campaign')
              )
            ),
            $filter('uconlyfirst')(
              $filter('sprintf')(
                $filter('translate')('app.item-successfully-removed'),
                $filter('translate')('app.savings_campaign')
              )
            )
          );
        });
      }
    }
  }
}());
