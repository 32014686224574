(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.factory:CountryFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .factory('CountryFactory', CountryFactory);

  function CountryFactory(Restangular) {
    var vm = this;
    vm.initialized = false;
    vm.countries = [];
    vm.init = init;
    vm.init();

    function init() {
      // Get all Countries
      return Restangular
      .all('countries')
      .customGET('')
      .then(function (resultCountries) {
        vm.countries = resultCountries.results;
        vm.initialized = true;
      });
    }

    return {
      getCountries: function () {
        return new Promise(function (resolve) {
          if (vm.initialized) {
            resolve(vm.countries);
          } else {
            vm.init()
            .then(function () {
              resolve(vm.countries);
            });
          }
        });
      }
    };
  }
}());
