(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosPaymentSettingsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosPaymentSettingsCtrl', PosPaymentSettingsCtrl);

  function PosPaymentSettingsCtrl(
    $filter,
    $modal,
    $q,
    PaymentMethodsFactory,
    PaymentMethodSiteFactory,
    SettingsService,
    SiteFactory,
    ToastrNotificationService
  ) {
    var vm = this,
        addEditPaymentModalOpened = false,
        addEditPaymentModalInstance = null;
    vm.methodTypes = [];
    vm.methods = [];
    vm.site = null;
    vm.toBeDeleted = [];

    vm.loadPaymentMethods = loadPaymentMethods;
    vm.onDropDelete = onDropDelete;
    vm.onDropPersist = onDropPersist;
    vm.save = save;
    vm.showAddEditPaymentMethodModal = showAddEditPaymentMethodModal;
    vm.deletePaymentMethod = deletePaymentMethod;
    vm.enablePaymentMethodModifications = SettingsService.get('settings.enablePaymentMethodModifications', false);

    SiteFactory.manageable.get({
      limit: 99,
      sort: 'label,ASC'
    }).then(function (result) {
      vm.manageableSites = result.results;
    });

    function onDropDelete(index, element) {
      if (angular.isDefined(element.id)) {
        vm.toBeDeleted.push(element);
      }

      return element;
    }

    function onDropPersist(index, element) {
      vm.toBeDeleted = vm.toBeDeleted.filter(function (item) {
        return item.code !== element.code;
      });

      return element;
    }

    function loadPaymentMethods() {
      if (vm.site) {
        PaymentMethodSiteFactory.getList({
          'filter[]': ['site.id,' + vm.site.id],
          limit: 99
        }).then(function (result) {
          vm.methods = result;
          vm.methods.forEach(function (method) {
            method.code = method.paymentMethod.code;
          });

          return PaymentMethodsFactory.getList({
            limit: 99,
            'filter[]': [
              'weight,NOT NULL'
            ]
          }).then(function (methodTypes) {
            vm.methodTypes = [];
            methodTypes.forEach(function (method) {
              var methodObject = {
                code: method.code,
                paymentMethod: method
              };

              if ((vm.methods.filter(function (element) {
                return element.paymentMethod.code === method.code;
              })).length === 0) {
                vm.methodTypes.push(methodObject);
              }
            });
          });
        });
      } else {
        vm.methods = [];
        vm.methodTypes = [];
      }
    }

    function save() {
      var promises = [];
      vm.toBeDeleted.forEach(function (method) {
        promises.push(PaymentMethodSiteFactory.one(method.id).remove());
      });

      vm.methods.forEach(function (method, index) {
        if (angular.isDefined(method.id)) {
          promises.push(PaymentMethodSiteFactory.one(method.id).patch({
            weight: index
          }));
        } else {
          promises.push(PaymentMethodSiteFactory.post({
            site: vm.site.id,
            paymentMethod: method.paymentMethod.id,
            weight: index
          }));
        }
      });

      return $q.all(promises).then(function () {
        vm.toBeDeleted = [];
        vm.loadPaymentMethods();
        ToastrNotificationService.showTranslatedNotification('success', 'app.success', '');
      });
    }

    function showAddEditPaymentMethodModal(paymentMethod) {
      // Check if modal view is open
      if (addEditPaymentModalOpened) {
        return;
      }
      // Check the type of the sended Object and open modal view with template and controller and add objects to that controller
      addEditPaymentModalInstance = $modal.open({
        templateUrl: 'pos-settings/views/payment-method.modal.addedit.tpl.html',
        controller: 'PaymentMethodAddEditCtrl',
        controllerAs: 'paymentMethodAddEditCtrl',
        resolve: {
          paymentMethod: function () {
            return paymentMethod;
          }
        }
      });

      addEditPaymentModalInstance.result.then(function () {
        addEditPaymentModalOpened = false;
        vm.loadPaymentMethods();
      }, function () {
        addEditPaymentModalOpened = false;
      });

      addEditPaymentModalOpened = true;
    }

    function deletePaymentMethod(paymentMethod) {
      PaymentMethodsFactory.one(paymentMethod.id).remove().then(function () {
        vm.loadPaymentMethods();
        ToastrNotificationService.showNotification(
          'success',
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-removed'),
              $filter('translate')('app.payment-method')
              )
            ),
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-successfully-removed'),
              $filter('translate')('app.payment-method')
              )
            )
          );
      });
    }
  }
}());
