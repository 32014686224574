(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:promiseButton
   * @restrict AEC
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('promiseButton', promiseButton);

  function promiseButton($timeout) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        id: '@',
        callback: '&',
        options: '=?',
        defaultText: '@',
        submittingText: '@',
        successText: '@',
        errorText: '@'
      },
      controller: ['$scope', '$filter', function ($scope, $filter) {
        $scope.defaultText = $scope.defaultText || $filter('uconlyfirst')($filter('translate')('app.submit'));
        $scope.submittingText = $scope.submittingText || $filter('uconlyfirst')($filter('translate')('app.submitting'));
        $scope.successText = $scope.successText || $filter('uconlyfirst')($filter('translate')('app.completed'));
        $scope.errorText = $scope.errorText || $filter('uconlyfirst')($filter('translate')('app.error_occured'));

        $scope.options = $scope.options || {};
        $scope.options = {
          defaultClass: $scope.options.defaultClass || 'btn-primary',
          submittingClass: $scope.options.submittingClass || 'btn-primary',
          successClass: $scope.options.successClass || 'btn-primary',
          errorClass: $scope.options.errorClass || 'btn-danger',
          sizeClass: $scope.options.sizeClass || null,
          submittingIcon: $scope.options.submittingIcon || 'glyphicon glyphicon-refresh',
          successIcon: $scope.options.successIcon || 'glyphicon glyphicon-ok',
          errorIcon: $scope.options.errorIcon || 'glyphicon glyphicon-remove',
          formIsInvalid: $scope.options.formIsInvalid || '',
          animationCompleteTime: $scope.options.animationCompleteTime || '2000'
        };
      }],
      template:
          '<button id="{{id}}" ng-click="executeCallBack()" class="btn {{ buttonClass }} {{ options.sizeClass }} btn-ng-bs-animated">' +
          '<span class="icons">' +
            '<span class="{{ options.submittingIcon }} icon-spinner icon-submit hidden"></span>' +
            '<span class="{{ options.successIcon }} icon-result icon-success hidden"></span>' +
            '<span class="{{ options.errorIcon }} icon-result icon-error hidden"></span>' +
          '</span>' +
          '<span class="text">' +
            '<span ng-if="state == \'default\'" class="text">{{ defaultText }}</span>' +
            '<span ng-if="state == \'submit\'" class="text">{{ submittingText }}</span>' +
            '<span ng-if="state == \'success\'" class="text">{{ successText }}</span>' +
            '<span ng-if="state == \'error\'" class="text">{{ errorText }}</span>' +
          '</span>' +
        '</button>',
      link: function (scope, element) {
        var icons = {
          submitting: angular.element(element[0].querySelector('.icon-submit')),
          result: angular.element(element[0].querySelectorAll('.icon-result')),
          success: angular.element(element[0].querySelector('.icon-success')),
          error: angular.element(element[0].querySelector('.icon-error'))
        };

        function resetButton() {
          scope.result = null;
          scope.buttonClass = scope.options.defaultClass;
          scope.state = 'default';
          icons.result.addClass('hidden');
          element.removeClass('is-active').attr('disabled', false);
        }
        resetButton();

        scope.executeCallBack = function () {
          scope.state = 'submit';
          scope.buttonClass = scope.options.submittingClass;
          icons.submitting.removeClass('hidden');
          element.attr('disabled', true).addClass('is-active');
          scope.callback()
          .then(function () {
            scope.state = 'success';
            scope.buttonClass = scope.options.successClass;
            icons.submitting.addClass('hidden');
            icons.success.removeClass('hidden');
            $timeout(resetButton, scope.options.animationCompleteTime);
          }, function () {
            scope.state = 'error';
            scope.buttonClass = scope.options.errorClass;
            icons.submitting.addClass('hidden');
            icons.error.removeClass('hidden');
            $timeout(resetButton, scope.options.animationCompleteTime);
          });
        };
      }
    };
  }
}());
