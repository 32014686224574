(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('ContactTypeFactory', ContactTypeFactory);

  function ContactTypeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'contactType'});
    })
    .service('contacts/types');
  }
}());
