(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name pos.controller:BillItemCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('BillItemCtrl', BillItemCtrl);

  function BillItemCtrl(
    $modalInstance,
    $scope,
    billItem,
    CurrentUserContextFactory,
    DiscountFactory,
    LogService,
    ProductUtilService,
    PosSaleService,
    SettingsService,
    UtilService,
    $rootScope
  ) {
    var vm = this;
    vm.billItem = billItem;
    vm.saveBillItemProperties = saveBillItemProperties;
    vm.cancel = cancelModalInstance;
    vm.datePickerIsOpened = false;
    vm.descriptionRequired = false;
    vm.openDatePicker = openDatePicker;
    vm.descriptionIsRequired = descriptionIsRequired;
    vm.quantityOrDiscountChanged = quantityOrDiscountChanged;
    vm.loggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.discountTypes = [DiscountFactory.getPercentageDiscountType()];
    if (!vm.billItem.isGroupProduct && !vm.billItem.product.productSubscription) {
      vm.discountTypes.push(DiscountFactory.getAmountDiscountType());
    }
    vm.enableDiscounts = !SettingsService.get('pos.disableDiscounts', false);
    vm.enableInternalComments = SettingsService.get('pos.saleItem.enableInternalComments', false);
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.inputRequired = (UtilService.isNotEmpty(vm.billItem.product.inputRequired) && vm.billItem.product.inputRequired === true);
    vm.discountAddProductEnabled = SettingsService.get('pos.discountAddProductEnabled', false);
    vm.discountProductCodeVAT0 = SettingsService.get('pos.discountProductCodeVAT0', false);
    vm.discountProductCodeVAT6 = SettingsService.get('pos.discountProductCodeVAT6', false);
    vm.discountProductCodeVAT21 = SettingsService.get('pos.discountProductCodeVAT21', false);
    vm.discountProductCodes = [vm.discountProductCodeVAT0, vm.discountProductCodeVAT6, vm.discountProductCodeVAT21];
    vm.discountsCommentsRequiredDisabled = SettingsService.get('pos.discountsCommentsRequiredDisabled', false);
    vm.discountsInternalCommentsRequiredDisabled = SettingsService.get('pos.discountsInternalCommentsRequiredDisabled', true);
    vm.internalCommentsReqiured = billItem.discount && !vm.discountsInternalCommentsRequiredDisabled;
    vm.approvalFlow34 = (SettingsService.get('pos.session.approvalFlowVersion') === '3' || SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.zeroQuantityAllowedDisabled = SettingsService.get('pos.zeroQuantityAllowedDisabled', false);

    loadBillItemProperties();

    // we don't want to modify the billItem object while the user is choosing options,
    // so store the original billItem values in intermediary models which will be modified instead
    function loadBillItemProperties() {
      LogService.log('BillItemCtrl::loadBillItemProperties() -> Loading' + billItem, 'debug');
      vm.needsStartDate = vm.billItem.startDate !== null;
      vm.amount = vm.billItem.amount;
      vm.discount = vm.billItem.discount;
      if (vm.discount === true) {
        vm.discountType = vm.billItem.discountType;
        if (vm.discountType.code === 'AMOUNT') {
          vm.discountAmount = vm.billItem.discountAmount;
        } else {
          vm.discountPercentage = vm.billItem.discountPercentage;
        }
      }
      vm.descriptionRequired = vm.billItem.descriptionRequired;
      vm.price = vm.billItem.productPrice;
      vm.productDescription = vm.billItem.productDescription;
      if (vm.needsStartDate) {
        vm.startDate = vm.billItem.startDate;
        vm.minDate = new Date();
      }
    }

    function saveBillItemProperties() {
      var hadDiscount = angular.copy(vm.billItem.discountObject),
          billItemBackup = angular.copy(vm.billItem);

      PosSaleService.saleIsCompleted(vm.saleId, true).then(function (isCompleted) {
        if (!isCompleted) {
          vm.billItem.amount = vm.amount;
          vm.billItem.discount = vm.discount;
          if (vm.discount === true) {
            vm.billItem.needRemoveDiscount = null;
            vm.billItem.discountType = vm.discountType;
            if (vm.discountType.code === 'AMOUNT') {
              vm.billItem.discountAmount = vm.discountAmount;
              vm.billItem.discountPercentage = null;
            } else {
              vm.billItem.discountAmount = null;
              vm.billItem.discountPercentage = vm.discountPercentage;
            }
          } else {
            vm.billItem.needRemoveDiscount = (hadDiscount) ? hadDiscount.id : null;
            console.log(vm.billItem.needRemoveDiscount);
            vm.billItem.discountType = null;
            vm.billItem.discountPercentage = null;
            vm.billItem.discountAmount = null;
            vm.billItem.discountObject = null;
          }
          // do not update price if it cannot be changed (causes issues with digipolis 2 decimals only unit price)
          if (!vm.hidePriceInput()) {
            vm.billItem.productPrice = vm.price;
            vm.billItem.productExclPrice = ProductUtilService.calculateExclPrice(vm.billItem.productPrice, vm.billItem.vat).exclPrice;
            vm.billItem.vatPrice = ProductUtilService.calculateExclPrice(vm.billItem.productPrice, vm.billItem.vat).vatPrice;
          }
          vm.billItem.dirty = true;
          vm.billItem.productDescription = vm.productDescription;
          vm.billItem.descriptionRequired = vm.descriptionRequired;
          if (vm.needsStartDate) {
            vm.billItem.startDate = vm.startDate;
          }
          if (vm.billItem.product.productSubscription && vm.billItem.product.productSubscription.length > 0) {
            vm.billItem.subscriptionDate = ProductUtilService.calculateSubscriptionDate(vm.billItem);
            vm.billItem.productExclPrice = ProductUtilService.calculateProRataPrice(vm.billItem, vm.billItem.product.price.price);
            vm.billItem.vatPrice = ProductUtilService.calculateInclPrice(vm.billItem.productExclPrice, vm.billItem.vat).vatPrice;
            vm.billItem.productPrice = ProductUtilService.calculateInclPrice(vm.billItem.productExclPrice, vm.billItem.vat).inclPrice;
          }
          if (hadDiscount && !vm.billItem.discount) {
            // Remove discount entity
            $rootScope.$broadcast('deleteDiscountProductBroadcast', vm.billItem);
            $modalInstance.close(vm.billItem);
          } else {
            LogService.log('check if had a discount or not', 'debug');
            if (hadDiscount) {
              // Patch existing discount entity
              if (vm.discountAddProductEnabled) {
                $rootScope.$broadcast('updateDiscountProductBroadcast', vm.billItem);
                $modalInstance.close(vm.billItem);
              } else {
                DiscountFactory.one(hadDiscount.id).patch({price: vm.billItem.discountAmount, percentage: vm.billItem.discountPercentage}).then(function () {
                  $modalInstance.close(vm.billItem);
                }, function () {
                  // reset bill item on failure
                  angular.copy(billItemBackup, vm.billItem);
                  $modalInstance.dismiss('could not patch discount object');
                });
              }
            } else {
              LogService.log('didn\'t have a discount', 'debug');
              if (vm.billItem.discount) {
                if (vm.discountAddProductEnabled) {
                  // vm.billItem.discount = false;
                  vm.billItem.discountObject = true;
                  $rootScope.$broadcast('addDiscountProductToShoppingCartBroadcast', vm.billItem.saleItemId, vm.discountPercentage, vm.discountAmount, vm.billItem, vm.discountType);
                  $modalInstance.close(vm.billItem);
                } else {
                  // Post new discount entity
                  DiscountFactory.post({label: vm.billItem.saleItemId, price: vm.billItem.discountAmount, percentage: vm.billItem.discountPercentage, site: vm.loggedInUserSiteId}).then(function (returnedDiscountObject) {
                    vm.billItem.discountObject = returnedDiscountObject;
                    $modalInstance.close(vm.billItem);
                  }, function () {
                    // reset bill item on failure
                    angular.copy(billItemBackup, vm.billItem);
                    $modalInstance.dismiss('could not create discount object');
                  });
                }
              } else {
                $modalInstance.close(vm.billItem);
              }
            }
          }
        } else {
          LogService.log('emit clearShoppingCartEmit', 'debug');
          $scope.$emit('clearShoppingCartEmit', { message: 'Sale is completed' });
          $modalInstance.close(vm.billItem);
        }
      }, function () {
        LogService.log('something went wrong', 'debug');
        $modalInstance.close(vm.billItem);
      });
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function descriptionIsRequired() {
      vm.descriptionRequired = true;
    }

    function quantityOrDiscountChanged() {
      vm.internalCommentsReqiured = vm.discount && !vm.discountsInternalCommentsRequiredDisabled ? true : false;
      if (vm.amount < 0 || (vm.discount && !vm.discountsCommentsRequiredDisabled)) {
        vm.descriptionIsRequired();
      } else {
        vm.descriptionRequired = false;
      }
      // add extra property to billItem to recalculate discount product
      if (vm.billItem.amount !== vm.amount) {
        vm.billItem.quantityChanged = true;
      }
    }

    vm.hidePriceInput = function () {
      return (vm.billItem.product.price.static ||
        (vm.billItem.product.productSubscription && vm.billItem.product.productSubscription.length > 0) ||
        vm.billItem.isGroupProduct
      );
    };

    vm.isDiscountItem = function () {
      return (vm.discountAddProductEnabled && vm.discountProductCodes.indexOf(vm.billItem.product.code) !== -1);
    };

    vm.itemHasDiscount = function () {
      return (vm.discountAddProductEnabled && angular.isDefined(vm.billItem.discountSaleItemId) && vm.billItem.discountSaleItemId);
    };
  }
}());
