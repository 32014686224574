(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name memberCard.controller:MemberCardDeactivateCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('memberCard')
    .controller('MemberCardDeactivateCtrl', MemberCardDeactivateCtrl);

  function MemberCardDeactivateCtrl(MemberCardFactory, $modalInstance, memberCard, LogService) {
    var vm = this;
    vm.memberCard = memberCard;
    vm.cancel = cancelModalInstance;
    vm.deactivateMemberCard = deactivateMemberCard;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deactivateMemberCard(reloadOption) {
      var today = new Date();

      // Delete a specific memberCard
      MemberCardFactory.one(vm.memberCard.id).patch({
        expirationDate: today.toISOString().substr(0, 10)
      })
      .then(function () {
        // Close the modal view
        LogService.log('reloadOption:' + reloadOption, 'debug');
        $modalInstance.close('reloadMembercards');
      });
    }
  }
}());
