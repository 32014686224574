(function () {
  'use strict';

  /**
   * @ngdoc object
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('SavingsCampaignSubscriptionsRegistrationsCtrl', SavingsCampaignSubscriptionsRegistrationsCtrl);

  function SavingsCampaignSubscriptionsRegistrationsCtrl(SavingsCampaignSubscriptionsRegistrationFactory, customer, savingsCampaignSubscription, $modalInstance) {
    var vm = this;
    vm.customer = customer;
    vm.savingsCampaignSubscription = savingsCampaignSubscription;
    vm.loadRegistrations = loadRegistrations;
    vm.savingsCampignSubscriptionsRegistrations = [];
    vm.cancel = cancelModalInstance;

    vm.loadRegistrations();

    function loadRegistrations() {
      vm.savingsCampignSubscriptionsRegistrations = [];
      ///savings_campaign_subscription_registration/{savingsCampaignId}/customer/{customerId}
      SavingsCampaignSubscriptionsRegistrationFactory.one(vm.savingsCampaignSubscription.id).one('customer').one(vm.customer.id).get().then(function (savingsCampaignSubscriptionsRegistrations) {
        angular.forEach(savingsCampaignSubscriptionsRegistrations.results, function (savingsCampaignSubscriptionsRegistration) {
          vm.savingsCampignSubscriptionsRegistrations.push(savingsCampaignSubscriptionsRegistration);
        });
      });
    }
    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }
  }
}());
