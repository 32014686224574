(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('UserAddEditCtrl', UserAddEditCtrl);

  function UserAddEditCtrl($modalInstance,
                           isEdit,
                           UserFactory,
                           userId) {
    var vm = this;
    vm.title = 'app.add';
    vm.item = {};
    vm.isEdit = isEdit;
    vm.entities = [];

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;

    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;

    init();

    function init() {
      if (isEdit) {
        UserFactory.one(userId).get().then(function (result) {
          vm.item = result;
        });
        vm.title = 'app.edit';
      } else {
        vm.title = 'app.add';
      }
    }

    function save() {
      var object = {
        username: vm.item.username,
        email: vm.item.email,
        enabled: vm.item.enabled,
        locked: vm.item.locked,
        plainPassword: vm.item.plainPassword
      };
      return new Promise(function (resolve, reject) {
        if (isEdit === true) {
          UserFactory.one(vm.item.id).customPUT(object).then(function () {
            resolve();
            vm.close();
          }, function () {
            reject();
          });
        } else {
          UserFactory.post(object).then(function () {
            resolve();
            vm.close();
          }, function () {
            reject();
          });
        }
      });
    }

    function remove() {
      UserFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function isValid() {
      return angular.isDefined(vm.item.username) && vm.item.username !== '' &&
        angular.isDefined(vm.item.email) && vm.item.email !== '';
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
