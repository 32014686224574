(function () {
  'use strict';

  angular
    .module('calendar')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('calendar', {
        url: '/calendar',
        templateUrl: 'calendar/views/calendar.tpl.html'
      })
      .state('calendar.overview', {
        url: '/overview?site&date',
        views: {
          'main@calendar': {
            templateUrl: 'calendar/views/calendar-overview.tpl.html',
            controller: 'PublicCalendarCtrl',
            controllerAs: 'calendarCtrl'
          }
        }
      });
  }
}());
