(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:SalesCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('SalesCtrl', SalesCtrl);

  function SalesCtrl(
      CurrentUserContextFactory,
      PermissionsUtilService,
      SaleFactory,
      SaleStatusFactory,
      TicketFactory,
      ToastrNotificationService,
      _,
      $filter,
      FilterUtilService,
      LogService,
      Restangular,
      $q,
      $state,
      $scope,
      SettingsService,
      UtilService,
      $modal
    ) {
    var vm = this;
    // vm.saleStatusFilterOptions = [];
    vm.permissionsUtilService = PermissionsUtilService;
    vm.approveSales = approveSales;
    vm.emitSaleEditEvent = emitSaleEditEvent;
    vm.getPagesToLoad = getPagesToLoad;
    vm.findOnLoadPageIndex = findOnLoadPageIndex;
    vm.init = init;
    vm.loadSales = loadSales;
    vm.finalizeSale = finalizeSale;
    vm.generateInvoices = generateInvoices;
    vm.isPosSalesTab = isPosSalesTab;
    vm.notifyOrderEngine = notifyOrderEngine;
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.enableSalesLinking = SettingsService.get('pos.enableSalesLinking', false);
    vm.showPOSColumn = SettingsService.get('accountancy.showPOSColumn', false);
    vm.enableWebshop = SettingsService.get('products.enableProductPropertyVisibleInWebshop', false);
    vm.overviewAmountEnabled = SettingsService.get('sales.overviewAmountEnabled', false);
    vm.setInvoiceStatusFilter = setInvoiceStatusFilter;
    vm.getSaleStatusId = getSaleStatusId;
    vm.pagesToSelectOnLoad = {
      accountancy: [],
      pos: []
    };
    vm.addOnLoadPage = addOnLoadPage;
    vm.removeOnLoadPage = removeOnLoadPage;
    vm.findOnLoadPageIndex = findOnLoadPageIndex;
    vm.selectSalesOnCurrentPage = selectSalesOnCurrentPage;
    vm.adjustSelectionsOnSalesPage = adjustSelectionsOnSalesPage;
    vm.selectSalesOnAllPages = selectSalesOnAllPages;
    vm.saleStatusOptions = [];
    vm.showSaleTypeFilter = SettingsService.get('accountancy.showSaleTypeFilter', false);
    vm.isAccountancySalesTab = isAccountancySalesTab;
    vm.selectedSaleType = null;
    vm.saleTypeOptions = [
      {
        code: 'webshop',
        label: $filter('translate')('app.webshop_sales')
      },
      {
        code: 'kassa',
        label: $filter('translate')('app.kassa_sales')
      }
    ];
    vm.invoiceStatusOptions = [
      {
        code: 'invoiced',
        label: $filter('translate')('app.invoiced')
      },
      {
        code: 'notInvoiced',
        label: $filter('translate')('app.not_invoiced')
      }
    ];

    // define filters
    vm.filters = {
      customer: {
        val: '',
        field: 'customer.label',
        cond: 'LIKE'
      },
      clerk: {
        val: '',
        field: 'pointOfSaleSession.userContext.user.username',
        cond: 'LIKE'
      },
      reference: {
        val: '',
        field: 'reference',
        cond: 'LIKE'
      },
      externalOrderId: {
        val: '',
        field: 'externalOrderId',
        cond: 'LIKE'
      },
      amount: {
        val: '',
        field: 'payments.amount',
        cond: ''
      },
      contact: {
        val: '',
        field: 'customer.customerContact.contact.label',
        cond: 'LIKE'
      },
      dateFrom: {
        val: new Date(),
        field: 'createdAt',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.dateFrom.isOpen = true;
        }
      },
      dateTo: {
        val: '',
        field: 'createdAt',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.dateTo.isOpen = true;
        }
      },
      invoiceDateFrom: {
        val: '',
        field: 'invoices.date',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.invoiceDateFrom.isOpen = true;
        }
      },
      invoiceDateTo: {
        val: '',
        field: 'invoices.date',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.invoiceDateTo.isOpen = true;
        }
      },
      // status: {
      //   val: false,
      //   field: 'invoiceRequested',
      //   cond: ''
      // },
      saleStatus: {
        val: '',
        field: 'saleStatus.code',
        cond: ''
      },
      invoiced: {
        isParam: true,
        field: 'invoiced',
        val: ''
      },
      individualSale: {
        val: '',
        field: 'reference',
        cond: 'LIKE'
      }
    };

    // define pagination
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      maxPages: 10,
      itemsPerPage: 15
    };

    vm.sales = [];
    vm.selected = [];
    vm.isPosSale = isPosSale;
    vm.modalOpened = false;
    vm.showSaleModalView = showSaleModalView;
    vm.showWebshopSaleModalView = showWebshopSaleModalView;
    vm.resendWebshopSaleQrCode = resendWebshopSaleQrCode;
    vm.setInvoiceStatusFilter = setInvoiceStatusFilter;
    vm.printDuplicateTicket = printDuplicateTicket;
    vm.loadSingleSalesPage = loadSingleSalesPage;
    vm.init();
    vm.enableAllSite = false;
    vm.checkboxSalesAllSites = SettingsService.get('accountancy.checkboxSalesAllSites', false);
    vm.currentSiteId = CurrentUserContextFactory.getSiteId();

    function showSaleModalView(viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
          controller: 'SaleViewCtrl',
          controllerAs: 'saleViewCtrl',
          resolve: {
            sale: function () {
              return viewObject;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler
      );
    }

    function showWebshopSaleModalView(viewObject) {
      return SaleFactory.validateWebshopSale(viewObject.reference).then(function (sale) {
        var webshopSale = sale;
        UtilService.showModal(
          {
            templateUrl: 'accountancy/views/accountancy.webshop_sale.view.modal.tpl.html',
            controller: ['$modalInstance', function ($modalInstance) {
              this.webshopSale = webshopSale;

              this.showCustomerPosModal = function () {
                if (webshopSale === null) {
                  return;
                }

                UtilService.showModal({
                  templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
                  controller: 'HwProxyMemberCardDataCtrl',
                  controllerAs: 'HwProxyMemberCardDataCtrl',
                  size: 'lg',
                  resolve: {
                    memberCardData: function () {
                      return null;
                    },
                    customer: function () {
                      return webshopSale.customer;
                    },
                    customerContact: function () {
                      return webshopSale.customerContact;
                    }
                  }
                }, null, null, true);
              };

              this.cancel = function () {
                $modalInstance.dismiss('cancel');
              };
            }],
            controllerAs: 'webshopSaleViewCtrl'
          },
          modalResultHandler,
          modalFailureHandler
        );
      });
    }

    function modalResultHandler() {
      vm.reloadSales();
    }

    function modalFailureHandler() {
      vm.reloadSales();
    }

    function resendWebshopSaleQrCode(sale) {
      return SaleFactory.resendWebshopSaleQrCode(sale.id);
    }

    function setInvoiceStatusFilter() {
      switch (vm.selectedInvoiceStatusFilter) {
        case '':
        case null:
          vm.filters.invoiced.val = '';
          break;
        case 'invoiced':
          vm.filters.invoiced.val = 'true';
          break;
        case 'notInvoiced':
          vm.filters.invoiced.val = 'false';
          break;
        default:
          break;
      }
      vm.reloadSales();
    }

    function loadSingleSalesPage(page) {
      var loadingModal, queryParams = {
            context: CurrentUserContextFactory.getUserContextId(),
            offset: (page - 1) * vm.pagination.itemsPerPage,
            limit: vm.pagination.itemsPerPage,
            sort: 'updatedAt,DESC,id,ASC'
          },
          result;

      loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });
      return new Promise(function (resolve) {
        queryParams = angular.merge(queryParams, FilterUtilService.createQueryParamsFromFilters(vm.filters));
        // retrieve only accountancy or pos sales depending on which state we are currently in
        if ($state.is('accountancy.list.accountancy')) {
          queryParams['filter[]'] = [];
          queryParams['filter[]'].push('pointOfSaleSession.id,NULL');
          // if (vm.showSaleTypeFilter && vm.selectedSaleType) {
          //   condition = vm.selectedSaleType === 'webshop' ? 'saleType,LIKE WEBSHOP' : 'saleType,NULL';
          //   queryParams['filter[]'].push(condition);
          // }
        }
        if ($state.is('accountancy.list.pos')) {
          queryParams['filter[]'] = [];

          if (vm.showSaleTypeFilter && vm.selectedSaleType) {
            if (vm.selectedSaleType === 'webshop') {
              queryParams['filter[]'].push('pointOfSaleSession.id,NULL');
              queryParams['filter[]'].push('saleType,WEBSHOP');
            } else {
              queryParams['filter[]'].push('pointOfSaleSession.id,NOT NULL');
              queryParams['filter[]'].push('saleType,NULL');
            }
          } else {
            queryParams['filter[]'].push('pointOfSaleSession.id,NOT NULL');
          }
          if (vm.enableAllSite !== true && vm.permissionsUtilService.userHasPermission('manage sale')) {
            queryParams['filter[]'].push('site.id,' + CurrentUserContextFactory.getSiteId());
          } else if (vm.enableAllSite === true && vm.permissionsUtilService.userHasPermission('manage sale')) {
            queryParams.displayAllSalesForCurrentUserByHisSites = true;
          }
        }

        result = [];
        SaleFactory.getList(queryParams).then(function (data) {
          // attach check model to sales which are completed
          angular.forEach(data, function (sale) {
            sale.linkedSales = [];
            if (sale.linkedWithSales.length > 0) {
              sale.linkedSales = sale.linkedWithSales;
            }
            angular.forEach(sale.linkedToSales, function (s) {
              sale.linkedSales.push(s);
            });
            sale.invoiced = sale.hasOwnProperty('invoices') && sale.invoices.length > 0;
            sale.invoiceStartDate = (sale.hasOwnProperty('invoices') && sale.invoices.length > 0) ? sale.invoices[0].date : null;
            if (sale.saleStatus.code === 'COMPLETED' && !sale.invoiced) {
              LogService.log('Attaching checked model to sale ' + sale, 'debug');
              sale.selected = false;
            }
            if ((SettingsService.get('pos.session.approvalFlowVersion') === '3') ||
              (SettingsService.get('pos.session.approvalFlowVersion') === '4' &&
              !sale.pointOfSaleSession.pointOfSaleInstance.approvalFlowVersion4) &&
              (!sale.isApproved && !sale.isRequestedToBeNotified)) {
              sale.status34 = true;
            } else {
              sale.status34 = false;
            }
            // amount of payments for sale
            sale.totalPaymentsAmount = null;
            angular.forEach(sale.payments, function (payment) {
              sale.totalPaymentsAmount += payment.amount;
            });

            result.push(sale);
          });
          vm.sales[page] = result;
          vm.pagination.totalItems = data.count;
          if (vm.findOnLoadPageIndex(page) > -1) {
            vm.adjustSelectionsOnSalesPage(page, 'all');
          }
          resolve();
          loadingModal.close();
        });
      });
    }

    function loadSales(reload, page) {
      // var result, queryParams, condition = '';
      if (angular.isUndefined(reload)) {
        reload = false;
      }
      if (angular.isUndefined(page)) {
        page = vm.pagination.currentPage;
      }

      // is page empty or items should be updated
      if (angular.isUndefined(vm.sales[vm.pagination.currentPage]) || (reload === true)) {
        vm.loadSingleSalesPage(vm.pagination.currentPage);
      } else {
        vm.loadSingleSalesPage(vm.pagination.currentPage);
      }

      // Pagination: redirect to page 1 after an update
      if (page > 1 && reload === true) {
        vm.pagination.currentPage = 1;
        vm.loadSingleSalesPage(vm.pagination.currentPage);
      }
    }

    function getSaleStatusId(code) {
      var i, result = null;
      for (i = 0; i < vm.saleStatusOptions.length; ++i) {
        if (vm.saleStatusOptions[i].code === code) {
          result = vm.saleStatusOptions[i].id;
          break;
        }
      }
      return result;
    }

    function init() {
      SaleStatusFactory.getList({limit: 99}).then(function (result) {
        angular.forEach(result, function (ss) {
          if ((ss.code !== 'CANCELLED' && ss.code !== 'PAYMENT_REQUESTED' && ss.code !== 'PAYMENT_CANCELLED') ||
            (ss.code === 'PAYMENT_REQUESTED' && SettingsService.get('pos.saleStatusPaymentRequestedEnabled', false)) ||
            (ss.code === 'PAYMENT_CANCELLED' && SettingsService.get('accountancy.saleStatusPaymentCancelledVisible', false))
          ) {
            vm.saleStatusOptions.push(ss);
          }
        });
      });

      vm.loadSales();
    }

    function loadMarkedPages() {
      var pagesToLoad = vm.getPagesToLoad();

      LogService.log('Preparing to load marked pages', 'debug');
      return UtilService.promiseLoop(pagesToLoad,
        function (pageNumber) {
          return new Promise(function (resolve) {
            if (UtilService.isEmpty(vm.sales[pageNumber])) {
              vm.loadSingleSalesPage(pageNumber)
                .then(function () {
                  resolve();
                });
            } else {
              // page already loaded, resolve immediately
              resolve();
            }
          });
        }
      );
    }

    function generateInvoices() {
      var data = {};
      vm.selected = [];

      return loadMarkedPages()
        .then(function () {
          LogService.log('Marked pages loaded', 'debug');
          _.each(vm.sales, function (page) {
            _.each(page, function (item) {
              if (item.hasOwnProperty('selected') && item.selected) {
                vm.selected.push(item.id);
              }
            });
          });
          data.includedSales = vm.selected;

          // check that there are no pages left to load
          if (UtilService.isNotEmpty(vm.getPagesToLoad())) {
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'error',
              'app.error',
              '',
              'accountancy.sale_to_invoice_generator_some_sales_not_loaded'
            );
            return Promise.reject();
          } else if (vm.selected.length > 0) {
            return Restangular.all('services/sales_to_invoices').customPOST(data).then(function () {
              $state.go('accountancy.invoices', {reload: true});
            });
          }
        });
    }

    function approveSales() {
      var ids = [],
          deferred = $q.defer();
      vm.selected = [];

      loadMarkedPages()
        .then(function () {
          LogService.log('Marked pages loaded', 'debug');
          _.each(vm.sales, function (page) {
            _.each(page, function (item) {
              if (item.hasOwnProperty('selected')) {
                vm.selected.push(item.id);
              }
              if (item.selected && !item.isApproved && !item.isRequestedToBeNotified && !item.isNotified) {
                ids.push(item.id);
              }
            });
          });

          // check that there are no pages left to load
          if (UtilService.isNotEmpty(vm.getPagesToLoad())) {
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'error',
              'app.error',
              '',
              'accountancy.sale_to_invoice_generator_some_sales_not_loaded'
            );
            deferred.reject();
          } else if (ids.length > 0) {
            Restangular.all('sales/batchApprove').customPOST({sales: ids}).then(function () {
              ToastrNotificationService.showTranslatedAndFormattedNotification(
                'success',
                'app.success',
                '',
                'accountancy.sales_successfully_approved'
              );
              deferred.resolve();
              vm.reloadSales();
            });
          } else {
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'warning',
              'app.warning',
              '',
              'accountancy.no_not_approved_sales_selected'
            );
            deferred.reject();
          }
        });

      return deferred.promise;
    }

    function notifyOrderEngine() {
      var ids = [],
          deferred = $q.defer();
      vm.selected = [];

      loadMarkedPages()
        .then(function () {
          LogService.log('Marked pages loaded', 'debug');
          _.each(vm.sales, function (page) {
            _.each(page, function (item) {
              if (item.hasOwnProperty('selected')) {
                vm.selected.push(item.id);
              }
              if (item.selected && item.isApproved && !item.isRequestedToBeNotified && !item.isNotified) {
                ids.push(item.id);
              }
            });
          });

          // check that there are no pages left to load
          if (UtilService.isNotEmpty(vm.getPagesToLoad())) {
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'error',
              'app.error',
              '',
              'accountancy.sale_to_invoice_generator_some_sales_not_loaded'
            );
            deferred.reject();
          } else if (ids.length > 0) {
            Restangular.all('sales/batchRequestNotification').customPOST({sales: ids}).then(function () {
              ToastrNotificationService.showTranslatedAndFormattedNotification(
                'success',
                'app.success',
                '',
                'accountancy.sale_order_engine_notification_successfully_requested'
              );
              deferred.resolve();
              vm.reloadSales();
            });
          } else {
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'warning',
              'app.warning',
              '',
              'accountancy.no_approved_sales_selected'
            );
            deferred.reject();
          }
        });

      return deferred.promise;
    }

    function isPosSale(sale) {
      return sale.hasOwnProperty('pointOfSaleSession');
    }

    function getPagesToLoad() {
      if ($state.is('accountancy.list.accountancy')) {
        return vm.pagesToSelectOnLoad.accountancy;
      }
      if ($state.is('accountancy.list.pos')) {
        return vm.pagesToSelectOnLoad.pos;
      }
      return null;
    }

    function findOnLoadPageIndex(pageNumber) {
      var pageIndex = -1;
      if ($state.is('accountancy.list.accountancy')) {
        pageIndex = vm.pagesToSelectOnLoad.accountancy.indexOf(pageNumber);
      }
      if ($state.is('accountancy.list.pos')) {
        pageIndex = vm.pagesToSelectOnLoad.pos.indexOf(pageNumber);
      }
      return pageIndex;
    }

    function addOnLoadPage(pageNumber) {
      if ($state.is('accountancy.list.accountancy')) {
        vm.pagesToSelectOnLoad.accountancy.push(pageNumber);
      }
      if ($state.is('accountancy.list.pos')) {
        vm.pagesToSelectOnLoad.pos.push(pageNumber);
      }
    }

    function removeOnLoadPage(pageIndex) {
      if ($state.is('accountancy.list.accountancy')) {
        vm.pagesToSelectOnLoad.accountancy.splice(pageIndex, 1);
      }
      if ($state.is('accountancy.list.pos')) {
        vm.pagesToSelectOnLoad.pos.splice(pageIndex, 1);
      }
    }

    function adjustSelectionsOnSalesPage(pageNumber, selectType) {
      var pageIndex = findOnLoadPageIndex(pageNumber);

      // if the page is already loaded, edit the selections on the items
      if (UtilService.isNotEmpty(vm.sales[pageNumber])) {
        _.each(vm.sales[pageNumber], function (item) {
          // we can only check completed sales
          if (item.hasOwnProperty('selected')) {
            switch (selectType) {
              case 'all':
                item.selected = true;
                break;
              case 'none':
                item.selected = false;
                break;
              case 'invert':
                item.selected = !item.selected;
                break;
              default:
                break;
            }
          }
        });

        // get rid of the page number since we have the actual page loaded
        if (pageIndex !== -1) {
          vm.removeOnLoadPage(pageIndex);
        }
      } else {
        // if the page has not been loaded yet, don't load it (for performance reasons)
        // but remember/forget the page number accordingly
        switch (selectType) {
          case 'all':
            if (pageIndex === -1) {
              vm.addOnLoadPage(pageNumber);
            }
            break;
          case 'none':
            if (pageIndex !== -1) {
              vm.removeOnLoadPage(pageIndex);
            }
            break;
          case 'invert':
            if (pageIndex === -1) {
              vm.addOnLoadPage(pageIndex);
            } else {
              vm.removeOnLoadPage(pageIndex);
            }
            break;
          default:
            break;
        }
      }
    }

    function selectSalesOnAllPages(selectType) {
      var pagesLeft, currentPage = 1;

      // count the number of pages we should have and mark them for selection
      if (UtilService.isNotEmpty(vm.pagination.totalItems)) {
        pagesLeft = vm.pagination.totalItems;
        while (pagesLeft > 0) {
          vm.adjustSelectionsOnSalesPage(currentPage, selectType);
          pagesLeft -= vm.pagination.itemsPerPage;
          currentPage += 1;
        }
      }
    }

    function selectSalesOnCurrentPage(selectType) {
      vm.adjustSelectionsOnSalesPage(vm.pagination.currentPage, selectType);
    }

    vm.reloadSales = function () {
      vm.loadSales(true);
    };

    function emitSaleEditEvent(sale) {
      $scope.$emit('emitSaleEditEvent', sale);
    }

    function finalizeSale(sale) {
      var patchObject = {
        saleStatus: vm.getSaleStatusId('COMPLETED')
      };
      SaleFactory.one(sale.id).patch(patchObject).then(function () {
        vm.reloadSales();
      });
    }

    function isPosSalesTab() {
      return $state.is('accountancy.list.pos');
    }

    function isAccountancySalesTab() {
      return $state.is('accountancy.list.accountancy');
    }

    function printDuplicateTicket(sale) {
      return TicketFactory.printTicket(sale, true);
    }

    $scope.$on('broadcastSalesReloadEvent', function (event) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        vm.reloadSales();
      }
    });
  }
}());
