(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PaymentMethodAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PaymentMethodAddEditCtrl', PaymentMethodAddEditCtrl);

  function PaymentMethodAddEditCtrl(
      $filter,
      $modalInstance,
      paymentMethod,
      PaymentMethodsFactory,
      ToastrNotificationService
    ) {
    var vm = this;
    vm.paymentMethod = paymentMethod;
    vm.paymentMethodLabel = '';
    vm.paymentMethodFrLabel = '';
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.savePaymentMethod = savePaymentMethod;
    vm.showSavedNotification = showSavedNotification;

    if (vm.isEdit()) {
      vm.paymentMethodLabel = vm.paymentMethod.label;
      vm.paymentMethodFrLabel = vm.paymentMethod.frLabel;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function savePaymentMethod() {
      var paymentMethodObject = {
        label: vm.paymentMethodLabel,
        frLabel: vm.paymentMethodFrLabel,
        code: angular.uppercase(vm.paymentMethodLabel).replace(' ', '_')
      };

      if (vm.isEdit()) {
        PaymentMethodsFactory.one(vm.paymentMethod.id).patch(paymentMethodObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      } else {
        PaymentMethodsFactory.post(paymentMethodObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      }
    }

    function isEdit() {
      return angular.isDefined(vm.paymentMethod) && vm.paymentMethod !== null;
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.payment-method')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.payment-method')
            )
          )
        );
    }
  }
}());
