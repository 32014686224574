(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:AccountancyPeriodsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('AccountancyPeriodsCtrl', AccountancyPeriodsCtrl);

  function AccountancyPeriodsCtrl(AccountancyPeriodFactory,
                                  ToastrNotificationService,
                                  CurrentUserContextFactory,
                                  UtilService) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.periods = [];
    vm.modalInstance = null;
    vm.modalOpened = false;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      maxPages: 10,
      itemsPerPage: 12
    };
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.loadPeriods = loadPeriods;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.showPeriodAddEditModalView = showPeriodAddEditModalView;
    vm.showModalDeleteView = showModalDeleteView;
    /* ----- END FUNCTIONS ----- */

    /* ----- PERIOD MODAL ----- */
    function showPeriodAddEditModalView(viewObject) {
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.accountancy-periods.add.edit.modal.tpl.html',
        controller: 'AccountancyPeriodsAddEditCtrl',
        controllerAs: 'accountancyPeriodsAddEditCtrl',
        resolve: {
          accountancyPeriod: function () {
            return angular.isDefined(viewObject) ? viewObject : null;
          },
          openedAsEdit: function () {
            return angular.isDefined(viewObject);
          }
        }
      },
      modalResultHandler,
      modalFailureHandler);
    }

    function showModalDeleteView(period) {
      // Check if modal is already open
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.accountancy-periods.delete.modal.tpl.html',
        controller: 'AccountancyPeriodsDeleteCtrl',
        controllerAs: 'accountancyPeriodsDeleteCtrl',
        resolve: {
          period: function () {
            return period;
          }
        }
      },
      modalResultHandler,
      modalFailureHandler
      );
    }

    function modalResultHandler() {
      vm.reloadItems();
    }

    function modalFailureHandler() {
    }

    function returnOfModalInstance() {
      // Check if dismiss or close action of modal view was called
      vm.modalInstance.result.then(function () {
        vm.modalOpened = false;
        vm.reloadItems();
      }, function () {
        vm.modalOpened = false;
      });
    }

    /* ----- END PERIOD MODAL ----- */

    /* ----- OTHER ----- */
    function loadPeriods(reload) {
      var queryParams;

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.periods[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          context: CurrentUserContextFactory.getUserContextId(),
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage,
          sort: 'createdAt,DESC'
        };

        AccountancyPeriodFactory.getList(queryParams).then(function (data) {
          angular.forEach(data, function (item) {
            AccountancyPeriodFactory.convertDatesToObject(item);
          });

          vm.periods[vm.pagination.currentPage] = data;
          vm.pagination.totalItems = data.count;
        });
      }
    }

    vm.reloadItems = function () {
      vm.loadPeriods(true);
    };

    vm.openPeriod = function (period) {
      vm.modifyPeriod(period, {isOpened: true});
    };

    vm.closePeriod = function (period) {
      vm.modifyPeriod(period, {isOpened: false});
    };

    vm.modifyPeriod = function (period, modifiedFields) {
      return AccountancyPeriodFactory.one(period.id).patch(modifiedFields).then(function () {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'success',
          'app.item-saved',
          'app.accountancy-period',
          'app.item-successfully-saved',
          'app.accountancy-period'
        );

        vm.reloadItems();
      });
    };
    /* ----- END OTHER ----- */
  }
}());
