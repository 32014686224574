(function () {
  'use strict';
  /* global document */

  /**
   * @ngdoc object
   * @name swimcourse.controller:CalendarCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('CalendarCtrl', CalendarCtrl);

  function CalendarCtrl(
      $state,
      $stateParams,
      $q,
      $scope,
      $timeout,
      $cookies,
      LevelService,
      UtilService,
      SessionService,
      SwimCourseManagementFactory,
      moment
  ) {
    var vm = this;

    vm.levels = [];
    vm.dates = [];

    if (angular.isDefined($stateParams.year)) {
      vm.year = parseInt($stateParams.year, 10);
    } else if (angular.isDefined($cookies.get('opensportSwimcourseCalendarYear'))) {
      vm.year = parseInt($cookies.get('opensportSwimcourseCalendarYear'), 10);
    } else {
      vm.year = parseInt(moment().format('YYYY'), 10);
    }

    if (angular.isDefined($stateParams.week)) {
      vm.week = parseInt($stateParams.week, 10);
    } else if (angular.isDefined($cookies.get('opensportSwimcourseCalendarWeek'))) {
      vm.week = parseInt($cookies.get('opensportSwimcourseCalendarWeek'), 10);
    } else {
      vm.week = parseInt(moment().format('W'), 10);
    }

    vm.weeks = [];
    vm.loading = false;

    vm.loadCalendar = loadCalendar;
    vm.getWaitingListAmount = getWaitingListAmount;
    vm.loadYear = loadYear;
    vm.redirect = redirect;
    vm.getClass = getClass;
    vm.setCookies = setCookies;

    loadYear();

    function loadYear() {
      vm.weeks = UtilService.getWeeksForYear(vm.year);
    }

    function getWaitingListAmount(setId, levelId, group) {
      group.waitingList = [];
      SwimCourseManagementFactory.getSwimCourseWaitingList(
        setId,
        levelId
      ).then(function (subscriptions) {
        group.waitingList = subscriptions;
      });
    }

    function loadCalendar() {
      checkDateForDisplayWeek();
      if (angular.isUndefined($stateParams.week) && angular.isUndefined($stateParams.year)) {
        $state.go('swimcourse.calendar', {year: vm.year, week: vm.week});
      } else {
        return new Promise(function (resolve) {
          var promises = {
            sessions: SessionService.getOverview(vm.year, vm.week),
            levels: LevelService.getAll()
          };

          vm.loading = true;
          $q.all(promises).then(function (values) {
            vm.levels = values.levels;
            if (angular.isDefined(vm.weeks[vm.week - 1])) {
              vm.dates = angular.copy(vm.weeks[vm.week - 1].days);
            }

            angular.forEach(vm.levels, function (level) {
              // copy empty dates object
              level.dates = angular.copy(vm.dates);
              angular.forEach(level.dates, function (date) {
                // get sessions for date for this level
                date.sessions = {};

                angular.forEach(values.sessions.results, function (session) {
                  //LogService.log(values.sessions.results.length, 'debug');
                  angular.forEach(session.swimCourseSeries.swimCourseSeriesGroups, function (group) {
                    var key = moment(session.startsAt).format('HH:mm');
                    group.swimCourseSeriesId = session.swimCourseSeries.id;
                    if (moment(session.startsAt).format('YYYY-MM-DD') === moment(date.date).format('YYYY-MM-DD') && group.swimCourseGroup.swimCourseLevel.id === level.id) {
                      vm.getWaitingListAmount(session.swimCourseSeries.swimCourseSet.id, level.id, group);
                      if (angular.isUndefined(date.sessions[key])) {
                        date.sessions[key] = {
                          session: session,
                          groups: []
                        };
                      }
                      date.sessions[key].groups.push(group);
                    }
                  });
                });
              });
            });
            vm.loading = false;
            resolve();
          });
        });
      }
    }

    function redirect(session, group) {
      $state.go('swimcourse.groups', {setId: session.swimCourseSeries.swimCourseSet.id, serId: group.swimCourseSeriesId, groId: group.id, year: vm.year, week: vm.week});
    }

    function getClass(group) {
      var c = null;

      if (angular.isDefined(group.tags) && angular.isDefined(group.tags[0])) {
        c = group.tags[0].color;
      }

      // fallback to swim course group color
      if (c === null && angular.isDefined(group.swimCourseGroup) && angular.isDefined(group.swimCourseGroup.tags) && angular.isDefined(group.swimCourseGroup.tags[0])) {
        c = group.swimCourseGroup.tags[0].color;
      }

      if (c === null) {
        c = 'btn-default';
      }

      return c;
    }

    function setCookies() {
      $cookies.put('opensportSwimcourseCalendarYear', vm.year);
      $cookies.put('opensportSwimcourseCalendarWeek', vm.week);
    }

    function checkDateForDisplayWeek() {
      var today = new Date(), dd, mm, yyyy;
      dd = today.getDate();
      mm = today.getMonth() + 1;
      yyyy = today.getFullYear();
      if (vm.weeks[0].label.substr(0, 10) !== '01/01/' + yyyy &&
        parseInt(vm.weeks[0].label.substr(1, 1), 10) > dd &&
        parseInt(vm.weeks[0].label.substr(3, 2), 10) === mm &&
        parseInt(vm.weeks[0].label.substr(6, 4), 10) === yyyy) {
        vm.year -= 1;
      }
    }

    $scope.$on('reloadSwimcourseCalendarOnTabSelection', function (event) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        $timeout(function () {
          /* eslint-disable */
          document.getElementById('swimcourseReloadButton ').click();
          /* eslint-enable */
        });
      }
    });
  }
}());
