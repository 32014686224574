(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name subscriptionContract.factory:SubscriptionContractFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('subscriptionContract')
    .factory('SubscriptionContractFactory', SubscriptionContractFactory);

  function SubscriptionContractFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'subscriptionContract'});
    })
    .service('subscription_contracts');
  }
}());
