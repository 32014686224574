(function () {
  'use strict';
  /* @ngInject */

  angular.module('utils')
  .service('UiHelperService', UiHelperService);

  function UiHelperService($modal) {
    var self = this;

    self.openDialog = function (options) {
      var defaults = {
        size: 'lg'
      };

      if (angular.isUndefined(options) || options === null) {
        options = {};
      }

      options = angular.extend({}, defaults, options);
      return $modal.open(options).result;
    };
  }
}());
