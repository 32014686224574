(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name auth.controller:AuthLoginCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('auth')
    .controller('AuthLoginCtrl', AuthLoginCtrl);

  function AuthLoginCtrl($location,
                         $modalStack,
                         $rootScope,
                         $state,
                         $timeout,
                         CurrentUserContextFactory,
                         OAuth,
                         OAUTH_CONFIG,
                         OAuthToken,
                         $modal,
                         LogService) {
    var vm = this,
        params = $location.search();

    vm.user = {};
    vm.requiredParams = ['access_token', 'refresh_token', 'token_type'];
    vm.loginFormIsVisible = false;
    vm.back = back;
    vm.passwordForgot = passwordForgot;
    vm.returnOfPasswordModalInstance = returnOfPasswordModalInstance;
    vm.passwordModalIsOpen = false;

    if (angular.isUndefined(OAUTH_CONFIG.externalAuthEnabled) || OAUTH_CONFIG.externalAuthEnabled === false) {
      vm.externalBackendUrl = OAUTH_CONFIG.baseUrl;
      vm.externalAuthEnabled = false;
    } else {
      vm.externalBackendUrl = OAUTH_CONFIG.baseUrl + OAUTH_CONFIG.externalAuthPath;
      vm.externalAuthEnabled = OAUTH_CONFIG.externalAuthEnabled;
    }

    vm.isRequestValid = function (token) {
      var i;
      for (i = 0; i < vm.requiredParams.length; ++i) {
        if (angular.isUndefined(token[vm.requiredParams[i]])) {
          return false;
        }
      }

      return true;
    };

    // prevent any open modals
    $modalStack.dismissAll('skip');
    // login user in, if the page is called with tokens in url
    if (vm.isRequestValid(params)) {
      OAuthToken.setToken(params);

      CurrentUserContextFactory.removeUserContextCookies();
      // auth successful
      if (angular.isDefined($rootScope.returnToState) && $rootScope.returnToState.length > 0 && $rootScope.returnToState !== 'logout') {
        $state.go($rootScope.returnToState);
      } else {
        // timeout prevents to render new state in same time as login state
        $timeout(function () {
          // no prev state defined - go to dashboard page
          $state.go('dashboard');
        }, 0);
      }
    }

    vm.oauthLogin = function (loginForm) {
      // angular.element('button[type=submit]').button('loading');
      angular.element('button[type=submit]', loginForm).prop('disabled', true).attr('data-action-type', 'loading');

      // attempt to retrieve an access token
      OAuth.getAccessToken(vm.user)
        .then(function () {
          // strip context cookies (view load will trigger a check and in turn a context selection modal)
          CurrentUserContextFactory.removeUserContextCookies();

          // update username and unset site (loaded from cookies before, might be other user)
          CurrentUserContextFactory.setUserContextCookies(vm.user.username);
          $rootScope.username = vm.user.username;
          $rootScope.site = undefined;

          // auth successful
          if (angular.isDefined($rootScope.returnToState) && $rootScope.returnToState.length > 0 && $rootScope.returnToState !== 'logout') {
            $state.go($rootScope.returnToState);
          } else {
            // no prev state defined - go to dashboard page
            $state.go('dashboard');
          }
        }, function () {
          // auth failed
          angular.element('button[type=submit]', loginForm).prop('disabled', false).attr('data-action-type', '');

          loginForm.$setPristine();
          loginForm.$setUntouched();
          loginForm.failedLogin = true;
          vm.user.password = '';
          // angular.element('button[type=submit]').button('reset');
        });
    };

    function back() {
      $state.go('login');
    }

    function passwordForgot() {
      if (vm.passwordModalIsOpen) {
        return;
      }
      vm.passwordModalInstance = $modal.open({
        templateUrl: 'user/views/user_password_forgot.modal.tpl.html',
        controller: 'UserPasswordForgotCtrl',
        controllerAs: 'userPasswordForgotCtrl'
      });
      vm.passwordModalIsOpen = true;
      vm.returnOfPasswordModalInstance();
    }

    function returnOfPasswordModalInstance() {
      vm.passwordModalInstance.result.then(function (returnValue) {
        LogService.log('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      }, function (returnValue) {
        LogService.log('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      });
    }
  }
}());
