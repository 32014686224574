(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name hwproxy.directive:hwProxyConnection
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
     <example module="hwproxy">
       <file name="index.html">
        <hw-proxy-connection></hw-proxy-connection>
       </file>
     </example>
   *
   */
  /* @ngInject */
  angular
    .module('navigation')
    .directive('hwProxyConnection', hwProxyConnection);

  function hwProxyConnection() {
    return {
      restrict: 'E',
      rootscope: {
        posInstanceActive: '='
      },
      templateUrl: 'hwproxy/views/hwproxy.connection.tpl.html',
      replace: false,
      controllerAs: 'hwProxyConnection',
      controller: [
        '_',
        '$cookies',
        'OAuth',
        '$rootScope',
        '$timeout',
        '$state',
        'LogService',
        '$modal',
        '$scope',
        'hwproxy',
        'PosGroupFactory',
        'CurrentPosInstanceFactory',
        function (_, $cookies, OAuth, $rootScope, $timeout, $state, LogService, $modal, $scope, hwproxy, PosGroupFactory, CurrentPosInstanceFactory) {
          var vm = this;
          vm.posInstance = {};
          vm.posGroup = {};
          $scope.hwproxy = hwproxy;

          vm.checkIfInstanceSelected = checkIfInstanceSelected;
          vm.attemptHwProxyConnection = attemptHwProxyConnection;
          vm.disconnectHwProxy = disconnectHwProxy;
          vm.attemptHwProxyConnection();
          $scope.vm = vm;

          $scope.$on('attemptHwProxyConnection', function () {
            vm.attemptHwProxyConnection();
          });

          $rootScope.$on('$viewContentLoaded', function () {
            if (!$state.is('logout') && OAuth.isAuthenticated()) {
              checkIfInstanceSelected();
            }
          });

          function attemptHwProxyConnection() {
            var ids;
            CurrentPosInstanceFactory.validatePosCookies()
            .then(function (valid) {
              if (valid && CurrentPosInstanceFactory.isInstanceSelected()) {
                ids = CurrentPosInstanceFactory.getInstanceCookies();

                PosGroupFactory.one(ids.posGroupId).get().then(function (posGroup) {
                  vm.posGroup = posGroup;

                  PosGroupFactory.one(ids.posGroupId).one('instances').one(ids.posInstanceId).get()
                  .then(function (posInstance) {
                    vm.posInstance = posInstance;
                  })
                  .then(function () {
                    // When posinstances are loaded, search hardwareProxyURL by posinstance id
                    connectToHwProxy(vm.posInstance, vm.posGroup);
                  });
                }, function (errorMsg) {
                  LogService.log(errorMsg, 'debug');
                  LogService.log('Could not get the pos group/instance, wiping cookies', 'debug');
                  CurrentPosInstanceFactory.removeInstanceCookies();
                  vm.checkIfInstanceSelected();
                });
              } else {
                LogService.log('Cookies invalid or no instance selected', 'debug');
                vm.checkIfInstanceSelected();
              }
            });
          }

          function checkIfInstanceSelected() {
            CurrentPosInstanceFactory.validatePosCookies()
            .then(function (valid) {
              if (!valid) {
                LogService.log('No valid instance cookies found, forcing instance selection', 'debug');
                $rootScope.$broadcast('showPosInstanceSelectionModal');
              }
            });
          }

          // refactor the connectToHwProxy() to a service function
          // this function was copied from pos-controller
          function connectToHwProxy(posInstance, posGroup) {
            // Open connection to HWproxy if required
            if (posInstance.hardwareProxyUrl) {
              hwproxy.options.posPrinter.type = posInstance.hardwareProxyPosPrinterType || null;
              hwproxy.options.posPrinter.receiptFooter = posGroup.pointOfSalePrinterReceiptFooter || null;
              hwproxy.options.posDisplay.standbyMessage = posGroup.pointOfSalePoleDisplayStandbyMessage || null;
              hwproxy.connect(posInstance.hardwareProxyUrl);
            } else {
              vm.disconnectHwProxy();
            }
          }

          function disconnectHwProxy() {
            hwproxy.disconnect();
          }
        }
      ]
    };
  }
}());
