(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.factory:RoleFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .factory('RoleFactory', RoleFactory);

  function RoleFactory(Restangular, _, UtilService) {
    var roleService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'role'});
    })
    .service('roles');

    return {
      getList: function (params) {
        return roleService.getList(params);
      },
      post: function (params) {
        return roleService.post(params);
      },
      one: function (fragment) {
        return roleService.one(fragment);
      },
      getRoleById: function (id) {
        var params = {
          limit: 99
        };

        params['filter[]'] = 'id,' + id;
        return roleService.getList(params)
        .then(function (eligibleRoles) {
          return _.first(eligibleRoles);
        });
      },
      checkIfRoleExists: function (id) {
        return this.getRoleById(id)
        .then(function (role) {
          return UtilService.isNotEmpty(role);
        });
      },
      getRoleByLabel: function (label) {
        var params = {
          limit: 99
        };

        params['filter[]'] = 'label,LIKE ' + label;
        return roleService.getList(params)
        .then(function (eligibleRoles) {
          return _.first(eligibleRoles);
        });
      },

      getRolesForFilter: function () {
        var params = {
          limit: 99
        };

        params['filter[]'] = 'isFilter,1';
        return roleService.getList(params);
      }
    };
  }
}());
