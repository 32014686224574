(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.controller:FacilityTypeAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .controller('FacilityTypeAddEditCtrl', FacilityTypeAddEditCtrl);

  function FacilityTypeAddEditCtrl(
    $modalInstance,
    LogService,
    $filter,
    facilityType,
    reloadType,
    FacilityTypeFactory,
    LocaleService,
    ToastrNotificationService
    ) {
    var vm = this;
    vm.facilityType = facilityType;
    vm.isEdit = isEdit;
    vm.frenchEnabled = LocaleService.isFrenchEnabled();
    vm.showSavedNotification = showSavedNotification;
    vm.saveFacilityType = saveFacilityType;
    vm.cancel = cancelModalInstance;

    if (vm.isEdit()) {
      // If edit mode
      vm.facilityTypeLabel = vm.facilityType.label;
      vm.facilityTypeFrLabel = vm.facilityType.frLabel;
      vm.facilityTypeCode = vm.facilityType.code;
    } else {
      // If not edit mode
      LogService.log('is not edit. Add-mode', 'debug');
    }

    function isEdit() {
      return vm.facilityType !== null;
    }

    function saveFacilityType() {
      var facilityTypeObject = {
        label: vm.facilityTypeLabel,
        frLabel: vm.facilityTypeFrLabel,
        code: vm.facilityTypeCode
      };
      if (vm.isEdit()) {
        // If edit mode
        FacilityTypeFactory.one(vm.facilityType.id).patch(facilityTypeObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      } else {
        // If not edit mode
        FacilityTypeFactory.post(facilityTypeObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      }
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.facility-type')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.facility-type')
            )
          )
        );
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
