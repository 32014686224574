(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:SavingsCampaignAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('SavingsCampaignAddEditCtrl', SavingsCampaignAddEditCtrl);

  function SavingsCampaignAddEditCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    SavingsCampaignFactory,
    MobileContentFactory,
    MobileLanguageFactory,
    savingsCampaign,
    $modalInstance
  ) {
    var vm = this;
    vm.savingsCampaign = savingsCampaign;
    vm.savingsCampaignLabel = '';
    vm.savingsCampaignStartDate = new Date();
    vm.savingsCampaignEndDate = new Date();
    vm.minDate = new Date();
    vm.isEdit = isEdit;
    vm.setSavingsCampaignData = setSavingsCampaignData;
    vm.getSavingsCampaignRestObject = getSavingsCampaignRestObject;
    vm.saveSavingsCampaign = saveSavingsCampaign;
    vm.showSavedNotification = showSavedNotification;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openStartDatePicker = openStartDatePicker;

    if (vm.isEdit()) {
      vm.setSavingsCampaignData();
    }

    function openStartDatePicker() {
      vm.startDatePickerIsOpened = true;
    }

    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function getSavingsCampaignRestObject() {
      return {
        label: vm.savingsCampaignLabel,
        startDate: vm.savingsCampaignStartDate ? vm.savingsCampaignStartDate.toISOString().substring(0, 10) : null,
        endDate: vm.savingsCampaignEndDate ? vm.savingsCampaignEndDate.toISOString().substring(0, 10) : null
      };
    }

    function setSavingsCampaignData() {
      vm.savingsCampaignLabel = vm.savingsCampaign.label;
      vm.savingsCampaignStartDate = vm.savingsCampaign.startDate ? new Date(vm.savingsCampaign.startDate) : new Date();
      vm.savingsCampaignEndDate = vm.savingsCampaign.endDate ? new Date(vm.savingsCampaign.endDate) : new Date();
    }

    function isEdit() {
      return angular.isDefined(vm.savingsCampaign) && vm.savingsCampaign !== null;
    }

    function saveSavingsCampaign() {
      var savingsCampaignRestObject = vm.getSavingsCampaignRestObject();

      if (vm.isEdit()) {
        SavingsCampaignFactory.one(vm.savingsCampaign.id).patch(savingsCampaignRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      } else {
        SavingsCampaignFactory.post(savingsCampaignRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      }
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.savings_campaign')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.savings_campaign')
          )
        )
      );
    }
  }
}());
