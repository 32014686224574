(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:PermissionsUtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('PermissionsUtilService', PermissionsUtilService);

  function PermissionsUtilService(UtilService, _, $localStorage) {
    function userHasPermission(permissions, selectionType) {
      var isGranted,
          userPermissions = $localStorage.currentUserPermissions;

      if (UtilService.isNotEmpty(permissions)) {
        if (angular.isArray(permissions)) {
          if (UtilService.isNotEmpty(selectionType)) {
            switch (selectionType) {
              case 'any':
                // any requirement must be met, check intersection
                isGranted = _.intersection(userPermissions, permissions).length > 0;
                break;
              default:
              case 'all':
                // all requirements must be met, check diff
                isGranted = _.intersection(userPermissions, permissions).length === permissions.length;
                break;
            }
          } else {
            // all requirements must be met, check diff
            isGranted = _.intersection(userPermissions, permissions).length === permissions.length;
          }
        } else {
          // single requirement must be met, see if the user has it
          isGranted = _.indexOf(userPermissions, permissions) !== -1;
        }
      } else {
        // no permission requirement given
        isGranted = true;
      }
      return isGranted;
    }

    return {
      userHasPermission: userHasPermission
    };
  }
}());
