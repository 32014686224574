(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.factories:StatisticInfoFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('StatisticInfoFactory', StatisticInfoFactory);

  function StatisticInfoFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'statisticInfo'});
    })
    .service('statistics');
  }
}());
