(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.controller:FacilityTypeDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .controller('FacilityTypeDeleteCtrl', FacilityTypeDeleteCtrl);

  function FacilityTypeDeleteCtrl(
    FacilityTypeFactory,
    $modalInstance,
    $filter,
    facilityType,
    reloadType,
    ToastrNotificationService
    ) {
    var vm = this;
    vm.facilityType = facilityType;
    vm.cancel = cancelModalInstance;
    vm.deleteFacilityType = deleteFacilityType;
    vm.showDeleteNotification = showDeleteNotification;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deleteFacilityType() {
      // Delete a specific customer
      FacilityTypeFactory.one(vm.facilityType.id).remove().then(function () {
        // Close the modal view
        vm.showDeleteNotification();
        $modalInstance.close(reloadType);
      });
    }

    function showDeleteNotification() {
      // Show notification at successful remove
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-removed'),
            $filter('translate')('app.facility-type')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-successfully-removed'),
            $filter('translate')('app.facility-type')
            )
          )
        );
    }
  }
}());
