(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:CustomerJournalAddCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('CustomerJournalAddCtrl', CustomerJournalAddCtrl);

  function CustomerJournalAddCtrl($cookies,
                                  $filter,
                                  $modalInstance,
                                  $q,
                                  customerId,
                                  JournalFactory,
                                  ProductFactory,
                                  ProductComponentFactory,
                                  ToastrNotificationService) {
    var vm = this;
    vm.credits = 0;
    vm.creditsRequired = false;
    vm.endDate = {
      value: new Date(),
      format: 'yyyy-MM-dd',
      isOpen: false,
      open: function () {
        vm.endDate.isOpen = true;
      }
    };
    vm.interval = 1;
    vm.selectedProduct = null;
    vm.startDate = {
      value: new Date(),
      val: '01/01/2018',
      format: 'yyyy-MM-dd',
      isOpen: false,
      open: function () {
        vm.startDate.isOpen = true;
      }
    };

    vm.cancel = function () {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
      vm.isProcessingRequest = false;
    };

    vm.getAvailableProducts = function (label) {
      var products = [];
      vm.selectedProduct = null;
      return ProductFactory.getJournalProductsForTypeahead(label).then(function (result) {
        angular.forEach(result.data, function (product) {
          products.push(product);
        });
        return products;
      });
    };

    vm.productSelected = function () {
      var params = [];
      if (vm.selectedProduct.id) {
        params['filter[]'] = ['product.id,' + vm.selectedProduct.id];

        ProductComponentFactory.one('credits').customGET('', params).then(
          function (result) {
            if (result.data.results.length > 0) {
              vm.creditsRequired = true;
              vm.credits = result.data.results[0].credits;
            } else {
              vm.creditsRequired = false;
              vm.credits = null;
            }
          }
        );

        ProductComponentFactory.one('expiration').customGET('', params).then(
          function (result) {
            if (result.data.results.length > 0) {
              vm.interval = result.data.results[0].interval;
              recalculateEndDate();
            } else {
              vm.endDate.value = null;
            }
          }
        );
      }
    };

    vm.save = function () {
      JournalFactory.one('actions').post('assign', getNewJournalObject()).then(function () {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'success',
          'app.item-saved',
          'journal.journal',
          'app.item-successfully-removed',
          'journal.journal'
        );
        $modalInstance.close();
      }, function () {
        ToastrNotificationService.showTranslatedNotification('error', 'app.error', 'app.error_occurred');
      });
    };

    function getNewJournalObject() {
      return {
        customer: customerId,
        product: vm.selectedProduct.id,
        credits: vm.credits,
        startDate: vm.startDate.value,
        endDate: vm.endDate.value
      };
    }

    function recalculateEndDate() {
      var result = new Date(vm.startDate.value), interval = parseISO8601(vm.interval);
      result.setMonth(result.getMonth() + parseInt(interval.months, 10));
      result.setDate(result.getDate() + parseInt(interval.days, 10));
      vm.endDate.value = result;
    }

    function parseISO8601(interval) {
      var matches = '', regexp = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?(T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/;
      matches = interval.match(regexp);

      return {
        sign: angular.isUndefined(matches[1]) ? '+' : '-',
        years: angular.isUndefined(matches[2]) ? 0 : matches[2],
        months: angular.isUndefined(matches[3]) ? 0 : matches[3],
        weeks: angular.isUndefined(matches[4]) ? 0 : matches[4],
        days: angular.isUndefined(matches[5]) ? 0 : matches[5],
        hours: angular.isUndefined(matches[6]) ? 0 : matches[6],
        minutes: angular.isUndefined(matches[7]) ? 0 : matches[7],
        seconds: angular.isUndefined(matches[8]) ? 0 : matches[8]
      };
    }
  }
}());
