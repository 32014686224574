(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accountancy.factory:Sales
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .factory('SaleFactory', SaleFactory);

  function SaleFactory(_, LogService, ProductUtilService, Restangular, SaleStatusFactory, UtilService, SettingsService) {
    var salesService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'sale'});
    })
    .service('sales');

    salesService.setSaleStatus = function (saleId, statusCode) {
      return SaleStatusFactory.getStatusByCode(statusCode)
      .then(function (status) {
        if (status) {
          return salesService.one(saleId).patch({
            saleStatus: status.id
          });
        }
        return false;
      });
    };

    salesService.loadSaleDetails = function (sale, withoutQrCodeValidationDate) {
      var priceComponent,
          promises = [];

      sale.amount = 0;
      promises.push(salesService.one(sale.id).one('totals').get().then(function (totals) {
        sale.amount = totals.due.EUR;
      }));

      // calculate the per-product totals
      promises.push(salesService.one(sale.id).get().then(function (resultSale) {
        sale.payments = resultSale.payments;
        sale.products = resultSale.saleItems;
        // calculate the individual totals
        _.each(sale.products, function (product) {
          priceComponent = _.findWhere(product.product.productComponents.reverse(), {type: 'price'});
          if (priceComponent) {
            if (UtilService.isNotEmpty(product.price)) {
              // product.priceInclVat = product.price + (product.price * (priceComponent.vatRate.percentage / 100));
              product.vatPrice = ProductUtilService.calculateInclPrice(product.price, priceComponent.vatRate.percentage).vatPrice;
              product.priceInclVat = ProductUtilService.calculateInclPrice(product.price, priceComponent.vatRate.percentage).inclPrice;
            } else {
              product.vatPrice = ProductUtilService.calculateInclPrice(priceComponent.price, priceComponent.vatRate.percentage).vatPrice;
              product.priceInclVat = ProductUtilService.calculateInclPrice(priceComponent.price, priceComponent.vatRate.percentage).inclPrice;
            }
            // round to 2 numbers after decimal (+ ensures toFixed returns number rather than string)
            LogService.log('price inc before rounding = ' + product.priceInclVat, 'debug');
            // product.priceInclVat = +(product.priceInclVat.toFixed(2));
            LogService.log('price inc after rounding = ' + product.priceInclVat, 'debug');
            product.totalOwed = product.priceInclVat * product.quantity;
          }

          if (angular.lowercase(product.product.code) === angular.lowercase(SettingsService.get('pos.roundingProductCode', null))) {
            product.totalOwed = product.price;
          }
          if (product.discount) {
            if (product.discount.price) {
              product.discountPrice = product.discount.price * product.quantity;
            } else {
              product.discountPrice = ProductUtilService.calculateDiscountWithPercentage(product.priceInclVat, product.quantity, product.discount.percentage);
            }
          }
        });
        sale.linkedTo = resultSale.linkedToSales;
        sale.linkedToSales = resultSale.linkedToSales;
        if (UtilService.isNotEmpty(sale.linkedTo)) {
          angular.forEach(sale.linkedTo, function (linkedTo) {
            linkedTo.paidVia = [];
            angular.forEach(linkedTo.payments, function (payment) {
              linkedTo.paidVia.push(payment.paymentMethod.translatedLabel);
            });
            promises.push(salesService.one(linkedTo.id).one('totals').get().then(function (totals) {
              linkedTo.amount = totals.due.EUR;
            }));
          });
        }
        sale.linkedSale = resultSale.linkedWithSales;
        sale.linkedWithSales = resultSale.linkedWithSales;
        if (UtilService.isNotEmpty(sale.linkedTo)) {
          angular.forEach(sale.linkedSale, function (linkedSale) {
            linkedSale.paidVia = [];
            angular.forEach(linkedSale.payments, function (payment) {
              linkedSale.paidVia.push(payment.paymentMethod.translatedLabel);
            });
            promises.push(salesService.one(linkedSale.id).one('totals').get().then(function (totals) {
              linkedSale.amount = totals.due.EUR;
            }));
          });
        }
        sale.payments = resultSale.payments;
        if (angular.isUndefined(withoutQrCodeValidationDate)) {
          sale.qrCodeValidatedAt = resultSale.qrCodeValidatedAt;
        }
      }));

      return Promise.all(promises);
    };

    salesService.validateWebshopSale = function (reference) {
      return salesService.one(reference).one('validate').get().then(function (sale) {
        salesService.loadSaleDetails(sale, true);
        return sale;
      });
    };

    salesService.resendWebshopSaleQrCode = function (id) {
      return salesService.one(id).one('resend_webshop_sale_qr_code').post();
    };

    return salesService;
  }
}());
