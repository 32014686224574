(function () {
  'use strict';

  angular
    .module('subscriptionContract')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('subscription-contracts', {
        url: '/subscription-contracts',
        templateUrl: 'subscription-contract/views/subscription-contract.tpl.html',
        controller: 'SubscriptionContractCtrl',
        controllerAs: 'subscriptionContractCtrl'
      });
  }
}());
