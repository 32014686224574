(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SubscriptionJournalCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SubscriptionJournalCtrl', SubscriptionJournalCtrl);

  function SubscriptionJournalCtrl(
    SubscriptionFactory,
    UtilService,
    $cookies,
    $modalInstance,
    SwimCourseSetFactory,
    subscription,
    SettingsService,
    LogService,
    $modal,
    ContactFactory
  ) {
    var vm = this;
    vm.subscription = subscription;
    vm.cancel = cancelModalInstance;
    vm.journalPaymentStatus = 'not-send';
    vm.sendPaymentRequest = sendPaymentRequest;
    vm.paymentReceived = paymentReceived;
    vm.statusColor = 'sportoase_red';
    vm.subscriptionJournalProductSetting = SettingsService.get('onlineReservation.subscriptionJournalProduct', false);
    vm.subscriptionJournalProduct = null;
    vm.init = init;
    vm.showChooseProductModal = showChooseProductModal;
    vm.sendOrShowModalForPaymentRequest = sendOrShowModalForPaymentRequest;
    vm.showingChooseProductModal = false;
    vm.isJsonString = isJsonString;

    vm.init();

    if (angular.isDefined(subscription.journalPaymentStatus)) {
      vm.statusColor = subscription.journalPaymentStatus === 'received' ? 'sportoase_green' : 'sportoase_orange';
      vm.journalPaymentStatus = subscription.journalPaymentStatus === 'received' ? 'received' : 'send';
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function sendPaymentRequest(productId) {
      var reloadOption = 'reloadListData';

      if (angular.isUndefined(productId)) {
        productId = vm.subscriptionJournalProduct;
      }

      SubscriptionFactory.one(vm.subscription.id).one('send-payment-request').get({productId: productId}).then(function () {
        // Close the modal view
        $modalInstance.close(reloadOption);
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function paymentReceived() {
      var reloadOption = 'reloadListData';

      SubscriptionFactory.one(vm.subscription.id).patch({journalPaymentStatus: 'received'}).then(function () {
        // Close the modal view
        $modalInstance.close(reloadOption);
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function init() {
      ContactFactory.one(subscription.contact.id).get().then(function (contact) {
        vm.contact = contact;
        if (vm.isJsonString(vm.subscriptionJournalProductSetting)) {
          vm.subscriptionJournalProduct = [];
          angular.forEach(angular.fromJson(vm.subscriptionJournalProductSetting), function (productId, label) {
            vm.subscriptionJournalProduct.push({label: label, productId: productId});
          });
        } else {
          vm.subscriptionJournalProduct = vm.subscriptionJournalProductSetting;
        }
      });
    }

    function showChooseProductModal() {
      if (vm.showingChooseProductModal) {
        return;
      }

      vm.showingChooseProductModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/group/registrations/registrations.subscription.products.modal.tpl.html',
        controller: function () {
          this.subscriptionJournalProducts = vm.subscriptionJournalProduct;
          this.contact = vm.contact;

          this.cancel = function () {
            vm.showingChooseProductModalInstance.dismiss();
          };

          this.sendPaymentRequest = function (productId) {
            vm.sendPaymentRequest(productId);
            vm.showingChooseProductModalInstance.close();
          };
        },
        controllerAs: 'subscriptionJournalProductCtrl'
      });

      vm.showingChooseProductModal = true;
      vm.showingChooseProductModalInstance.result.then(function (returnValue) {
        LogService.log('Subscription journal products modal: Closed => reason: ' + returnValue, 'debug');
        vm.showingChooseProductModalInstance = null;
        vm.showingChooseProductModal = false;
      }, function (returnValue) {
        LogService.log('Subscription journal products modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.showingChooseProductModalInstance = null;
        vm.showingChooseProductModal = false;
      });
    }

    function sendOrShowModalForPaymentRequest() {
      if (angular.isArray(vm.subscriptionJournalProduct)) {
        return vm.showChooseProductModal();
      }

      return sendPaymentRequest(vm.subscriptionJournalProduct);
    }

    function isJsonString(str) {
      try {
        angular.fromJson(str);
      } catch (e) {
        return false;
      }

      return true;
    }
  }
}());
