(function () {
  'use strict';

  angular
    .module('kiosk')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('kiosk', {
        url: '/kiosk',
        views: {
          '@': {
            templateUrl: 'kiosk/views/kiosk.tpl.html'
          },
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.login.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope, hwproxy, LogService) {
          LogService.log('enter /kiosk state', 'debug');
          $rootScope.ignoreAuthenticationErrors = true;
          hwproxy.options.cardReader.cardDataEventOpenModal = false;
        },
        onExit: function ($rootScope, hwproxy, LogService) {
          LogService.log('exit /kiosk state', 'debug');
          $rootScope.ignoreAuthenticationErrors = false;
          hwproxy.options.cardReader.cardDataEventOpenModal = true;
        }
      })
      .state('kiosk.customer', {
        url: '/customer/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.customer.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.contact', {
        url: '/contact/:customerId/:contactId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.contact.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      ;
  }
}());
