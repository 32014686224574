(function () {
  'use strict';

  angular
    .module('billingDivision')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('billing_division', {
        url: '/billing_divisions',
        views: {
          '@': {
            templateUrl: 'billing-division/views/billing-division.tpl.html'
          },
          'nav@billing_division': {
            templateUrl: 'billing-division/views/billing-division.nav.tpl.html'
          },
          'content@billing_division': {
            templateUrl: 'billing-division/views/billing-division.list.tpl.html',
            controller: [
              'SettingsService',
              'BillingDivisionFactory',
              'UtilService',
              '$filter',
              '_',
              '$state',
              function (SettingsService, BillingDivisionFactory, UtilService, $filter, _, $state) {
                var vm = this;
                vm.remove = remove;
                vm.makeDefault = makeDefault;
                vm.settings = {
                  'accountancy.cashSaleBillingDivisionId': 'cashSale',
                  'accountancy.invoicedSaleBillingDivisionId': 'regularInvoice',
                  'accountancy.directDebitBillingDivisionId': 'directDebit'
                };

                BillingDivisionFactory.getList({limit: 99, sort: 'label,ASC'}).then(function (resultData) {
                  vm.billingDivisions = resultData;

                  angular.forEach(vm.billingDivisions, function (billingDivision) {
                    var params = {
                      'filter[]': [
                        'value,' + billingDivision.id,
                        'site.id,' + billingDivision.site.id
                      ]
                    };

                    billingDivision.defaults = [];
                    billingDivision.isDefault = false;

                    SettingsService.getAll('LIKE accountancy.', params).then(function (results) {
                      angular.forEach(results, function (result) {
                        if (vm.settings.hasOwnProperty(result.code)) {
                          billingDivision.defaults[vm.settings[result.code]] = true;
                          billingDivision.isDefault = true;
                        }
                      });
                    });
                  });
                });

                function makeDefault(type, entity) {
                  if (entity.defaults && entity.defaults[type]) {
                    return;
                  }

                  _.filter(vm.settings, function (value, key) {
                    if (type === value) {
                      SettingsService.set(key, entity.id, entity.site.id).then(function () {
                        UtilService.reloadState();
                      });
                    }
                  });
                }

                function remove(entity) {
                  if (entity.isDefault) {
                    return;
                  }

                  $state.go('billing_division.delete', {id: entity.id});
                }
              }
            ],
            controllerAs: 'billingDivisionCtrl'
          }
        }
      })
      .state('billing_division.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'billing-division/views/billing-division.add.edit.modal.tpl.html',
            controller: 'BillingDivisionCtrl',
            controllerAs: 'billingDivisionCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('billing_division.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'billing-division/views/billing-division.add.edit.modal.tpl.html',
            controller: 'BillingDivisionCtrl',
            controllerAs: 'billingDivisionCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('billing_division.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'billing-division/views/billing-division.delete.modal.tpl.html',
            controller: 'BillingDivisionCtrl',
            controllerAs: 'billingDivisionCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
