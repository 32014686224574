(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name site.controller:SiteAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('site')
    .controller('SiteAddEditCtrl', SiteAddEditCtrl);

  function SiteAddEditCtrl($filter,
                           $modalInstance,
                           $q,
                           ContactFactory,
                           ContactDataFactory,
                           ContactLocationFactory,
                           ContactTypeFactory,
                           LogService,
                           reloadType,
                           Restangular,
                           site,
                           SiteFactory,
                           SettingsService,
                           ToastrNotificationService) {
    var vm = this;
    vm.site = site;
    vm.contactLocation = {
      contactDataContext: null,
      location: {
        countryCode: null,
        street: '',
        number: '',
        box: '',
        postalCode: '',
        city: ''
      },
      isNew: true
    };
    vm.siteLabel = '';
    vm.pricesCloneSourceSite = null;
    vm.createContactDataObject = ContactDataFactory.createContactDataObject();
    vm.saveLocation = saveLocation;
    vm.sendContactDataToBackend = sendContactDataToBackend;
    vm.vatNumber = ContactDataFactory.createContactDataObject('VAT_NUMBER');
    vm.companyNumber = ContactDataFactory.createContactDataObject('COMPANY_NUMBER');
    vm.bankAccount = ContactDataFactory.createContactDataObject('BANK_ACCOUNT_NUMBER');
    vm.contactEmail = ContactDataFactory.createContactDataObject('EMAIL');
    vm.contactPhone = ContactDataFactory.createContactDataObject('PHONE_NUMBER');
    vm.contactWebsite = ContactDataFactory.createContactDataObject('WEBSITE');
    vm.isEdit = isEdit;
    vm.modalTitle = 'app.add_item';
    vm.sites = [];
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.enablePriceCloning = !vm.isEdit() && !SettingsService.get('settings.disableSitePriceCloning', false);

    vm.showSavedNotification = showSavedNotification;
    vm.saveSite = saveSite;
    vm.cancel = cancelModalInstance;
    vm.getObjectInputId = getObjectInputId;
    vm.init = init;

    function saveSite() {
      return new Promise(function (resolve, reject) {
        var promises = [], siteObject = {
              label: vm.siteLabel
            },
            contactObject = {
              label: vm.siteLabel
            };

        if (vm.isEdit()) {
          // If edit mode
          return SiteFactory.one(vm.site.id).patch(siteObject).then(function () {
            ContactFactory.one(vm.site.contact.id).patch(contactObject).then(function () {
              promises.push(vm.sendContactDataToBackend(vm.site.contact.id, vm.contactEmail));
              promises.push(vm.sendContactDataToBackend(vm.site.contact.id, vm.contactPhone));
              promises.push(vm.sendContactDataToBackend(vm.site.contact.id, vm.contactWebsite));
              promises.push(vm.sendContactDataToBackend(vm.site.contact.id, vm.vatNumber));
              promises.push(vm.sendContactDataToBackend(vm.site.contact.id, vm.companyNumber));
              promises.push(vm.sendContactDataToBackend(vm.site.contact.id, vm.bankAccount));
              promises.push(vm.saveLocation(vm.site.contact.id));
              $q.all(promises).then(function () {
                resolve();
                $modalInstance.close(reloadType);
                vm.showSavedNotification();
              }).catch(function () {
                reject();
              });
            }).catch(function () {
              reject();
            });
          }).catch(function () {
            reject();
          });
        }

        // If not edit mode
        contactObject.contactType = vm.organizationContactTypeObject.id;
        contactObject.user = null;
        return ContactFactory.post(contactObject).then(function (newContact) {
          if (vm.pricesCloneSourceSite !== null) {
            siteObject.pricesCloneSourceSite = vm.pricesCloneSourceSite;
          }
          siteObject.contact = newContact.id;
          promises.push(vm.sendContactDataToBackend(newContact.id, vm.contactEmail));
          promises.push(vm.sendContactDataToBackend(newContact.id, vm.contactPhone));
          promises.push(vm.sendContactDataToBackend(newContact.id, vm.contactWebsite));
          promises.push(vm.sendContactDataToBackend(newContact.id, vm.vatNumber));
          promises.push(vm.sendContactDataToBackend(newContact.id, vm.companyNumber));
          promises.push(vm.sendContactDataToBackend(newContact.id, vm.bankAccount));
          promises.push(vm.saveLocation(newContact.id));
          return SiteFactory.post(siteObject).then(function () {
            $q.all(promises).then(function () {
              resolve();
              $modalInstance.close(reloadType);
              vm.showSavedNotification();
            }).catch(function () {
              reject();
            });
          }).catch(function () {
            reject();
          });
        }).catch(function () {
          reject();
        });
      });
    }

    function init() {
      var contactTypeParams = {
        limit: 99
      };

      if (!vm.isEdit()) {
        SiteFactory.getList({limit: 99}).then(function (resultSites) {
          var i;
          vm.sites = [{id: null, label: ''}];
          for (i = 0; i < resultSites.length; ++i) {
            vm.sites.push({id: resultSites[i].id, label: resultSites[i].label});
          }
        });
      }

      Restangular.all('countries').customGET('').then(function (resultCountries) {
        vm.countries = resultCountries.results;
        contactTypeParams['filter[]'] = 'code,ORGANIZATION';
        ContactTypeFactory.getList(contactTypeParams).then(function (resultTypes) {
          vm.organizationContactTypeObject = resultTypes[0];
        });
        if (vm.isEdit()) {
          if (angular.isDefined(vm.site.id)) {
            vm.modalTitle = 'app.edit_item';

            // load full site for edit
            SiteFactory.one(vm.site.id).get().then(function (resultSite) {
              vm.site = resultSite;
              vm.siteLabel = vm.site.label;
              ContactFactory.one(vm.site.contact.id).get().then(function (resultContact) {
                vm.site.contact = resultContact;
                vm.contactLocation = resultContact.contactLocations[0];

                // Loop all contact data
                angular.forEach(resultContact.contactData, function (contactData) {
                  contactData.code = contactData.contactDataType.code;
                  switch (contactData.contactDataType.code) {
                    case 'PHONE_NUMBER':
                      vm.contactPhone = contactData;
                      break;

                    case 'WEBSITE':
                      vm.contactWebsite = contactData;
                      break;

                    case 'EMAIL':
                      vm.contactEmail = contactData;
                      break;

                    case 'VAT_NUMBER':
                      vm.vatNumber = contactData;
                      break;

                    case 'COMPANY_NUMBER':
                      vm.companyNumber = contactData;
                      break;

                    case 'BANK_ACCOUNT_NUMBER':
                      vm.bankAccount = contactData;
                      break;

                    default:
                      LogService.log('ContactData code ' + contactData.contactDataType.code + ' not recognized', 'debug');
                      break;
                  }
                });
              });
            });
          }
        }
      });
    }

    function isEdit() {
      return vm.site !== null;
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.site')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.site')
          )
        )
      );
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function sendContactDataToBackend(contactId, object) {
      var contactDC = object.contactDataContext,
          contactDataObject = {
            contactDataContext: angular.isUndefined(contactDC) || angular.isUndefined(contactDC.id) ? null : contactDC.id
          };
      contactDataObject[ContactDataFactory.getFieldName(object.code)] = object.value;

      if (object.code === 'VAT_NUMBER') {
        contactDataObject.noVatNumberCheck = true;
      }

      if (object.value === null) {
        return;
      }

      if (object.id) {
        // edit contact data
        return ContactFactory.one(contactId).one('data').one(object.id)
          .patch(contactDataObject);
      }
        // Create contact data
      return ContactFactory.one(contactId).one('data').one(object.code.toLowerCase())
          .customPOST(contactDataObject);
    }

    function saveLocation(contactId) {
      var locationObject = {
        street: vm.contactLocation.location.street,
        number: vm.contactLocation.location.number,
        box: vm.contactLocation.location.box,
        postalCode: vm.contactLocation.location.postalCode,
        city: vm.contactLocation.location.city,
        countryCode: vm.contactLocation.location.countryCode
      };
      if (vm.contactLocation.location.id) {
        // Post location to backend
        return ContactLocationFactory.one(vm.contactLocation.location.id).patch(locationObject, {inputId: vm.getObjectInputId(vm.contactLocation.location)}).then(function () {
          LogService.log('location update', 'debug');
        });
      }
        // Post location to backend
      return ContactLocationFactory.post(locationObject, {inputId: vm.getObjectInputId(vm.contactLocation.location)}).then(function (createdLocation) {
          // Link location to contact
        return ContactFactory.one(contactId).one('locations')
            .customPOST({contactDataContext: null, location: createdLocation.id})
            .then(function () {
              LogService.log('location created', 'debug');
            });
      });
    }

    function getObjectInputId(object) {
      return (angular.isDefined(object.id) ? object.id : object.inputId);
    }
  }
}());
