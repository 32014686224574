(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name journal.factory:JournalType
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .factory('JournalTypeFactory', JournalTypeFactory);

  function JournalTypeFactory(Restangular) {
    var journalTypeService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'journalType'});
    })
    .service('journals/types');

    return {
      getList: function (params) {
        return journalTypeService.getList(params);
      },
      one: function (fragment) {
        return journalTypeService.one(fragment);
      },
      getJournalTypeById: function (journalTypeId) {
        return journalTypeService.one(journalTypeId).get();
      },
      post: function (object) {
        return journalTypeService.post(object);
      }
    };
  }
}());
