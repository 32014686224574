(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SeriesCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SeriesCtrl', SeriesCtrl);

  function SeriesCtrl(
      $state,
      $stateParams,
      $modal,
      LogService,
      SwimCourseSetFactory,
      LevelFactory,
      SubscriptionFactory,
      AttachmentUtilService,
      UtilService,
      ToastrNotificationService
    ) {
    var vm = this;
    vm.set = null;
    vm.session = null;
    vm.series = null;
    vm.levels = [];
    vm.dates = [];
    vm.viewSeriesGroup = viewSeriesGroup;
    vm.goBack = goBack;
    vm.loadActivatedGroups = loadActivatedGroups;
    vm.findLevelAndSwimCourseGroupIndex = findLevelAndSwimCourseGroupIndex;
    vm.loadPupils = loadPupils;
    vm.downloadAttendanceSheet = downloadAttendanceSheet;

    // select add/edit group modal
    vm.showingAddEditGroupModal = false;
    vm.showAddEditGroupModal = showAddEditGroupModal;
    vm.returnOfAddEditGroupModalInstance = returnOfAddEditGroupModalInstance;
    vm.addEditGroupModalInstance = null;

    // select add/edit date modal
    vm.showingAddDateModal = false;
    vm.showAddDateModal = showAddDateModal;
    vm.returnOfAddDateModalInstance = returnOfAddDateModalInstance;
    vm.addDateModalInstance = null;

    // date delete modal
    vm.showingDateDeleteModal = false;
    vm.showDateDeleteModal = showDateDeleteModal;
    vm.returnOfDateDeleteModalInstance = returnOfDateDeleteModalInstance;
    vm.dateDeleteModalInstance = null;

    vm.expandedLevels = [];
    vm.collectExpandedLevels = collectExpandedLevels;
    vm.expandLevels = expandLevels;

    vm.deactivateGroup = deactivateGroup;

    vm.loadData = loadData;
    loadData();

    function loadData() {
      var firstDate, lastDate;
      // load the set
      vm.collectExpandedLevels();
      SwimCourseSetFactory.one($stateParams.setId).get().then(function (resultSet) {
        vm.set = resultSet;

        // load the series
        SwimCourseSetFactory.one(vm.set.id).one('series').one($stateParams.serId).get()
        .then(function (series) {
          // get the first and last date
          firstDate = series.startsAt;
          lastDate = firstDate;
          angular.forEach(series.swimCourseSessions, function (session) {
            if (session.startsAt > lastDate) {
              lastDate = session.startsAt;
            }
            if (session.startsAt < firstDate) {
              firstDate = session.startsAt;
            }
          });

          series.firstDate = firstDate;
          series.lastDate = lastDate;
          vm.series = series;

          // load the activated groups
          LevelFactory.getList({limit: 99}).then(function (resultLevels) {
            vm.levels = resultLevels;
            vm.loadActivatedGroups();
          });
        });
      });
    }

    function collectExpandedLevels() {
      vm.expandedLevels = [];

      angular.forEach(vm.levels, function (lvl) {
        if (lvl.hasOwnProperty('groupsShown') && lvl.groupsShown) {
          vm.expandedLevels.push(lvl.id);
        }
      });
    }

    function expandLevels() {
      angular.forEach(vm.levels, function (existingLvl) {
        if (vm.expandedLevels.indexOf(existingLvl.id) > -1) {
          existingLvl.groupsShown = true;
        }
      });
    }

    // our list of groups consists of two types found in the backend. In the frontend we
    // distinguish these as non-active and active.
    // This function retrieves the active groups and replaces the matching inactive group in the list.
    function loadActivatedGroups() {
      // REFACTOR THIS SHIT!!
      var agIndices;
      SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id).getList('groups', {limit: 99})
      .then(function (activeGroups) {
        angular.forEach(activeGroups, function (ag) {
          SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id).one('groups').one(ag.id).get()
          .then(function (activeGroup) {
            // replace the swimcoursegroup with set group entity
            agIndices = vm.findLevelAndSwimCourseGroupIndex(activeGroup.swimCourseGroup);
            if (agIndices.levelIndex !== -1 && agIndices.groupIndex !== -1) {
              vm.levels[agIndices.levelIndex].swimCourseGroups[agIndices.groupIndex] = activeGroup.swimCourseGroup;
              vm.levels[agIndices.levelIndex].swimCourseGroups[agIndices.groupIndex].seriesGroupId = activeGroup.id;
              vm.levels[agIndices.levelIndex].swimCourseGroups[agIndices.groupIndex].trainerContact = activeGroup.trainerContact;
              vm.levels[agIndices.levelIndex].swimCourseGroups[agIndices.groupIndex].maximumMembers = activeGroup.maximumMembers;
              vm.levels[agIndices.levelIndex].swimCourseGroups[agIndices.groupIndex].activated = true;

              // load pupil info
              vm.loadPupils(vm.levels[agIndices.levelIndex], vm.levels[agIndices.levelIndex].swimCourseGroups[agIndices.groupIndex]);
            }
          });
        });
      });
    }

    function loadPupils(level, seriesGroup) {
      var params = {
        limit: 99
      };

      // load pupils that are subscribed
      params['filter[]'] = ['swimCourseLevel.id,' + level.id, 'swimCourseSeriesGroup.id,' + seriesGroup.seriesGroupId];
      SubscriptionFactory.getList(params).then(function (resultSubscriptions) {
        seriesGroup.subscriptions = resultSubscriptions;
        seriesGroup.memberCount = resultSubscriptions.count;
        vm.expandLevels();
      });
    }

    // find indices for level and swimcourse group (used to replace by seriesGroup when applicable)
    function findLevelAndSwimCourseGroupIndex(group) {
      var i,
          j,
          found = false,
          foundIndices = {
            levelIndex: -1,
            groupIndex: -1
          };

      for (i = 0; i < vm.levels.length; ++i) {
        for (j = 0; j < vm.levels[i].swimCourseGroups.length; ++j) {
          if (vm.levels[i].swimCourseGroups[j].id === group.id) {
            foundIndices.levelIndex = i;
            foundIndices.groupIndex = j;
            found = true;
            break;
          }
        }

        if (found) {
          break;
        }
      }

      return foundIndices;
    }

    // ADD EDIT GROUP
    function showAddEditGroupModal(swimCourseGroup, seriesGroup) {
      if (vm.showingAddEditGroupModal) {
        return;
      }

      vm.addEditGroupModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/levels/series.levels.group.addedit.modal.tpl.html',
        controller: 'GroupAddEditCtrl',
        controllerAs: 'groupAddEditCtrl',
        size: 'sm',
        resolve: {
          set: function () {
            return vm.set;
          },
          series: function () {
            return vm.series;
          },
          swimCourseGroup: function () {
            return angular.isUndefined(swimCourseGroup) ? null : swimCourseGroup;
          },
          seriesGroup: function () {
            return angular.isUndefined(seriesGroup) ? null : seriesGroup;
          }
        }
      });

      vm.showingAddEditGroupModal = true;
      vm.returnOfAddEditGroupModalInstance();
    }

    function returnOfAddEditGroupModalInstance() {
      vm.addEditGroupModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        vm.addEditGroupModalInstance = null;
        vm.showingAddEditGroupModal = false;
        // vm.customer = returnValue.customer;
        // vm.customerContact = returnValue.contact;
        vm.loadData();
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.addEditGroupModalInstance = null;
        vm.showingAddEditGroupModal = false;
      });
    }

    vm.showSessionAddEditModal = showSessionAddEditModal;
    vm.returnOfSessionAddEditModalInstance = returnOfSessionAddEditModalInstance;
    function showSessionAddEditModal(set, series, session) {
      if (vm.showingSessionAddEditModal) {
        return;
      }

      vm.sessionAddEditModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/session.addedit.modal.tpl.html',
        controller: 'SessionAddEditCtrl',
        controllerAs: 'sessionAddEditCtrl',
        size: 'lg',
        resolve: {
          set: function () {
            return angular.isUndefined(set) ? null : set;
          },
          series: function () {
            return angular.isUndefined(series) ? null : series;
          },
          session: function () {
            return angular.isUndefined(session) ? null : session;
          }
        }
      });

      vm.showingSessionAddEditModal = true;
      vm.returnOfSessionAddEditModalInstance();
    }

    // ADD DATE
    function showAddDateModal() {
      if (vm.showingAddDateModal) {
        return;
      }

      vm.addDateModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/dates/series.data.add.tpl.html',
        controller: 'SeriesAddDateCtrl',
        controllerAs: 'seriesAddDateCtrl',
        size: 'sm'
      });

      vm.showingAddDateModal = true;
      vm.returnOfAddDateModalInstance();
    }

    function returnOfSessionAddEditModalInstance() {
      vm.sessionAddEditModalInstance.result.then(function () {
        vm.showingSessionAddEditModal = false;
        vm.loadData();
      }, function () {
        vm.showingSessionAddEditModal = false;
      });
    }

    function returnOfAddDateModalInstance() {
      vm.addDateModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id).post('sessions', {
          startsAt: returnValue
        })
        .then(function () {
          vm.loadData();
        });
        vm.addDateModalInstance = null;
        vm.showingAddDateModal = false;
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.addDateModalInstance = null;
        vm.showingAddDateModal = false;
      });
    }

    // DELETE DATE
    function showDateDeleteModal(session) {
      if (vm.showingDateDeleteModal) {
        return;
      }

      vm.dateDeleteModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/sets/series/dates/series.date.delete.modal.tpl.html',
        controller: 'SeriesDateDeleteCtrl',
        controllerAs: 'seriesDateDeleteCtrl',
        size: 'sm'
      });

      vm.showingDateDeleteModal = true;
      vm.returnOfDateDeleteModalInstance(session);
    }

    function returnOfDateDeleteModalInstance(session) {
      vm.dateDeleteModalInstance.result.then(function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
        if (returnValue === 'yes') {
          SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id).one('sessions').one(session.id).remove()
          .then(function () {
            vm.loadData();
          });
        }
        vm.dateDeleteModalInstance = null;
        vm.showingDateDeleteModal = false;
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.dateDeleteModalInstance = null;
        vm.showingDateDeleteModal = false;
      });
    }

    function downloadAttendanceSheet() {
      if (!vm.series) {
        return;
      }

      AttachmentUtilService.download('/services/swim_course_series_groups_export', {
        format: 'pdf',
        type: 'attendance_sheets',
        'swimCourseSeries[]': vm.series.id
      });
    }

    function viewSeriesGroup(group) {
      // redirect to detail view for groups
      $state.go('swimcourse.groups', {setId: vm.set.id, serId: vm.series.id, groId: group.seriesGroupId});
    }

    function goBack() {
      $state.go('swimcourse.calendar');
    }

    function deactivateGroup(group) {
      /* check if has subscriptions */
      if (group.subscriptions.length === 0) {
        UtilService.showTranslatedConfirmationModal('app.deactivate-swimcourse-group', 'app.deactivate-swimcourse-group-extended', function (returnValue) {
          if (returnValue) {
            SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id).one('groups')
            .one(group.seriesGroupId)
            .remove()
            .then(function () {
              ToastrNotificationService.showTranslatedNotification(
                'success',
                'app.deactivated',
                ''
              );
              vm.loadData();
            });
          }
        });
      } else {
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.series-group-cannot-be-removed-subscription',
          ''
        );
      }
    }
  }
}());
