(function () {
  'use strict';

  angular
    .module('settings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        templateUrl: 'settings/views/settings.tpl.html'
      })
      .state('settings.overview', {
        url: '/overview',
        views: {
          'main@settings': {
            templateUrl: 'settings/views/settings-overview.tpl.html',
            controller: 'SettingsCtrl',
            controllerAs: 'settingsCtrl'
          }
        }
      })
      .state('settings.features', {
        url: '/features',
        views: {
          'main@settings': {
            templateUrl: 'settings/views/settings-features.tpl.html',
            controller: 'FeaturesCtrl',
            controllerAs: 'featuresCtrl'
          }
        }
      })
      .state('set-locale', {
        url: '/set-locale/:locale',
        onEnter: ['$stateParams', '$state', 'LocaleService', function ($stateParams, $state, LocaleService) {
          LocaleService.setLocale($stateParams.locale);
          $state.go('dashboard', {}, {reload: true});
        }]
      })
      .state('toggle-locale', {
        url: '/toggle-locale',
        onEnter: ['$stateParams', '$state', 'LocaleService', function ($stateParams, $state, LocaleService) {
          LocaleService.toggleLocale();
          $state.go('dashboard', {}, {reload: true});
        }]
      });
  }
}());
