(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name calendar.controller:PublicCalendarCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('calendar')
    .controller('PublicCalendarCtrl', PublicCalendarCtrl);

  function PublicCalendarCtrl(
    _,
    $rootScope,
    $state,
    $translate,
    CurrentUserContextFactory,
    EventInstancesFactory,
    ImageService,
    moment,
    UtilService
  ) {
    // calendar time range
    var vm = this, hoursStart = 8, hoursEnd = 21;
    // set root UI variables
    $rootScope.ui = {
      navigation: false,
      header: false,
      // for modification of page__body via css
      bodyClasses: ['calendar']
    };

    // calendar table
    vm.weekTable = {};
    vm.startDate = moment($state.params.date).startOf('isoWeek').hours(12);
    vm.endDate = moment($state.params.date).endOf('isoWeek').hours(12);
    vm.weekDays = generateWeek();
    vm.weekDaysArray = {};

    // navigation
    vm.nextWeek = moment($state.params.date).startOf('isoWeek').hours(12).add(7, 'days');
    vm.prevWeek = moment($state.params.date).startOf('isoWeek').hours(12).subtract(7, 'days');

    vm.openEventDetail = eventDetail;
    vm.generateWeek = generateWeek;

    init();

    /**
     * Controller initialization.
     *
     * This function generate weekTable, which contains all necessary data for render.
     * For easy rendering, table have following structure:
     * weekTable = {
     *   '08:00': {
     *     'monday': [Event1]
     *     'tuesday': [Event2]
     *     ...
     *   },
     *   '09:00': {
     *     'monday': [Event10]
     *     'tuesday': [Event12]
     *   }
     *   ...
     * }
     */
    function init() {
      var params, i;

      // This generate array of day hashes, which are later used in weekTable
      vm.weekDays.forEach(function (e) {
        vm.weekDaysArray[getDayHash(e)] = [];
      });

      // Generate hours of week table from given range
      for (i = hoursStart; i <= hoursEnd; i++) {
        vm.weekTable[getTimeHash(i)] = _.cloneDeep(vm.weekDaysArray);
      }

      params = {
        'filter[]': [
          'startsAt,GTE ' + vm.startDate.format('YYYY-MM-DD 00:00:00'),
          'endsAt,LTE ' + vm.endDate.format('YYYY-MM-DD 23:59:59'),
          'displayOnWebsite,TRUE'
        ],
        sort: 'startsAt,ASC',
        limit: 99
      };

      if (angular.isDefined($state.params.site)) {
        params['filter[]'].push('event.site.id,' + $state.params.site);
      } else {
        params['filter[]'].push('event.site.id,' + CurrentUserContextFactory.getSiteId());
      }

      EventInstancesFactory.one().get(params).then(function (result) {
        result.results.forEach(function (event) {
          var startsAt = new Date(event.startsAt);
          if (angular.isDefined(event.event.fitnessCourses[0].photo)) {
            ImageService.getImageFromBackend(event.event.fitnessCourses[0].photo.id).then(function (photo) {
              event.photo = photo;
              event.hasPhoto = true;
            });
          }
          // put each event directly where it belongs
          if (angular.isDefined(vm.weekTable[getTimeHash(startsAt.getHours())])) {
            vm.weekTable[getTimeHash(startsAt.getHours())][getDayHash(startsAt)].push(event);
          }
        });
      });
    }

    /**
     * Generate array of dates for current week
     * @returns {Array} of moments
     */
    function generateWeek() {
      var days = [],
          day = vm.startDate;

      while (day <= vm.endDate) {
        days.push(day.toDate());
        day = day.clone().add(1, 'd');
      }

      return days;
    }

    /**
     * Handles event detail modal
     * @param {eventInstance} event - event instance for modal
     */
    function eventDetail(event) {
      UtilService.showModal({
        templateUrl: 'calendar/views/event-detail.modal.tpl.html',
        controller: 'EventDetailCtrl',
        controllerAs: 'eventDetailCtrl',
        size: 'lg',
        resolve: {
          eventInstance: function () {
            return event;
          }
        },
        backdrop: true
      }, function () {}).then(function (modal) {
        modal.element.one('hidden.bs.modal', function () {
          if (!modal.controller.closed) {
            modal.controller.closeModal();
          }
        });
        modal.element.modal();
      });
    }

    /**
     * Generates table hash for a given day
     * @param {moment} date - Date
     * @returns {string} - generated table hash
     */
    function getDayHash(date) {
      return date.getMonth() + '-' + date.getDate();
    }

    /**
     * Generates table hash for given hour
     *
     * @param {int} time - hours in range (8 - 21)
     * @return {string} - generated table hash
     */
    function getTimeHash(time) {
      return (time.toString() + ':00').padStart(5, '0');
    }
  }
}());
