(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosACardCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosACardCtrl', PosACardCtrl);

  function PosACardCtrl(
    FacilityTypeFactory,
    ToastrNotificationService,
    $filter,
    $modalInstance,
    Restangular,
    $rootScope,
    hwproxy
  ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.aCardNumber = null;
    vm.searchFinished = false;
    vm.searchACard = searchACard;
    vm.displayConsumeButton = displayConsumeButton;
    vm.consumeJournal = consumeJournal;
    vm.journals = [];
    vm.journalsLoading = false;
    vm.consumeInProgress = false;

    $rootScope.$on(hwproxy.options.cardReader.unknownCardEventName, function (event, data) {
      if (angular.isDefined(data.event.tracks)) {
        angular.forEach(data.event.tracks, function (trackData) {
          if (angular.isDefined(trackData['a-kaart'])) {
            vm.aCardNumber = trackData['a-kaart'];
          }
        });
      }
    });

    function searchACard(aCardNumber) {
      vm.journalsLoading = true;
      vm.journals = [];
      vm.searchFinished = false;
      if (angular.isUndefined(aCardNumber)) {
        aCardNumber = vm.aCardNumber;
      }

      if (aCardNumber) {
        Restangular.one('customer').one('a-card').one('journals').customGET('', {a_card: aCardNumber}).then(function (results) {
          vm.journals = results.subscriptions;
          vm.searchFinished = true;
          vm.journalsLoading = false;
        });
      }
    }

    function displayConsumeButton(journal) {
      return isJournalValid(journal);
    }

    function isJournalValid(journal) {
      if (journal.active !== true && journal.valid !== true) {
        return false;
      }

      if (journal.remainingTurns && journal.remainingTurns > 0) {
        return true;
      }

      if (journal.checkNumberOfTurns === false) {
        return true;
      }

      return false;
    }

    function consumeJournal(journal) {
      if (isJournalValid(journal)) {
        vm.consumeInProgress = true;
        return Restangular.one('journal').one(journal.id).one('a-card').one('consume').customPOST({cardNumber: vm.aCardNumber}).then(function () {
          vm.consumeInProgress = false;
          vm.searchACard(vm.aCardNumber);
          ToastrNotificationService.showTranslatedNotification('success', 'app.success');
        });
      }
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }
  }
}());
