(function () {
  'use strict';

  angular
    .module('user')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('users', {
        url: '/users',
        views: {
          '@': {
            templateUrl: 'user/views/users.tpl.html'
          },
          'nav@users': {
            templateUrl: 'user/views/users.nav.tpl.html',
            controller: 'UserCtrl',
            controllerAs: 'userCtrl'
          },
          'content@users': {
            templateUrl: 'user/views/users.list.tpl.html',
            controller: 'UserCtrl',
            controllerAs: 'userCtrl'
          }
        }
      })
      .state('users.edit', {
        url: '/:id/edit',
        views: {
          'content@users': {
            templateUrl: 'user/views/users.add.edit.modal.tpl.html',
            controller: 'UserCtrl',
            controllerAs: 'userCtrl'
          }
        }
      })
      .state('users.new', {
        url: '/new',
        views: {
          'content@users': {
            templateUrl: 'user/views/users.add.edit.modal.tpl.html',
            controller: 'UserAddEditCtrl',
            controllerAs: 'userAddEditCtrl'
          }
        }
      })
      .state('users.delete', {
        url: '/:id/delete',
        views: {
          'content@users': {
            templateUrl: 'user/views/users.delete.modal.tpl.html',
            controller: 'UserCtrl',
            controllerAs: 'userCtrl'
          }
        }
      })
      .state('user_contexts', {
        url: '/users/:userId/contexts',
        abstract: true
      })
      .state('user_contexts.edit', {
        url: '/:id/edit',
        views: {
          'content@users': {
            templateUrl: 'user/views/user_contexts.add.edit.modal.tpl.html',
            controller: 'UserContextCtrl',
            controllerAs: 'userContextCtrl'
          }
        }
      })
      .state('user_contexts.new', {
        url: '/new',
        views: {
          'content@users': {
            templateUrl: 'user/views/user_contexts.add.edit.modal.tpl.html',
            controller: 'UserContextCtrl',
            controllerAs: 'userContextCtrl'
          }
        }
      })
      .state('user_contexts.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'user/views/user_contexts.delete.modal.tpl.html',
            controller: 'UserContextCtrl',
            controllerAs: 'userContextCtrl'
          }).result.then(function () {
            $state.go('users', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('users', {}, {reload: true});
            }
          });
        }]
      })
      .state('roles', {
        url: '/roles',
        views: {
          '@': {
            templateUrl: 'user/views/roles.tpl.html'
          },
          'nav@roles': {
            templateUrl: 'user/views/roles.nav.tpl.html'
          },
          'content@roles': {
            templateUrl: 'user/views/roles.list.tpl.html',
            controller: 'RoleCtrl',
            controllerAs: 'roleCtrl'
          }
        }
      })
      .state('roles.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'user/views/roles.add.edit.modal.tpl.html',
            controller: 'RoleAddEditCtrl',
            controllerAs: 'roleAddEditCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('roles.edit_global', {
        url: '/edit_global',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'user/views/roles.edit.global.modal.tpl.html',
            controller: 'RoleEditGlobalCtrl',
            controllerAs: 'roleEditGlobalCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('roles.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'user/views/roles.add.edit.modal.tpl.html',
            controller: 'RoleAddEditCtrl',
            controllerAs: 'roleAddEditCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('roles.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'user/views/roles.delete.modal.tpl.html',
            controller: 'RoleAddEditCtrl',
            controllerAs: 'roleAddEditCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('user_activation', {
        url: '/users/activate/:token',
        views: {
          '@': {
            templateUrl: 'user/views/user_activation.tpl.html'
          },
          'content@user_activation': {
            templateUrl: 'user/views/user_activation.content.tpl.html',
            controller: 'UserActivationCtrl',
            controllerAs: 'userActivationCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('user_password_reset', {
        url: '/users/password/reset/:token',
        views: {
          '@': {
            templateUrl: 'user/views/user_activation.tpl.html'
          },
          'content@user_password_reset': {
            templateUrl: 'user/views/user_reset_password.tpl.html',
            controller: 'UserPasswordResetCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
    ;
  }
}());
