(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:SaleAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('SaleAddEditCtrl', SaleAddEditCtrl);

  function SaleAddEditCtrl($scope,
                           $modalInstance,
                           $timeout,
                           LogService,
                           $q,
                           AccountancyPeriodFactory,
                           CurrentUserContextFactory,
                           DiscountFactory,
                           PaymentMethodsFactory,
                           PermissionsUtilService,
                           PosSessionFactory,
                           ProductFactory,
                           ProductUtilService,
                           Restangular,
                           SaleFactory,
                           sale,
                           SaleStatusFactory,
                           SettingsService,
                           ToastrNotificationService,
                           UtilService) {
    var vm = this;
    vm.PermissionsUtilService = PermissionsUtilService;
    vm.ProductUtilService = ProductUtilService;
    vm.paymentMethods = [];
    vm.currentFacilityId = CurrentUserContextFactory.getUserContextCookies().siteFacilityId;
    vm.loggedInUserSiteId = CurrentUserContextFactory.getUserContextCookies().siteId;
    vm.currentSale = sale;
    vm.enableInternalComments = SettingsService.get('pos.saleItem.enableInternalComments');
    vm.showCustomerListModal = showCustomerListModal;
    vm.salePaymentMethod = null;
    vm.saleCustomer = null;
    vm.saleProducts = [];
    vm.deletedSaleProducts = [];
    vm.totalPrice = 0;
    vm.totalPriceExclVAT = 0;
    vm.openedAccountancyPeriods = [];
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.addSelectedProduct = addSelectedProduct;
    vm.getInvoiceProductIndex = getInvoiceProductIndex;
    vm.getSaleItemRestObject = getSaleItemRestObject;
    vm.getSaleRestObject = getSaleRestObject;
    vm.removeProduct = removeProduct;
    vm.getAsyncProduct = getAsyncProduct;
    vm.loadPosSessions = loadPosSessions;
    vm.posSessions = [];
    vm.formatCustomer = formatCustomer;
    vm.startSaveSequence = startSaveSequence;
    vm.persistInvoice = persistInvoice;
    vm.persistSale = persistSale;
    vm.persistSaleItems = persistSaleItems;
    vm.scrubOldSaleItems = scrubOldSaleItems;
    vm.savePaymentMethod = savePaymentMethod;
    vm.isPosSale = isPosSale;
    vm.openDatePicker = openDatePicker;
    vm.datePickerIsOpened = false;
    vm.completedAtDate = new Date();
    vm.isAccountancySale = isAccountancySale;
    vm.checkIfFinalizeIsPossible = checkIfFinalizeIsPossible;
    vm.checkCompletedAtDateCallback = checkCompletedAtDateCallback;
    vm.today = new Date();
    vm.invoiceAlreadyPaid = false;
    vm.init = init;
    vm.recountTotals = recountTotals;
    vm.setPriceToProduct = setPriceToProduct;
    vm.discountPercentageChanged = discountPercentageChanged;
    vm.isAccountancyPeriodsCheckingEnabled = isAccountancyPeriodsCheckingEnabled;
    vm.UtilService = UtilService;
    vm.disableAddDiscountOnEditSale = SettingsService.get('accountancy.disableAddDiscountOnEditSale', false);
    vm.posInstanceAdministrativePropertyEnabled = SettingsService.get('posInstanceAdministrativePropertyEnabled', false);
    vm.init();
    vm.approvalFlow34 = (SettingsService.get('pos.session.approvalFlowVersion') === '3' || SettingsService.get('pos.session.approvalFlowVersion') === '4');

    function init() {
      var currentProduct, i;
      vm.saleProducts = [];
      PaymentMethodsFactory.getAccountancyList({
        limit: 99,
        'filter[]': [
          'site.id,' + CurrentUserContextFactory.getSiteId()
        ],
        sort: 'weight,asc'
      }).then(function (paymentMethods) {
        vm.paymentMethods = paymentMethods;
      });
      vm.loadPosSessions();

      vm.isCustomerMandatory = SettingsService.get('accountancy.isCustomerMandatory', true);
      vm.allowedSelectPos = !!SettingsService.get('accountancy.allowedSelectPos', false);
      vm.disableAlreadyPaidCheckbox = SettingsService.get('accountancy.disableAlreadyPaidCheckbox', false);
      vm.disableFinalizeSaleCheckbox = SettingsService.get('accountancy.disableFinalizeSaleCheckbox', false);
      vm.disableSaleItemEdit = SettingsService.get('accountancy.disableSaleItemEdit', false);
      vm.disableSalePaymentMethodEdit = SettingsService.get('accountancy.disableSalePaymentMethodEdit', false);
      vm.enableDiscounts = !SettingsService.get('pos.disableDiscounts', false);

      if (vm.isEdit()) {
        vm.saleCustomer = vm.currentSale.customer;

        SaleFactory.one(vm.currentSale.id).get()
          .then(function (retrievedSale) {
            if (retrievedSale.hasOwnProperty('payments')) {
              $timeout(function () {
                vm.currentSale.payments = retrievedSale.payments;
              });
            }

            if (UtilService.isNotEmpty(retrievedSale.completedAt)) {
              vm.completedAtDate = new Date(retrievedSale.completedAt).toISOString();
            }

            // iterate the sale items and change some of the data
            // csi = current sale item
            angular.forEach(retrievedSale.saleItems, function (csi) {
              currentProduct = csi.product;
              currentProduct.saleItemId = csi.id;
              currentProduct.comments = UtilService.isNotEmpty(csi.comments) ? csi.comments : '';
              currentProduct.internalComments = UtilService.isNotEmpty(csi.internalComments) ? csi.internalComments : '';
              // keep whole parameters object, so parameters which we don't edit here don't get lost
              currentProduct.parametersObject = csi.parameters || {};

              if (vm.approvalFlow34) {
                currentProduct.gfp = {};
                currentProduct.gfp.invoiceNumber = csi.invoiceNumber;
                currentProduct.gfp.invoiceDate = new Date(csi.invoiceDate).toDateString();
                currentProduct.gfp.gfpCustomer = csi.gfpCustomer;
                currentProduct.gfp.firstName = csi.firstName;
                currentProduct.gfp.lastName = csi.lastName;
              }
              // make the price component more accessible
              for (i = 0; i < currentProduct.productComponents.length; ++i) {
                if (currentProduct.productComponents[i].type === 'price') {
                  currentProduct.price = currentProduct.productComponents[i];
                  break;
                }
              }

              // override price with sale item price if it exists (higher priority than product price)
              if (csi.hasOwnProperty('price')) {
                currentProduct.price.price = csi.price;
              }

              // apply discount from sale item
              if (csi.discount) {
                if (csi.discount.percentage) {
                  currentProduct.discount = csi.discount.id;
                  currentProduct.previousDiscountPercentage = csi.discount.percentage;
                  currentProduct.discountPercentage = csi.discount.percentage;
                  currentProduct.discountType = DiscountFactory.getPercentageDiscountType();
                } else if (csi.discount.price) {
                  currentProduct.discount = csi.discount.id;
                  currentProduct.discountAmount = csi.discount.price;
                  currentProduct.discountType = DiscountFactory.getAmountDiscountType();
                }
              }

              // apply combi product parent/child parameters from sale item
              if (csi.parameters) {
                if (csi.parameters.isGroupProduct) {
                  currentProduct.isGroupProduct = csi.parameters.isGroupProduct;
                  currentProduct.subProducts = [];
                } else if (csi.parameters.parentSaleItem) {
                  // this loop assumes that parent sale items are processed first, which should be the case everytime
                  angular.forEach(vm.saleProducts, function (saleProduct) {
                    if (saleProduct.saleItemId === csi.parameters.parentSaleItem) {
                      currentProduct.isSubProduct = true;
                      currentProduct.parentSaleItem = saleProduct;
                      saleProduct.subProducts.push(currentProduct);
                    }
                  });
                }
              }

              currentProduct.amount = csi.quantity;
              vm.saleProducts.push(currentProduct);
            });
          }).then(function () {
            vm.recountTotals();
          });
      }

      AccountancyPeriodFactory.getAllOpened(vm.loggedInUserSiteId).then(function (items) {
        angular.forEach(items, function (item) {
          AccountancyPeriodFactory.convertDatesToObject(item);
          vm.openedAccountancyPeriods.push(item);
        });
      });
    }

    function isAccountancyPeriodsCheckingEnabled() {
      return !SettingsService.get('accountancy.disableAccountancyPeriodCheck', false);
    }

    $scope.$on('pos.setSaleCustomer', function (event, data) {
      vm.saleCustomer = data.customer;
      vm.saleCustomerContact = data.contact;
      console.log('data');
      console.log(data);
    });

    function isPosSale(saleObject) {
      return saleObject.hasOwnProperty('pointOfSaleSession');
    }

    // this is a workaround for 'select c.id as c.label for c in customers' showing
    // the customer id in the typeahead input field after selecting a customer. This will take that id and format
    // it into the customer label
    function formatCustomer(customer) {
      return customer.label;
    }

    // these functions retrieve products and customers via ajax
    // for the typeahead field
    function getAsyncProduct(viewValue) {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };
      params['filter[]'] = ['label,LIKE ' + viewValue];
      params['filter[]'].push('mostRecent,TRUE');
      params['filter[]'].push('OR,hidden,FALSE,hidden,NULL');
      params['filter[]'].push('OR,blueprint,FALSE,blueprint,NULL');
      params.extendedFilter = 'withoutPrice';

      return ProductFactory.getList(params)
        .then(function (resultProducts) {
          return resultProducts.data;
        });
    }

    function loadPosSessions() {
      var params = {
        limit: 99,
        sort: 'created_at,DESC'
      };

      params['filter[]'] = [];
      params['filter[]'].push('endedAt,NULL');

      vm.posSessions = [];

      return PosSessionFactory.getList(params)
        .then(function (result) {
          $timeout(function () {
            if (vm.posInstanceAdministrativePropertyEnabled) {
              angular.forEach(result, function (session) {
                if (session.pointOfSaleInstance.administrative) {
                  vm.posSessions.push(session);
                }
              });
            } else {
              vm.posSessions = result;
            }
            vm.posSessions.sort(function (a, b) {
              var labelA = a.userContext.user.username + ' - ' + a.pointOfSaleInstance.label,
                  labelB = b.userContext.user.username + ' - ' + b.pointOfSaleInstance.label;
              return (labelA <= labelB) ? -1 : 1;
            });
          });
        });
    }

    function savePaymentMethod(payment) {
      if (vm.isEdit()) {
        SaleFactory.one(vm.currentSale.id).one('payments').one(payment.id)
          .patch({
            paymentMethod: payment.paymentMethod.id
          });
      }
    }

    function removeProduct(product) {
      var productIndex;
      if (product.isGroupProduct) {
        product.subProducts.forEach(function (p) {
          vm.removeProduct(p);
        });
      }
      productIndex = vm.getInvoiceProductIndex(product.id);
      // if the product exists in the backend, add it to the list of deleted items
      if (vm.saleProducts[productIndex].hasOwnProperty('saleItemId')) {
        vm.deletedSaleProducts.push(vm.saleProducts[productIndex]);
      }
      vm.saleProducts.splice(productIndex, 1);
      vm.recountTotals();
    }

    // add the currently selected product to the list of sale products
    function addSelectedProduct(selectedProduct) {
      var i, deletedProduct, product, prIndex, subProduct;
      if (angular.isUndefined(selectedProduct) || selectedProduct === null) {
        selectedProduct = vm.selectedProduct;
      } else {
        selectedProduct.isSubProduct = true;
      }
      if (selectedProduct !== null && selectedProduct.hasOwnProperty('id')) {
        // retrieve the product components from the backend when adding the product
        // to the list so we can easily access the price
        ProductFactory.one(selectedProduct.id).get().then(function (p) {
          product = p.data;
          for (i = 0; i < product.productComponents.length; ++i) {
            if (product.productComponents[i].type === 'price') {
              selectedProduct.price = product.productComponents[i];
            }
            if (product.productComponents[i].type === 'products') {
              selectedProduct.isGroupProduct = true;
              selectedProduct.subProducts = [];
              for (prIndex = 0; prIndex < product.productComponents[i].products.length; ++prIndex) {
                subProduct = product.productComponents[i].products[prIndex];
                vm.addSelectedProduct(subProduct);
                selectedProduct.subProducts.push(subProduct);
                subProduct.parentSaleItem = selectedProduct;
              }
            }
          }
          selectedProduct.price.priceIncl = ProductUtilService.calculateInclPrice(selectedProduct.price.price, selectedProduct.price.vatRate.percentage).inclPrice;
          selectedProduct.amount = 1;
          selectedProduct.discountPercentage = 0;
          selectedProduct.previousDiscountPercentage = 0;
          selectedProduct.price.originalPrice = selectedProduct.price.price;
          selectedProduct.price.originalPriceIncl = selectedProduct.price.priceIncl;
          vm.saleProducts.push(selectedProduct);

          // check if we previously deleted the product, if so - 'undelete' it!
          for (i = 0; i < vm.deletedSaleProducts.length; ++i) {
            deletedProduct = vm.deletedSaleProducts[i];
            if (deletedProduct.id === selectedProduct.id) {
              vm.deletedSaleProducts.splice(i, 1);
            }
          }
          vm.recountTotals();
          vm.selectedProduct = null;
        });
      }
    }

    function getInvoiceProductIndex(productId) {
      var cp, i, foundIndex = -1;
      for (i = 0; i < vm.saleProducts.length; ++i) {
        cp = vm.saleProducts[i];
        if (cp.id === productId) {
          foundIndex = i;
          break;
        }
      }
      return foundIndex;
    }

    // This function is meant to prevent the following scenario:
    // 1: User fills in completedAt date
    // 2: Checkbox 'finalize immediately' becomes enabled
    // 3: User checks 'finalize immediately'
    // 4: User removes completedAt date
    // 5: Checkbox becomes disabled but still checked
    // 6: User saves without a completedAt date
    // 7: A completed sale is generated without said date
    // 8: Bert Vandaele spends two hours trying to figure out why data is missing from some report
    function checkIfFinalizeIsPossible() {
      if (UtilService.isEmpty(vm.completedAtDate)) {
        vm.finalizeAfterSubmit = false;
      }
    }

    function checkCompletedAtDateCallback() {
      var isInOpenedPeriod = false;

      if (vm.isAccountancyPeriodsCheckingEnabled() && !UtilService.isEmpty(vm.completedAtDate)) {
        vm.completedAtDate.setHours(0, 0, 0);
        vm.completedAtDate = new Date(vm.completedAtDate.getTime() - (vm.completedAtDate.getTimezoneOffset() * 60000));
        AccountancyPeriodFactory.getOpenedForDate(vm.loggedInUserSiteId, vm.completedAtDate).then(function (result) {
          if (result.length > 0) {
            isInOpenedPeriod = true;
          }

          if (!isInOpenedPeriod) {
            vm.completedAtDate = null;
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'error',
              'app.error',
              '',
              'sale.date_wrong_period_closed'
            );
          }

          checkIfFinalizeIsPossible();
        });
      }
    }

    // create a sale, save all sale items
    // and generate a draft invoice afterwards
    function startSaveSequence() {
      LogService.log('Sale save sequence started', 'debug');
      return vm.persistSale()
        .then(function () {
          LogService.log('sale persisted', 'debug');
          return vm.scrubOldSaleItems();
        })
        .then(function () {
          LogService.log('Old sale items scrubbed', 'debug');
          if (vm.saleProducts.length === 0) {
            LogService.log('no sale items to persist - closing sale modal', 'debug');
            $modalInstance.close('reloadSaleListData');
          } else {
            // persist sale items and generate a draft invoice
            return vm.persistSaleItems();
          }
        });
    }

    function showCustomerListModal() {
      UtilService.showModal({
        templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
        controller: 'HwProxyMemberCardDataCtrl',
        controllerAs: 'HwProxyMemberCardDataCtrl',
        size: 'lg',
        resolve: {
          memberCardData: function () {
            return null;
          },
          customer: function () {
            return null;
          },
          customerContact: function () {
            return null;
          }
        }
      }, null, null, true);
    }

    function persistSale() {
      var isNewSale = !vm.isEdit();
      return vm.getSaleRestObject(isNewSale).then(function (saleRestObject) {
        if (!isNewSale) {
          return SaleFactory.one(vm.currentSale.id).patch(saleRestObject);
        }
        saleRestObject.invoiceRequested = true;
        return SaleFactory.one('1').customPOST(saleRestObject).then(function (postedSale) {
          vm.currentSale = postedSale;
        });
      });
    }

    function scrubOldSaleItems() {
      return Promise.all(vm.deletedSaleProducts.map(function (product) {
        return SaleFactory.one(vm.currentSale.id).one('items').one(product.saleItemId).one('1').remove();
      }));
    }

    /*
     * Order of sale items in the create sale form matters to the client and it has to be the same on the invoice.
     * Sale items on the invoice are ordered by creation time in backend.
     * We could introduce some order property to the backend sale item entity, but we don't like that.
     * So instead we persists all items sequentially using a promise chain.
     */
    function persistSaleItems() {
      var promiseChain = $q.resolve();

      // persist the sale items sequentially
      LogService.log('Preparing to persist sale items', 'debug');
      vm.saleProducts.forEach(function (saleItem) {
        promiseChain = promiseChain.then(function () {
          var saleItemRestObject = vm.getSaleItemRestObject(saleItem);

          if (saleItem.hasOwnProperty('saleItemId')) {
            return SaleFactory.one(vm.currentSale.id)
              .one('items').one(saleItem.saleItemId)
              // do not call external cart API
              .one('1')
              .patch(saleItemRestObject);
          }
          return SaleFactory.one(vm.currentSale.id)
            .one('items')
            // do not call external cart API
            .one('1')
            .customPOST(saleItemRestObject)
            .then(function (result) {
              saleItem.saleItemId = result.id;
            });
        });
      });

      // finalize sale after all items have been persisted
      promiseChain = promiseChain.then(function () {
        LogService.log('Sale items persisted.', 'debug');
        if (!vm.finalizeAfterSubmit || !UtilService.isNotEmpty(vm.completedAtDate)) {
          $modalInstance.close('reloadSaleListData');
          return;
        }

        SaleStatusFactory.getStatusByCode('completed').then(function (completedStatus) {
          var patchObject = {saleStatus: completedStatus.id};
          LogService.log('finalize immediately enabled, finalizing sale', 'debug');
          SaleFactory.one(vm.currentSale.id).patch(patchObject).then(function () {
            if (vm.finalizeAfterSubmit) {
              LogService.log('Creating invoice.', 'debug');
              vm.persistInvoice();
            }
          });
        });
      });
      return promiseChain;
    }

    function persistInvoice() {
      var params = {};
      params.includedSales = [vm.currentSale.id];
      Restangular.all('services/sales_to_invoices').customPOST(params).then(function () {
        $modalInstance.close('reloadSalesListData');
      });
    }

    // get a sale rest object
    function getSaleRestObject(includeSiteId) {
      var restObject = {
            facility: vm.currentFacilityId
          },
          saleStatusCode = 'in_progress';

      if (UtilService.isNotEmpty(vm.completedAtDate)) {
        restObject.completedAt = vm.completedAtDate;
        saleStatusCode = 'completed';
      }

      // pos sale -> keep sale status
      if (vm.isEdit() && vm.isPosSale(vm.currentSale)) {
        // restObject.saleStatus = vm.saleStatuses[vm.currentSale.saleStatus.code];
        saleStatusCode = vm.currentSale.saleStatus.code;
      }

      if (vm.saleCustomer !== null && angular.isDefined(vm.saleCustomer)) {
        restObject.customer = vm.saleCustomer.id;
      }

      if (includeSiteId) {
        restObject.site = vm.loggedInUserSiteId;
      }

      if (vm.selectedPos !== null && angular.isDefined(vm.selectedPos)) {
        restObject.pointOfSaleSession = vm.selectedPos.id;
      }

      restObject.invoiceAlreadyPaid = vm.invoiceAlreadyPaid;

      return SaleStatusFactory.getStatusByCode(saleStatusCode).then(function (saleStatus) {
        restObject.saleStatus = saleStatus.id;
        return restObject;
      });
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function isAccountancySale() {
      if (vm.isEdit()) {
        return !vm.currentSale.hasOwnProperty('pointOfSaleSession');
      }
      // we can only do add in accountancy anyway
      return true;
    }

    // get a sale item rest object
    function getSaleItemRestObject(ip) {
      var restObject;
      // add price overriding, discount refs later
      restObject = {
        product: ip.id,
        quantity: ip.amount,
        // unit price for 1 product, excl VAT
        price: ip.price.price,
        comments: ip.comments,
        internalComments: ip.internalComments,
        discount: ip.discount || null
      };

      restObject.parameters = ip.parametersObject ? ip.parametersObject : {};
      restObject.parameters.discount = ip.discount !== null;
      restObject.parameters.discountPercentage = ip.discountPercentage || null;
      restObject.parameters.discountAmount = ip.discountAmount || null;
      restObject.parameters.discountType = ip.discountType || null;
      if (ip.isGroupProduct) {
        restObject.parameters.isGroupProduct = true;
        restObject.price = 0;
      } else {
        restObject.parameters.isGroupProduct = false;
      }
      if (ip.parentSaleItem) {
        restObject.parameters.parentSaleItem = ip.parentSaleItem.saleItemId;
        restObject.quantity = ip.parentSaleItem.amount;
      }

      return restObject;
    }

    function isEdit() {
      return vm.currentSale !== null;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function discountPercentageChanged() {
      angular.forEach(vm.saleProducts, function (product) {
        if (product.discountPercentage !== product.previousDiscountPercentage) {
          if (!product.discount) {
            DiscountFactory.post({
              percentage: product.discountPercentage,
              site: vm.loggedInUserSiteId
            }).then(function (discountObject) {
              product.discountObject = discountObject;
              product.discount = discountObject.id;
            });
          } else {
            DiscountFactory.one(product.discount).patch({
              percentage: product.discountPercentage,
              price: null
            });
          }
          product.discountType = DiscountFactory.getPercentageDiscountType();
          if (product.subProducts) {
            if (product.discountPercentage !== product.previousDiscountPercentage) {
              angular.forEach(product.subProducts, function (subProduct) {
                subProduct.discountPercentage = product.discountPercentage;
                subProduct.discountType = DiscountFactory.getPercentageDiscountType();
              });
            }
          }
          product.previousDiscountPercentage = product.discountPercentage;
        }
      });
      vm.recountTotals();
    }

    function recountTotals() {
      var quantity;
      vm.totalPrice = 0;
      vm.totalPriceExclVAT = 0;
      angular.forEach(vm.saleProducts, function (product) {
        //we dont want to count root-products
        if (!product.isGroupProduct) {
          if (product.isSubProduct) {
            quantity = product.amount * product.parentSaleItem.amount;
          } else {
            quantity = product.amount;
          }
          if (angular.isUndefined(product.price.priceIncl)) {
            product.price.priceIncl = vm.ProductUtilService.calculateInclPrice(product.price.price, product.price.vatRate.percentage).inclPrice;
          }
          vm.totalPrice += vm.ProductUtilService.calculateTotalPrice(product.price.priceIncl, quantity, product.discountPercentage);
          vm.totalPriceExclVAT += vm.ProductUtilService.calculateTotalPrice(product.price.price, quantity, product.discountPercentage);
        }
      });
    }

    function setPriceToProduct(product) {
      var correctPrice, parentPrice;
      correctPrice = ProductUtilService.calculateExclPrice(product.price.priceIncl, product.price.vatRate.percentage);
      product.price.price = correctPrice.exclPrice;
      //if price of sub-product is changed, propagate it to root-product
      if (product.isSubProduct) {
        parentPrice = 0;
        product.parentSaleItem.subProducts.forEach(function (subProduct) {
          parentPrice += subProduct.price.priceIncl;
        });
        product.parentSaleItem.price.price = parentPrice;
      }
      vm.recountTotals();
    }
  }
}());
