(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerCtrl', CustomerCtrl);

  function CustomerCtrl(
      ContactFactory,
      ContactDataTypeFactory,
      CurrentUserContextFactory,
      CustomerFactory,
      MemberCardFactory,
      SubscriptionContractFactory,
      JournalManagementFactory,
      SwimCourseManagementFactory,
      $compile,
      SaleFactory,
      ServiceFactory,
      CustomerLogsFactory,
      DirectDebitFactory,
      $filter,
      LogService,
      $scope,
      $state,
      $stateParams,
      $templateRequest,
      $timeout,
      $window,
      _,
      $localStorage,
      EventParticipantFactory,
      RestUtilService,
      Restangular,
      OAuthToken,
      AttachmentUtilService,
      SettingsService,
      UtilService,
      moment,
      PermissionsUtilService,
      SavingsCampaignFactory,
      ToastrNotificationService,
      SavingsCampaignSubscriptionsFactory,
      PARAMS
    ) {
    var vm = this,
        contactPhotoDataTypeValue = 'PHOTO';

    vm.reloadPageData = reloadPageData;
    vm.reloadListData = reloadListData;
    vm.getDetails = getDetails;
    vm.showModalView = showModalView;
    vm.showSubscriptionEditModalView = showSubscriptionEditModalView;
    vm.showModalAddEditView = showModalAddEditView;
    vm.showContractTerminateView = showContractTerminateView;
    vm.showModalDeleteView = showModalDeleteView;
    vm.manageJournalSuspension = manageJournalSuspension;
    vm.showJournalEditModal = showJournalEditModal;
    vm.viewHistory = viewHistory;
    vm.handleModalReturnValue = returnOfModalInstance;
    vm.PermissionsUtilService = PermissionsUtilService;
    vm.showSwimCourseSubscriptionCommentModal = showSwimCourseSubscriptionCommentModal;
    vm.isPerson = isPerson;
    vm.contacts = [];
    vm.memberCards = [];
    vm.directDebit = [];
    vm.subscriptionContracts = [];
    vm.customerSearchQuery = '';
    vm.searchCustomer = searchCustomer;
    vm.contactSearchQuery = '';
    vm.searchContact = searchContact;
    vm.memberCardSearchQuery = '';
    vm.searchMemberCard = searchMemberCard;
    vm.deactivateContract = deactivateContract;
    vm.isGranted = isGranted;
    vm.getJournals = getJournals;
    vm.participationHistory = [];
    vm.downloadHistory = downloadHistory;
    vm.reloadCustomerBalanceEntries = reloadCustomerBalanceEntries;
    vm.balanceEntries = [];
    vm.balanceEntriesTotal = 0;
    vm.isEntryCounted = isEntryCounted;
    vm.reloadSwimschoolSubscriptions = reloadSwimschoolSubscriptions;
    vm.assignedSubscriptions = [];
    vm.getWeekday = getWeekday;
    vm.getTime = getTime;
    vm.waitlist = [];
    vm.redirect = redirect;
    vm.isSubscriptionsEmpty = isSubscriptionsEmpty;
    vm.isWaitlistEmpty = isWaitlistEmpty;
    vm.showNewButton = !SettingsService.get('customers.hideNewButton', false);
    vm.showMemberCards = !SettingsService.get('customers.hideMemberCards', false);
    vm.hidePhoto = SettingsService.get('customers.hidePhoto', false);
    vm.hideExternalDebtorNumber = SettingsService.get('customers.hideExternalDebtorNumber', false);
    vm.enableAccountancy = !SettingsService.get('settings.disableAccountancy', false);
    vm.customer = null;
    vm.savingsCampaigns = [];
    vm.savingsCampaignSubscriptions = [];
    vm.reloadSavingsCampaigns = reloadSavingsCampaigns;
    vm.reloadSavingsCampaignSubscriptions = reloadSavingsCampaignSubscriptions;
    vm.showSavingsCampaign = SettingsService.get('customer.showSavingsCampaign', false);
    vm.showSavingsCampaignSubscriptionsRegistrationsView = showSavingsCampaignSubscriptionsRegistrationsView;
    vm.showSavingsCampaignSubscriptionsRegistrationsAddEditModal = showSavingsCampaignSubscriptionsRegistrationsAddEditModal;
    vm.showSavingsCampaignSubscriptionsAddEditModal = showSavingsCampaignSubscriptionsAddEditModal;
    vm.deleteCustomerBalance = deleteCustomerBalance;
    vm.closeSavingsCmpaignSubscription = closeSavingsCmpaignSubscription;
    vm.activeTab = 'customer';
    vm.newDesignVersion = PARAMS.designVersion === 2 ? true : false;
    vm.salePagination = {
      totalItems: 0,
      currentPage: 1,
      itemsPerPage: 25
    };

    vm.logsPagination = {
      totalItems: 0,
      currentPage: 1,
      itemsPerPage: 25
    };

    vm.showModalCustomerBalanceDeleteView = function (customerBalance) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'customer.balance', 'app.delete-msg', 'app.this_customer_balance',
        function (returnValue) {
          if (returnValue) {
            vm.deleteCustomerBalance(customerBalance);
          }
        });
    };

    vm.showCloseSubscriptionModal = function (subscription) {
      UtilService.showFormattedConfirmationModal('app.close_item', 'app.savings_campaign_subscription', 'app.close-msg', 'app.this_savings_campaign_subscription',
        function (returnValue) {
          if (returnValue) {
            vm.closeSavingsCmpaignSubscription(subscription);
          }
        });
    };

    $scope.$on('refreshJournals', function () {
      vm.getJournals();
    });

    $scope.$on('refreshCustomerDetailLabel', function (e, label) {
      if (vm.customer) {
        vm.customer.label = label;
      }
    });

    function reloadCustomerBalanceEntries() {
      vm.balanceEntries = [];
      CustomerFactory.getCustomerBalanceEntries(vm.customer.id)
      .then(function (balanceEntries) {
        vm.balanceEntries = balanceEntries;
      });

      CustomerFactory.getCustomerBalance(vm.customer.id)
      .then(function (balance) {
        vm.balanceEntriesTotal = balance.EUR;
      });
    }

    function isEntryCounted(entry) {
      return moment(entry.startDate).format() > moment(new Date()).format();
    }

    vm.exportCustomer = function (customer) {
      $window.open(
        Restangular.configuration.baseUrl + '/customers/export/' + customer.id +
          '?access_token=' + OAuthToken.getAccessToken()
      );
    };

    vm.reloadDirectDebits = function () {
      var expDate = null,
          today = new Date();
      vm.directDebit = [];
      DirectDebitFactory.getList({'filter[]': 'customer.id,' + vm.customer.id, sort: 'createdAt,DESC'})
      .then(function (data) {
        angular.forEach(data, function (ddc) {
          if (ddc.hasOwnProperty('termination')) {
            expDate = new Date(ddc.termination);
            expDate.setHours(0, 0, 0, 0);
            if (expDate <= today) {
              ddc.terminated = true;
            }
          }
          vm.directDebit.push(ddc);
        });
      });
    };

    vm.reloadEvents = function () {
      var parameters = {
        limit: 99,
        view: 'table'
      };
      vm.participationHistory = [];
      parameters['filter[]'] = [];
      if (UtilService.isNotEmpty(vm.contact)) {
        parameters['filter[]'].push('contact.id,' + vm.contact.contact.id);
        RestUtilService.getFullList(EventParticipantFactory, parameters).then(function (participationHistory) {
          vm.participationHistory = participationHistory;
        });
      }
      //parameters['filter[]'].push('eventParticipantStatus.code,ATTENDED');
    };

    vm.reloadSales = function () {
      var params = {
        offset: (vm.salePagination.currentPage - 1) * vm.salePagination.itemsPerPage,
        limit: vm.salePagination.itemsPerPage,
        sort: 'createdAt,DESC',
        'filter[]': [
          'customer.id,' + vm.customer.id
        ],
        displayAllSalesForCurrentUserByHisSites: true
      };

      SaleFactory.getList(params).then(function (results) {
        vm.salePagination.totalItems = results.count;
        vm.history.sales = results;
      });
    };

    vm.reloadLogs = function () {
      // var params = {
      //   id: vm.customer.id
      // };

      CustomerLogsFactory.getCustomerLog(vm.customer.id)
      .then(function (results) {
        vm.history.logs = results;
      });
      // CustomerLogsFactory.getList(params)
      // .then(function (results) {
      //   vm.logsPagination.totalItems = results.count;
      //   vm.history.logs = results;
      // });
    };

    vm.reloadSubscriptionContracts = function () {
      vm.subscriptionContracts = [];
      SubscriptionContractFactory.getList({'filter[]': 'customer.id,' + vm.customer.id, limit: 99}).then(function (resultContracts) {
        angular.forEach(resultContracts, function (contract) {
          SubscriptionContractFactory.one(contract.id).get().then(function (resultContract) {
            vm.subscriptionContracts.push(resultContract);
            resultContract.vatPercentage = resultContract.product.productComponents.filter(function (pc) {
              return pc.type === 'price';
            })[0].vatRate.percentage;
            resultContract.monthlyVatAmount = Math.round((((resultContract.price / (resultContract.minimumDuration / resultContract.collectionInterval)) * resultContract.vatPercentage) / 100) * 100) / 100;
            resultContract.exclMonthlyPrice = Math.round((resultContract.price / (resultContract.minimumDuration / resultContract.collectionInterval)) * 100) / 100;
            resultContract.monthlyPrice = resultContract.exclMonthlyPrice + resultContract.monthlyVatAmount;
            resultContract.inclPrice = Math.round(resultContract.price * (Math.round((resultContract.vatPercentage / 100 + 1) * 100) / 100) * 100) / 100;
          });
        });
      });
    };

    vm.showLogEntryModal = function (entry) {
      UtilService.showModal({
        templateUrl: 'customer/views/detail/modal/customer.detail.modal.logs.tpl.html',
        controller: 'CustomerLogDetailCtrl',
        controllerAs: 'CustomerLogDetailCtrl',
        resolve: {
          entry: function () {
            return entry;
          }
        }
      }, null, null, true);
    };

    vm.showAddJournalModal = function () {
      UtilService.showModal({
        templateUrl: 'journal/views/customer.journal.modal.add.tpl.html',
        controller: 'CustomerJournalAddCtrl',
        controllerAs: 'customerJournalAddCtrl',
        resolve: {
          customerId: function () {
            return vm.customer.id;
          }
        }
      }, function () {
        vm.getJournals();
        vm.reloadPageData(vm.customer.id);
      }, null, true);
    };

    vm.reloadMembercards = function () {
      var expDate = null,
          today = new Date();
      vm.memberCards = [];
      LogService.log('reloading membercards', 'debug');
      // Loop all the memberCards of a customer
      RestUtilService.getFullList(MemberCardFactory, {limit: 99, 'filter[]': 'customer.id,' + vm.customer.id}).then(function (customerMembercards) {
        angular.forEach(customerMembercards, function (completeMemberCard) {
          LogService.log('membercard Details: ' + completeMemberCard, 'debug');
          LogService.log('Today: ' + today, 'debug');
          if (completeMemberCard.hasOwnProperty('expirationDate')) {
            expDate = new Date(completeMemberCard.expirationDate).setHours(0, 0, 0, 0);
            if (expDate <= today) {
              completeMemberCard.expired = true;
            }
          }
          vm.memberCards.push(completeMemberCard);
        });
      });
    };

    vm.reloadSettings = function () {
      var params = {
        'filter[]': [
          'value,' + vm.customer.id
        ]
      };

      vm.customer.defaults = [];
      vm.customer.isDefault = false;

      SettingsService.getAll('LIKE accountancy.', params).then(function (results) {
        angular.forEach(results, function (result) {
          if (vm.settings.hasOwnProperty(result.code)) {
            vm.customer.defaults[vm.settings[result.code]] = true;
            vm.customer.isDefault = true;
          }
        });
      });
    };

    vm.settings = {
      'accountancy.cashSaleCustomerId': 'cashSale'
    };

    vm.makeDefault = function (type, entity) {
      if (entity.defaults && entity.defaults[type]) {
        return;
      }

      UtilService.showTranslatedConfirmationModal('app.cash-sale-set-customer', 'app.cash-sale-set-customer-extended', function (returnValue) {
        if (returnValue) {
          _.filter(vm.settings, function (value, key) {
            if (type === value) {
              SettingsService.set(key, entity.id, CurrentUserContextFactory.getSiteId())
              .then(function () {
                UtilService.reloadState();
              });
            }
          });
        }
      });
    };

    // Check the state of the customer page
    if ($state.is('customerdetail')) {
      // Reload page data
      // comment
      vm.reloadPageData($stateParams.id);
      if ($stateParams.action === 'eid') {
        vm.shouldOpenEditModal = true;
      } else {
        vm.shouldOpenEditModal = false;
      }
    } else {
      // Reload List Data
      vm.reloadListData();
    }

    function showSubscriptionEditModalView(type, viewObject) {
      UtilService.showModal({
        templateUrl: 'subscription-contract/views/subscription-contract.modal.edit.tpl.html',
        controller: 'SubscriptionContractEditCtrl',
        controllerAs: 'subscriptionContractEditCtrl',
        resolve: {
          subscriptionContract: function () {
            return viewObject;
          }
        }
      }, vm.reloadSubscriptionContracts);
    }

    function showModalView(type, viewObject) {
      var modalOptions = {};

      // Check the type of the sended Object and open modal view with template and controller and add memberCard to that controller
      switch (type) {
        case 'memberCard':
          modalOptions = {
            templateUrl: 'member-card/views/member-card.modal.view.tpl.html',
            controller: 'MemberCardCtrl',
            controllerAs: 'memberCardCtrl',
            resolve: {
              memberCard: function () {
                return viewObject;
              }
            }
          };
          break;
        case 'sale':
          modalOptions = {
            templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
            controller: 'SaleViewCtrl',
            controllerAs: 'saleViewCtrl',
            resolve: {
              sale: function () {
                return viewObject;
              }
            }
          };
          break;
        default:
          LogService.log('Unknown showModalAddView type ' + type, 'debug');
      }

      if (UtilService.isNotEmpty(modalOptions)) {
        UtilService.showModal(modalOptions, vm.handleModalReturnValue);
      }
    }

    function showContractTerminateView(type, viewObject) {
      UtilService.showModal({
        templateUrl: 'subscription-contract/views/subscription-contract.modal.terminate.tpl.html',
        controller: 'SubscriptionContractTerminateCtrl',
        controllerAs: 'subscriptionContractTerminateCtrl',
        resolve: {
          subscriptionContract: function () {
            return viewObject;
          }
        }
      }, vm.reloadSubscriptionContracts);
    }

    function showModalAddEditView(type, customer, currentObject) {
      var modalOptions = {};

      // Check the type of the sended Object and open modal view with template and controller and add objects to that controller
      switch (type) {
        case 'memberCard':
          modalOptions = {
            templateUrl: 'member-card/views/member-card.modal.addedit.tpl.html',
            controller: 'MemberCardAddEditCtrl',
            controllerAs: 'memberCardAddEditCtrl',
            resolve: {
              customer: function () {
                return customer;
              },
              currentObject: function () {
                return currentObject;
              }
            }
          };
          break;

        case 'customerList':
        case 'customer':
          modalOptions = {
            templateUrl: 'customer/views/customer.modal.addedit.tpl.html',
            controller: 'CustomerAddEditCtrl',
            controllerAs: 'customerAddEditCtrl',
            resolve: {
              customer: function () {
                return customer;
              },
              type: function () {
                return type;
              }
            }
          };
          break;

        case 'contact':
          modalOptions = {
            templateUrl: 'contact/views/contact.modal.addedit.tpl.html',
            controller: 'ContactAddEditCtrl',
            controllerAs: 'contactAddEditCtrl',
            size: 'lg',
            resolve: {
              customer: function () {
                return customer;
              },
              customerContact: function () {
                return currentObject;
              }
            }
          };
          break;
        case 'directDebit':
          modalOptions = {
            templateUrl: 'customer/views/customer.contract.modal.new.tpl.html',
            controller: 'DirectDebitContractCtrl',
            controllerAs: 'directDebitContractCtrl',
            resolve: {
              customer: function () {
                return vm.customer;
              },
              contracts: function () {
                return vm.directDebit;
              }
            }
          };
          break;
        case 'accountancy':
          modalOptions = {
            templateUrl: 'customer/views/customer.accountancy.modal.edit.tpl.html',
            controller: 'CustomerAccountancyCtrl',
            controllerAs: 'customerAccountancyCtrl',
            resolve: {
              customer: function () {
                return vm.customer;
              }
            }
          };
          break;
        case 'balance':
          modalOptions = {
            templateUrl: 'customer/views/detail/balance.addedit.modal.tpl.html',
            controller: 'CustomerAddEditBalanceCtrl',
            controllerAs: 'customerAddEditBalanceCtrl',
            // size: 'sm',
            resolve: {
              customer: function () {
                return customer;
              },
              balance: function () {
                return currentObject;
              }
            }
          };
          break;
        default:
          LogService.log('Unknown showModalAddView type ' + type, 'debug');
      }

      // if the modal vars were filled in, show the modal
      if (UtilService.isNotEmpty(modalOptions)) {
        UtilService.showModal(modalOptions, vm.handleModalReturnValue);
      }
    }

    function showModalDeleteView(type, viewObject) {
      var modalOptions = {};
      // Check if modal view is open
      // if (opened) {
      //   return;
      // }
      // Check the type of the sended Object and open modal view with template and controller and add objects to that controller
      switch (type) {
        case 'memberCard':
          modalOptions = {
            templateUrl: 'member-card/views/member-card.modal.deactivate.tpl.html',
            controller: 'MemberCardDeactivateCtrl',
            controllerAs: 'memberCardDeactivateCtrl',
            resolve: {
              memberCard: function () {
                return viewObject;
              }
            }
          };
          break;

        case 'customerList':
        case 'customer':
          modalOptions = {
            templateUrl: 'customer/views/customer.modal.delete.tpl.html',
            controller: 'CustomerDeleteCtrl',
            controllerAs: 'customerDeleteCtrl',
            resolve: {
              customer: function () {
                return viewObject;
              }
            }
          };
          break;
        case 'contact':
          modalOptions = {
            templateUrl: 'contact/views/contact.modal.delete.tpl.html',
            controller: 'CustomerContactDeleteCtrl',
            controllerAs: 'customerContactDeleteCtrl',
            resolve: {
              customer: function () {
                return vm.customer;
              },
              contact: function () {
                return viewObject;
              }
            }
          };
          break;
        default:
          LogService.log('Unknown showModalAddView type ' + type, 'debug');
      }

      if (UtilService.isNotEmpty(modalOptions)) {
        UtilService.showModal(modalOptions, vm.handleModalReturnValue);
      }
    }

    function returnOfModalInstance(returnValue) {
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'reloadPageData':
          vm.reloadPageData(vm.customer.id);
          break;

        case 'reloadListData':
          vm.reloadListData();
          break;

        case 'reloadMembercards':
          vm.reloadMembercards();
          break;

        case 'reloadSales':
          vm.reloadSales();
          break;

        case 'reloadJournals':
          vm.getJournals();
          break;

        case 'reloadDirectDebits':
          vm.reloadDirectDebits();
          break;

        case 'reloadCustomerBalances':
          vm.reloadCustomerBalanceEntries();
          break;

        case 'reloadLogs':
          vm.reloadLogs();
          break;

        case 'reloadSwimschoolSubscriptions':
          vm.reloadSwimschoolSubscriptions();
          break;

        default:
          break;
      }
    }

    function reloadPageData(customerId) {
      vm.history = {
        sales: [],
        logs: []
      };
      // Get the contact data types
      ContactDataTypeFactory.getList({limit: 99}).then(function (resultContactDataTypes) {
        // Filter the contactDatatypes and select the Photo-dataType
        vm.photoDataType = $filter('filter')(resultContactDataTypes, function (cdt) {
          return cdt.code === contactPhotoDataTypeValue;
        })[0];
      });
      // Get one specific customer
      CustomerFactory.one(customerId).get().then(function (customer) {
        vm.customer = customer;
        vm.contactViewNeedsReload = true;
        vm.reloadSettings();

        // Check if the customer is kind of 'Person'
        if (vm.isPerson()) {
          vm.contact = $filter('filter')(customer.customerContacts, function (cc) {
            return cc.customerContactType.code === 'USER';
          })[0];
          // If customer is a 'Person' the contact-detail template is loaded
          $templateRequest('contact/views/contact.detail.tpl.html').then(function (html) {
            $timeout(function () {
              var tpl = $compile(html)($scope);
              angular.element('#contact-container-view').contents().remove();
              angular.element('#contact-container-view').append(tpl);
            });
          });
        } else {
          // If customer is not a 'Person' the contacts-list template is loaded
          $templateRequest('contact/views/contact.list.tpl.html').then(function (html) {
            $timeout(function () {
              var tpl = $compile(html)($scope);
              angular.element('#contact-container-view').contents().remove();
              angular.element('#contact-container-view').append(tpl);
            });
          });
        }
        vm.billingContact = customer && customer.customerContacts ? $filter('filter')(customer.customerContacts, function (cc) {
          return cc.customerContactType.code === 'BILLING';
        })[0] : null;

        // load the full data for the billingContact and retrieve the iban number
        if (UtilService.isNotEmpty(vm.billingContact)) {
          ContactFactory.one(vm.billingContact.contact.id)
          .get()
          .then(function (billingContact) {
            vm.billingContact = billingContact;
            vm.billingContactIban = _.find(billingContact.contactData, function (contactData) {
              return contactData.contactDataType.code === 'BANK_ACCOUNT_NUMBER';
            });
          });
        }

        $templateRequest('contact/views/contact.billing-detail.tpl.html').then(function (html) {
          var tpl = $compile(html)($scope);
          angular.element('#billing-contact-view').contents().remove();
          angular.element('#billing-contact-view').append(tpl);
        });

        if (vm.shouldOpenEditModal) {
          vm.shouldOpenEditModal = false;
          ContactFactory.one(vm.contact.contact.id).get().then(function (completeContact) {
            vm.contact.completeContact = completeContact;
            vm.showModalAddEditView('contact', vm.customer, vm.contact);
          });
        }
      });
    }

    function reloadListData() {
      vm.customers = [];
      /*
      // Get all Customers and add it to the customers-array (sorted on 'label' ASC)
      RestUtilService.getFullList(CustomerFactory, {sort: 'label,ASC'}).then(function (resultCustomers) {
        vm.customers = resultCustomers;
      }, function (errorMsg) {
        LogService.log('error: ' + errorMsg, 'debug');
      });
      */
    }

    function getDetails(type, customerId) {
      // Go to the detail page of a customer
      $state.go('customerdetail', {id: customerId}, {reload: true});
    }

    function isPerson() {
      // Check if customer is of type Person
      return vm.customer && vm.customer.customerType && vm.customer.customerType.code === 'PERSON';
    }

    // these functions are used to filter customers/contacts/etc based only on certain fields
    function searchCustomer(item) {
      var lcQuery = vm.customerSearchQuery.toLowerCase();
      // Check if isEmpty, indexOf is -1 if not found
      if (lcQuery.length === 0 ||
          item.label.toLowerCase().indexOf(lcQuery) !== -1) {
        return true;
      }
      return false;
    }

    function searchContact(item) {
      var lcQuery = vm.contactSearchQuery.toLowerCase();
      // Check if isEmpty, indexOf is -1 if not found
      if (lcQuery.length === 0 ||
          item.contact.label.toLowerCase().indexOf(lcQuery) !== -1 ||
          item.customerContactType.label.toLowerCase().indexOf(lcQuery) !== -1) {
        return true;
      }
      return false;
    }

    function searchMemberCard(item) {
      var lcQuery = vm.memberCardSearchQuery.toLowerCase();
      // Check if isEmpty, indexOf is -1 if not found
      if (lcQuery.length === 0 || item.label.toLowerCase().indexOf(lcQuery) !== -1 ||
          item.memberCardType.label.toLowerCase().indexOf(lcQuery) !== -1 ||
          item.site.label.toLowerCase().indexOf(lcQuery) !== -1) {
        return true;
      }
      return false;
    }

    function isGranted(role) {
      var index, roles;
      roles = $localStorage.currentUserPermissions;
      index = _.indexOf(roles, role);
      return index + 1;
    }

    function deactivateContract(item) {
      var today = (new Date()).toISOString().substr(0, 10);

      item.patch({
        termination: today
      }).then(function () {
        vm.reloadPageData(vm.customer.id);
      });
    }

    function getJournals() {
      var journalParams = {
        limit: 99
      };
      if (vm.customer) {
        // load the journals, the journal management factory will provide some extra information for us
        // such as validity of the journal and a boolean indicating whether it is pristine
        journalParams['filter[]'] = [
          'customer.id,' + vm.customer.id
        ];

        vm.journals = [];
        JournalManagementFactory.getJournals(journalParams, 'all')
        .then(function (detailedJournals) {
          // in case there was no user interaction we want to update the view
          $timeout(function () {
            vm.journals = detailedJournals;
          });
        });
      }
    }

    function manageJournalSuspension(journal, suspension) {
      UtilService.showModal({
        templateUrl: 'journal/views/customer.journal.suspension.modal.edit.tpl.html',
        controller: 'SuspensionEditCtrl',
        controllerAs: 'suspensionEditCtrl',
        resolve: {
          journal: function () {
            return journal;
          },
          suspension: function () {
            return suspension;
          }
        }
      }, vm.handleModalReturnValue);
    }

    function showJournalEditModal(journal, type) {
      var knownTypes = [
        'startdate',
        'credits',
        'expiration',
        'resumption',
        'termination'
      ];

      if (!knownTypes.includes(type)) {
        LogService.log('Journal edit type unknown, cannot display edit modal.', 'debug');
        return;
      }

      // show the edit modal
      UtilService.showModal({
        templateUrl: 'journal/views/customer.journal.' + type + '.modal.edit.tpl.html',
        controller: 'CustomerJournalEditCtrl',
        controllerAs: 'customerJournalEditCtrl',
        resolve: {
          journal: function () {
            return journal;
          },
          type: function () {
            return type;
          }
        }
      }, vm.handleModalReturnValue);
    }

    function viewHistory(journal) {
      UtilService.showModal({
        templateUrl: 'journal/views/customer.journal.history.modal.view.tpl.html',
        controller: 'CustomerJournalHistoryCtrl',
        controllerAs: 'customerJournalHistoryCtrl',
        size: 'lg',
        resolve: {
          journal: function () {
            return journal;
          }
        }
      }, vm.handleModalReturnValue);
    }

    function downloadHistory(subType) {
      if (!vm.customer) {
        return;
      }

      AttachmentUtilService.download('/services/customers_export', {
        format: 'pdf',
        type: subType + '_history',
        'customers[]': vm.customer.id
      });
    }

    vm.setMasterJournal = function (journal) {
      UtilService.showModal({
        templateUrl: 'customer/views/detail/modal/customer.detail.journal.set_master.modal.tpl.html',
        controller: ['$modalInstance', function ($modalInstance) {
          var that = this;
          that.journals = vm.journals.filter(function (j) {
            return j.id !== journal.id && !j.termination && !j.masterJournal;
          });
          that.selectedJournal = null;
          that.modal = $modalInstance;
          that.set = function () {
            if (that.selectedJournal && that.selectedJournal.id && !that.selectedJournal.masterJournal) {
              journal.patch({
                masterJournal: that.selectedJournal.id
              }).then(function () {
                return $modalInstance.close(that.selectedJournal);
              }, function () {
                return $modalInstance.close('no selected journal');
              });
            } else {
              return $modalInstance.close('no selected journal');
            }
          };
          that.cancel = function () {
            $modalInstance.close('cancelled');
          };
        }],
        controllerAs: 'masterJournalSetCtrl'
      },
      function (returnValue) {
        LogService.log('CustomerCtrl::setMasterJournal() -> Set master journal modal closed with return value: ' + returnValue, 'debug');
        vm.getJournals();
      });
    };

    vm.unsetMasterJournal = function (journal) {
      if (journal.id && journal.slaveJournals.length === 0 && journal.masterJournal) {
        journal.patch({
          masterJournal: null
        }).then(function () {
          vm.getJournals();
        });
      }
    };

    function showSwimCourseSubscriptionCommentModal(subscription) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/pupils/pupil.subscription.comment.addedit.modal.tpl.html',
          controller: 'SubscriptionCommentEditCtrl',
          controllerAs: 'subscriptionCommentEditCtrl',
          resolve: {
            subscription: function () {
              return subscription;
            }
          }
        },
        function () {
          vm.reloadSwimschoolSubscriptions();
        },
        null,
        true
      );
    }

    function reloadSwimschoolSubscriptions() {
      vm.assignedSubscriptions = [];
      vm.waitlist = [];

      ServiceFactory.getSwimCourseSubscriptionsByCustomer(vm.customer.id)
      .then(function (retrievedSubscriptions) {
        angular.forEach(retrievedSubscriptions, function (subscription) {
          if (UtilService.isNotEmpty(subscription.swimCourseSeriesGroup)) {
            vm.attachSwimCoursePupilsCount(subscription);
          } else {
            vm.attachSwimCourseSeriesGroup(subscription);
            vm.attachSwimCourseWaitinglistPosition(subscription);
          }
        });
      });
    }

    function getWeekday(label) {
      return label.split(' ')[1];
    }

    function getTime(label) {
      return label.split(' ')[2];
    }

    function redirect(swimCourseSet, swimCourseSeriesGroup) {
      $state.go('swimcourse.groups', {setId: swimCourseSet.id, serId: swimCourseSeriesGroup.swimCourseSeries.id, groId: swimCourseSeriesGroup.id});
    }

    function isSubscriptionsEmpty() {
      return UtilService.isEmpty(vm.assignedSubscriptions);
    }

    function isWaitlistEmpty() {
      return UtilService.isEmpty(vm.waitlist);
    }

    vm.attachSwimCourseSeriesGroup = function (subscription) {
      subscription.swimCourseSeriesGroups = [];
      ServiceFactory.getSwimCourseSeriesGroupByLevelAndSet(subscription.swimCourseLevel.id, subscription.swimCourseSet.id)
      .then(function (seriesGroups) {
        if (seriesGroups.count) {
          subscription.swimCourseSeriesGroups = seriesGroups;
          subscription.firstDateOfSeries = seriesGroups[0].swimCourseSeries.swimCourseSessions[0].startsAt;
          vm.waitlist.push(subscription);
        }
      });
    };

    vm.attachSwimCoursePupilsCount = function (subscription) {
      SwimCourseManagementFactory.getSwimCoursePupils(subscription.swimCourseSet.id, subscription.swimCourseLevel.id, subscription.swimCourseSeriesGroup.id)
      .then(function (pupils) {
        subscription.totalPupilsCount = pupils.length;
        vm.assignedSubscriptions.push(subscription);
      });
    };

    vm.attachSwimCourseWaitinglistPosition = function (subscription) {
      SwimCourseManagementFactory.getSwimCourseWaitingList(subscription.swimCourseSet.id, subscription.swimCourseLevel.id)
      .then(function (waitingList) {
        waitingList = $filter('orderBy')(waitingList, 'subscribedAt');
        angular.forEach(waitingList, function (pupil, key) {
          if (subscription.contact.id === pupil.contact.id) {
            subscription.positionInWaitinglist = key + 1;
            subscription.totalPupilsInWaitingList = waitingList.length;
          }
        });
      });
    };

    vm.isCashSaleCustomerSet = function () {
      return !!SettingsService.get('accountancy.cashSaleCustomerId', false);
    };

    vm.reactivateMemberCard = function (memberCard) {
      MemberCardFactory.one(memberCard.id).patch({
        expirationDate: null
      }).then(function () {
        vm.reloadMembercards();
      });
    };

    function reloadSavingsCampaigns() {
      vm.savingsCampaigns = [];
      LogService.log('reloading savings campaign', 'debug');
      SavingsCampaignFactory.one('customer').one(vm.customer.id).get().then(function (savingsCampaigns) {
        angular.forEach(savingsCampaigns.results, function (savingCampaign) {
          vm.savingsCampaigns.push(savingCampaign);
        });
      });
    }

    function reloadSavingsCampaignSubscriptions() {
      vm.savingsCampaignSubscriptions = [];
      LogService.log('reloading savings campaign subscriptions', 'debug');
      SavingsCampaignSubscriptionsFactory.one('customer').one(vm.customer.id).get().then(function (savingsCampaignSubscriptions) {
        angular.forEach(savingsCampaignSubscriptions.results, function (savingCampaignSubscription) {
          vm.savingsCampaignSubscriptions.push(savingCampaignSubscription);
        });
      });
    }

    function showSavingsCampaignSubscriptionsRegistrationsView(savingsCampaignSubscription, customer) {
      UtilService.showModal({
        templateUrl: 'mobile/views/savings_campaign_subscriptions_registrations.modal.tpl.html',
        controller: 'SavingsCampaignSubscriptionsRegistrationsCtrl',
        controllerAs: 'savingsCampaignSubscriptionsRegistrationsCtrl',
        resolve: {
          customer: function () {
            return customer;
          },
          savingsCampaignSubscription: function () {
            return savingsCampaignSubscription;
          }
        }
      });
    }

    function showSavingsCampaignSubscriptionsRegistrationsAddEditModal(savingsCampaignSubscription, customer, savingsCampaignSubscriptionsRegistration) {
      UtilService.showModal({
        templateUrl: 'mobile/views/savings_campaign_subscriptions_registrations.addedit.modal.tpl.html',
        controller: 'SavingsCampaignSubscriptionsRegistrationsAddEditCtrl',
        controllerAs: 'savingsCampaignSubscriptionsRegistrationsAddEditCtrl',
        resolve: {
          customer: function () {
            return customer;
          },
          savingsCampaignSubscription: function () {
            return savingsCampaignSubscription;
          },
          savingsCampaignSubscriptionsRegistration: function () {
            return savingsCampaignSubscriptionsRegistration;
          }
        }
      },
      function () {
        vm.reloadSavingsCampaignSubscriptions();
      },
      null,
      true
      );
    }

    function showSavingsCampaignSubscriptionsAddEditModal(customer, savingsCampaignSubscription) {
      UtilService.showModal({
        templateUrl: 'mobile/views/savings_campaign_subscriptions.addedit.modal.tpl.html',
        controller: 'SavingsCampaignSubscriptionsAddEditCtrl',
        controllerAs: 'savingsCampaignSubscriptionsAddEditCtrl',
        resolve: {
          customer: function () {
            return customer;
          },
          savingsCampaignSubscription: function () {
            return savingsCampaignSubscription;
          }
        }
      },
      function () {
        vm.reloadSavingsCampaignSubscriptions();
      },
      null,
      true
      );
    }

    function deleteCustomerBalance(customerBalance) {
      CustomerFactory.removeCustomerBalance(vm.customer.id, customerBalance.id).then(function () {
        vm.reloadCustomerBalanceEntries();
        ToastrNotificationService.showNotification(
          'success',
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-removed'),
              $filter('translate')('customer.balance')
            )
          ),
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-successfully-removed'),
              $filter('translate')('customer.balance')
            )
          )
        );
      });
    }

    function closeSavingsCmpaignSubscription(subscription) {
      SavingsCampaignSubscriptionsFactory.one(subscription.id).one('close').get().then(function () {
        vm.reloadSavingsCampaignSubscriptions();
        ToastrNotificationService.showNotification(
          'success',
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-closed'),
              $filter('translate')('app.savings_campaign_subscription')
            )
          ),
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-successfully-closed'),
              $filter('translate')('app.savings_campaign_subscription')
            )
          )
        );
      });
    }

    vm.selectTab = function (tabId) {
      vm.activeTab = tabId;
      // Check the type of the tab and call reload-method
      switch (tabId) {
        case 'memberCards':
          vm.reloadMembercards();
          break;
        case 'directDebitContracts':
          vm.reloadDirectDebits();
          break;
        case 'subscriptionContracts':
          vm.reloadSubscriptionContracts();
          break;
        case 'customerBalanceOverview':
          vm.reloadCustomerBalanceEntries();
          break;
        case 'journals':
          vm.getJournals();
          break;
        case 'events':
          vm.reloadEvents();
          break;
        case 'history':
          vm.reloadSales();
          break;
        case 'log':
          vm.reloadLogs();
          break;
        case 'swimschool':
          vm.reloadSwimschoolSubscriptions();
          break;
        case 'savingsCampaign':
          vm.reloadSavingsCampaignSubscriptions();
          break;

        default:
          break;
      }
    };
  }
}());
