(function () {
  'use strict';

  angular
    .module('journal')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('journaltypes', {
        url: '/journaltypes?tab',
        templateUrl: 'journal/views/journal-types.tabs.tpl.html',
        controller: 'JournalTypesCtrl',
        controllerAs: 'journalTypesCtrl'
      });
  }
}());
