(function () {
  'use strict';

  angular
    .module('contact')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('contact', {
        url: '/contact',
        views: {
          '': {
            templateUrl: 'contact/views/contact.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contactCtrl'
          },
          'list@contact': {
            templateUrl: 'contact/views/contact.list.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contactCtrl'
          }
        }
      }).state('contact.new', {
        url: '/new',
        views: {
          'content@contact': {
            templateUrl: 'contact/views/contact.new.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contactCtrl'
          }
        }
      }).state('contact.detail', {
        url: '/:id',
        views: {
          'content@contact': {
            templateUrl: 'contact/views/contact.detail.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contactCtrl'
          }
        }
      }).state('contact.edit', {
        url: '/:id/edit',
        views: {
          'content@contact': {
            templateUrl: 'contact/views/contact.edit.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contactCtrl'
          }
        }
      }).state('employee-contacts', {
        url: '/employees',
        views: {
          '': {
            templateUrl: 'contact/views/employee.contact.tpl.html',
            controller: 'EmployeeContactCtrl',
            controllerAs: 'employeeContactCtrl'
          },
          'nav@employee-contacts': {
            templateUrl: 'contact/views/employee.contact.nav.tpl.html'
          },
          'content@employee-contacts': {
            templateUrl: 'contact/views/employee.contact.list.tpl.html'
          }
        }
      })
    ;
  }
}());
