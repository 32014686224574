(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('ContactAddEditCtrl', ContactAddEditCtrl);

  function ContactAddEditCtrl(
    $compile,
    $filter,
    $modalInstance,
    $q,
    $rootScope,
    $scope,
    $templateRequest,
    _,
    ContactDataContextFactory,
    ContactDataFactory,
    ContactDataTypeFactory,
    ContactFactory,
    ContactLocationFactory,
    ContactTypeFactory,
    customer,
    customerContact,
    CustomerContactTypeFactory,
    CustomerFactory,
    FitnessCreditsFactory,
    ImageFactory,
    ImageService,
    LogService,
    moment,
    PermissionsUtilService,
    Restangular,
    SettingsService,
    ToastrNotificationService,
    UtilService
  ) {
    var vm = this;
    vm.currentCustomerContact = angular.copy(customerContact);
    vm.customer = angular.copy(customer);
    vm.isEdit = isEdit;
    vm.isValid = isValid;
    vm.datePickerIsOpened = false;
    vm.canEditPhoto = true;
    vm.contactName = null;
    vm.contactFirstName = null;
    vm.contactLastName = null;
    vm.contactType = null;
    vm.customerTags = '';
    if (angular.isDefined(vm.customer.tags) && angular.isArray(vm.customer.tags)) {
      vm.customerTags = vm.customer.tags.toString();
    }
    vm.contactTypes = null;
    vm.customerContactType = null;
    vm.customerContactTypes = null;
    vm.photoBase64 = '';
    vm.photoDataType = null;
    vm.photoObject = null;
    vm.photoWebcam = null;
    vm.videoStream = null;
    vm.newLocations = [];
    vm.newComments = [];
    vm.blackList = null;
    if (angular.isDefined(vm.customer.blacklist)) {
      vm.blackList = vm.customer.blacklist;
      vm.blackList.checked = true;
    }
    vm.newMobilePhones = [];
    vm.newPhones = [];
    vm.newFaxes = [];
    vm.newWebsites = [];
    vm.newEmails = [];
    vm.countries = [];
    vm.contactContexts = [];
    vm.homeContext = null;
    vm.currentSocialSecurityNumber = [];
    vm.currentBirthDate = [];
    vm.currentVATNumber = [];
    vm.currentCompanyNumber = [];
    vm.currentGender = [];
    vm.currentBankAccountNumber = [];
    vm.noSocialSecurityNumber = false;
    vm.noCompanyNumber = false;
    vm.getVideoData = getVideoData;
    vm.cancel = cancelModalInstance;
    vm.saveLocations = saveLocations;
    vm.checkContact = checkContact;
    vm.saveContact = saveContact;
    vm.saveContactData = saveContactData;
    vm.sendContactDataToBackend = sendContactDataToBackend;
    vm.shouldContactDataUpdate = shouldContactDataUpdate;
    vm.editPhoto = startWebcam;
    vm.makeSnapshot = makeSnapshot;
    vm.cancelSnapshot = cancelWebcam;
    vm.onSuccess = onSuccess;
    vm.onError = onError;
    vm.onStream = onStream;
    vm.addNewObjectOfType = addNewObjectOfType;
    vm.addNewLocation = addNewLocation;
    vm.removeDataFromList = removeDataFromList;
    vm.dataIsDeletedFilter = dataIsDeletedFilter;
    vm.postNewPhoto = postNewPhoto;
    vm.openDatePicker = openDatePicker;
    vm.isBilling = isBilling;
    vm.isOrganization = isOrganization;
    vm.isNotBillingType = isNotBillingType;
    vm.checkIfObjectIsEmpty = checkIfObjectIsEmpty;
    vm.showSavedNotification = showSavedNotification;
    vm.clearConditionalInputs = clearConditionalInputs;
    vm.contactHasFitnessCredits = false;
    vm.getContactFitnessCredits = getContactFitnessCredits;
    vm.saveContactFitnessCredits = saveContactFitnessCredits;
    vm.getObjectInputId = getObjectInputId;
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.enableNetherlandVATnumber = SettingsService.get('customers.enableNetherlandVATNumber', false);
    vm.mywellnessIntegrationEnabled = SettingsService.get('customers.enableMywellnessIntegration', false);
    vm.enableBlackList = SettingsService.get('customers.enableBlackList', false);
    vm.addBEtoCompanyNumber = addBEtoCompanyNumber;
    vm.showTags = !SettingsService.get('contact.hideTags', false);
    vm.hidePhoto = SettingsService.get('customers.hidePhoto', false);
    vm.customerLabel = SettingsService.get('pos.customerLabel', 'klant');
    vm.language = null;
    vm.genders = [
      {
        letter: 'M',
        label: $filter('uconlyfirst')($filter('translate')('contact.male'))
      }, {
        letter: 'F',
        label: $filter('uconlyfirst')($filter('translate')('contact.female'))
      }
    ];

    vm.languages = [
      {
        letters: 'EN',
        label: 'EN'
      }, {
        letters: 'NL',
        label: 'NL'
      }, {
        letters: 'FR',
        label: 'FR'
      }
    ];
    vm.newsletter = false;

    // Get all Contact Data Contexts
    ContactDataContextFactory.getList({limit: 99})
    .then(function (resultContexts) {
      vm.contactContexts = resultContexts;
      vm.homeContext = _.find(resultContexts, function (context) {
        return context.code === 'HOME';
      });
    });

    // Get all Contact Data Types
    ContactDataTypeFactory.getList({limit: 99}).then(function (resultDataTypes) {
      vm.contactDataTypes = resultDataTypes;
    });
    // Get all Countries
    Restangular.all('countries').customGET('').then(function (resultCountries) {
      vm.countries = resultCountries.results;
    });
    // Get all Contact Types
    ContactTypeFactory.getList({limit: 99}).then(function (resultTypes) {
      vm.contactTypes = resultTypes;
      vm.contactType = $filter('filter')(vm.contactTypes, function (ct) {
        return ct.code === 'PERSON';
      })[0];

      // Get all Customer Contact Types
      CustomerContactTypeFactory.getList({limit: 99}).then(function (resultCustomerTypes) {
        vm.customerContactTypes = resultCustomerTypes;

        // Check if it item exists
        if (vm.isEdit()) {
          if (PermissionsUtilService.userHasPermission('access fitness section')) {
            vm.getContactFitnessCredits();
          }
          vm.contactName = vm.currentCustomerContact.contact.label;
          vm.contactLastName = vm.currentCustomerContact.completeContact.lastName;
          vm.contactFirstName = vm.currentCustomerContact.completeContact.firstName;
          vm.newsletter = vm.currentCustomerContact.completeContact.newsletter;
          vm.language = vm.customer.language;

          // Loop all contact locations
          angular.forEach(vm.currentCustomerContact.completeContact.contactLocations, function (location) {
            vm.newLocations.push(location);
          });

          // Loop all contact data
          angular.forEach(vm.currentCustomerContact.completeContact.contactData, function (contactData) {
            contactData.important = angular.isDefined(contactData.tags) && contactData.tags.includes('important');
            contactData.code = contactData.contactDataType.code;
            switch (contactData.contactDataType.code) {
              case 'WEBSITE':
                vm.newWebsites.push(contactData);
                break;

              case 'PHONE_NUMBER':
                vm.newPhones.push(contactData);
                break;

              case 'MOBILE_NUMBER':
                vm.newMobilePhones.push(contactData);
                break;

              case 'FAX_NUMBER':
                vm.newFaxes.push(contactData);
                break;

              case 'COMMENT':
                vm.newComments.push(contactData);
                break;

              case 'EMAIL':
                vm.newEmails.push(contactData);
                break;

              case 'SOCIAL_SECURITY_NUMBER':
                vm.currentSocialSecurityNumber.push(contactData);
                break;

              case 'VAT_NUMBER':
                vm.hasVATNumber = true;
                vm.currentVATNumber.push(contactData);
                break;

              case 'COMPANY_NUMBER':
                vm.currentCompanyNumber.push(contactData);
                break;

              case 'GENDER':
                vm.currentGender.push(contactData);
                break;

              case 'BIRTH_DATE':
                contactData.value = new Date(contactData.value);
                contactData.today = moment(contactData.value).format('DD-MM') === moment().format('DD-MM');
                vm.currentBirthDate.push(contactData);
                break;

              case 'BANK_ACCOUNT_NUMBER':
                vm.currentBankAccountNumber.push(contactData);
                break;

              default:
                LogService.log('no match', 'debug');
                break;
            }
          });

          // Check if social security Number and birth date are empty
          if (vm.currentSocialSecurityNumber.length === 0) {
            vm.addNewObjectOfType('SOCIAL_SECURITY_NUMBER');
            vm.noSocialSecurityNumber = true;
          }
          if (vm.currentVATNumber.length === 0) {
            vm.addNewObjectOfType('VAT_NUMBER');
          }
          if (vm.currentCompanyNumber.length === 0) {
            vm.addNewObjectOfType('COMPANY_NUMBER');
            vm.noCompanyNumber = true;
          }
          if (vm.currentBirthDate.length === 0) {
            vm.addNewObjectOfType('BIRTH_DATE');
          }
          if (vm.currentGender.length === 0) {
            vm.addNewObjectOfType('GENDER');
          }
          if (vm.currentBankAccountNumber.length === 0) {
            vm.addNewObjectOfType('BANK_ACCOUNT_NUMBER');
          }

          // Select the Customer Contact Type of the current Customer Contact
          vm.customerContactType = $filter('filter')(vm.customerContactTypes, function (cct) {
            return cct.id === vm.currentCustomerContact.customerContactType.id;
          })[0];

          // Select the Contact Type of the current contact
          vm.contactType = $filter('filter')(vm.contactTypes, function (ct) {
            return ct.id === vm.currentCustomerContact.completeContact.contactType.id;
          })[0];
          if (vm.currentCustomerContact.completeContact.contactPhotos.length > 0) {
            // Get Photo from backend
            ImageService.getImageFromBackend(vm.currentCustomerContact.completeContact.contactPhotos[0].photo.id).then(function (photo) {
              vm.currentCustomerContact.completeContact.photo = photo;
              vm.currentCustomerContact.completeContact.hasPhoto = true;
            });
          }
        } else {
          if (vm.currentSocialSecurityNumber.length === 0) {
            vm.addNewObjectOfType('SOCIAL_SECURITY_NUMBER');
          }
          if (vm.currentBirthDate.length === 0) {
            vm.addNewObjectOfType('BIRTH_DATE');
          }
          if (vm.currentVATNumber.length === 0) {
            vm.addNewObjectOfType('VAT_NUMBER');
          }
          if (vm.currentCompanyNumber.length === 0) {
            vm.addNewObjectOfType('COMPANY_NUMBER');
          }
          if (vm.currentGender.length === 0) {
            vm.addNewObjectOfType('GENDER');
          }
          if (vm.currentBankAccountNumber.length === 0) {
            vm.addNewObjectOfType('BANK_ACCOUNT_NUMBER');
          }
        }
      });
    });

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function addBEtoCompanyNumber(companyNumber) {
      var result = companyNumber;
      if (companyNumber.length === 10) {
        result = 'BE' + result;
      }
      return result;
    }

    function saveContact(reloadOption) {
      var checkResult,
          contactObject = {
            contactType: vm.contactType.id,
            newsletter: vm.newsletter
          },
          customerContactObject = {
            customerContactType: vm.customerContactType.id
          },
          customerObject = {
            tags: vm.customerTags.split(',')
          },
          promiseQueue = [];

      if (vm.useAlternateCustomerForm) {
        checkResult = vm.checkContact();
        if (checkResult) {
          ToastrNotificationService.showNotification(
            'error', $filter('uconlyfirst')($filter('translate')(checkResult)));
          return null;
        }
      }

      if (vm.isOrganization()) {
        contactObject.label = vm.contactName;
        if (vm.isBilling) {
          customerObject.label = contactObject.label;
        }
      } else {
        contactObject.label = vm.contactFirstName + ' ' + vm.contactLastName;
        contactObject.firstName = vm.contactFirstName;
        contactObject.lastName = vm.contactLastName;
        customerObject.label = contactObject.label;
      }
      customerObject.language = vm.language;
      // patch customer's tags and label
      promiseQueue.push(CustomerFactory.one(vm.customer.id).patch(customerObject).then(function () {
        $rootScope.$broadcast('refreshCustomerDetailLabel', customerObject.label);
        LogService.log('customer Saved', 'debug');
      }));

      // Check if it is existing contact
      if (vm.isEdit()) {
        if (vm.contactHasFitnessCredits) {
          promiseQueue.push(vm.saveContactFitnessCredits());
        }
        customerContactObject.contact = vm.currentCustomerContact.contact.id;

        // Save locations
        promiseQueue.push(vm.saveLocations(vm.currentCustomerContact.contact.id));

        // Save contact data
        promiseQueue.push(vm.saveContactData(vm.currentCustomerContact.contact.id));

        // Save changes to contact
        promiseQueue.push(ContactFactory.one(vm.currentCustomerContact.contact.id).patch(contactObject).then(function () {
          promiseQueue.push(CustomerFactory
            .one(vm.customer.id)
            .one('contacts')
            .one(vm.currentCustomerContact.id)
            .patch(customerContactObject)
            .then(function () {
              // Check if a photo has been added to contact
              if (vm.photoObject !== null) {
                // Check if contact had a photo
                if (vm.currentCustomerContact.completeContact.contactPhotos.length > 0) {
                  // Override old photo with new one
                  return ImageFactory.one(vm.currentCustomerContact.completeContact.contactPhotos[0].photo.id)
                      .patch(vm.photoObject).then(function () {
                        $modalInstance.close(reloadOption);
                        // Show notification at successful save
                        vm.showSavedNotification();
                      });
                }

                // Save Photo
                return vm.postNewPhoto(vm.photoObject, vm.currentCustomerContact.contact, reloadOption);
              }
            }));
        }));
      } else {
        contactObject.user = null;
        // Create new contact
        promiseQueue.push(ContactFactory.post(contactObject).then(function (newContact) {
          // Save locations
          promiseQueue.push(vm.saveLocations(newContact.id));
          // Save contact data
          promiseQueue.push(vm.saveContactData(newContact.id));
          // Add params to customer contact object
          customerContactObject.contact = newContact.id;
          // Link contact to customer
          promiseQueue.push(CustomerFactory.one(vm.customer.id).one('contacts').customPOST(customerContactObject).then(function () {
            // Save photo if a photo has been taken
            if (vm.photoObject !== null) {
              // Save new photo
              return vm.postNewPhoto(vm.photoObject, newContact, reloadOption);
            }
          }));
        }));
      }

      return $q.all(promiseQueue).then(function () {
        $modalInstance.close(reloadOption);
        vm.showSavedNotification();
        if (vm.mywellnessIntegrationEnabled && vm.customer.mywellnessToken) {
          vm.exportToMywellness();
        }
      });
    }

    function getContactFitnessCredits() {
      ContactFactory.one(vm.currentCustomerContact.contact.id).one('fitness_credits').customGET().then(function (resultCredits) {
        LogService.log('fitness credits' + resultCredits, 'debug');
        if (!angular.isArray(resultCredits)) {
          vm.contactStartFitnessCredits = angular.copy(resultCredits.credits);
          vm.contactFitnessCredits = resultCredits.credits;
          vm.contactHasFitnessCredits = true;
        } else {
          vm.contactFitnessCredits = 0;
          vm.contactStartFitnessCredits = 0;
          vm.contactHasFitnessCredits = true;
        }
      });
    }

    function saveContactFitnessCredits() {
      var creditsDiff = vm.contactFitnessCredits - vm.contactStartFitnessCredits,
          creditObject = {
            contact: vm.currentCustomerContact.contact.id,
            credits: creditsDiff
          };
      // Save Credits
      return FitnessCreditsFactory.post(creditObject).then(function () {
        LogService.log(creditsDiff.toString() + ' are added to the credit list');
      });
    }

    function saveContactData(contactId) {
      var promiseQueue = [],
          blackListReq = {reg: Restangular.one('black_list_customer'), method: null, obj: {}};

      // Save every item of contact data
      angular.forEach(vm.currentSocialSecurityNumber, function (socialNumber) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, socialNumber));
      });
      angular.forEach(vm.currentVATNumber, function (vatNumber) {
        // check if empty
        if (!vm.hasVATNumber) {
          vatNumber.value = '';
        }
        promiseQueue.push(vm.sendContactDataToBackend(contactId, vatNumber));
      });
      angular.forEach(vm.currentCompanyNumber, function (companyNumber) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, companyNumber));
      });
      angular.forEach(vm.currentBirthDate, function (birthDate) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, birthDate));
      });
      angular.forEach(vm.newWebsites, function (newWebsite) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newWebsite));
      });
      angular.forEach(vm.newComments, function (newComment) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newComment));
      });
      angular.forEach(vm.newMobilePhones, function (newMobilePhone) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newMobilePhone));
      });
      angular.forEach(vm.newPhones, function (newPhone) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newPhone));
      });
      angular.forEach(vm.newFaxes, function (newFax) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newFax));
      });
      angular.forEach(vm.newEmails, function (newEmail) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newEmail));
      });
      angular.forEach(vm.currentGender, function (gender) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, gender));
      });
      angular.forEach(vm.currentBankAccountNumber, function (bankAccountNumber) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, bankAccountNumber));
      });

      if (vm.blackList) {
        blackListReq.obj.description = vm.blackList.description;
        blackListReq.obj.customer = customer.id;
        if (vm.blackList.id) {
          blackListReq.reg = blackListReq.reg.one(vm.blackList.id);
          blackListReq.method = (vm.blackList.markAsDelete) ? 'remove' : 'patch';
          blackListReq.obj = (vm.blackList.markAsDelete) ? undefined : blackListReq.obj;
        } else {
          blackListReq.method = 'customPOST';
        }
        promiseQueue.push(blackListReq.reg[blackListReq.method](blackListReq.obj));
      }

      return Promise.all(promiseQueue);
    }

    function sendContactDataToBackend(contactId, object) {
      var contactDC = object.contactDataContext,
          contactDataObject = {
            contactDataContext: angular.isUndefined(contactDC) || angular.isUndefined(contactDC.id) ? null : contactDC.id
          }, fieldName = ContactDataFactory.getFieldName(object.code);
      contactDataObject[fieldName] = object.value;

      // Check if birth Date or Social Security Number have been cleared and add isDeleted if so
      if (!object.isNew && ((object.contactDataType.code === 'BIRTH_DATE' &&
        object[fieldName] === null) ||
        vm.checkIfObjectIsEmpty(object))) {
        object.isDeleted = true;
      }

      if (object.contactDataType.code === 'COMMENT') {
        contactDataObject.tags = object.important ? ['important'] : [];
      }

      // Check if isDeleted
      if (object.isDeleted) {
        LogService.log('check if new', 'debug');
        // If is not isNew remove the object
        if (!object.isNew) {
          return ContactFactory.one(contactId).one('data').one(object.id)
            .remove()
            .then(function () {
              LogService.log('remove Data', 'debug');
            });
        }
      } else {
        if (object.contactDataType.code === 'VAT_NUMBER') {
          contactDataObject.noVatNumberCheck = true;
        }
        LogService.log('Not deleted check if new of edit', 'debug');
        // Check if birth Date or Social Security Number have a value -> if not ignore
        if (vm.shouldContactDataUpdate(object)) {
          switch (object.contactDataType.code) {
            case 'BIRTH_DATE':
              contactDataObject[fieldName] = object.value.toISOString().substr(0, 10);
              break;
            default:
              break;
          }
          // Create contact data
          return ContactFactory.one(contactId).one('data').one(object.contactDataType.code.toLowerCase())
            .customPOST(contactDataObject, null, {inputId: vm.getObjectInputId(object)});
        }

        LogService.log('not new or birthDate and social security number empty', 'debug');
        // Check if not is New to patch (this avoids social security number and birthdate to patch non existing data)
        if (!object.isNew) {
          switch (object.contactDataType.code) {
            case 'BIRTH_DATE':
              contactDataObject[fieldName] = (object.value && object.value.toISOString().length > 0) ?
              object.value.toISOString().substr(0, 10) : null;
              break;
            default:
              break;
          }
          // Patch existing contact data
          return ContactFactory.one(contactId).one('data').one(object.id)
            .patch(contactDataObject, {inputId: vm.getObjectInputId(object)});
        }
      }
    }

    /**
     * @param {object} object Contact data object
     * @returns {boolean} Returns true when contactData object can/should be updated
     */
    function shouldContactDataUpdate(object) {
      return object.isNew &&
        ((object.contactDataType.code !== 'BIRTH_DATE' &&
        object.contactDataType.code !== 'SOCIAL_SECURITY_NUMBER' &&
        object.contactDataType.code !== 'VAT_NUMBER' &&
        object.contactDataType.code !== 'COMPANY_NUMBER' &&
        object.contactDataType.code !== 'GENDER' &&
        object.contactDataType.code !== 'BANK_ACCOUNT_NUMBER') ||
        ((object.contactDataType.code === 'BIRTH_DATE' &&
        object.value && object.value.toISOString().length > 0) || (object.value.length > 0 && (
        object.contactDataType.code === 'SOCIAL_SECURITY_NUMBER' ||
        object.contactDataType.code === 'VAT_NUMBER' ||
        object.contactDataType.code === 'COMPANY_NUMBER' ||
        object.contactDataType.code === 'GENDER' ||
        object.contactDataType.code === 'BANK_ACCOUNT_NUMBER'))));
    }

    function saveLocations(contactId) {
      var locationObject = null,
          promiseQueue = [];

      // Loop locations to add/edit/remove
      angular.forEach(vm.newLocations, function (newLocation) {
        locationObject = {
          street: newLocation.location.street,
          number: newLocation.location.number,
          box: newLocation.location.box,
          postalCode: newLocation.location.postalCode,
          city: newLocation.location.city,
          countryCode: newLocation.location.countryCode
        };
        // Check if location has been deleted
        if (newLocation.isDeleted) {
          LogService.log('check if new location', 'debug');
          // Check if it was not a new row that was added
          if (!newLocation.isNew) {
            // Remove location
            promiseQueue.push(ContactFactory.one(contactId).one('locations').one(newLocation.id)
              .remove()
              .then(function () {
                LogService.log('remove Location', 'debug');
              }));
          }
        } else {
          LogService.log('Not deleted check if new of edit', 'debug');
          // Check if location is new of existing
          if (newLocation.isNew) {
            // Post location to backend
            promiseQueue.push(ContactLocationFactory.post(locationObject, {inputId: vm.getObjectInputId(newLocation)}).then(function (createdLocation) {
              // Link location to contact
              ContactFactory.one(contactId).one('locations')
              .customPOST({contactDataContext: newLocation.contactDataContext.id, location: createdLocation.id})
              .then(function () {
                LogService.log('CreatedLocation', 'debug');
              });
            }));
          } else {
            // Patch existing location
            promiseQueue.push(ContactLocationFactory.one(newLocation.location.id)
            .patch(locationObject, {inputId: vm.getObjectInputId(newLocation)})
            .then(function () {
              LogService.log('updated location', 'debug');
              return ContactFactory.one(contactId).one('locations').one(newLocation.id)
              .patch({contactDataContext: newLocation.contactDataContext.id, location: newLocation.location.id})
              .then(function () {
                LogService.log('updated location-contact link', 'debug');
              });
            }));
          }
        }
      });

      return Promise.all(promiseQueue);
    }

    function isEdit() {
      return vm.currentCustomerContact !== null;
    }

    function isOrganization() {
      var returnValue = false;
      if (vm.contactType) {
        returnValue = vm.contactType.code === 'ORGANIZATION';
      }
      return returnValue;
    }

    function isBilling() {
      var returnValue = false;
      if (vm.customerContactType) {
        returnValue = vm.customerContactType.code === 'BILLING';
      }
      return returnValue;
    }

    function onError(err) {
      LogService.log('error: ' + err, 'debug');
    }

    function onStream(stream) {
      // Webcam streaming function
      LogService.log('stream' + stream, 'debug');
      vm.videoStream = stream;
    }

    function onSuccess() {
      vm.photoWebcam = vm.channel.video;
    }

    function getVideoData() {
      // This function returns a hidden canvas to get the base64 data
      var hiddenCanvas = angular.element('<canvas/>')[0], ctx;
      hiddenCanvas.width = vm.photoWebcam.width;
      hiddenCanvas.height = vm.photoWebcam.height;
      ctx = hiddenCanvas.getContext('2d');
      ctx.drawImage(vm.photoWebcam, 0, 0, vm.photoWebcam.width, vm.photoWebcam.height);
      return hiddenCanvas;
    }

    function startWebcam() {
      // This function includes the webcam in the page
      $templateRequest('contact/views/contact.modal.webcam.tpl.html').then(function (html) {
        var tpl = $compile(html)($scope);
        angular.element('.webcam__container').append(tpl);
        vm.canEditPhoto = false;
      });
    }

    function makeSnapshot() {
      // Take the picture and save it to scope variable
      var patCanvas = null;
      if (vm.photoWebcam) {
        patCanvas = angular.element('.webcam__photo img');
        if (!patCanvas) {
          return;
        }
        vm.photoBase64 = getVideoData().toDataURL();
        LogService.log('base 64 data: ' + vm.photoBase64, 'debug');
        vm.photoObject = {
          label: 'Contact Photo',
          encoding: 'base64',
          data: (vm.photoBase64.split(';')[1]).substring(7)
        };
        vm.cancelSnapshot();
      }
    }

    function cancelWebcam() {
      angular.element('.webcam__container').empty();
      vm.canEditPhoto = true;
      LogService.log(vm.videoStream, 'debug');
      vm.videoStream.getTracks()[0].stop();
      vm.photoWebcam = null;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function addNewObjectOfType(type) {
      var dataType = $filter('filter')(vm.contactDataTypes, function (cdt) {
            return cdt.code === type;
          })[0],
          newObject = {
            // generate random input id
            inputId: Math.random().toString(36).substr(2, 36),
            contactDataType: {id: dataType.id, code: dataType.code},
            contactDataContext: {},
            code: type,
            isNew: true
          };
      // Set home context as default
      if (vm.homeContext) {
        newObject.contactDataContext = vm.homeContext;
      }

      // Add new element to list of specific type of Contact Data
      switch (type) {
        case 'COMMENT':
          vm.newComments.push(newObject);
          break;
        case 'MOBILE_NUMBER':
          vm.newMobilePhones.push(newObject);
          break;
        case 'PHONE_NUMBER':
          vm.newPhones.push(newObject);
          break;
        case 'WEBSITE':
          vm.newWebsites.push(newObject);
          break;
        case 'EMAIL':
          vm.newEmails.push(newObject);
          break;
        case 'FAX_NUMBER':
          vm.newFaxes.push(newObject);
          break;
        case 'SOCIAL_SECURITY_NUMBER':
          newObject.value = '';
          vm.currentSocialSecurityNumber.push(newObject);
          break;
        case 'VAT_NUMBER':
          newObject.value = '';
          vm.currentVATNumber.push(newObject);
          break;
        case 'COMPANY_NUMBER':
          newObject.value = '';
          vm.currentCompanyNumber.push(newObject);
          break;
        case 'GENDER':
          newObject.value = '';
          vm.currentGender.push(newObject);
          break;
        case 'BIRTH_DATE':
          newObject.value = '';
          vm.currentBirthDate.push(newObject);
          break;
        case 'BANK_ACCOUNT_NUMBER':
          newObject.value = '';
          vm.currentBankAccountNumber.push(newObject);
          break;
        default:
          break;
      }
    }

    function postNewPhoto(photoObject, newContact, reloadOption) {
      // Post Image to Backend
      return ImageFactory.post(photoObject).then(function (newPhoto) {
        // Link photo with contact
        return ContactFactory.one(newContact.id)
        .one('photos')
        .customPOST({contactDataContext: null, photo: newPhoto.id})
        .then(function () {
          // Close modalInstance with reloadOption
          $modalInstance.close(reloadOption);
          return vm.showSavedNotification();
        });
      });
    }

    function addNewLocation() {
      // Add new location to contact locations
      vm.newLocations.push({location: {}, contactDataContext: vm.homeContext, isNew: true, id: ''});
    }

    function removeDataFromList(object) {
      // Set isDeleted on true on a listobject
      object.isDeleted = true;
    }

    function checkIfObjectIsEmpty(object) {
      return (object.contactDataType.code === 'SOCIAL_SECURITY_NUMBER' ||
          object.contactDataType.code === 'VAT_NUMBER' ||
          object.contactDataType.code === 'COMPANY_NUMBER' ||
          object.contactDataType.code === 'GENDER' ||
          object.contactDataType.code === 'BANK_ACCOUNT_NUMBER') &&
          object.value.length === 0;
    }

    function dataIsDeletedFilter(object) {
      // Filter for ng-repeat in template
      return !object.isDeleted;
    }

    function isNotBillingType(object) {
      // Filter for types, everything except BILLING
      return object.code !== 'BILLING';
    }

    function showSavedNotification() {
      // Show notification at successful save
      return ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.contact')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.contact')
            )
          )
        );
    }

    function isValid() {
      if (vm.customerTags && vm.customerTags.length > 0) {
        LogService.log('new comments' + vm.newComments, 'debug');
        return Boolean(vm.newComments && (vm.newComments).filter(vm.dataIsDeletedFilter).length > 0);
      }

      return true;
    }

    function checkContact() {
      var address = false,
          kbo = false,
          vat = false,
          rr = false,
          companyName = !!vm.contactName,
          firstName = !!vm.contactFirstName,
          lastName = !!vm.contactLastName;

      angular.forEach(vm.newLocations, function (location) {
        if (!location.isDeleted &&
            !!location.location.street &&
            !!location.location.number &&
            !!location.location.postalCode &&
            !!location.location.city &&
            !!location.location.countryCode) {
          address = true;
        }
      });
      angular.forEach(vm.currentCompanyNumber, function (companyNumber) {
        if (companyNumber.value) {
          kbo = true;
        }
      });
      angular.forEach(vm.currentVATNumber, function (vatNumber) {
        if (vatNumber.value) {
          vat = true;
        }
      });
      angular.forEach(vm.currentSocialSecurityNumber, function (ssn) {
        if (ssn.value) {
          rr = true;
        }
      });

      if (vm.isOrganization()) {
        if (!kbo && !vat && !address) {
          return 'pos.error.organization_details';
        }
        if (!companyName && kbo) {
          vm.organizationName = '(KBO: ' + vm.companyNumber.value + ')';
        }
        if (!companyName && vat) {
          vm.organizationName = '(BTW: ' + vm.vatNumber.value + ')';
        }
      } else {
        if (!rr && !address) {
          return 'pos.error.person_details';
        }
        if (rr && !firstName) {
          vm.customerFirstName = '---';
        }
        if (rr && !lastName) {
          vm.customerLastName = '(RR: ' + vm.contactSocialNumber.value + ')';
        }
      }
      // passed
      return null;
    }

    function clearConditionalInputs() {
      if (vm.useAlternateCustomerForm) {
        if (vm.noSocialSecurityNumber) {
          angular.forEach(vm.currentSocialSecurityNumber, function (ssn) {
            ssn.value = '';
          });
        }
        if (vm.noCompanyNumber) {
          angular.forEach(vm.currentCompanyNumber, function (companyNumber) {
            companyNumber.value = '';
          });
        } else {
          vm.hasVATNumber = '';
        }
        if (!vm.hasVATNumber) {
          angular.forEach(vm.currentVATNumber, function (vatNumber) {
            vatNumber.value = '';
          });
        }
      }
    }

    function getObjectInputId(object) {
      return (angular.isDefined(object.id) ? object.id : object.inputId);
    }

    vm.exportToMywellness = function () {
      CustomerFactory.one(vm.customer.id)
        .one('export-to-mywellness')
        .post()
        .then(function (response) {
          if (angular.isDefined(response) && angular.isDefined(response.mywellnessToken)) {
            vm.customer.mywellnessToken = response.mywellnessToken;
            ToastrNotificationService.showTranslatedNotification('success', 'app.success', 'mywellness.customer_updated', {});
          }
        });
    };

    vm.showNewsletterCheckbox = function () {
      var isEmpty = false;

      if (UtilService.isEmpty(vm.newEmails)) {
        isEmpty = true;
      }

      if (!isEmpty) {
        isEmpty = true;
        angular.forEach(vm.newEmails, function (newEmail) {
          if (angular.isUndefined(newEmail.isDeleted) || !newEmail.isDeleted) {
            isEmpty = false;
          }
        });
      }

      return !isEmpty;
    };

    vm.addBlackListObj = function () {
      if (vm.blackList && vm.blackList.markAsDelete) {
        vm.blackList.markAsDelete = false;
      } else {
        vm.blackList = {
          inputId: Math.random().toString(36).substr(2, 36),
          description: null,
          checked: false
        };
      }
    };
  }
}());
