(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accountancy.factory:Invoice
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .factory('InvoiceFactory', InvoiceFactory);

  function InvoiceFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'invoice'});
      })
      .service('invoices');

    return {
      getList: function (params) {
        return service.getList(params);
      },
      one: function (fragment) {
        return service.one(fragment);
      },
      post: function (params) {
        return service.post(params);
      },
      patch: function (params) {
        return service.patch(params);
      },
      put: function (params) {
        return service.put(params);
      },
      remove: function (params) {
        return service.remove(params);
      },
      removeWithSales: function (invoiceId) {
        return service.one(invoiceId).all('removeWithSales').remove();
      }
    };
  }
}());
