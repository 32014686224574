(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SetListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SetListCtrl', SetListCtrl);

  function SetListCtrl($state,
                       LogService,
                       $rootScope,
                       SwimCourseSetFactory,
                       UtilService,
                       SwimCourseManagementFactory) {
    var vm = this;
    vm.sets = [];
    vm.viewSeries = viewSeries;
    vm.deleteSet = deleteSet;
    vm.deleteSeries = deleteSeries;
    vm.loadSetSeries = loadSetSeries;
    vm.loadSets = loadSets;
    vm.expandedSets = [];
    vm.collectExpandedSets = collectExpandedSets;
    vm.expandSets = expandSets;
    vm.broadcastRefreshSubscriptionsMatrixEvent = broadcastRefreshSubscriptionsMatrixEvent;
    // set deletion confirmation modal
    vm.showSetDeletionModal = showSetDeletionModal;
    vm.setDeletionModalInstance = null;
    vm.showingSetDeletionModal = false;

    function collectExpandedSets() {
      vm.expandedSets = [];
      angular.forEach(vm.sets, function (set) {
        if (set.hasOwnProperty('seriesShown') && set.seriesShown) {
          vm.expandedSets.push(set.id);
        }
      });
    }

    function expandSets() {
      angular.forEach(vm.sets, function (set) {
        if (vm.expandedSets.indexOf(set.id) > -1) {
          vm.loadSetSeries(set);
          set.seriesShown = true;
        }
      });
    }

    // session add/edit modal
    vm.showSessionAddEditModal = showSessionAddEditModal;
    vm.showingSessionAddEditModal = false;
    vm.sessionAddEditModalInstance = null;

    // session multiset add/edit modal
    vm.showSessionMultiSetAddEditModal = showSessionMultiSetAddEditModal;
    vm.showingSessionMultiSetAddEditModal = false;
    vm.sessionMultiSetAddEditModalInstance = null;

    // set add/edit modal
    vm.showingSetAddEditModal = false;
    vm.showSetAddEditModal = showSetAddEditModal;
    vm.setAddEditModalInstance = null;
    loadSets();

    function viewSeries(set, series) {
      // redirect to detail view for groups
      $state.go('swimcourse.series', {setId: set.id, serId: series.id});
    }

    function loadSets() {
      vm.broadcastRefreshSubscriptionsMatrixEvent();
      vm.collectExpandedSets();
      SwimCourseSetFactory.getList({
        limit: 200,
        sort: 'label,ASC'
      })
        .then(function (result) {
          vm.sets = result;
          vm.expandSets();
        });
    }

    function deleteSet(set) {
      SwimCourseSetFactory.one(set.id)
        .remove()
        .then(function () {
          loadSets();
        });
    }

    function deleteSeries(set, series) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.series', 'app.delete-msg', 'app.this_series',
        function (returnValue) {
          if (returnValue) {
            SwimCourseSetFactory.one(set.id)
              .one('series').one(series.id)
              .remove()
              .then(function () {
                loadSets();
              });
          }
        }
      );
    }

    function broadcastRefreshSubscriptionsMatrixEvent() {
      LogService.log('Broadcasting refresh subscriptions matrix', 'debug');
      $rootScope.$broadcast('refreshSubscriptionsMatrix');
    }

    function loadSetSeries(set) {
      var firstDate, lastDate;
      LogService.log('Load set series called', 'debug');
      SwimCourseManagementFactory.getSeries(set.id)
        .then(function (series) {
          set.series = [];

          angular.forEach(series, function (foundSeries) {
            // get the first and last date
            firstDate = foundSeries.startsAt;
            lastDate = firstDate;
            angular.forEach(foundSeries.swimCourseSessions, function (session) {
              if (session.startsAt > lastDate) {
                lastDate = session.startsAt;
              }
              if (session.startsAt < firstDate) {
                firstDate = session.startsAt;
              }
            });

            foundSeries.firstDate = firstDate;
            foundSeries.lastDate = lastDate;

            set.series.push(foundSeries);
          });
        });

      vm.broadcastRefreshSubscriptionsMatrixEvent();
    }

    function showSetAddEditModal(set) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/sets/set.addedit.modal.tpl.html',
          controller: 'SetAddEditCtrl',
          controllerAs: 'setAddEditCtrl',
          size: 'sm',
          resolve: {
            set: function () {
              return angular.isUndefined(set) ? null : set;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler
      );
    }

    function showSetDeletionModal(set) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.set', 'app.delete-msg', 'app.this_set', function (returnValue) {
        if (returnValue) {
          set.remove()
            .then(function () {
              vm.loadSets();
            });
        }
      });
    }

    function modalResultHandler() {
      loadSets();
    }

    function modalFailureHandler() {
    }

    function showSessionMultiSetAddEditModal(series, session) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/sets/session.multiset.addedit.modal.tpl.html',
          controller: 'SessionMultiSetAddEditCtrl',
          controllerAs: 'sessionMultiSetAddEditCtrl',
          size: 'lg',
          resolve: {
            series: function () {
              return angular.isUndefined(series) ? null : series;
            },
            session: function () {
              return angular.isUndefined(session) ? null : session;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler
      );
    }

    function showSessionAddEditModal(set, series, session) {
      UtilService.showModal({
        templateUrl: 'swimcourse/views/sets/session.addedit.modal.tpl.html',
        controller: 'SessionAddEditCtrl',
        controllerAs: 'sessionAddEditCtrl',
        size: 'lg',
        resolve: {
          set: function () {
            return angular.isUndefined(set) ? null : set;
          },
          series: function () {
            return angular.isUndefined(series) ? null : series;
          },
          session: function () {
            return angular.isUndefined(session) ? null : session;
          }
        }
      },
      modalResultHandler,
      modalFailureHandler);
    }
  }
}());
