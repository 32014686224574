(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:uniqueCompanyNumberValidator
   * @restrict A
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('uniqueCompanyNumberValidator', uniqueCompanyNumberValidator);

  function uniqueCompanyNumberValidator(
    Restangular,
    SettingsService,
    $q
    ) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attr, ngModel) {
        ngModel.$asyncValidators.companyNumberNotUnique = function (modelValue) {
          var deferred = $q.defer(),
              useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
          ngModel.$setValidity('invalidCompanyNumber', true);
          if (modelValue && useAlternateCustomerForm) {
            if (controlMod97(modelValue)) {
              Restangular.one('services').one('validate-unique-company-number', modelValue).one(attr.contactId).get().then(function (response) {
                if (response.data.valid) {
                  deferred.resolve();
                } else {
                  deferred.reject();
                }
              });
            } else {
              ngModel.$setValidity('invalidCompanyNumber', false);
              deferred.resolve();
            }
          } else {
            deferred.resolve();
          }
          return deferred.promise;
        };
      }
    };
    function controlMod97(value) {
      var success = false,
          y = parseInt(value.slice(0, -2), 10),
          x = parseInt(value.slice(-2), 10);
      if (97 - (y % 97) === x) {
        success = true;
      }
      return success;
    }
  }
}());
