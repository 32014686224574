(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SkillListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SkillListCtrl', SkillListCtrl);

  function SkillListCtrl(
    SkillFactory,
    $modal
  ) {
    var vm = this;
    vm.skills = [];
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.loadSkills = loadSkills;

    // skill add/edit modal
    vm.showingSkillAddEditModal = false;
    vm.showSkillAddEditModal = showSkillAddEditModal;
    vm.skillAddEditModalInstance = null;

    // skill delete modal
    vm.showingSkillDeleteModal = false;
    vm.showSkillDeleteModal = showSkillDeleteModal;
    vm.skillDeleteModalInstance = null;

    vm.loadSkills();

    function loadSkills() {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };

      vm.skills = [];

      SkillFactory.getList(params).then(function (result) {
        vm.skills = result;
      });
    }

    function showSkillAddEditModal(skill) {
      if (vm.showingSkillAddEditModal) {
        return;
      }

      vm.skillAddEditModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/skills/skill.addedit.modal.tpl.html',
        controller: 'SkillAddEditCtrl',
        controllerAs: 'skillAddEditCtrl',
        size: 'sm',
        resolve: {
          skill: function () {
            return angular.isUndefined(skill) ? null : skill;
          }
        }
      });

      vm.showingSkillAddEditModal = true;
      vm.returnOfModalInstance('skillAddEdit');
    }

    function showSkillDeleteModal(skill) {
      if (vm.showingSkillDeleteModal) {
        return;
      }

      vm.skillDeleteModalInstance = $modal.open({
        templateUrl: 'swimcourse/views/skills/skill.delete.modal.tpl.html',
        controller: 'SkillAddEditCtrl',
        controllerAs: 'skillAddEditCtrl',
        size: 'sm',
        resolve: {
          skill: function () {
            return angular.isUndefined(skill) ? null : skill;
          }
        }
      });

      vm.showingSkillDeleteModal = true;
      vm.returnOfModalInstance('skillDelete');
    }

    function returnOfModalInstance(modal) {
      switch (modal) {
        case 'skillAddEdit':
          vm.skillAddEditModalInstance.result.then(function () {
            vm.showingSkillAddEditModal = false;
            vm.loadSkills();
          }, function () {
            vm.showingSkillAddEditModal = false;
          });

          break;

        case 'skillDelete':
          vm.skillDeleteModalInstance.result.then(function () {
            vm.showingSkillDeleteModal = false;
            vm.loadSkills();
          }, function () {
            vm.showingSkillDeleteModal = false;
          });

          break;

        default:
          break;
      }
    }
  }
}());
