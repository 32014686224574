(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:CollectionPeriodsViewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('CollectionPeriodsViewCtrl', CollectionPeriodsViewCtrl);

  function CollectionPeriodsViewCtrl(DebitCollectionPeriodFactory,
                                     SaleFactory,
                                     JournalFactory,
                                     Restangular,
                                     SaleStatusFactory,
                                     $stateParams,
                                     LogService) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.invoices = [];
    vm.periodSearchQuery = '';
    vm.itemsToProcess = undefined;
    vm.grandTotal = 0;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };
    vm.periodId = $stateParams.id;
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.changeSaleStatus = changeSaleStatus;
    vm.reloadPeriod = reloadPeriod;
    /* ----- END FUNCTIONS ----- */

    /* ----- OTHER ----- */
    SaleStatusFactory.getList({
      limit: 99,
      'filter[]': 'OR,code,COMPLETED,code,REJECTED',
      sort: 'code,asc'
    }).then(function (result) {
      vm.saleStatuses = result;
      vm.reloadPeriod();
    });

    function reloadPeriod() {
      var totalsQueryData = {invoices: []}, contractsQueryData = {customers: [], active: true};

      vm.itemsToProcess = undefined;
      vm.grandTotal = 0;

      DebitCollectionPeriodFactory.one($stateParams.id).get().then(function (resultPeriod) {
        vm.itemsToProcess = resultPeriod.invoices.length * 2;
        vm.startDate = resultPeriod.startDate;
        vm.endDate = resultPeriod.endDate;
        vm.invoices = [];

        if (vm.periodSearchQuery !== '') {
          resultPeriod.invoices = resultPeriod.invoices.filter(function (inv) {
            return (inv.reference.toLowerCase().indexOf(vm.periodSearchQuery.toLowerCase()) !== -1) || (inv.customer.label.toLowerCase().indexOf(vm.periodSearchQuery.toLowerCase()) !== -1);
          });
        }
        vm.invoices = resultPeriod.invoices;
        angular.forEach(vm.invoices, function (inv) {
          inv.totalPrice = undefined;
          inv.customer.directDebitContracts = undefined;
          inv.hasError = angular.isUndefined(inv.customer.externalDebtorNumber);

          totalsQueryData.invoices.push(inv.id);
          if (angular.isDefined(inv.customer) && !contractsQueryData.customers.includes(inv.customer.id)) {
            contractsQueryData.customers.push(inv.customer.id);
          }
        });

        Restangular.all('services/get-batch-invoice-totals').customPOST(totalsQueryData).then(function (totals) {
          angular.forEach(vm.invoices, function (inv) {
            inv.totalPrice = totals.due[inv.id].EUR;
            vm.grandTotal += totals.due[inv.id].EUR;
            vm.itemsToProcess--;
          });
        });

        Restangular.all('services/get-batch-customer-direct-debit-contracts').customPOST(contractsQueryData).then(function (contracts) {
          angular.forEach(vm.invoices, function (inv) {
            if (angular.isDefined(inv.customer)) {
              inv.customer.directDebitContracts = contracts[inv.customer.id];
            }
            if (inv.customer.directDebitContracts.length === 0) {
              inv.hasError = true;
            }
            vm.itemsToProcess--;
          });
        });
      });
    }

    function changeSaleStatus(invoice) {
      var patchObject = {
            saleStatus: vm.saleStatuses.filter(function (ss) {
              return ss.id !== invoice.sales[0].saleStatus.id;
            })[0].id
          },
          journalParams = {
            'filter[]': 'journalItems.saleItem.sale.id,' + invoice.sales[0].id
          },
          suspensionParam = {};

      SaleFactory.one(invoice.sales[0].id).patch(patchObject)
        .then(function () {
          // Should only reload this sale instead of all
          SaleFactory.one(invoice.sales[0].id).get()
            .then(function (updatedSale) {
              invoice.sales[0] = updatedSale;
            });
        });

      if (invoice.sales[0].saleStatus.code === 'COMPLETED') {
        // Get journal
        JournalFactory.getList(journalParams).then(function (resultJournal) {
          suspensionParam.startDate = (new Date()).toISOString().substring(0, 10);
          angular.forEach(resultJournal, function (journal) {
            JournalFactory.one(journal.id).one('suspensions').customPOST(suspensionParam)
              .then(function () {
                LogService.log('suspension posted', 'debug');
              });
          });
        });
      } else {
        // Get journal
        JournalFactory.getList(journalParams).then(function (resultJournal) {
          // Get existing suspension
          angular.forEach(resultJournal, function (journal) {
            JournalFactory.one(journal.id).getList('suspensions', {'filter[]': ['endDate,NULL', 'allowAppend,false']}).then(function (resultSuspensions) {
              suspensionParam.endDate = (new Date()).toISOString().substring(0, 10);
              angular.forEach(resultSuspensions, function (suspension) {
                JournalFactory.one(journal.id).one('suspensions').one(suspension.id).patch(suspensionParam)
                  .then(function () {
                    LogService.log('suspension patched', 'debug');
                  });
              });
            });
          });
        });
      }
    }

    /* ----- END OTHER ----- */
  }
}());
