(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.factory:Fitness
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .factory('FitnessFactory', FitnessFactory);

  function FitnessFactory(
    LogService,
    $q,
    CustomerManagementFactory,
    FitnessEventParticipationService,
    moment
  ) {
    var vm = this;

    // variables
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };

    // methods
    vm.loadCompleteFitnessData = loadCompleteFitnessData;
    //  vm.loadFitnessEvents = loadFitnessEvents;
    vm.loadJournals = loadJournals;
    vm.participation = FitnessEventParticipationService.participation;

    function loadCompleteFitnessData(customer, customerContact, enablePerformance, startDate) {
      var deferred = $q.defer(),
          eventCategoriesTranslatedLabels = {}, itemsForFetchData = [],
          date = angular.isDefined(startDate) ? startDate : new Date().toISOString().substr(0, 10);

      if (enablePerformance) {
        itemsForFetchData = ['journals', 'fitnessCourses', 'credits', 'eventCategories', 'startDate:' + date];
      } else {
        itemsForFetchData = ['performance', 'journals', 'fitnessCourses', 'credits', 'eventCategories', 'startDate:' + date];
      }

      LogService.log('FitnessFactory::loadCompleteFitnessData() -> CustomerContact: ' + customerContact, 'debug');
      CustomerManagementFactory.getCustomerDetails(customer.id, customerContact.id, itemsForFetchData).then(function (results) {
        LogService.log('FitnessFactory::loadJournals() -> data: ' + results, 'debug');

        angular.forEach(results.eventCategories, function (eventCategory) {
          eventCategoriesTranslatedLabels[eventCategory.id] = eventCategory.translatedLabel;
        });

        results.categories = {};
        results.calendar = {};

        angular.forEach(results.fitnessEventInstances, function (resultInstance) {
          var event = resultInstance.event;

          resultInstance.eligibleJournalTypeIds = [];
          resultInstance.classes = [];
          angular.forEach(event.eventCategories, function (EventCategory) {
            EventCategory.displayFlag = true;

            // categories
            if (angular.isUndefined(results.categories[EventCategory.code])) {
              results.categories[EventCategory.code] = {
                code: EventCategory.code,
                label: EventCategory.label,
                translatedLabel: eventCategoriesTranslatedLabels[EventCategory.id]
              };
            }
            resultInstance.classes.push(EventCategory.code);
          });
          // override classes with fitness course color tag if set
          if (angular.isDefined(event.fitnessCourses) && angular.isDefined(event.fitnessCourses[0]) &&
            event.fitnessCourses[0].tags && angular.isDefined(event.fitnessCourses[0].tags[0]) &&
            angular.isDefined(event.fitnessCourses[0].tags[0].id)
          ) {
            resultInstance.classes = [event.fitnessCourses[0].tags[0].id];
          }
          // calendar
          resultInstance.date = moment(resultInstance.startsAt).format('YYYY-MM-DD');
          if (angular.isUndefined(results.calendar[resultInstance.date])) {
            results.calendar[resultInstance.date] = {
              label: resultInstance.date
            };
          }

          // collect eligible journal types
          angular.forEach(event.fitnessCourses, function (currentFitnessCourse) {
            angular.forEach(currentFitnessCourse.journalTypes, function (currentJournalType) {
              if (resultInstance.eligibleJournalTypeIds.indexOf(currentJournalType.id) === -1) {
                resultInstance.eligibleJournalTypeIds.push(currentJournalType.id);
              }
            });
          });

          // calculate confirmation date
          resultInstance.confirmationDate = event.fitnessCourses[0].defaultConfirmationHours !== 0 ?
            moment(resultInstance.startsAt).subtract(event.fitnessCourses[0].defaultConfirmationHours, 'hours').format() :
            moment(resultInstance.startsAt).subtract(2, 'hours').format();

          // calculate latest confirmation date
          resultInstance.maxConfirmationDate = moment(resultInstance.startsAt).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');

          // calculate available places
          resultInstance.availablePlaces = resultInstance.maximumMembers - resultInstance.eventParticipants.filter(function (participant) {
            return participant.eventParticipantStatus.code !== 'REJECTED' &&
              participant.eventParticipantStatus.code !== 'PENDING' &&
              participant.eventParticipantStatus.code !== 'TENTATIVE' &&
              participant.eventParticipantType.code === 'PARTICIPANT';
          }).length;

          // my participation
          if (angular.isDefined(customerContact)) {
            FitnessEventParticipationService.participation(resultInstance, customerContact).then(function (participation) {
              LogService.log('My participation' + participation, 'debug');
              resultInstance.participation = participation;
            });
          }
        });

        deferred.resolve(results);
      });
      return deferred.promise;
    }

    function loadJournals(customer, customerContact) {
      var deferred = $q.defer();
      LogService.log('FitnessFactory::loadCompleteFitnessData() -> CustomerContact: ' + customerContact, 'debug');
      CustomerManagementFactory.getCustomerDetails(customer.id, customerContact.id, ['journals', 'credits']).then(function (results) {
        LogService.log('FitnessFactory::loadJournals() -> data: ', results, 'debug');
        deferred.resolve(results);
      });
      return deferred.promise;
    }
    return vm;
  }
}());
