(function () {
  'use strict';

  /* @ngdoc object
   * @name customer
   * @description
   *
   */
  angular
    .module('memberCard',
    [
      'ui.router',
      'restangular'
    ])
    .config(config);

  function config() {
  }
}());
