(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:NewsItemCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('dashboard')
    .controller('NewsItemCtrl', NewsItemCtrl);

  function NewsItemCtrl($scope,
                        $state,
                        $stateParams,
                        LogService,
                        NewsItemFactory,
                        UtilService) {
    var vm = this;
    vm.reloadPageData = reloadPageData;
    vm.reloadListData = reloadListData;
    vm.showModalView = showModalView;

    // Check the state of the page
    if ($state.is('news-item-detail')) {
      // Reload page data
      vm.reloadPageData($stateParams.id);
    } else {
      // Reload List Data
      vm.reloadListData();
      LogService.log('reload List Data', 'debug');
    }

    function reloadPageData(id) {
      NewsItemFactory.one(id).get().then(function (result) {
        vm.currentNewsItem = result;
      });
    }

    function reloadListData() {
      var params = {
        limit: 99,
        sort: 'publishedAt,DESC',
        publishedOnly: false,
        siteSpecific: false
      };

      NewsItemFactory.getList(params).then(function (results) {
        vm.newsItems = results;
      });
    }

    function showModalView(returnType, type, object) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal({
            templateUrl: 'dashboard/views/news-item.modal.addedit.tpl.html',
            controller: 'NewsItemAddEditCtrl',
            controllerAs: 'newsItemAddEditCtrl',
            size: 'lg',
            resolve: {
              newsItem: function () {
                return object;
              },
              reloadType: function () {
                return returnType;
              }
            }
          },
          modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          UtilService.showModal({
            templateUrl: 'dashboard/views/news-item.modal.delete.tpl.html',
            controller: 'NewsItemDeleteCtrl',
            controllerAs: 'newsItemDeleteCtrl',
            resolve: {
              newsItem: function () {
                return object;
              },
              reloadType: function () {
                return returnType;
              }
            }
          },
          modalResultHandler);

          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function modalResultHandler(returnValue) {
      vm.modalInstance = null;
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'page':
          vm.reloadPageData(vm.currentNewsItem.id);
          break;

        case 'list':
          vm.reloadListData();
          break;

        default:
          break;
      }
    }
  }
}());
