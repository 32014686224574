(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name event.factory:EventFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('event')
    .factory('EventInstancesFactory', EventInstancesFactory);

  function EventInstancesFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventInstance'});
    })
    .service('eventInstances');
  }
}());
