(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name billingDivision.factory:BillingDivisionFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('billingDivision')
    .factory('BillingDivisionFactory', BillingDivisionFactory);

  function BillingDivisionFactory(Restangular) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'billingDivision'});
    }).service('billing_divisions');
  }
}());
