(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:AttachmentUtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('AttachmentUtilService', AttachmentUtilService);

  function AttachmentUtilService($window, Blob, FileSaver, LogService, OAuthToken, Restangular) {
    return {
      download: function (url, parameters) {
        var actualParameters = parameters || {},
            actualUrl = Restangular.configuration.baseUrl + url,
            uriObject,
            uri;

        if (angular.isUndefined(actualParameters.access_token)) {
          actualParameters.access_token = OAuthToken.getAccessToken();
        }

        /* global URI */
        uriObject = new URI(actualUrl);
        uriObject.setQuery(actualParameters);
        uri = uriObject.toString();

        LogService.log('ATTACHMENT_UTILS: Downloading ' + uri, 'debug');

        $window.open(uri, '_blank');

        return uri;
      },
      postDownloadPdf: function (url, parameters, name) {
        return Restangular.all(url)
          .withHttpConfig({responseType: 'blob', headers: {'Content-Type': 'application/json', Accept: 'application/pdf'}})
          .post(parameters).then(function (res) {
            var file = new Blob([res], { type: 'application/pdf;charset=utf-8' });
            FileSaver.saveAs(file, name);
          });
      }
    };
  }
}());
