(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:LevelListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('LevelListCtrl', LevelListCtrl);

  function LevelListCtrl(LevelFactory, UtilService) {
    var vm = this;
    vm.levels = [];
    vm.loadLevels = loadLevels;

    // level add/edit modal
    vm.showLevelAddEditModal = showLevelAddEditModal;

    // group add/edit modal
    vm.showGroupAddEditModal = showGroupAddEditModal;

    // SkillLevel add/edit modal
    vm.showSkillLevelAddEditModal = showSkillLevelAddEditModal;

    // level delete modal
    vm.showLevelDeleteModal = showLevelDeleteModal;

    // group delete modal
    vm.showGroupDeleteModal = showGroupDeleteModal;

    // SkillLevel delete modal
    vm.showSkillLevelDeleteModal = showSkillLevelDeleteModal;

    vm.expandedLevels = [];
    vm.collectExpandedLevels = collectExpandedLevels;
    vm.expandLevels = expandLevels;

    vm.loadLevels();

    function loadLevels() {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };
      vm.collectExpandedLevels();
      vm.levels = [];
      LevelFactory.getList(params).then(function (result) {
        vm.levels = result;
        vm.expandLevels();
      });
    }

    function showLevelAddEditModal(level) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/levels/level.addedit.modal.tpl.html',
          controller: 'LevelAddEditCtrl',
          controllerAs: 'levelAddEditCtrl',
          size: 'sm',
          resolve: {
            level: function () {
              return angular.isUndefined(level) ? null : level;
            },
            group: function () {
              return null;
            },
            skillLevel: function () {
              return null;
            }
          }
        },
        modalResultHandler);
    }

    function collectExpandedLevels() {
      vm.expandedLevels = [];
      angular.forEach(vm.levels, function (lvl) {
        if (lvl.hasOwnProperty('groupsShown') && lvl.groupsShown) {
          vm.expandedLevels.push(lvl.id);
        }
      });
    }

    function expandLevels() {
      angular.forEach(vm.levels, function (existingLvl) {
        if (vm.expandedLevels.indexOf(existingLvl.id) > -1) {
          existingLvl.groupsShown = true;
        }
      });
      vm.expandedLevels = [];
    }

    function showLevelDeleteModal(level) {
      if (vm.showingLevelDeleteModal) {
        return;
      }

      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/levels/level.delete.modal.tpl.html',
          controller: 'LevelAddEditCtrl',
          controllerAs: 'levelAddEditCtrl',
          size: 'sm',
          resolve: {
            level: function () {
              return angular.isUndefined(level) ? null : level;
            },
            group: function () {
              return null;
            },
            skillLevel: function () {
              return null;
            }
          }
        },
        modalResultHandler);
    }

    function showGroupAddEditModal(level, group) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/levels/level.group.addedit.modal.tpl.html',
          controller: 'LevelAddEditCtrl',
          controllerAs: 'levelAddEditCtrl',
          size: 'md',
          resolve: {
            level: function () {
              return angular.isUndefined(level) ? null : level;
            },
            group: function () {
              return angular.isUndefined(group) ? null : group;
            },
            skillLevel: function () {
              return null;
            }
          }
        },
        modalResultHandler);
    }

    function showGroupDeleteModal(level, group) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.group', 'app.delete-msg', 'app.this_group',
        function (returnValue) {
          if (returnValue) {
            LevelFactory.one(level.id)
              .one('groups').one(group.id)
              .remove()
              .then(function () {
                vm.loadLevels();
              });
          }
        });
    }

    function showSkillLevelAddEditModal(level, skillLevel) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/levels/level.skilllevel.addedit.modal.tpl.html',
          controller: 'LevelAddEditCtrl',
          controllerAs: 'levelAddEditCtrl',
          size: 'sm',
          resolve: {
            level: function () {
              return angular.isUndefined(level) ? null : level;
            },
            skillLevel: function () {
              return angular.isUndefined(skillLevel) ? null : skillLevel;
            },
            group: function () {
              return null;
            }
          }
        },
        modalResultHandler);
    }

    function showSkillLevelDeleteModal(level, skillLevel) {
      UtilService.showModal(
        {
          templateUrl: 'swimcourse/views/levels/level.skilllevel.delete.modal.tpl.html',
          controller: 'LevelAddEditCtrl',
          controllerAs: 'levelAddEditCtrl',
          size: 'sm',
          resolve: {
            level: function () {
              return angular.isUndefined(level) ? null : level;
            },
            skillLevel: function () {
              return angular.isUndefined(skillLevel) ? null : skillLevel;
            },
            group: function () {
              return null;
            }
          }
        },
        modalResultHandler);
    }

    function modalResultHandler() {
      vm.loadLevels();
    }
  }
}());
