(function () {
  'use strict';

  angular
    .module('accountancy')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      // accountancy home state
      .state('accountancy', {
        url: '/accountancy',
        views: {
          '': {
            templateUrl: 'accountancy/views/accountancy.tpl.html',
            controller: 'AccountancyCtrl',
            controllerAs: 'accountancyCtrl'
          }
        }
      })
      .state('accountancy.list', {
        url: '/list',
        abstract: true
      })
      .state('accountancy.list.accountancy', {
        url: '/accountancy',
        views: {
          'main@accountancy': {
            templateUrl: 'accountancy/views/accountancy.list.accountancy.tpl.html',
            controller: 'SalesCtrl',
            controllerAs: 'salesCtrl'
          }
        }
      })
      .state('accountancy.list.pos', {
        url: '/pos',
        views: {
          'main@accountancy': {
            templateUrl: 'accountancy/views/accountancy.list.pos.tpl.html',
            controller: 'SalesCtrl',
            controllerAs: 'salesCtrl'
          }
        }
      })
      .state('accountancy.invoices', {
        url: '/invoices',
        views: {
          'main@accountancy': {
            templateUrl: 'accountancy/views/accountancy.invoice.tpl.html',
            controller: 'AccountancyCtrl',
            controllerAs: 'accountancyCtrl'
          },
          'main@accountancy.invoices': {
            templateUrl: 'accountancy/views/accountancy.invoice.list.tpl.html',
            controller: 'InvoiceCtrl',
            controllerAs: 'invoiceCtrl'
          }
        }
      })
      .state('accountancy.invoices.view', {
        url: '/invoices/:id',
        views: {
          'main@invoices': {
            templateUrl: 'accountancy/views/accountancy.invoice.view.tpl.html',
            controller: 'InvoiceViewCtrl',
            controllerAs: 'invoiceViewCtrl'
          }
        }
      })
      .state('accountancy.invoices.edit', {
        url: '/invoices/:id/edit',
        views: {
          'main@invoices': {
            templateUrl: 'accountancy/views/accountancy.invoice.edit.tpl.html',
            controller: 'InvoiceEditCtrl',
            controllerAs: 'invoiceCtrl'
          }
        }
      })
      .state('accountancy.list.collection-periods', {
        url: '/collection-periods',
        views: {
          'main@accountancy': {
            templateUrl: 'accountancy/views/accountancy.collection-periods.list.tpl.html',
            controller: 'CollectionPeriodsCtrl',
            controllerAs: 'collectionPeriodsCtrl'
          }
        }
      })
      .state('collection-periods', {
        url: '/collection-periods/:id',
        views: {
          '': {
            templateUrl: 'accountancy/views/collection-periods.view.tpl.html',
            controller: 'CollectionPeriodsViewCtrl',
            controllerAs: 'collectionPeriodsViewCtrl'
          }
        }
      })
      .state('accountancy.list.accountancy-periods', {
        url: '/accountancy-periods',
        views: {
          'main@accountancy': {
            templateUrl: 'accountancy/views/accountancy.accountancy-periods.list.tpl.html',
            controller: 'AccountancyPeriodsCtrl',
            controllerAs: 'accountancyPeriodsCtrl'
          }
        }
      })
      .state('accountancy.list.stuck-sale', {
        url: '/stuck-sale',
        views: {
          'main@accountancy': {
            templateUrl: 'accountancy/views/accountancy.list.stuck-sale.tpl.html',
            controller: 'StuckSaleCtrl',
            controllerAs: 'stuckSaleCtrl'
          }
        }
      })
    ;
  }
}());
