(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SeriesAddDateCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SeriesAddDateCtrl', SeriesAddDateCtrl);

  function SeriesAddDateCtrl(
     $modalInstance
  ) {
    var vm = this;
    vm.selectedDate = null;
    vm.startTime = new Date();
    vm.cancel = cancelModalInstance;

    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.returnDate = returnDate;
    vm.selectedDates = [];

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function returnDate() {
      vm.selectedDate.setHours(vm.startTime.getHours());
      vm.selectedDate.setMinutes(vm.startTime.getMinutes());
      $modalInstance.close(vm.selectedDate);
    }
  }
}());
