(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.factory:PermissionFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .factory('PermissionFactory', PermissionFactory);

  function PermissionFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'permission'});
    })
    .service('permissions');
  }
}());
