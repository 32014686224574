(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.service:SessionService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SessionService', SessionService);

  function SessionService(
    ServiceFactory
    ) {
    return {
      getOverview: function (year, week, filters) {
        var params = {};
        if (angular.isDefined(filters)) {
          angular.forEach(filters, function (filter, key) {
            params['filter[' + key + ']'] = filter;
          });
        }
        return ServiceFactory.one('swim_course_sessions').one(year.toString()).one(week.toString()).get(params);
      }
    };
  }
}());
