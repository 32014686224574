(function () {
  'use strict';

  angular
    .module('auth')
    .config(config);

  function config($stateProvider, PARAMS) {
    $stateProvider
      .state('login', {
        url: '/login',
        templateUrl: (PARAMS.designVersion === 2) ? 'auth/views/login-face-2.tpl.html' : 'auth/views/login.tpl.html',
        controller: 'AuthLoginCtrl',
        controllerAs: 'authLogin'
      })
      .state('logout', {
        url: '/logout',
        controller: 'AuthLogoutCtrl',
        controllerAs: 'authLogout'
      });
  }
}());
