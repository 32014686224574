(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:SavingsCampaignSubscriptionsRegistrationsAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('SavingsCampaignSubscriptionsRegistrationsAddEditCtrl', SavingsCampaignSubscriptionsRegistrationsAddEditCtrl);

  function SavingsCampaignSubscriptionsRegistrationsAddEditCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    SavingsCampaignFactory,
    MobileContentFactory,
    MobileLanguageFactory,
    savingsCampaignSubscription,
    customer,
    savingsCampaignSubscriptionsRegistration,
    $modalInstance,
    SavingsCampaignSubscriptionsRegistrationFactory
  ) {
    var vm = this;
    vm.savingsCampaignSubscriptionsRegistration = savingsCampaignSubscriptionsRegistration;
    vm.savingsCampaignSubscription = savingsCampaignSubscription;
    vm.customer = customer;
    vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt = new Date();
    vm.minDate = new Date();
    vm.startTime = new Date();
    vm.isEdit = isEdit;
    vm.setSavingsCampaignSubscriptionsRegistrationsData = setSavingsCampaignSubscriptionsRegistrationsData;
    vm.getSavingsCampaignSubscriptionsRegistrationsRestObject = getSavingsCampaignSubscriptionsRegistrationsRestObject;
    vm.saveSavingsCampaignSubscriptionsRegistrations = saveSavingsCampaignSubscriptionsRegistrations;
    vm.showSavedNotification = showSavedNotification;
    vm.openRegistratedAtPicker = openRegistratedAtPicker;

    if (vm.isEdit()) {
      vm.setSavingsCampaignSubscriptionsRegistrationsData();
    }

    function openRegistratedAtPicker() {
      vm.registratedAtPickerIsOpened = true;
    }

    function getSavingsCampaignSubscriptionsRegistrationsRestObject() {
      var registratedAt = '';
      vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt = new Date(vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt.setMinutes(vm.startTime.getMinutes()));
      vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt = new Date(vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt.setHours(vm.startTime.getHours()));
      registratedAt = vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt.toISOString();
      return {
        registratedAt: registratedAt
      };
    }

    function setSavingsCampaignSubscriptionsRegistrationsData() {
      vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt = vm.savingsCampaignSubscriptionsRegistration.registratedAt ? new Date(vm.savingsCampaignSubscriptionsRegistration.registratedAt) : new Date();
      vm.startTime = new Date(vm.startTime.setMinutes(vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt.getMinutes()));
      vm.startTime = new Date(vm.startTime.setHours(vm.savingsCampaignSubscriptionsRegistrationsRegistratedAt.getHours()));
    }

    function isEdit() {
      return angular.isDefined(vm.savingsCampaignSubscriptionsRegistration) && vm.savingsCampaignSubscriptionsRegistration !== null;
    }

    function saveSavingsCampaignSubscriptionsRegistrations() {
      var savingsCampaignSubscriptionsRegistrationsRestObject = vm.getSavingsCampaignSubscriptionsRegistrationsRestObject();

      if (vm.isEdit()) {
        SavingsCampaignSubscriptionsRegistrationFactory.one(vm.savingsCampaignSubscriptionsRegistration.id).patch(savingsCampaignSubscriptionsRegistrationsRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      } else {
        SavingsCampaignSubscriptionsRegistrationFactory.one(vm.savingsCampaignSubscription.id).one('customer').one(vm.customer.id).customPOST(savingsCampaignSubscriptionsRegistrationsRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      }
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.savings_campaign_registrations')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.savings_campaign_registrations')
          )
        )
      );
    }
  }
}());
