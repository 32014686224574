(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:dynamicFormErrors
   * @restrict AEC
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('dynamicFormErrors', dynamicFormErrors);

  function dynamicFormErrors(
    UtilService
  ) {
    return {
      restrict: 'A',
      priority: 1000,
      link: function (scope, element) {
        var dynamicMessagesElement, formGroups;
        element.on('submit', function () {
          // prevent double/stale error messages by clearing the previous divs if necessary
          dynamicMessagesElement = element.find('.dynamic-error-messages');
          formGroups = dynamicMessagesElement.closest('.has-error');

          formGroups.removeClass('has-error');
          dynamicMessagesElement.remove();
        });

        scope.$on('showFormErrorMsgBroadcast', function (event, errorMsg) {
          var currentSelector,
              errorMessage,
              inputElement,
              formGroupElement,
              entity = errorMsg.config.headers['x-entity'];

          event.preventDefault();
          angular.forEach(errorMsg.data.errors.children, function (errors, field) {
            // check if request went out with inputId parameter
            // uppercase first letter of field and concat to entity name
            // journal + StartDate = journalStartDate as selector
            if (angular.isDefined(errorMsg.config.params) && angular.isDefined(errorMsg.config.params.inputId)) {
              currentSelector = '#' + errorMsg.config.params.inputId;
              inputElement = element.find(currentSelector);
              // selector us used in some multi-input form like address,
              if (inputElement === 0) {
                currentSelector += '-' + field;
                inputElement = element.find(currentSelector);
              }
            } else {
              currentSelector = '#' + entity + (field.charAt(0).toUpperCase() + field.slice(1));
              inputElement = element.find(currentSelector);
            }

            inputElement = element.find(currentSelector);
            formGroupElement = inputElement.closest('.form-group');

            if (UtilService.isNotEmpty(errors)) {
              if (inputElement.length) {
                formGroupElement.addClass('has-error');

                // append error messages
                errorMessage = '<div class="dynamic-error-messages">';
                angular.forEach(errors, function (error) {
                  errorMessage += '<div class="error__message_box"><span class="error-message">';
                  errorMessage += error;
                  errorMessage += '</span></div>';
                });
                errorMessage += '</div>';
                formGroupElement.append(errorMessage);
              }
            }
          });
        });
      }
    };
  }
}());
