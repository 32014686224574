(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .factory('MobileContentFactory', MobileContentFactory);

  function MobileContentFactory(Restangular) {
    return Restangular.service('mobile/mobile_content');
  }
}());
