(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:JournalTypeDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('JournalTypeDeleteCtrl', JournalTypeDeleteCtrl);

  function JournalTypeDeleteCtrl(JournalTypeFactory, $modalInstance, journalType, LogService) {
    var vm = this;
    vm.journalType = journalType;
    vm.cancel = cancelModalInstance;
    vm.deleteJournalType = deleteJournalType;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deleteJournalType() {
      // Delete a specific customer
      JournalTypeFactory.one(vm.journalType.id).remove().then(function (data) {
        LogService.log('Removed data: ' + data, 'debug');
        // Close the modal view
        $modalInstance.close();
      });
    }
  }
}());
