(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.factory:UserFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .factory('UserFactory', UserFactory);

  function UserFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'user'});
    })
    .service('users');
  }
}());
