(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:DigipolisRePaymentsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('DigipolisRePaymentsCtrl', DigipolisRePaymentsCtrl);

  function DigipolisRePaymentsCtrl(
    _,
    $filter,
    $state,
    $stateParams,
    DigipolisOrderFactory,
    LogService,
    ProductUtilService,
    SettingsService,
    UtilService,
    $modal,
    $timeout
  ) {
    var vm = this;
    vm.rePayments = [];
    vm.loadRePayments = loadRePayments;
    vm.loadSingleRePaymentsPage = loadSingleRePaymentsPage;
    vm.filterChanged = filterChanged;
    vm.enableAllSite = false;
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.enableSalesLinking = SettingsService.get('pos.enableSalesLinking', false);
    vm.showSaleModalView = showSaleModalView;
    vm.openReference = openReference;
    vm.ProductUtilService = ProductUtilService;
    vm.pagination = {
      totalItems: null,
      maxPages: 10,
      currentPage: 1,
      itemsPerPage: 20
    };

    vm.filters = {
      beginDate: {
        val: '',
        field: 'createdAt',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.beginDate.isOpen = true;
        }
      },
      endDate: {
        val: '',
        field: 'createdAt',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        allowNullIfSet: true,
        open: function () {
          vm.filters.endDate.isOpen = true;
        }
      },
      orderId: {
        val: '',
        field: 'orderId',
        cond: 'LIKE'
      },
      customer: {
        val: '',
        field: 'sale.customer.label',
        cond: 'LIKE'
      },
      saleReference: {
        val: '',
        field: 'sale.reference',
        cond: 'LIKE'
      }
    };

    if (angular.isDefined($stateParams.tab) && $stateParams.tab === 'digipolisRePayments') {
      vm.loadRePayments();
    }

    function loadSingleRePaymentsPage(page) {
      var value, queryParams = {}, condition = '', loadingModal;

      queryParams = {
        offset: (page - 1) * vm.pagination.itemsPerPage,
        limit: vm.pagination.itemsPerPage,
        sort: 'createdAt,DESC',
        partial: 1
      };

      loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });

      _.each(vm.filters, function (filter, index) {
        if (angular.isDefined(filter.val) && filter.val) {
          switch (index) {
            case 'beginDate':
              filter.val.setHours(0);
              filter.val.setMinutes(0);
              filter.val.setSeconds(0);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'endDate':
              filter.val.setHours(23);
              filter.val.setMinutes(59);
              filter.val.setSeconds(59);
              value = $filter('date')(filter.val, filter.format);
              break;
            default:
              value = filter.val;
              break;
          }

          condition = '';
          if (UtilService.isNotEmpty(filter.cond)) {
            condition = filter.cond + ' ';
          }

          if (filter.hasOwnProperty('isParam') && filter.isParam) {
            queryParams[index] = value;
          } else {
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            if (angular.isDefined(filter.allowNullIfSet) && filter.allowNullIfSet === true) {
              queryParams['filter[' + index + ']'] = 'OR,' + queryParams['filter[' + index + ']'] + ',' + filter.field + ',NULL';
            }
          }
        }
      });

      if (vm.enableAllSite === true) {
        queryParams.displayAllSalesForCurrentUserByHisSites = true;
      }

      LogService.log('DigipolisRePaymentsCtrl::loadSingleRePaymentsPage() -> Loading page (using partial): ' + page, 'debug');

      return DigipolisOrderFactory.getRePaymentsList(queryParams)
        .then(function (data) {
          angular.forEach(data, function (result) {
            if (angular.isUndefined(result.sale) || angular.isUndefined(result.sale.customer) || angular.isUndefined(result.sale.customer.customerContacts)) {
              return;
            }
            angular.forEach(result.sale.customer.customerContacts, function (customerContact) {
              var ibanContactData = customerContact.contact.contactData.filter(function (cd) {
                return cd.contactDataType.code === 'BANK_ACCOUNT_NUMBER';
              });
              if (ibanContactData.length > 0) {
                result.sale.customer.ibanContactData = ibanContactData[0];
              }
            });
          });
          vm.rePayments[page] = data;
          vm.pagination.totalItems = data.count;
          loadingModal.close();
        });
    }

    function loadRePayments(reload) {
      LogService.log('DigipolisRePaymentsCtrl::loadRePayments() -> Loading Digipolis re-payments.', 'debug');

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.rePayments[vm.pagination.currentPage]) || (reload === true)) {
        vm.loadSingleRePaymentsPage(vm.pagination.currentPage);
      }
    }

    function filterChanged() {
      vm.loadRePayments(true);
    }

    function showSaleModalView(viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
          controller: 'SaleViewCtrl',
          controllerAs: 'saleViewCtrl',
          resolve: {
            sale: function () {
              return viewObject;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler
      );
    }

    function modalResultHandler() {}
    function modalFailureHandler() {}

    function openReference(relatedSale) {
      /* you cannot show two modals with same combination of controller and view
      lets close actual session and create new one. Timeout to wait till actual windows is properly closed.
       */
      vm.cancel();
      $timeout(function () {
        UtilService.showModal({
          templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
          controller: 'SaleViewCtrl',
          controllerAs: 'saleViewCtrl',
          resolve: {
            sale: function () {
              return relatedSale;
            }
          }
        },
        function () {},
        function () {},
        true);
      },
      200);
    }
  }
}());
