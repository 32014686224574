(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerCtrl
   *
   * @description
   */
  /* @ngInject */
  angular
    .module('customer')
    .factory('CustomerLogsFactory', CustomerLogFactory);

  function CustomerLogFactory(Restangular) {
    var customerLogService = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'resource_version'});
      })
      .service('customers/history');

    return {
      getList: function (params) {
        return customerLogService.getList(params);
      },
      one: function (fragment) {
        return customerLogService.one(fragment);
      },
      getCustomerLog: function (customerId) {
        var params = {
          id: customerId
        };

        return this.getList(params);
      }
    };
  }
}());
