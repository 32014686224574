(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name navigation.controller:NavigationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('navigation')
    .factory('NavigationFactory', NavigationFactory);

  function NavigationFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'navigation'});
    })
    .service('navigations');
  }
}());
