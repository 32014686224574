(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosAssignWristbandCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosAssignWristbandCtrl', PosAssignWristbandCtrl);

  function PosAssignWristbandCtrl(
    FacilityTypeFactory,
    ToastrNotificationService,
    $filter,
    $modalInstance,
    Restangular,
    $rootScope,
    hwproxy,
    saleId,
    wristbandId
  ) {
    var vm = this;
    vm.selectedFacilityTypes = [];
    vm.facilityTypes = null;
    vm.cancel = cancelModalInstance;
    vm.wristbandCollect = true;
    vm.wristbandId = wristbandId ? wristbandId : null;
    vm.saleId = saleId;
    vm.assignWristband = assignWristband;
    vm.possibleFacilityTypes = ['sportbad', 'schaatshal', 'recreatiebad'];
    vm.wristbandUnlimitedAccess = false;
    vm.startDatePickerIsOpened = false;
    vm.endDatePickerIsOpened = false;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openStartDatePicker = openStartDatePicker;
    vm.startDate = null;
    vm.endDate = null;
    vm.minDate = new Date();
    vm.days = vm.days = [
      {
        val: $filter('translate')('app.Monday'),
        dayName: 'Monday',
        selected: false
      },
      {
        val: $filter('translate')('app.Tuesday'),
        dayName: 'Tuesday',
        selected: false
      },
      {
        val: $filter('translate')('app.Wednesday'),
        dayName: 'Wednesday',
        selected: false
      },
      {
        val: $filter('translate')('app.Thursday'),
        dayName: 'Thursday',
        selected: false
      },
      {
        val: $filter('translate')('app.Friday'),
        dayName: 'Friday',
        selected: false
      },
      {
        val: $filter('translate')('app.Saturday'),
        dayName: 'Saturday',
        selected: false
      },
      {
        val: $filter('translate')('app.Sunday'),
        dayName: 'Sunday',
        selected: false
      }
    ];
    vm.selectedDays = [];
    vm.getSelectedDays = getSelectedDays;

    function assignWristband() {
      var data = {
        facilities: vm.selectedFacilityTypes,
        wristbandId: vm.wristbandId,
        collect: vm.wristbandCollect,
        saleId: vm.saleId,
        unlimitedAccess: vm.wristbandUnlimitedAccess,
        startDate: vm.startDate ? vm.startDate.toISOString().substring(0, 10) : null,
        endDate: vm.endDate ? vm.endDate.toISOString().substring(0, 10) : null,
        days: vm.getSelectedDays()
      };

      Restangular.one('access_control/assign-wristband').customPOST(data).then(function () {
        ToastrNotificationService.showTranslatedNotification('success', 'app.success');
        // Close the modal view
        $modalInstance.close('');
      });
    }

    FacilityTypeFactory.getList({limit: 99}).then(function (resultFacilityTypes) {
      var facilityTypes = [];
      angular.forEach(resultFacilityTypes, function (facilityType) {
        if (vm.possibleFacilityTypes.indexOf(facilityType.label.toLowerCase()) !== -1) {
          facilityTypes.push(facilityType);
        }
      });
      vm.facilityTypes = facilityTypes;
    }).then(function () {
      $rootScope.$on(hwproxy.options.cardReader.unknownCardEventName, function (event, data) {
        vm.wristbandId = data.event.number;
      });
    });

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function openStartDatePicker() {
      vm.startDatePickerIsOpened = true;
    }

    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function getSelectedDays() {
      var selected = vm.days.filter(function (day) {
        return day.selected;
      });
      vm.selectedDays = [];
      angular.forEach(selected, function (selectedDay) {
        vm.selectedDays.push(selectedDay.dayName);
      });

      return vm.selectedDays;
    }
  }
}());
