(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name discount.factory:DiscountFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .factory('DiscountFactory', DiscountFactory);

  function DiscountFactory($filter, Restangular) {
    var discountService = Restangular
        .withConfig(function (RestangularConfigurer) {
          RestangularConfigurer.setDefaultHeaders({'x-entity': 'discount'});
        })
        .service('discounts'),
        amountDiscountType = {
          code: 'AMOUNT',
          label: $filter('uconlyfirst')($filter('translate')('app.discount-amount'))
        },
        percentageDiscountType = {
          code: 'PERCENTAGE',
          label: $filter('uconlyfirst')($filter('translate')('app.percentage'))
        };

    discountService.getPercentageDiscountType = function () {
      return percentageDiscountType;
    };

    discountService.getAmountDiscountType = function () {
      return amountDiscountType;
    };

    discountService.getDiscountTypes = function () {
      return [amountDiscountType, percentageDiscountType];
    };

    return discountService;
  }
}());
