(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:AttachmentUtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('FilterUtilService', FilterUtilService);

  function FilterUtilService(_, $filter, LogService, UtilService) {
    return {
      createQueryParamsFromFilters: function (filters) {
        var value, condition, queryParams = [];

        _.each(filters, function (filter, index) {
          if (angular.isDefined(filter.field) && angular.isDefined(filter.val) && filter.val) {
            switch (index) {
              case 'dateFrom':
              case 'dueDateFrom':
              case 'invoiceDateFrom':
                filter.val.setHours(0);
                filter.val.setMinutes(0);
                filter.val.setSeconds(0);
                value = $filter('date')(filter.val, filter.format);
                break;
              case 'dateTo':
              case 'dueDateTo':
              case 'invoiceDateTo':
                filter.val.setHours(23);
                filter.val.setMinutes(59);
                filter.val.setSeconds(59);
                value = $filter('date')(filter.val, filter.format);
                break;
              default:
                value = filter.val;
                break;
            }
            if (UtilService.isNotEmpty(filter.cond)) {
              condition = filter.cond + ' ';
            } else {
              condition = '';
            }

            if (filter.hasOwnProperty('isParam') && filter.isParam) {
              // add as parameter
              queryParams[index] = value;
            } else {
              // add as filter var
              queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            }
          }
        });
        return queryParams;
      },

      isEmpty: function (filters, options) {
        var isEmpty = true,
            ignore = [];

        if (angular.isDefined(options) && angular.isArray(options.ignore)) {
          ignore = options.ignore;
        }

        _.each(filters, function (filter, index) {
          if (ignore.length > 0 && ignore.indexOf(index) !== -1) {
            return;
          }
          console.log(filter.val);
          if (angular.isDefined(filter.val) && filter.val) {
            isEmpty = false;
          }
        });
        return isEmpty;
      }
    };
  }
}());
