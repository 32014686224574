(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SessionMultiSetAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SessionMultiSetAddEditCtrl', SessionMultiSetAddEditCtrl);

  function SessionMultiSetAddEditCtrl(
    LevelFactory,
    SwimCourseSetFactory,
    $cookies,
    LogService,
    $filter,
    $modalInstance,
    UtilService,
    series,
    session
  ) {
    var vm = this;
    vm.startTime = new Date();
    vm.currentSeries = series;
    vm.currentSession = session;
    vm.sets = [];
    vm.cancel = cancelModalInstance;
    vm.getSessionRestObject = getSessionRestObject;
    vm.getSeriesRestObject = getSeriesRestObject;
    vm.addHighLightedDate = addHighLightedDate;
    vm.setStartDate = setStartDate;
    vm.createBatchSessions = createBatchSessions;
    vm.defaultInterval = 'P7D';
    vm.startDate = null;
    vm.highLightedDate = null;
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.removeDate = removeDate;
    vm.selectedDates = [];
    vm.loadData = loadData;
    vm.duplicateLastEntry = duplicateLastEntry;
    vm.selectedDateOffset = null;
    vm.selectedOffsetUnit = null;
    vm.offsetUnits = [
      {
        label: $filter('translate')('app.days'),
        type: 'day'
      },
      {
        label: $filter('translate')('app.weeks'),
        type: 'week'
      },
      {
        label: $filter('translate')('app.months'),
        type: 'month'
      }
    ];

    vm.loadData();

    function loadData() {
      // we only need all sets when we're not already working with a
      // specifically selected set
      if (!UtilService.isNotEmpty(vm.currentSet)) {
        SwimCourseSetFactory.getList({limit: 99})
        .then(function (result) {
          vm.sets = result;
        });
      }
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function removeDate(date) {
      var index = vm.selectedDates.indexOf(date);
      if (index > -1) {
        vm.selectedDates.splice(index, 1);
      }
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function getSeriesRestObject(set) {
      var restObject, time = new Date(set.startTime);
      vm.startDate.setHours(time.getHours());
      vm.startDate.setMinutes(time.getMinutes());

      restObject = {
        startsAt: vm.startDate,
        interval: vm.defaultInterval
      };

      return restObject;
    }

    function getSessionRestObject(set, date) {
      var restObject,
          time = new Date(set.startTime);

      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());

      restObject = {
        startsAt: date
      };

      return restObject;
    }

    function duplicateLastEntry() {
      var index, newDate;
      if (UtilService.isNotEmpty(vm.selectedDates)) {
        index = vm.selectedDates.length - 1;
        newDate = new Date(vm.selectedDates[index].valueOf());

        if (UtilService.isNotEmpty(vm.selectedDateOffset) &&
          UtilService.isNotEmpty(vm.selectedOffsetUnit)) {
          switch (vm.selectedOffsetUnit.type) {
            case 'day':
              newDate.setDate(newDate.getDate() + vm.selectedDateOffset);
              break;
            case 'week':
              newDate.setDate(newDate.getDate() + (vm.selectedDateOffset * 7));
              break;
            case 'month':
              newDate.setMonth(newDate.getMonth() + vm.selectedDateOffset);
              break;
            default:
              break;
          }
        }
        // if (UtilService.isNotEmpty(vm.selectedTimeOffset)) {
        //   newDate.setMinutes(newDate.getMinutes() + vm.selectedTimeOffset);
        // }

        vm.selectedDates.push(newDate);
      }
    }

    function setStartDate() {
      var earliest = null;
      LogService.log('setStartDate', 'debug');
      angular.forEach(vm.selectedDates, function (date) {
        if (earliest === null || earliest > date) {
          LogService.log('setting earliest to', 'debug');
          LogService.log(date, 'debug');
          earliest = date;
        }
      });
      vm.startDate = earliest;
    }

    function addHighLightedDate() {
      var fullDate;
      if (UtilService.isNotEmpty(vm.highLightedDate)) {
        fullDate = new Date(vm.highLightedDate);
        // fullDate.setHours(vm.startTime.getHours());
        // fullDate.setMinutes(vm.startTime.getMinutes());

        vm.selectedDates.push(fullDate);
        vm.highLightedDate = null;
        setStartDate();
      }
    }

    // create sessions in batch
    function createBatchSessions() {
      var seriesRestObject, sessionRestObject;

      if (UtilService.isNotEmpty(vm.sets)) {
        angular.forEach(vm.sets, function (currentSet) {
          if (currentSet.selected) {
            seriesRestObject = vm.getSeriesRestObject(currentSet);

            SwimCourseSetFactory.one(currentSet.id).post('series', seriesRestObject).then(function (createdSeries) {
              angular.forEach(vm.selectedDates, function (date) {
                sessionRestObject = vm.getSessionRestObject(currentSet, date);
                SwimCourseSetFactory.one(currentSet.id).one('series').one(createdSeries.id).post('sessions', sessionRestObject)
                .then(function () {
                  // Close the modal view
                  $modalInstance.close();
                });
              });
            });
          }
        });
      }
    }
  }
}());
