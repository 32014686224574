(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosSaleService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
  .module('pos')
  .service('PosSaleService', PosSaleService);

  function PosSaleService(
    SaleFactory,
    ToastrNotificationService,
    Restangular,
    _,
    SettingsService,
    CurrentPosInstanceFactory
  ) {
    var self = this;
    self.roundingProductCode = SettingsService.get('pos.roundingProductCode', null);
    this.saleIsCompleted = function (saleId, showErrorMsgWhenComplete) {
      var promise = null;
      if (saleId) {
        promise = SaleFactory.one(saleId).get().then(function (sale) {
          var returnObject = false;
          if (angular.isDefined(sale.saleStatus)) {
            returnObject = sale.saleStatus.code === 'COMPLETED';
          }
          if (angular.isDefined(showErrorMsgWhenComplete) && showErrorMsgWhenComplete && returnObject) {
            ToastrNotificationService.showTranslatedNotification('warning', 'app.sale.error-completed.title', 'app.sale.error-completed.msg', {});
          }
          return returnObject;
        }, function () {
          return false;
        });
      } else {
        promise = new Promise(function (resolve) {
          resolve(false);
        });
      }

      return promise;
    };

    this.diffBetweenCarts = function (billItems) {
      return new Promise(function (resolve) {
        Restangular.one('active-cart').get({pointOfSaleSessionId: CurrentPosInstanceFactory.getPosSessionId()}).then(function (cartData) {
          var itemWithDiffQuantityExist = false, existedBillItem;
          //check the products in cart if there item with different quantity
          angular.forEach(cartData.products, function (product) {
            existedBillItem = billItems.filter(function (billItem) {
              return billItem.product.code === product.articleCode;
            });

            if (angular.isDefined(existedBillItem[0]) && existedBillItem[0].amount !== product.quantity) {
              itemWithDiffQuantityExist = true;
            }
          });
          //remove rounding product before checking, see #62928
          angular.forEach(cartData.products, function (product, index) {
            if (product.articleCode === self.roundingProductCode) {
              cartData.products.splice(index, 1);
            }
          });

          if (billItems.length !== cartData.products.length || itemWithDiffQuantityExist) {
            angular.forEach(billItems, (function (billItem) {
              _.remove(cartData.products, function (product) {
                return ((angular.isDefined(billItem.externalApiId) && product.id === billItem.externalApiId) || (angular.isDefined(billItem.parametersObject) && angular.isDefined(billItem.parametersObject.externalApiId) && product.id === billItem.parametersObject.externalApiId)) && product.quantity === billItem.amount;
              });
            }));
            resolve(cartData);
          }
          resolve();
        });
      });
    };
  }
}());
