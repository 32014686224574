(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosGroupService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .service('PosGroupService', PosGroupService);

  function PosGroupService(
    $cookies,
    PosSessionFactory,
    UtilService,
    RestUtilService,
    Restangular,
    CurrentUserContextFactory
  ) {
    var self = this;

    self.posGroupService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'posGroup'});
    })
    .service('points_of_sale/groups');

    this.one = function (fragment) {
      return this.posGroupService.one(fragment);
    };

    this.getList = function (params, getFullList, filters) {
      var currentSiteId = CurrentUserContextFactory.getSiteId();

      getFullList = !!getFullList;

      params = angular.isObject(params) ? params : {};
      if (!UtilService.isInteger(params.limit)) {
        params.limit = 99;
      }

      if (UtilService.isEmpty(params['filter[]'])) {
        params['filter[]'] = [];
      }
      params['filter[]'].push('site.id,' + currentSiteId);

      if (UtilService.isNotEmpty(filters)) {
        // construct filter strings and attach them to the query params object
        RestUtilService.attachFilterStrings(params, filters);
      }

      if (getFullList) {
        return RestUtilService.getFullList(this.posGroupService, params);
      }
      return this.posGroupService.getList(params);
    };

    this.getFullList = function (params, filters) {
      params = angular.isObject(params) ? params : {};
      filters = angular.isObject(filters) ? filters : {};
      return this.getList(params, true, filters);
    };

    this.attachPosInstances = function (posGroup, onlyInactive) {
      return this.getGroupInstances(posGroup.id)
        .then(function (instances) {
          if (angular.isDefined(onlyInactive) && onlyInactive) {
            return PosSessionFactory.getActiveSessions().then(function (activeSessions) {
              var activeAndNotOwnInstanceIds = [];
              angular.forEach(activeSessions, function (activeSession) {
                if (activeSession.userContext.id !== $cookies.get('currentContextId')) {
                  activeAndNotOwnInstanceIds.push(activeSession.pointOfSaleInstance.id);
                }
              });
              posGroup.instances = instances.filter(function (instance) {
                return (activeAndNotOwnInstanceIds.indexOf(instance.id) === -1);
              });
              return posGroup;
            });
          }
          posGroup.instances = instances;
          return posGroup;
        });
    };

    this.getInstance = function (groupId, instanceId) {
      return this.one(groupId).one('instances').one(instanceId).get();
    };

    this.getGroupInstances = function (groupId, params) {
      return RestUtilService.getFullList(this.one(groupId), params, 'instances');
    };

    this.getGroupsAndInstances = function (onlyInactiveInstances) {
      return this.getFullList()
        .then(function (posGroups) {
          return UtilService.promiseLoop(posGroups, function (posGroup) {
            return self.attachPosInstances(posGroup, onlyInactiveInstances);
          })
          .then(function () {
            return posGroups;
          });
        });
    };
  }
}());
