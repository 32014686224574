(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:WaitingListConfirmationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('WaitingListConfirmationCtrl', WaitingListConfirmationCtrl);

  function WaitingListConfirmationCtrl(
    $modalInstance,
    subscription
  ) {
    var vm = this;
    vm.subscription = subscription;
    if (angular.isArray(vm.subscription)) {
      vm.subscriptionIsArray = true;
    }
    vm.cancel = cancelModalInstance;
    vm.returnAnswer = returnAnswer;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function returnAnswer(answer) {
      $modalInstance.close(answer);
    }
  }
}());
