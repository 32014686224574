(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.factory:SettingFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .factory('SettingFactory', SettingFactory);

  function SettingFactory(Restangular) {
    var settingService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'setting'});
    })
    .service('settings');

    settingService.getListSimple = function () {
      return new Promise(function (resolve, reject) {
        settingService.one('simple').customGET().then(function (response) {
          resolve(response.plain());
        }, reject);
      });
    };

    return settingService;
  }
}());
