(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:AddEditSurveyQueryCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('AddEditSurveyQueryCtrl', AddEditSurveyQueryCtrl);

  function AddEditSurveyQueryCtrl(
    $modalInstance,
    UtilService,
    SurveyQueryService,
    SurveyQueryFactory,
    surveyQuery,
    moment
  ) {
    var vm = this;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openStartDatePicker = openStartDatePicker;
    vm.startDatePickerIsOpened = false;
    vm.endDatePickerIsOpened = false;
    vm.minDate = new Date();
    vm.useAnsewersChoice = (surveyQuery && surveyQuery.surveyQueryChoices.length > 0);

    console.log(surveyQuery);
    vm.addNewAnswer = function () {
      vm.query.surveyQueryChoices.push({name: ''});
    };

    vm.removeAnswer = function (index) {
      vm.query.surveyQueryChoices.splice(index, 1);
    };

    vm.ansewersOptions = function () {
      if (UtilService.isEmpty(vm.ansewersChoices)) {
        vm.addNewAnswer();
      }
    };
    function openStartDatePicker() {
      vm.startDatePickerIsOpened = true;
    }

    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    vm.query = SurveyQueryFactory.createSurveyQuery(surveyQuery);

    vm.isEdit = function () {
      return UtilService.isNotEmpty(vm.query.id);
    };

    vm.persistQuery = function () {
      vm.query.endDate = moment(vm.query.endDate).format('YYYY-MM-DD');
      vm.query.startDate = moment(vm.query.startDate).format('YYYY-MM-DD');
      return SurveyQueryService.saveSurveyQuery(vm.query)
      .then(function () {
        $modalInstance.close();
      });
    };
  }
}());
