(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name settings.service:LocaleService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .service('ContactDataFactory', ContactDataFactory);

  function ContactDataFactory() {
    return {
      createContactDataObject: function (objectDataType) {
        var obj = {
              contactDataContext: {},
              code: objectDataType,
              isNew: true,
              value: null
            }, type;
        type = this.getFieldName(objectDataType);
        obj[type] = '';

        return obj;
      },
      getFieldName: function (type) {
        var types = {
          GENDER: 'gender',
          EMAIL: 'email',
          BIRTH_DATE: 'date',
          DEATH_DATE: 'date',
          PHONE_NUMBER: 'string',
          MOBILE_NUMBER: 'string',
          FAX_NUMBER: 'string',
          WEBSITE: 'url',
          COMMENT: 'text',
          SOCIAL_SECURITY_NUMBER: 'string',
          VAT_NUMBER: 'vat',
          COMPANY_NUMBER: 'companyNumber',
          BANK_ACCOUNT_NUMBER: 'iban'
        };
        return types[type];
      }
    };
  }
}());
