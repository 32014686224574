(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .factory('MobileFactory', MobileFactory);

  function MobileFactory($filter, Restangular) {
    var mobileService = Restangular.service('open');

    mobileService.getCategories = function () {
      return mobileService.one('category').get({limit: 99}).then(function (result) {
        var categories = {};
        result.forEach(function (category) {
          categories[category.label] = $filter('uconlyfirst')(category.label);
        });
        return categories;
      }, function () {
        return {};
      });
    };

    return mobileService;
  }
}());
