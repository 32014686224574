(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .factory('ProductFactory', ProductFactory);

  // because of product versioning we require the response headers after each change
  // so we can get the new product id, therefore we set full response to true
  function ProductFactory(Restangular, $filter) {
    var productService = Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'product'});
    }).service('products');

    productService.getOneByCode = function (code, searchHidden) {
      var params = {
        limit: 1
      };

      params['filter[]'] = ['code,' + code];
      params['filter[]'].push('mostRecent,TRUE');
      if (angular.isUndefined(searchHidden) || searchHidden !== true) {
        params['filter[]'].push('OR,hidden,FALSE,hidden,NULL');
      }
      params['filter[]'].push('OR,blueprint,FALSE,blueprint,NULL');

      return productService.getList(params).then(function (result) {
        if (angular.isDefined(result.data[0])) {
          return productService.one(result.data[0].id).get().then(function (product) {
            var productPrice;

            // skip products without a price component
            productPrice = $filter('filter')(product.data.productComponents, function (pc) {
              return pc.type === 'price';
            });
            if (angular.isUndefined(productPrice[0])) {
              return Promise.reject();
            }

            return product.data;
          });
        }

        return Promise.reject();
      });
    };

    productService.getJournalProductsForTypeahead = function (query) {
      return productService.one('for-journal-typeahead').one(query).get();
    };

    return productService;
  }
}());
