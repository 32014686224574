(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name history.factory:HistoryFactory
   *
   * @description
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('ColorFactory', ColorFactory);

  function ColorFactory() {
    return {
      getColors: function () {
        return [
          {id: 'black', color: 'bg-black'},
          {id: 'charcoal', color: 'bg-charcoal'},
          {id: 'shadow', color: 'bg-shadow'},
          {id: 'lead', color: 'bg-lead'},
          {id: 'steel', color: 'bg-steel'},
          {id: 'gray', color: 'bg-gray'},
          {id: 'smoke', color: 'bg-smoke'},
          {id: 'fog', color: 'bg-fog'},
          {id: 'white', color: 'bg-white'},
          {id: 'snow', color: 'bg-snow'},
          {id: 'slate_light', color: 'bg-slate_light'},
          {id: 'slate', color: 'bg-slate'},
          {id: 'slate_dark', color: 'bg-slate_dark'},
          {id: 'teal', color: 'bg-teal'},
          {id: 'teal_dark', color: 'bg-teal_dark'},
          {id: 'laguna_light', color: 'bg-laguna_light'},
          {id: 'laguna', color: 'bg-laguna'},
          {id: 'laguna_dark', color: 'bg-laguna_dark'},
          {id: 'red', color: 'bg-red'},
          {id: 'orange', color: 'bg-orange'},
          {id: 'sunflower', color: 'bg-sunflower'},
          {id: 'lemon', color: 'bg-lemon'},
          {id: 'grape', color: 'bg-grape'},
          {id: 'plum', color: 'bg-plum'},
          {id: 'lavender', color: 'bg-lavender'},
          {id: 'purple', color: 'bg-purple'},
          {id: 'forest', color: 'bg-forest'},
          {id: 'lawn', color: 'bg-lawn'},
          {id: 'aquamarine', color: 'bg-aquamarine'},
          {id: 'emerald', color: 'bg-emerald'},
          {id: 'turquoise', color: 'bg-turquoise'},
          {id: 'chocolate', color: 'bg-chocolate'},
          {id: 'barnwood', color: 'bg-barnwood'},
          {id: 'sportoase_blue', color: 'bg-sportoase_blue'},
          {id: 'sportoase_blue_dark', color: 'bg-sportoase_blue_dark'},
          {id: 'sportoase_orange', color: 'bg-sportoase_orange'},
          {id: 'sportoase_green', color: 'bg-sportoase_green'}
        ];
      },
      getSportColors: function () {
        return [
          {id: 'sportoase_blue_dark', color: 'bg-sportoase_blue_dark'},
          {id: 'sportoase_blue', color: 'bg-sportoase_blue'},
          {id: 'laguna_dark', color: 'bg-laguna_dark'},
          {id: 'forest', color: 'bg-forest'},
          {id: 'sportoase_green', color: 'bg-sportoase_green'},
          {id: 'lemon', color: 'bg-lemon'},
          {id: 'sportoase_orange', color: 'bg-sportoase_orange'},
          {id: 'orange', color: 'bg-orange'},
          {id: 'red', color: 'bg-red'},
          {id: 'sportoase_red', color: 'bg-sportoase_red'}
        ];
      }
    };
  }
}());
