(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name hwproxy.controller:HwProxyMemberCardDataCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('hwproxy')
    .controller('CustomerLogDetailCtrl', CustomerLogDetailCtrl);

  function CustomerLogDetailCtrl(
    $filter,
    $modalInstance,
    entry,
    moment
  ) {
    var vm = this;
    vm.changeSet = [];
    vm.cancel = cancelModalInstance;

    if (entry.action === 'customer.history.contact.email') {
      vm.changeSet.push({
        key: 'contact.email',
        from: entry.versionedData.value[0],
        to: entry.versionedData.value[1]
      });
      return;
    }

    angular.forEach(Object.keys(entry.versionedData), function (key) {
      var table = {
        externalDebtorNumber: 'customer.externalDebtorNumber',
        invoicePaymentDays: 'customer.invoicePaymentDays',
        invoicePreferred: 'customer.invoicePreferred',
        preferredPaymentMethod: 'customer.preferredPaymentMethod',
        hidden: 'app.customer_hidden',
        customerType: 'customer.views.customerType',
        contactType: 'customer.views.contactType',
        firstName: 'contact.firstname',
        lastName: 'contact.lastname',
        comments: 'customer.comments',
        tags: 'customer.views.customerTags',
        label: 'customer.views.name',
        createdAt: 'screen.created',
        updatedAt: 'accountancy.updated'
      };

      if (!entry.versionedData[key][0] && !entry.versionedData[key][1]) {
        return;
      }

      switch (key) {
        case 'id':
        case 'site':
        case 'user':
        case 'createdBy':
        case 'updatedBy':
          return;

        case 'createdAt':
        case 'updatedAt':
          vm.changeSet.push({
            key: table[key],
            from: entry.versionedData[key][0] ?
              moment(new Date(entry.versionedData[key][0].date)).format('DD/MM/YYYY HH:mm:ss') :
              '',
            to: entry.versionedData[key][1] ?
              moment(new Date(entry.versionedData[key][1].date)).format('DD/MM/YYYY HH:mm:ss') :
              ''
          });
          break;

        default:
          vm.changeSet.push({
            key: table[key] || key,
            from: entry.versionedData[key][0],
            to: entry.versionedData[key][1]
          });
      }
    });

    function cancelModalInstance() {
      // Dismiss the modal instance
      $modalInstance.dismiss('cancel');
    }
  }
}());
