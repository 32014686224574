(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name journal.factory:JournalManagementFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .factory('JournalManagementFactory', JournalManagementFactory);

  function JournalManagementFactory(
    Restangular,
    UtilService,
    RestUtilService,
    JournalFactory,
    JournalTypeFactory,
    JournalActionFactory,
    LogService
  ) {
    return {
      getJournals: function (params, detailsToFetch) {
        var that = this;
        if (!params) {
          params = {
            limit: 99
          };
        }

        LogService.log('JournalManagement.getJournals() called with detailsToFetch set to' + detailsToFetch);

        if (!UtilService.isNotEmpty(detailsToFetch)) {
          return RestUtilService.getFullList(JournalFactory, params);
        }

        // detailsToFetch filled in -> fetch additional entities and attach them to the journal object
        // but first, make sure it's an array
        if (angular.isString(detailsToFetch)) {
          if (detailsToFetch.toLowerCase() === 'all') {
            detailsToFetch = ['detail', 'type', 'validity'];
          } else {
            detailsToFetch = detailsToFetch.split(',');
          }
        }

        return new Promise(function (resolve) {
          var result = [];

          RestUtilService.getFullList(JournalFactory, params)
          .then(function (journals) {
            // loop over the journals, fetching additional information for each one
            UtilService.promiseLoop(journals, function (currentJournal) {
              return new Promise(function (innerResolve) {
                var currentMergedObject = currentJournal,
                    currentDetails = {};

                // use a nested promiseLoop so we can fetch these things asynchronously
                UtilService.promiseLoop(detailsToFetch, function (thingToFetch) {
                  if (thingToFetch === 'detail') {
                    return JournalFactory.getJournalById(currentJournal.id)
                    .then(function (journalDetail) {
                      // attach information indicating usage
                      journalDetail.pristine = that.isPristine(journalDetail);
                      journalDetail.isDomJournal = that.isDomJournal(journalDetail);
                      journalDetail.isExpired = that.isExpiredJournal(journalDetail);
                      currentDetails.detail = journalDetail;
                    });
                  }

                  if (thingToFetch === 'type') {
                    return JournalTypeFactory.getJournalTypeById(currentJournal.journalType.id)
                    .then(function (journalTypeDetail) {
                      currentDetails.type = journalTypeDetail;
                    });
                  }

                  return JournalFactory.getJournalValidity(currentJournal.id)
                  .then(function (journalValidityDetail) {
                    currentDetails.validity = journalValidityDetail;
                  });
                })
                .then(function () {
                  var termination = [];

                  // replace journal with detailed journal
                  if (UtilService.isNotEmpty(currentDetails.detail)) {
                    currentMergedObject = currentDetails.detail;
                  }

                  // replace journal type with detailed type
                  if (UtilService.isNotEmpty(currentDetails.type)) {
                    currentMergedObject.journalType = currentDetails.type;
                  }

                  // attach validity information
                  if (UtilService.isNotEmpty(currentDetails.validity)) {
                    currentMergedObject.validity = currentDetails.validity;
                  }

                  // check termination information
                  currentMergedObject.terminated = false;
                  currentMergedObject.termination = undefined;
                  if (UtilService.isNotEmpty(currentMergedObject.journalItems)) {
                    termination = currentMergedObject.journalItems.filter(function (journalItem) {
                      return journalItem.journalAction.code === 'TERMINATION';
                    });
                  }
                  if (termination.length) {
                    currentMergedObject.termination = termination[0].date;
                    currentMergedObject.terminated = new Date(currentMergedObject.termination).getTime() < new Date().getTime();
                  }

                  result.push(currentMergedObject);
                  LogService.log('Done merging details for journal' + currentJournal, 'debug');
                  innerResolve();
                });
              });
            })
            .then(function () {
              LogService.log('Done fetching detailed journals, resolving.', 'debug');
              resolve(result);
            });
          });
        });
      },
      deleteJournalSuspension: function (journalId, suspensionId) {
        return JournalFactory.one(journalId)
        .one('suspensions').one(suspensionId)
        .remove();
      },
      saveJournalSuspension: function (journalId, suspensionVars) {
        var suspensionId = null;
        if (suspensionVars.hasOwnProperty('startDate') && suspensionVars.startDate instanceof Date) {
          suspensionVars.startDate = suspensionVars.startDate.toISOString();
        }
        if (suspensionVars.hasOwnProperty('endDate') && suspensionVars.endDate instanceof Date) {
          suspensionVars.endDate = suspensionVars.endDate.toISOString();
        }

        if (suspensionVars.hasOwnProperty('id')) {
          suspensionId = suspensionVars.id;
          delete suspensionVars.id;
          return JournalFactory.one(journalId)
          .one('suspensions')
          .one(suspensionId)
          .patch(suspensionVars, {'x-entity': 'suspension'});
        }
        // id parameter not supplied, post suspension
        return JournalFactory.one(journalId)
        .post('suspensions', suspensionVars, {'x-entity': 'suspension'});
      },
      postJournalItem: function (journalId, actionCode, actionVars) {
        // retrieve the journal action based on the code and post the journal item
        return new Promise(function (resolve, reject) {
          LogService.log('Posting journal item of type' + actionCode, 'debug');
          JournalActionFactory.getJournalActionByCode(actionCode)
          .then(function (action) {
            actionVars.journalAction = action.id;
            if (angular.isDefined(actionVars.date) && angular.isFunction(actionVars.date.toISOString)) {
              actionVars.date = actionVars.date.toISOString();
            }
            JournalFactory.one(journalId)
            .post('items', actionVars)
            .then(function (postedItem) {
              resolve(postedItem);
            });
          }, function (errorMsg) {
            reject(errorMsg);
          });
        });
      },
      modifyJournalStartDate: function (journal, date) {
        var that = this;
        return new Promise(function (resolve, reject) {
          var oldStartDate = new Date(journal.startDate),
              newStartDate = null,
              shiftInMillSeconds = 0;

          if (!(UtilService.isNotEmpty(date) && UtilService.isNotEmpty(journal))) {
            reject('JournalManagement::modifyJournalStartDate() -> Not all parameters filled in.');
          }

          newStartDate = new Date(date);

          // new start dates must be further in the past
          if (oldStartDate - newStartDate <= 0) {
            reject('JournalManagement::modifyJournalStartDate() -> new start date must come before old start date.');
          }

          // determine the shift in milliseconds to use in shiftJournalItemDate()
          shiftInMillSeconds = newStartDate - oldStartDate;
          that.shiftJournalItemDate(journal.id, 'subscription', shiftInMillSeconds)
          .then(function () {
            resolve();
          });
        });
      },
      isDomJournal: function (journal) {
        return journal.hasOwnProperty('subscriptionContracts') && UtilService.isNotEmpty(journal.subscriptionContracts);
      },
      isPristine: function (journal) {
        var isPristine = true,
            i,
            acceptableActionCodes = ['SUBSCRIPTION', 'RENEWAL', 'RECHARGE'];

        // search for actions which indicate this journal has been used and set pristine to false/true accordingly
        if (journal.hasOwnProperty('journalItems') && UtilService.isNotEmpty(journal.journalItems)) {
          for (i = 0; i < journal.journalItems.length; ++i) {
            if (acceptableActionCodes.indexOf(journal.journalItems[i].journalAction.code) === -1) {
              isPristine = false;
              break;
            }
          }
        } else {
          LogService.log('JournalManagement::modifyJournalStartDate() -> no journalItems found for supplied journal - did you forget to fetch the details first?', 'debug');
          isPristine = false;
        }
        return isPristine;
      },
      getCurrentlyActiveJournalsFromCustomer: function (customerId, contactId) {
        var that = this;
        // this is just a wrapper around getActiveJournalsFromCustomer() meant to take
        // validity into account (i.e. startdate not in the future).
        // -- It would be better if we could pass 'valid = true' as a filter parameter to the backend in the future --
        return new Promise(function (resolve) {
          var result = [];
          that.getActiveJournalsFromCustomer(customerId, contactId, 'validity')
          .then(function (activeJournals) {
            // we only want to allow consumption on currently valid journals (i.e. startdate not in the future)
            angular.forEach(activeJournals, function (currentJournal) {
              if (currentJournal.validity.valid) {
                result.push(currentJournal);
              }
            });
            resolve(result);
          });
        });
      },
      getActiveJournalsFromCustomer: function (customerId, contactId, detailsToFetch) {
        var params = {
              limit: 99
            },
            currentISODate = (new Date()).toISOString();

        params['filter[]'] = [
          'customer.id,' + customerId,
          'OR,termination,NULL,termination,GTE ' + currentISODate,
          'OR,expiration,NULL,expiration,GTE ' + currentISODate,
          'OR,credits,NULL,credits,GT 0'
        ];
        if (UtilService.isNotEmpty(contactId)) {
          params['filter[]'].push('OR,customerContacts.id,NULL,customerContacts.id,' + contactId);
        }

        return this.getJournals(params, detailsToFetch);
      },
      getJournalItems: function (journalId, actions) {
        var params = {
              limit: 99,
              sort: 'createdAt,DESC'
            },
            actionFilterString = 'OR,';

        if (!UtilService.isNotEmpty(journalId)) {
          LogService.log('Get journal items: journalId parameter empty!', 'debug');
          return false;
        }

        // if actions were passed as a parameter, add them as a filter
        if (UtilService.isNotEmpty(actions)) {
          params['filter[]'] = [];
          // convert string argument to array
          if (angular.isString(actions)) {
            actions = actions.split(',');
          }

          // construct action code filter
          if (angular.isArray(actions)) {
            angular.forEach(actions, function (action) {
              actionFilterString += 'journalAction.code,' + action.toUpperCase();
            });
          }

          params['filter[]'] = actionFilterString;
        }

        return JournalFactory.one(journalId)
        .getList('items', params);
      },
      terminateJournal: function (journalId, terminationDate) {
        if (!terminationDate) {
          terminationDate = new Date();
        }
        return this.postJournalItem(journalId, 'termination', {
          date: terminationDate
        });
      },
      shiftJournalItemDate: function (journalId, itemCodes, shiftInMillSeconds) {
        var that = this;
        // shifting the end date of a journal involves finding the recharge/renewal journal item and
        // setting the date of said item
        return new Promise(function (resolve, reject) {
          var itemsToPatch = [],
              date,
              millisecondsAsDays;

          if (!(journalId && shiftInMillSeconds)) {
            reject('JournalManagement::shiftJournalItemDate() -> Not all required parameters were passed.');
          }

          // convert milliseconds to days
          // milSecs / 1000 -> seconds
          // seconds / 60 -> minutes
          // 60 * 24 = number of minutes in a day
          millisecondsAsDays = shiftInMillSeconds / 1000 / 60 / (60 * 24);

          LogService.log('Shifting journal item dates for' + journalId + 'by' + millisecondsAsDays + 'days', 'debug');

          that.getJournalItems(journalId, itemCodes)
          .then(function (journalItems) {
            // collect objects containing the modified date and journal item
            angular.forEach(journalItems, function (currentItem) {
              if (currentItem.hasOwnProperty('date')) {
                date = new Date(currentItem.date);
              } else {
                date = new Date(currentItem.createdAt);
              }

              date.setDate(date.getDate() + millisecondsAsDays);

              itemsToPatch.push({
                item: currentItem,
                date: new Date(date)
              });
            });

            // use a promise loop to apply all the changes asynchronously before resolving
            UtilService.promiseLoop(itemsToPatch, function (current) {
              LogService.log('Setting date' + current.date + 'for journal item' + current.item, 'debug');
              return current.item.patch({
                date: current.date.toISOString()
              });
            })
            .then(function () {
              resolve();
            });
          });
        });
      },
      isExpiredJournal: function (journal) {
        var now = {
              date: new Date(),
              seconds: Math.round(new Date().getTime() / 1000)
            },
            journalExpirationDate = {
              date: new Date(journal.expiration),
              seconds: Math.round(new Date(journal.expiration).getTime() / 1000)
            };

        return journalExpirationDate.seconds < now.seconds;
      }
    };
  }
}());
