(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .factory('ContactFactory', ContactFactory);

  function ContactFactory(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'contact'});
      })
      .service('contacts');
  }
}());
