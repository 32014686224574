(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SubscriptionCommentEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SubscriptionCommentEditCtrl', SubscriptionCommentEditCtrl);

  function SubscriptionCommentEditCtrl(
    SubscriptionFactory,
    UtilService,
    $modalInstance,
    subscription
  ) {
    var vm = this;
    vm.subscription = subscription;
    vm.cancel = cancelModalInstance;
    vm.comments = UtilService.isNotEmpty(subscription.comments) ? subscription.comments : '';
    vm.importantComment = UtilService.isNotEmpty(subscription.importantComment) ? subscription.importantComment : false;
    vm.saveSubscriptionComments = saveSubscriptionComments;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveSubscriptionComments() {
      SubscriptionFactory
      .one(vm.subscription.id)
      .patch({
        comments: vm.comments,
        importantComment: vm.importantComment
      })
      .then(function () {
        // Close the modal view
        $modalInstance.close();
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }
  }
}());
