(function () {
  'use strict';

  angular
    .module('event')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('event_categories', {
        url: '/events/categories',
        views: {
          '@': {
            templateUrl: 'event/views/event-categories.tpl.html'
          },
          'nav@event_categories': {
            templateUrl: 'event/views/event-categories.nav.tpl.html'
          },
          'content@event_categories': {
            templateUrl: 'event/views/event-categories.list.tpl.html',
            controller: [
              'EventCategoryFactory',
              function (EventCategoryFactory) {
                var vm = this,
                    params = {
                      limit: 99,
                      sort: 'label,ASC'
                    };

                EventCategoryFactory.getList(params).then(function (results) {
                  vm.eventCategories = results;
                });
              }
            ],
            controllerAs: 'eventCategoryCtrl'
          }
        }
      })
      .state('event_categories.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'event/views/event-categories.add.edit.modal.tpl.html',
            controller: 'EventCategoryCtrl',
            controllerAs: 'eventCategoryCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('event_categories.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'event/views/event-categories.add.edit.modal.tpl.html',
            controller: 'EventCategoryCtrl',
            controllerAs: 'eventCategoryCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('event_categories.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'event/views/event-categories.delete.modal.tpl.html',
            controller: 'EventCategoryCtrl',
            controllerAs: 'eventCategoryCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
