(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:CurrentPosInstanceFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('CurrentPosInstanceFactory', CurrentPosInstanceFactory);

  function CurrentPosInstanceFactory(
     PosGroupFactory,
     UtilService,
     $cookies,
     LogService
    ) {
    return {
      getPosGroupId: function () {
        return this.getInstanceCookies().posGroupId;
      },
      getPosInstanceId: function () {
        return this.getInstanceCookies().posInstanceId;
      },
      getPosSessionId: function () {
        return this.getInstanceCookies().posSessionId;
      },
      isPosSessionCookieSet: function () {
        return (UtilService.isNotEmpty(this.getPosSessionId));
      },
      getInstanceCookies: function () {
        var cookies = {
          posInstanceId: $cookies.get('currentPosInstanceId'),
          posGroupId: $cookies.get('currentPosGroupId'),
          noInstanceSelected: $cookies.get('noPosInstanceSelected'),
          posSessionId: $cookies.get('currentPosSessionId')
        };
        return cookies;
      },
      removeInstanceCookies: function () {
        LogService.log('CurrentPosInstanceFactory::removeInstanceCookies() -> Removing all instance cookies.', 'debug');
        $cookies.remove('currentPosGroupId');
        $cookies.remove('currentPosInstanceId');
        $cookies.remove('noPosInstanceSelected');
        $cookies.remove('currentPosSessionId');
      },
      arePosInstanceCookiesSet: function () {
        var cookies = this.getInstanceCookies();
        return (UtilService.isNotEmpty(cookies.posInstanceId) && UtilService.isNotEmpty(cookies.posGroupId));
      },
      isNoInstanceCookieSet: function () {
        var cookies = this.getInstanceCookies();
        return (UtilService.isNotEmpty(cookies.noInstanceSelected) && cookies.noInstanceSelected === 'true');
      },
      isInstanceSelected: function () {
        return (!this.isNoInstanceCookieSet() && this.arePosInstanceCookiesSet());
      },
      validatePosCookies: function () {
        var that = this;
        return new Promise(function (resolve) {
          var params = {limit: 99}, cookies;

          LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> Validating P.O.S. cookies.', 'debug');
          if (that.isNoInstanceCookieSet()) {
            LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> "No instance" cookies is set.', 'debug');
            resolve(true);
          } else if (that.arePosInstanceCookiesSet()) {
            cookies = that.getInstanceCookies();
            // check if the id's are valid
            params['filter[]'] = ['id,' + cookies.posGroupId];
            PosGroupFactory.getList(params).then(function (groups) {
              if (groups.count === 0) {
                LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> Invalid P.O.S. group id!', 'debug');
                that.removeInstanceCookies();
                resolve(false);
              }
              params['filter[]'] = ['id,' + cookies.posInstanceId];
              PosGroupFactory.one(cookies.posGroupId).getList('instances', params)
              .then(function (instances) {
                if (instances.count === 0) {
                  LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> Invalid P.O.S. instance id!', 'debug');
                  that.removeInstanceCookies();
                  resolve(false);
                } else if (UtilService.isNotEmpty(cookies.posSessionId)) {
                  // check if the pos session id is valid
                  params['filter[]'] = ['id,' + cookies.posSessionId];
                  PosGroupFactory
                  .one(cookies.posGroupId)
                  .one('instances')
                  .one(cookies.posInstanceId)
                  .getList('sessions', params)
                  .then(function (sessions) {
                    if (!sessions.count) {
                      LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> The session id was not found for the current group/instance combination - removing this cookie!', 'debug');
                      $cookies.remove('currentPosSessionId');
                    }
                  });
                }
                LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> P.O.S. cookies are valid', 'debug');
                resolve(true);
              });
            });
          } else {
            LogService.log('CurrentPosInstanceFactory::validatePosCookies() -> No valid pos cookies found.', 'debug');
            // wipe the cookies to be sure there are no dangling ids
            that.removeInstanceCookies();
            resolve(false);
          }
        });
      },
      setPosInstanceCookies: function (posGroupId, posInstanceId) {
        var now, exp;
        now = new Date();
        // set the expiration to 12 months
        exp = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
        $cookies.put('currentPosGroupId', posGroupId, {expires: exp});
        $cookies.put('currentPosInstanceId', posInstanceId, {expires: exp});
        $cookies.put('noPosInstanceSelected', false);
      },
      setNoInstanceCookie: function () {
        $cookies.put('noPosInstanceSelected', true);
        $cookies.remove('currentPosGroupId');
        $cookies.remove('currentPosInstanceId');
        $cookies.remove('currentPosSessionId');
      },
      getCurrentPosInstance: function () {
        var that = this;
        return new Promise(function (resolve) {
          var posInstanceCookies = that.getInstanceCookies();

          if (that.isInstanceSelected()) {
            PosGroupFactory.one(posInstanceCookies.posGroupId)
            .one('instances').one(posInstanceCookies.posInstanceId)
            .get()
            .then(function (instance) {
              resolve(instance);
            });
          } else {
            resolve(null);
          }
        });
      }
    };
  }
}());
