(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:RestUtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .factory('RestUtilService', RestUtilService);

  function RestUtilService(
    $q,
    LogService,
    UtilService,
    $filter
  ) {
    return {
      attachFilterStrings: function (queryParamsObject, filterObject) {
        var filterValue,
            filterCondition,
            filterString;

        if (angular.isObject(filterObject) && angular.isObject(queryParamsObject) && UtilService.isNotEmpty(filterObject)) {
          // construct filter variables
          angular.forEach(filterObject, function (filterVars, filterKey) {
            filterValue = '';
            filterCondition = '';
            filterString = '';

            if (UtilService.isNotEmpty(filterVars.val)) {
              // convert to date format if necessary
              filterValue = (UtilService.isNotEmpty(filterVars.isDate) && filterVars.isDate) ? $filter('date')(filterVars.val, 'yyyy-MM-dd"T"HH:mm:ssZ') : filterVars.val;

              if (UtilService.isNotEmpty(filterVars.cond)) {
                filterCondition = filterVars.cond;
              }

              if (UtilService.isNotEmpty(filterVars.isParam) && filterVars.isParam) {
                queryParamsObject[filterVars.field] = filterValue;
              } else {
                filterString = filterVars.field + ',';
                if (filterCondition) {
                  filterString += filterCondition + ' ';
                }
                filterString += filterValue;

                queryParamsObject['filter[' + filterKey + ']'] = [filterString];
              }
            }
          });
        } else {
          LogService.log('Called with non-object or empty filterObject/queryParamsObject!', 'error', 'RestUtilService::ConstructFilterStrings');
        }
      },
      getFullList: function (factory, parameters, subElement, list) {
        var actualParameters = parameters || {},
            actualList = list || [],
            actualSubElement = subElement || false,
            actualArguments = [];

        if (!actualParameters.limit) {
          actualParameters.limit = 99;
        }

        if (!actualParameters.offset) {
          actualParameters.offset = 0;
        }

        if (actualSubElement) {
          actualArguments.push(actualSubElement);
        }

        actualArguments.push(actualParameters);

        return $q(function (resolve, reject) {
          var offsetsToRetrieve = [],
              remainder = 0,
              blockSize = actualParameters.limit,
              currentOffsetValue = 0;
          // Call apply function on factory, passing this and argument array.
          // This call will retrieve the initial block of data, along with a count value
          // telling us the total number of results.
          factory.getList.apply(factory, actualArguments).then(function (results) {
            // collect results, either from subarray "data" of just the response
            if (angular.isUndefined(results.count)) {
              angular.forEach(results.data, function (result) {
                actualList.push(result);
              });
            } else {
              angular.forEach(results, function (result) {
                actualList.push(result);
              });
            }

            // determine the number of results that still need to be fetched
            remainder = (angular.isUndefined(results.count) ? results.data.count : results.count) - blockSize;

            // set current offset value to blockSize since we already fetched the first block
            currentOffsetValue = blockSize;

            // determine all the offsets that still need to be fetched
            while (remainder > 0) {
              offsetsToRetrieve.push(currentOffsetValue);
              currentOffsetValue += blockSize;
              remainder -= blockSize;
            }

            // retrieve all the offsets asynchronously and resolve when it's done.
            UtilService.promiseLoop(offsetsToRetrieve, function (offset) {
              actualParameters.offset = offset;
              return factory.getList.apply(factory, actualArguments)
                .then(function (offsetResults) {
                  actualList = actualList.concat(offsetResults);
                });
            }).then(function () {
              LogService.log('Fetched ' + actualList.length + ' records.', 'debug', 'RestUtilService::getFullList');
              resolve(actualList);
            }, function (errorResponse) {
              reject(errorResponse);
            });

            // if (actualList.length < (angular.isUndefined(results.count) ? results.data.count : results.count)) {
            //   actualParameters.offset += actualParameters.limit;

            //   that.getFullList(factory, actualParameters, actualSubElement, actualList)
            //   .then(function (res) {
            //     resolve(res);
            //   }, function (errorResponse) {
            //     reject(errorResponse);
            //   });
            // } else {
            //   resolve(actualList);
            // }
          }, function (errorResponse) {
            // call to apply failed for some reason
            reject(errorResponse);
          });
        });
      }
    };
  }
}());
