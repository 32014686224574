(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.controller:FitnessAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .controller('FitnessAddEditCtrl', FitnessAddEditCtrl);

  function FitnessAddEditCtrl(
    $filter,
    $modalInstance,
    ColorFactory,
    CurrentUserContextFactory,
    EventCategoryFactory,
    EventFactory,
    EventTypeFactory,
    FacilityFactory,
    FitnessCourseFactory,
    fitnessEvent,
    ImageFactory,
    JournalTypeFactory,
    reloadType,
    SettingsService,
    ToastrNotificationService
  ) {
    var vm = this,
        searchParams = {
          limit: 200,
          sort: 'label,asc'
        },
        facilitySearchParams = {
          limit: 99,
          sort: 'label,asc',
          'filter[]': ['site.id,' + CurrentUserContextFactory.getSiteId()]
        };
    vm.fitnessEvent = fitnessEvent;
    vm.isEdit = isEdit;
    vm.showSavedNotification = showSavedNotification;
    vm.saveFitnessEvent = saveFitnessEvent;
    vm.cancel = cancelModalInstance;
    vm.eventName = '';
    vm.eventDescription = '';
    vm.eventIsActive = false;
    vm.displayOnWebsite = false;
    vm.defaultConfirmationHours = 3;
    vm.defaultReservationDays = 14;
    vm.defaultReservationHours = 1;
    vm.onlineEvent = false;
    vm.reminderMailHoursBeforeEvent = null;
    vm.journalTypes = [];
    vm.eventJournalTypes = [];
    vm.eventCategories = [];
    vm.eventEventCategories = [];
    vm.getEventJournalTypesIds = getEventJournalTypesIds;
    vm.existingEventJournalTypes = existingEventJournalTypes;
    vm.color = false;
    vm.photoObject = null;
    vm.calendarEnabled = SettingsService.get('settings.enableCalendar', false);
    vm.rawPhoto = null;
    vm.setColor = setColor;
    vm.savePhoto = savePhoto;

    if (vm.isEdit()) {
      FitnessCourseFactory.one(vm.fitnessEvent.id).get().then(function (result) {
        vm.fitnessEvent = result;
        if (angular.isDefined(result.photo)) {
          ImageFactory.one(result.photo.id).get().then(function (photo) {
            vm.photoObject = photo;
          });
        }
      });
    }

    EventCategoryFactory.getList({limit: 99}).then(function (results) {
      vm.eventCategories = results;
    });

    JournalTypeFactory.getList(searchParams)
      .then(function (resultTypes) {
        vm.journalTypes = resultTypes;
        FacilityFactory.getList(facilitySearchParams).then(function (returnFacilities) {
          vm.facilities = returnFacilities;
          EventTypeFactory.getList(searchParams).then(function (returnEventTypes) {
            vm.eventTypes = returnEventTypes;
            if (vm.isEdit()) {
              FitnessCourseFactory.one(vm.fitnessEvent.id).get().then(function (returnFitnessEvent) {
                vm.fitnessEvent = returnFitnessEvent;
                vm.eventName = vm.fitnessEvent.label;
                vm.eventDescription = vm.fitnessEvent.comments;
                vm.eventIsActive = vm.fitnessEvent.active;
                vm.displayOnWebsite = vm.fitnessEvent.displayOnWebsite;
                vm.defaultConfirmationHours = vm.fitnessEvent.defaultConfirmationHours;
                vm.defaultReservationHours = vm.fitnessEvent.defaultReservationHours;
                vm.onlineEvent = vm.fitnessEvent.onlineEvent;
                vm.defaultReservationDays = vm.fitnessEvent.defaultReservationDays;
                vm.reminderMailHoursBeforeEvent = (vm.fitnessEvent.reminderMailHoursBeforeEvent || null);
                vm.eventJournalTypes = vm.existingEventJournalTypes(vm.fitnessEvent.journalTypes);
                vm.eventEventCategories = vm.fitnessEvent.events[0].eventCategories.map(function (v) {
                  return v.id;
                });
                vm.eventFacility = vm.fitnessEvent.events[0].facility;
                vm.eventType = vm.fitnessEvent.events[0].eventType;
                vm.color = vm.fitnessEvent.tags[0];
              });
            }
          });
        });
      });

    function setColor(color) {
      vm.color = color;
    }

    function isEdit() {
      return vm.fitnessEvent !== null;
    }

    function saveFitnessEvent() {
      var fitnessObject = {
            label: vm.eventName,
            active: vm.eventIsActive,
            comments: vm.eventDescription,
            displayOnWebsite: vm.displayOnWebsite,
            defaultConfirmationHours: vm.defaultConfirmationHours,
            defaultReservationHours: vm.defaultReservationHours,
            onlineEvent: vm.onlineEvent,
            reminderMailHoursBeforeEvent: vm.reminderMailHoursBeforeEvent === null ? 0 : vm.reminderMailHoursBeforeEvent,
            defaultReservationDays: vm.defaultReservationDays,
            journalTypes: vm.getEventJournalTypesIds(),
            photo: (vm.photoObject !== null) ? vm.photoObject.id : null,
            tags: [vm.color]
          },
          eventObject = {
            label: vm.eventName,
            eventType: vm.eventType.id,
            facility: vm.eventFacility.id,
            eventCategories: vm.eventEventCategories,
            displayOnWebsite: vm.displayOnWebsite
          };

      if (vm.isEdit()) {
        // If edit mode
        return EventFactory.one(vm.fitnessEvent.events[0].id).patch(eventObject).then(function () {
          FitnessCourseFactory.one(vm.fitnessEvent.id).patch(fitnessObject).then(function () {
            vm.showSavedNotification();
            $modalInstance.close(reloadType);
          });
        });
      }

      // If not edit mode
      fitnessObject.site = CurrentUserContextFactory.getSiteId();
      eventObject.site = CurrentUserContextFactory.getSiteId();
      return EventFactory.post(eventObject).then(function (newEvent) {
        fitnessObject.events = [newEvent.id];
        return FitnessCourseFactory.post(fitnessObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      });
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.event')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.event')
          )
        )
      );
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function getEventJournalTypesIds() {
      var idList = [];
      angular.forEach(vm.eventJournalTypes, function (journalType) {
        idList.push(journalType.id);
      });
      return idList;
    }

    function existingEventJournalTypes(journalTypes) {
      var idList = [];
      angular.forEach(journalTypes, function (journalType) {
        idList.push(journalType.id);
      });

      return $filter('filter')(vm.journalTypes, function (jt) {
        return idList.indexOf(jt.id) !== -1;
      });
    }

    function savePhoto() {
      if (vm.fitnessEvent.photo !== null) {
        vm.photoObject = {
          label: vm.fitnessEvent.label,
          encoding: 'base64',
          data: vm.rawPhoto.base64
        };

        ImageFactory.post(vm.photoObject).then(function (newPhoto) {
          vm.photoObject.id = newPhoto.id;
          vm.fitnessEvent.photo = vm.photoObject;
        });
      } else {
        vm.photoObject.data = vm.photo.base64;
        ImageFactory.one(vm.photoObject.id).patch({data: vm.photo.base64});
      }
    }
  }
}());
