(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .factory('CustomerFactory', CustomerFactory);

  function CustomerFactory(
    Restangular,
    RestUtilService
  ) {
    var customerService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'customer'});
    })
    .service('customers');

    return {
      getList: function (params) {
        return customerService.getList(params);
      },
      one: function (fragment) {
        return customerService.one(fragment);
      },
      getCustomerBalance: function (customerId) {
        return customerService.one(customerId).one('balance').get();
      },
      saveCustomerBalance: function (customerId, balanceVars) {
        var balanceId = null;
        if (balanceVars.hasOwnProperty('id')) {
          balanceId = balanceVars.id;
          delete balanceVars.id;
          return customerService.one(customerId)
          .one('balances')
          .one(balanceId)
          .patch(balanceVars, {'x-entity': 'customerbalance'});
        }
        // id parameter not supplied, post suspension
        return customerService.one(customerId)
        .post('balances', balanceVars, {'x-entity': 'customerbalance'});
      },
      removeCustomerBalance: function (customerId, balanceId) {
        return customerService.one(customerId)
          .one('balances')
          .one(balanceId)
          .remove();
      },
      post: function (object) {
        return customerService.post(object);
      },
      getCustomerBalanceEntries: function (customerId) {
        var params = {
          limit: 99,
          sort: 'createdAt,ASC'
        };
        return RestUtilService.getFullList(customerService.one(customerId), params, 'balances');
      },
      remove: function (customerId) {
        return customerService.one(customerId).remove();
      }
    };
  }
}());
