(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('ledger')
    .factory('LedgerFactory', LedgerFactory);

  function LedgerFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'ledger'});
    })
    .service('ledgers');
  }
}());
