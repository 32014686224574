(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .factory('MobileLanguageFactory', MobileLanguageFactory);

  function MobileLanguageFactory(Restangular) {
    return Restangular.service('mobile/mobile_language');
  }
}());
