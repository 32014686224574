(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:AccountancyCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('AccountancyCtrl', AccountancyCtrl);

  function AccountancyCtrl($scope,
                           $translate,
                           $state,
                           SettingsService,
                           UtilService,
                           PARAMS,
                           PermissionsUtilService) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.allowedSelectPos = SettingsService.get('accountancy.allowedSelectPos', false);
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.showSaleAddEditModal = showSaleAddEditModal;
    vm.broadcastInvoiceReloadEvent = broadcastInvoiceReloadEvent;
    vm.broadcastSalesReloadEvent = broadcastSalesReloadEvent;
    /* ----- END FUNCTIONS ----- */

    /* ----- SALE MODAL ----- */
    function showSaleAddEditModal(viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'accountancy/views/accountancy.sale.addedit.modal.tpl.html',
          controller: 'SaleAddEditCtrl',
          controllerAs: 'saleAddEditCtrl',
          size: 'lg',
          resolve: {
            sale: function () {
              return angular.isUndefined(viewObject) ? null : viewObject;
            }
          }
        },
        function (returnValue) {
          switch (returnValue) {
            case 'reloadInvoiceListData':
              vm.broadcastInvoiceReloadEvent();
              break;
            default:
            case 'reloadSalesListData':
              vm.broadcastSalesReloadEvent();
              break;
          }
        }
      );
    }

    /* ----- END SALE MODAL ----- */

    /* ----- EMIT FUNCTIONS ----- */
    $scope.$on('emitSaleEditEvent', function (event, sale) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        vm.showSaleAddEditModal(sale);
      }
    });
    /* ----- END EMIT FUNCTIONS ----- */

    /* ----- TO BROADCAST EVENTS ----- */
    function broadcastInvoiceReloadEvent() {
      $scope.$broadcast('broadcastInvoiceReloadEvent');
    }

    function broadcastSalesReloadEvent() {
      $scope.$broadcast('broadcastSalesReloadEvent');
    }

    /* ----- END TO BROADCAST EVENTS ----- */

    /* ----- TABS OF ACCOUNTANCY ----- */
    vm.newDesignVersion = PARAMS.designVersion === 2 ? true : false;
    vm.tabs = [];

    if (PermissionsUtilService.userHasPermission('access accountancy sales section')) {
      vm.tabs.push({
        heading: $translate.instant('accountancy.list.accountancy'),
        route: 'accountancy.list.accountancy'
      });
    }

    vm.tabs.push({
      heading: $translate.instant('accountancy.list.pos'),
      route: 'accountancy.list.pos'
    });

    if (PermissionsUtilService.userHasPermission('access accountancy invoice section')) {
      vm.tabs.push({
        heading: $translate.instant('accountancy.invoices'),
        route: 'accountancy.invoices'
      });
    }

    if (PermissionsUtilService.userHasPermission('access accountancy collection periods section')) {
      vm.tabs.push({
        heading: $translate.instant('accountancy.list.collection-periods'),
        route: 'accountancy.list.collection-periods'
      });
    }

    if (PermissionsUtilService.userHasPermission('access accountancy periods section')) {
      vm.tabs.push({
        heading: $translate.instant('accountancy.list.accountancy-periods'),
        route: 'accountancy.list.accountancy-periods'
      });
    }

    if (PermissionsUtilService.userHasPermission('access accountancy stuck sale')) {
      vm.tabs.push({
        heading: $translate.instant('accountancy.list.stuck-sale'),
        route: 'accountancy.list.stuck-sale'
      });
    }

    if (vm.newDesignVersion) {
      vm.tabs[0].active = true;
    }

    vm.setActiveTab = function (tab) {
      angular.forEach(vm.tabs, function (value, key) {
        vm.activeRoute = vm.tabs[tab].route;
        value.active = key === tab;
      });
      $state.go(vm.activeRoute, {tab: tab}, {reload: false});
    };
    /* ----- END TABS OF ACCOUNTANCY ----- */
  }
}());
