(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerContactListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerContactListCtrl', CustomerContactListCtrl);

  function CustomerContactListCtrl($filter,
                                   $state,
                                   $localStorage,
                                   ContactFactory,
                                   ContactService,
                                   CustomerFactory,
                                   LogService,
                                   RestUtilService,
                                   SettingsService,
                                   ToastrNotificationService,
                                   UtilService) {
    var vm = this;
    vm.clearSearch = clearSearch;
    vm.downloadReport = downloadReport;
    vm.customerGroups = [];
    vm.customerSearchQuery = '';
    vm.getFilteredCustomerList = getFilteredCustomerList;
    vm.insertCustomerGroupsIfNew = insertCustomerGroupsIfNew;
    vm.totalRecordCount = 0;
    // vm.getContact = getContact;
    vm.showModalView = showModalView;
    vm.showModalAddEditView = showModalAddEditView;
    vm.toggleCustomerVisibility = toggleCustomerVisibility;
    vm.anonymize = anonymize;
    vm.isNotBillingType = isNotBillingType;
    vm.showCustomer = showCustomer;
    vm.getAppliedContactFilters = getAppliedContactFilters;
    vm.filterRelevantCustomersFromCustomerGroup = filterRelevantCustomersFromCustomerGroup;
    vm.checkNestedObjectForValue = checkNestedObjectForValue;
    vm.getContactDetails = getContactDetails;
    vm.customerInfoMaxItems = 20;
    vm.showCustomerInfo = showCustomerInfo;
    vm.assignCustomerInfo = assignCustomerInfo;
    vm.hideCustomerInfo = hideCustomerInfo;
    vm.customerInfoShown = false;
    vm.showActiveCustomersFilter = !SettingsService.get('customers.hideActiveCustomersFilter', false);
    vm.showSearchButton = SettingsService.get('customers.showSearchButton', false);
    vm.hideExternalDebtorSearchField = SettingsService.get('customer.hideExternalDebtorSearchField', false);
    vm.hideTagsSearchField = SettingsService.get('customer.hideTagsSearchField', false);
    vm.searchSSOEnabled = SettingsService.get('customer.searchSSOEnabled', false);
    vm.searchCompanyNumberEnabled = SettingsService.get('customer.searchCompanyNumberEnabled', false);
    vm.hideFilterSitesSearchField = SettingsService.get('customer.hideFilterSitesSearchField', true);
    vm.customerSearchAllSitesFilter = vm.hideFilterSitesSearchField;
    vm.searchByEmailEnabled = SettingsService.get('customer.searchEmailInputEnabled', false);

    vm.filters = {
      customer: {
        val: '',
        field: 'label',
        cond: 'LIKE'
      },
      contact: {
        val: '',
        field: 'customerContacts.contact.label',
        cond: 'LIKE'
      },
      street: {
        val: '',
        field: 'customerContacts.contact.contactLocations.location.street',
        cond: 'LIKE'
      },
      city: {
        val: '',
        field: 'customerContacts.contact.contactLocations.location.city',
        cond: 'LIKE'
      },
      ssn: {
        val: '',
        field: 'customerContacts.contact.contactData.ContactStringData.value',
        cond: 'LIKE'
      },
      company: {
        val: '',
        field: 'customerContacts.contact.contactData.ContactCompanyNumberData.value',
        cond: 'LIKE'
      },
      memberCardNumber: {
        val: '',
        field: 'memberCards.label',
        cond: 'LIKE'
      },
      externalDebtorNumber: {
        val: '',
        field: 'externalDebtorNumber',
        cond: 'LIKE'
      },
      activeInactiveCustomers: {
        type: 'RADIO',
        selectedCode: 'ALL',
        items: [{
          label: $filter('uconlyfirst')($filter('translate')('app.all')),
          code: 'ALL'
        }, {
          label: $filter('uconlyfirst')($filter('translate')('app.customers.active')),
          code: 'ACTIVE'
        }, {
          label: $filter('uconlyfirst')($filter('translate')('app.customers.inactive')),
          code: 'INACTIVE'
        }]
      },
      hidden: {
        val: 0,
        field: 'hidden',
        cond: ''
      },
      customerTag: {
        type: 'INPUT',
        val: ''
      },
      email: {
        val: '',
        field: 'customerContacts.contact.contactData.ContactEmailData.value',
        cond: 'LIKE'
      }
    };

    // Get the customers
    vm.getFilteredCustomerList();

    // collect those filters which are active and act on the customer ( = contact) level
    function getAppliedContactFilters() {
      var result = {};
      angular.forEach(vm.filters, function (filter, key) {
        if (angular.isUndefined(filter.type)) {
          if (filter.hasOwnProperty('val') && angular.isDefined(filter.val) && filter.val.length &&
            filter.field.indexOf('customerContacts.contact.') > -1) {
            result[key] = filter;
          }
        } else {
          LogService.log('Customer List Ctrl: Check if special filters are filled in', 'debug');
          switch (key) {
            case 'activeInactiveCustomers':
              angular.forEach(filter.items, function (item) {
                if (item.code === filter.selectedCode) {
                  switch (item.code) {
                    case 'ACTIVE':
                    case 'INACTIVE':
                      result[key] = filter;
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
            case 'customerTag':
              if (filter.val.length > 0) {
                result[key] = filter;
              }
              break;
            default:
              break;
          }
        }
      });
      return result;
    }

    // ----------------------------------READ ME!-------------------------------------
    // When we ask the backend for a customer group with criterium "customer.label like x"
    // we get the entire customer group back.
    // This extra filtering done in the frontend is meant to pick out just the customer
    // we are interested in from that entire group.
    //
    // If the applied filter relates to customerGroups rather than customers,
    // we can therefore assume that the entire group already meets the criterium.
    // (because the backend already did this filtering for us)
    // So, in that case we just add all the customers from the group.
    //  We also add all customers from all groups when no filters apply
    // -------------------------------------------------------------------------------
    function filterRelevantCustomersFromCustomerGroup() {
      var setFilters, performFiltering, result;
      result = [];

      // this function filters the list of customers to get the final resultset
      performFiltering = function () {
        // now filter the collected customers
        setFilters = getAppliedContactFilters();
        if (Object.keys(setFilters).length > 0) {
          // whittle down the results untill all filters are applied
          // angular.forEach(setFilters, function (filter) {
          applyCustomerContactsFilter(result, setFilters);
          // });
          // Filter result list with 3 contact Filters
        } else {
          angular.forEach(result, function (customerObject) {
            vm.customers.push(customerObject);
          });
          vm.assignCustomerInfo();
        }
      };

      // get all customers
      angular.forEach(vm.customerGroups, function (customerGroup) {
        // get all customer objects from this group
        angular.forEach(customerGroup.customerContacts, function (customerContact) {
          result.push({
            customer: customerGroup,
            contact: customerContact
          });
        });
      });

      performFiltering();
    }

    function applyCustomerContactsFilter(customers, filters) {
      var filterString = [],
          filterParams = {};
      // retrieve the relevant keys from the dot-seperated string in filter.field
      angular.forEach(filters, function (filter, key) {
        if (angular.isUndefined(filter.type)) {
          if (filter.field.indexOf('customerContacts.contact.') > -1) {
            if (filter.field.replace('customerContacts.contact.', '') === 'label') {
              customers = customers.filter(function (customer) {
                return customer.contact.contact.label.toLowerCase().indexOf(filter.val.toLowerCase()) > -1;
              });
              filterString.push(filter.field.replace('customerContacts.contact.', '') + ',LIKE ' + filter.val);
            } else {
              filterString.push(filter.field.replace('customerContacts.contact.', '') + ',LIKE ' + filter.val);
            }
          }
        } else {
          LogService.log('Customer List Ctrl: Filter is from special type.', 'debug');
          switch (key) {
            case 'activeInactiveCustomers':
              angular.forEach(filter.items, function (item) {
                if (item.code === filter.selectedCode) {
                  switch (item.code) {
                    case 'ACTIVE':
                      filterParams.customerIsActive = true;
                      break;
                    case 'INACTIVE':
                      filterParams.customerIsInactive = true;
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
            case 'customerTag':
              if (filter.val.length > 0) {
                filterParams.customerTag = filter.val;
              }
              break;
            default:
              break;
          }
        }
      });
      filterParams['filter[]'] = filterString;
      RestUtilService.getFullList(ContactFactory, filterParams).then(function (resultContacts) {
        angular.forEach(customers, function (singleCustomer) {
          if (resultContacts.filter(function (c) {
            return c.id === singleCustomer.contact.contact.id;
          }).length) {
            vm.customers.push(singleCustomer);
          }
        });
        vm.assignCustomerInfo();
      });
    }

    // given an array of keys, descend into a json object until that field is found - then check
    // if it contains the value we're looking for
    function checkNestedObjectForValue(object, keys, searchValue) {
      var currentKey, subObjectContainsValue, i;
      // just in case...
      if (!keys.length) {
        return false;
      }
      currentKey = keys.shift();
      if (object.hasOwnProperty(currentKey) || angular.isDefined(object[currentKey])) {
        if (keys.length === 0) {
          // end of the line reached!
          return object[currentKey].toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
        }
        // not done yet, keep going...
        // is array? split off into different drill-downs
        if (object[currentKey] instanceof Array) {
          subObjectContainsValue = false;
          for (i = 0; i < object[currentKey].length; ++i) {
            if (checkNestedObjectForValue(object[currentKey][i], angular.copy(keys), searchValue)) {
              subObjectContainsValue = true;
              break;
            }
          }
          return subObjectContainsValue;
        }
        // not array? drill down
        return checkNestedObjectForValue(object[currentKey], keys, searchValue);
      }

      return false;
    }

    function getFilteredCustomerList() {
      var queryParams, value, condition;
      vm.hideCustomerInfo();

      queryParams = {
        limit: 99,
        siteFilter: !vm.customerSearchAllSitesFilter ? 1 : 0
      };
      vm.customerGroups = [];

      if ($localStorage.customerFilter) {
        if (Object.keys($localStorage.customerFilter).length !== Object.keys(vm.filters).length) {
          $localStorage.customerFilter = vm.filters;
        }
        vm.filters = $localStorage.customerFilter;
      } else {
        $localStorage.$default({customerFilter: vm.filters});
      }

      angular.forEach(vm.filters, function (filter, index) {
        if (angular.isUndefined(filter.type)) {
          if (angular.isDefined(filter.val) && (filter.val || filter.val === 0)) {
            value = filter.val;
            condition = '';

            if (filter.cond) {
              condition = filter.cond + ' ';
            }
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
          }
        } else {
          LogService.log('Customer List Ctrl: Filter is from special type.', 'debug');
          switch (index) {
            case 'activeInactiveCustomers':
              angular.forEach(filter.items, function (item) {
                if (item.code === filter.selectedCode) {
                  switch (item.code) {
                    case 'ACTIVE':
                      queryParams.customerIsActive = true;
                      break;
                    case 'INACTIVE':
                      queryParams.customerIsInactive = true;
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
            case 'customerTag':
              if (filter.val.length > 0) {
                queryParams.customerTag = filter.val;
              }
              break;
            default:
              break;
          }
        }
      });

      // ----------------READ THIS!---------------------
      // frontend: customerGroup === backend: customer
      // frontend: customer === backend: customerContact
      // -----------------------------------------------
      CustomerFactory.getList(queryParams)
        .then(function (resultCustomerGroups) {
          if (angular.isDefined(resultCustomerGroups.count)) {
            vm.totalRecordCount = resultCustomerGroups.count;
          }
          // Add newly found customer-groups to the list
          vm.insertCustomerGroupsIfNew(resultCustomerGroups);
          vm.customers = [];
          vm.filterRelevantCustomersFromCustomerGroup();
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
    }

    // add customer groups to the list if they aren't already on it
    function insertCustomerGroupsIfNew(customerGroups) {
      angular.forEach(customerGroups, function (group) {
        // Check if vm.customerGroups contains the group already.
        if (vm.customerGroups.filter(function (addedCustomerGroup) {
          return group.id === addedCustomerGroup.id;
        }).length === 0) {
          vm.customerGroups.push(group);
        }
      });
    }

    function getContactDetails(contact) {
      // Get first customerContact of a customer
      return new Promise(function (resolve) {
        CustomerFactory.one(contact.customer.id)
          .one('contacts').one(contact.contact.id)
          .get()
          .then(function (completeCustomerContact) {
            // Get a specific Contact
            ContactFactory.one(completeCustomerContact.contact.id)
              .get()
              .then(function (contactDetails) {
                contact.contact.completeContact = contactDetails;
                resolve();
              });
          });
      });
    }

    function showModalView(viewObject) {
      // Open modal view with specific template and controller, Add a customer and contact object to that controller
      UtilService.showModal(
        {
          templateUrl: 'contact/views/contact.modal.view.tpl.html',
          controller: 'ContactCtrl',
          controllerAs: 'contactCtrl',
          size: 'lg',
          resolve: {
            contact: function () {
              return viewObject.contact;
            },
            customer: function () {
              return viewObject.customer;
            },
            showOnlineAccessInfo: function () {
              return true;
            }
          }
        },
        modalResultHandler,
        modalFailureHandler);
      vm.returnOfModalInstance();
    }

    function showModalAddEditView(currentObject) {
      vm.getContactDetails(currentObject)
        .then(function () {
          UtilService.showModal({
            templateUrl: 'contact/views/contact.modal.addedit.tpl.html',
            controller: 'ContactAddEditCtrl',
            controllerAs: 'contactAddEditCtrl',
            size: 'lg',
            resolve: {
              customer: function () {
                return currentObject.customer;
              },
              customerContact: function () {
                return currentObject.contact;
              }
            }
          }, function () {
            vm.getFilteredCustomerList();
          });
        });
    }

    function sendToggleVisibility(customer) {
      return CustomerFactory.one(customer.id).patch({hidden: !customer.hidden});
    }

    function sendAnonymize(customer) {
      return CustomerFactory.one('anonymize').one(customer.id).post();
    }

    function anonymize(customer) {
      sendAnonymize(customer).then(
        function () {
          vm.getFilteredCustomerList();
        },
        function () {
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.error_occured',
            ''
          );
        }
      );
    }

    function toggleCustomerVisibility(customer) {
      function onSuccess() {
        ToastrNotificationService.showTranslatedNotification(
          'success',
          customer.hidden ? 'app.customer_shown' : 'app.customer_hidden',
          ''
        );
        vm.getFilteredCustomerList();
      }

      function onError() {
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.error_occured',
          ''
        );
      }

      if (!customer.hidden) {
        UtilService.showModal({
          templateUrl: 'customer/views/customer.modal.hide.tpl.html',
          controller: 'CustomerHideCtrl',
          controllerAs: 'customerHideCtrl',
          size: 'md',
          resolve: {
            customer: function () {
              return customer;
            }
          }
        }, function (hide) {
          (hide ? sendAnonymize(customer) : sendToggleVisibility(customer))
            .then(onSuccess, onError);
        });
      } else {
        sendToggleVisibility(customer).then(onSuccess, onError);
      }
    }

    function showCustomer(contact) {
      $state.go('customerdetail', {id: contact.customer.id}, {reload: true});
    }

    function isNotBillingType(item) {
      return !(item.hasOwnProperty('contact') && item.contact.customerContactType.code === 'BILLING');
    }

    function modalResultHandler() {
      // Reload the list data
      vm.getFilteredCustomerList();
    }

    function modalFailureHandler() {
    }

    function clearSearch() {
      vm.filters.customer.val = '';
      vm.filters.contact.val = '';
      vm.filters.street.val = '';
      vm.filters.city.val = '';
      vm.filters.ssn.val = '';
      vm.filters.company.val = '';
      vm.filters.memberCardNumber.val = '';
      vm.filters.externalDebtorNumber.val = '';
      vm.filters.activeInactiveCustomers.selectedCode = 'ALL';
      vm.filters.hidden.val = false;
      vm.filters.customerTag.val = '';
      vm.getFilteredCustomerList();
    }

    function downloadReport() {
      ContactService.getContactsReport(vm.filters).then(function () {
        ToastrNotificationService.showNotification(
          'info',
          $filter('uconlyfirst')(
            $filter('translate')('app.report-download-mail')
          ),
          $filter('uconlyfirst')(
            $filter('translate')('app.report-download-mail-extended')
          )
        );
      });
    }

    function assignCustomerInfo() {
      var contactDetails = null;
      angular.forEach(vm.customerGroups, function (customerGroup) {
        angular.forEach(customerGroup.customerContacts, function (customerContact) {
          if (customerContact.customerContactType.code !== 'USER') {
            return;
          }
          contactDetails = customerContact.contact;
          customerGroup.mobileNumber = '-';
          customerGroup.email = '-';

          angular.forEach(contactDetails.contactData, function (contactData) {
            switch (contactData.contactDataType.code) {
              case 'MOBILE_NUMBER':
                customerGroup.mobileNumber = contactData.value;
                break;

              case 'EMAIL':
                if (contactData.contactDataContext.code === 'HOME') {
                  customerGroup.email = contactData.value;
                }
                break;

              default:
                break;
            }
          });
        });
      });
    }

    function showCustomerInfo() {
      if (vm.totalRecordCount < vm.customerInfoMaxItems) {
        angular.forEach(vm.customerGroups, function (customerGroup) {
          angular.forEach(customerGroup.customerContacts, function (customerContact) {
            if (customerContact.customerContactType.code !== 'USER') {
              return;
            }

            ContactFactory.one(customerContact.contact.id).get().then(function (contactDetails) {
              customerGroup.city = '-';
              customerGroup.address = '-';
              angular.forEach(contactDetails.contactLocations, function (contactLocation) {
                if (contactLocation.contactDataContext.code === 'HOME') {
                  customerGroup.city = contactLocation.location.city;
                  customerGroup.address = contactLocation.location.street + ' ' + contactLocation.location.number;
                }
              });
            });
          });
        });

        vm.customerInfoShown = true;
      } else {
        ToastrNotificationService.showNotification(
          'warning',
          '',
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('customer.errorTooManyRecordsInList'),
              vm.customerInfoMaxItems
            )
          )
        );
      }
    }

    function hideCustomerInfo() {
      vm.customerInfoShown = false;
    }
  }
}());
