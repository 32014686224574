(function () {
  'use strict';

  angular
    .module('consolidationCode')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('consolidation-code', {
        url: '/consolidation-code',
        views: {
          '@': {
            templateUrl: 'consolidation-code/views/consolidation-code.tpl.html'
          },
          'nav@consolidation-code': {
            templateUrl: 'consolidation-code/views/consolidation-code.nav.tpl.html'
          },
          'content@consolidation-code': {
            templateUrl: 'consolidation-code/views/consolidation-code.list.tpl.html',
            controller: [
              'ConsolidationCodeFactory',
              'RestUtilService',
              function (ConsolidationCodeFactory, RestUtilService) {
                var vm = this;
                RestUtilService.getFullList(ConsolidationCodeFactory).then(function (resultData) {
                  vm.consolidationCodes = resultData;
                });
              }
            ],
            controllerAs: 'consolidationCodeCtrl'
          }
        }
      })
      .state('consolidation-code.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'consolidation-code/views/consolidation-code.add.edit.modal.tpl.html',
            controller: 'ConsolidationCodeCtrl',
            controllerAs: 'consolidationCodeCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('consolidation-code.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'consolidation-code/views/consolidation-code.add.edit.modal.tpl.html',
            controller: 'ConsolidationCodeCtrl',
            controllerAs: 'consolidationCodeCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
      .state('consolidation-code.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'consolidation-code/views/consolidation-code.delete.modal.tpl.html',
            controller: 'ConsolidationCodeCtrl',
            controllerAs: 'consolidationCodeCtrl'
          }).result.then(function () {
            $state.go('^', {}, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go('^', {}, {reload: true});
            }
          });
        }]
      })
    ;
  }
}());
