(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:DigipolisOrdersCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('DigipolisOrdersCtrl', DigipolisOrdersCtrl);

  function DigipolisOrdersCtrl($scope,
                       _,
                       $filter,
                       $state,
                       $stateParams,
                       $timeout,
                       DigipolisOrderFactory,
                       LogService,
                       ProductUtilService,
                       Restangular,
                       SettingsService,
                       ToastrNotificationService,
                       UtilService,
                       $modal
  ) {
    var vm = this;
    vm.orders = [];
    vm.order = null;
    vm.filterChanged = filterChanged;
    vm.loadOrder = loadOrder;
    vm.loadOrders = loadOrders;
    vm.loadSingleOrdersPage = loadSingleOrdersPage;
    vm.confirmTransaction = confirmTransaction;
    vm.cancelTransaction = cancelTransaction;
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.ProductUtilService = ProductUtilService;
    vm.goBack = goBack;
    vm.goTo = goTo;
    vm.enableAllSite = false;
    vm.pagination = {
      totalItems: null,
      maxPages: 10,
      currentPage: 1,
      itemsPerPage: 20
    };
    vm.sums = [];

    vm.filters = {
      beginDate: {
        val: '',
        field: 'createdAt',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.beginDate.isOpen = true;
        }
      },
      endDate: {
        val: '',
        field: 'createdAt',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        allowNullIfSet: true,
        open: function () {
          vm.filters.endDate.isOpen = true;
        }
      },
      ogm: {
        val: '',
        field: 'ogm',
        cond: 'LIKE'
      },
      orderId: {
        val: '',
        field: 'orderId',
        cond: 'LIKE'
      },
      orderEngineStatus: {
        val: ''
      }
    };

    if (angular.isDefined($stateParams.id)) {
      vm.loadOrder($stateParams.id, $stateParams.searchOnAllSites);
    } else if (angular.isDefined($stateParams.tab) && ($stateParams.tab === 'digipolisOrders' || $stateParams.tab === 'digipolisIndividualOrderTab')) {
      vm.currentTab = $stateParams.tab;
      vm.srefToTab = $stateParams.tab === 'digipolisOrders';
      vm.loadOrders();
    }

    function goBack() {
      $state.go('reports', {tab: (angular.isDefined($stateParams.backTo) ? $stateParams.backTo : '')});
    }

    function goTo(id) {
      $state.go('digipolis_order', {id: id, backTo: $stateParams.tab, searchOnAllSites: vm.enableAllSite});
    }

    function loadSingleOrdersPage(page) {
      var value, queryParams = {}, condition = '', loadingModal, typeAndSaleFiltersExist = false;

      queryParams = {
        offset: (page - 1) * vm.pagination.itemsPerPage,
        limit: vm.pagination.itemsPerPage,
        sort: 'createdAt,DESC'
      };

      loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });

      _.each(vm.filters, function (filter, index) {
        if (angular.isDefined(filter.val) && filter.val) {
          switch (index) {
            case 'beginDate':
              filter.val.setHours(0);
              filter.val.setMinutes(0);
              filter.val.setSeconds(0);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'endDate':
              filter.val.setHours(23);
              filter.val.setMinutes(59);
              filter.val.setSeconds(59);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'orderEngineStatus':
              if (angular.isUndefined(queryParams['filter[]'])) {
                queryParams['filter[]'] = [];
              }
              if (filter.val === '0') {
                queryParams['filter[]'].push('isConfirmed,0');
                queryParams['filter[]'].push('externalId,NULL');
              } else if (filter.val === '1') {
                queryParams['filter[]'].push('isConfirmed,1');
                queryParams['filter[]'].push('externalId,NULL');
              } else if (filter.val === '2') {
                queryParams['filter[]'].push('externalId,NOT NULL');
              }
              return;
            default:
              value = filter.val;
              break;
          }

          condition = '';
          if (UtilService.isNotEmpty(filter.cond)) {
            condition = filter.cond + ' ';
          }

          if (filter.hasOwnProperty('isParam') && filter.isParam) {
            queryParams[index] = value;
          } else {
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            if (angular.isDefined(filter.allowNullIfSet) && filter.allowNullIfSet === true) {
              queryParams['filter[' + index + ']'] = 'OR,' + queryParams['filter[' + index + ']'] + ',' + filter.field + ',NULL';
            }
          }
        }
      });

      if (vm.enableAllSite === true) {
        queryParams.displayAllSalesForCurrentUserByHisSites = true;
      }

      LogService.log('DigipolisOrdersCtrl::loadSingleOrdersPage() -> Loading page: ' + page, 'debug');

      if (angular.isDefined($stateParams.tab)) {
        if (angular.isUndefined(queryParams['filter[]'])) {
          queryParams['filter[]'] = [];
        }
        queryParams['filter[]'].push(($stateParams.tab === 'digipolisOrders' ? 'individualOrder,0' : 'individualOrder,1'));
        queryParams['filter[]'].push(($stateParams.tab === 'digipolisIndividualOrderTab' ? 'isConfirmed,1' : ''));
        queryParams['filter[]'].push(($stateParams.tab === 'digipolisIndividualOrderTab' ? 'type,EQ invoice' : 'type,EQ banktransfer'));

        if ($stateParams.tab === 'digipolisOrders') {
          queryParams['filter[]'].push('sale.id,NULL');
        }

        typeAndSaleFiltersExist = true;
      }

      return DigipolisOrderFactory.getTransactionsList(queryParams, typeAndSaleFiltersExist)
        .then(function (data) {
          $timeout(function () {
            vm.orders[page] = data;
            vm.pagination.totalItems = data.count;
          });
          angular.forEach(data, function (order) {
            // load "total" for not sent orders
            if (!order.externalId) {
              vm.loadOrder(order.id, vm.enableAllSite).then(function () {
                order.total = vm.sums[order.id].requestInclPrice;
              });
            }
          });
          loadingModal.close();
        });
    }

    function loadOrders(reload) {
      LogService.log('DigipolisOrdersCtrl::loadOrders() -> Loading Digipolis Orders.', 'debug');

      if (reload) {
        return vm.loadSingleOrdersPage(1);
      }

      if (angular.isUndefined(vm.orders[vm.pagination.currentPage])) {
        return vm.loadSingleOrdersPage(vm.pagination.currentPage);
      }
    }

    function loadOrder(id, searchOnAllSites) {
      var loadingModal = $modal.open({
            template: '<div class="modal-body">\n' +
              '  {{ "app.loading" | translate | uconlyfirst }}\n' +
              '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
              '          <span class="icons">\n' +
              '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
              '          </span>\n' +
              '      </span>\n' +
              '</div>',
            size: 'sm'
          }), queryParams = {};
      if (angular.isDefined(searchOnAllSites) && searchOnAllSites && searchOnAllSites !== 'false') {
        queryParams.displayAllSalesForCurrentUserByHisSites = true;
      }
      return DigipolisOrderFactory.one(id).get(queryParams).then(function (order) {
        var sums = { requestPrice: 0, requestInclPrice: 0, responsePrice: 0, responseInclPrice: 0 };

        // set api request preview as api request if present
        if (UtilService.isEmpty(order.apiRequest) && order.apiRequestPreview) {
          order.apiRequest = angular.fromJson(order.apiRequestPreview);
        }
        vm.order = order;

        if (order.apiRequest && order.apiRequest.items) {
          angular.forEach(order.apiRequest.items, function (item) {
            item.vatAmount = ProductUtilService.roundPrice((item.unitPrice * item.vatRate) / 100);
            item.unitPriceIncl = ProductUtilService.roundPrice(item.unitPrice) + item.vatAmount;

            item.totalPrice = item.price + (item.vatAmount * item.quantity);
            if (!item.isUnaccountable) {
              sums.requestPrice += item.price;
              sums.requestInclPrice += item.totalPrice;
            }
          });
          angular.forEach(order.items, function (orderItem, key) {
            if (angular.isDefined(order.apiRequest.items[key]) &&
              order.apiRequest.items[key].articleCode === orderItem.articleCode &&
              angular.isDefined(order.apiRequest.items[key].accountancy)) {
              orderItem.ASTDNumber = order.apiRequest.items[key].accountancy.originalRepayASTDNumber;
            }
          });
        }
        if (order.externalId) {
          angular.forEach(order.items, function (item) {
            item.vatAmount = ProductUtilService.roundPrice((item.unitPrice * item.vatRate) / 100);
            item.unitPriceIncl = ProductUtilService.roundPrice(item.unitPrice) + item.vatAmount;

            item.totalPrice = item.price + (item.vatAmount * item.quantity);
            sums.responsePrice += item.price;
            sums.responseInclPrice += item.totalPrice;
          });
        }
        vm.sums[order.id] = sums;
        loadingModal.close();
      });
    }

    function filterChanged() {
      vm.loadOrders(true);
    }

    function confirmTransaction() {
      LogService.log('DigipolisOrdersCtrl::confirmTransaction() -> confirming', 'debug');

      return Restangular.all('points_of_sale/confirm_transaction').one(vm.order.id).get().then(function (transaction) {
        LogService.log('DigipolisOrdersCtrl::confirmTransaction -> success: ', 'debug');
        LogService.log(transaction, 'debug');

        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'success',
          'app.success',
          '',
          'accountancy.sale_order_engine_notification_successfully_requested'
        );

        $state.go('reports');
      });
    }

    function cancelTransaction() {
      LogService.log('DigipolisOrdersCtrl::cancelTransaction()', 'debug');

      return DigipolisOrderFactory.one(vm.order.id).remove().then(function () {
        $state.go('reports', {tab: 'sessions'});
      });
    }
  }
}());
