(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SessionAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SessionAddEditCtrl', SessionAddEditCtrl);

  function SessionAddEditCtrl(
      LevelFactory,
      SwimCourseSetFactory,
      $cookies,
      LogService,
      $filter,
      $modalInstance,
      $rootScope,
      UtilService,
      set,
      series,
      session
    ) {
    var vm = this;
    vm.startTime = new Date();
    vm.currentSet = set;
    vm.currentSeries = series;
    vm.currentSession = session;
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.getSessionRestObject = getSessionRestObject;
    vm.getSeriesRestObject = getSeriesRestObject;
    vm.saveSessions = saveSessions;
    vm.deleteSession = deleteSession;
    vm.addHighLightedDate = addHighLightedDate;
    vm.setStartDate = setStartDate;
    vm.createBatchSessions = createBatchSessions;
    vm.defaultInterval = 'P7D';
    vm.startDate = null;
    vm.highLightedDate = null;
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.removeDate = removeDate;
    vm.selectedDates = [];
    vm.duplicateLastEntry = duplicateLastEntry;
    vm.broadcastRefreshSubscriptionsMatrixEvent = broadcastRefreshSubscriptionsMatrixEvent;
    vm.selectedDateOffset = null;
    vm.selectedTimeOffset = null;

    vm.selectedDateOffsetUnit = null;
    vm.dateOffsetUnits = [
      {
        label: $filter('translate')('app.days'),
        type: 'day'
      },
      {
        label: $filter('translate')('app.weeks'),
        type: 'week'
      },
      {
        label: $filter('translate')('app.months'),
        type: 'month'
      }
    ];

    vm.selectedTimeOffsetUnit = null;
    vm.timeOffsetUnits = [
      {
        label: $filter('translate')('app.minutes'),
        type: 'minute'
      },
      {
        label: $filter('translate')('app.hours'),
        type: 'hour'
      }
    ];

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function removeDate(date) {
      var index = vm.selectedDates.indexOf(date);
      if (index > -1) {
        vm.selectedDates.splice(index, 1);
      }
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function getSeriesRestObject() {
      var restObject = {
        startsAt: vm.startDate,
        interval: vm.defaultInterval
      };

      return restObject;
    }

    function getSessionRestObject(date) {
      var restObject = {
        startsAt: date
      };

      return restObject;
    }

    function duplicateLastEntry() {
      var index, newDate;

      if (UtilService.isNotEmpty(vm.selectedDates)) {
        index = vm.selectedDates.length - 1;
        newDate = new Date(vm.selectedDates[index].valueOf());

        if (UtilService.isNotEmpty(vm.selectedDateOffset) &&
          UtilService.isNotEmpty(vm.selectedDateOffsetUnit)) {
          switch (vm.selectedDateOffsetUnit.type) {
            case 'day':
              newDate.setDate(newDate.getDate() + vm.selectedDateOffset);
              break;
            case 'week':
              newDate.setDate(newDate.getDate() + (vm.selectedDateOffset * 7));
              break;
            case 'month':
              newDate.setMonth(newDate.getMonth() + vm.selectedDateOffset);
              break;
            default:
              break;
          }
        }
        if (UtilService.isNotEmpty(vm.selectedTimeOffset) &&
          UtilService.isNotEmpty(vm.selectedTimeOffsetUnit)) {
          switch (vm.selectedTimeOffsetUnit.type) {
            case 'minute':
              newDate.setMinutes(newDate.getMinutes() + vm.selectedTimeOffset);
              break;
            case 'hour':
              newDate.setHours(newDate.getHours() + vm.selectedTimeOffset);
              break;
            default:
              break;
          }
        }

        vm.selectedDates.push(newDate);
      }
    }

    function setStartDate() {
      var earliest = null;
      angular.forEach(vm.selectedDates, function (date) {
        if (earliest === null || earliest > date) {
          earliest = date;
        }
      });
      vm.startDate = earliest;
    }

    function addHighLightedDate() {
      var fullDate;

      if (vm.highLightedDate !== null && vm.startTime !== null) {
        fullDate = vm.highLightedDate;
        fullDate.setHours(vm.startTime.getHours());
        fullDate.setMinutes(vm.startTime.getMinutes());
        vm.selectedDates.push(fullDate);
        vm.highLightedDate = null;
        setStartDate();
      }
    }

    function deleteSession() {
      if (isEdit()) {
        SwimCourseSetFactory.one(vm.currentSet.id).remove()
        .then(function () {
          vm.broadcastRefreshSubscriptionsMatrixEvent();
        });
      }
      // Close the modal view
      $modalInstance.close();
    }

    // create sessions in batch
    function createBatchSessions() {
      var seriesRestObject;

      seriesRestObject = vm.getSeriesRestObject();

      if (UtilService.isNotEmpty(vm.currentSet)) {
        if (UtilService.isNotEmpty(vm.currentSeries)) {
          saveSelectedDatesToBackend(vm.currentSeries);
        } else {
          SwimCourseSetFactory.one(vm.currentSet.id)
          .post('series', seriesRestObject)
          .then(function (createdSeries) {
            saveSelectedDatesToBackend(createdSeries);
          });
        }
      }
    }

    function saveSelectedDatesToBackend(serieToSave) {
      var sessionRestObject, datesToPost = 0;
      angular.forEach(vm.selectedDates, function (date) {
        ++datesToPost;
        sessionRestObject = vm.getSessionRestObject(date);
        SwimCourseSetFactory.one(vm.currentSet.id).one('series').one(serieToSave.id)
        .post('sessions', sessionRestObject)
        .then(function () {
          --datesToPost;
          if (datesToPost <= 0) {
            LogService.log('Done posting, closing modal', 'debug');
            vm.broadcastRefreshSubscriptionsMatrixEvent();
            // Close the modal view
            $modalInstance.close();
          }
        });
      });
    }

    function broadcastRefreshSubscriptionsMatrixEvent() {
      LogService.log('Broadcasting refresh subscriptions matrix event', 'debug');
      $rootScope.$broadcast('refreshSubscriptionsMatrix');
    }

    function saveSessions() {
      var reloadOption = 'reloadListData', setObject;

      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        setObject = getSessionRestObject();
        // Patch the set
        LevelFactory.one(vm.currentLevel.id).patch(setObject).then(function () {
          vm.broadcastRefreshSubscriptionsMatrixEvent();
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        setObject = getSessionRestObject(true);
        // Create the set
        LevelFactory.post(setObject).then(function () {
          vm.broadcastRefreshSubscriptionsMatrixEvent();
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function isEdit(object) {
      switch (object) {
        case 'set':
          return vm.currentSet !== null;
        case 'series':
          return vm.currentSeries !== null;
        case 'session':
          return vm.currentSession !== null;
        default:
          return vm.currentSet !== null || vm.currentSeries !== null || vm.currentSession !== null;
      }
    }
  }
}());
