(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name site.factory:SiteFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('site')
    .factory('SiteFactory', SiteFactory);

  function SiteFactory(Restangular) {
    var sitesService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'site'});
    })
    .service('sites');

    sitesService.getPermittedSitesForAction = function (action) {
      return sitesService.one('permitted-for-' + action).get();
    };

    sitesService.manageable = sitesService.one('manageable');

    return sitesService;
  }
}());
