(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name memberCard.controller:MemberCardAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('memberCard')
    .controller('MemberCardAddEditCtrl', MemberCardAddEditCtrl);

  function MemberCardAddEditCtrl(
      MemberCardFactory,
      MemberCardTypeFactory,
      $filter,
      $modalInstance,
      currentObject,
      customer,
      $rootScope,
      LogService,
      hwproxy
    ) {
    var vm = this;
    vm.currentMemberCard = currentObject;
    vm.isEdit = isEdit;
    vm.memberCardTypes = null;
    vm.memberCardLabel = null;
    vm.memberCardType = null;
    vm.aKaartLabel = null;
    vm.cancel = cancelModalInstance;
    vm.saveMemberCard = saveMemberCard;

    // Get memberCardTypes
    MemberCardTypeFactory.getList({limit: 99}).then(function (resultTypes) {
      vm.memberCardTypes = resultTypes;
      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        vm.memberCardLabel = vm.currentMemberCard.label;
        // Filter the memberCardType and select the memberCardType of the current MemberCard
        vm.memberCardType = $filter('filter')(vm.memberCardTypes, function (mct) {
          return mct.id === vm.currentMemberCard.memberCardType.id;
        })[0];
      }
    }).then(function () {
      $rootScope.$on(hwproxy.options.cardReader.unknownCardEventName, function (event, data) {
        vm.memberCardLabel = data.event.number;
        vm.aKaartLabel = data.event.tracks[1]['a-kaart'];
        vm.memberCardType = $filter('filter')(vm.memberCardTypes, function (mct) {
          return mct.code === 'MEMBER_CARD';
        })[0];
      });
    });

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveMemberCard(reloadOption) {
      var memberCardObject = {
            label: vm.memberCardLabel,
            customer: customer.id,
            memberCardType: vm.memberCardType.id
          }, aKaartObject = {
            label: vm.aKaartLabel,
            customer: customer.id,
            memberCardType: vm.memberCardType.id
          };
      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        // Patch an existing memberCard
        MemberCardFactory.one(vm.currentMemberCard.id).patch(memberCardObject).then(function () {
          // Close the modal view
          $modalInstance.close('reloadMembercards');
        });
      } else {
        // Check if A-Kaart was presented
        if (aKaartObject.label !== null && angular.isDefined(aKaartObject.label)) {
          MemberCardFactory.post(aKaartObject).then(function () {
            LogService.log('A-Kaart Added!');
          });
        }
        // Post the new memberCard
        MemberCardFactory.post(memberCardObject).then(function () {
          // Close the modal view
          LogService.log('reloadOption:' + reloadOption, 'debug');
          $modalInstance.close('reloadMembercards');
        });
      }
    }

    // Method that check's if we're in Edit-mode
    function isEdit() {
      return vm.currentMemberCard !== null;
    }
  }
}());
