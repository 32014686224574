(function () {
  'use strict';

  /**
   * @ngdoc object
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .factory('SavingsCampaignSubscriptionsFactory', SavingsCampaignSubscriptionsFactory);

  function SavingsCampaignSubscriptionsFactory(Restangular) {
    return Restangular.service('savings_campaign_subscriptions');
  }
}());
