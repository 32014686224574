(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:AttendanceCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('AttendanceCtrl', AttendanceCtrl);

  function AttendanceCtrl(
    $modalInstance,
    set,
    series,
    seriesGroupId,
    group,
    session,
    subscriptions,
    SubscriptionFactory,
    SwimCourseParticipantStatusFactory,
    SwimCourseManagementFactory,
    SwimCourseSetFactory
  ) {
    var vm = this;
    vm.statuses = {};
    vm.set = set;
    vm.series = series;
    vm.seriesGroupId = seriesGroupId;
    vm.group = group;
    vm.session = session;
    vm.subscriptions = subscriptions;
    vm.close = closeModalInstance;
    vm.cancel = cancelModalInstance;
    vm.loadData = loadData;
    vm.noteAttendance = noteAttendance;
    vm.noteAbsent = noteAbsent;
    vm.initialized = true;
    vm.attendances = [];
    vm.loadData();

    function loadData() {
      var subIndex,
          params = {
            limit: 99
          };
      params['filter[]'] = [];
      params['filter[]'].push('swimCourseSeriesGroup.id,' + vm.seriesGroupId);

      return SwimCourseParticipantStatusFactory.getList(params).then(function (statuses) {
        angular.forEach(statuses, function (status) {
          vm.statuses[status.code] = status;
        });

        SwimCourseSetFactory.one(vm.set.id).one('series').one(vm.series.id)
        .one('sessions').one(vm.session.id)
        .getList('participants', params)
        .then(function (resultParticipants) {
          angular.forEach(resultParticipants, function (rp) {
            subIndex = findSubscription(rp.swimCourseSubscription.id);
            if (subIndex > -1) {
              vm.subscriptions[subIndex].participant = rp;
              vm.subscriptions[subIndex].attended = rp.swimCourseParticipantStatus.code === 'ATTENDED';
              vm.subscriptions[subIndex].absent = rp.swimCourseParticipantStatus.code === 'ABSENT';
            }
            // fallback to only known attendances if we don't find a subscription (happens after subscriptions transfer)
            if (vm.subscriptions.length === 0) {
              SubscriptionFactory.one(rp.swimCourseSubscription.id).get().then(function (currentSubscription) {
                vm.attendances.push({
                  contact: currentSubscription.contact,
                  attended: rp.swimCourseParticipantStatus.code === 'ATTENDED',
                  absent: rp.swimCourseParticipantStatus.code === 'ABSENT'
                });
              });
            }
          });
        });
        vm.initialized = true;
      });
    }

    function findSubscription(subId) {
      var i, foundIndex = -1;
      for (i = 0; i < vm.subscriptions.length; ++i) {
        if (vm.subscriptions[i].id === subId) {
          foundIndex = i;
          break;
        }
      }
      return foundIndex;
    }

    function noteAttendance(subscription) {
      var participantId;
      if (!vm.initialized) {
        loadData.then(function () {
          vm.noteAttendance(subscription);
        });
      } else {
        participantId = subscription.hasOwnProperty('participant') ? subscription.participant.id : null;

        SwimCourseManagementFactory.noteSessionAttendanceByBoolean(
          vm.set.id,
          vm.series.id,
          vm.session.id,
          subscription.id,
          subscription.swimCourseSeriesGroup.id,
          subscription.attended,
          participantId
        )
        .then(function () {
          vm.loadData();
        });
      }
    }

    function noteAbsent(subscription) {
      var participantId;
      if (!vm.initialized) {
        loadData.then(function () {
          vm.noteAbsent(subscription);
        });
      } else {
        participantId = subscription.hasOwnProperty('participant') ? subscription.participant.id : null;
        SwimCourseManagementFactory.noteSessionAbsentByBoolean(
            vm.set.id,
            vm.series.id,
            vm.session.id,
            subscription.id,
            subscription.swimCourseSeriesGroup.id,
            subscription.absent,
            participantId
        )
            .then(function () {
              vm.loadData();
            });
      }
    }

    function closeModalInstance() {
      $modalInstance.close();
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
