(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:SubscriptionFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SubscriptionFactory', SubscriptionFactory);

  function SubscriptionFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseSubscription'});
    })
    .service('swim_courses/subscriptions');
  }
}());
