(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.controller:FitnessDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .controller('FitnessDeleteCtrl', FitnessDeleteCtrl);

  function FitnessDeleteCtrl(
    FitnessCourseFactory,
    $modalInstance,
    $filter,
    fitnessEvent,
    reloadType,
    ToastrNotificationService
    ) {
    var vm = this;
    vm.fitnessEvent = fitnessEvent;
    vm.cancel = cancelModalInstance;
    vm.deleteFitnessEvent = deleteFitnessEvent;
    vm.showDeleteNotification = showDeleteNotification;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deleteFitnessEvent() {
      // Delete a specific fitness event
      FitnessCourseFactory.one(vm.fitnessEvent.id).remove().then(function () {
        // Close the modal view
        vm.showDeleteNotification();
        $modalInstance.close(reloadType);
      });
    }

    function showDeleteNotification() {
      // Show notification at successful remove
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-removed'),
            $filter('translate')('app.event')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-successfully-removed'),
            $filter('translate')('app.event')
            )
          )
        );
    }
  }
}());
