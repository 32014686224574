(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name hwproxy.directive:posSwitchInstance
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="hwproxy">
   <file name="index.html">
   <hw-proxy-connection></hw-proxy-connection>
   </file>
   </example>
   *
   */
  /* @ngInject */
  angular
    .module('navigation')
    .directive('posSwitchInstance', posSwitchInstance);

  function posSwitchInstance() {
    return {
      restrict: 'AEC',
      template: '<a ng-show="posSwitchInstanceCtrl.showButton()" ng-click="posSwitchInstanceCtrl.showSelectPosInstanceModal()">{{ "pos.switch_session" | translate | uconlyfirst}}</a>',
      controllerAs: 'posSwitchInstanceCtrl',
      controller: [
        '$scope',
        '$rootScope',
        '$cookies',
        '$state',
        '$element',
        'LogService',
        'SettingsService',
        '$modal',
        '$timeout',
        'UtilService',
        'CurrentPosInstanceFactory',
        'CurrentUserContextFactory',
        function ($scope,
                  $rootScope,
                  $cookies,
                  $state,
                  $element,
                  LogService,
                  SettingsService,
                  $modal,
                  $timeout,
                  UtilService,
                  CurrentPosInstanceFactory,
                  CurrentUserContextFactory) {
          var vm = this;
          vm.posSessionButtonsDisplayOnlyOnPosScreen = SettingsService.get('pos.session.buttonsDisplayOnlyOnPosScreen', false);
          // select pos instance modal
          vm.showingSelectPosInstanceModal = false;
          vm.showSelectPosInstanceModal = showSelectPosInstanceModal;
          vm.selectPosInstanceModalInstance = null;
          vm.showButton = showButton;

          function showButton() {
            if (vm.posSessionButtonsDisplayOnlyOnPosScreen) {
              return $state.current.name === 'pos';
            }
            return true;
          }

          $scope.$on('showPosInstanceSelectionModal', function () {
            vm.showSelectPosInstanceModal();
          });

          function showSelectPosInstanceModal() {
            if (vm.showingSelectPosInstanceModal || $state.is('login') || $state.is('logout') ||
              $state.is('user_activation') || !CurrentUserContextFactory.isUserContextSelected()) {
              return;
            }

            LogService.log('Invoking select pos instance modal', 'debug');
            UtilService.showModal({
              templateUrl: 'pos/views/pos.selectinstance.modal.tpl.html',
              controller: 'SelectPosInstanceCtrl',
              controllerAs: 'selectPosInstanceCtrl',
              size: 'lg'
            }, function (returnValue) {
              LogService.log('Pos Instance Selection Modal: Closed => reason: ' + returnValue, 'debug');
              vm.selectPosInstanceModalInstance = null;
              vm.showingSelectPosInstanceModal = false;
              // move away from pos or reload page
              if (CurrentPosInstanceFactory.isInstanceSelected()) {
                // attempt reconnect with hwproxy and reload page
                $rootScope.$broadcast('attemptHwProxyConnection');
                UtilService.reloadState();
              } else if ($state.is('pos')) {
                $state.go('dashboard');
              }
            });

            vm.showingSelectPosInstanceModal = true;
          }
        }
      ]
    };
  }
}());
