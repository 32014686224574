(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:EndPosShiftCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('EndPosShiftCtrl', EndPosShiftCtrl);

  function EndPosShiftCtrl(
    _,
    $localStorage,
    $modalInstance,
    CurrentPosInstanceFactory,
    CurrentUserContextFactory,
    LogService,
    PaymentMethodsFactory,
    PermissionsUtilService,
    PosGroupFactory,
    PosSessionFactory,
    ProductUtilService,
    session,
    SettingsService,
    TicketFactory,
    ToastrNotificationService,
    UtilService
  ) {
    var vm = this;
    vm.add = add;
    vm.subtract = subtract;
    vm.posGroupId = null;
    vm.posGroupInstanceId = null;
    vm.activeSession = null;
    vm.endCurrentPosSession = endCurrentPosSession;
    vm.cancel = cancelModalInstance;
    vm.checkRegisterBalance = checkRegisterBalance;
    vm.loadBalanceInformation = loadBalanceInformation;
    vm.loadFormDataFromStorage = loadFormDataFromStorage;
    vm.permissionsUtilService = PermissionsUtilService;
    vm.printEndOfSessionTicket = printEndOfSessionTicket;
    vm.calcTotals = calcTotals;
    vm.loadData = loadData;
    vm.startAmount = 0;
    vm.findPaymentIndexByCode = findPaymentIndexByCode;
    vm.loadPaymentInformation = loadPaymentInformation;
    vm.saveFormDataToStorage = saveFormDataToStorage;
    vm.setPaymentAmountByCode = setPaymentAmountByCode;
    vm.unsetFormDataFromStorage = unsetFormDataFromStorage;
    vm.closeModalAndShowNotification = closeModalAndShowNotification;
    vm.dirtyBills = false;
    vm.totalIncome = 0;
    vm.sessionDataLoaded = false;
    vm.zeroSessionDifferenceRequired = SettingsService.get('pos.zeroSessionDifferenceRequired', false);
    vm.instantEndSession = SettingsService.get('pos.instantEndSession', false);
    vm.hideTotalsInputs = SettingsService.get('pos.hideShiftEndTotalsInputs', false);
    vm.posHideShiftEndTotalsReceived = SettingsService.get('pos.hideShiftEndTotalsReceived', false);
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.extendedApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '2');
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.approvalFlowVersion4Enabled = (SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.approvalFlowVersion4 = false;
    vm.sessionFetchSaldo = SettingsService.get('pos.session_fetch_saldo', false);

    vm.notes = [
      {
        imgSrc: '/images/currency/5_euro.png',
        value: 5,
        amount: 0
      },
      {
        imgSrc: '/images/currency/10_euro.png',
        value: 10,
        amount: 0
      },
      {
        imgSrc: '/images/currency/20_euro.png',
        value: 20,
        amount: 0
      },
      {
        imgSrc: '/images/currency/50_euro.png',
        value: 50,
        amount: 0
      },
      {
        imgSrc: '/images/currency/100_euro.png',
        value: 100,
        amount: 0
      },
      {
        imgSrc: '/images/currency/200_euro.png',
        value: 200,
        amount: 0
      }
    ];
    vm.coins = [
      {
        imgSrc: '/images/currency/1_ct.png',
        value: 0.01,
        amount: 0
      },
      {
        imgSrc: '/images/currency/2_ct.png',
        value: 0.02,
        amount: 0
      },
      {
        imgSrc: '/images/currency/5_ct.png',
        value: 0.05,
        amount: 0
      },
      {
        imgSrc: '/images/currency/10_ct.png',
        value: 0.1,
        amount: 0
      },
      {
        imgSrc: '/images/currency/20_ct.png',
        value: 0.2,
        amount: 0
      },
      {
        imgSrc: '/images/currency/50_ct.png',
        value: 0.5,
        amount: 0
      },
      {
        imgSrc: '/images/currency/1_euro.png',
        value: 1,
        amount: 0
      },
      {
        imgSrc: '/images/currency/2_euro.png',
        value: 2,
        amount: 0
      }
    ];

    vm.sessionPayments = [];
    vm.loadData();
    vm.approvalFlowVersion4 = false;

    function findPaymentIndexByCode(code) {
      if (UtilService.isNotEmpty(code)) {
        return _.findIndex(vm.sessionPayments, function (element) {
          return element.code.toLowerCase() === code.toLowerCase();
        });
      }
      return -1;
    }

    function loadData() {
      var instanceIds,
          weightOverride = {CASH: 0.1, DISCOUNT: 0.2, INVOICE: 0.3};
      CurrentPosInstanceFactory.getCurrentPosInstance().then(function (instance) {
        if (instance) {
          vm.approvalFlowVersion4 = instance.approvalFlowVersion4;
        }
      });

      // load active payment methods from backend
      PaymentMethodsFactory.getClosePosSessionList({
        limit: 99,
        'filter[]': [
          'site.id,' + CurrentUserContextFactory.getSiteId()
        ],
        sort: 'weight,asc'
      }).then(function (paymentMethods) {
        angular.forEach(paymentMethods, function (paymentMethod) {
          vm.sessionPayments.push({
            id: null,
            code: paymentMethod.code,
            label: paymentMethod.translatedLabel,
            amount: 0,
            weight: angular.isDefined(paymentMethod.weight) ? paymentMethod.weight : 9,
            editable: (['CASH', 'INVOICE', 'DISCOUNT'].indexOf(paymentMethod.code) === -1)
          });
        });
        // override the default order
        angular.forEach(vm.sessionPayments, function (sessionPayment) {
          if (weightOverride.hasOwnProperty(sessionPayment.code)) {
            sessionPayment.weight = weightOverride[sessionPayment.code];
          }
        });
        vm.sessionPayments.sort(function (a, b) {
          return (a.weight < b.weight) ? -1 : 1;
        });
      });

      // a session object was passed to this controller, so we're editing an old session
      if (UtilService.isNotEmpty(session)) {
        LogService.log('EndPosShiftCtrl::loadData() -> Loading data for session' + session.id, 'debug');
        vm.activeSession = session;
        vm.posGroupId = session.posGroupId;
        vm.posGroupInstanceId = session.posInstanceId;
        if (!vm.activeSession.wasTallied && !vm.hideTotalsInputs) {
          vm.loadFormDataFromStorage();
        }
        // load payment information (i.e. previously counted amounts)
        vm.loadPaymentInformation(vm.activeSession)
        .then(function () {
          return vm.loadBalanceInformation(vm.activeSession, vm.hideTotalsInputs)
          .then(function () {
            vm.sessionDataLoaded = true;
          });
        })
        .catch(function () {
          LogService.log('EndPosShiftCtrl::loadData() -> Session data could not be loaded.', 'debug');
          vm.closeModalAndShowNotification();
        });
      } else if (CurrentPosInstanceFactory.isInstanceSelected()) {
        LogService.log('EndPosShiftCtrl::loadData() -> Instance cookies found, searching for session.', 'debug');
        instanceIds = CurrentPosInstanceFactory.getInstanceCookies();
        vm.posGroupId = instanceIds.posGroupId;
        vm.posGroupInstanceId = instanceIds.posInstanceId;

        // if no session object was passed, check if we have a currently active session
        // but pass 'false' as a parameter so that we only check sessions for the current instance
        PosSessionFactory.getActivePosSession(false)
        .then(function (active) {
          if (active !== null) {
            LogService.log('EndPosShiftCtrl::loadData() -> Found session' + active.id, 'debug');
            vm.activeSession = active;

            // get start amount
            if (vm.activeSession.hasOwnProperty('startAmount')) {
              vm.startAmount = parseFloat(vm.activeSession.startAmount);
            }

            if (!vm.hideTotalsInputs) {
              vm.loadFormDataFromStorage();
            }
            return vm.loadBalanceInformation(vm.activeSession, vm.hideTotalsInputs)
            .then(function () {
              vm.sessionDataLoaded = true;
            });
          }
          return Promise.reject();
        })
        .catch(function () {
          LogService.log('EndPosShiftCtrl::loadData() -> No session active for the current instance.', 'debug');
          vm.closeModalAndShowNotification();
        });
      } else {
        LogService.log('EndPosShiftCtrl::loadData() -> No pos instance selected, checking active own sessions.', 'debug');
        // check if there are opened session for current user
        PosSessionFactory.getActiveSessions(true)
        .then(function (active) {
          if (active.length === 1) {
            LogService.log('EndPosShiftCtrl::loadData() -> Found session ' + active[0].id, 'debug');
            vm.activeSession = active[0];
            vm.posGroupInstanceId = vm.activeSession.pointOfSaleInstance.id;
            vm.posGroupId = vm.activeSession.pointOfSaleInstance.pointOfSaleGroup.id;
            vm.activeSession.posGroupId = vm.posGroupId;
            vm.activeSession.posInstanceId = vm.posGroupInstanceId;

            // get start amount
            if (vm.activeSession.hasOwnProperty('startAmount')) {
              vm.startAmount = parseFloat(vm.activeSession.startAmount);
            }

            if (!vm.hideTotalsInputs) {
              vm.loadFormDataFromStorage();
            }
            return vm.loadBalanceInformation(vm.activeSession, vm.hideTotalsInputs)
            .then(function () {
              vm.sessionDataLoaded = true;
            });
          }
          return Promise.reject();
        })
        .catch(function () {
          LogService.log('EndPosShiftCtrl::loadData() -> Multiple or 0 active own sessions found, cannot continue.', 'debug');
          vm.closeModalAndShowNotification();
        });
      }
    }

    function closeModalAndShowNotification() {
      // we were supposed to close down a session, but no active session was found
      // so show a notification and close the modal
      vm.cancel();
      ToastrNotificationService.showTranslatedNotification(
        'error',
        'app.no_session_short',
        'app.no_session_long'
      );
    }

    function loadPaymentInformation(activeSession) {
      // get start amount
      if (vm.activeSession.hasOwnProperty('startAmount')) {
        vm.startAmount = parseFloat(vm.activeSession.startAmount);
      }

      return PosSessionFactory.getPosSessionPayments(activeSession.posGroupId, activeSession.posInstanceId, activeSession.id)
      .then(function (payments) {
        angular.forEach(payments, function (payment) {
          vm.setPaymentAmountByCode(payment.paymentMethod.code, payment.amount, payment.id);
        });

        // once payments have been set, recalculate the total amount
        vm.calcTotals();
      });
    }

    function loadBalanceInformation(activeSession, copyToFormData) {
      LogService.log('Retrieving balance information for session ' + activeSession.id, 'debug');
      return PosGroupFactory.one(activeSession.posGroupId)
      .one('instances').one(activeSession.posInstanceId)
      .one('sessions').one(activeSession.id)
      .one('balance')
      .get()
      .then(function (balance) {
        LogService.log('Balance: ' + balance, 'debug');
        activeSession.balance = balance;
        if (angular.isDefined(copyToFormData) && copyToFormData) {
          angular.forEach(vm.sessionPayments, function (sessionPayment) {
            if (activeSession.balance.projectedPerPaymentMethod.hasOwnProperty(sessionPayment.code)) {
              sessionPayment.amount = activeSession.balance.projectedPerPaymentMethod[sessionPayment.code].EUR;
            }
          });
          vm.calcTotals();
        }
      });
    }

    function setPaymentAmountByCode(code, amount, id) {
      var index = vm.findPaymentIndexByCode(code);
      if (index > -1) {
        // set the id if it was passed
        if (UtilService.isNotEmpty(id)) {
          vm.sessionPayments[index].id = id;
        }
        if (UtilService.isNotEmpty(amount)) {
          LogService.log('Setting amount ' + parseFloat(amount) + ' for payment code ' + code, 'debug');
          vm.sessionPayments[index].amount = parseFloat(amount);
        }
      }
    }

    function endCurrentPosSession() {
      var projectedTotalAmount = 0,
          sessionPaymentObjects = [];

      return new Promise(function (resolve, reject) {
        // get income data for payment methods not entered by the clerk
        vm.loadBalanceInformation(vm.activeSession, vm.hideTotalsInputs)
        .then(function () {
          if (vm.activeSession.hasOwnProperty('balance')) {
            if (vm.activeSession.balance.hasOwnProperty('projectedPerPaymentMethod')) {
              if (vm.activeSession.balance.projectedPerPaymentMethod.hasOwnProperty('DISCOUNT') &&
                vm.activeSession.balance.projectedPerPaymentMethod.DISCOUNT.hasOwnProperty('EUR')) {
                vm.setPaymentAmountByCode('discount', vm.activeSession.balance.projectedPerPaymentMethod.DISCOUNT.EUR);
              }

              if (vm.activeSession.balance.projectedPerPaymentMethod.hasOwnProperty('INVOICE') &&
                vm.activeSession.balance.projectedPerPaymentMethod.INVOICE.hasOwnProperty('EUR')) {
                vm.setPaymentAmountByCode('invoice', vm.activeSession.balance.projectedPerPaymentMethod.INVOICE.EUR);
              }

              // now that we've retrieved the payments that won't be entered manually from the backend
              // calculate the total amount again
              vm.calcTotals();

              // if zero sale session difference is required
              // and a difference exists
              // then do not persist the session payments totals
              if (UtilService.isNotEmpty(vm.activeSession.balance.projectedAmount.EUR) && UtilService.isNotEmpty(vm.activeSession.balance.startAmount.EUR)) {
                projectedTotalAmount = ProductUtilService.roundPrice(
                  parseFloat(vm.activeSession.balance.projectedAmount.EUR) - parseFloat(vm.activeSession.balance.startAmount.EUR)
                );
              }

              if (vm.zeroSessionDifferenceRequired && projectedTotalAmount !== vm.totalIncome && !vm.instantEndSession) {
                ToastrNotificationService.showTranslatedNotification(
                  'error',
                  'app.error',
                  'app.end_amount_incorrect'
                );
                reject();
              } else {
                // if (vm.instantEndSession && vm.zeroSessionDifferenceRequired && projectedTotalAmount !== vm.totalIncome) {
                //   ToastrNotificationService.showTranslatedNotification(
                //     'error',
                //     'app.error',
                //     'app.end_amount_incorrect'
                //   );
                // }
                angular.forEach(vm.sessionPayments, function (sessionPayment) {
                  sessionPaymentObjects.push({
                    id: sessionPayment.id,
                    code: sessionPayment.code,
                    amount: sessionPayment.amount
                  });
                });
                return PosSessionFactory.persistSessionPaymentTotals(vm.activeSession.posGroupId, vm.activeSession.posInstanceId, vm.activeSession.id, sessionPaymentObjects, vm.startAmount)
                .then(function () {
                  LogService.log('Ended pos session', 'debug');
                  ToastrNotificationService.showTranslatedNotification(
                    'info',
                    'app.session_closed',
                    'app.session_closed_long'
                  );
                  vm.checkRegisterBalance();
                  vm.unsetFormDataFromStorage(vm.activeSession.id);
                  if (vm.extendedApprovalFlow && vm.useExternalOrderEngine) {
                    return PosSessionFactory.setCashierStatus(vm.activeSession).then(function () {
                      $modalInstance.close();
                    });
                  } else if (vm.automaticApprovalFlow && vm.useExternalOrderEngine) {
                    return PosSessionFactory.logAsSentToOrderEngine(vm.activeSession).then(function () {
                      $modalInstance.close();
                    });
                  } else if (vm.approvalFlowVersion4Enabled && vm.useExternalOrderEngine && !vm.approvalFlowVersion4) {
                    return PosSessionFactory.logAsSentToOrderEngine(vm.activeSession).then(function () {
                      $modalInstance.close();
                    });
                  }
                  $modalInstance.close();
                  resolve();
                }, function () {
                  reject();
                });
              }
            } else {
              reject('EndPosShiftCtrl::endCurrentPosSession() -> Session balance has no projectedPerPaymentMethod attached to it.');
            }
          } else {
            reject('EndPosShiftCtrl::endCurrentPosSession() -> Active session has no balance object attached to it.');
          }
        });
      });
    }

    // print the ticket with the end totals on it, and reprint it until such a time the clerk confirms the ticket was
    // printed correctly
    function printEndOfSessionTicket(posGroupId, posGroupInstanceId, activeSession, countedBills) {
      // groupId, instanceId, session, countedBills
      TicketFactory.printEndSessionTicket(
        posGroupId,
        posGroupInstanceId,
        activeSession,
        countedBills
      )
      .then(function () {
        UtilService.showTranslatedConfirmationModal('pos.session_ended', 'app.reprint_ticket', function (returnValue) {
          // Check if successful ticket print was confirmed by the clerk, if not try again
          // function calls itself if the 'reprint ticket' prompt returned true
          if (returnValue) {
            printEndOfSessionTicket(posGroupId, posGroupInstanceId, activeSession, countedBills);
          }
        });
      });
    }

    function checkRegisterBalance() {
      // get the new balance
      vm.loadBalanceInformation(vm.activeSession)
      .then(function () {
        // print end of session ticket ticket
        if (vm.zeroSessionDifferenceRequired && parseFloat(vm.activeSession.balance.balanceAmount.EUR) !== parseFloat(0)) {
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.error',
            'app.end_amount_incorrect'
          );
        } else {
          ToastrNotificationService.showTranslatedNotification(
            'success',
            'app.success',
            'app.end_amount_correct'
          );
        }

        // print the end of session ticket and ask for confirmation that the printing was successful
        if (SettingsService.get('pos.disableShiftEndTicket', false)) {
          return;
        }
        vm.printEndOfSessionTicket(vm.posGroupId, vm.posGroupInstanceId, vm.activeSession, _.union(vm.notes, vm.coins));
      });
    }

    function calcTotals() {
      var money,
          countedCash = 0;

      // add all the counted money and set the cash amount, provided the bills were touched
      if (vm.dirtyBills) {
        money = vm.coins.concat(vm.notes);
        angular.forEach(money, function (current) {
          countedCash += current.value * current.amount;
        });
        vm.setPaymentAmountByCode('cash', countedCash);
      }

      // total everything
      vm.totalIncome = 0.0;
      angular.forEach(vm.sessionPayments, function (payment) {
        vm.totalIncome += payment.amount;
        // round to 2 decimals, otherwise there might be a .999999999 float issue
        vm.totalIncome = ProductUtilService.roundPrice(vm.totalIncome);
      });
    }

    function loadFormDataFromStorage() {
      if (vm.activeSession === null ||
          angular.isUndefined($localStorage.posBills) ||
          angular.isUndefined($localStorage.posBills[vm.activeSession.id])) {
        return;
      }

      vm.coins = $localStorage.posBills[vm.activeSession.id].coins;
      vm.notes = $localStorage.posBills[vm.activeSession.id].notes;
      vm.sessionPayments = $localStorage.posBills[vm.activeSession.id].sessionPayments;

      vm.dirtyBills = true;
      vm.calcTotals();
      vm.dirtyBills = false;
    }

    function saveFormDataToStorage() {
      if (vm.activeSession === null) {
        return;
      }

      if (angular.isUndefined($localStorage.posBills)) {
        $localStorage.posBills = [];
      }

      if (angular.isUndefined($localStorage.posBills[vm.activeSession.id])) {
        $localStorage.posBills[vm.activeSession.id] = {coins: null, notes: null, sessionPayments: null};
      }

      $localStorage.posBills[vm.activeSession.id].coins = vm.coins;
      $localStorage.posBills[vm.activeSession.id].notes = vm.notes;
      $localStorage.posBills[vm.activeSession.id].sessionPayments = vm.sessionPayments;
    }

    function unsetFormDataFromStorage(sessionId) {
      if (angular.isUndefined($localStorage.posBills) ||
          angular.isUndefined($localStorage.posBills[vm.activeSession.id])) {
        return;
      }

      delete $localStorage.posBills[sessionId];
    }

    function add(currency) {
      LogService.log(currency, 'debug');
      // set dirtyBills flag to true so we know
      // that we have to recalculate cash total later
      vm.dirtyBills = true;
      currency.amount++;
      vm.calcTotals();
      vm.saveFormDataToStorage();
    }

    function subtract(currency) {
      LogService.log(currency, 'debug');
      // set dirtyBills flag to true so we know
      // that we have to recalculate cash total later
      vm.dirtyBills = true;
      if (--currency.amount < 0) {
        currency.amount = 0;
      }
      vm.calcTotals();
      vm.saveFormDataToStorage();
    }

    function cancelModalInstance(redirectToDashboard) {
      // wait until the modal has finished opening before trying to close it
      $modalInstance.opened
      .then(function () {
        $modalInstance.dismiss(redirectToDashboard);
      });
    }
  }
}());
