(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.factory:FitnessManagementFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .factory('FitnessManagementFactory', FitnessManagementFactory);

  function FitnessManagementFactory(
    Restangular,
    LogService
  ) {
    var fitnessAttendableInstancesService =
      Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'fitnessDetails'});
      })
      .service('services/fitness_attendable_instances')
    ;

    return {
      fitnessAttendableInstancesService: function (customerId, contactId) {
        var fitnessAttendableInstances = fitnessAttendableInstancesService.one('customer').one(customerId).one('contact').one(contactId).get();
        LogService.log('FitnessManagementFactory::fitnessAttendableInstancesService()' + fitnessAttendableInstances, 'debug');
        return fitnessAttendableInstances;
      }
    };
  }
}());
