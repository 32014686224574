(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.service:UtilService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .service('UtilService', UtilService);

  function UtilService(
    $filter,
    $modal,
    $state,
    _,
    LogService,
    moment
  ) {
    var vm = this;
    vm.modalInstanceKeys = [];

    vm.reloadState = function () {
      LogService.log('UtilsFactory::reloadState() -> Reloading current state.', 'debug');
      $state.go($state.current, {}, {reload: true, notify: true});
    };

    vm.isNotEmpty = function (variable) {
      var notNull = angular.isDefined(variable) && variable !== null;
      if (!notNull) {
        return false;
      }
      if (angular.isString(variable) || angular.isArray(variable)) {
        return variable.length > 0;
      }
      if (angular.isObject(variable) && !angular.isDate(variable)) {
        return Object.keys(variable).length > 0;
      }

      return true;
    };

    vm.isEmpty = function (variable) {
      return !vm.isNotEmpty(variable);
    };

    vm.isValidNumber = function (value) {
      return vm.isNotEmpty(value) && isFinite(parseFloat(value));
    };

    vm.isInteger = function (value) {
      return vm.isValidNumber(value) && (value % 1 === 0);
    };

    vm.intervalStringToValuesObject = function (intervalString) {
      var dateString = '',
          timeString = '',
          dateDesignatorIndex,
          timeDesignatorIndex,
          result = {};

      if (angular.isString(intervalString) && intervalString) {
        dateDesignatorIndex = intervalString.indexOf('P');
        timeDesignatorIndex = intervalString.indexOf('T');

        // valid interval string starts with P(eriod)
        if (dateDesignatorIndex === 0) {
          // divide the string into date and time portion
          if (timeDesignatorIndex > -1) {
            timeString = intervalString.slice(timeDesignatorIndex);
            dateString = intervalString.slice(0, timeDesignatorIndex);
          } else {
            dateString = intervalString;
          }

          // use regex magic to determine the actual values
          result.days = parseInt(dateString.match(/(\d+)(?:D)/g), 10);
          result.months = parseInt(dateString.match(/(\d+)(?:M)/g), 10);
          result.years = parseInt(dateString.match(/(\d+)(?:Y)/g), 10);

          if (timeString.length) {
            result.hours = parseInt(timeString.match(/(\d+)(?:H)/g), 10);
            result.minutes = parseInt(timeString.match(/(\d+)(?:M)/g), 10);
            result.seconds = parseInt(timeString.match(/(\d+)(?:S)/g), 10);
          }
        }

        // strip NaN values
        angular.forEach(result, function (value, key) {
          if (!vm.isValidNumber(value)) {
            delete result[key];
          }
        });
      }

      return result;
    };

    vm.valuesObjectToIntervalString = function (valuesObject) {
      var result = '',
          hasDays = vm.isValidNumber(valuesObject.days),
          hasMonths = vm.isValidNumber(valuesObject.months),
          hasYears = vm.isValidNumber(valuesObject.years),
          hasHours = vm.isValidNumber(valuesObject.hours),
          hasMinutes = vm.isValidNumber(valuesObject.minutes),
          hasSeconds = vm.isValidNumber(valuesObject.seconds);

      if (hasDays || hasMonths || hasYears) {
        result += 'P';

        if (hasYears) {
          result += valuesObject.years + 'Y';
        }

        if (hasMonths) {
          result += valuesObject.months + 'M';
        }

        if (hasDays) {
          result += valuesObject.days + 'D';
        }
      }

      if (hasHours || hasMinutes || hasSeconds) {
        result += 'T';

        if (hasHours) {
          result += valuesObject.hours + 'H';
        }
        if (hasMinutes) {
          result += valuesObject.minutes + 'M';
        }
        if (hasSeconds) {
          result += valuesObject.seconds + 'S';
        }
      }
      return result;
    };

    vm.decimalAdjust = function (type, value, exp) {
      if (vm.isEmpty(type) || !(angular.isString(type) && vm.isValidNumber(value))) {
        LogService.log('UtilsFactory::decimalAdjust() called but type or value were missing!', 'debug');
        return NaN;
      }
      // This function was grabbed (but modified) from mdn
      // see: https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Math/round
      if (!vm.isValidNumber(exp) || +exp === 0) {
        return Math[type](value);
      }

      value = +value;
      exp = +exp;

      if (!vm.isInteger(exp)) {
        LogService.log('UtilsFactory::decimalAdjust() called but parameter \'exp\' was not an integer!', 'debug');
        return NaN;
      }

      // Shift left and apply operation type
      value = Math[type](+(value.toString() + 'e' + -exp));

      // Shift back right
      return +(value.toString() + 'e' + exp);
    };

    vm.roundDecimal = function (value, exp) {
      return vm.decimalAdjust('round', value, exp);
    };

    vm.floorDecimal = function (value, exp) {
      return vm.decimalAdjust('floor', value, exp);
    };

    vm.ceilDecimal = function (value, exp) {
      return vm.decimalAdjust('ceil', value, exp);
    };

    vm.promiseLoop = function (collection, loopCallBack) {
      if (!(angular.isArray(collection) && angular.isFunction(loopCallBack))) {
        LogService.log('UtilsFactory::promiseLoop -> Collection should be an array and loopCallBack should be a function.', 'debug');
        return;
      }

      return new Promise(function (resolve) {
        var elementsToProcess = 0;

        function decreaseCounter() {
          --elementsToProcess;
          if (elementsToProcess <= 0) {
            resolve();
          }
        }

        if (!collection.length) {
          resolve();
        } else {
          elementsToProcess = collection.length;
          angular.forEach(collection, function (element) {
            Promise.resolve(loopCallBack(element))
              .then(function () {
                decreaseCounter();
              });
          });
        }
      });
    };

    vm.showModal = function (modalOptions, resultHandler, failureHandler, allowStacking, allowSameTemplate) {
      var currentKey = '';

      if (vm.isEmpty(modalOptions.templateUrl)) {
        LogService.log('Attempted to call UtilService.showModal() without temlateUrl!', 'debug');
        return;
      }

      if (vm.isEmpty(resultHandler)) {
        resultHandler = function () {
        };
      }

      if (vm.isEmpty(failureHandler)) {
        failureHandler = function () {
        };
      }

      // generate a key for this modal so we don't show the same modal twice
      currentKey = 'modal_' + modalOptions.templateUrl;

      function findModalInstanceIndex(key) {
        return _.findIndex(vm.modalInstanceKeys, function (storedKey) {
          return storedKey === key;
        });
      }

      function removeModalInstance(key) {
        var index = findModalInstanceIndex(key);
        if (index > -1) {
          vm.modalInstanceKeys.splice(index, 1);
        }
      }

      // default allowStacking to false
      if (vm.isEmpty(allowStacking) || (typeof allowStacking !== 'boolean')) {
        allowStacking = false;
      }

      // default allowSameTemplate to false
      if (vm.isEmpty(allowSameTemplate) || (typeof allowSameTemplate !== 'boolean')) {
        allowSameTemplate = false;
      }

      if (!vm.modalInstanceKeys.length || allowStacking) {
        // check if this modal was already invoked
        if (findModalInstanceIndex(currentKey) > -1 && !allowSameTemplate) {
          LogService.log('Attempted to show a modal, but that template/controller combination was already invoked - skipping!', 'debug');
          return;
        }

        // store the modal data so we don't show this modal twice
        vm.modalInstanceKeys.push(currentKey);

        LogService.log('Opening modal' + currentKey, 'debug');
        // remove the modal and call the result handler when the modal closes
        $modal.open(modalOptions)
          .result
          .then(function (returnValue) {
            LogService.log('Modal' + currentKey + 'closed.', 'debug');
            // handle close
            removeModalInstance(currentKey);
            if (vm.isNotEmpty(resultHandler)) {
              resultHandler(returnValue);
            }
          }, function (returnValue) {
            LogService.log('Modal' + currentKey + 'dismissed.', 'debug');
            // handle dismiss
            removeModalInstance(currentKey);
            if (vm.isNotEmpty(failureHandler)) {
              failureHandler(returnValue);
            }
          });
      } else {
        LogService.log('Could not show modal because there is already one open, and allowStacking flag was not set.', 'debug');
      }
    };

    vm.showConfirmationModal = function (title, body, handler) {
      return vm.showModal({
        templateUrl: 'views/confirmation.modal.view.tpl.html',
        controllerAs: 'confirmationPromptCtrl',
        controller: [
          'passedTitle',
          'passedBody',
          function (passedTitle, passedBody) {
            this.title = passedTitle;
            this.body = passedBody;
          }
        ],
        resolve: {
          passedTitle: function () {
            return title;
          },
          passedBody: function () {
            return body;
          }
        }
      }, handler, null, true);
    };

    vm.showTranslatedConfirmationModal = function (title, body, handler) {
      return vm.showConfirmationModal(
        $filter('uconlyfirst')($filter('translate')(title)),
        $filter('uconlyfirst')($filter('translate')(body)),
        handler
      );
    };

    vm.showFormattedConfirmationModal = function (titleFormat, title, bodyFormat, body, handler) {
      return vm.showConfirmationModal(
        $filter('uconlyfirst')($filter('sprintf')($filter('translate')(titleFormat), $filter('translate')(title))),
        $filter('uconlyfirst')($filter('sprintf')($filter('translate')(bodyFormat), $filter('translate')(body))),
        handler
      );
    };

    vm.pad = function (input, max) {
      var str = input.toString();
      return str.length < max ? vm.pad('0' + str, max) : str;
    };

    vm.getWeeksForYear = function (year) {
      var weeksInYear = 0, weeks = [], days = [], w, d;

      if (angular.isUndefined(year)) {
        year = moment().format('YYYY');
      }

      weeksInYear = moment(year + '-01-01').isoWeeksInYear();

      for (w = 1; w <= weeksInYear; w++) {
        days = [
          {date: moment(year + '-W' + vm.pad(w, 2) + '-' + 1)}
        ];
        for (d = 1; d <= 6; d++) {
          days.push({date: angular.copy(days[0]).date.add(d, 'd')});
        }
        weeks.push({
          weekNumber: w,
          days: days,
          label: days[0].date.format('DD/MM/YYYY') + ' - ' + days[6].date.format('DD/MM/YYYY')
        });
      }

      return weeks;
    };

    vm.getSportoaseColors = function () {
      return [
        {id: 'black', color: 'bg-black'},
        {id: 'charcoal', color: 'bg-charcoal'},
        {id: 'shadow', color: 'bg-shadow'},
        {id: 'lead', color: 'bg-lead'},
        {id: 'steel', color: 'bg-steel'},
        {id: 'gray', color: 'bg-gray'},
        {id: 'smoke', color: 'bg-smoke'},
        {id: 'fog', color: 'bg-fog'},
        {id: 'white', color: 'bg-white'},
        {id: 'snow', color: 'bg-snow'},
        {id: 'slate_light', color: 'bg-slate_light'},
        {id: 'slate', color: 'bg-slate'},
        {id: 'slate_dark', color: 'bg-slate_dark'},
        {id: 'teal', color: 'bg-teal'},
        {id: 'teal_dark', color: 'bg-teal_dark'},
        {id: 'laguna_light', color: 'bg-laguna_light'},
        {id: 'laguna', color: 'bg-laguna'},
        {id: 'laguna_dark', color: 'bg-laguna_dark'},
        {id: 'red', color: 'bg-red'},
        {id: 'orange', color: 'bg-orange'},
        {id: 'sunflower', color: 'bg-sunflower'},
        {id: 'lemon', color: 'bg-lemon'},
        {id: 'grape', color: 'bg-grape'},
        {id: 'plum', color: 'bg-plum'},
        {id: 'lavender', color: 'bg-lavender'},
        {id: 'purple', color: 'bg-purple'},
        {id: 'forest', color: 'bg-forest'},
        {id: 'lawn', color: 'bg-lawn'},
        {id: 'aquamarine', color: 'bg-aquamarine'},
        {id: 'emerald', color: 'bg-emerald'},
        {id: 'turquoise', color: 'bg-turquoise'},
        {id: 'chocolate', color: 'bg-chocolate'},
        {id: 'barnwood', color: 'bg-barnwood'},
        {id: 'sportoase_blue', color: 'bg-sportoase_blue'},
        {id: 'sportoase_blue_dark', color: 'bg-sportoase_blue_dark'},
        {id: 'sportoase_orange', color: 'bg-sportoase_orange'},
        {id: 'sportoase_green', color: 'bg-sportoase_green'}
      ];
    };

    vm.getter = function (key) {
      return function (obj) {
        return obj[key];
      };
    };

    vm.setAsync = function (object, key, defaultVal, promise, selector) {
      object[key] = defaultVal;
      return promise.then(function (value) {
        if (selector) {
          value = selector(value);
        }
        object[key] = value;
      }, function (e) {
        console.error('error: ' + e);
      });
    };

    vm.retrieveContactData = function (contact) {
      contact.mobileNumbers = [];
      contact.phoneNumbers = [];
      contact.faxNumbers = [];
      contact.websites = [];
      contact.emails = [];
      contact.comments = [];
      contact.socialSecurityNumbers = [];
      contact.birthDates = [];
      contact.companyNumbers = [];
      contact.genders = [];
      contact.VATNumbers = [];
      contact.bankAccounts = [];
      // Loop the contact data and add them to a list depending on the Data Type
      angular.forEach(contact, function (contactData) {
        contactData.important = angular.isDefined(contactData.tags) && contactData.tags.includes('important');

        switch (contactData.contactDataType.code) {
          case 'WEBSITE':
            contact.websites.push(contactData);
            break;

          case 'PHONE_NUMBER':
            contact.phoneNumbers.push(contactData);
            break;

          case 'MOBILE_NUMBER':
            contact.mobileNumbers.push(contactData);
            break;

          case 'FAX_NUMBER':
            contact.faxNumbers.push(contactData);
            break;

          case 'COMMENT':
            contact.comments.push(contactData);
            break;

          case 'EMAIL':
            contact.emails.push(contactData);
            break;

          case 'SOCIAL_SECURITY_NUMBER':
            contact.socialSecurityNumbers.push(contactData);
            break;

          case 'VAT_NUMBER':
            contact.VATNumbers.push(contactData);
            break;

          case 'COMPANY_NUMBER':
            contact.companyNumbers.push(contactData);
            break;

          case 'BIRTH_DATE':
            contactData.today = moment(contactData.value).format('DD-MM') === moment().format('DD-MM');
            contact.birthDates.push(contactData);
            break;

          case 'GENDER':
            contact.genders.push(contactData);
            break;

          case 'BANK_ACCOUNT_NUMBER':
            contact.bankAccounts.push(contactData);
            break;

          default:
            LogService.log(contactData.contactDataType.code + ' contact data type not found', 'error');
        }
      });
    };
  }
}());
