(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:POSAccessCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosAccessCtrl', PosAccessCtrl);

  function PosAccessCtrl(Restangular, ToastrNotificationService, UtilService) {
    var vm = this;
    vm.source = [
      {id: 1, name: 'access control', type: 'link', action: false, parentid: 0},
      {id: 2, name: 'Toegangscontrole fitness', deviceId: 112, permitionType: 'accessControl', type: 'button', action: false, parentid: 1},
      {id: 201, name: 'Ingang', deviceId: 301, permitionType: 'accessControl', action: false, parentid: 2},
      {id: 2011, name: 'Draaipoortje in open > 1x', deviceId: 301, delay: 0, action: true, parentid: 201},
      {id: 2012, name: 'Draaipoortje in open > permanent', deviceId: 301, duration: 60, delay: 0, action: true, parentid: 201},
      {id: 2013, name: 'reset', reset: true, duration: 0, delay: 0, action: true, parentid: 201},
      {id: 202, name: 'Uitgang', deviceId: 333, permitionType: 'accessControl', action: false, parentid: 2},
      {id: 2021, name: 'Draaipoortje uit open > 1x', deviceId: 333, delay: 0, action: true, parentid: 202},
      {id: 2022, name: 'Draaipoortje uit open > permanent', deviceId: 333, duration: 60, delay: 0, action: true, parentid: 202},
      {id: 2023, name: 'reset', reset: true, duration: 0, delay: 0, action: true, parentid: 202},
      {id: 7, name: 'Aansturing zonnebank', type: 'button', action: false, parentid: 1},
      {id: 8, name: 'Zonnebank 1', deviceId: 200, permitionType: 'tanningBed', action: false, parentid: 7},
      {id: 36, name: '3', duration: 3, delay: 0, action: true, parentid: 8},
      {id: 9, name: '10', duration: 10, delay: 0, action: true, parentid: 8},
      {id: 10, name: '15', duration: 15, delay: 0, action: true, parentid: 8},
      {id: 11, name: '20', duration: 20, delay: 0, action: true, parentid: 8},
      {id: 35, name: 'reset', reset: true, duration: 20, delay: 0, action: true, parentid: 8},
      {id: 12, name: 'Zonnebank 2', deviceId: 201, permitionType: 'tanningBed', action: false, parentid: 7},
      {id: 37, name: '3', duration: 3, delay: 0, action: true, parentid: 12},
      {id: 13, name: '10', duration: 10, delay: 0, action: true, parentid: 12},
      {id: 14, name: '15', duration: 15, delay: 0, action: true, parentid: 12},
      {id: 15, name: '20', duration: 20, delay: 0, action: true, parentid: 12},
      {id: 34, name: 'reset', reset: true, duration: 0, delay: 0, action: true, parentid: 12},
      {id: 16, name: 'Aansturing deur Instructiebad', deviceId: 204, permitionType: 'accessControl', type: 'button', action: false, parentid: 1},
      {id: 17, name: '5', duration: 5, delay: 0, action: true, parentid: 16},
      {id: 18, name: '10', duration: 10, delay: 0, action: true, parentid: 16},
      {id: 19, name: 'permanent', duration: 60, delay: 0, action: true, parentid: 16},
      {id: 33, name: 'reset', reset: true, duration: 0, delay: 0, action: true, parentid: 16},
      {id: 20, name: 'squash', type: 'button', action: false, parentid: 1},
      {id: 2101, name: 'squash 1', deviceId: 202, permitionType: 'squash', action: false, parentid: 20},
      {id: 21011, name: 'aan/uit', delay: 0, action: true, parentid: 2101},
      {id: 21012, name: '30', duration: 30, delay: 0, action: true, parentid: 2101},
      {id: 21013, name: '60', duration: 60, delay: 0, action: true, parentid: 2101},
      {id: 21014, name: '90', duration: 90, delay: 0, action: true, parentid: 2101},
      {id: 21015, name: '120', duration: 120, delay: 0, action: true, parentid: 2101},
      {id: 21016, name: 'reset', duration: 0, delay: 0, reset: true, action: true, parentid: 2101},
      {id: 2601, name: 'squash 2', deviceId: 203, permitionType: 'squash', action: false, parentid: 20},
      {id: 26011, name: 'aan/uit', delay: 0, action: true, parentid: 2601},
      {id: 26012, name: '30', duration: 30, delay: 0, action: true, parentid: 2601},
      {id: 26013, name: '60', duration: 60, delay: 0, action: true, parentid: 2601},
      {id: 26014, name: '90', duration: 90, delay: 0, action: true, parentid: 2601},
      {id: 26015, name: '120', duration: 120, delay: 0, action: true, parentid: 2601},
      {id: 26016, name: 'reset', duration: 0, delay: 0, reset: true, action: true, parentid: 2601}
    ];

    vm.arr = [
      {id: 1, name: 'access control', type: 'link', action: false, parentid: 0},
      {id: 2, name: 'Toegangscontrole fitness', deviceId: 112, permitionType: 'accessControl', type: 'button', action: false, parentid: 1},
      {id: 7, name: 'Aansturing zonnebank', type: 'button', action: false, parentid: 1},
      {id: 16, name: 'Aansturing deur Instructiebad', deviceId: 204, permitionType: 'accessControl', type: 'button', action: false, parentid: 1},
      {id: 20, name: 'squash', type: 'button', action: false, parentid: 1}
    ];
    vm.open = open;
    vm.dropWristband = dropWristband;
    vm.showWristbandModal = showWristbandModal;
    vm.showACardModal = showACardModal;

    function open(node) {
      if (node.action === false) {
        deleteRightSideOfArrayFromCurrentNode(node);
        deleteSiblingsAddCurrentNode(node);
        openChildren(node);
      } else {
        runCommand(node);
      }
    }

    function deleteSiblingsAddCurrentNode(node) {
      var currentNode = node, arrayWithoutSiblings = [];
      vm.arr.forEach(function (element) {
        if (node.parentid !== element.parentid) {
          arrayWithoutSiblings.push(element);
        }
      });
      vm.arr = arrayWithoutSiblings;
      currentNode.type = 'link';
      vm.arr.push(currentNode);
    }

    function deleteRightSideOfArrayFromCurrentNode(node) {
      var indexOfElement;
      vm.arr.forEach(function (element, index) {
        if (node.id === element.id) {
          indexOfElement = index;
        }
      });
      vm.arr.splice(indexOfElement + 1, vm.arr.length - indexOfElement - 1);
    }

    function openChildren(node) {
      vm.source.forEach(function (element) {
        if (node.id === element.parentid && node.action === false) {
          element.type = 'button';
          vm.arr.push(element);
        }
      });
    }

    function runCommand(node) {
      var data = {}, parent;
      vm.arr.forEach(function (element) {
        if (element.id === node.parentid) {
          parent = element;
        }
      });

      if (node.reset === true) {
        Restangular.one('access_control/item/' + parent.deviceId).remove().then(function () {
          ToastrNotificationService.showTranslatedNotification('success', 'app.success');
        });
      } else {
        data.deviceId = parent.deviceId;
        data.duration = node.duration;
        data.delay = node.delay;
        Restangular.one('access_control/item/' + parent.permitionType).customPOST(data).then(function () {
          ToastrNotificationService.showTranslatedNotification('success', 'app.success');
        });
      }
    }

    function dropWristband(facility) {
      Restangular.one('access_control/drop-wristband').customPOST({facilities: facility}).then(function () {
        ToastrNotificationService.showTranslatedNotification('success', 'app.success');
      });
    }

    function showWristbandModal() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.wristband.modal.tpl.html',
        controller: 'PosWristbandCtrl',
        controllerAs: 'posWristbandCtrl',
        size: 'lg',
        resolve: {}
      }, null, null, true);
    }

    function showACardModal() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.a-kaart.modal.tpl.html',
        controller: 'PosACardCtrl',
        controllerAs: 'posACardCtrl',
        resolve: {}
      }, null, null, true);
    }
  }
}());
