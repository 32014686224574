(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name product.factory:ConsolidationCodeFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .factory('ConsolidationCodeFactory', ConsolidationCodeFactory);

  function ConsolidationCodeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'consolidationCode'});
    })
    .service('consolidation_codes');
  }
}());
