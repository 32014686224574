(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:productAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .controller('ProductAddEditCtrl', ProductAddEditCtrl)
    .directive('onImageChange', function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          var onChangeHandler = scope.$eval(attrs.onImageChange);
          element.bind('change', onChangeHandler);
        }
      };
    });

  function ProductAddEditCtrl(
      $filter,
      LogService,
      $modalInstance,
      $timeout,
      _,
      AnalyticalAccountFactory,
      component,
      ConsolidationCodeFactory,
      CurrentUserContextFactory,
      FacilityFactory,
      ImageFactory,
      ImageService,
      JournalActionFactory,
      JournalTypeFactory,
      LedgerFactory,
      PaymentMethodsFactory,
      product,
      ProductCategoryFactory,
      ProductFactory,
      ProductUtilService,
      Restangular,
      SettingsService,
      ToastrNotificationService,
      type,
      UtilService,
      VatRateFactory,
      RestUtilService
  ) {
    var vm = this;
    vm.loggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.currentProduct = product;
    vm.currentComponent = component;
    vm.productJournalExpirationInMonths = '';
    vm.productJournalExpirationInWeeks = '';
    vm.productJournalExpirationInDays = '';
    vm.productJournalFreeMonths = '';
    vm.productJournalCredits = '';
    vm.productJournalType = null;
    vm.productJournalAction = null;
    vm.productJournalAttemptAppend = false;
    vm.productLedgerNumber = null;
    vm.productPriceIsStatic = false;
    vm.priceCurrency = null;
    vm.isGroupProduct = false;
    vm.wasGroupProduct = false;
    vm.paymentMethods = [];
    vm.productAnalyticalAccount = null;
    vm.isNewProduct = false;
    vm.subProducts = [];
    vm.subProduct = null;
    vm.subProductJournalsExist = false;
    vm.subProductMasterJournalId = null;

    // defaults for journal subscription instead of input fields so the user doesn't hurt himself
    vm.productJournalSubscriptionPaymentInterval = 1;
    vm.productJournalSubscriptionPaymentMethod = null;

    vm.consolidationCodes = {};

    vm.productComponent = {};
    vm.productComponent.selected = undefined;

    vm.priceInclVat = null;
    vm.priceInPos = null;
    vm.productComponents = [
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'journal',
        label: $filter('uconlyfirst')($filter('translate')('journal.journal')),
        fields: {
          journalType: null,
          journalAction: null,
          attemptAppend: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'subscription_contract',
        label: $filter('uconlyfirst')($filter('translate')('journal.subscription-contract')),
        fields: {
          duration: null,
          paymentInterval: null,
          paymentMethod: null,
          proRata: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'expiration',
        label: $filter('uconlyfirst')($filter('translate')('journal.expiration')),
        fields: {
          interval: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'price',
        label: $filter('uconlyfirst')($filter('translate')('app.price')),
        fields: {
          price: null,
          currency: 'EUR',
          vatRate: null,
          static: true
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'credits',
        label: $filter('uconlyfirst')($filter('translate')('journal.credits')),
        fields: {
          credits: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'free_months',
        label: $filter('uconlyfirst')($filter('translate')('journal.free-months')),
        fields: {
          months: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        type: 'facilities',
        label: $filter('uconlyfirst')($filter('translate')('app.facilities')),
        fields: {
          facilities: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: true,
        background: true,
        type: 'start_date',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.start_date')),
        fields: {}
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'products',
        label: $filter('uconlyfirst')($filter('translate')('app.products')),
        fields: {
          products: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        type: 'fitness_credits',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.fitnesscredits')),
        fields: {
          credits: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'photo',
        label: $filter('uconlyfirst')($filter('translate')('app.photo')),
        fields: {
          photo: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        type: 'sale_consolidation_code',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.sale_consolidation_code')),
        fields: {
          consolidationCode: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'ledger',
        label: $filter('uconlyfirst')($filter('translate')('product.views.ledger-number')),
        fields: {
          ledger: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'analytical_account',
        label: $filter('uconlyfirst')($filter('translate')('accountancy.analytical_account')),
        fields: {
          analyticalAccount: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        type: 'article_type_consolidation_code',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.article_type_consolidation_code')),
        fields: {
          consolidationCode: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        type: 'activity_consolidation_code',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.activity_consolidation_code')),
        fields: {
          consolidationCode: null
        }
      }
    ];

    vm.calculationPct = true;
    vm.totalPct = 0;
    vm.photoObject = null;
    vm.productImageSrc = '';
    vm.productHasPhoto = false;
    vm.productLabel = '';
    vm.frProductLabel = '';
    vm.productDescription = '';
    vm.productDescriptionFr = '';
    vm.productInputRequired = false;
    vm.productContactRequired = false;
    vm.productCode = null;
    vm.productCategories = null;
    vm.productBlueprint = false;
    vm.productFractionsAllowed = false;
    vm.productVisibleInWebshop = false;
    vm.productIsGroupedOnTicket = false;
    vm.journalTypes = null;
    vm.facilities = null;
    vm.products = null;
    vm.vatRates = [];
    vm.currencies = [];
    vm.journalActions = null;
    vm.selectedComponents = [];
    vm.onProductImageChange = onProductImageChange;
    vm.currentlyHighlightedComponent = null;
    vm.isComponentTypeHighlighted = isComponentTypeHighlighted;
    vm.componentIsNotSelectedFilter = componentIsNotSelectedFilter;
    vm.selectedProductCategories = [];
    vm.addHighlightedComponentAndSaveProduct = addHighlightedComponentAndSaveProduct;
    vm.isComponentSelected = isComponentSelected;
    vm.findComponentIndex = findComponentIndex;
    vm.markComponentAsDirty = markComponentAsDirty;
    vm.addComponentTab = addComponentTab;
    vm.storeComponentId = storeComponentId;
    vm.storeComponentFieldValue = storeComponentFieldValue;
    vm.removeComponent = removeComponent;
    vm.removeComponentImmediately = removeComponentImmediately;
    vm.startComponentSaveSequence = startComponentSaveSequence;
    vm.productFacility = null;
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.saveProduct = saveProduct;
    vm.setPriceInclVat = setPriceInclVat;
    vm.setPriceExclVat = setPriceExclVat;
    vm.selectComponentTab = selectComponentTab;
    vm.prepareJournalComponents = prepareJournalComponents;
    vm.productJournalActionChanged = productJournalActionChanged;
    vm.prepareLedgerComponent = prepareLedgerComponent;
    vm.prepareAnalyticalAccountComponent = prepareAnalyticalAccountComponent;
    vm.preparePriceComponent = preparePriceComponent;
    vm.prepareGroupComponent = prepareGroupComponent;
    vm.getAsyncLedgers = getAsyncLedgers;
    vm.getAsyncAnalyticalAccounts = getAsyncAnalyticalAccounts;
    vm.getAsyncProducts = getAsyncProducts;
    vm.addSubProduct = addSubProduct;
    vm.removeSubProduct = removeSubProduct;
    vm.calculatePriceInPos = calculatePriceInPos;
    vm.showVisibleInWebshop = SettingsService.get('products.enableProductPropertyVisibleInWebshop', false);
    initProductData();

    if (vm.isEdit()) {
      vm.productLabel = vm.currentProduct.label;
      vm.frProductLabel = vm.currentProduct.frLabel;
      vm.productInputRequired = vm.currentProduct.inputRequired;
      vm.productContactRequired = vm.currentProduct.contactRequired;
      vm.productFractionsAllowed = vm.currentProduct.fractionsAllowed;
      vm.productVisibleInWebshop = vm.currentProduct.visibleInWebshop;
      vm.productCode = vm.currentProduct.code;
      vm.productDescription = vm.currentProduct.description;
      vm.productDescriptionFr = vm.currentProduct.descriptionFr;
      vm.productIsGroupedOnTicket = vm.currentProduct.grouped;
      vm.productBlueprint = vm.currentProduct.blueprint;
    }

    function initProductData() {
      var parameters = {
            limit: 99
          },
          vatRateParameters = {
            limit: 99,
            sort: 'percentage,ASC'
          },
          paymentParameters = {
            limit: 99,
            'filter[]': 'code,NEQ DISCOUNT'
          },
          journalTypeParameters = {
            sort: 'label,ASC'
          },
          productsParam,
          journalActionParameters, getBackendData, initCountdown, checkInitialized;
      productsParam = angular.copy(parameters);
      productsParam['filter[]'] = ['mostRecent,TRUE'];
      journalActionParameters = angular.copy(parameters);
      journalActionParameters['filter[]'] = ['OR,code,RECHARGE,code,RENEWAL'];
      getBackendData = new Promise(function (resolve) {
        initCountdown = 0;
        checkInitialized = function () {
          --initCountdown;
          if (initCountdown <= 0) {
            resolve('data loaded');
          }
        };

        // load list options for facilities, actions, etc
        // productFactory is a special case - see comment in product-factory.js
        ++initCountdown;
        VatRateFactory.getList(vatRateParameters).then(function (resultVatRates) {
          vm.vatRates = resultVatRates;
          checkInitialized();
        });

        ++initCountdown;
        PaymentMethodsFactory.getList(paymentParameters).then(function (resultPaymentMethods) {
          vm.paymentMethods = resultPaymentMethods;
          vm.productJournalSubscriptionPaymentMethod = _.findWhere(vm.paymentMethods, {code: 'INVOICE'});
          checkInitialized();
        });

        ++initCountdown;
        ConsolidationCodeFactory.getList(parameters).then(function (ccodes) {
          angular.forEach(ccodes, function (cc) {
            if (!vm.consolidationCodes.hasOwnProperty(cc.consolidationCodeType.code)) {
              vm.consolidationCodes[cc.consolidationCodeType.code] = [];
            }
            vm.consolidationCodes[cc.consolidationCodeType.code].push(cc);
          });
          checkInitialized();
        });

        ++initCountdown;
        Restangular.all('currencies').customGET('', parameters).then(function (resultCurrencies) {
          angular.forEach(resultCurrencies.results, function (value, key) {
            vm.currencies.push(key);
          });
          checkInitialized();
        });
        ++initCountdown;
        ProductCategoryFactory.getList(parameters).then(function (resultCategories) {
          vm.productCategories = resultCategories;
          checkInitialized();
        });

        ++initCountdown;
        JournalActionFactory.getList(journalActionParameters).then(function (resultActions) {
          vm.journalActions = resultActions;
          checkInitialized();
        });

        ++initCountdown;
        RestUtilService.getFullList(JournalTypeFactory, journalTypeParameters).then(function (resultTypes) {
          vm.journalTypes = resultTypes;
          checkInitialized();
        });

        ++initCountdown;
        FacilityFactory.getList(parameters).then(function (resultFacilities) {
          vm.facilities = resultFacilities;
          checkInitialized();
        });
      });

      getBackendData.then(function () {
        vm.getAsyncLedgers('');
        // if we're editing a product, load that data too
        if (isEdit()) {
          ProductFactory.one(vm.currentProduct.uuid).get().then(function (returnData) {
            var productComponent,
                index,
                componentFields,
                retrievedProduct,
                subProductPrice;

            retrievedProduct = returnData.data;

            // load category data
            angular.forEach(retrievedProduct.productCategories, function (category) {
              vm.selectedProductCategories.push(category.id);
            });

            // load component data
            // loop over product components and set their field values
            angular.forEach(retrievedProduct.productComponents, function (retrievedComponent) {
              var subProductJournalComponent;

              if (retrievedComponent.type === 'g_f_p') {
                return;
              }
              productComponent = retrievedComponent;

              // load the product photo
              if (productComponent.type === 'photo') {
                ImageService.getImageFromBackend(retrievedComponent.photo.id).then(function (photo) {
                  vm.photo = photo;
                  vm.productHasPhoto = true;
                });
              }
              index = findComponentIndex(productComponent.type);
              componentFields = vm.productComponents[index].fields;
              if (!isBgComponent(productComponent.type)) {
                // add component type to list of selected types
                // (if it's nog a background component!)
                productComponent.label = $filter('filter')(vm.productComponents, function (pc) {
                  return pc.type === productComponent.type;
                })[0].label;
                vm.selectedComponents.push(productComponent);
              } else {
                switch (productComponent.type) {
                  case 'subscription_contract':
                    vm.productJournalIsSubscriptionContract = true;
                    vm.productJournalSubscriptionDuration = retrievedComponent.duration;
                    vm.productJournalSubscriptionPaymentInterval = retrievedComponent.paymentInterval;
                    vm.productJournalSubscriptionPaymentMethod = retrievedComponent.paymentMethod;
                    vm.productJournalSubscriptionProRata = retrievedComponent.proRata;
                    break;

                  case 'ledger':
                    LedgerFactory.one(retrievedComponent.ledger.id).get().then(function (resultLedger) {
                      vm.productLedgerNumber = resultLedger;
                    });
                    break;
                  case 'analytical_account':
                    AnalyticalAccountFactory.one(retrievedComponent.analyticalAccount.id).get().then(function (resultAnalyticalAccount) {
                      vm.productAnalyticalAccount = resultAnalyticalAccount;
                    });
                    break;
                  case 'journal':
                    vm.productIsJournal = true;
                    vm.filteredJournalTypes = $filter('filter')(vm.journalTypes, function (jt) {
                      return jt.id === retrievedComponent.journalType.id;
                    });
                    vm.productJournalType = vm.filteredJournalTypes && vm.filteredJournalTypes.length > 0 ? vm.filteredJournalTypes[0] : null;
                    vm.filteredJournalActions = $filter('filter')(vm.journalActions, function (ja) {
                      return ja.id === retrievedComponent.journalAction.id;
                    });
                    vm.productJournalAction = vm.filteredJournalActions && vm.filteredJournalActions.length > 0 ? vm.filteredJournalActions[0] : null;
                    vm.productJournalAttemptAppend = UtilService.isNotEmpty(retrievedComponent.attemptAppend) ? retrievedComponent.attemptAppend : false;
                    break;

                  case 'credits':
                    vm.productJournalCredits = retrievedComponent.credits;
                    break;

                  case 'start_date':
                    vm.productHasStartDate = true;
                    break;

                  case 'expiration':
                    vm.productJournalExpirationInMonths = UtilService.intervalStringToValuesObject(retrievedComponent.interval).months;
                    vm.productJournalExpirationInWeeks = Math.floor(UtilService.intervalStringToValuesObject(retrievedComponent.interval).days / 7);
                    vm.productJournalExpirationInDays = UtilService.intervalStringToValuesObject(retrievedComponent.interval).days % 7;
                    break;

                  case 'free_months':
                    vm.productJournalFreeMonths = retrievedComponent.months;
                    break;

                  case 'price':
                    vm.priceVatRate = retrievedComponent.vatRate.id;
                    vm.priceCurrency = retrievedComponent.currency;
                    vm.productPrice = retrievedComponent.price;
                    vm.setPriceInclVat();
                    vm.productPriceIsStatic = retrievedComponent.static;
                    break;

                  case 'products':
                    vm.isGroupProduct = true;
                    vm.wasGroupProduct = true;
                    vm.subProducts = [];
                    angular.forEach(retrievedComponent.products, function (retrievedComponentProduct) {
                      ProductFactory.one(retrievedComponentProduct.uuid).get().then(function (resultProduct) {
                        vm.subProducts.push(resultProduct.data);
                        subProductPrice = resultProduct.data.productComponents.filter(function (pc) {
                          return pc.type === 'price';
                        })[0];
                        subProductJournalComponent = resultProduct.data.productComponents.filter(function (pc) {
                          return pc.type === 'journal';
                        });
                        resultProduct.data.price = subProductPrice;
                        resultProduct.data.price.priceInclVat = vm.calculatePriceInclVat(subProductPrice.price, subProductPrice.vatRate.percentage);
                        resultProduct.data.hasJournalComponent = UtilService.isNotEmpty(subProductJournalComponent);
                        vm.calculateGroupProductPrice();
                        vm.updateGroupProductProperties();
                      });
                    });
                    if (retrievedComponent.masterJournalProduct) {
                      vm.subProductMasterJournalId = retrievedComponent.masterJournalProduct.id;
                    }
                    break;

                  default:
                    break;
                }
              }
              // set component fields
              angular.forEach(componentFields, function (value, field) {
                vm.storeComponentFieldValue(productComponent.type, field, retrievedComponent[field]);
              });
              vm.storeComponentId(productComponent);
            });
          });
        } else {
          vm.productPrice = 0.00;
          vm.setPriceInclVat();
        }
      });
    }

    // returns a boolean to indicate the user has selected a component type (used in the templates to
    // determine when to load extra input fields)
    function isComponentTypeHighlighted() {
      return !(angular.isUndefined(vm.currentlyHighlightedComponent) ||
        vm.currentlyHighlightedComponent === null ||
        vm.currentlyHighlightedComponent.length === 0);
    }

    function componentIsNotSelectedFilter(object) {
      // Filter for ng-repeat in template
      var currType = object.type;
      return !isComponentSelected(currType) && !isBgComponent(currType);
    }

    // remove a component from the list of selected components (this action is not immediate but will only take effect
    // when the user saves his changes)
    function removeComponent(componentType) {
      var selectedComponent = $filter('filter')(vm.selectedComponents, function (sc) {
            return sc.type === componentType;
          })[0],
          selIndex = vm.selectedComponents.indexOf(selectedComponent);
      LogService.log('selIndex = ' + selIndex, 'debug');
      if (selIndex !== -1) {
        vm.selectedComponents.splice(selIndex, 1);
      }
    }

    // remove a component right now (instead of just marking it as deleted and saving the changes later)
    function removeComponentImmediately(componentType) {
      var typeAsArray = [];
      typeAsArray.push(componentType);
      scrubComponents(typeAsArray);
    }

    // because of the difficulty involved with product versioning (ids for product and components change on every update)
    // we want to restrict update calls to only those components that were actually changed/added, rather than going over
    // the whole list of components.
    function markComponentAsDirty(componentType) {
      var index = findComponentIndex(componentType);

      vm.productComponents[index].dirty = true;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function findVatRateIndex(vatRateId) {
      var foundIndex = -1,
          currentIndex = 0;

      // use native for loop instead of angular.forEach() so we can break when we've found the type
      for (currentIndex = 0; currentIndex < vm.vatRates.length; ++currentIndex) {
        if (vm.vatRates[currentIndex].id === vatRateId) {
          foundIndex = currentIndex;
          break;
        }
      }
      return foundIndex;
    }

    function findComponentIndex(componentType) {
      var foundIndex = -1,
          currentIndex = 0;

      // use native for loop instead of angular.forEach() so we can break when we've found the type
      for (currentIndex = 0; currentIndex < vm.productComponents.length; ++currentIndex) {
        if (vm.productComponents[currentIndex].type === componentType) {
          foundIndex = currentIndex;
          break;
        }
      }
      return foundIndex;
    }

    // check whether a component is in the list of selected components,
    // and not in the list of background components
    // (because the user should never select background components)
    function isComponentSelected(componentType) {
      var exists = $filter('filter')(vm.selectedComponents, function (sc) {
        return sc.type === componentType;
      });
      return exists.length > 0 ? true : false;
    }

    function isBgComponent(componentType) {
      var componentDesc = vm.productComponents[findComponentIndex(componentType)];
      return componentDesc.hasOwnProperty('background') && componentDesc.background;
    }

    // take only id from values which are objects in themselves
    function stripIdFromFieldValue(field, value) {
      var ids = [];

      switch (field) {
        case 'journalType':
        case 'journalAction':
        case 'vatRate':
        case 'photo':
        case 'consolidationCode':
          return value.id;
        case 'facilities':
        case 'products':
          angular.forEach(value, function (currentValue) {
            ids.push(currentValue.id);
          });
          return ids;
        default:
          return -1;
      }
    }

    vm.calculatePriceExclVat = function (price, vatPercentage) {
      return price / (1 + (vatPercentage / 100));
    };

    vm.calculatePriceInclVat = function (price, vatPercentage) {
      return price + (price * (vatPercentage / 100));
    };

    vm.calculateGroupProductPrice = function () {
      if (vm.isGroupProduct) {
        if (!vm.calculationPct) {
          vm.priceInclVat = 0;
          angular.forEach(vm.subProducts, function (subProduct) {
            vm.priceInclVat += subProduct.price.priceInclVat;
          });
        }
        vm.totalPct = 0;
        angular.forEach(vm.subProducts, function (subProduct) {
          subProduct.price.priceInclVatPct = (subProduct.price.priceInclVat / vm.priceInclVat) * 100;
          vm.totalPct += subProduct.price.priceInclVatPct;
        });
        vm.calculatePriceInPos();
      }
    };

    vm.calculateGroupProductPct = function () {
      if (vm.isGroupProduct) {
        if (vm.calculationPct) {
          vm.totalPct = 0;
          angular.forEach(vm.subProducts, function (subProduct) {
            subProduct.price.priceInclVat = (subProduct.price.priceInclVatPct / 100) * vm.priceInclVat;
            vm.totalPct += subProduct.price.priceInclVatPct;
          });
          vm.calculatePriceInPos();
        }
      }
    };

    vm.updateGroupProductProperties = function () {
      vm.subProductJournalsExist = false;
      if (vm.isGroupProduct) {
        angular.forEach(vm.subProducts, function (subProduct) {
          if (subProduct.hasJournalComponent === true) {
            vm.subProductJournalsExist = true;
          }
        });
      }
    };

    vm.unsetSubproductMasterJournal = function () {
      vm.subProductMasterJournalId = null;
    };

    // the user want to enter prices as vat-inclusive (which is not how the backend stores them)
    // so we do the translations here
    function setPriceInclVat() {
      var price,
          vatRateIndex,
          vatPercentage;

      price = vm.productPrice;
      vatRateIndex = findVatRateIndex(vm.priceVatRate);

      if (vatRateIndex !== -1) {
        vatPercentage = vm.vatRates[vatRateIndex].percentage;
      } else {
        vatPercentage = 0;
      }

      $timeout(function () {
        vm.priceInclVat = ProductUtilService.calculateInclPrice(price, vatPercentage).inclPrice;
      });
    }

    // the user want to enter prices as vat-inclusive (which is not how the backend stores them)
    // so we do the translation here
    function setPriceExclVat() {
      var vatRateIndex,
          vatPercentage;

      vatRateIndex = findVatRateIndex(vm.priceVatRate);

      if (vatRateIndex !== -1) {
        vatPercentage = vm.vatRates[vatRateIndex].percentage;
      } else {
        vatPercentage = 0;
      }
      vm.productPrice = ProductUtilService.calculateExclPrice(vm.priceInclVat, vatPercentage).exclPrice;
    }

    function storeComponentFieldValue(componentType, field, value) {
      var componentIndex = findComponentIndex(componentType),
          valId = stripIdFromFieldValue(field, value);

      if (valId !== -1) {
        value = valId;
      }

      vm.productComponents[componentIndex].fields[field] = value;
    }

    function storeComponentId(productComponent) {
      var componentIndex = findComponentIndex(productComponent.type);
      vm.productComponents[componentIndex].id = productComponent.id;
      vm.productComponents[componentIndex].uuid = productComponent.uuid;
    }

    function saveComponentsToBackend(typesToSave) {
      var restObject, currentComponentType;
      return new Promise(function (resolve) {
        if (typesToSave.length > 0) {
          currentComponentType = typesToSave[0].type;
          LogService.log('productComponent: ' + currentComponentType, 'debug');
          // either post or patch the component depending on whether the id was set
          if (typesToSave[0].uuid !== null) {
            restObject = getComponentRestObject(typesToSave[0]);
            if (currentComponentType !== 'start_date') {
              ProductFactory.one(vm.currentProduct.uuid).one('components').one(typesToSave[0].uuid).patch(restObject, {}, {'x-entity': currentComponentType + 'Component'})
                .then(function (returnData) {
                  LogService.log(returnData, 'debug');
                  LogService.log('Edit component' + currentComponentType, 'debug');
                  return saveNextComponent(resolve);
                });
            } else {
              return saveNextComponent(resolve);
            }
          } else {
            restObject = getComponentRestObject(typesToSave[0]);
            ProductFactory.one(vm.currentProduct.uuid).one('components').post(
              currentComponentType, restObject, {}, {'x-entity': currentComponentType + 'Component'})
              .then(function (returnData) {
                LogService.log(returnData, 'debug');
                LogService.log('Add new component' + currentComponentType, 'debug');
                return saveNextComponent(resolve);
              });
          }
        } else {
          resolve('finished');
        }
      });
      function saveNextComponent(resolve) {
        typesToSave.splice(0, 1);
        return saveComponentsToBackend(typesToSave).then(function () {
          resolve();
        });
      }
    }

    function scrubComponentsFromBackend(typesToRemove) {
      var promise = new Promise(function (resolve) {
        LogService.log('ProductAddEditCtrl::scrubComponentsFromBackend() -> typesToRemove' + typesToRemove, 'debug');
        if (typesToRemove.length > 0) {
          LogService.log('productComponent: ' + typesToRemove[0].type, 'debug');
          if (typesToRemove[0].uuid !== null) {
            LogService.log('remove productComponent: ' + typesToRemove[0].type, 'debug');
            ProductFactory.one(vm.currentProduct.uuid).one('components').one(typesToRemove[0].uuid).remove()
            .then(function (returnData) {
              LogService.log('Remove component' + returnData, 'debug');
              return removeNextComponent(resolve);
            });
          } else {
            return removeNextComponent(resolve);
          }
        } else {
          resolve('finished');
        }
      });
      function removeNextComponent(resolve) {
        typesToRemove.splice(0, 1);
        return scrubComponentsFromBackend(typesToRemove).then(function () {
          resolve();
        });
      }
      return promise;
    }

    // remove old components (this will be called by save components when it is finished!)
    function scrubComponents(componentList) {
      var reloadOption, removedComponents = [];

      if (angular.isUndefined(componentList)) {
        // collect all dirty components
        angular.forEach(vm.productComponents, function (currentComponent) {
          if (currentComponent.uuid &&
            !(isComponentSelected(currentComponent.type) || isBgComponent(currentComponent.type))) {
            removedComponents.push(currentComponent);
          }
        });
      } else {
        removedComponents = componentList;
      }

      // kick off the sequential component save chain
      return scrubComponentsFromBackend(removedComponents).then(function () {
        // Update old products if blueprint
        if (vm.currentProduct.blueprint) {
          // Use RestUtil to get all products
          return RestUtilService.getFullList(ProductFactory.one('components'), {'filter[]': ['products.originVersion.uuid,' + vm.currentProduct.uuid, 'product.mostRecent,TRUE']}, 'products')
          .then(function (groupProducts) {
            var promiseQueue = [];
            if (groupProducts.length > 0) {
              angular.forEach(groupProducts, function (groupProduct) {
                if (groupProduct.product.mostRecent) {
                  groupProduct.subProduct = groupProduct.products.filter(function (sp) {
                    return sp.originVersion.uuid === vm.currentProduct.uuid;
                  })[0];
                  promiseQueue.push(ProductFactory.one(groupProduct.subProduct.uuid).get().then(function (returnProduct) {
                    returnProduct = returnProduct.data;
                    returnProduct.priceComponent = returnProduct.productComponents.filter(function (pc) {
                      return pc.type === 'price';
                    })[0];
                    returnProduct.product = groupProduct.product;
                    return ProductFactory.one(vm.currentProduct.uuid).post('clone?except[]=products', {}).then(function (resultClonedProduct) {
                      resultClonedProduct.data.priceComponent = resultClonedProduct.data.productComponents.filter(function (pc) {
                        return pc.type === 'price';
                      })[0];
                      return ProductFactory.one(resultClonedProduct.data.uuid).one('components').one(resultClonedProduct.data.priceComponent.uuid).patch({
                        price: returnProduct.priceComponent.price,
                        currency: 'EUR',
                        vatRate: returnProduct.priceComponent.vatRate.id,
                        static: returnProduct.priceComponent.static
                      })
                      .then(function () {
                        return ProductFactory.one(resultClonedProduct.data.uuid).patch({label: returnProduct.label, blueprint: false, hidden: true}).then(function () {
                          LogService.log('Patched product', 'debug');
                          return ProductFactory.one(resultClonedProduct.data.uuid).get().then(function (returnedClonedProduct) {
                            return ProductFactory.one(returnProduct.product.uuid).get().then(function (returnedGroupProduct) {
                              returnedGroupProduct.data.productsComponent = returnedGroupProduct.data.productComponents.filter(function (pc) {
                                return pc.type === 'products';
                              })[0];
                              returnedGroupProduct.data.productsComponent.newProducts = [];
                              angular.forEach(returnedGroupProduct.data.productsComponent.products, function (productComponent) {
                                if (productComponent.id !== returnProduct.id) {
                                  returnedGroupProduct.data.productsComponent.newProducts.push(productComponent.id);
                                }
                              });
                              returnedGroupProduct.data.productsComponent.newProducts.push(returnedClonedProduct.data.id);
                              return ProductFactory.one(returnProduct.product.uuid).one('components').one(returnedGroupProduct.data.productsComponent.uuid).patch({
                                products: returnedGroupProduct.data.productsComponent.newProducts
                              }).then(function () {
                                LogService.log('is it this patched call ??', 'debug');
                              });
                            });
                          });
                        });
                      });
                    });
                  }));
                }
              });
            }
            return Promise.all(promiseQueue);
          });
        }
      }).then(function () {
        reloadOption = type === 'productList' ? 'reloadListData' : 'reloadPageData';
        // Add toastr
        ToastrNotificationService.showNotification(
          'success',
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-saved'),
              $filter('translate')('app.product')
              )
            ),
          $filter('uconlyfirst')(
            $filter('sprintf')(
              vm.isEdit() ?
                $filter('translate')('app.item-successfully-updated') :
                $filter('translate')('app.item-successfully-saved'),
              $filter('translate')('app.product')
              )
            )
          );
        return $modalInstance.close(reloadOption);
      });
    }

    // save components (this will call scrub components when it is finished!)
    function startComponentSaveSequence() {
      var dirtyComponents = [];
      // collect all dirty components
      angular.forEach(vm.productComponents, function (currentComponent) {
        if (currentComponent.dirty &&
          (isComponentSelected(currentComponent.type) || (isBgComponent(currentComponent.type) && currentComponent.type === 'photo'))) {
          dirtyComponents.push(currentComponent);
        }
      });
      LogService.log('saving components', 'debug');
      LogService.log(dirtyComponents, 'debug');
      // kick off the sequential component save chain
      return saveComponentsToBackend(dirtyComponents).then(function () {
        return scrubComponents();
      });
    }

    // the photo component refers to id's of entities which can be made on the fly, so
    // this function ensures the proper photo entities exist before storing the components
    function storeProductPhoto() {
      var photoDesc = vm.productComponents[findComponentIndex('photo')],
          photoObject,
          photoId;
      return new Promise(function (resolve) {
        if (photoDesc.dirty) {
          if (photoDesc.uuid === null) {
            photoObject = getPhotoRestObject();
            ImageFactory.post(photoObject).then(function (resultPhoto) {
              vm.productComponents[findComponentIndex('photo')].dirty = true;
              vm.productComponents[findComponentIndex('photo')].fields.photo = resultPhoto.id;
              resolve('Image posted!');
            });
          } else {
            photoObject = getPhotoRestObject();
            photoId = vm.productComponents[findComponentIndex('photo')].fields.photo;
            ImageFactory.one(photoId).patch(photoObject).then(function () {
              resolve('Image patched!');
            });
          }
        } else {
          resolve('No action required!');
        }
      });
    }

    // this function prepares the components to be saved later
    function prepareJournalComponents() {
      var typesToSave = [],
          typesToRemove = [];
      return new Promise(function (resolve) {
        if (vm.productIsJournal) {
          vm.productComponents[vm.findComponentIndex('journal')].fields.journalAction = vm.productJournalAction.id;
          vm.productComponents[vm.findComponentIndex('journal')].fields.journalType = vm.productJournalType.id;
          vm.productComponents[vm.findComponentIndex('journal')].fields.attemptAppend = vm.productJournalAttemptAppend;
          typesToSave.push(vm.productComponents[vm.findComponentIndex('journal')]);
          LogService.log('Product is journal', 'debug');
          if (vm.productJournalIsSubscriptionContract === true) {
            vm.productComponents[vm.findComponentIndex('subscription_contract')].fields.duration = vm.productJournalSubscriptionDuration;
            vm.productComponents[vm.findComponentIndex('subscription_contract')].fields.paymentInterval = vm.productJournalSubscriptionPaymentInterval;
            vm.productComponents[vm.findComponentIndex('subscription_contract')].fields.paymentMethod = vm.productJournalSubscriptionPaymentMethod.id;
            vm.productComponents[vm.findComponentIndex('subscription_contract')].fields.proRata = vm.productJournalSubscriptionProRata;
            typesToSave.push(vm.productComponents[vm.findComponentIndex('subscription_contract')]);
            typesToSave.push(vm.productComponents[vm.findComponentIndex('start_date')]);
          } else {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('subscription_contract')]);
          }
          switch (vm.productJournalAction.code) {
            case 'RECHARGE':
              vm.productComponents[vm.findComponentIndex('credits')].fields.credits = vm.productJournalCredits;
              typesToSave.push(vm.productComponents[vm.findComponentIndex('credits')]);

              if (UtilService.isNotEmpty(vm.productJournalExpirationInMonths) ||
                UtilService.isNotEmpty(vm.productJournalExpirationInWeeks) ||
                UtilService.isNotEmpty(vm.productJournalExpirationInDays)) {
                vm.productComponents[vm.findComponentIndex('expiration')].fields.interval =
                UtilService.valuesObjectToIntervalString({
                  months: UtilService.isNotEmpty(vm.productJournalExpirationInMonths) ? vm.productJournalExpirationInMonths : 0,
                  days: (UtilService.isNotEmpty(vm.productJournalExpirationInDays) ? parseInt(vm.productJournalExpirationInDays, 10) : 0) +
                  (UtilService.isNotEmpty(vm.productJournalExpirationInWeeks) ? parseInt(vm.productJournalExpirationInWeeks, 10) * 7 : 0)
                });
                typesToSave.push(vm.productComponents[vm.findComponentIndex('expiration')]);
              } else {
                LogService.log('No expiration filled in', 'debug');
                if (vm.productComponents[vm.findComponentIndex('expiration')].id !== null) {
                  // Remove component
                  typesToRemove.push(vm.productComponents[vm.findComponentIndex('expiration')]);
                } else {
                  LogService.log('no action for expiration component', 'debug');
                }
              }

              if (vm.productHasStartDate === true && vm.productJournalIsSubscriptionContract !== true) {
                typesToSave.push(vm.productComponents[vm.findComponentIndex('start_date')]);
              } else {
                LogService.log('No start date', 'debug');
                if (vm.productJournalIsSubscriptionContract !== true) {
                  if (vm.productComponents[vm.findComponentIndex('start_date')].id !== null) {
                    // Remove component
                    typesToRemove.push(vm.productComponents[vm.findComponentIndex('start_date')]);
                  } else {
                    LogService.log('no action for start_date', 'debug');
                  }
                }
              }
              break;

            case 'RENEWAL':
              if (vm.productComponents[vm.findComponentIndex('credits')].id !== null) {
                typesToRemove.push(vm.productComponents[vm.findComponentIndex('credits')]);
              }
              vm.productComponents[vm.findComponentIndex('expiration')].fields.interval = UtilService.valuesObjectToIntervalString({
                months: vm.productJournalExpirationInMonths,
                days: parseInt(vm.productJournalExpirationInDays, 10) + parseInt(vm.productJournalExpirationInWeeks, 10) * 7
              });

              typesToSave.push(vm.productComponents[vm.findComponentIndex('expiration')]);
              if (vm.productJournalIsSubscriptionContract !== true) {
                typesToSave.push(vm.productComponents[vm.findComponentIndex('start_date')]);
              }

              if (vm.productJournalFreeMonths) {
                vm.productComponents[vm.findComponentIndex('free_months')].fields = { months: vm.productJournalFreeMonths };
                typesToSave.push(vm.productComponents[vm.findComponentIndex('free_months')]);
              }

              break;

            default:
              break;
          }
        } else {
          if (vm.productComponents[vm.findComponentIndex('journal')].id !== null) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('journal')]);
          }
          if (vm.productComponents[vm.findComponentIndex('subscription_contract')].id !== null) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('subscription_contract')]);
          }
          if (vm.productComponents[vm.findComponentIndex('expiration')].id !== null) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('expiration')]);
          }
          if (vm.productComponents[vm.findComponentIndex('free_months')].id !== null) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('free_months')]);
          }
          if (vm.productComponents[vm.findComponentIndex('start_date')].id !== null) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('start_date')]);
          }
          if (vm.productComponents[vm.findComponentIndex('credits')].id !== null) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('credits')]);
          }
        }
        return saveComponentsToBackend(typesToSave).then(function () {
          scrubComponentsFromBackend(typesToRemove).then(function () {
            resolve('Journal components removed');
          });
        });
      });
    }

    function saveProduct() {
      var productObject = {
        label: vm.productLabel,
        frLabel: vm.frProductLabel,
        code: vm.productLabel,
        description: vm.productDescription,
        descriptionFr: vm.productDescriptionFr,
        productCategories: vm.selectedProductCategories,
        grouped: vm.productIsGroupedOnTicket,
        contactRequired: vm.productContactRequired,
        fractionsAllowed: vm.productFractionsAllowed,
        visibleInWebshop: vm.productVisibleInWebshop,
        inputRequired: vm.productInputRequired,
        blueprint: vm.productBlueprint
      };

      if (vm.totalPct > 100) {
        ToastrNotificationService.showTranslatedNotification('alert', 'app.warning', 'product.warning.price-calculations-over-100');
      }

      return storeProductPhoto().then(function () {
        if (vm.isEdit()) {
          // patch product
          return ProductFactory.one(vm.currentProduct.uuid).patch(productObject)
            .then(function () {
              return vm.prepareJournalComponents();
            })
            .then(function () {
              return vm.prepareAnalyticalAccountComponent();
            })
            .then(function () {
              return vm.prepareLedgerComponent();
            })
            .then(function () {
              return vm.preparePriceComponent();
            })
            .then(function () {
              return vm.prepareGroupComponent();
            })
            .then(function () {
              return startComponentSaveSequence();
            });
        }

        // post product
        return ProductFactory.post(productObject)
          .then(function (returnData) {
            vm.currentProduct = returnData.data;
            vm.isNewProduct = true;
          })
          .then(function () {
            return vm.prepareJournalComponents();
          })
          .then(function () {
            return vm.prepareAnalyticalAccountComponent();
          })
          .then(function () {
            return vm.prepareLedgerComponent();
          })
          .then(function () {
            return vm.preparePriceComponent();
          })
          .then(function () {
            return vm.prepareGroupComponent();
          })
          .then(function () {
            return startComponentSaveSequence();
          });
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function onProductImageChange(changeEvent) {
      var files = changeEvent.target.files;

      // see: http://stackoverflow.com/questions/12234677/javascript-variable-undefined-when-onload-function-runs
      angular.forEach(files, function (file) {
        var reader;

        /* eslint-disable no-undef */
        reader = new FileReader();
        /* eslint-enable no-undef */

        // show a preview of the selected file
        reader.onload = function (e) {
          $timeout(function () {
            vm.productComponents[findComponentIndex('photo')].dirty = true;
            vm.productImageSrc = e.target.result;
            vm.productHasPhoto = true;
          });
        };
        reader.readAsDataURL(file);
      });
    }

    function getPhotoRestObject() {
      if (vm.productImageSrc === '') {
        return null;
      }

      return {
        label: 'Product Photo',
        encoding: 'base64',
        data: (vm.productImageSrc.split(';')[1]).substring(7)
      };
    }

    function getComponentRestObject(componentToConstruct) {
      var object;

      // construct object to use for rest call
      object = {};
      angular.forEach(componentToConstruct.fields, function (value, field) {
        object[field] = value;
      });
      object.site = vm.loggedInUserSiteId;
      return object;
    }

    function isEdit() {
      return vm.currentProduct !== null && angular.isDefined(vm.currentProduct) && !vm.isNewProduct;
    }

    function addComponentTab() {
      if (vm.productComponent.selected) {
        vm.selectedComponents.push(vm.productComponent.selected);
        vm.selectComponentTab(vm.productComponent.selected);
        vm.productComponent.selected = undefined;
      }
    }

    // this function is used by component.modal.add.tpl.html to add the highlighted component
    // and store it immediately.
    function addHighlightedComponentAndSaveProduct() {
      addComponentTab();
      saveProduct();
    }

    function selectComponentTab(newTab) {
      var selectComponentId = '#product-component-' + newTab.type;
      $timeout(function () {
        angular.element(selectComponentId).find('a').click();
      });
    }

    function productJournalActionChanged() {
      if ((vm.productJournalAction && vm.productJournalAction.code === 'RENEWAL') || vm.productJournalIsSubscriptionContract) {
        vm.productHasStartDate = true;
      }
    }

    function getAsyncLedgers(viewValue) {
      var params = {
        limit: 99,
        sort: 'code,ASC'
      };
      params['filter[]'] = ['OR,label,LIKE ' + viewValue + ',code,LIKE ' + viewValue];
      return LedgerFactory.getList(params);
    }

    function getAsyncProducts(viewValue) {
      var params = {
        limit: 99,
        sort: 'label,asc'
      };
      params['filter[]'] = ['mostRecent,TRUE'];
      params['filter[]'].push('label,LIKE ' + viewValue);
      params['filter[]'].push('blueprint,TRUE');
      params['filter[]'].push('OR,hidden,FALSE,hidden,NULL');
      if (vm.isEdit()) {
        params['filter[]'].push('uuid,NEQ ' + vm.currentProduct.uuid);
      }
      angular.forEach(vm.subProducts, function (subProduct) {
        params['filter[]'].push('uuid,NEQ ' + subProduct.uuid);
      });
      return new Promise(function (resolve) {
        ProductFactory.getList(params).then(function (products) {
          resolve(products.data);
        }, function () {
          resolve([]);
        });
      });
    }

    function prepareLedgerComponent() {
      var typesToSave = [];
      return new Promise(function (resolve) {
        vm.productComponents[vm.findComponentIndex('ledger')].fields.ledger = vm.productLedgerNumber.id;
        typesToSave.push(vm.productComponents[vm.findComponentIndex('ledger')]);
        return saveComponentsToBackend(typesToSave).then(function () {
          resolve('Journal components removed');
        });
      });
    }

    function preparePriceComponent() {
      var typesToSave = [],
          zeroVatRate;
      return new Promise(function (resolve) {
        vm.productComponents[vm.findComponentIndex('price')].fields.static = vm.productPriceIsStatic;
        if (vm.isGroupProduct) {
          zeroVatRate = vm.vatRates.filter(function (vr) {
            return vr.percentage === 0;
          })[0];
          vm.productComponents[vm.findComponentIndex('price')].fields.vatRate = zeroVatRate.id;
        } else {
          vm.productComponents[vm.findComponentIndex('price')].fields.vatRate = vm.priceVatRate;
        }
        vm.productComponents[vm.findComponentIndex('price')].fields.currency = 'EUR';
        vm.setPriceExclVat();
        // SHOULD NOT use this function but the calculateExclPrice from Util
        vm.productComponents[vm.findComponentIndex('price')].fields.price = vm.productPrice;
        typesToSave.push(vm.productComponents[vm.findComponentIndex('price')]);
        return saveComponentsToBackend(typesToSave).then(function () {
          resolve('save price component to backend');
        });
      });
    }

    function prepareGroupComponent() {
      var returnValue = null,
          typesToRemove = [];
      vm.finalSubProducts = [];
      vm.subProductsLeft = 0;
      return new Promise(function (resolve) {
        if (vm.isGroupProduct) {
          angular.forEach(vm.subProducts, function (subProduct) {
            ++vm.subProductsLeft;
            subProduct.priceComponent = subProduct.productComponents.filter(function (pc) {
              return pc.type === 'price';
            })[0];
            if (subProduct.blueprint) {
              LogService.log('Cloning product with UUID/ID: ' + subProduct.uuid, 'debug');
              ProductFactory.one(subProduct.uuid).post('clone?except[]=products', {}).then(function (result) {
                LogService.log('Cloned product UUID/ID: ' + result.data.uuid, 'debug');
                result.data.priceComponent = result.data.productComponents.filter(function (pc) {
                  return pc.type === 'price' && pc.site && pc.site.id === vm.loggedInUserSiteId;
                })[0];
                ProductFactory.one(result.data.uuid).patch({label: subProduct.label}).then(function () {
                  LogService.log('Patched product', 'debug');
                  result.data.priceComponent = result.data.productComponents.filter(function (pc) {
                    return pc.type === 'price' && pc.site && pc.site.id === vm.loggedInUserSiteId;
                  })[0];
                  ProductFactory.one(result.data.uuid).one('components').one(result.data.priceComponent.uuid).patch({
                    price: vm.calculatePriceExclVat(subProduct.price.priceInclVat, subProduct.price.vatRate.percentage),
                    currency: 'EUR',
                    vatRate: subProduct.priceComponent.vatRate.id,
                    static: subProduct.priceComponent.static
                  }, {}, {'x-entity': 'priceComponent'})
                  .then(function () {
                    ProductFactory.one(result.data.uuid).patch({blueprint: false, hidden: true}).then(function () {
                      LogService.log('Patched product', 'debug');
                      ProductFactory.one(result.data.uuid).get().then(function (newPatchedProduct) {
                        if (subProduct.id === vm.subProductMasterJournalId) {
                          vm.subProductMasterJournalId = newPatchedProduct.data.id;
                        }
                        vm.finalSubProducts.push(newPatchedProduct.data);
                        vm.notifyGroupProduct(resolve);
                      });
                    });
                  });
                });
              });
            } else {
              ProductFactory.one(subProduct.uuid).patch({label: subProduct.label}).then(function () {
                LogService.log('Patched product', 'debug');
                ProductFactory.one(subProduct.uuid).one('components').one(subProduct.priceComponent.uuid).patch({
                  price: vm.calculatePriceExclVat(subProduct.price.priceInclVat, subProduct.price.vatRate.percentage),
                  currency: 'EUR',
                  vatRate: subProduct.priceComponent.vatRate.id,
                  static: subProduct.priceComponent.productPriceIsStatic
                }, {}, {'x-entity': 'priceComponent'})
                .then(function () {
                  ProductFactory.one(subProduct.uuid).get().then(function (newPatchedProduct) {
                    if (subProduct.id === vm.subProductMasterJournalId) {
                      vm.subProductMasterJournalId = newPatchedProduct.data.id;
                    }
                    vm.finalSubProducts.push(newPatchedProduct.data);
                    vm.notifyGroupProduct(resolve);
                  });
                });
              });
            }
          });
        } else {
          // not a group
          LogService.log('not a combi product', 'debug');
          if (vm.wasGroupProduct) {
            typesToRemove.push(vm.productComponents[vm.findComponentIndex('products')]);
            returnValue = scrubComponentsFromBackend(typesToRemove).then(function () {
              resolve('group component removed');
            });
          } else {
            returnValue = resolve('not a group');
          }
          return returnValue;
        }
      });
    }

    function addSubProduct() {
      var subProductPrice,
          subProductJournalComponent;

      ProductFactory.one(vm.subProduct.uuid).get().then(function (resultProduct) {
        subProductPrice = resultProduct.data.productComponents.filter(function (pc) {
          return pc.type === 'price';
        })[0];
        if (angular.isUndefined(subProductPrice)) {
          ToastrNotificationService.showTranslatedNotification('warning', 'app.warning', 'product.warning.blueprint_without_price_component');
          return;
        }
        vm.subProducts.push(resultProduct.data);
        subProductJournalComponent = resultProduct.data.productComponents.filter(function (pc) {
          return pc.type === 'journal';
        });
        resultProduct.data.price = subProductPrice;
        resultProduct.data.price.priceInclVat = vm.calculatePriceInclVat(subProductPrice.price, subProductPrice.vatRate.percentage);
        resultProduct.data.hasJournalComponent = UtilService.isNotEmpty(subProductJournalComponent);
        vm.calculateGroupProductPrice();
        vm.updateGroupProductProperties();
      });
      vm.subProduct = null;
    }

    function removeSubProduct(subProduct) {
      var productIndex = vm.subProducts.indexOf(subProduct);
      if (productIndex > -1) {
        if (subProduct.id === vm.subProductMasterJournalId) {
          vm.subProductMasterJournalId = null;
        }
        vm.subProducts.splice(productIndex, 1);
        vm.calculateGroupProductPrice();
        vm.updateGroupProductProperties();
      }
    }

    function getAsyncAnalyticalAccounts(viewValue) {
      var params = {
        limit: 99,
        sort: 'code,ASC'
      };
      params['filter[]'] = ['OR,label,LIKE ' + viewValue + ',code,LIKE ' + viewValue];
      return AnalyticalAccountFactory.getList(params);
    }

    function prepareAnalyticalAccountComponent() {
      var typesToSave = [], returnValue, typesToRemove = [];
      return new Promise(function (resolve) {
        if (angular.isDefined(vm.productAnalyticalAccount) &&
          vm.productAnalyticalAccount !== null &&
          vm.productAnalyticalAccount.toString().length > 0) {
          vm.productComponents[vm.findComponentIndex('analytical_account')].fields.analyticalAccount = vm.productAnalyticalAccount.id;
          typesToSave.push(vm.productComponents[vm.findComponentIndex('analytical_account')]);
          returnValue = saveComponentsToBackend(typesToSave).then(function () {
            resolve('Journal components removed');
          });
        } else {
          typesToRemove.push(vm.productComponents[vm.findComponentIndex('analytical_account')]);
          returnValue = scrubComponentsFromBackend(typesToRemove).then(function () {
            resolve('analytical account removed');
          });
        }
        return returnValue;
      });
    }

    vm.validatePriceInPos = function () {
      return (
        vm.isGroupProduct &&
        (
          !vm.calculationPct ||
          Math.round(vm.totalPct * 10000) / 10000 === 100
        )
      );
    };

    function calculatePriceInPos() {
      var total = 0,
          unitPrice = 0,
          vatPercentage = 0,
          itemSubtotalAmount = 0,
          itemVatAmount = 0,
          itemTotalAmount = 0;

      angular.forEach(vm.subProducts, function (subProduct) {
        vatPercentage = subProduct.price.vatRate.percentage;
        unitPrice = ProductUtilService.calculateExclPrice(subProduct.price.priceInclVat, vatPercentage).exclPrice;
        // the unit price is stored in the DB as double, with a precision up to 14 decimal places
        // because of that, we have to simulate the loss of precision here to see exact numbers
        unitPrice = Math.round(unitPrice * 100000000000000) / 100000000000000;

        // calculate VAT
        itemVatAmount = (unitPrice * vatPercentage) / 100;

        // Calculate totals
        itemVatAmount = ProductUtilService.roundPrice(itemVatAmount);
        itemSubtotalAmount = ProductUtilService.roundPrice(unitPrice);
        itemTotalAmount = itemVatAmount + itemSubtotalAmount;
        subProduct.price.priceInPos = itemTotalAmount;
        total += itemTotalAmount;
      });

      vm.priceInPos = ProductUtilService.roundPrice(total);
    }

    vm.adjustPriceInPos = function () {
      var firstSubProduct = vm.subProducts[0],
          secondSubProduct = vm.subProducts[1],
          maxIncrements = 1000,
          increment = 0;

      while (vm.priceInclVat !== vm.priceInPos && increment < maxIncrements) {
        firstSubProduct.price.priceInclVatPct += 0.0001;
        secondSubProduct.price.priceInclVatPct -= 0.0001;
        vm.calculateGroupProductPct();
        increment++;
      }
    };

    // reload the page if all invoices are done being published
    vm.notifyGroupProduct = function (resolve) {
      var typesToSave = [];

      --vm.subProductsLeft;
      if (vm.subProductsLeft <= 0) {
        vm.productComponents[vm.findComponentIndex('products')].fields.products = stripIdFromFieldValue('products', vm.finalSubProducts);
        vm.productComponents[vm.findComponentIndex('products')].fields.masterJournalProduct = vm.subProductMasterJournalId;
        typesToSave.push(vm.productComponents[vm.findComponentIndex('products')]);
        return saveComponentsToBackend(typesToSave).then(function () {
          resolve('save products component to backend');
        });
      }
    };
  }
}());
