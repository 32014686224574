(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .factory('ReportFactory', ReportFactory);

  function ReportFactory(Restangular) {
    return Restangular.service('reports');
  }
}());
