(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:promiseButton
   * @restrict AEC
   *
   */
  /* @ngInject */
  angular
    .module('calendar')
    .directive('eventBadge', eventBadge);

  function eventBadge() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        callback: '&',
        options: '=?',
        eventColor: '@',
        eventInstance: '=?'
      },
      controller: ['$scope', 'moment', 'EventInstanceService', function ($scope, moment, EventInstanceService) {
        $scope.duration = EventInstanceService.getDuration($scope.eventInstance);
        if ($scope.eventInstance.event.fitnessCourses[0].tags.length > 0) {
          $scope.color = $scope.eventInstance.event.fitnessCourses[0].tags[0].color;
        }
      }],
      template:
        '<div class="event" ng-class="[color]">' +
        '<div class="time-and-duration">' +
        '<div class="time">{{ eventInstance.startsAt | date: "HH:mm" }}</div>' +
        '<div class="duration">' +
        '<span class="ion-ios-timer"></span> ' +
        '{{ duration }} min' +
        //'{{ eventInstance.startsAt | date: "dd.MM" }}' +
        '</div>' +
        '</div>' +
        '<div class="name">{{ eventInstance.event.label }}</div>' +
        '<div class="location">{{ eventInstance.facility.label }}</div>' +
        '<div class="clearfix"></div>' +
        '</div>'
    };
  }
}());
