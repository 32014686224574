(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:GroupAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('GroupAddEditCtrl', GroupAddEditCtrl);

  function GroupAddEditCtrl(
    $modalInstance,
    $modal,
    LogService,
    $cookies,
    SwimCourseSetFactory,
    SiteFactory,
    ContactFactory,
    set,
    series,
    swimCourseGroup,
    CurrentUserContextFactory,
    seriesGroup
  ) {
    var vm = this,
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.selectedTrainer = null;
    vm.trainers = [];
    vm.maxPupils = 10;
    vm.currentSet = set;
    vm.currentSeries = series;
    vm.swimCourseGroup = swimCourseGroup;
    vm.currentSeriesGroup = seriesGroup;
    vm.saveGroup = saveGroup;
    vm.getGroupRestObject = getGroupRestObject;
    vm.cancel = cancelModalInstance;
    vm.isEdit = isEdit;
    vm.loadData = loadData;
    vm.loadData();

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function loadData() {
      var contactParams = {
        limit: 99
      };
      SiteFactory.one(currentLoggedInUserSiteId).get().then(function (resultSite) {
        contactParams['filter[]'] = ['childContactRelations.parentContact.id,' + resultSite.contact.id];
        contactParams['filter[]'].push('childContactRelations.contactRelationType.code,EMPLOYMENT');
        ContactFactory.getList(contactParams).then(function (resultContacts) {
          vm.trainers = resultContacts;
          if (vm.isEdit()) {
            vm.selectedTrainer = vm.currentSeriesGroup.trainerContact;
            vm.maxPupils = vm.currentSeriesGroup.maximumMembers;
          }
        });
      });
    }

    function saveGroup() {
      var reloadOption = 'reloadListData', groupObject;

      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        groupObject = getGroupRestObject();
        // Patch the group
        SwimCourseSetFactory.one(vm.currentSet.id).one('series').one(vm.currentSeries.id).one('groups')
        .one(vm.currentSeriesGroup.seriesGroupId)
        .patch(groupObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        groupObject = getGroupRestObject();
        // Create the group
        SwimCourseSetFactory.one(vm.currentSet.id).one('series').one(vm.currentSeries.id).post('groups', groupObject)
        .then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function getGroupRestObject() {
      var result = {
        trainerContact: vm.selectedTrainer.id,
        maximumMembers: vm.maxPupils
      };

      if (!isEdit()) {
        result.swimCourseGroup = vm.swimCourseGroup.id;
      }
      return result;
    }

    function isEdit() {
      return vm.currentSeriesGroup !== null;
    }
  }
}());
