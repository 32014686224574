(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:SuspensionEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('SuspensionEditCtrl', SuspensionEditCtrl);

  function SuspensionEditCtrl(
    $cookies,
    $filter,
    LogService,
    $modalInstance,
    journal,
    suspension,
    JournalManagementFactory,
    ToastrNotificationService,
    CurrentUserContextFactory,
    UtilService,
    moment
  ) {
    var vm = this;
    vm.journal = journal;
    vm.suspension = suspension;
    vm.cancel = cancelModalInstance;
    vm.saveSuspension = saveSuspension;
    vm.showSavedNotification = showSavedNotification;
    vm.openDatePicker = openDatePicker;
    vm.isEdit = isEdit;
    vm.allowAppend = vm.isEdit() ? suspension.allowAppend : false;
    vm.suspensionEndDatePickerIsOpened = false;
    vm.suspensionError = false;
    vm.suspensionErrorMsg = '';
    vm.suspensionStartDatePickerIsOpened = false;
    vm.journalSuspensionStartInput = vm.isEdit() ? new Date(suspension.startDate) : new Date();
    vm.journalSuspensionEndInput = (vm.isEdit() && suspension.endDate) ? new Date(suspension.endDate) : null;
    vm.journalSuspensionCommentsInput = vm.isEdit() ? suspension.comments : null;
    // vm.maxDate = new Date(journal.expiration);
    // vm.minDate = new Date();
    vm.resumptionMinDate = vm.journalSuspensionStartInput;
    vm.saveSuspension = saveSuspension;
    vm.deleteSuspension = deleteSuspension;

    vm.facility = {
      id: CurrentUserContextFactory.getSiteFacilityId()
    };

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function showSavedNotification(itemSaved) {
      // Show notification at successful save
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-saved',
        itemSaved,
        'app.item-successfully-updated',
        itemSaved
      );
      $modalInstance.close('reloadJournals');
    }

    function openDatePicker(editType) {
      switch (editType) {
        case 'suspension-start':
          vm.suspensionStartDatePickerIsOpened = true;
          break;

        case 'suspension-end':
          vm.suspensionEndDatePickerIsOpened = true;
          break;

        default:
          break;
      }
    }

    function deleteSuspension() {
      if (vm.isEdit()) {
        JournalManagementFactory.deleteJournalSuspension(journal.id, vm.suspension.id)
        .then(function () {
          $modalInstance.close();
        });
      }
    }

    function saveSuspension() {
      // Suspension or Suspension and resumption of journal
      var suspensionRestObject = {
        startDate: vm.journalSuspensionStartInput,
        comments: vm.journalSuspensionCommentsInput,
        allowAppend: vm.allowAppend
      };
      vm.suspensionError = false;

      if (UtilService.isNotEmpty(vm.journalSuspensionEndInput)) {
        suspensionRestObject.endDate = vm.journalSuspensionEndInput;
      }

      if (vm.isEdit()) {
        suspensionRestObject.id = vm.suspension.id;
      }
      angular.forEach(vm.journal.suspensions, function (susp) {
        var startDate = new Date(susp.startDate),
            endDate = new Date(susp.endDate);
        if (((vm.isEdit() && vm.suspension.id !== susp.id) || !vm.isEdit()) && (startDate < vm.journalSuspensionStartInput && vm.journalSuspensionStartInput < endDate)) {
          vm.suspensionError = true;
          vm.suspensionErrorMsg = $filter('sprintf')(
            $filter('translate')('app.suspension-errormsg-with-params'),
            moment(new Date(susp.startDate)).format('DD/MM/YYYY'),
            moment(new Date(susp.endDate)).format('DD/MM/YYYY')
          );
          if (!vm.journalSuspensionCommentsInput) {
            vm.journalSuspensionCommentsInput = '';
          } else {
            vm.journalSuspensionCommentsInput += '\n\n';
          }
          vm.journalSuspensionCommentsInput += $filter('sprintf')(
            $filter('translate')('app.suspension-comment-with-params'),
            moment(vm.journalSuspensionStartInput).format('DD/MM/YYYY'),
            moment(vm.journalSuspensionEndInput).format('DD/MM/YYYY'),
            moment(new Date(susp.startDate)).format('DD/MM/YYYY'),
            moment(new Date(susp.endDate)).format('DD/MM/YYYY')
          );
          vm.suspEndDate = new Date(susp.endDate);
          vm.journalSuspensionStartInput = new Date(vm.suspEndDate.setDate((vm.suspEndDate.getDate())));
        }
      });
      if (!vm.suspensionError) {
        JournalManagementFactory.saveJournalSuspension(journal.id, suspensionRestObject)
        .then(function () {
          vm.showSavedNotification('journal.suspension');
        });
      }
    }

    function isEdit() {
      return UtilService.isNotEmpty(vm.suspension);
    }
  }
}());
