(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:uniqueVatNumberValidator
   * @restrict A
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('uniqueVatNumberValidator', uniqueVatNumberValidator);

  function uniqueVatNumberValidator(
    $filter,
    $q,
    Restangular,
    SettingsService
    ) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attr, ngModel) {
        ngModel.$asyncValidators.vatNumberNotUnique = function (modelValue) {
          var deferred = $q.defer(),
              useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
          if (modelValue && useAlternateCustomerForm) {
            Restangular.one('services').one('validate-unique-vat-number', modelValue).one(attr.contactId).get().then(function (response) {
              var errorMsg = {
                config: {
                  headers: {'x-entity': 'contact'}
                },
                data: {
                  errors: {
                    children: {
                      vat: []
                    }
                  }
                }
              };

              if (response.data.valid) {
                // broadcast errorMsg even if field is valid, to remove already shown error
                scope.$root.$broadcast('showFormErrorMsgBroadcast', errorMsg);
                deferred.resolve();
              } else {
                errorMsg.data.errors.children.vat = [($filter('uconlyfirst')($filter('translate')('contact.vatnumber.not_unique')))];
                scope.$root.$broadcast('showFormErrorMsgBroadcast', errorMsg);
                deferred.reject();
              }
            });
          } else {
            deferred.resolve();
          }
          return deferred.promise;
        };
      }
    };
  }
}());
