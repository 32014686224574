(function () {
  'use strict';

  angular
    .module('customer')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('customer', {
        url: '/customer',
        templateUrl: 'customer/views/customer.tpl.html',
        controller: 'CustomerCtrl',
        controllerAs: 'customerCtrl'
      })
      .state('customerdetail', {
        url: '/customer/detail?id&action',
        templateUrl: 'customer/views/customer.detail.tpl.html',
        controller: 'CustomerCtrl',
        controllerAs: 'customerCtrl'
      })
      ;
  }
}());
