(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:InvoiceCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('InvoiceCtrl', InvoiceCtrl);

  function InvoiceCtrl(CurrentUserContextFactory, InvoiceFactory, UtilService, BillingDivisionFactory, _, LogService, PermissionsUtilService, $state, $filter, $scope, OAuthToken, $window, Restangular) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.permissionsUtilService = PermissionsUtilService;
    vm.invoices = [];
    vm.billingDivisions = [];
    vm.modalInstance = null;
    vm.modalEditInstance = null;
    vm.modalOpened = false;
    vm.editModalOpened = false;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      maxPages: 10,
      itemsPerPage: 15
    };

    vm.filters = {
      customer: {
        val: '',
        field: 'customer.label',
        cond: 'LIKE'
      },
      reference: {
        val: '',
        field: 'reference',
        cond: 'LIKE'
      },
      invoiceDateFrom: {
        val: '',
        field: 'date',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.invoiceDateFrom.isOpen = true;
        }
      },
      invoiceDateTo: {
        val: '',
        field: 'date',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.invoiceDateTo.isOpen = true;
        }
      },
      dueDateFrom: {
        val: '',
        field: 'dueDate',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.dueDateFrom.isOpen = true;
        }
      },
      dueDateTo: {
        val: '',
        field: 'dueDate',
        cond: 'LTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.dueDateTo.isOpen = true;
        }
      },
      salesReference: {
        val: '',
        field: 'sales.reference',
        cond: 'LIKE'
      },
      billingDivision: {
        val: '',
        field: 'billingDivision.id',
        cond: ''
      },
      invoiceStatus: {
        val: '',
        field: 'published',
        cond: ''
      },
      OGM: {
        val: '',
        field: 'structuredReference',
        cond: 'LIKE'
      },
      discount: {
        val: '',
        field: '',
        cond: 'LIKE'
      }
    };
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.emitSaleEditEvent = emitSaleEditEvent;
    vm.loadInvoices = loadInvoices;
    vm.downloadPdf = downloadPdf;
    vm.showInvoiceModalView = showInvoiceModalView;
    vm.showInvoiceModalEditView = showInvoiceModalEditView;
    vm.selectInvoices = selectInvoices;
    vm.removeInvoices = removeInvoices;
    vm.removeInvoicesAndSales = removeInvoicesAndSales;
    vm.createCreditNote = createCreditNote;
    /* ----- END FUNCTIONS ----- */

    /* ----- ON INIT ----- */
    BillingDivisionFactory.getList({limit: 99})
      .then(function (result) {
        vm.billingDivisions = result;
      });
    /* ----- END ON INIT ----- */

    /* ----- OTHER ----- */
    function showInvoiceModalEditView(viewObject) {
      LogService.log('opening invoice edit modal', 'debug');
      UtilService.showModal(
        {
          templateUrl: 'accountancy/views/accountancy.invoice.edit.modal.tpl.html',
          controller: 'InvoiceEditCtrl',
          controllerAs: 'invoiceEditCtrl',
          resolve: {
            invoice: function () {
              return viewObject;
            }
          }
        },
        editModalResultHandler);
    }

    function showInvoiceModalView(viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'accountancy/views/accountancy.invoice.view.modal.tpl.html',
          controller: 'InvoiceViewCtrl',
          controllerAs: 'invoiceViewCtrl',
          resolve: {
            invoice: function () {
              return viewObject;
            }
          }
        },
        editModalResultHandler);
    }

    function editModalResultHandler() {
      vm.reloadItems();
    }

    function downloadPdf(format, id) {
      var link = Restangular.configuration.baseUrl + '/services/invoices_export.json?access_token=' +
          OAuthToken.getAccessToken(),
          idsToAdd = [];

      // download single pdf if applicable
      if (angular.isDefined(id)) {
        idsToAdd.push(id);
      } else {
        // add all the invoice ids
        _.each(vm.invoices[vm.pagination.currentPage], function (item) {
          if (item.hasOwnProperty('selected') && item.selected) {
            idsToAdd.push(item.id);
          }
        });
      }

      // concat id-string
      _.each(idsToAdd, function (idToAdd) {
        link += '&invoices[]=' + idToAdd;
      });

      link += '&format=';
      switch (format) {
        case 'pdf_batch':
          link += 'pdf_batch';
          break;
        default:
          link += 'pdf';
          break;
      }

      // open in new tab
      $window.open(link, '_blank');
      return link;
    }

    function selectInvoices(selectType) {
      _.each(vm.invoices[vm.pagination.currentPage], function (item) {
        switch (selectType) {
          case 'all':
            item.selected = true;
            break;
          case 'none':
            item.selected = false;
            break;
          case 'invert':
            item.selected = !item.selected;
            break;
          default:
            break;
        }
      });
    }

    function removeInvoices(id) {
      var invoice;
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.invoice', 'app.delete-msg', 'app.this_invoice',
        function (returnValue) {
          if (returnValue) {
            invoice = _.findWhere(vm.invoices[vm.pagination.currentPage], {id: id});
            invoice.remove().then(function () {
              LogService.log('patched! Loading invoices', 'debug');
              vm.loadInvoices(true);
            });
          }
        });
    }

    function removeInvoicesAndSales(invoiceId) {
      var invoice;
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.invoice_and_sales', 'app.delete-msg', 'app.this_invoice_and_sales',
        function (returnValue) {
          if (returnValue) {
            invoice = _.findWhere(vm.invoices[vm.pagination.currentPage], {id: invoiceId});
            InvoiceFactory.removeWithSales(invoice.id).then(function () {
              LogService.log('patched! Loading invoices', 'debug');
              vm.loadInvoices(true);
            });
          }
        });
    }

    function loadInvoices(reload) {
      var queryParams, value, condition = '';

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.invoices[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          context: CurrentUserContextFactory.getUserContextId(),
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage,
          sort: 'createdAt,DESC'
        };

        _.each(vm.filters, function (filter, index) {
          if (angular.isDefined(filter.val) && filter.val) {
            switch (index) {
              case 'invoiceDateFrom':
              case 'dueDateFrom':
                filter.val.setHours(0);
                filter.val.setMinutes(0);
                filter.val.setSeconds(0);
                value = $filter('date')(filter.val, filter.format);
                break;
              case 'invoiceDateTo':
              case 'dueDateTo':
                filter.val.setHours(23);
                filter.val.setMinutes(59);
                filter.val.setSeconds(59);
                value = $filter('date')(filter.val, filter.format);
                break;
              default:
                value = filter.val;
                break;
            }
            condition = '';

            if (UtilService.isNotEmpty(filter.cond)) {
              condition = filter.cond + ' ';
            }

            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
          }
        });

        InvoiceFactory.getList(queryParams).then(function (data) {
          vm.invoices[vm.pagination.currentPage] = data;
          vm.pagination.totalItems = data.count;
        });
      }
    }

    function createCreditNote(invoiceId, downloadImmediately) {
      if (angular.isUndefined(invoiceId)) {
        return;
      }

      Restangular.one('services/create_credit_note').customGET('', {invoiceId: invoiceId}).then(function (data) {
        vm.reloadItems();

        if (downloadImmediately && angular.isDefined(data.creditNoteId)) {
          downloadPdf('pdf', data.creditNoteId);
        }
      });
    }

    vm.reloadItems = function () {
      vm.loadInvoices(true);
    };

    vm.toggleInvoices = function () {
      _.each(vm.invoices[vm.pagination.currentPage], function (item) {
        item.selected = !item.selected;
      });
    };

    vm.approveInvoices = function (id) {
      var invoice;

      invoice = _.findWhere(vm.invoices[vm.pagination.currentPage], {id: id});
      invoice.patch({published: true}).then(function () {
        LogService.log('patched! Loading invoices', 'debug');
        vm.loadInvoices(true);
      });

      // $state.go($state.current, {}, {reload: true});
    };

    vm.approveSelectedInvoices = function () {
      var data = {};
      data.invoices = [];
      // collect all the invoices to patch
      angular.forEach(vm.invoices, function (page) {
        angular.forEach(page, function (item) {
          if (UtilService.isNotEmpty(item.selected) && item.selected) {
            if (item.validated) {
              data.invoices.push(item.id);
            }
          }
        });
      });

      if (data.invoices.length > 0) {
        Restangular.all('services/publish-invoices').customPOST(data).then(function () {
          vm.loadInvoices(true);
        });
      }
    };

    // broadcast responder for refreshing invoices
    $scope.$on('broadcastInvoiceReloadEvent', function (event, reloadType) {
      if (!event.defaultPrevented) {
        LogService.log('invoice reload broadcast received', 'debug');
        LogService.log(event, 'debug');
        LogService.log(reloadType, 'debug');
        // stop the event
        event.preventDefault();
        // reload invoice list
        vm.reloadItems();
      }
    });

    function emitSaleEditEvent(sale) {
      $scope.$emit('emitSaleEditEvent', sale);
    }

    /* ----- END OTHER ----- */
  }
}());
