(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.factory:SettingsService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .factory('SettingsService', SettingsService);

  function SettingsService(
    $injector,
    $localStorage,
    $q,
    $rootScope,
    $state,
    $timeout,
    _,
    CurrentUserContextFactory,
    LogService,
    RestUtilService,
    SettingFactory,
    UserMeFactory,
    UtilService
  ) {
    var vm = this;
    vm.getAll = getAll;
    vm.get = get;
    vm.getRaw = getRaw;
    vm.set = set;
    vm.remove = remove;
    vm.reloadSettings = reloadSettings;

    function getAll(key, params) {
      params = params || {};

      if (angular.isUndefined(params['filter[]'])) {
        params['filter[]'] = [];
      }

      params['filter[]'] = _.union(params['filter[]'], ['code,' + key]);

      return $q(function (resolve, reject) {
        RestUtilService.getFullList(SettingFactory, params).then(function (results) {
          resolve(results);
        }, function () {
          reject();
        });
      });
    }

    function get(key, defaultValue) {
      var setting = null;

      if (angular.isUndefined($localStorage.currentUserSettings)) {
        return null;
      }

      if (key in $localStorage.currentUserSettings) {
        setting = $localStorage.currentUserSettings[key];
        if (angular.isString(setting) && (setting.toLowerCase() === 'false' || setting.toLowerCase() === 'off')) {
          setting = false;
        }
      } else if (angular.isDefined(defaultValue)) {
        setting = defaultValue;
      }

      return setting;
    }

    // use this async method if you need a setting before the user logs in
    function getRaw(key, defaultValue, site, params) {
      var defaultObject,
          that = this;

      params = params || {};
      site = site || null;
      defaultValue = defaultValue || null;
      defaultObject = {
        id: null,
        code: key,
        value: defaultValue,
        site: site
      };

      if (angular.isUndefined(params['filter[]'])) {
        params['filter[]'] = [];
      }

      params['filter[]'] = _.union(params['filter[]'], ['site.id,' + (site ? site : 'NULL')]);
      params.limit = 1;

      return $q(function (resolve) {
        that.getAll(key, params).then(function (results) {
          if (results.length) {
            resolve(results[0]);
          } else {
            resolve(defaultObject);
          }
        }, function () {
          resolve(defaultObject);
        });
      });
    }

    function set(key, value, site) {
      var that = this,
          setting;

      return $q(function (resolve, reject) {
        that.getRaw(key, null, site).then(function (result) {
          if (result.id) {
            setting = result;
            setting.value = value;

            SettingFactory.one(setting.id).patch({
              value: value
            }).then(function () {
              $localStorage.currentUserSettings[key] = value;
              resolve(setting);
            }, function () {
              reject();
            });
          } else {
            setting = {
              code: key,
              site: site,
              value: value
            };

            SettingFactory.post(setting).then(function (res) {
              $localStorage.currentUserSettings[key] = value;
              resolve(res);
            }, function () {
              reject();
            });
          }
        }, function () {
          reject();
        });
      });
    }

    function remove(key, site) {
      var setting = this.get(key, null, site);

      return $q(function (resolve, reject) {
        if (setting) {
          SettingFactory.one(setting.id).remove().then(function () {
            resolve();
          }, function () {
            reject();
          });
        } else {
          resolve();
        }
      });
    }

    function reloadSettings(userContext, reload) {
      var hwproxy = $injector.get('hwproxy'),
          PaymentEngineFactory = $injector.get('PaymentEngineFactory'),
          userContextId = angular.isDefined(userContext) ? userContext.id : CurrentUserContextFactory.getUserContextId();

      return Promise.all([
        SettingFactory.getListSimple(),
        UserMeFactory.one('permissions').get()
      ]).then(function (result) {
        $localStorage.currentUserSettings = result[0];
        $localStorage.currentUserPermissions = result[1][userContextId];
        $rootScope.standaloneMode = get('pos.standaloneMode', false);
        $rootScope.enablePaymentEngineConnection = PaymentEngineFactory.enabled() && !$rootScope.standaloneMode;
        $rootScope.enableHwproxyConnection = hwproxy.enabled();
        // run angular digest so all buttons show up in the navbar
        $timeout();
        $rootScope.$broadcast('userLoaded');

        if (reload !== false) {
          if ($state.is('dashboard')) {
            UtilService.reloadState();
          } else {
            $state.go('dashboard');
          }
        }
      });
    }

    return vm;
  }
}());
