(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name pos.controller:ShoppingCartCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('LinkSaleCtrl', LinkSaleCtrl);

  function LinkSaleCtrl($modal, $modalInstance, FilterUtilService, SaleFactory, UtilService) {
    var vm = this;
    vm.cancel = cancel;
    vm.close = close;
    vm.isPosSale = isPosSale;
    vm.linkSale = linkSale;
    vm.reloadSales = reloadSales;
    vm.showSaleModalView = showSaleModalView;
    vm.filters = {
      reference: {
        val: '',
        field: 'reference',
        cond: 'LIKE'
      },
      first_name: {
        val: '',
        field: 'customerContact.contact.firstName',
        cond: 'LIKE'
      },
      last_name: {
        val: '',
        field: 'customerContact.contact.lastName',
        cond: 'LIKE'
      },
      dateFrom: {
        val: '',
        field: 'createdAt',
        cond: 'GTE',
        format: 'yyyy-MM-dd HH:mm:ss',
        isOpen: false,
        open: function () {
          vm.filters.dateFrom.isOpen = true;
        }
      },
      status: {
        val: 'COMPLETED',
        field: 'saleStatus.code'
      },
      ssn: {
        field: 'customer.customerContacts.contact.contactData.ContactStringData.value',
        val: '',
        cond: 'LIKE'
      },
      companyName: {
        val: ''
      },
      vatNumber: {
        field: 'customer.customerContacts.contact.contactData.ContactVatData.value',
        val: '',
        cond: 'LIKE'
      },
      companyNumber: {
        field: 'customer.customerContacts.contact.contactData.ContactCompanyNumberData.value',
        val: '',
        cond: 'LIKE'
      }
    };
    vm.sales = [];

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function close(sale) {
      $modalInstance.close(sale);
    }

    function isPosSale(sale) {
      return sale.hasOwnProperty('pointOfSaleSession');
    }

    function linkSale(sale) {
      vm.close(sale);
    }

    function reloadSales() {
      return new Promise(function (resolve) {
        var filterParams, queryParams = {
              limit: 99,
              sort: 'createdAt,DESC'
            };

        if (FilterUtilService.isEmpty(vm.filters, {ignore: ['status']})) {
          vm.sales = [];
          resolve();
        } else {
          /* make from dateFrom filter exact date */
          vm.filters.dateTo = angular.copy(vm.filters.dateFrom);
          vm.filters.dateTo.cond = 'LTE';

          filterParams = FilterUtilService.createQueryParamsFromFilters(vm.filters);
          if (vm.filters.companyName.val) {
            queryParams['filter[companyName]'] = 'customerContact.contact.label,LIKE ' + vm.filters.companyName.val;
            queryParams['filter[first_name]'] = 'customerContact.contact.firstName,NULL';
            queryParams['filter[last_name]'] = 'customerContact.contact.lastName,NULL';
          }
          queryParams = angular.merge(queryParams, filterParams);

          SaleFactory.getList(queryParams).then(function (data) {
            vm.sales = data;
            resolve();
          });
        }
      });
    }

    function showSaleModalView(viewObject) {
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
        controller: 'SaleViewCtrl',
        controllerAs: 'saleViewCtrl',
        resolve: {
          sale: function () {
            return viewObject;
          }
        }
      }, function () {
      }, function () {

      }, true, true);
    }
  }
}());
