(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name subscriptionContract.controller:SubscriptionContractCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('subscriptionContract')
    .controller('SubscriptionContractCtrl', SubscriptionContractCtrl);

  function SubscriptionContractCtrl(
    SubscriptionContractFactory,
    LogService
  ) {
    var vm = this,
        params = {
          limit: 99
        };
    SubscriptionContractFactory.getList(params).then(function (resultContracts) {
      vm.subscriptionContracts = resultContracts;
      LogService.log(resultContracts, 'debug');
    });
  }
}());
