(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:dateBiggerThan
   * @restrict AE
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('dateBiggerThan', dateBiggerThan)
    .directive('dateLesserThan', dateLesserThan);

  function dateBiggerThan() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, model) {
        scope.$watch(attrs.ngModel, function () {
          var otherDate, theDate = model.$modelValue;
          otherDate = scope.$eval(attrs.dateBiggerThan);
          if (theDate >= otherDate) {
            model.$setValidity('meaning', true);
          } else {
            model.$setValidity('meaning', false);
          }
        });
      }
    };
  }

  function dateLesserThan() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, model) {
        scope.$watch(attrs.ngModel, function () {
          var otherDate, theDate = model.$modelValue;
          otherDate = scope.$eval(attrs.dateLesserThan);
          if (theDate <= otherDate) {
            model.$setValidity('meaning', true);
          } else {
            model.$setValidity('meaning', false);
          }
        });
      }
    };
  }
}());
