(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('ContactListCtrl', ContactListCtrl);

  function ContactListCtrl($scope,
                           ContactFactory,
                           CustomerFactory,
                           ImageService,
                           LogService,
                           SettingsService,
                           UtilService) {
    var vm = this;
    vm.getCustomerContactData = getCustomerContactData;
    vm.searchContact = searchContact;
    vm.contactSearchQuery = '';
    vm.showModalView = showModalView;
    vm.showModalCreateUser = showModalCreateUser;
    vm.showModalAddEditView = showModalAddEditView;
    vm.showModalDeleteView = showModalDeleteView;
    vm.reloadListData = reloadListData;
    vm.isNotBillingType = isNotBillingType;
    vm.isUserDefined = isUserDefined;
    vm.showOnlineAccessInfo = !SettingsService.get('customers.hideCreateOnlineAccessButton', false);
    // Dirty hack for Gulp errors
    /* eslint-disable angular/ng_controller_as*/
    $scope.init = init;
    /* eslint-enable angular/ng_controller_as*/

    // init scope function for 'ng-init' from template
    function init(customer) {
      vm.customer = customer;
      vm.getCustomerContactData();
    }

    // Get customer contact data
    function getCustomerContactData() {
      vm.contacts = [];
      // Loop all customerContacts from current customer
      angular.forEach(vm.customer.customerContacts, function (contact) {
        // Get the customer contact
        CustomerFactory.one(vm.customer.id).one('contacts', contact.id).get().then(function (completeContact) {
          // Add the customer contact to the contacts-array
          vm.contacts.push(completeContact);
          // Get Contact linked to the customer
          ContactFactory.one(completeContact.contact.id).get().then(function (contactDetails) {
            completeContact.contact.contactType = contactDetails.contactType;
            completeContact.completeContact = contactDetails;
            // Check if contact has a photo
            if (completeContact.completeContact.contactPhotos.length > 0) {
              // Get Photo from backend
              ImageService.getImageFromBackend(completeContact.completeContact.contactPhotos[0].photo.id).then(function (photo) {
                completeContact.completeContact.photo = photo;
                completeContact.completeContact.hasPhoto = true;
              });
            }
            vm.reloadList = true;
          });
        });
      });
    }

    // Search Function for the list
    function searchContact(item) {
      var lcQuery = vm.contactSearchQuery.toLowerCase();
      // Check if isEmpty, indexOf() is -1 when not found in the item
      if (lcQuery.length === 0 ||
        item.contact.label.toLowerCase().indexOf(lcQuery) !== -1 ||
        item.customerContactType.label.toLowerCase().indexOf(lcQuery) !== -1) {
        return true;
      }
      return false;
    }

    // Filter Function for the list
    function isNotBillingType(item) {
      if (item.customerContactType.code === 'BILLING') {
        return false;
      }
      return true;
    }

    function showModalView(viewObject) {
      // Open modal view with specific template and controller, Add a customer and contact object to that controller
      UtilService.showModal({
        templateUrl: 'contact/views/contact.modal.view.tpl.html',
        controller: 'ContactCtrl',
        controllerAs: 'contactCtrl',
        size: 'lg',
        resolve: {
          contact: function () {
            return viewObject;
          },
          customer: function () {
            return vm.customer;
          },
          showOnlineAccessInfo: function () {
            return true;
          }
        }
      });
    }

    function showModalAddEditView(currentObject) {
      // Open modal view with specific template and controller, Add a customer and customerContact object to that controller
      UtilService.showModal(
        {
          templateUrl: 'contact/views/contact.modal.addedit.tpl.html',
          controller: 'ContactAddEditCtrl',
          controllerAs: 'contactAddEditCtrl',
          size: 'lg',
          resolve: {
            customer: function () {
              return vm.customer;
            },
            customerContact: function () {
              return currentObject;
            }
          }
        },
        modalResultHandler);
    }

    function showModalDeleteView(viewObject) {
      // Open modal view with specific template and controller, Add a customer and contact object to that controller
      UtilService.showModal(
        {
          templateUrl: 'contact/views/contact.modal.delete.tpl.html',
          controller: 'CustomerContactDeleteCtrl',
          controllerAs: 'customerContactDeleteCtrl',
          resolve: {
            customer: function () {
              return vm.customer;
            },
            contact: function () {
              return viewObject;
            }
          }
        },
        modalResultHandler);
    }

    function showModalCreateUser(viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'contact/views/contact.user.add.edit.modal.tpl.html',
          controller: 'ContactUserCtrl',
          controllerAs: 'contactUserCtrl',
          size: 'lg',
          resolve: {
            contact: function () {
              return viewObject;
            },
            customer: function () {
              return vm.customer;
            }
          }
        },
        modalResultHandler);
    }

    function modalResultHandler() {
      // Reload the list data
      vm.reloadListData();
      vm.reloadList = false;
      $scope.$emit('reloadCustomerContactsEmit');
    }

    function isUserDefined(contact) {
      return angular.isDefined(contact.contact.user) && contact.contact.user.id !== '';
    }

    function reloadListData() {
      // Get a specific customer
      CustomerFactory.one(vm.customer.id).get().then(function (newCustomer) {
        vm.customer = newCustomer;
        // reload the customer contact Data
        vm.getCustomerContactData();
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    $scope.$on('reloadCustomerContactsListBroadcast', function (event) {
      if (!event.defaultPrevented) {
        // stop the event
        event.preventDefault();
        LogService.log('Reload LIST CONTACT', 'debug');
        if (vm.reloadList) {
          vm.reloadListData();
        }
      }
    });
  }
}());
