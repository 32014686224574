(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:ContactUserCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('ContactUserCtrl', ContactUserCtrl);

  function ContactUserCtrl(
    contact,
    customer,
    $modalInstance,
    $q,
    $timeout,
    LogService,
    UserFactory,
    UserContextFactory,
    CurrentUserContextFactory,
    RoleFactory,
    SiteFactory,
    ContactFactory,
    SettingsService,
    ToastrNotificationService
  ) {
    var vm = this;
    vm.contact = contact;
    vm.customer = customer;
    vm.user = {};
    vm.roles = [];
    vm.sites = [];

    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.resendActivationMail = resendActivationMail;

    vm.isValid = isValid;
    vm.submit = submit;
    vm.save = save;
    vm.update = update;
    vm.init = init;

    function submit() {
      return (vm.isEdit()) ? vm.update() : vm.save();
    }

    function update() {
      var deferred = $q.defer(),
          updateObject = {
            username: vm.user.username,
            email: vm.user.email,
            plainPassword: vm.user.plainPassword,
            enabled: vm.user.enabled,
            locked: vm.user.locked
          };

      UserFactory.one(vm.user.id).patch(updateObject).then(function () {
        deferred.resolve();
        vm.close();
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    }

    function save() {
      var deferred = $q.defer(),
          object = {
            username: vm.user.username,
            email: vm.user.email,
            enabled: vm.user.enabled,
            locked: vm.user.locked
          };

      UserFactory.post(object).then(function (user) {
        var ContextObject = {
          roles: vm.user.roles,
          site: vm.user.site,
          user: user.id
        };

        UserContextFactory.post(ContextObject).then(function () {
          ContactFactory.one(vm.contact.contact.id).patch({user: user.id}).then(function () {
            deferred.resolve();
            vm.close();
          }, function () {
            deferred.reject();
          });
        }, function () {
          deferred.reject();
        });
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    }

    function init() {
      var params = {
            sort: 'label,ASC',
            limit: 99
          },
          email = '',
          defaultRoleId;

      RoleFactory.getList(params).then(function (results) {
        vm.roles = results;
      });

      SiteFactory.getList(params).then(function (results) {
        vm.sites = results;
      });

      if (vm.isEdit()) {
        UserFactory.one(vm.contact.contact.user.id).get().then(function (result) {
          LogService.log('result' + result, 'debug');
          vm.user = result;
        });
      } else {
        angular.forEach(vm.contact.contact.contactData, function (data) {
          if (data.contactDataType.code === 'EMAIL') {
            email = data.value;
          }
        });

        // I'm sorry for this hack, but without the timeout the ng-disabled on <promise-button>
        // in contact.user.add.edit.modal.tpl won't work (the button will stay disabled)
        $timeout(function () {
          vm.user.username = vm.contact.contact.label.replace(/ /g, '');
          vm.user.site = CurrentUserContextFactory.getSiteId();
          vm.user.email = email;
          vm.user.enabled = true;
          vm.user.locked = false;
        }, 500);

        // if the default role was deleted for some reason, the ui-select will show an empty label
        // so we perform an additional check here
        defaultRoleId = SettingsService.get('user.defaultRoleId', null);
        RoleFactory.checkIfRoleExists(defaultRoleId)
        .then(function (exists) {
          if (exists) {
            vm.user.roles = [];
            vm.user.roles.push(defaultRoleId);
          }
        });
      }
    }

    function resendActivationMail() {
      var id;

      if (!vm.user || !vm.user.enabled || vm.user.passwordSet) {
        return;
      }

      id = vm.user.id;

      UserFactory.one(id).one('resend_activation').get().then(function () {
        ToastrNotificationService.showTranslatedNotification(
          'success',
          'app.activation-mail-sended',
          ''
        );
      }, function (error) {
        LogService.log('User Resend activation error:' + error, 'debug');

        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.error_occured',
          ''
        );
      });
    }

    function isEdit() {
      return angular.isDefined(vm.contact.contact.user) && vm.contact.contact.user.id !== '';
    }
    function isValid() {
      return angular.isDefined(vm.user.username) && vm.user.username !== '' &&
             angular.isDefined(vm.user.email) && vm.user.email !== '';
    }

    function cancelModalInstance() {
      // dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
