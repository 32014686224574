(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.controller:FacilityCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .controller('FacilityTypeCtrl', FacilityTypeCtrl);

  function FacilityTypeCtrl($modal,
                            $scope,
                            $state,
                            $stateParams,
                            FacilityTypeFactory,
                            LogService,
                            UtilService) {
    var vm = this;
    vm.reloadPageData = reloadPageData;
    vm.reloadListData = reloadListData;
    vm.showModalView = showModalView;

    // Check the state of the customer page
    if ($state.is('facilitydetail')) {
      // Reload page data
      vm.reloadPageData($stateParams.id);
    } else {
      // Reload List Data
      vm.reloadListData();
      LogService.log('reload List Data', 'debug');
    }

    function reloadPageData(facilityTypeId) {
      // Get facility
      FacilityTypeFactory.one(facilityTypeId).get().then(function (resultFacilityType) {
        vm.currentFacilityType = resultFacilityType;
      });
    }

    function reloadListData() {
      // Get all Facilities
      FacilityTypeFactory.getList({limit: 99}).then(function (resultFacilityTypes) {
        vm.facilityTypes = resultFacilityTypes;
      });
    }

    function showModalView(returnType, type, facilityType) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'facility/views/facility.type.modal.addedit.tpl.html',
              controller: 'FacilityTypeAddEditCtrl',
              controllerAs: 'facilityTypeAddEditCtrl',
              resolve: {
                facilityType: function () {
                  return facilityType;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'facility/views/facility.type.modal.delete.tpl.html',
              controller: 'FacilityTypeDeleteCtrl',
              controllerAs: 'facilityTypeDeleteCtrl',
              resolve: {
                facilityType: function () {
                  return facilityType;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function modalResultHandler(returnValue) {
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'page':
          vm.reloadPageData(vm.currentFacilityType.id);
          break;

        case 'list':
          vm.reloadListData();
          break;

        default:
          break;
      }
    }
  }
}());
