(function () {
  'use strict';

  angular
    .module('documentation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('documentation', {
        url: '/documentation?tab&loadFilters?loadCheckboxState?activeModule',
        templateUrl: 'documentation/views/documentation.tpl.html',
        controller: 'DocumentationCtrl',
        controllerAs: 'documentationCtrl',
        params: {
          currentPage: null
        }
      });
  }
}());
