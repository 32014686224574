(function () {
  'use strict';

  angular
    .module('site')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('site', {
        url: '/site',
        templateUrl: 'site/views/site.tpl.html',
        controller: 'SiteCtrl',
        controllerAs: 'siteCtrl'
      });
  }
}());
