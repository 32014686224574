(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name event.factory:EventParticipantStatusFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('event')
    .factory('EventParticipantStatusFactory', EventParticipantStatusFactory);

  function EventParticipantStatusFactory(
    Restangular,
    _
  ) {
    var statusService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipantStatus'});
    })
    .service('event_participants/statuses');

    return {
      getList: function (params) {
        return statusService.getList(params);
      },
      one: function (fragment) {
        return statusService.one(fragment);
      },
      getStatusByCode: function (code) {
        return new Promise(function (resolve, reject) {
          var status = null,
              params = {
                limit: 99
              };

          code = code.toUpperCase();
          params['filter[]'] = 'code,' + code;
          // journalActions will be cached so this should only cause a delay once
          statusService.getList(params)
          .then(function (eventParticipantStatuses) {
            status = _.find(eventParticipantStatuses, function (item) {
              return item.code === code;
            });

            if (status) {
              resolve(status);
            } else {
              reject('EventParticipantStatusFactory::getStatusByCode() -> Unknown status code ' + code);
            }
          });
        });
      }
    };
  }
}());
