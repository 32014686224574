(function () {
  'use strict';

    /**
     * @ngdoc service
     * @name accountancy.factory:StuckSale
     *
     * @description
     *
     */
    /* @ngInject */
  angular
      .module('accountancy')
      .factory('StuckSalePosSessionFactory', StuckSalePosSessionFactory)
      .factory('StuckSaleAccountancyPeriodsFactory', StuckSaleAccountancyPeriodsFactory)
      .factory('StuckSalesModificationsFactory', StuckSalesModificationsFactory)
      .factory('StuckSalesLoadSaleByReferenceFactory', StuckSalesLoadSaleByReferenceFactory)
      .factory('StuckSalesEditSaleByIdFactory', StuckSalesEditSaleByIdFactory)
      .factory('StuckSalesCompleteSaleByIdFactory', StuckSalesCompleteSaleByIdFactory)
      .factory('StuckSalesDiscardSaleByIdFactory', StuckSalesDiscardSaleByIdFactory)
      .factory('StuckSalesGetSaleModificationsOfASale', StuckSalesGetSaleModificationsOfASale);

  function StuckSalePosSessionFactory(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'stuckSale'});
      })
      .service('points_of_sale/sessions');
  }

  function StuckSaleAccountancyPeriodsFactory(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'stuckSale'});
      })
      .service('accountancy_periods');
  }

  function StuckSalesModificationsFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'salesModifications'});
      })
      .service('sales/modifications/create');

    return {
      post: function (params) {
        return service.post(params);
      }
    };
  }

  function StuckSalesLoadSaleByReferenceFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'sale'});
      })
      .service('sales/modifications/load/');

    return {
      one: function (fragment) {
        return service.one(fragment);
      }
    };
  }

  function StuckSalesEditSaleByIdFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'salesModifications'});
      })
      .service('sales/modifications/edit');

    return {
      one: function (fragment) {
        return service.one(fragment);
      },
      patch: function (params) {
        return service.patch(params);
      }
    };
  }

  function StuckSalesCompleteSaleByIdFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'sale edit'});
      })
      .service('sales/modifications/complete');

    return {
      one: function (fragment) {
        return service.one(fragment);
      },
      patch: function (params) {
        return service.patch(params);
      }
    };
  }

  function StuckSalesDiscardSaleByIdFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'sale edit'});
      })
      .service('sales/modifications/discard');

    return {
      one: function (fragment) {
        return service.one(fragment);
      },
      patch: function (params) {
        return service.patch(params);
      }
    };
  }

  function StuckSalesGetSaleModificationsOfASale(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'sale edit'});
      })
      .service('sale/modifications/history');
  }
}());
