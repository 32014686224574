(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerAccountancyCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerAccountancyCtrl', CustomerAccountancyCtrl);

  function CustomerAccountancyCtrl(
    _,
    $modalInstance,
    $timeout,
    ContactFactory,
    ContactService,
    customer,
    LogService,
    PaymentMethodSiteFactory,
    SettingsService,
    UtilService
  ) {
    var vm = this;
    vm.customer = customer;
    vm.ibanNumber = {id: null, value: null};
    vm.billingContact = null;
    vm.defaults = {
      invoicePaymentDays: 30
    };
    vm.formData = {
      background: {},
      val: {
        externalDebtorNumber: vm.customer.externalDebtorNumber,
        preferredPaymentMethod: vm.customer.preferredPaymentMethod,
        invoicePaymentDays: vm.customer.invoicePaymentDays,
        invoicePreferred: vm.customer.invoicePreferred,
        comments: vm.customer.comments
      },
      changed: {}
    };

    vm.saveContactData = saveContactData;
    vm.cancel = cancelModalInstance;
    vm.save = saveCustomer;
    vm.markChanged = markChanged;
    vm.invoicePreferredChanged = invoicePreferredChanged;
    vm.fillInDefaultValue = fillInDefaultValue;
    vm.checkDefaults = checkDefaults;
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.hideExternalDebtorNumber = SettingsService.get('customers.hideExternalDebtorNumber', false);

    initAccountancyData();

    function initAccountancyData() {
      var i, pm, iban;
      LogService.log('reloadInitAccountactData', 'debug');
      PaymentMethodSiteFactory.getList().then(function (resultMethods) {
        vm.formData.background.preferredPaymentMethod = resultMethods;

        // set cash as the default
        for (i = 0; i < resultMethods.length; ++i) {
          pm = resultMethods[i].paymentMethod;
          if (pm.code === 'CASH') {
            vm.defaults.preferredPaymentMethod = pm.id;
            break;
          }
        }
      })
      .then(function () {
        checkDefaults();
      });

      // find the billing contact and load contact data from it
      vm.billingContact = _.find(vm.customer.customerContacts, function (contact) {
        return contact.customerContactType.code === 'BILLING';
      });
      if (angular.isDefined(vm.billingContact)) {
        LogService.log('billing Contact:' + vm.billingContact, 'debug');
        ContactService.getContact(vm.billingContact.contact.id)
        .then(function (contact) {
          vm.billingContact.contact = contact;
          iban = _.find(contact.contactData, function (contactData) {
            return contactData.contactDataType.code === 'BANK_ACCOUNT_NUMBER';
          });
          if (iban) {
            vm.ibanNumber = iban;
          }
        });
      }
    }

    function saveCustomer() {
      vm.checkDefaults();
      vm.customer.patch(vm.formData.changed).then(function () {
        vm.saveContactData()
        .then(function () {
          $modalInstance.close('reloadPageData');
        });
      });
    }

    function saveContactData() {
      return new Promise(function (resolve) {
        if (UtilService.isNotEmpty(vm.ibanNumber.id)) {
          if (UtilService.isNotEmpty(vm.ibanNumber.value)) {
            ContactFactory.one(vm.billingContact.contact.id).one('data')
            .one(vm.ibanNumber.id)
            .patch({
              iban: vm.ibanNumber.value
            },
            null, {
              'x-entity': 'iban'
            })
            .then(function () {
              resolve();
            });
          } else {
            // value was emptied after backend-fetch (meaning we delete the iban number from the backend)
            ContactFactory.one(vm.billingContact.contact.id).one('data')
            .one(vm.ibanNumber.id)
            .remove()
            .then(function () {
              resolve();
            });
          }
        } else if (UtilService.isNotEmpty(vm.ibanNumber.value)) {
          ContactFactory.one(vm.billingContact.contact.id).one('data')
          .post('bank_account_number', {
            iban: vm.ibanNumber.value
          },
          null, {
            'x-entity': 'iban'
          })
          .then(function () {
            resolve();
          });
        } else {
          resolve();
        }
      });
    }

    function checkDefaults() {
      angular.forEach(vm.formData.val, function (value, key) {
        fillInDefaultValue(key);
      });
    }

    function fillInDefaultValue(field) {
      if (vm.defaults.hasOwnProperty(field) && UtilService.isEmpty(vm.formData.val[field])) {
        $timeout(function () {
          vm.formData.val[field] = vm.defaults[field];
          markChanged(field);
        });
      }
    }

    function markChanged(field) {
      vm.formData.changed[field] = vm.formData.val[field];
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function invoicePreferredChanged() {
      // wipe the invoice payment days if invoices are not preferred
      // (otherwise a hidden field might prevent the form from submitting)
      if (!vm.formData.val.invoicePreferred) {
        vm.formData.val.invoicePaymentDays = '';
      } else if (angular.isUndefined(vm.formData.val.invoicePaymentDays) ||
        vm.formData.val.invoicePaymentDays.length === 0) {
        // fill in default value
        vm.fillInDefaultValue('invoicePaymentDays');
      }
    }
  }
}());
