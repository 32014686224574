(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PaymentMethodSiteFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PaymentMethodSiteFactory', PaymentMethodSiteFactory);

  function PaymentMethodSiteFactory(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'paymentMethod'});
      })
      .service('sales/payments/methods');
  }
}());
