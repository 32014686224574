(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name hwproxy.controller:HwProxyMemberCardDataCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('hwproxy')
    .controller('HwProxyMemberCardDataCtrl', HwProxyMemberCardDataCtrl);

  function HwProxyMemberCardDataCtrl(
    FitnessEventParticipationService,
    $modal,
    $log,
    $scope,
    $filter,
    $modalInstance,
    $q,
    $rootScope,
    $state,
    _,
    ContactFactory,
    CurrentUserContextFactory,
    customer,
    customerContact,
    CustomerFactory,
    CustomerManagementFactory,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    FitnessFactory,
    hwproxy,
    ImageService,
    JournalActionFactory,
    JournalFactory,
    JournalManagementFactory,
    LogService,
    memberCardData,
    moment,
    PermissionsUtilService,
    ProductFactory,
    ProductUtilService,
    SaleFactory,
    SettingsService,
    SwimCourseManagementFactory,
    ToastrNotificationService,
    UtilService,
    hotkeys
  ) {
    var vm = this;
    vm.$state = $state;
    vm.memberCardData = memberCardData;
    vm.customer = customer;
    vm.customerContact = customerContact;
    if (memberCardData !== null) {
      vm.customer = vm.memberCardData.customer;
      vm.customerContact = vm.memberCardData.customerContact || null;
    }
    vm.journals = [];
    vm.eventsList = [];
    vm.swimmingCourses = [];
    vm.fitness = undefined;
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };
    vm.contactHasCredits = undefined;
    vm.contactFitnessCredits = undefined;
    vm.recentSaleItems = [];

    vm.addCustomerToSale = addCustomerToSale;
    vm.addSaleItemToSale = addSaleItemToSale;
    vm.determineCustomerContact = determineCustomerContact;
    vm.selectJournal = selectJournal;
    vm.attendToEvent = attendToEvent;
    vm.changeSwimmingCourseStatusCode = changeSwimmingCourseStatusCode;
    vm.showCustomerContactSelectionModal = showCustomerContactSelectionModal;
    vm.showCustomer = showCustomer;
    vm.cancel = cancelModalInstance;
    vm.getCustomerContact = getCustomerContact;
    vm.showButton = showButton;
    vm.changeStatus = changeStatus;
    vm.dateToSeconds = dateToSeconds;
    vm.loadFitnessCredits = loadFitnessCredits;
    vm.displayJournal = displayJournal;
    vm.confirm = confirm;
    vm.linkCustomerButtonVisible = linkCustomerButtonVisible;
    vm.loadFitnessEvents = loadFitnessEvents;
    vm.loadSwimCourseEvents = loadSwimCourseEvents;
    vm.parseContactData = parseContactData;
    vm.posDetails = null;
    vm.checkIfBirthday = checkIfBirthday;
    vm.isCustomer = isCustomer;
    vm.getCustomerPosDetails = getCustomerPosDetails;
    vm.toggleComment = toggleComment;
    vm.completeRejectedSale = completeRejectedSale;
    vm.showAttendButton = showAttendButton;
    vm.showAbsentButton = showAbsentButton;
    vm.isOrganization = isOrganization;
    vm.showDifferentOriginSiteWarning = showDifferentOriginSiteWarning;
    vm.showCustomerContactEditModal = showCustomerContactEditModal;
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.enableCustomerBuyHistory = SettingsService.get('pos.enableCustomerBuyHistory', false);
    vm.warningOriginalSiteDisabled = SettingsService.get('customer.warningOriginalSiteDisabled', false);
    vm.customerLabel = SettingsService.get('pos.customerLabel', 'klant');
    vm.hidePhoto = SettingsService.get('customers.hidePhoto', false);
    vm.posAddCustomerToSaleOnEnter = SettingsService.get('pos.addCustomerToSaleOnEnter', false);
    vm.multipleConfirm = multipleConfirm;
    vm.linkInvoiceCustomerButtonVisible = linkInvoiceCustomerButtonVisible;
    vm.addCustomerToInvoice = addCustomerToInvoice;
    vm.filterEvents = filterEvents;
    vm.getEventStatusViaCode = getEventStatusViaCode;
    vm.loading = {
      journals: 0,
      swimmingcourses: 0,
      fitness: 0
    };

    vm.filters = {
      startDate: {
        val: new Date(),
        field: 'startsAt',
        cond: 'GTE ',
        format: 'DD/MM/YYYY',
        isOpen: false,
        open: function () {
          vm.filters.startDate.isOpen = true;
        }
      }
    };

    function filterEvents() {
      if (vm.tabs.fitness.active === true && angular.isFunction(vm.tabs.fitness.load)) {
        vm.tabs.fitness.load();
      }
    }

    vm.tabs = {};
    if (PermissionsUtilService.userHasPermission('access journal section')) {
      vm.tabs.journals = {
        label: 'journal.views.journals',
        template: 'hwproxy/views/hwproxy.member_card_data.modal.tab.journals.tpl.html',
        select: null,
        disable: false,
        active: true
      };
    }
    if (PermissionsUtilService.userHasPermission('access swim course section')) {
      vm.tabs.swimmingcourses = {
        label: 'app.swimming_courses',
        template: 'hwproxy/views/hwproxy.member_card_data.modal.tab.swim_courses.tpl.html',
        load: loadSwimCourseEvents,
        disable: vm.customerContact,
        active: angular.isUndefined(vm.tabs.journals)
      };
    }
    if (PermissionsUtilService.userHasPermission('access fitness section')) {
      vm.tabs.fitness = {
        label: 'app.events',
        template: 'hwproxy/views/hwproxy.member_card_data.modal.tab.events.tpl.html',
        load: loadFitnessEvents,
        disable: vm.customentact,
        active: angular.isUndefined(vm.tabs.journals) && angular.isUndefined(vm.tabs.swimmingcourses)
      };
    }
    vm.tabs.contact = {
      label: vm.customerLabel,
      template: 'hwproxy/views/hwproxy.member_card_data.modal.tab.contact.tpl.html',
      disable: false,
      active: (!PermissionsUtilService.userHasPermission('access journal section') &&
        !PermissionsUtilService.userHasPermission('access swim course section') &&
        !PermissionsUtilService.userHasPermission('access fitness section'))
    };

    if (vm.enableCustomerBuyHistory) {
      vm.tabs.rebuy = {
        label: 'app.re_buy',
        template: 'hwproxy/views/hwproxy.member_card_data.modal.tab.rebuy.tpl.html',
        load: loadRecentSales,
        disable: false,
        active: false
      };
    }

    vm.setActiveTab = function (tab) {
      angular.forEach(vm.tabs, function (value, key) {
        value.active = key === tab;
      });
    };
    vm.reloadActiveTab = function () {
      angular.forEach(vm.tabs, function (value) {
        if (value.active === true && angular.isFunction(value.load)) {
          value.load();
        }
      });
    };

    vm.determineCustomerContact();

    function dateToSeconds(date) {
      return Math.round(new Date(date).getTime() / 1000);
    }

    function completeRejectedSale(sale) {
      var journalParams = {
            'filter[]': 'journalItems.saleItem.sale.id,' + sale.id
          },
          suspensionParam = {};

      UtilService.showTranslatedConfirmationModal('app.complete-rejected-sale', 'app.complete-rejected-sale-message', function (returnValue) {
        if (returnValue) {
          SaleFactory.setSaleStatus(sale.id, 'completed')
            .then(function () {
              // ATTENTION: this needs to be done better
              vm.getCustomerPosDetails(vm.customer.id, vm.customerContact.id);
            });

          // Get journal
          JournalFactory.getList(journalParams)
            .then(function (resultJournal) {
              // Get existing suspension
              angular.forEach(resultJournal, function (journal) {
                JournalFactory.one(journal.id).getList('suspensions', {'filter[]': ['endDate,NULL', 'allowAppend,false']})
                  .then(function (resultSuspensions) {
                    suspensionParam.endDate = (new Date()).toISOString().substring(0, 10);

                    angular.forEach(resultSuspensions, function (suspension) {
                      JournalFactory.one(journal.id).one('suspensions').one(suspension.id)
                        .patch(suspensionParam)
                        .then(function () {
                          LogService.log('suspension patched', 'debug');
                        });
                    });
                  });
              });
            });
        }
      });
    }

    function determineCustomerContact() {
      var tmpCustomerContact, i;

      if (!vm.customerContact && vm.memberCardData && vm.memberCardData.customerContactType && vm.memberCardData.customerContactType.id) {
        for (i = 0; i < memberCardData.customerContacts.length; i++) {
          tmpCustomerContact = memberCardData.customerContacts[i];

          if (tmpCustomerContact.customerContactType.id === memberCardData.customerContactType.id) {
            if (vm.customerContact) {
              vm.customerContact = null;

              break;
            }

            vm.customerContact = tmpCustomerContact;
            vm.reloadActiveTab();
          }
        }
      }

      if (!vm.customerContact) {
        if (vm.customer && vm.customer.customerType.code === 'PERSON') {
          CustomerFactory.one(vm.customer.id).get().then(function (resultCustomer) {
            vm.customerContact = resultCustomer.customerContacts.filter(function (cc) {
              return cc.customerContactType.code === 'USER';
            })[0];
            vm.getCustomerContact();
            vm.reloadActiveTab();
          });
        } else {
          vm.showCustomerContactSelectionModal();
        }
      } else {
        vm.getCustomerContact();
      }
    }

    function getCustomerContact() {
      vm.journals = [];

      vm.getCustomerPosDetails(vm.customer.id, vm.customerContact.id);
    }

    function linkCustomerButtonVisible() {
      return UtilService.isNotEmpty(vm.customer) && UtilService.isNotEmpty(vm.customerContact) &&
        ($state.current.controller === 'PosCtrl' || $state.is('accountancy.list.accountancy') || $state.is('accountancy.list.pos')) &&
        !vm.customer.blacklist;
    }

    function linkInvoiceCustomerButtonVisible() {
      return UtilService.isNotEmpty(vm.customer) && UtilService.isNotEmpty(vm.customerContact) &&
        $state.is('accountancy.invoices') &&
        !vm.customer.blacklist;
    }

    function addCustomerToInvoice() {
      $modalInstance.close(vm.customer);
    }

    function loadFitnessCredits() {
      var deferred = $q.defer();
      ContactFactory.one(vm.customerContact.contact.id).one('fitness_credits').customGET().then(function (resultCredits) {
        if (!angular.isArray(resultCredits)) {
          vm.fitness.credits = resultCredits.credits;
          vm.contactHasCredits = true;
        } else {
          vm.contactHasCredits = false;
        }
        vm.isBusy = false;
        deferred.resolve();
      }, function () {
        vm.isBusy = false;
      });
      return deferred.promise;
    }

    function loadRecentSales() {
      var parameters = {
        limit: 99,
        'filter[]': [
          'customer.id,' + vm.customer.id,
          'completedAt,NOT NULL',
          'saleItems.product.productComponents.JournalProductComponent.id,NOT NULL'
        ]
      };
      vm.recentSaleItems = [];
      if (vm.customer) {
        SaleFactory.getList(parameters).then(function (result) {
          result.forEach(function (sale) {
            var saleItemParameters = {
              'filter[]': ['product.productComponents.JournalProductComponent.id,NOT NULL'],
              limit: 99
            };
            SaleFactory.one(sale.id).one('items').get(saleItemParameters).then(function (saleItemList) {
              processSaleItems(saleItemList.results);
            });
          });
        });
      }
    }

    function processSaleItems(saleItems) {
      saleItems.forEach(function (saleItem) {
        if (angular.isDefined(saleItem.parameters.parentSaleItem)) {
          SaleFactory.one(saleItem.sale.id).one('items').get({'filter[]': 'id,' + saleItem.parameters.parentSaleItem}).then(function (saleItemList) {
            processSaleItems(saleItemList.results);
          });
        } else if (vm.recentSaleItems.filter(function (item) {
          return item.id === saleItem.id;
        }).length === 0) {
          // load the product via ProductFactory again to strip price components of other sites
          // and use the versionSearch flag to access older versions
          ProductFactory.one(saleItem.product.id).customGET('', {versionSearch: 1}).then(function (product) {
            saleItem.product = ProductUtilService.getProductDetails(product.data);
            saleItem.calculatedPrice = ProductUtilService.calculateInclPrice(saleItem.product.price.price, saleItem.product.price.vatRate.percentage).inclPrice;
            ProductFactory.one(saleItem.product.uuid).customGET('', {}).then(function (newestProduct) {
              saleItem.newestProduct = ProductUtilService.getProductDetails(newestProduct.data);
              saleItem.newestCalculatedPrice = ProductUtilService.calculateInclPrice(saleItem.newestProduct.price.price, saleItem.newestProduct.price.vatRate.percentage).inclPrice;
              vm.recentSaleItems.push(saleItem);
            });
          });
        }
      });
    }

    function confirm(journal, event) {
      var journalItemForm,
          journalActionParams = {
            limit: 1
          };

      if (!vm.isBusy) {
        vm.isBusy = true;
        LogService.log('consume journal:' + journal, 'debug');

        journalActionParams['filter[]'] = 'code,CONSUMPTION';
        JournalActionFactory.getList(journalActionParams).then(function (journalActions) {
          journalItemForm = {
            journalAction: journalActions[0].id,
            facility: CurrentUserContextFactory.getSiteFacilityId()
          };
          if (angular.isDefined(journal.credits) && journal.credits > 0) {
            journalItemForm.credits = 1;
          } else if (angular.isDefined(journal.credits)) {
            ToastrNotificationService.showNotification('error',
              $filter('uconlyfirst')($filter('translate')('app.entrance-not-allowed')));
            return false;
          }

          JournalFactory.one(journal.id).post('items', journalItemForm).then(function () {
            vm.isBusy = false;
            vm.changeStatus('confirm', event);
          }, function () {
            vm.isBusy = false;
            ToastrNotificationService.showNotification('error',
              $filter('uconlyfirst')($filter('translate')('app.entrance-not-allowed')));
            return false;
          });
        });
      } else {
        return null;
      }
    }

    function showButton(action, event) {
      var isTentative = false,
          isAccepted = false,
          isPending = false,
          participates = angular.isDefined(event.participation.myParticipation),
          attends = angular.isDefined(event.participation.myParticipation) &&
          angular.isDefined(event.participation.myParticipation.eventParticipantStatus.code) &&
          event.participation.myParticipation.eventParticipantStatus.code === 'ATTENDED';
      if (event.participation.myParticipation) {
        isTentative = event.participation.myParticipation.eventParticipantStatus.code === 'TENTATIVE';
        isAccepted = event.participation.myParticipation.eventParticipantStatus.code === 'ACCEPTED';
        isPending = event.participation.myParticipation.eventParticipantStatus.code === 'PENDING';
      }
      switch (action) {
        case 'subscribe':
          if ((!participates || isTentative) && ((vm.fitness.credits > 0 && !isTentative) || (isTentative && event.availablePlaces > 0)) && !attends && vm.now.seconds < vm.dateToSeconds(event.maxConfirmationDate)) {
            return true;
          }
          break;
        case 'confirm':
          if (participates &&
            (isAccepted || (angular.isDefined(event.participation.myParticipation) && event.participation.myParticipation.reservationsCount > 0 && !isPending)) &&
            vm.now.seconds >= vm.dateToSeconds(event.confirmationDate) &&
            vm.now.seconds < vm.dateToSeconds(event.maxConfirmationDate)
          ) {
            return true;
          }
          break;
        case 'cancel':
          if (participates &&
            (isAccepted || isTentative) &&
            vm.now.seconds < vm.dateToSeconds(event.confirmationDate)
          ) {
            return true;
          }
          break;
        case 'cancel-waitinglist':
          if (participates &&
            isPending
          ) {
            return true;
          }
          break;
        default:
      }
      return false;
    }

    function changeStatus(status, event) {
      if (!vm.isBusy) {
        vm.isBusy = true;
        switch (status) {
          case 'confirm':
            return event.participation.confirm().then(function () {
              vm.loadFitnessCredits();
              LogService.log('Kiosk: event participation confirmed', 'debug');
            }, function () {
              vm.isBusy = false;
              LogService.log('Kiosk: event participation confirmation failed', 'debug');
            });
          case 'multipleConfirm':
            return event.participation.multipleConfirm().then(function () {
              vm.loadFitnessCredits();
              vm.tabs.fitness.load();
              LogService.log('Kiosk: event participation confirmed', 'debug');
            }, function () {
              vm.isBusy = false;
              LogService.log('Kiosk: event participation confirmation failed', 'debug');
            });
          case 'cancel':
          case 'cancel-waitinglist':
            return event.participation.cancel().then(function () {
              vm.loadFitnessCredits();
              vm.loadFitnessEvents();
              LogService.log('Kiosk: event participation cancelled', 'debug');
            }, function () {
              vm.isBusy = false;
              LogService.log('Kiosk: event participation cancel failed', 'debug');
            });
          case 'subscribe':
            if (checkInstaceForMultipleSubscribing(event)) {
              return multipleSubscribe(event);
            }
            return event.participation.subscribe().then(function () {
              vm.loadFitnessCredits();
              LogService.log('Kiosk: event participation subscription', 'debug');
            }, function () {
              vm.isBusy = false;
              LogService.log('Kiosk: event participation subscription failed', 'debug');
            });
          default:
            vm.isBusy = false;
            break;
        }
      }
      return false;
    }

    function checkInstaceForMultipleSubscribing(eventInstance) {
      var result = false;
      angular.forEach(vm.journals, function (journal) {
        if (journal.journalType.multipleConsumptions) {
          eventInstance.eligibleJournalTypeIds.forEach(function (element) {
            if (journal.journalType.id === element && journal.journalType.multipleConsumptions === true) {
              result = true;
            }
          });
        }
      });
      return result;
    }

    function multipleSubscribe(event) {
      var i = 0, promicesOfSubscribes = [], creditsFromJournals = 0;
      if (vm.multipleSubscribeActionIsOpen) {
        return;
      }
      vm.multipleSubscribeInstance = $modal.open({
        templateUrl: 'hwproxy/views/multiple_subscribe.modal.tpl.html',
        controller: 'MultipleSubscribeCtrl',
        controllerAs: 'multipleSubscribeCtrl'
      });

      angular.forEach(vm.journals, function (journal) {
        event.eligibleJournalTypeIds.forEach(function (element) {
          if (journal.journalType.id === element) {
            creditsFromJournals += angular.isDefined(journal.credits) ? journal.credits : 0;
          }
        });
      });

      //if there is more credits than available places, then show the amount of available places
      creditsFromJournals = creditsFromJournals > event.availablePlaces ? event.availablePlaces : creditsFromJournals;
      vm.multipleSubscribeInstance.availableCredits = (creditsFromJournals > 0 && creditsFromJournals < vm.fitness.credits) ? creditsFromJournals : vm.fitness.credits;
      vm.multipleSubscribeActionIsOpen = true;
      vm.multipleSubscribeInstance.result.then(function (returnValue) {
        for (i = 0; i < vm.multipleSubscribeInstance.count; i++) {
          console.log('suscribe -> ' + i);
          promicesOfSubscribes.push(new Promise(function (res) {
            return res(event.participation.subscribe());
          }));
        }
        Promise.all(promicesOfSubscribes).then(function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          vm.loadFitnessEvents();
          return vm.loadFitnessCredits();
        }, function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          vm.loadFitnessEvents();
          return vm.loadFitnessCredits();
        });
        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      });
      vm.loadFitnessEvents();
      return vm.loadFitnessCredits();
    }

    function toggleComment(comment) {
      var patchObject = {};

      if (comment.important) {
        _.pull(comment.tags, 'important');
      } else {
        comment.tags.push('important');
      }

      patchObject.tags = comment.tags;

      ContactFactory.one(vm.customerContact.contact.id).one('data').one(comment.id)
        .patch(patchObject)
        .then(function () {
          LogService.log('comment toggled', 'debug');
        });
      comment.important = !comment.important;
      return comment;
    }

    function displayJournal(eventInstance, journal) {
      return eventInstance.eligibleJournalTypeIds.indexOf(journal.journalType.id) > -1;
    }

    function loadFitnessEvents() {
      var startDate = vm.filters.startDate.val.toISOString().substr(0, 10);
      if (!vm.customer || !vm.customerContact) {
        return;
      }

      vm.loading.fitness = 1;

      FitnessFactory.loadCompleteFitnessData(vm.customer, vm.customerContact, false, startDate)
        .then(function (fitnessData) {
          LogService.log('HwProxyMemberCardDataCtrl::loadFitnessEvents() -> FitnessData:' + fitnessData, 'debug');
          vm.loading.fitness = 0;
          vm.fitness = fitnessData;

          if (vm.fitness.credits > 0) {
            vm.contactHasCredits = true;
          }
        });
    }

    function loadSwimCourseEvents() {
      var journalTypeIds = [];

      if (!vm.customer || !vm.customerContact) {
        return;
      }

      vm.loading.swimmingcourses = 1;

      // collect journal types of valid journals
      angular.forEach(vm.journals, function (journal) {
        if (journal.valid && journalTypeIds.indexOf(journal.journalType.id) === -1) {
          journalTypeIds.push(journal.journalType.id);
        }
      });

      vm.swimmingCourses = [];
      SwimCourseManagementFactory.getAttendableSwimCourseSessions(vm.customerContact.contact.id, vm.customer.id, journalTypeIds, true, false, true)
        .then(function (attendableSessions) {
          LogService.log('Got these attendable sessions:' + attendableSessions, 'debug');
          angular.forEach(attendableSessions.results, function (attendableSession) {
            // this is just some post-processing so that I don't have to change the names of these
            // variables in the rest of the frontend-code (time doesn't permit it)
            // see about eliminating this step later
            vm.swimmingCourses.push({
              journal: attendableSession.journal,
              set: attendableSession.set,
              serie: attendableSession.series,
              group: attendableSession.seriesGroup,
              session: attendableSession.session,
              subscription: attendableSession.subscription,
              participant: attendableSession.participant
            });
          });

          vm.loading.swimmingcourses = 0;
        });
    }

    function showCustomerContactSelectionModal() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.customers.list.modal.tpl.html',
        controller: 'PosCustomerListCtrl',
        controllerAs: 'posCustomerListCtrl',
        size: 'lg',
        resolve: {
          customerId: function () {
            return vm.customer && vm.memberCardData ? vm.customer.id : null;
          },
          customerContactTypeId: function () {
            if (vm.memberCardData) {
              return vm.memberCardData.customerContactType ? vm.memberCardData.customerContactType.id : null;
            }
            return null;
          }
        }
      }, function (returnValue) {
        LogService.log('Customer contact selection modal returned' + returnValue, 'debug');
        vm.customer = returnValue.customer;
        vm.journals = [];
        vm.getCustomerPosDetails(returnValue.customer.id, returnValue.contact.id);
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal Dismissed => reason:' + returnValue, 'debug');
        vm.cancel();
      }, true);
    }

    function getCustomerPosDetails(customerId, contactId) {
      var billingContact;
      LogService.log('Preparing to fetch pos details for this customer.', 'debug');
      Promise.all([
        CustomerManagementFactory.getCustomerDetails(customerId, contactId, ['journals', 'fixedJournals', 'accountancy']),
        CustomerFactory.getList({'filter[]': 'id,' + customerId})
      ]).then(function (result) {
        var posDetails = result[0],
            customerLocal = result[1].shift();

        LogService.log('HwProxyMemberCardDataCtrl::getCustomerPosDetails() -> posDetails:' + posDetails, 'debug');
        vm.customer = customerLocal;
        vm.customerContact = posDetails.customerContact;
        ContactFactory.one(vm.customerContact.contact.id).get().then(function (contactDetails) {
          vm.customer.completeContact = contactDetails;
          vm.customerContact.completeContact = contactDetails;
        });

        billingContact = vm.customer.customerContacts.filter(function (cc) {
          return cc.customerContactType.code === 'BILLING';
        });
        if (angular.isDefined(billingContact[0])) {
          vm.customer.billingCustomerContact = billingContact[0];
        }
        vm.reloadActiveTab();
        // find the photo urls
        if (angular.isDefined(vm.customerContact.contact.contactPhotos) && vm.customerContact.contact.contactPhotos.length) {
          ImageService.getImageFromBackend(vm.customerContact.contact.contactPhotos[0].photo.id).then(function (photo) {
            vm.customerContact.photo = photo;
            vm.customerContact.hasPhoto = true;
          });
        }

        vm.journals = posDetails.activeJournals;

        if (posDetails.fixedViewJournals) {
          //to avoid duplicates, check if there already is object with same id before pushing it to the array
          posDetails.fixedViewJournals.forEach(function (fixedJournal) {
            if (vm.journals.filter(function (e) {
              return e.id === fixedJournal.id;
            }).length === 0) {
              vm.journals.push(fixedJournal);
            }
          });
        }

        vm.posDetails = posDetails;
        vm.loading.journals = 0;

        vm.parseContactData();
        $rootScope.$apply();
      }, function () {
        LogService.log('HwProxyMemberCardDataCtrl::getCustomerPosDetails() -> error fetching posDetails', 'debug');
      });
    }

    function selectJournal(journal, number, noReceipt) {
      var journalItemForm, type;

      if (angular.isDefined(journal.selectNumber)) {
        number = journal.selectNumber;
      }
      if (angular.isUndefined(number)) {
        number = 1;
      }

      if ((journal.credits && journal.credits > 0) || angular.isUndefined(journal.credits)) {
        journalItemForm = {
          facility: CurrentUserContextFactory.getSiteFacilityId()
        };

        if (angular.isDefined(journal.credits) && journal.credits > 0 && journal.credits >= number) {
          journalItemForm.credits = number;
          type = 'credit';
        } else {
          LogService.log('check if not journal. Show error', 'debug');
          if (angular.isUndefined(journal.credits)) {
            type = 'journal';
          } else {
            ToastrNotificationService.showTranslatedNotification(
              'error',
              'app.entrance-not-allowed',
              ''
            );
            return false;
          }
        }

        JournalManagementFactory.postJournalItem(journal.id, 'consumption', journalItemForm)
          .then(function (journalItem) {
            LogService.log('Member Card Modal: Added item to journal ' + journal.id + ' => journalItem: ' + journalItem, 'debug');
            ToastrNotificationService.showTranslatedNotification(
              'success',
              'app.entrance-allowed',
              'app.consumption-registered'
            );
            if (angular.isUndefined(noReceipt)) {
              hwproxy.sendJournalConsumptionInfoToPrinter(vm.customerContact, journal, type, number);
            }
            $rootScope.$broadcast('refreshJournals');
          }, function () {
            LogService.log('Member Card Modal: Failed to add item to journal ' + journal.id, 'warn');
          });
        vm.cancel();
      } else {
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.entrance-not-allowed',
          ''
        );
      }
    }

    function showCustomer() {
      $state.go('customerdetail', {
        id: vm.customer.id
      });

      vm.cancel();
    }

    function addCustomerToSale(product) {
      if (!vm.customer) {
        return;
      }
      LogService.log('sended customer:' + vm.customer, 'debug');
      LogService.log('sended customerContact:' + vm.customerContact, 'debug');
      $rootScope.$broadcast('pos.setSaleCustomer', {
        customer: vm.customer,
        contact: vm.customerContact,
        product: product
      });

      vm.cancel();
    }

    function addSaleItemToSale(product) {
      addCustomerToSale(product);
    }

    function cancelModalInstance() {
      // Dismiss the modal instance
      $modalInstance.dismiss('cancel');
    }

    function attendToEvent(participant, journal, type) {
      var participantObject = {};
      EventParticipantStatusFactory.getList({limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
        participantObject.eventParticipantStatus = $filter('filter')(resultStatuses, function (ps) {
          return ps.code === 'ATTENDED';
        })[0].id;
        // Set Rejected
        EventParticipantFactory.one(participant.id).patch(participantObject)
          .then(function () {
            // print ticket of event
            hwproxy.sendEventAttendanceInfoToPrinter(participant, journal, type);
            vm.selectJournal(journal);
          });
      });
    }

    function isCustomer() {
      return vm.customer !== null;
    }

    function parseContactData() {
      var commentIds = [];
      LogService.log('parseContactData', 'debug');
      vm.contactMobileNumbers = [];
      vm.contactPhoneNumbers = [];
      vm.contactFaxNumbers = [];
      vm.contactWebsites = [];
      vm.contactEmails = [];
      vm.contactComments = [];
      vm.contactSocialSecurityNumbers = [];
      vm.contactBirthDates = [];
      vm.contactCompanyNumbers = [];
      vm.contactGenders = [];
      vm.contactVATNumbers = [];
      vm.bankAccountNumbers = [];
      vm.isBirthday = false;
      // Loop the contact data and add them to a list depending on the Data Type
      angular.forEach(vm.isCustomer() ? vm.customerContact.contact.contactData : vm.customerContact.contactData, function (contactData) {
        contactData.important = angular.isDefined(contactData.tags) && contactData.tags.includes('important');

        switch (contactData.contactDataType.code) {
          case 'WEBSITE':
            vm.contactWebsites.push(contactData);
            break;

          case 'PHONE_NUMBER':
            vm.contactPhoneNumbers.push(contactData);
            break;

          case 'MOBILE_NUMBER':
            vm.contactMobileNumbers.push(contactData);
            break;

          case 'FAX_NUMBER':
            vm.contactFaxNumbers.push(contactData);
            break;

          case 'COMMENT':
            vm.contactComments.push(contactData);
            break;

          case 'EMAIL':
            vm.contactEmails.push(contactData);
            break;

          case 'SOCIAL_SECURITY_NUMBER':
            vm.contactSocialSecurityNumbers.push(contactData);
            break;

          case 'VAT_NUMBER':
            vm.contactVATNumbers.push(contactData);
            break;

          case 'COMPANY_NUMBER':
            vm.contactCompanyNumbers.push(contactData);
            break;

          case 'BIRTH_DATE':
            contactData.today = moment(contactData.value).format('DD-MM') === moment().format('DD-MM');
            vm.contactBirthDates.push(contactData);
            break;

          case 'GENDER':
            vm.contactGenders.push(contactData);
            break;

          case 'BANK_ACCOUNT_NUMBER':
            vm.bankAccountNumbers.push(contactData);
            break;

          default:
            LogService.log('contact data type not found', 'error');
        }
      });

      if (angular.isDefined(vm.customer.billingCustomerContact)) {
        angular.forEach(vm.customer.billingCustomerContact.contact.contactData, function (billingContactData) {
          billingContactData.important = angular.isDefined(billingContactData.tags) && billingContactData.tags.includes('important');
          switch (billingContactData.contactDataType.code) {
            case 'COMMENT':
              commentIds = vm.contactComments.map(function (v) {
                return v.id;
              });
              if (commentIds.indexOf(billingContactData.id) === -1) {
                vm.contactComments.push(billingContactData);
              }
              break;
            default:
              break;
          }
        });
      }

      vm.checkIfBirthday();
    }

    function checkIfBirthday() {
      var bi;

      vm.isBirthday = false;
      for (bi in vm.contactBirthDates) {
        if (vm.contactBirthDates[bi].today === true) {
          vm.isBirthday = true;
          break;
        }
      }
    }

    function changeSwimmingCourseStatusCode(swimmingCourse, statuscode) {
      var type = '',
          participantId = null,
          isAttending = false;

      // set isAttending to true by default
      if (statuscode === 'attended') {
        isAttending = true;
      }

      // fill in id (used to determine post/patch)
      if (swimmingCourse.hasOwnProperty('participant') && UtilService.isNotEmpty(swimmingCourse.participant)) {
        participantId = swimmingCourse.participant.id;
      }

      // determine operation type (used to print ticket)
      if (swimmingCourse.journal.hasOwnProperty('credits')) {
        type = 'credit';
      } else {
        type = 'journal';
      }

      // note attendance and print/reload
      SwimCourseManagementFactory.noteSessionAttendanceByStatusCode(
        swimmingCourse.set.id,
        swimmingCourse.serie.id,
        swimmingCourse.session.id,
        swimmingCourse.subscription.id,
        swimmingCourse.subscription.swimCourseSeriesGroup.id,
        statuscode,
        participantId
      )
        .then(function () {
          // print ticket of event if guy is attending
          if (isAttending) {
            hwproxy.sendSwimmingSchoolAttendanceInfoToPrinter(vm.customerContact.contact, swimmingCourse, type);
            vm.selectJournal(swimmingCourse.journal, 1, true);
          }
          vm.loadSwimCourseEvents();
        });
    }

    function showAttendButton(swimmingCourse) {
      var today,
          now,
          startsAt;

      today = moment(swimmingCourse.session.startsAt).startOf('day');
      now = moment();
      startsAt = moment(swimmingCourse.session.startsAt).add(15, 'minutes');

      return swimmingCourse.journal.valid &&
        !(swimmingCourse.participant && swimmingCourse.participant.swimCourseParticipantStatus.code === 'ATTENDED') &&
        today < now &&
        startsAt > now;
    }

    function showAbsentButton(swimmingCourse) {
      var now,
          startsAt;

      now = moment();
      startsAt = moment(swimmingCourse.session.startsAt).add(15, 'minutes');

      return swimmingCourse.journal.valid &&
        !(swimmingCourse.participant && swimmingCourse.participant.swimCourseParticipantStatus.code === 'ATTENDED') &&
        !(swimmingCourse.participant && swimmingCourse.participant.swimCourseParticipantStatus.code === 'ABSENT') &&
        startsAt > now;
    }

    function isOrganization() {
      if (UtilService.isNotEmpty(vm.customer)) {
        return UtilService.isNotEmpty(vm.customer.customerType) &&
          vm.customer.customerType.code !== 'PERSON' &&
          vm.customer.customerType.code !== 'FAMILY';
      }
    }

    function showCustomerContactEditModal() {
      UtilService.showModal({
        templateUrl: 'contact/views/contact.modal.addedit.tpl.html',
        controller: 'ContactAddEditCtrl',
        controllerAs: 'contactAddEditCtrl',
        size: 'lg',
        resolve: {
          customer: function () {
            return vm.customer;
          },
          customerContact: function () {
            return vm.customerContact;
          }
        }
      }, function () {
        vm.getCustomerContact();
      }, function () {
        vm.reloadActiveTab();
      }, true);
    }

    function showDifferentOriginSiteWarning() {
      var cust = this.customer;
      if (angular.isUndefined(cust) && angular.isDefined(memberCardData)) {
        cust = memberCardData.customer;
      }
      return (CurrentUserContextFactory.getSiteId() && cust && cust.originSite &&
        CurrentUserContextFactory.getSiteId() !== cust.originSite.id);
    }

    function multipleConfirm(journal, event, reservation) {
      var journalItemForm,
          journalActionParams = {
            limit: 1
          };
      reservation = angular.isDefined(reservation) ? reservation : 1;
      if (!vm.isBusy) {
        vm.isBusy = true;
        LogService.log('consume journal:' + journal, 'debug');

        journalActionParams['filter[]'] = 'code,CONSUMPTION';
        JournalActionFactory.getList(journalActionParams).then(function (journalActions) {
          journalItemForm = {
            journalAction: journalActions[0].id,
            facility: CurrentUserContextFactory.getSiteFacilityId()
          };
          if (angular.isDefined(journal.credits) && journal.credits > 0) {
            journalItemForm.credits = 1;
          } else if (angular.isDefined(journal.credits)) {
            ToastrNotificationService.showNotification('error',
              $filter('uconlyfirst')($filter('translate')('app.entrance-not-allowed')));
            return false;
          }
          if (event.participation.myParticipation.eventParticipantStatus.code === 'PENDING') {
            ToastrNotificationService.showNotification('info',
              $filter('uconlyfirst')('you cannot confirm because you are in waiting list'));
            vm.isBusy = false;
            return null;
          }
          addJournalItemsAndUpdateStatus(journal, journalItemForm, event, reservation);
        });
      } else {
        return null;
      }
    }

    function addJournalItemsAndUpdateStatus(journal, journalItemForm, event, reservation) {
      if (reservation > 0) {
        reservation -= 1;
        return JournalFactory.one(journal.id).post('items', journalItemForm).then(function () {
          vm.isBusy = false;
          vm.changeStatus('multipleConfirm', event);
          addJournalItemsAndUpdateStatus(journal, journalItemForm, event, reservation);
        },
        function () {
          vm.isBusy = false;
          ToastrNotificationService.showNotification('error',
            $filter('uconlyfirst')($filter('translate')('app.entrance-not-allowed')));
        });
      }
    }

    function getEventStatusViaCode(code) {
      if (code === 'ACCEPTED') {
        return 'kiosk.subscribed';
      }
      if (code === 'PENDING') {
        return 'kiosk.waiting_list';
      }
      return 'kiosk.places_unavailable';
    }

    hotkeys.bindTo($scope)
    .add({
      combo: 'enter',
      description: 'Consume one turn on membershipcard (if only 1 journal is valid or present and credits are available)',
      callback: function () {
        // only use enter when there is one journal present and credits are present
        if (vm.journals.length === 1 && (vm.journals[0].credits > 0 || vm.journals[0].journalType.multipleConsumptions) && vm.journals[0].valid) {
          vm.selectJournal(vm.journals[0], 1);
        } else if (vm.posAddCustomerToSaleOnEnter) {
          vm.addCustomerToSale();
        }
      }
    });
  }
}());
