(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:ParticipantStatusFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SwimCourseParticipantStatusFactory', SwimCourseParticipantStatusFactory);

  function SwimCourseParticipantStatusFactory(
    Restangular,
    _
  ) {
    var statusService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseParticipantStatus'});
    })
    .service('swim_courses/participants/statuses');

    return {
      getList: function (params) {
        return statusService.getList(params);
      },
      one: function (fragment) {
        return statusService.one(fragment);
      },
      getStatusByCode: function (code) {
        return new Promise(function (resolve, reject) {
          var status = null,
              params = {
                limit: 99
              };

          code = code.toUpperCase();
          params['filter[]'] = 'code,' + code;
          // journalActions will be cached so this should only cause a delay once
          statusService.getList(params)
          .then(function (swimCourseParticipantStatuses) {
            status = _.find(swimCourseParticipantStatuses, function (item) {
              return item.code === code;
            });

            if (status) {
              resolve(status);
            } else {
              reject('SwimCourseParticipantStatusFactory::getStatusByCode() -> Unknown status code ' + code);
            }
          });
        });
      }
    };
  }
}());
