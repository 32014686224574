(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosScreenFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PosScreenFactory', PosScreenFactory);

  function PosScreenFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'posScreen'});
    })
    .service('points_of_sale/screens');
  }
}());
