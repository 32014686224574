(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerAddPosCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerAddPosCtrl', CustomerAddPosCtrl);

  function CustomerAddPosCtrl(
      ContactFactory,
      ContactTypeFactory,
      ContactDataContextFactory,
      ContactDataFactory,
      ContactDataTypeFactory,
      ContactLocationFactory,
      CustomerContactTypeFactory,
      CustomerFactory,
      CustomerTypeFactory,
      MemberCardFactory,
      MemberCardTypeFactory,
      ContactService,
      SettingsService,
      ToastrNotificationService,
      UtilService,
      Restangular,
      billTotal,
      $filter,
      $scope,
      $modalInstance,
      LogService
    ) {
    var vm = this,
        param = {limit: 99};

    vm.billTotal = billTotal;
    vm.datePickerIsOpened = false;
    vm.newCardDatePickerIsOpened = false;
    vm.existingCardDatePickerIsOpened = false;
    vm.genders = [
      {
        letter: 'M',
        label: $filter('uconlyfirst')($filter('translate')('contact.male'))
      }, {
        letter: 'F',
        label: $filter('uconlyfirst')($filter('translate')('contact.female'))
      }
    ];
    vm.organizationName = null;
    vm.customerTypes = null;
    vm.customerLabel = null;
    vm.customerFirstName = null;
    vm.customerLastName = null;
    vm.customerMemberCards = [];
    vm.addMemberCard = addMemberCard;
    vm.removeMemberCard = removeMemberCard;
    vm.createContactData = createContactData;
    vm.contactLocation = {
      contactDataContext: null,
      location: {
        countryCode: null,
        street: '',
        number: '',
        box: '',
        postalCode: '',
        city: ''
      },
      isNew: true
    };
    vm.companyNumber = vm.createContactData('COMPANY_NUMBER');
    vm.vatNumber = vm.createContactData('VAT_NUMBER');
    vm.contactEmail = vm.createContactData('EMAIL');
    vm.contactMobile = vm.createContactData('MOBILE_NUMBER');
    vm.contactBirthDate = vm.createContactData('BIRTH_DATE');
    vm.contactSocialNumber = vm.createContactData('SOCIAL_SECURITY_NUMBER');
    vm.contactGender = vm.createContactData('GENDER');
    vm.bankAccount = vm.createContactData('BANK_ACCOUNT_NUMBER');
    vm.noCompanyNumber = false;
    vm.noSocialSecurityNumber = false;
    vm.customerTypeValue = null;
    vm.cancel = cancelModalInstance;
    vm.saveContact = saveContact;
    vm.saveCustomer = saveCustomer;
    vm.saveCustomerAfterCheck = saveCustomerAfterCheck;
    vm.isOrganization = isOrganization;
    vm.checkCustomer = checkCustomer;
    vm.newMemberCardlabel = '';
    vm.newMemberCardType = '';
    vm.sendContactDataToBackend = sendContactDataToBackend;
    vm.saveLocation = saveLocation;
    vm.openDatePicker = openDatePicker;
    vm.openNewCardDatePicker = openNewCardDatePicker;
    vm.openExistingCardDatePicker = openExistingCardDatePicker;
    vm.clearConditionalInputs = clearConditionalInputs;
    vm.activeTab = 'details';
    vm.approvalFlowVersion4Enabled = (SettingsService.get('pos.session.approvalFlowVersion') === '4');
    UtilService.setAsync(vm, 'memberCardTypes', [], MemberCardTypeFactory.getList(param));
    UtilService.setAsync(vm, 'contactDataTypes', [], ContactDataTypeFactory.getList(param));
    UtilService.setAsync(vm, 'countries', [], Restangular.all('countries').customGET(''), UtilService.getter('results'));

    // Get all Contact Data Contexts
    ContactDataContextFactory.getList(param).then(function (resultContexts) {
      function isHome(cdc) {
        return cdc.code === 'HOME';
      }
      vm.contactContexts = resultContexts;
      vm.contactLocation.contactDataContext = vm.contactContexts.filter(isHome)[0];
      vm.contactMobile.contactDataContext = vm.contactContexts.filter(isHome)[0];
      vm.contactEmail.contactDataContext = vm.contactContexts.filter(isHome)[0];
    });

    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.customerHideOptionalText = SettingsService.get('customer.hideOptionalText', false);
    vm.posCustomerLabel = SettingsService.get('pos.customerLabel', 'klant');

    Promise.all([
      CustomerTypeFactory.getList(param),
      CustomerContactTypeFactory.getList(param),
      ContactTypeFactory.getList(param)
    ]).then(function (result) {
      var customerTypes = result[0],
          customerContactTypes = result[1],
          contactTypes = result[2];

      if (vm.useAlternateCustomerForm) {
        vm.customerTypes = customerTypes.filter(function (x) {
          return x.code === 'PERSON' || x.code === 'COMPANY';
        });
        vm.customerTypeValue = vm.customerTypes[0];
      } else {
        vm.customerTypes = customerTypes;
        vm.customerTypeValue = customerTypes[0];
      }

      // Get the Customer Contact 'Billing' Type to add to the new automatically added customer contact
      vm.customerContactBillingType = customerContactTypes.find(function (cct) {
        return cct.code === 'BILLING';
      });
      // Get the Customer Contact 'User' Type to add to the new automatically added customer contact
      vm.customerContactUserType = customerContactTypes.find(function (cct) {
        return cct.code === 'USER';
      });

      // Get the 'person' contact Type to add to the new automatically added contact
      vm.personContactType = contactTypes.find(function (ct) {
        return ct.code === 'PERSON';
      });
      // Get the 'person' contact Type to add to the new automatically added contact
      vm.organizationContactType = contactTypes.find(function (ct) {
        return ct.code === 'ORGANIZATION';
      });
    }, function (errorMsg) {
      console.error('error: ' + errorMsg);
    });

    function findCardByNumber(number) {
      var result = Object.values(vm.customerMemberCards).find(function (card) {
        return card.label === number;
      });
      return angular.isUndefined(result) ? -1 : result.id;
    }

    function findCardTypeId(type) {
      var result = Object.values(vm.memberCardTypes).find(function (card) {
        return card.code.toLowerCase() === type.toLowerCase();
      });
      return angular.isUndefined(result) ? null : result.id;
    }

    function isCardKnown(number) {
      return findCardByNumber(number) !== -1;
    }

    // close modal if a known card gets scanned
    $scope.$on('hwproxy.card_reader.known_card', function () {
      vm.cancel();
    });

    // respond to unknown cards being read
    $scope.$on('hwproxy.card_reader.unknown_card', function (event, cardData) {
      if (!isCardKnown(cardData.event.number)) {
        // add a new member card object
        vm.customerMemberCards.push({
          label: cardData.event.number,
          customer: '',
          memberCardType: findCardTypeId(cardData.event.type)
        });
      } else {
        LogService.log('this card is already known!', 'debug');
      }
    });

    function removeMemberCard(membercard) {
      var i = vm.customerMemberCards.findIndex(function (card) {
        return card.label === membercard.label;
      });
      if (i !== -1) {
        vm.customerMemberCards.splice(i, 1);
      }
    }

    function addMemberCard() {
      if (vm.newMemberCardlabel.length && vm.newMemberCardType.length) {
        vm.customerMemberCards.push({
          label: vm.newMemberCardlabel,
          customer: '',
          memberCardType: vm.newMemberCardType
        });
        vm.newMemberCardlabel = '';
        vm.newMemberCardType = '';
      } else {
        LogService.log('member card data not filled in!', 'debug');
      }
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function isOrganization() {
      return UtilService.isNotEmpty(vm.customerTypeValue) &&
             vm.customerTypeValue.code !== 'PERSON' &&
             vm.customerTypeValue.code !== 'FAMILY';
    }

    function checkCustomer() {
      var address = (!!vm.contactLocation.location.street &&
                     !!vm.contactLocation.location.number &&
                     !!vm.contactLocation.location.postalCode &&
                     !!vm.contactLocation.location.city &&
                     !!vm.contactLocation.location.countryCode),
          kbo = !!vm.companyNumber.value,
          vat = !!vm.vatNumber.value,
          companyName = !!vm.organizationName,
          rr = !!vm.contactSocialNumber.value,
          firstName = !!vm.customerFirstName,
          lastName = !!vm.customerLastName;

      if (vm.isOrganization()) {
        if (!kbo && !vat && !address) {
          return 'pos.error.organization_details';
        }
        if (!companyName && kbo) {
          vm.organizationName = '(KBO: ' + vm.companyNumber.value + ')';
        }
        if (!companyName && vat) {
          vm.organizationName = '(BTW: ' + vm.vatNumber.value + ')';
        }
      } else {
        if (!rr && !address) {
          return 'pos.error.person_details';
        }
        if (rr && !firstName) {
          vm.customerFirstName = '---';
        }
        if (rr && !lastName) {
          vm.customerLastName = '(RR: ' + vm.contactSocialNumber.value + ')';
        }
      }
      // passed
      return null;
    }

    function saveCustomer() {
      var customer = {
            customerType: vm.customerTypeValue.id
          },
          contact = {
            user: null
          },
          checkResult;

      //check with a notification error
      if (vm.useAlternateCustomerForm) {
        checkResult = vm.checkCustomer();
        if (checkResult) {
          ToastrNotificationService.showNotification(
            'error', $filter('uconlyfirst')($filter('translate')(checkResult)));
          return Promise.resolve();
        }
      }

      if (vm.isOrganization()) {
        contact.label = customer.label = vm.organizationName;
        return vm.saveCustomerAfterCheck(customer, contact);
      }

      contact.label = customer.label = vm.customerFirstName + ' ' + vm.customerLastName;
      contact.firstName = vm.customerFirstName;
      contact.lastName = vm.customerLastName;
      //Check if customer first and lastname already exist
      return ContactService.getContactsWithName(vm.customerFirstName, vm.customerLastName).then(function (existingContacts) {
        if (angular.isArray(existingContacts) && existingContacts.length > 0) {
          // Show modal asking if sure to create
          UtilService.showTranslatedConfirmationModal('app.contact-exist', 'app.contact-exist-extended', function (returnValue) {
            if (returnValue) {
              return vm.saveCustomerAfterCheck(customer, contact);
            }
          });
        } else {
          return vm.saveCustomerAfterCheck(customer, contact);
        }
      });
    }

    function saveContact(contact) {
      return ContactFactory.post(contact).then(function (newContact) {
        // Create contact data
        if (UtilService.isNotEmpty(vm.contactEmail.value)) {
          vm.sendContactDataToBackend(newContact.id, vm.contactEmail);
        }
        if (UtilService.isNotEmpty(vm.contactMobile.value)) {
          vm.sendContactDataToBackend(newContact.id, vm.contactMobile);
        }
        if (vm.hasVATNumber && UtilService.isNotEmpty(vm.vatNumber.value)) {
          vm.sendContactDataToBackend(newContact.id, vm.vatNumber);
        }
        if (UtilService.isNotEmpty(vm.bankAccount.value)) {
          vm.sendContactDataToBackend(newContact.id, vm.bankAccount);
        }
        if (UtilService.isNotEmpty(vm.companyNumber.value)) {
          vm.sendContactDataToBackend(newContact.id, vm.companyNumber);
        }
        if (UtilService.isNotEmpty(vm.contactBirthDate)) {
          vm.sendContactDataToBackend(newContact.id, vm.contactBirthDate);
        }
        if (UtilService.isNotEmpty(vm.contactSocialNumber)) {
          vm.sendContactDataToBackend(newContact.id, vm.contactSocialNumber);
        }
        if (UtilService.isNotEmpty(vm.contactGender)) {
          vm.sendContactDataToBackend(newContact.id, vm.contactGender);
        }
        vm.saveLocation(newContact.id);
        return newContact;
      });
    }

    function saveCustomerAfterCheck(customer, contact) {
      if (UtilService.isNotEmpty(vm.invoicePreferred)) {
        customer.invoicePreferred = vm.invoicePreferred;
        customer.invoicePaymentDays = vm.invoicePaymentDays;
      }

      // Create contact details
      if (vm.isOrganization()) {
        contact.contactType = vm.organizationContactType.id;
      } else {
        contact.contactType = vm.personContactType.id;
      }

      // add the member card if applicable
      vm.addMemberCard();

      // Create the customer
      CustomerFactory.post(customer).then(function (newCustomer) {
        // save the member cards
        angular.forEach(vm.customerMemberCards, function (mc) {
          mc.customer = newCustomer.id;
          MemberCardFactory.post(mc);
        });

        // Create a contact with same values as customer
        vm.saveContact(contact).then(function (newContact) {
          var customerBillingContact = {
                contact: newContact.id,
                customerContactType: vm.customerContactBillingType.id
              },
              customerUserContact = {
                contact: newContact.id,
                customerContactType: vm.customerContactUserType.id
              },
              contactsRoute = CustomerFactory.one(newCustomer.id).one('contacts');

          return Promise.all([
            contactsRoute.customPOST(customerBillingContact),
            contactsRoute.customPOST(customerUserContact)
          ]);
        }).then(function (result) {
          // Close the modal view and return the contact data
          $modalInstance.close({customer: newCustomer, contact: result[1]});
        });
      }).catch(function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function sendContactDataToBackend(contactId, object) {
      var contactDC = object.contactDataContext,
          contactData = {
            contactDataContext: angular.isUndefined(contactDC) || angular.isUndefined(contactDC.id) ? null : contactDC.id
          };
      contactData[ContactDataFactory.getFieldName(object.code)] = object.value;

      if (!object.isNew) {
        return;
      }
      if (object.code === 'BIRTH_DATE' && (!object.value || object.value.toISOString().length <= 0)) {
        return;
      }
      if ((object.code === 'SOCIAL_SECURITY_NUMBER' ||
           object.code === 'VAT_NUMBER' ||
           object.code === 'COMPANY_NUMBER' ||
           object.code === 'GENDER') && object.value.length <= 0) {
        return;
      }
      if (object.code === 'BIRTH_DATE') {
        contactData[ContactDataFactory.getFieldName(object.code)] = object.value.toISOString().substr(0, 10);
      }
      if (object.code === 'VAT_NUMBER') {
        contactData.noVatNumberCheck = true;
      }
      // Create contact data
      ContactFactory.one(contactId).one('data').one(object.code.toLowerCase())
        .customPOST(contactData);
    }

    function saveLocation(contactId) {
      var location = {
        street: vm.contactLocation.location.street,
        number: vm.contactLocation.location.number,
        box: vm.contactLocation.location.box,
        postalCode: vm.contactLocation.location.postalCode,
        city: vm.contactLocation.location.city,
        countryCode: vm.contactLocation.location.countryCode
      };

      // Post location to backend
      ContactLocationFactory.post(location).then(function (createdLocation) {
        // Link location to contact
        ContactFactory.one(contactId).one('locations')
        .customPOST({contactDataContext: vm.contactLocation.contactDataContext.id, location: createdLocation.id})
        .then(function () {
        });
      });
    }

    function openNewCardDatePicker() {
      vm.newCardDatePickerIsOpened = true;
    }
    function openExistingCardDatePicker() {
      vm.existingCardDatePickerIsOpened = true;
    }
    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function createContactData(objectDataType) {
      return {
        contactDataContext: {},
        value: '',
        code: objectDataType,
        isNew: true
      };
    }

    function clearConditionalInputs() {
      if (vm.useAlternateCustomerForm) {
        if (vm.noSocialSecurityNumber) {
          vm.contactSocialNumber.value = '';
        }
        if (vm.noCompanyNumber) {
          vm.companyNumber.value = '';
        } else {
          vm.hasVATNumber = '';
        }
        if (!vm.hasVATNumber) {
          vm.vatNumber.value = '';
        }
      }
    }

    vm.selectTab = function (tabId) {
      vm.activeTab = tabId;
    };
  }
}());
