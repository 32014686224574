(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCategoriesCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .controller('ProductCategoriesCtrl', ProductCategoriesCtrl);

  function ProductCategoriesCtrl($modalInstance, $stateParams, LocaleService, ProductCategoryFactory) {
    var vm = this;
    vm.category = [];
    vm.title = 'product.categories.add';
    vm.isEdit = false;
    vm.frenchEnabled = LocaleService.isFrenchEnabled();

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;

    function save() {
      var categoryObj = {
        label: vm.item.label,
        frLabel: vm.item.frLabel
      };
      if (vm.isEdit === true) {
        ProductCategoryFactory.one(vm.item.id).customPUT(categoryObj).then(function () {
          vm.close();
        });
      } else {
        ProductCategoryFactory.post(categoryObj).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      ProductCategoryFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'product.categories.edit';
        vm.isEdit = true;
      }
      if (vm.isEdit === true) {
        ProductCategoryFactory.one($stateParams.id).get().then(function (item) {
          vm.item = item;
        });
      }
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
