(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:SkillFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SkillFactory', SkillFactory);

  function SkillFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseSkill'});
    })
    .service('swim_courses/skills');
  }
}());
