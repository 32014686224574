(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name auth.controller:UserContextSelectCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('auth')
    .controller('UserContextSelectCtrl', UserContextSelectCtrl);

  function UserContextSelectCtrl(
    $modalInstance,
    userContexts
  ) {
    var vm = this;
    vm.cancel = cancel;
    vm.selectUserContext = selectUserContext;
    vm.userContexts = userContexts.sort(compare);
    vm.contextIsActive = contextIsActive;

    function selectUserContext(selectedContext) {
      $modalInstance.close(selectedContext);
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function contextIsActive(userContext) {
      return userContext.active || angular.isUndefined(userContext.active);
    }

    function compare(a, b) {
      if (a.site.label < b.site.label) {
        return -1;
      }
      if (a.site.label > b.site.label) {
        return 1;
      }
      return 0;
    }
  }
}());
