(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosWristbandCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosWristbandCtrl', PosWristbandCtrl);

  function PosWristbandCtrl(
    FacilityTypeFactory,
    ToastrNotificationService,
    $filter,
    $modalInstance,
    Restangular,
    $rootScope,
    hwproxy,
    UtilService,
    LogService
  ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.wristbandId = null;
    vm.checkWristband = checkWristband;
    vm.searchFinished = false;
    vm.accessControlIdentification = null;
    vm.accessControlIdentificationHistory = [];
    vm.resetWristband = resetWristband;
    vm.showWristbandHistory = showWristbandHistory;
    vm.showAssignWristbandModalForm = showAssignWristbandModalForm;
    vm.historySearchFinished = false;
    vm.historySearchStarted = false;
    vm.showSaleModal = showSaleModal;
    vm.iceSkateRental = iceSkateRental;

    $rootScope.$on(hwproxy.options.cardReader.unknownCardEventName, function (event, data) {
      vm.wristbandId = data.event.number;
      vm.checkWristband(data.event.number);
    });

    function checkWristband(wristbandId) {
      var accessControlParams = {
        limit: 1
      };

      if (angular.isUndefined(wristbandId)) {
        wristbandId = vm.wristbandId;
      }

      if (wristbandId) {
        accessControlParams['filter[]'] = [
          'identificationNumber,' + vm.wristbandId
        ];
        Restangular.one('access_control_identifications').customGET('', accessControlParams).then(function (results) {
          vm.accessControlIdentification = results.count ? results.results[0] : null;
          vm.searchFinished = true;
        });
      }
    }

    function resetWristband() {
      Restangular.one('access_control_identification').one(vm.accessControlIdentification.id).remove().then(function () {
        vm.searchFinished = false;
        vm.checkWristband();
      });
    }

    function showWristbandHistory() {
      var accessControlHistoryParams = {
        limit: 5
      };

      vm.historySearchStarted = true;
      if (vm.wristbandId) {
        accessControlHistoryParams['filter[]'] = [
          'identificationNumber,' + vm.wristbandId
        ];
        Restangular.one('access_control_identifications_history').customGET('', accessControlHistoryParams).then(function (results) {
          if (results.count) {
            vm.accessControlIdentificationHistory = results.results;
          }
          vm.historySearchFinished = true;
          vm.historySearchStarted = false;
        });
      }
    }

    function showAssignWristbandModalForm() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.assign.wristband.modal.tpl.html',
        controller: 'PosAssignWristbandCtrl',
        controllerAs: 'posAssignWristbandCtrl',
        size: 'lg',
        resolve: {
          saleId: function () {
            return vm.saleId;
          },
          wristbandId: function () {
            return vm.wristbandId;
          }
        }
      }, function () {
        vm.searchFinished = false;
        vm.checkWristband();
      }, null, true);
    }

    function showSaleModal(accessControlIdentification) {
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
        controller: 'SaleViewCtrl',
        controllerAs: 'saleViewCtrl',
        resolve: {
          sale: function () {
            return accessControlIdentification.sale;
          }
        }
      }, null, null, true);
    }

    function iceSkateRental(action) {
      if (angular.isUndefined(action)) {
        action = 'rent';
      }

      Restangular.one('access_control_identification').one(vm.accessControlIdentification.id).one('ice_skate_rental').patch({action: action}).then(function () {
        LogService.log('patched accessControlIdentification ' + vm.accessControlIdentification.id, 'debug');
        vm.searchFinished = false;
        vm.checkWristband(vm.wristbandId);
      }, function (errorMsg) {
        console.error(errorMsg);
      });
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }
  }
}());
