(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:LevelAddEditCtrlCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('LevelAddEditCtrl', LevelAddEditCtrl);

  function LevelAddEditCtrl(
      $modalInstance,
      CurrentUserContextFactory,
      group,
      level,
      LevelFactory,
      SkillFactory,
      skillLevel,
      UtilService
    ) {
    var vm = this,
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.currentLevel = level;
    vm.currentGroup = group;
    vm.currentSkillLevel = skillLevel;
    vm.levelWeight = level === null ? 1 : level.weight;
    vm.levelLabel = level === null ? '' : level.label;
    vm.groupLabel = group === null ? '' : group.label;
    vm.skillLevelSkill = skillLevel === null ? null : skillLevel.swimCourseSkill.id;
    vm.skillLevelLevel = skillLevel === null ? null : skillLevel.level.toString();
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.getLevelRestObject = getLevelRestObject;
    vm.getGroupRestObject = getGroupRestObject;
    vm.getSkillLevelRestObject = getSkillLevelRestObject;
    vm.saveLevel = saveLevel;
    vm.saveGroup = saveGroup;
    vm.saveSkillLevel = saveSkillLevel;
    vm.deleteLevel = deleteLevel;
    vm.deleteGroup = deleteGroup;
    vm.deleteSkillLevel = deleteSkillLevel;
    vm.isUnusedSkill = isUnusedSkill;
    vm.colors = UtilService.getSportoaseColors();
    vm.groupColor = group === null || (angular.isUndefined(group.tags) || angular.isUndefined(group.tags[0])) ? false : group.tags[0];
    vm.setGroupColor = setGroupColor;

    vm.skills = [];
    vm.loadSkills = loadSkills;
    vm.loadSkills();

    function loadSkills() {
      var params = {
        limit: 99,
        sort: 'label,ASC'
      };
      vm.skills = [];

      SkillFactory.getList(params).then(function (result) {
        vm.skills = result;
      });
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function getLevelRestObject(includeSiteId) {
      var restObject = {
        label: vm.levelLabel,
        weight: vm.levelWeight
      };

      if (angular.isDefined(includeSiteId) && includeSiteId) {
        restObject.site = currentLoggedInUserSiteId;
      }

      return restObject;
    }

    function getGroupRestObject() {
      var restObject = {
        label: vm.groupLabel,
        tags: [vm.groupColor]
      };
      return restObject;
    }

    function getSkillLevelRestObject() {
      var restObject = {
        swimCourseSkill: vm.skillLevelSkill,
        level: vm.skillLevelLevel
      };

      return restObject;
    }

    function deleteLevel() {
      if (isEdit('level')) {
        LevelFactory.one(vm.currentLevel.id).remove().then(function () {
          // Close the modal view
          $modalInstance.close();
        });
      } else {
        // Close the modal view
        $modalInstance.close();
      }
    }

    function deleteGroup() {
      if (isEdit()) {
        LevelFactory.one(vm.currentLevel.id).one('groups').one(vm.currentGroup.id).remove()
        .then(function () {
          // Close the modal view
          $modalInstance.close();
        });
      } else {
        // Close the modal view
        $modalInstance.close();
      }
    }

    function deleteSkillLevel() {
      if (isEdit()) {
        LevelFactory.one(vm.currentLevel.id).one('skills').one(vm.currentSkillLevel.id).remove()
        .then(function () {
          // Close the modal view
          $modalInstance.close();
        });
      } else {
        // Close the modal view
        $modalInstance.close();
      }
    }

    function saveLevel() {
      var reloadOption = 'reloadListData', levelObject;

      // Check if we're in Edit-mode
      if (vm.isEdit('level')) {
        levelObject = getLevelRestObject();
        // Patch the level
        LevelFactory.one(vm.currentLevel.id).patch(levelObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        levelObject = getLevelRestObject(true);
        // Create the level
        LevelFactory.post(levelObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function saveGroup() {
      var reloadOption = 'reloadListData', groupObject;

      // Check if we're in Edit-mode
      if (vm.isEdit('group')) {
        groupObject = getGroupRestObject();
        LevelFactory.one(vm.currentLevel.id).one('groups').one(vm.currentGroup.id).patch(groupObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        groupObject = getGroupRestObject();
        // Create the group
        LevelFactory.one(vm.currentLevel.id).post('groups', groupObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function saveSkillLevel() {
      var reloadOption = 'reloadListData',
          skillLevelObject = getSkillLevelRestObject();

      // Check if we're in Edit-mode
      if (vm.isEdit('skillLevel')) {
        LevelFactory.one(vm.currentLevel.id).one('skills').one(vm.currentSkillLevel.id)
        .patch(skillLevelObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        // Create the skill level
        LevelFactory.one(vm.currentLevel.id).post('skills', skillLevelObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function isEdit(object) {
      switch (object) {
        case 'level':
          return vm.currentLevel !== null;
        case 'group':
          return vm.currentGroup !== null;
        case 'skillLevel':
          return vm.currentSkillLevel !== null;
        default:
          return vm.currentLevel !== null || vm.currentGroup !== null || vm.currentSkillLevel !== null;
      }
    }

    function isUnusedSkill(value) {
      var results;

      if (vm.currentSkillLevel === null) {
        return true;
      }

      if (vm.currentSkillLevel.swimCourseSkill.id === value.id) {
        return true;
      }

      results = level.swimCourseSkillLevels.filter(function (skillLvl) {
        return skillLvl.swimCourseSkill.id === value.id;
      });

      if (results.length) {
        return false;
      }

      return true;
    }

    function setGroupColor(color) {
      vm.groupColor = color;
    }
  }
}());
