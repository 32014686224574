(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:ScheduledReportFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .factory('MobileCategoryFactory', MobileCategoryFactory);

  function MobileCategoryFactory(Restangular) {
    return Restangular.service('mobile/mobile_category');
  }
}());
