(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:RoleCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('SurveyQueriesCtrl', SurveyQueriesCtrl);

  function SurveyQueriesCtrl($stateParams, LogService, _, SurveyQueryService, ToastrNotificationService, UtilService) {
    var vm = this;
    vm.surveyQueries = [];
    vm.surveySearchQuery = '';

    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    vm.showQueryAddEditModal = function (viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'pos/views/survey_queries/survey_query.addedit.modal.tpl.html',
          controller: 'AddEditSurveyQueryCtrl',
          controllerAs: 'addEditSurveyQueryCtrl',
          resolve: {
            surveyQuery: function () {
              return angular.isUndefined(viewObject) ? null : viewObject;
            }
          }
        },
        function () {
          vm.loadSurveyQueries(true);
        }
      );
    };

    vm.showQueryDeleteModal = function (query) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.survey_query', 'app.delete-msg', 'app.this_survey_query',
      function (returnValue) {
        if (returnValue) {
          SurveyQueryService.removeSurveyQuery(query.id)
          .then(function () {
            vm.loadSurveyQueries(true);
          });
        }
      });
    };

    vm.toggleQuery = function (query) {
      if (UtilService.isNotEmpty(query.active) && query.active) {
        return SurveyQueryService.activateSurveyQuery(query.id);
      }
      return SurveyQueryService.deactivateSurveyQuery(query.id);
    };

    vm.loadSurveyQueries = function (reload) {
      var queryParams;

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.surveyQueries[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage
        };

        if (UtilService.isNotEmpty(vm.surveySearchQuery)) {
          queryParams['filter[]'] = 'OR,label,LIKE ' + vm.surveySearchQuery + ',question,LIKE ' + vm.surveySearchQuery;
        }

        SurveyQueryService.getList(queryParams)
        .then(function (data) {
          vm.surveyQueries[vm.pagination.currentPage] = data;
          vm.pagination.totalItems = data.count;
        });
      }
    };

    vm.init = function () {
      vm.loadSurveyQueries();
    };

    vm.init();
  }
}());
