(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.factory:CurrentUserContextFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .factory('CurrentUserContextFactory', CurrentUserContextFactory);

  function CurrentUserContextFactory(
     UtilService,
     CurrentPosInstanceFactory,
     $cookies,
     LogService
    ) {
    return {
      getSiteId: function () {
        return this.getUserContextCookies().siteId;
      },
      getSiteFacilityId: function () {
        return this.getUserContextCookies().siteFacilityId;
      },
      getUserContextId: function () {
        return this.getUserContextCookies().contextId;
      },
      getUserName: function () {
        return this.getUserContextCookies().userName;
      },
      getSiteName: function () {
        return this.getUserContextCookies().siteName;
      },
      getUserContextCookies: function () {
        return {
          siteId: $cookies.get('currentSiteId'),
          userName: $cookies.get('currentUsername'),
          sitename: $cookies.get('currentSitename'),
          contextId: $cookies.get('currentContextId'),
          siteFacilityId: $cookies.get('siteFacilityId')
        };
      },
      removeUserContextCookies: function () {
        LogService.log('Removing all user context cookies', 'debug');
        $cookies.remove('currentSiteId');
        $cookies.remove('currentUsername');
        $cookies.remove('currentSitename');
        $cookies.remove('currentContextId');
        $cookies.remove('siteFacilityId');
      },
      isUserContextSelected: function () {
        var cookies = this.getUserContextCookies();
        return (UtilService.isNotEmpty(cookies.siteId) &&
          UtilService.isNotEmpty(cookies.contextId) &&
          UtilService.isNotEmpty(cookies.userName));
      },
      setUserContextCookies: function (username, siteName, contextId, siteId, siteFacilityId) {
        if (UtilService.isNotEmpty(username)) {
          $cookies.put('currentUsername', username);
        }
        if (UtilService.isNotEmpty(siteName)) {
          $cookies.put('currentSitename', siteName);
          /* eslint-disable no-undef */
          //to share event between tabs we should use localStorage. see #59731
          localStorage.setItem('siteName', siteName);
        }
        if (UtilService.isNotEmpty(contextId)) {
          $cookies.put('currentContextId', contextId);
        }
        if (UtilService.isNotEmpty(siteId)) {
          $cookies.put('currentSiteId', siteId);
        }
        if (UtilService.isNotEmpty(siteFacilityId)) {
          $cookies.put('siteFacilityId', siteFacilityId);
        }

        // remove instance cookies to be sure, this could be done better if we implemented #19663
        if (CurrentPosInstanceFactory.isInstanceSelected()) {
          CurrentPosInstanceFactory.removeInstanceCookies();
        }
      },
      clearSiteFacilityIdCookie: function () {
        $cookies.put('siteFacilityId', '');
      },
      setPosSessionIsActiveCookie: function () {
        $cookies.put('posSessionIsActive', 'true', { expires: null});
      },
      isPosSessionActive: function () {
        return false;
      },
      removePosSessionIsActiveCookie: function () {
        $cookies.remove('posSessionIsActive');
      }
    };
  }
}());
