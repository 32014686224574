(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.service:LevelService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('LevelService', LevelService);

  function LevelService(
    LevelFactory
    ) {
    return {
      getAll: function (filters) {
        var params = {
          limit: 99,
          sort: 'weight,ASC'
        };
        if (angular.isDefined(filters)) {
          angular.forEach(filters, function (filter, key) {
            params['filter[' + key + ']'] = filter;
          });
        }
        return LevelFactory.getList(params);
      }
    };
  }
}());
