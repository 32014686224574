(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:colorPicker
   * @restrict AEC
   *
   */
  /* @ngInject */
  angular
    .module('sportoase')
    .directive('colorPicker', colorPicker);

  function colorPicker() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        callback: '&',
        activeColor: '=?',
        useSportColors: '=?'
      },
      controller: ['$scope', 'ColorFactory', function ($scope, ColorFactory) {
        $scope.colors = $scope.useSportColors ? ColorFactory.getSportColors() : ColorFactory.getColors();
      }],
      template:
      '<div class="color-picker">' +
      '<div class="colors">' +
      '<a ng-class="{selected: activeColor === false}" class="color none" ng-click="setColor(false)"><span>{{ "app.none" | translate | uconlyfirst }}</span></a>' +
      '<a ng-repeat="color in colors" ng-class="[{selected: color.id === activeColor.id}, \'bg-\' + color.id]" class="color" ng-click="setColor(color)">' +
      '<span>{{ color.id }}</span>' +
      '</a>' +
      '</div>' +
      '</div>',
      link: function (scope) {
        scope.setColor = function (color) {
          scope.callback({color: color});
        };
      }
    };
  }
}());
