(function () {
  'use strict';
  /* @ngInject */

  angular.module('utils')
    .service('LogService', LogService);

  function LogService($log, moment) {
    var self = this;
    self.subscribers = [];
    self.messages = [];

    // (un)register subscriber functions
    self.subscribe = function (method) {
      if (angular.isFunction(method)) {
        self.subscribers.push(method);
      }
    };

    self.unsubscribe = function (method) {
      var index = this.subscribers.indexOf(method);
      if (index > -1) {
        self.subscribers.splice(index, 1);
      }
    };

    self.notifySubscribers = function (message) {
      angular.forEach(self.subscribers, function (callback) {
        callback(message);
      });
    };

    self.logToConsole = function (message, type, tag) {
      // we use an arguments array because we might want to print multiple thing,
      // for example when we want to print text + a variable's content
      var argumentsArray = [];

      if (isEmpty(type)) {
        type = 'debug';
      }

      if (isEmpty(tag)) {
        tag = '[LogService]';
      } else {
        tag = '[' + tag + ']';
      }

      if (angular.isArray(message)) {
        argumentsArray = message.slice();
      } else {
        argumentsArray.push(message);
      }

      argumentsArray.unshift(tag);

      switch (type) {
        case 'info':
          $log.info.apply(this, argumentsArray);
          break;
        case 'warn':
          $log.warn.apply(this, argumentsArray);
          break;
        case 'error':
          $log.error.apply(this, argumentsArray);
          break;
        default:
        case 'debug':
          $log.info.apply(this, argumentsArray);
          break;
      }
    };

    self.logToBackend = function (message, type) {
      // debug is default
      if (!isNotEmpty(type)) {
        type = 'debug';
      }

      self.logToConsole('A message was passed to the backend, but this functionality is not implemented yet!\"' + message + '\"', type);
    };

    self.log = function (message, type, tag, logToConsole, logToBackend) {
      var record = {}, now = moment();

      message = '[' + now.format('YYYY-MM-DD HH:mm:ss.SSS') + '] ' + message;

      // set up some default values
      if (isEmpty(logToConsole)) {
        logToConsole = true;
      }

      if (isEmpty(logToBackend)) {
        logToBackend = false;
      }

      if (isEmpty(type)) {
        type = 'debug';
      }

      if (logToConsole) {
        self.logToConsole(message, type, tag);
      }

      if (logToBackend) {
        self.logToBackend(message, type);
      }

      record.type = type;
      record.message = message;
      self.messages.push(record);
      self.notifySubscribers(record);
    };

    self.getMessages = function () {
      return self.messages;
    };

    function isNotEmpty(variable) {
      var notNull = angular.isDefined(variable) && variable !== null;
      if (!notNull) {
        return false;
      }
      if (angular.isString(variable) || angular.isArray(variable)) {
        return variable.length > 0;
      }
      if (angular.isObject(variable) && !angular.isDate(variable)) {
        return Object.keys(variable).length > 0;
      }

      return true;
    }

    function isEmpty(variable) {
      return !isNotEmpty(variable);
    }
  }
}());
