(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name subscriptionContract.controller:SubscriptionContractTerminateCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('subscriptionContract')
    .controller('SubscriptionContractTerminateCtrl', SubscriptionContractTerminateCtrl);

  function SubscriptionContractTerminateCtrl(
      subscriptionContract,
      JournalFactory,
      JournalActionFactory,
      SubscriptionContractFactory,
      UtilService,
      $modalInstance,
      LogService
      ) {
    var vm = this;
    vm.subscriptionContract = subscriptionContract;
    vm.cancel = cancelModalInstance;
    vm.terminateContract = terminateContract;
    vm.terminateJournal = true;
    vm.terminateDate = new Date();
    vm.terminateJournalDate = new Date();
    vm.minDate = new Date();
    vm.datePickerIsOpened = false;
    vm.journalDatePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.openJournalDatePicker = openJournalDatePicker;
    vm.showConfirm = showConfirm;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function terminateContract() {
      var journalItemForm;

      // Delete a specific customer
      SubscriptionContractFactory.one(vm.subscriptionContract.id).patch({endDate: vm.terminateDate.toISOString().substring(0, 10)}).then(function () {
        LogService.log('Data patched', 'debug');
        // Close the modal view
        $modalInstance.close('list');
      });
      if (vm.terminateJournal === true) {
        JournalActionFactory.getJournalActionByCode('termination')
        .then(function (terminationAction) {
          journalItemForm = {
            journalAction: terminationAction.id,
            date: vm.terminateJournalDate.toISOString()
          };

          LogService.log('journalItem: ' + journalItemForm, 'debug');
          JournalFactory.one(vm.subscriptionContract.journal.id).post('items', journalItemForm)
          .then(function (journalItem) {
            LogService.log('journalItem termination: ' + journalItem, 'debug');
          });
        });
      }
    }

    function showConfirm() {
      var body, title;

      if (vm.terminateJournal) {
        title = 'subscription_contract_delete.title';
        body = 'subscription_contract_delete.body';
        UtilService.showTranslatedConfirmationModal(title, body, function (result) {
          if (result === true) {
            terminateContract();
          }
        });
      } else {
        terminateContract();
      }
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function openJournalDatePicker() {
      vm.journalDatePickerIsOpened = true;
    }
  }
}());
