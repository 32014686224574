(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SkillAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SkillAddEditCtrl', SkillAddEditCtrl);

  function SkillAddEditCtrl(
      SkillFactory,
      LogService,
      $modalInstance,
      skill
    ) {
    var vm = this;
    vm.object = skill;

    if (vm.object === null) {
      vm.object = {
        label: '',
        frLabel: '',
        comments: null
      };
    }

    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.save = save;
    vm.remove = remove;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function remove() {
      if (isEdit()) {
        SkillFactory.one(vm.object.id).remove().then(function () {
          // Close the modal view
          $modalInstance.close();
        });
      } else {
        // Close the modal view
        $modalInstance.close();
      }
    }

    function save() {
      var reloadOption = 'reloadListData',
          restObject = {
            label: vm.object.label,
            frLabel: vm.object.frLabel,
            comments: vm.object.comments
          };

      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        // Patch
        SkillFactory.one(vm.object.id).patch(restObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          LogService.log('error: ' + errorMsg, 'error');
        });
      } else {
        // Create the level
        SkillFactory.post(restObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          LogService.log('error: ' + errorMsg, 'error');
        });
      }
    }

    function isEdit() {
      return vm.object.id && (vm.object.id !== null);
    }
  }
}());
