(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name event.factory:EventCategoryFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('event')
    .factory('EventCategoryFactory', EventCategoryFactory);

  function EventCategoryFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventCategory'});
    })
    .service('events/categories');
  }
}());
