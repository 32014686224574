(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.controller:FitnessCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .controller('FitnessCtrl', FitnessCtrl);

  function FitnessCtrl($filter,
                       $state,
                       $stateParams,
                       _,
                       EventFactory,
                       FitnessCourseFactory,
                       LogService,
                       UtilService,
                       moment) {
    var vm = this;
    vm.filterEvents = filterEvents;
    vm.reloadPageData = reloadPageData;
    vm.reloadListData = reloadListData;
    vm.loadEvents = loadEvents;
    vm.showModalView = showModalView;
    vm.showEventInstanceModalView = showEventInstanceModalView;
    vm.modalInstance = null;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.eventDates = [];
    vm.eventDatesFiltered = [];
    vm.filters = {
      startDate: {
        val: new Date(),
        field: 'startsAt',
        cond: 'GTE ',
        format: 'DD/MM/YYYY 00:00:00',
        isOpen: false,
        open: function () {
          vm.filters.startDate.isOpen = true;
        }
      },
      endDate: {
        val: (new Date()).setDate((new Date()).getDate() + 14),
        field: 'endsAt',
        cond: 'LTE ',
        format: 'DD/MM/YYYY 23:59:59',
        isOpen: false,
        open: function () {
          vm.filters.endDate.isOpen = true;
        }
      }
    };
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    // Check the state of the customer page
    if ($state.is('fitnessdetail')) {
      // Reload page data
      vm.reloadPageData($stateParams.id);
      vm.fitnessCourseId = $stateParams.id;
    } else {
      // Reload List Data
      vm.reloadListData();
      LogService.log('reload List Data', 'debug');
    }

    function reloadPageData(eventId) {
      return new Promise(function (resolve) {
        FitnessCourseFactory.one(eventId).get().then(function (fitnessCourse) {
          LogService.log('fitnessCourse: ' + fitnessCourse, 'debug');
          vm.fitnessEvent = fitnessCourse;
          vm.loadEvents(resolve);
        });
      });
    }

    function loadEvents(resolve) {
      var queryParams = {
            offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
            limit: vm.pagination.itemsPerPage,
            sort: 'startsAt,ASC',
            view: 'table'
          },
          results = [];

      angular.forEach(vm.filters, function (filter, index) {
        var value = null;
        switch (index) {
          case 'startDate':
          case 'endDate':
            value = $filter('date')(filter.val, 'yyyy-MM-dd"T"HH:mm:ssZ');
            break;
          default:
            value = filter.val;
            break;
        }
        queryParams['filter[' + index + ']'] = filter.field + ',' + filter.cond + value;
      });

      if (angular.isDefined(vm.fitnessEvent.events) && angular.isDefined(vm.fitnessEvent.events[0])) {
        EventFactory.one(vm.fitnessEvent.events[0].id).one('instances').get(queryParams).then(function (fitnessEvent) {
          results = fitnessEvent.results;
          //angular.forEach(fitnessEvent.results, function (instance) {
          //  EventFactory.one(fitnessEvent.id).one('instances').one(instance.id).get().then(function (resultInstance) {
          //    results.push(resultInstance);
          //  });
          //});
          vm.eventDates[vm.pagination.currentPage] = results;
          vm.pagination.totalItems = fitnessEvent.count;
          if (resolve) {
            resolve();
          }
        });
      }
    }

    function reloadListData() {
      var parameters = {
        limit: 99,
        active: true
      };
      FitnessCourseFactory.getList(parameters).then(function (resultCourses) {
        vm.events = resultCourses;
      });
    }

    function filterEvents() {
      var sd, ed, sds, eds;
      if (UtilService.isNotEmpty(vm.filters.startDate.val)) {
        sd = moment(vm.filters.startDate.val);
      } else {
        sd = moment().subtract(365, 'days').startOf('day');
      }
      if (UtilService.isNotEmpty(vm.filters.endDate.val)) {
        ed = moment(vm.filters.endDate.val);
      } else {
        ed = moment().add(365, 'days').startOf('day');
      }
      vm.eventDatesFiltered = [];
      _.each(vm.eventDates, function (event) {
        if (event) {
          sds = moment(event.startsAt).startOf('day');
          eds = moment(event.endsAt).startOf('day');
          if ((sds <= ed) && (eds >= sd)) {
            vm.eventDatesFiltered.push(event);
          }
        }
      });
    }

    function showEventInstanceModalView(returnType, type, eventInstance) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add-multiple':
        case 'edit-multiple':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.instance.modal.addedit.tpl.html',
              controller: 'FitnessInstanceAddEditCtrl',
              controllerAs: 'fitnessInstanceAddEditCtrl',
              resolve: {
                eventInstance: function () {
                  return eventInstance;
                },
                fitnessEvent: function () {
                  return vm.fitnessEvent.events[0].id;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'add-single':
        case 'edit-single':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.instance.modal.addedit.single.tpl.html',
              controller: 'FitnessInstanceAddEditCtrl',
              controllerAs: 'fitnessInstanceAddEditCtrl',
              resolve: {
                eventInstance: function () {
                  return eventInstance;
                },
                fitnessEvent: function () {
                  return vm.fitnessEvent.events[0].id;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.instance.modal.delete.tpl.html',
              controller: 'FitnessInstanceDeleteCtrl',
              controllerAs: 'fitnessInstanceDeleteCtrl',
              resolve: {
                eventInstance: function () {
                  return eventInstance;
                },
                fitnessEvent: function () {
                  return vm.fitnessEvent.events[0].id;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);

          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function showModalView(returnType, type, fitnessEvent) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.modal.addedit.tpl.html',
              controller: 'FitnessAddEditCtrl',
              controllerAs: 'fitnessAddEditCtrl',
              resolve: {
                fitnessEvent: function () {
                  return fitnessEvent;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.modal.delete.tpl.html',
              controller: 'FitnessDeleteCtrl',
              controllerAs: 'fitnessDeleteCtrl',
              resolve: {
                fitnessEvent: function () {
                  return fitnessEvent;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler
          );
          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function modalResultHandler(returnValue) {
      vm.modalInstance = null;
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'page':
          vm.reloadPageData(vm.fitnessEvent.id);
          break;

        case 'list':
          vm.reloadListData();
          break;

        default:
          break;
      }
    }

    function returnOfModalInstance() {
      // Check result of modal view
      vm.modalInstance.result.then(function (returnValue) {
        vm.modalInstance = null;
        // Check the type of the returnValue (= reloadOption) and fire specific reload-method
        switch (returnValue) {
          case 'page':
            vm.reloadPageData(vm.fitnessEvent.id);
            break;

          case 'list':
            vm.reloadListData();
            break;

          default:
            break;
        }
      }, function (returnValue) {
        LogService.log('reason of closing: ' + returnValue, 'debug');
        vm.modalInstance = null;
      });
    }
  }
}());
