(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.controller:HistoryViewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('utils')
    .controller('HistoryViewCtrl', HistoryViewCtrl);

  function HistoryViewCtrl($modalInstance, entityId, entityName, keys, HistoryFactory, HistoryService, SettingsService, UserFactory, UtilService) {
    var vm = this;
    vm.entityId = entityId;
    vm.entityName = entityName;
    vm.relatedTo = [];
    vm.history = [];
    vm.cancel = cancelModalInstance;
    vm.enableInternalComments = SettingsService.get('pos.saleItem.enableInternalComments');
    vm.enableSalesLinking = SettingsService.get('pos.enableSalesLinking', false);

    HistoryFactory.getList({
      id: vm.entityId
    }).then(function (result) {
      angular.forEach(result, function (historyItem) {
        historyItem.changes = HistoryService.getVersionedData(historyItem.versionedData, keys);
        if (UtilService.isNotEmpty(historyItem.changes)) {
          if (angular.isDefined(historyItem.cause)) {
            UserFactory.one(historyItem.cause).get().then(function (user) {
              historyItem.cause = user;
            });
          }
          vm.history.push(historyItem);
        }
      });
    });

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
