(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancyPeriod.controller:AccountancyPeriodsDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('AccountancyPeriodsDeleteCtrl', AccountancyPeriodsDeleteCtrl);

  function AccountancyPeriodsDeleteCtrl(AccountancyPeriodFactory,
                                        $modalInstance,
                                        period,
                                        ToastrNotificationService) {
    var vm = this;
    vm.period = period;
    vm.deleteAccountancyPeriod = deleteAccountancyPeriod;
    vm.cancel = cancelModalInstance;
    vm.showDeleteNotification = showDeleteNotification;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function deleteAccountancyPeriod(reloadOption) {
      AccountancyPeriodFactory.one(vm.period.id).remove().then(function () {
        // Close the modal view
        $modalInstance.close(reloadOption);
        // Show notification at successful remove
        vm.showDeleteNotification();
      });
    }

    function showDeleteNotification() {
      // Show notification at successful remove
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-removed',
        'app.accountancy-period',
        'app.item-successfully-removed',
        'app.accountancy-period'
      );
    }
  }
}());
