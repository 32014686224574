(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SeriesDateDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SeriesDateDeleteCtrl', SeriesDateDeleteCtrl);

  function SeriesDateDeleteCtrl(
      $modalInstance
    ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.returnAnswer = returnAnswer;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function returnAnswer(answer) {
      $modalInstance.close(answer);
    }
  }
}());
