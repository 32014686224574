(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.controller:FacilityOverviewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .controller('FacilityOverviewCtrl', FacilityOverviewCtrl);

  function FacilityOverviewCtrl(
    FacilityFactory,
    _
  ) {
    var vm = this;
    vm.facilities = [];
    vm.nodes = [];

    vm.loadFacilities = function () {
      var params = {
        limit: 99
      };

      FacilityFactory.getList(params).then(function (resultFacilities) {
        _.each(resultFacilities, function (item) {
          if (angular.isDefined(item.parentFacility)) {
            vm.facilities.push(item);
          } else {
            item.parentFacility = {id: 0};
            vm.facilities.push(item);
          }
        });

        vm.nodes = unflatten(vm.facilities);
      });
    };

    function unflatten(array, parent, tree) {
      var children;
      tree = angular.isDefined(tree) ? tree : [];
      parent = angular.isDefined(parent) ? parent : {id: 0};

      children = _.filter(array, function (child) {
        return child.parentFacility.id === parent.id;
      });

      if (!_.isEmpty(children)) {
        if (parent.id === 0) {
          tree = children;
        } else {
          parent.childFacilities = children;
        }
        _.each(children, function (child) {
          unflatten(array, child);
        });
      }

      return tree;
    }

    vm.loadFacilities();
  }
}());
