/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {
  'use strict';

    /**
     * @ngdoc object
     * @name accountancy.controller:StuckSaleAddCtrl
     *
     * @description
     *
     */
    /* @ngInject */
  angular
        .module('accountancy')
        .controller('StuckSaleAddCtrl', StuckSaleAddCtrl);

  function StuckSaleAddCtrl($filter,
                            $timeout,
                            StuckSalePosSessionFactory,
                            StuckSaleAccountancyPeriodsFactory,
                            StuckSalesModificationsFactory,
                            CurrentUserContextFactory,
                            UtilService,
                            ToastrNotificationService,
                            $modalInstance) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.posSessions = [];
    vm.startDate = new Date();
    vm.newSaleDate = new Date();
    vm.datePickerIsOpened = false;
    vm.loadPosSessions = loadPosSessions;
    vm.selectPosRow = selectPosRow;
    vm.comment = '';
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.openDatePicker = openDatePicker;
    vm.loadPosSessions();
    vm.selectPosRow();
    vm.checkStartEndTime = checkStartEndTime;
    /* ----- END FUNCTIONS ----- */

    /* ----- DATE PICKER ----- */
    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }
    /* ----- END DATE PICKER ----- */

    /* ----- OTHER ----- */
    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    // Select pos session logic
    vm.selectedRow = null;
    vm.selectedPos = null;
    function selectPosRow(index, posId) {
      if (vm.selectedRow === index) {
        vm.selectedRow = null;
        vm.selectedPos = null;
      } else {
        vm.selectedRow = index;
        vm.selectedPos = posId;
        vm.newSaleDate = new Date(vm.posSessions[index].createdAt);
      }
    }

    function loadPosSessions(reload) {
      var value, accountancyPeriodsParams, queryParams = {}, condition = '';

      vm.filters = {
        beginDate: {
          val: vm.startDate,
          field: 'createdAt',
          cond: 'GTE',
          format: 'yyyy-MM-dd HH:mm:ss',
          isOpen: false,
          open: function () {
            vm.filters.beginDate.isOpen = true;
          }
        },
        endDate: {
          val: vm.startDate,
          field: 'createdAt',
          cond: 'LTE',
          format: 'yyyy-MM-dd HH:mm:ss',
          isOpen: false,
          open: function () {
            vm.filters.endDate.isOpen = true;
          }
        }
      };

      queryParams = {
        sort: 'createdAt,DESC',
        limit: 99
      };

      _.each(vm.filters, function (filter, index) {
        if ((angular.isDefined(filter.val) && filter.val) || (reload === true)) {
          switch (index) {
            case 'beginDate':
              filter.val.setHours(0);
              filter.val.setMinutes(0);
              filter.val.setSeconds(0);
              value = $filter('date')(filter.val, filter.format);
              break;
            case 'endDate':
              filter.val.setHours(23);
              filter.val.setMinutes(59);
              filter.val.setSeconds(59);
              value = $filter('date')(filter.val, filter.format);
              break;
            default:
              value = filter.val;
              break;
          }

          condition = '';
          if (UtilService.isNotEmpty(filter.cond)) {
            condition = filter.cond + ' ';
          }

          if (filter.hasOwnProperty('isParam') && filter.isParam) {
            queryParams[index] = value;
          } else {
            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + value];
            if (angular.isDefined(filter.allowNullIfSet) && filter.allowNullIfSet === true) {
              queryParams['filter[' + index + ']'] = 'OR,' + queryParams['filter[' + index + ']'] + ',' + filter.field + ',NULL';
            }
          }
        }
      });

      accountancyPeriodsParams = {
        context: CurrentUserContextFactory.getUserContextId(),
        sort: 'createdAt,DESC'
      };

      // Check if date is in open Accountancy Period
      StuckSaleAccountancyPeriodsFactory.getList(accountancyPeriodsParams).then(function (data) {
        //set time for start date to 00:00:00 for correct work with last date of period, see #79491
        vm.startDate.setHours(0);
        vm.startDate.setMinutes(0);
        vm.startDate.setSeconds(0);
        data.forEach(function (period) {
          // Check date is between period and period is opened
          if ((vm.startDate.toISOString() <= period.endDate && vm.startDate.toISOString() >= period.startDate) && period.isOpened) {
            StuckSalePosSessionFactory.getList(queryParams).then(function (posData) {
              $timeout(function () {
                vm.posSessions = posData;
              });
            });
          }
        });
      });
    }

    vm.reloadItems = function () {
      vm.loadPosSessions(true);
    };

    vm.saveCollectionPeriod = function () {
      var queryParamsStuckSalesModifications;
      // check if time sale is between selected pos start and end time
      if (vm.selectedRow === null) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'error',
          'app.error',
          '',
          'accountancy.stuck-sale.selectPos'
        );

        return Promise.reject();
      }

      if (!checkStartEndTime()) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'error',
          'app.error',
          '',
          'accountancy.stuck-sale.start-endtime-error'
        );

        return Promise.reject();
      }

      queryParamsStuckSalesModifications = {
        context: CurrentUserContextFactory.getUserContextId(),
        facility: CurrentUserContextFactory.getSiteFacilityId(),
        site: CurrentUserContextFactory.getSiteId(),
        pointOfSaleSession: vm.selectedPos,
        createdAtSaleModification: vm.newSaleDate,
        comment: vm.comment
      };

      return StuckSalesModificationsFactory.post(queryParamsStuckSalesModifications).then(function (newSale) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'success',
          'app.item-saved',
          'app.stuck-sale.add',
          'app.item-successfully-saved',
          'app.stuck-sale.success-added'
        );
        $modalInstance.close(newSale);
      });
    };

    function checkStartEndTime() {
      var startDate = new Date(vm.posSessions[vm.selectedRow].createdAt), endDate = new Date(vm.posSessions[vm.selectedRow].endedAt);
      vm.newSaleDate.setSeconds(0);
      vm.newSaleDate.setMilliseconds(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
      // Check start time in pos session
      if (vm.newSaleDate >= startDate && vm.newSaleDate <= endDate) {
        // Check end time in pos session and after start time
        return true;
      }
      return false;
    }
    /* ----- END OTHER ----- */
  }
}());

