(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:PupilInputMethodCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('PupilInputMethodCtrl', PupilInputMethodCtrl);

  function PupilInputMethodCtrl(
      $modalInstance,
      addNewContactIsAllowed,
      addExistingContactIsAllowed
    ) {
    var vm = this;
    vm.addNewContactIsAllowed = addNewContactIsAllowed;
    vm.addExistingContactIsAllowed = addExistingContactIsAllowed;
    vm.cancel = cancelModalInstance;
    vm.returnInputMethodChoice = returnInputMethodChoice;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function returnInputMethodChoice(inputMethod) {
      $modalInstance.close(inputMethod);
    }
  }
}());
