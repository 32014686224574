(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name promotion.factory:PromotionFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('promotion')
    .factory('PromotionFactory', PromotionFactory);

  function PromotionFactory(Restangular) {
    return Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'promotions'});
      })
      .service('promotions');
  }
}());
