(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosScreenItemCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosScreenItemCtrl', PosScreenItemCtrl);

  function PosScreenItemCtrl(item, screen, PosScreenFactory, ProductFactory, _, $stateParams, $modalInstance, $filter, LogService, RestUtilService) {
    var vm = this;
    vm.item = item;
    vm.screen = screen;

    vm.init = init;
    vm.save = save;
    vm.close = closeModalInstance;
    vm.cancel = cancelModalInstance;
    vm.updateScreen = updateScreen;
    vm.updateProduct = updateProduct;
    vm.updateBlank = updateBlank;
    vm.addTag = addTag;
    vm.removeTag = removeTag;
    vm.delete = deleteItem;
    vm.setColor = setColor;
    vm.setDestination = setDestination;
    vm.destinations = [
      {id: 'product', label: $filter('uconlyfirst')($filter('translate')('pos.destinations.product'))},
      {id: 'screen', label: $filter('uconlyfirst')($filter('translate')('pos.destinations.screen'))},
      {id: 'blank', label: $filter('uconlyfirst')($filter('translate')('pos.destinations.blank'))}
    ];
    vm.destination = vm.setDestination();

    vm.updated = {
      row: vm.item.row,
      column: vm.item.column
    };
    if (angular.isDefined(vm.item.product)) {
      vm.updated.product = vm.item.product.id;
    }
    if (angular.isDefined(vm.item.targetScreen)) {
      vm.updated.targetScreen = vm.item.targetScreen.id;
    }

    vm.newTag = '';
    vm.posScreens = [];
    vm.products = [];
    vm.posTags = [
      {id: 'fitness', label: $filter('uconlyfirst')($filter('translate')('pos.tags.fitness'))},
      {id: 'swimmingpool', label: $filter('uconlyfirst')($filter('translate')('pos.tags.swimmingpool'))},
      {id: 'residents', label: $filter('uconlyfirst')($filter('translate')('pos.tags.residents'))},
      {id: 'group', label: $filter('uconlyfirst')($filter('translate')('pos.tags.group'))},
      {id: 'highlight', label: $filter('uconlyfirst')($filter('translate')('pos.tags.highlight'))}
    ];

    function loadScreens() {
      var params = {
        limit: 99
      };
      params['filter[]'] = ['master,false'];
      angular.forEach(vm.screen.pointOfSaleGroups, function (pointOfSaleGroup) {
        params['filter[]'].push(['pointOfSaleGroups.id,' + pointOfSaleGroup.id]);
      });
      RestUtilService.getFullList(PosScreenFactory, params).then(function (resultScreens) {
        angular.forEach(resultScreens, function (posScreen) {
          vm.posScreens.push(posScreen);
        });
      });
    }

    function loadProducts() {
      var params = {
        limit: 99
      };
      params['filter[]'] = [
        'mostRecent,TRUE',
        'OR,hidden,NULL,hidden,false',
        'OR,blueprint,false,blueprint,null'
      ];
      RestUtilService.getFullList(ProductFactory, params).then(function (resultData) {
        LogService.log('products: ' + resultData[0], 'debug');
        vm.products = resultData[0];
      });
    }

    function save() {
      vm.updated.tags = [vm.item.color];
      // is new
      if (angular.isUndefined($stateParams.iId)) {
        PosScreenFactory.one($stateParams.sId).all('items').post(
          vm.updated
        ).then(function (data) {
          vm.close(data);
        });
      } else {
        LogService.log(vm.updated, 'debug');
        PosScreenFactory.one($stateParams.sId).one('items').one($stateParams.iId).customPUT(
          vm.updated
        ).then(function (data) {
          vm.close(data);
        });
      }
    }

    function updateScreen() {
      delete vm.updated.product;
      vm.updated.targetScreen = vm.item.targetScreen;
      delete vm.item.product;
    }

    function updateProduct() {
      vm.updated.product = vm.item.product;
      delete vm.updated.targetScreen;
      delete vm.item.targetScreen;
    }

    function updateBlank() {
      delete vm.updated.targetScreen;
      delete vm.updated.product;
      delete vm.item.targetScreen;
      delete vm.item.product;

      LogService.log('item: ' + vm.item, 'debug');
      LogService.log('updated: ' + vm.updated, 'debug');
    }

    function addTag() {
      if (angular.isUndefined(vm.item.tags)) {
        vm.item.tags = [];
      }
      vm.item.tags.push(vm.newTag);
      vm.newTag = '';
    }

    function removeTag(index) {
      vm.item.tags.splice(index, 1);
    }

    function setColor(color) {
      vm.item.color = color;
    }

    function deleteItem() {
      PosScreenFactory
        .one($stateParams.sId)
        .one('items')
        .one($stateParams.iId)
        .remove()
        .then(function () {
          $modalInstance.close('done');
        });
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance(data) {
      $modalInstance.close(data);
    }

    function setDestination() {
      if (angular.isDefined(vm.item.targetScreen)) {
        return 'screen';
      } else if (angular.isDefined(vm.item.product)) {
        return 'product';
      }
      return 'blank';
    }

    function init() {
      loadScreens();
      loadProducts();
    }
  }
}());
