(function () {
  'use strict';

  /*
   * @ngdoc object
   * @name toastr-notification
   * @description
   */
  angular
    .module('toastrNotification', [
      'ui.router'
    ]);
}());
