(function () {
  'use strict';
  // Async bootstrap made possible by Marius Schulz
  // https://blog.mariusschulz.com/2014/10/22/asynchronously-bootstrapping-angularjs-applications-with-server-side-data
  angular.module('sportoase.config', []);
  angular.module('sportoase.run', []);

  fetchData();

  function fetchData() {
    var $http = angular.injector(['ng']).get('$http');

    $http.get('../env/env_vars.json').then(function (response) {
      var config = response.data.OAUTH_CONFIG,
          params = response.data.PARAMS;

      angular
        .module('sportoase.config')
        .constant('OAUTH_CONFIG', config)
        .constant('PARAMS', params);
      angular
        .module('sportoase.run')
        .constant('PARAMS', params);

      bootstrapApplication();
    }, function (errorResponse) {
      // Handle error case
      console.log('An error occurred while loading the authentication configuration file', errorResponse);
    });
  }

  function bootstrapApplication() {
    var $document = angular.injector(['ng']).get('$document');
    angular.element($document).ready(function () {
      console.log('Bootstrap config data received. Angular app will be booting now.');
      angular.bootstrap($document, ['sportoase']);
    });
  }
}());
