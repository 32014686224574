(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name contact.controller:EmployeeContactAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('contact')
    .controller('EmployeeContactAddEditCtrl', EmployeeContactAddEditCtrl);

  function EmployeeContactAddEditCtrl(
    ContactFactory,
    ContactTypeFactory,
    ContactRelationFactory,
    ContactRelationTypeFactory,
    SiteFactory,
    contact,
    reloadType,
    $filter,
    $modalInstance,
    ToastrNotificationService,
    CurrentUserContextFactory,
    ContactDataTypeFactory,
    ContactDataFactory,
    LogService,
    moment
  ) {
    var vm = this,
        contactTypeParams = {
          limit: 99
        },
        contactRelationParams = {
          limit: 99
        },
        relationObject = {};
    vm.isEdit = isEdit;
    vm.contact = contact;
    vm.contactName = null;
    vm.contactFirstName = null;
    vm.contactLastName = null;
    vm.contactType = null;
    vm.relationType = null;
    vm.newEmails = [];
    vm.contactEmail = null;
    vm.removeDataFromList = removeDataFromList;
    vm.addNewObjectOfType = addNewObjectOfType;
    vm.saveContactData = saveContactData;
    vm.sendContactDataToBackend = sendContactDataToBackend;
    vm.shouldContactDataUpdate = shouldContactDataUpdate;
    vm.getObjectInputId = getObjectInputId;
    vm.dataIsDeletedFilter = dataIsDeletedFilter;
    vm.checkIfObjectIsEmpty = checkIfObjectIsEmpty;
    vm.cancel = cancelModalInstance;
    vm.saveContact = saveContact;
    vm.showSavedNotification = showSavedNotification;
    contactRelationParams['filter[]'] = ['code,EMPLOYMENT'];
    contactTypeParams['filter[]'] = ['code,PERSON'];
    // Get all Contact Types
    ContactTypeFactory.getList(contactTypeParams).then(function (resultTypes) {
      vm.contactType = resultTypes[0];
      ContactRelationTypeFactory.getList(contactRelationParams).then(function (resultRelationTypes) {
        vm.relationType = resultRelationTypes[0];
      });
      // Check if it item exists
      if (vm.isEdit()) {
        ContactFactory.one(vm.contact.id).get().then(function (resultContact) {
          vm.contact = resultContact;
          vm.contactLastName = vm.contact.lastName;
          vm.contactFirstName = vm.contact.firstName;
          // Loop all contact data
          angular.forEach(vm.contact.contactData, function (contactData) {
            contactData.important = angular.isDefined(contactData.tags) && contactData.tags.includes('important');
            contactData.code = contactData.contactDataType.code;
            switch (contactData.contactDataType.code) {
              case 'WEBSITE':
                vm.newWebsites.push(contactData);
                break;

              case 'PHONE_NUMBER':
                vm.newPhones.push(contactData);
                break;

              case 'MOBILE_NUMBER':
                vm.newMobilePhones.push(contactData);
                break;

              case 'FAX_NUMBER':
                vm.newFaxes.push(contactData);
                break;

              case 'COMMENT':
                vm.newComments.push(contactData);
                break;

              case 'EMAIL':
                vm.newEmails.push(contactData);
                break;

              case 'SOCIAL_SECURITY_NUMBER':
                vm.currentSocialSecurityNumber.push(contactData);
                break;

              case 'VAT_NUMBER':
                vm.hasVATNumber = true;
                vm.currentVATNumber.push(contactData);
                break;

              case 'COMPANY_NUMBER':
                vm.currentCompanyNumber.push(contactData);
                break;

              case 'GENDER':
                vm.currentGender.push(contactData);
                break;

              case 'BIRTH_DATE':
                contactData.value = new Date(contactData.value);
                contactData.today = moment(contactData.value).format('DD-MM') === moment().format('DD-MM');
                vm.currentBirthDate.push(contactData);
                break;

              case 'BANK_ACCOUNT_NUMBER':
                vm.currentBankAccountNumber.push(contactData);
                break;

              default:
                LogService.log('no match', 'debug');
                break;
            }
          });
        });
      }
    });

    function saveContact() {
      var contactObject = {
        contactType: vm.contactType.id,
        label: vm.contactFirstName + ' ' + vm.contactLastName,
        firstName: vm.contactFirstName,
        lastName: vm.contactLastName
      };
      // Check if it is existing contact
      if (vm.isEdit()) {
        // Save changes to contact
        ContactFactory.one(vm.contact.id).patch(contactObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      } else {
        contactObject.user = null;
        // Create new contact
        ContactFactory.post(contactObject).then(function (newContact) {
          SiteFactory.one(CurrentUserContextFactory.getSiteId()).get().then(function (resultSite) {
            relationObject.contactRelationType = vm.relationType.id;
            relationObject.parentContact = resultSite.contact.id;
            relationObject.childContact = newContact.id;
            ContactRelationFactory.post(relationObject).then(function () {
              vm.showSavedNotification();
              $modalInstance.close(reloadType);
            });
          });
        });
      }
    }

    function isEdit() {
      return vm.contact !== null;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function showSavedNotification() {
      // Show notification at successful save
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.employee')
            )
          ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.employee')
            )
          )
        );
    }

    ContactDataTypeFactory.getList({limit: 99}).then(function (resultDataTypes) {
      vm.contactDataTypes = resultDataTypes;
    });

    function addNewObjectOfType(type) {
      var dataType = $filter('filter')(vm.contactDataTypes, function (cdt) {
            return cdt.code === type;
          })[0],
          newObject = {
            // generate random input id
            inputId: Math.random().toString(36).substr(2, 36),
            contactDataType: {id: dataType.id, code: dataType.code},
            contactDataContext: {},
            code: type,
            isNew: true
          };
      // Set home context as default
      if (vm.homeContext) {
        newObject.contactDataContext = vm.homeContext;
      }

      // Add new element to list of specific type of Contact Data
      switch (type) {
        case 'COMMENT':
          vm.newComments.push(newObject);
          break;
        case 'MOBILE_NUMBER':
          vm.newMobilePhones.push(newObject);
          break;
        case 'PHONE_NUMBER':
          vm.newPhones.push(newObject);
          break;
        case 'WEBSITE':
          vm.newWebsites.push(newObject);
          break;
        case 'EMAIL':
          vm.newEmails.push(newObject);
          break;
        case 'FAX_NUMBER':
          vm.newFaxes.push(newObject);
          break;
        case 'SOCIAL_SECURITY_NUMBER':
          newObject.value = '';
          vm.currentSocialSecurityNumber.push(newObject);
          break;
        case 'VAT_NUMBER':
          newObject.value = '';
          vm.currentVATNumber.push(newObject);
          break;
        case 'COMPANY_NUMBER':
          newObject.value = '';
          vm.currentCompanyNumber.push(newObject);
          break;
        case 'GENDER':
          newObject.value = '';
          vm.currentGender.push(newObject);
          break;
        case 'BIRTH_DATE':
          newObject.value = '';
          vm.currentBirthDate.push(newObject);
          break;
        case 'BANK_ACCOUNT_NUMBER':
          newObject.value = '';
          vm.currentBankAccountNumber.push(newObject);
          break;
        default:
          break;
      }
    }

    function saveContactData(contactId) {
      var promiseQueue = [], contactObject = {
            contactType: vm.contactType.id,
            label: vm.contactFirstName + ' ' + vm.contactLastName,
            firstName: vm.contactFirstName,
            lastName: vm.contactLastName
          };
      // Check if it is existing contact
      if (vm.isEdit()) {
        // Save changes to contact
        ContactFactory.one(vm.contact.id).patch(contactObject).then(function () {
          vm.showSavedNotification();
          $modalInstance.close(reloadType);
        });
      } else {
        contactObject.user = null;
        // Create new contact
        ContactFactory.post(contactObject).then(function (newContact) {
          SiteFactory.one(CurrentUserContextFactory.getSiteId()).get().then(function (resultSite) {
            relationObject.contactRelationType = vm.relationType.id;
            relationObject.parentContact = resultSite.contact.id;
            relationObject.childContact = newContact.id;
            ContactRelationFactory.post(relationObject).then(function () {
              vm.showSavedNotification();
              $modalInstance.close(reloadType);
            });
          });
        });
      }
          // blackListReq = {reg: Restangular.one('black_list_customer'), method: null, obj: {}};

      // Save every item of contact data
      // angular.forEach(vm.currentSocialSecurityNumber, function (socialNumber) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, socialNumber));
      // });
      // angular.forEach(vm.currentVATNumber, function (vatNumber) {
      //   // check if empty
      //   if (!vm.hasVATNumber) {
      //     vatNumber.value = '';
      //   }
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, vatNumber));
      // });
      // angular.forEach(vm.currentCompanyNumber, function (companyNumber) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, companyNumber));
      // });
      // angular.forEach(vm.currentBirthDate, function (birthDate) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, birthDate));
      // });
      // angular.forEach(vm.newWebsites, function (newWebsite) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, newWebsite));
      // });
      // angular.forEach(vm.newComments, function (newComment) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, newComment));
      // });
      // angular.forEach(vm.newMobilePhones, function (newMobilePhone) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, newMobilePhone));
      // });
      // angular.forEach(vm.newPhones, function (newPhone) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, newPhone));
      // });
      // angular.forEach(vm.newFaxes, function (newFax) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, newFax));
      // });
      angular.forEach(vm.newEmails, function (newEmail) {
        promiseQueue.push(vm.sendContactDataToBackend(contactId, newEmail));
      });
      // angular.forEach(vm.currentGender, function (gender) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, gender));
      // });
      // angular.forEach(vm.currentBankAccountNumber, function (bankAccountNumber) {
      //   promiseQueue.push(vm.sendContactDataToBackend(contactId, bankAccountNumber));
      // });

      // if (vm.blackList) {
      //   blackListReq.obj.description = vm.blackList.description;
      //   blackListReq.obj.customer = customer.id;
      //   if (vm.blackList.id) {
      //     blackListReq.reg = blackListReq.reg.one(vm.blackList.id);
      //     blackListReq.method = (vm.blackList.markAsDelete) ? 'remove' : 'patch';
      //     blackListReq.obj = (vm.blackList.markAsDelete) ? undefined : blackListReq.obj;
      //   } else {
      //     blackListReq.method = 'customPOST';
      //   }
      //   promiseQueue.push(blackListReq.reg[blackListReq.method](blackListReq.obj));
      // }

      return Promise.all(promiseQueue).then(function () {
        $modalInstance.close('list');
      });
    }

    function sendContactDataToBackend(contactId, object) {
      var contactDC = object.contactDataContext,
          contactDataObject = {
            contactDataContext: angular.isUndefined(contactDC) || angular.isUndefined(contactDC.id) ? null : contactDC.id
          }, fieldName = ContactDataFactory.getFieldName(object.code);
      contactDataObject[fieldName] = object.value;
      // Check if birth Date or Social Security Number have been cleared and add isDeleted if so
      if (!object.isNew && ((object.contactDataType.code === 'BIRTH_DATE' &&
        object[fieldName] === null) ||
        vm.checkIfObjectIsEmpty(object))) {
        object.isDeleted = true;
      }

      if (object.contactDataType.code === 'COMMENT') {
        contactDataObject.tags = object.important ? ['important'] : [];
      }

      // Check if isDeleted
      if (object.isDeleted) {
        LogService.log('check if new', 'debug');
        // If is not isNew remove the object
        if (!object.isNew) {
          return ContactFactory.one(contactId).one('data').one(object.id)
            .remove()
            .then(function () {
              LogService.log('remove Data', 'debug');
            });
        }
      } else {
        if (object.contactDataType.code === 'VAT_NUMBER') {
          contactDataObject.noVatNumberCheck = true;
        }
        LogService.log('Not deleted check if new of edit', 'debug');
        // Check if birth Date or Social Security Number have a value -> if not ignore
        if (vm.shouldContactDataUpdate(object)) {
          switch (object.contactDataType.code) {
            case 'BIRTH_DATE':
              contactDataObject[fieldName] = object.value.toISOString().substr(0, 10);
              break;
            default:
              break;
          }
          // Create contact data
          return ContactFactory.one(contactId).one('data').one(object.contactDataType.code.toLowerCase())
            .customPOST(contactDataObject, null, {inputId: vm.getObjectInputId(object)});
        }

        LogService.log('not new or birthDate and social security number empty', 'debug');
        // Check if not is New to patch (this avoids social security number and birthdate to patch non existing data)
        if (!object.isNew) {
          switch (object.contactDataType.code) {
            case 'BIRTH_DATE':
              contactDataObject[fieldName] = (object.value && object.value.toISOString().length > 0) ?
                object.value.toISOString().substr(0, 10) : null;
              break;
            default:
              break;
          }
          // Patch existing contact data
          return ContactFactory.one(contactId).one('data').one(object.id)
            .patch(contactDataObject, {inputId: vm.getObjectInputId(object)});
        }
      }
    }

    function shouldContactDataUpdate(object) {
      return object.isNew &&
        ((object.contactDataType.code !== 'BIRTH_DATE' &&
          object.contactDataType.code !== 'SOCIAL_SECURITY_NUMBER' &&
          object.contactDataType.code !== 'VAT_NUMBER' &&
          object.contactDataType.code !== 'COMPANY_NUMBER' &&
          object.contactDataType.code !== 'GENDER' &&
          object.contactDataType.code !== 'BANK_ACCOUNT_NUMBER') ||
          ((object.contactDataType.code === 'BIRTH_DATE' &&
            object.value && object.value.toISOString().length > 0) || (object.value.length > 0 && (
            object.contactDataType.code === 'SOCIAL_SECURITY_NUMBER' ||
            object.contactDataType.code === 'VAT_NUMBER' ||
            object.contactDataType.code === 'COMPANY_NUMBER' ||
            object.contactDataType.code === 'GENDER' ||
            object.contactDataType.code === 'BANK_ACCOUNT_NUMBER'))));
    }

    function getObjectInputId(object) {
      return (angular.isDefined(object.id) ? object.id : object.inputId);
    }

    function removeDataFromList(object) {
      // Set isDeleted on true on a listobject
      object.isDeleted = true;
    }

    function dataIsDeletedFilter(object) {
      // Filter for ng-repeat in template
      return !object.isDeleted;
    }

    function checkIfObjectIsEmpty(object) {
      return (object.contactDataType.code === 'SOCIAL_SECURITY_NUMBER' ||
        object.contactDataType.code === 'VAT_NUMBER' ||
        object.contactDataType.code === 'COMPANY_NUMBER' ||
        object.contactDataType.code === 'GENDER' ||
        object.contactDataType.code === 'BANK_ACCOUNT_NUMBER') &&
        object.value.length === 0;
    }
  }
}());
