(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosGroupAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosGroupAddEditCtrl', PosGroupAddEditCtrl);

  function PosGroupAddEditCtrl(
    PosGroupFactory,
    $modalInstance,
    posGroup,
    CurrentUserContextFactory,
    SettingsService,
    UtilService
  ) {
    var vm = this;
    vm.posGroup = UtilService.isNotEmpty(posGroup) ? posGroup : null;
    vm.placeholder = {
      label: '',
      defaultPointOfSaleSessionStartAmount: '',
      pointOfSalePoleDisplayStandbyMessage: '',
      pointOfSalePoleDisplayStandbyMessageLine1: '',
      pointOfSalePoleDisplayStandbyMessageLine2: '',
      pointOfSalePrinterReceiptFooter: '',
      openingHours: {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: ''
      }
    };

    vm.showOpeningHoursInputs = SettingsService.get('pos.downloadPdfRecipe', false);
    vm.hideStartAmount = SettingsService.get('pos.hideStartAmount', false);
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.getPosGroupRestObject = getPosGroupRestObject;
    vm.savePosGroup = savePosGroup;
    vm.deletePosGroup = deletePosGroup;
    vm.loadData = loadData;

    vm.loadData();

    function loadData() {
      if (UtilService.isNotEmpty(vm.posGroup)) {
        PosGroupFactory.one(vm.posGroup.id).get()
        .then(function (pg) {
          if (UtilService.isNotEmpty(pg.label)) {
            vm.placeholder.label = pg.label;
          }

          if (UtilService.isNotEmpty(pg.defaultPointOfSaleSessionStartAmount)) {
            vm.placeholder.defaultPointOfSaleSessionStartAmount = pg.defaultPointOfSaleSessionStartAmount;
          }

          if (UtilService.isNotEmpty(pg.pointOfSalePoleDisplayStandbyMessage)) {
            vm.placeholder.pointOfSalePoleDisplayStandbyMessageLine1 =
              pg.pointOfSalePoleDisplayStandbyMessage.substr(0, 20).replace(/\s+$/gm, '');

            vm.placeholder.pointOfSalePoleDisplayStandbyMessageLine2 =
              pg.pointOfSalePoleDisplayStandbyMessage.substr(20, 20).replace(/\s+$/gm, '');

            vm.placeholder.pointOfSalePoleDisplayStandbyMessage = pg.pointOfSalePoleDisplayStandbyMessage;
          }

          if (UtilService.isNotEmpty(pg.pointOfSalePrinterReceiptFooter)) {
            vm.placeholder.pointOfSalePrinterReceiptFooter = pg.pointOfSalePrinterReceiptFooter;
          }
          if (UtilService.isNotEmpty(pg.openingHours)) {
            angular.forEach(pg.openingHours, function (value, key) {
              if (vm.placeholder.openingHours.hasOwnProperty(key) && UtilService.isNotEmpty(value)) {
                vm.placeholder.openingHours[key] = value;
              }
            });
          }
          vm.posGroup = pg;
        });
      }
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function getPosGroupRestObject(includeSiteId) {
      var lineLength = 20,
          line1 = (vm.placeholder.pointOfSalePoleDisplayStandbyMessageLine1 + '                    ').slice(0, lineLength),
          line2 = (vm.placeholder.pointOfSalePoleDisplayStandbyMessageLine2 + '                    ').slice(0, lineLength),
          openingHours = null,
          restObject;

      vm.placeholder.pointOfSalePoleDisplayStandbyMessage = line1 + line2;

      angular.forEach(vm.placeholder.openingHours, function (value) {
        if (UtilService.isNotEmpty(value)) {
          openingHours = vm.placeholder.openingHours;
        }
      });

      restObject = {
        label: vm.placeholder.label,
        defaultPointOfSaleSessionStartAmount: vm.placeholder.defaultPointOfSaleSessionStartAmount,
        pointOfSalePoleDisplayStandbyMessage: vm.placeholder.pointOfSalePoleDisplayStandbyMessage,
        pointOfSalePrinterReceiptFooter: vm.placeholder.pointOfSalePrinterReceiptFooter,
        openingHours: openingHours
      };

      if (angular.isDefined(includeSiteId) && includeSiteId) {
        restObject.site = CurrentUserContextFactory.getSiteId();
      }

      return restObject;
    }

    function deletePosGroup() {
      if (vm.isEdit()) {
        PosGroupFactory.one(vm.posGroup.id).remove()
        .then(function () {
          $modalInstance.close();
        });
      } else {
        $modalInstance.close();
      }
    }

    function savePosGroup() {
      var reloadOption = 'reloadListData', posGroupObject;

      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        posGroupObject = getPosGroupRestObject();
        // Patch the level
        PosGroupFactory.one(vm.posGroup.id).patch(posGroupObject).then(function () {
          $modalInstance.close(reloadOption);
        });
      } else {
        posGroupObject = getPosGroupRestObject(true);
        // Create the level
        PosGroupFactory.post(posGroupObject).then(function () {
          $modalInstance.close(reloadOption);
        });
      }
    }

    function isEdit() {
      return UtilService.isNotEmpty(vm.posGroup);
    }
  }
}());
