(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:EvaluationCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('EvaluationCtrl', EvaluationCtrl);

  function EvaluationCtrl(
    LogService,
    $modalInstance,
    SkillFactory,
    SubscriptionFactory,
    subscription,
    $filter
  ) {
    var vm = this;
    vm.subscription = subscription;
    vm.saveEvaluations = saveEvaluations;
    vm.cancel = cancelModalInstance;
    vm.skill = [];
    vm.possibleValues = [
      {
        label: $filter('translate')('app.failed'),
        value: 0
      },
      {
        label: $filter('translate')('app.needsWork'),
        value: 1
      },
      {
        label: $filter('translate')('app.passed'),
        value: 2
      }
    ];
    vm.evaluations = {};
    vm.loadData = loadData;
    vm.notifyEvaluationPersisted = notifyEvaluationPersisted;
    vm.loadData();

    function loadData() {
      // load skills
      SkillFactory.getList({limit: 99}).then(function (skills) {
        vm.skills = skills;
        angular.forEach(skills, function (skill) {
          vm.evaluations[skill.id] = {
            id: null,
            skillId: skill.id,
            level: null,
            comments: null
          };
        });

        // fill in the evaluation data
        angular.forEach(subscription.evaluations, function (evaluation, skillId) {
          vm.evaluations[skillId].level = evaluation.level;
          vm.evaluations[skillId].id = evaluation.id;
          vm.evaluations[skillId].comments = evaluation.comments;
        });
      });
    }

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveEvaluation(evaluation) {
      var restObject;
      if (evaluation.level !== null && angular.isNumber(evaluation.level)) {
        restObject = {
          swimCourseSkill: evaluation.skillId,
          level: evaluation.level
        };

        if (angular.isDefined(evaluation.comments) && evaluation.comments !== null && evaluation.comments.length) {
          restObject.comments = evaluation.comments;
        }
        if (evaluation.hasOwnProperty('id') && evaluation.id !== null) {
          // patch
          SubscriptionFactory.one(subscription.id).one('evaluations').one(evaluation.id).patch(restObject)
          .then(function () {
            vm.notifyEvaluationPersisted();
          });
        } else {
          // post
          SubscriptionFactory.one(subscription.id).post('evaluations', restObject).then(function (result) {
            evaluation.id = result.id;
            vm.notifyEvaluationPersisted();
          });
        }
      } else {
        LogService.log('Evaluation level is not a number!', 'debug');
        LogService.log(evaluation, 'debug');
        vm.notifyEvaluationPersisted();
      }
    }

    // only close the modal when everything is done persisting (otherwise the refresh will come too soon)
    function notifyEvaluationPersisted() {
      --vm.evaluationsToPersist;
      if (vm.evaluationsToPersist <= 0) {
        $modalInstance.close();
      }
    }

    function saveEvaluations() {
      vm.evaluationsToPersist = 0;
      angular.forEach(vm.evaluations, function (evaluation) {
        ++vm.evaluationsToPersist;
        saveEvaluation(evaluation);
      });
    }
  }
}());
