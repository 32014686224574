(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facility.controller:FacilityCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('facility')
    .controller('FacilityCtrl', FacilityCtrl);

  function FacilityCtrl($state,
                        $stateParams,
                        FacilityFactory,
                        LogService,
                        UtilService) {
    var vm = this;
    vm.reloadPageData = reloadPageData;
    vm.reloadListData = reloadListData;
    vm.showModalView = showModalView;
    vm.modalInstance = null;

    // Check the state of the customer page
    if ($state.is('facilitydetail')) {
      // Reload page data
      vm.reloadPageData($stateParams.id);
    } else {
      // Reload List Data
      vm.reloadListData();
    }

    function reloadPageData(facilityId) {
      // Get facility
      FacilityFactory.one(facilityId).get().then(function (resultFacility) {
        vm.currentFacility = resultFacility;
      });
    }

    function reloadListData() {
      var params = {
        limit: 99
      };

      // Get all Facilities
      LogService.log('reload List Data', 'debug');
      FacilityFactory.getList(params).then(function (resultFacilities) {
        vm.facilities = resultFacilities;
      });
    }

    function showModalView(returnType, type, facility) {
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');

          UtilService.showModal(
            {
              templateUrl: 'facility/views/facility.modal.addedit.tpl.html',
              controller: 'FacilityAddEditCtrl',
              controllerAs: 'facilityAddEditCtrl',
              resolve: {
                facility: function () {
                  return facility;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'facility/views/facility.modal.delete.tpl.html',
              controller: 'FacilityDeleteCtrl',
              controllerAs: 'facilityDeleteCtrl',
              resolve: {
                facility: function () {
                  return facility;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function modalResultHandler(returnValue) {
      LogService.log('return value is ' + returnValue, 'debug');
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'page':
          vm.reloadPageData(vm.currentFacility.id);
          break;

        case 'list':
          vm.reloadListData();
          break;

        default:
          break;
      }
    }
  }
}());
