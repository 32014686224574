(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name customer.controller:CustomerAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('customer')
    .controller('CustomerAddEditCtrl', CustomerAddEditCtrl);

  function CustomerAddEditCtrl(
      ContactFactory,
      ContactTypeFactory,
      CustomerContactTypeFactory,
      CustomerFactory,
      CustomerTypeFactory,
      UtilService,
      ContactService,
      $filter,
      $modalInstance,
      customer,
      $state,
      type
    ) {
    var vm = this;
    vm.currentCustomer = customer;
    vm.isProcessingRequest = false;
    vm.customerTypes = null;
    vm.customerLabel = null;
    vm.customerFirstName = null;
    vm.customerLastName = null;
    vm.customerTypeValue = null;
    vm.customerTags = '';
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.saveCustomer = saveCustomer;
    vm.saveCustomerAfterCheck = saveCustomerAfterCheck;
    vm.isOrganization = isOrganization;

    // Get The customer Types
    CustomerTypeFactory.getList({limit: 99}).then(function (resultCustomerTypes) {
      vm.customerTypes = resultCustomerTypes;
      // Check if it is a edit or an add
      if (vm.isEdit()) {
        vm.customerLabel = vm.currentCustomer.label;
        if (angular.isDefined(vm.currentCustomer.tags) && angular.isArray(vm.currentCustomer.tags)) {
          vm.customerTags = vm.currentCustomer.tags.toString();
        }
        vm.customerInvoicePreferred = vm.currentCustomer.invoicePreferred ? vm.currentCustomer.invoicePreferred : false;
        vm.customerInvoicePaymentDays = vm.customerInvoicePreferred ? vm.currentCustomer.invoicePaymentDays : '';
        // Get the current Customer his customer Type
        vm.customerTypeValue = $filter('filter')(vm.customerTypes, function (ct) {
          return ct.id === vm.currentCustomer.customerType.id;
        })[0];
      } else {
        // Get the customer contact Types
        CustomerContactTypeFactory.getList({limit: 99}).then(function (resultCustomerContactTypes) {
          // Get the Customer Contact 'Billing' Type to add to the new automatically added customer contact
          vm.customerContactBillingType = $filter('filter')(resultCustomerContactTypes, function (cct) {
            return cct.code === 'BILLING';
          })[0];
          // Get the Customer Contact 'User' Type to add to the new automatically added customer contact
          vm.customerContactUserType = $filter('filter')(resultCustomerContactTypes, function (cct) {
            return cct.code === 'USER';
          })[0];
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
        // Get the Contact Types
        ContactTypeFactory.getList({limit: 99}).then(function (resultTypes) {
          // Get the 'person' contact Type to add to the new automatically added contact
          vm.personContactTypeObject = $filter('filter')(resultTypes, function (ct) {
            return ct.code === 'PERSON';
          })[0];
          // Get the 'person' contact Type to add to the new automatically added contact
          vm.organizationContactTypeObject = $filter('filter')(resultTypes, function (ct) {
            return ct.code === 'ORGANIZATION';
          })[0];
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }, function (errorMsg) {
      console.error('error: ' + errorMsg);
    });

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
      vm.isProcessingRequest = false;
    }

    function saveCustomer() {
      var reloadOption = type === 'customerList' ? 'reloadListData' :
          'reloadPageData',
          customerObject = {
            label: vm.customerLabel,
            customerType: vm.customerTypeValue.id,
            invoicePaymentDays: vm.customerInvoicePreferred ? vm.customerInvoicePaymentDays : null,
            invoicePreferred: vm.customerInvoicePreferred,
            tags: vm.customerTags.split(',')
          },
          contactObject = {
            label: vm.customerLabel,
            user: null
          },
          customerContactObject = {};
      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        // Patch the customer
        vm.isProcessingRequest = true;
        CustomerFactory.one(vm.currentCustomer.id).patch(customerObject).then(function () {
          // Close the modal view
          $modalInstance.close(reloadOption);
          vm.isProcessingRequest = false;
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else if (vm.isOrganization()) {
        contactObject.contactType = vm.organizationContactTypeObject.id;
        vm.saveCustomerAfterCheck(customerObject, reloadOption, contactObject, customerContactObject);
      } else {
        contactObject.contactType = vm.personContactTypeObject.id;
        customerObject.label = vm.customerFirstName + ' ' + vm.customerLastName;
        contactObject.label = customerObject.label;
        contactObject.firstName = vm.customerFirstName;
        contactObject.lastName = vm.customerLastName;
        //Check if customer first and lastname already exist
        ContactService.getContactsWithName(vm.customerFirstName, vm.customerLastName).then(function (existingContacts) {
          if (angular.isArray(existingContacts) && existingContacts.length > 0) {
            // Show modal asking if sure to create
            UtilService.showTranslatedConfirmationModal('app.contact-exist', 'app.contact-exist-extended', function (returnValue) {
              if (returnValue) {
                vm.saveCustomerAfterCheck(customerObject, reloadOption, contactObject, customerContactObject);
              }
            });
          } else {
            vm.saveCustomerAfterCheck(customerObject, reloadOption, contactObject, customerContactObject);
          }
        });
      }
    }

    function saveCustomerAfterCheck(customerObject, reloadOption, contactObject, customerContactObject) {
      vm.isProcessingRequest = true;
      // Create the customer
      CustomerFactory.post(customerObject).then(function (newCustomer) {
        // Create a contact with same values as customer
        ContactFactory.post(contactObject).then(function (newContact) {
          // Add params to the Customer Contact Object
          customerContactObject.contact = newContact.id;
          customerContactObject.customerContactType = vm.customerContactBillingType.id;
          // Link Contact to Customer
          CustomerFactory.one(newCustomer.id)
          .one('contacts')
          .customPOST(customerContactObject)
          .then(function () {
            if (vm.customerTypeValue.organization) {
              contactObject.contactType = vm.personContactTypeObject.id;
              contactObject.lastName = vm.customerLabel;
              ContactFactory.post(contactObject).then(function (newUserContact) {
                /* eslint-disable max-nested-callbacks*/
                // Add params to the Customer Contact Object
                customerContactObject.contact = newUserContact.id;
                customerContactObject.customerContactType = vm.customerContactUserType.id;
                CustomerFactory.one(newCustomer.id)
                .one('contacts')
                .customPOST(customerContactObject)
                .then(function (newCustomerContact) {
                  // Goto the customer detail view if not POS
                  if (type !== 'POS') {
                    // Close the modal view
                    $modalInstance.close(reloadOption);
                    $state.go('customerdetail', {id: newCustomer.id});
                  } else {
                    newCustomerContact.contact = newContact;
                    // Close the modal view and return the contact data
                    $modalInstance.close({customer: newCustomer, contact: newCustomerContact});
                  }
                });
                /* eslint-enable max-nested-callbacks*/
              });
            } else {
              customerContactObject.customerContactType = vm.customerContactUserType.id;
              CustomerFactory.one(newCustomer.id)
              .one('contacts')
              .customPOST(customerContactObject)
              .then(function (newCustomerContact) {
                // Goto the customer detail view if not POS
                if (type !== 'POS') {
                  // Close the modal view
                  $modalInstance.close(reloadOption);
                  $state.go('customerdetail', {id: newCustomer.id});
                } else {
                  newCustomerContact.contact = newContact;
                  // Close the modal view and return the contact data
                  $modalInstance.close({customer: newCustomer, contact: newCustomerContact});
                }
              });
            }
          }, function (errorMsg) {
            console.error('error: ' + errorMsg);
          });
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function isEdit() {
      // Check for Edit mode
      return vm.currentCustomer !== null;
    }

    function isOrganization() {
      // Check if selected type is organization
      return vm.customerTypeValue && vm.customerTypeValue.organization;
    }
  }
}());
