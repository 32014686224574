(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:SettingsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .controller('SettingsCtrl', SettingsCtrl);

  function SettingsCtrl(
    $rootScope,
    $state,
    CurrentUserContextFactory,
    LogService,
    Restangular,
    SettingsService,
    ToastrNotificationService,
    UserFactory,
    UserMeFactory,
    UtilService
  ) {
    var vm = this;
    vm.standaloneMode = SettingsService.get('pos.standaloneMode', false);
    vm.enableBillingDivisions = !SettingsService.get('settings.disableBillingDivisions', false);
    vm.enableEmployees = !SettingsService.get('settings.disableEmployees', false);
    vm.enableAccountancy = !SettingsService.get('settings.disableAccountancy', false);
    vm.enableFacilities = !SettingsService.get('settings.disableFacilities', false);
    vm.enableKiosk = !SettingsService.get('settings.disableKiosk', false);
    vm.enableCalendar = SettingsService.get('settings.enableCalendar', false);
    vm.enablePosScreenManager = !SettingsService.get('settings.disablePosScreenManager', false);
    vm.enableSurvey = !SettingsService.get('settings.disableSurvey', false);
    vm.enableProductImport = SettingsService.get('settings.enableProductImport', false);
    vm.enableStandaloneModeToggle = SettingsService.get('settings.enableStandaloneModeToggle', false);
    vm.enableSiteSettings = SettingsService.get('settings.enableSitesSettings', false);
    vm.enableLinkOnlineDocumentation = SettingsService.get('settings.enableLinkOnlineDocumentation', false);
    vm.enableMobileContent = SettingsService.get('settings.enableMobileContent', false);
    vm.enablePromotions = SettingsService.get('settings.enablePromotions', false);
    vm.enableCustomers = SettingsService.get('settings.enableCustomers', false);
    vm.toggleStandaloneMode = toggleStandaloneMode;
    vm.importProducts = importProducts;
    vm.sendActivationMail = sendActivationMail;
    vm.openPasswordModal = openPasswordModal;
    vm.openStandaloneModal = openStandaloneModal;
    vm.importCustomers = importCustomers;

    function openPasswordModal() {
      UtilService.showModal({
        templateUrl: 'auth/views/password-change.modal.tpl.html',
        controller: 'PasswordChangeCtrl',
        size: 'md'
      });
    }

    function openStandaloneModal() {
      UtilService.showModal({
        templateUrl: 'settings/views/standalone-overview.modal.tpl.html',
        controller: 'StandaloneOverviewCtrl',
        controllerAs: 'standaloneOverviewCtrl',
        size: 'lg'
      }, null, null, true);
    }

    function toggleStandaloneMode() {
      var bodyMessage = SettingsService.get('pos.standaloneMode', false) ? 'settings.sureToDisableStandalone' : 'settings.sureToEnableStandalone';
      return new Promise(function (resolve) {
        UtilService.showTranslatedConfirmationModal('app.standalone_mode', bodyMessage, function (result) {
          if (result) {
            return SettingsService.set('pos.standaloneMode', (vm.standaloneMode ? 'false' : 'true'), CurrentUserContextFactory.getSiteId())
              .then(function () {
                SettingsService.reloadSettings().then(resolve);
              });
          }
          resolve();
        });
      });
    }

    function importProducts() {
      return Restangular.one('products/import-from-digipolis').customGET().then(function (response) {
        // show command output in modal
        UtilService.showModal({
          templateUrl: 'settings/views/settings.product-import-output.modal.tpl.html',
          controller: ['$modalInstance', function ($modalInstance) {
            this.output = response.output;
            this.modal = $modalInstance;
            this.cancel = function () {
              $modalInstance.close();
              UtilService.reloadState();
            };
          }],
          controllerAs: 'productImportOutputCtrl'
        });
      });
    }

    function sendActivationMail() {
      return UserMeFactory.one().get().then(function (me) {
        return UserFactory.one(me.id).one('resend_activation').get().then(function () {
          ToastrNotificationService.showTranslatedNotification(
            'success',
            'app.activation-mail-sended',
            ''
          );
        }, function (error) {
          LogService.log('User Resend activation error:' + error, 'debug');
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.error_occured',
            ''
          );
        });
      });
    }

    vm.importSepaBankToCustomerStatementXml = function () {
      UtilService.showModal({
        templateUrl: 'settings/views/settings.sepa-bank-to-customer-statement-xml-import.modal.tpl.html',
        controller: ['$modalInstance', function ($modalInstance) {
          var that = this;
          this.rawXml = null;
          this.preview = null;
          this.modal = $modalInstance;
          this.import = function () {
            if (UtilService.isEmpty(that.rawXml)) {
              return Promise.reject();
            }
            return Restangular.all('services/sepa-bank-to-customer-statement-xml-import').customPOST({
              xml: that.rawXml[0].base64,
              preview: (that.preview === null)
            }).then(function (result) {
              if (that.preview === null) {
                if (UtilService.isEmpty(result)) {
                  return Promise.reject();
                }
                that.preview = result;
              } else {
                if (UtilService.isEmpty(result)) {
                  return Promise.reject();
                }
                that.preview = null;
                that.rawXml = null;
              }
            });
          };
          this.cancel = function () {
            $modalInstance.close();
            UtilService.reloadState();
          };
        }],
        controllerAs: 'sepaBankToCustomerStatementXmlImportCtrl'
      });
    };

    function importCustomers() {
      UtilService.showModal({
        templateUrl: 'settings/views/settings.customer-import.modal.tpl.html',
        controller: ['$modalInstance', function ($modalInstance) {
          var that = this;
          this.rawData = null;
          this.modal = $modalInstance;
          this.import = function () {
            if (UtilService.isEmpty(that.rawData)) {
              return Promise.reject();
            }
            return Restangular.all('services/customer-csv-import').customPOST({
              csv: that.rawData[0].base64
            }).then(function () {
              that.rawData = null;
              that.cancel();
              ToastrNotificationService.showTranslatedNotification(
                'success',
                'settings.customer_imported_success',
                ''
              );
            });
          };
          this.cancel = function () {
            $modalInstance.close();
            UtilService.reloadState();
          };
        }],
        controllerAs: 'customerImportCtrl'
      });
    }
  }
}());
