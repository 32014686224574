(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ScheduledReportTypeFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .factory('ScheduledReportTypeFactory', ScheduledReportTypeFactory);

  function ScheduledReportTypeFactory(Restangular) {
    return Restangular.service('scheduled_reports/types');
  }
}());
