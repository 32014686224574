(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name auth.controller:AuthLogoutCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('auth')
    .controller('AuthLogoutCtrl', AuthLogoutCtrl);

  function AuthLogoutCtrl($state, CurrentUserContextFactory, OAuth, PermissionsUtilService, PosSessionFactory, UtilService) {
    if (angular.isUndefined(CurrentUserContextFactory.getUserContextId()) || !PermissionsUtilService.userHasPermission('manage pos session')) {
      logout();
    } else {
      PosSessionFactory.getActiveSessions(true).then(function (activeSessions) {
        if (UtilService.isNotEmpty(activeSessions)) {
          UtilService.showModal({
            templateUrl: 'auth/views/active-sessions.modal.tpl.html',
            controller: ['$modalInstance', function ($modalInstance) {
              this.sessions = activeSessions;
              this.modal = $modalInstance;
            }],
            controllerAs: 'activeSessionsCtrl'
          }, function () {
            $state.go('dashboard');
          });
        } else {
          logout();
        }
      });
    }

    function logout() {
      CurrentUserContextFactory.removeUserContextCookies();
      // attempt to delete the access token
      OAuth.revokeToken().then(function () {
        $state.go('login');
      }, function () {
        $state.go('login');
      });
    }
  }
}());
