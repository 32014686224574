(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name hwproxy.controller:HwProxyEidDataCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('hwproxy')
    .controller('HwProxyEidDataCtrl', HwProxyEidDataCtrl);

  function HwProxyEidDataCtrl(
    $rootScope,
    $modalInstance,
    $modal,
    $modalStack,
    $state,
    LogService,
    $scope,
    ContactTypeFactory,
    ContactFactory,
    ContactDataContextFactory,
    CurrentUserContextFactory,
    MemberCardTypeFactory,
    MemberCardFactory,
    CustomerTypeFactory,
    CustomerFactory,
    CustomerContactTypeFactory,
    ContactLocationFactory,
    ImageFactory,
    eidData,
    memberCardData,
    SettingsService,
    ToastrNotificationService,
    ContactTracingFactory
  ) {
    var vm = this;

    vm.$state = $state;
    vm.eidData = eidData;
    vm.memberCardData = memberCardData;

    vm.customerContact = memberCardData ? memberCardData.customerContact : null;
    vm.customer = vm.customerContact ? memberCardData.customer : null;

    vm.customerListModalInstance = null;

    vm.addCustomerToSale = addCustomerToSale;
    vm.showCustomer = showCustomer;
    vm.showCustomerListModal = showCustomerListModal;
    vm.createCustomerContact = createCustomerContact;
    vm.createCustomer = createCustomer;
    vm.cancel = cancelModalInstance;
    vm.showDifferentOriginSiteWarning = showDifferentOriginSiteWarning;
    vm.updateCustomerInfo = updateCustomerInfo;

    vm.enableCreateAndLinkButton = SettingsService.get('pos.eid.enableCreateAndLinkButton') && $state.is('pos');
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.hideEIDCardNumber = SettingsService.get('hwproxy.hideEIDCardNumber', false);
    vm.contactTracingEnabled = SettingsService.get('contactTracingEnabled', false);
    vm.warningOriginalSiteDisabled = SettingsService.get('customer.warningOriginalSiteDisabled', false);
    vm.addContactTracing = addContactTracing;

    $scope.$on('customers.selectCustomer', function (event, data) {
      vm.customer = data;
      vm.createCustomerContact(false);
    });

    function addCustomerToSale() {
      if (!vm.customerContact || !vm.customer) {
        return;
      }

      $rootScope.$broadcast('pos.setSaleCustomer', {
        customer: vm.customer,
        contact: vm.customerContact
      });

      return vm.cancel(true);
    }

    function showCustomer() {
      $state.go('customerdetail', {
        id: vm.customer.id,
        action: 'eid'
      });

      return vm.cancel(true);
    }

    function showCustomerListModal() {
      if (vm.customerListModalInstance) {
        return;
      }

      vm.customerListModalInstance = $modal.open({
        templateUrl: 'hwproxy/views/hwproxy.customer_list.modal.tpl.html',
        controller: 'HwProxyCustomerListCtrl',
        controllerAs: 'HwProxyCustomerListCtrl',
        size: 'lg'
      });

      vm.customerListModalInstance.result.then(function (returnValue) {
        LogService.log('HWProxy: Customer List Modal: Closed => reason: ' + returnValue, 'debug');
        vm.customerListModalInstance = null;
      }, function (returnValue) {
        LogService.log('HWProxy: Customer List Modal: Dismissed => reason: ' + returnValue, 'debug');
        vm.customerListModalInstance = null;
      });
    }

    function createCustomer(linkToSale) {
      var customerLabel = vm.eidData.firstName + ' ' + (vm.eidData.middleName ? vm.eidData.middleName + ' ' : '') +
          vm.eidData.name;

      return CustomerTypeFactory.getList({
        'filter[]': ['code,PERSON'],
        limit: 1
      }).then(function (customerTypeResults) {
        if (customerTypeResults.count) {
          return CustomerFactory.post({
            label: customerLabel,
            customerType: customerTypeResults[0].id
          }).then(function (customer) {
            vm.customer = customer;
            return vm.createCustomerContact(true, linkToSale).then(function (customerContact) {
              ToastrNotificationService.showTranslatedNotification('success', 'app.success', 'hwproxy.customer_created', {});
              vm.customerContact = customerContact;
              if (angular.isDefined(linkToSale) && linkToSale) {
                vm.addCustomerToSale();
              }
            });
          });
        }
      });
    }

    function createCustomerContact(includeBillingContact, linkToSale) {
      var contactLabel = vm.eidData.firstName + ' ' + (vm.eidData.middleName ? vm.eidData.middleName + ' ' : '') +
          vm.eidData.name,
          contactFirstName = vm.eidData.firstName + ' ' + (vm.eidData.middleName ? vm.eidData.middleName + ' ' : ''),
          contactLastName = vm.eidData.name,
          memberCardLabel = vm.eidData.nationalNumber,
          locationStreetAndNumber = vm.eidData.streetAndNumber,
          locationStreet = null,
          locationNumber = null,
          locationBox = null,
          matches;

      if (angular.isDefined(locationStreetAndNumber)) {
        locationStreetAndNumber = locationStreetAndNumber.trim();
        matches = (/^(.+)\s+\/([a-zA-Z0-9]+)$/g).exec(locationStreetAndNumber);

        if (matches) {
          locationBox = matches[2];
          locationStreetAndNumber = matches[1];
        }

        matches = (/^(.+)\s+([a-zA-Z0-9]+)$/g).exec(locationStreetAndNumber);

        if (matches) {
          locationNumber = matches[2];
          locationStreet = matches[1];
        }
      }

      return ContactTypeFactory.getList({
        'filter[]': ['code,PERSON'],
        limit: 1
      }).then(function (contactTypeResults) {
        if (!contactTypeResults.count) {
          return Promise.reject();
        }

        return ContactFactory.post({
          label: contactLabel,
          firstName: contactFirstName,
          lastName: contactLastName,
          contactType: contactTypeResults[0].id
        });
      }).then(function (contact) {
        return CustomerContactTypeFactory.getList({
          'filter[]': ['OR,code,USER,code,BILLING'],
          limit: 2,
          sort: 'code,ASC'
        }).then(function (customerContactTypeResults) {
          if (!customerContactTypeResults) {
            return Promise.reject();
          }

          if (includeBillingContact) {
            CustomerFactory.one(vm.customer.id).one('contacts').customPOST({
              customerContactType: customerContactTypeResults[0].id,
              contact: contact.id
            });
          }

          return CustomerFactory.one(vm.customer.id).one('contacts').customPOST({
            customerContactType: customerContactTypeResults[1].id,
            contact: contact.id
          }).then(function (customerContactUser) {
            var createContactPromises = [];

            $rootScope.$broadcast('reloadCustomerContactsListBroadcast');

            createContactPromises.push(MemberCardTypeFactory.getList({
              'filter[]': ['code,EID_CARD'],
              limit: 1
            }).then(function (memberCardTypeResults) {
              if (memberCardTypeResults.count) {
                MemberCardFactory.post({
                  customer: vm.customer.id,
                  customerContact: customerContactUser.id,
                  memberCardType: memberCardTypeResults[0].id,
                  label: memberCardLabel
                });
              }
            }));

            if (vm.eidData.dateOfBirth) {
              createContactPromises.push(ContactFactory.one(contact.id).one('data').one('birth_date').customPOST({
                date: vm.eidData.dateOfBirth
              }));
            }

            createContactPromises.push(ContactFactory.one(contact.id).one('data').one('social_security_number').customPOST({
              string: vm.eidData.nationalNumber
            }));

            if (vm.eidData.gender) {
              createContactPromises.push(ContactFactory.one(contact.id).one('data').one('gender').customPOST({
                gender: vm.eidData.gender === 'MALE' ? 'M' : 'F'
              }));
            }

            new Promise(function (resolve) {
              if (vm.eidData.photoString) {
                createContactPromises.push(ImageFactory.post({
                  label: memberCardLabel + '_eID_Photo',
                  encoding: 'base64',
                  data: vm.eidData.photoString
                }).then(function (photo) {
                  ContactFactory.one(contact.id).one('photos').customPOST({
                    photo: photo.id
                  }).then(resolve);
                }));
              } else {
                resolve();
              }
            }).then(function () {
              // Get all Contact Data Contexts
              return ContactDataContextFactory.getList({limit: 99, 'filter[]': ['code,HOME']}).then(function (resultContexts) {
                vm.contactHomeContext = resultContexts[0];

                if ((angular.isUndefined(linkToSale) || !linkToSale) && (locationStreet === null || locationNumber === null)) {
                  return vm.showCustomer();
                }

                return ContactLocationFactory.post({
                  street: locationStreet,
                  number: locationNumber,
                  box: locationBox,
                  postalCode: vm.eidData.zip,
                  city: vm.eidData.municipality,
                  countryCode: 'BE'
                }).then(function (location) {
                  return ContactFactory.one(contact.id).one('locations').customPOST({
                    contactDataContext: vm.contactHomeContext.id,
                    location: location.id
                  });
                }).then(function () {
                  return (angular.isUndefined(linkToSale) || !linkToSale) ? vm.showCustomer() : Promise.resolve();
                });
              });
            });

            return Promise.all(createContactPromises).then(function () {
              return customerContactUser;
            });
          });
        });
      });
    }

    function cancelModalInstance(cancelWholeStack) {
      if (angular.isDefined(cancelWholeStack) && cancelWholeStack) {
        return $modalStack.dismissAll();
      }

      return $modalInstance.dismiss('cancel');
    }

    function showDifferentOriginSiteWarning() {
      return (CurrentUserContextFactory.getSiteId() && this.customer && this.customer.originSite &&
        CurrentUserContextFactory.getSiteId() !== this.customer.originSite.id);
    }

    function addContactTracing() {
      var firstName = vm.eidData.firstName + ' ' + (vm.eidData.middleName ? vm.eidData.middleName + ' ' : ''),
          name = vm.eidData.name,
          socialSecurityNumber = vm.eidData.nationalNumber,
          zipCode = vm.eidData.zip,
          city = vm.eidData.municipality,
          address = vm.eidData.streetAndNumber;

      ContactTracingFactory.post({
        name: name,
        firstName: firstName,
        address: address,
        city: city,
        zipCode: zipCode,
        socialSecurityNumber: socialSecurityNumber,
        site: CurrentUserContextFactory.getSiteId()
      });

      return vm.cancel();
    }

    function updateCustomerInfo() {
      var promiseQueue = [],
          contactLabel = vm.eidData.firstName + ' ' + (vm.eidData.middleName ? vm.eidData.middleName + ' ' : '') +
          vm.eidData.name,
          contactFirstName = vm.eidData.firstName + ' ' + (vm.eidData.middleName ? vm.eidData.middleName + ' ' : ''),
          contactLastName = vm.eidData.name,
          locationStreetAndNumber = vm.eidData.streetAndNumber,
          locationStreet = null,
          locationNumber = null,
          locationBox = null,
          matches;
      return ContactFactory.one(vm.customerContact.contact.id).get().then(function (contactDetails) {
        //update customer label
        promiseQueue.push(CustomerFactory.one(vm.customerContact.customer.id).patch({label: contactLabel}));
        //update contact
        promiseQueue.push(ContactFactory.one(contactDetails.id).patch({
          label: contactLabel,
          firstName: contactFirstName,
          lastName: contactLastName
        }));
        //update contact data
        angular.forEach(contactDetails.contactData, function (contactData) {
          switch (contactData.contactDataType.code) {
            case 'SOCIAL_SECURITY_NUMBER':
              promiseQueue.push(ContactFactory.one(contactDetails.id).one('data').one(contactData.id).patch({
                string: vm.eidData.nationalNumber
              }));
              break;

            case 'GENDER':
              if (vm.eidData.gender) {
                promiseQueue.push(ContactFactory.one(contactDetails.id).one('data').one(contactData.id).patch({
                  gender: vm.eidData.gender === 'MALE' ? 'M' : 'F'
                }));
              }
              break;

            case 'BIRTH_DATE':
              if (vm.eidData.dateOfBirth) {
                promiseQueue.push(ContactFactory.one(contactDetails.id).one('data').one(contactData.id).patch({
                  date: vm.eidData.dateOfBirth
                }));
              }
              break;

            default:
              LogService.log('no match', 'debug');
              break;
          }
        });
        //update contact location
        angular.forEach(contactDetails.contactLocations, function (locationData) {
          switch (locationData.contactDataContext.code) {
            case 'HOME':
              if (angular.isDefined(locationStreetAndNumber)) {
                locationStreetAndNumber = locationStreetAndNumber.trim();
                matches = (/^(.+)\s+\/([a-zA-Z0-9]+)$/g).exec(locationStreetAndNumber);

                if (matches) {
                  locationBox = matches[2];
                  locationStreetAndNumber = matches[1];
                }

                matches = (/^(.+)\s+([a-zA-Z0-9]+)$/g).exec(locationStreetAndNumber);

                if (matches) {
                  locationNumber = matches[2];
                  locationStreet = matches[1];
                }
              }

              promiseQueue.push(ContactLocationFactory.one(locationData.location.id).patch({
                street: locationStreet,
                number: locationNumber,
                box: locationBox,
                postalCode: vm.eidData.zip,
                city: vm.eidData.municipality,
                countryCode: 'BE'
              }));
              break;

            default:
              LogService.log('no match', 'debug');
              break;
          }
        });

        return Promise.all(promiseQueue).then(function () {
          return vm.customerContact;
        });
      });
    }
  }
}());
