(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.factory:UserContextFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .factory('UserContextFactory', UserContextFactory);

  function UserContextFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'userContext'});
    })
    .service('user_contexts');
  }
}());
