(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:SurveyQueryService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .service('SurveyQueryService', SurveyQueryService);

  function SurveyQueryService(
    UtilService,
    RestUtilService,
    Restangular,
    SurveyQueryFactory,
    CurrentUserContextFactory,
    LogService
  ) {
    var self = this;

    self.surveyQueryService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'surveyQuery'});
    })
    .service('survey_queries');

    this.one = function (fragment) {
      return this.surveyQueryService.one(fragment);
    };

    this.getList = function (params, getFullList, filters) {
      getFullList = (typeof getFullList === 'boolean') ? getFullList : false;
      params = angular.isObject(params) ? params : {};
      if (!UtilService.isInteger(params.limit)) {
        params.limit = 99;
      }

      if (UtilService.isNotEmpty(filters)) {
        // construct filter strings and attach them to the query params object
        RestUtilService.attachFilterStrings(params, filters);
      }

      if (getFullList) {
        return RestUtilService.getFullList(this.surveyQueryService, params);
      }
      return this.surveyQueryService.getList(params);
    };

    this.getFullList = function (params, filters) {
      params = angular.isObject(params) ? params : {};
      filters = angular.isObject(filters) ? filters : {};
      return this.getList(params, true, filters);
    };

    this.getSurveyQuery = function (queryId) {
      return this.one(queryId).get();
    };

    this.getPage = function (pageNumber, itemsPerPage, filters) {
      var queryParams = {
        offset: (pageNumber - 1) * itemsPerPage,
        limit: itemsPerPage,
        sort: 'updatedAt,DESC'
      };

      return this.getList(queryParams, false, filters);
    };

    this.patchSurveyQuery = function (id, params) {
      return this.one(id).patch(params);
    };

    this.postSurveyQuery = function (params) {
      return this.surveyQueryService.post(params);
    };

    this.removeSurveyQuery = function (id) {
      return this.one(id).remove();
    };

    // accepts a survey query object and performs post or patch depending on wether the object contains an id or not
    this.saveSurveyQuery = function (surveyQuery) {
      var id, restObject;

      LogService.log(['Persisting', surveyQuery], 'debug', 'SurveyQueryService::saveSurveyQuery');

      if (UtilService.isNotEmpty(surveyQuery.id)) {
        // site is only set on initial post call, so remove it here if we already have an id
        if (UtilService.isNotEmpty(surveyQuery.site)) {
          delete surveyQuery.site;
        }

        id = surveyQuery.id;
        delete surveyQuery.id;
      } else if (UtilService.isEmpty(surveyQuery.site)) {
        // the factory should have already taken care of this for us, but it
        // can't hurt to verify I suppose.
        surveyQuery.site = CurrentUserContextFactory.getSiteId();
      }

      restObject = SurveyQueryFactory.createRestObject(surveyQuery);
      return UtilService.isNotEmpty(id) ? self.patchSurveyQuery(id, restObject) : self.postSurveyQuery(restObject);
    };

    this.activateSurveyQuery = function (queryId) {
      return this.patchSurveyQuery(queryId, {active: true});
    };

    this.deactivateSurveyQuery = function (queryId) {
      return this.patchSurveyQuery(queryId, {active: false});
    };

    this.getActiveQueries = function () {
      var currentISODate = (new Date()).toISOString().substr(0, 10),
          params = {};
      params['filter[]'] = [
        'active,TRUE',
        'OR,startDate,NULL,startDate,LTE ' + currentISODate,
        'OR,endDate,NULL,endDate,GTE ' + currentISODate
      ];

      return this.getFullList(params);
    };

    this.postSurveyAnswer = function (saleId, surveyId, answer) {
      return this.one(surveyId).one('answers').customPOST({
        sale: saleId,
        answer: answer
      });
    };

    this.getSurveyAnswers = function (surveyId, params) {
      return RestUtilService.getFullList(this.one(surveyId), params, 'answers');
    };
  }
}());
