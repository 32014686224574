(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:MobileContentCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('MobileContentCtrl', MobileContentCtrl);

  function MobileContentCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    MobileCategoryFactory,
    MobileContentFactory
  ) {
    var vm = this;
    vm.categories = [];
    vm.content = [];
    vm.category = null;
    vm.toBeDeleted = [];
    vm.activeOnly = false;

    vm.loadMobileContent = loadMobileContent;
    vm.loadCategories = loadCategories;
    vm.save = save;
    vm.showModalAddEditView = showModalAddEditView;
    vm.deleteMobileContentRecord = deleteMobileContentRecord;
    vm.contentIsActive = contentIsActive;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    vm.showModalDeleteView = function (mobileContent) {
      UtilService.showFormattedConfirmationModal('app.delete_item', 'app.mobile_content', 'app.delete-msg', 'app.this_mobile_content',
        function (returnValue) {
          if (returnValue) {
            vm.deleteMobileContentRecord(mobileContent);
          }
        });
    };

    vm.loadCategories();

    function loadCategories() {
      MobileCategoryFactory.getList({limit: 99}).then(function (result) {
        var categories = {};

        result.forEach(function (category) {
          categories[category.id] = category;
        });

        vm.categories = categories;
      }, function () {
        return {};
      });
    }

    function loadMobileContent() {
      if (vm.category) {
        MobileContentFactory.getList({
          limit: 99,
          category: vm.category.label,
          activeOnly: vm.activeOnly
        }).then(function (result) {
          vm.content = result;
        });
      } else {
        vm.content = [];
      }
    }

    function save() {
      var promises = [];

      vm.content.forEach(function (contentItem, index) {
        if (angular.isDefined(contentItem.id)) {
          promises.push(MobileContentFactory.one(contentItem.id).patch({
            weight: index
          }));
        }
      });

      return $q.all(promises).then(function () {
        vm.loadMobileContent();
        ToastrNotificationService.showTranslatedNotification('success', 'app.success', '');
      });
    }

    function showModalAddEditView(mobileContent) {
      UtilService.showModal(
        {
          templateUrl: 'mobile/views/mobile.content.addedit.modal.tpl.html',
          controller: 'MobileContentAddEditCtrl',
          controllerAs: 'mobileContentAddEditCtrl',
          size: 'lg',
          resolve: {
            mobileContent: function () {
              return mobileContent;
            }
          }
        },
        function () {
          vm.loadMobileContent();
        }
      );
    }

    function deleteMobileContentRecord(mobileContentRecord) {
      MobileContentFactory.one(mobileContentRecord.id).remove().then(function () {
        vm.loadMobileContent();
        ToastrNotificationService.showNotification(
          'success',
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-removed'),
              $filter('translate')('app.mobile_content')
            )
          ),
          $filter('uconlyfirst')(
            $filter('sprintf')(
              $filter('translate')('app.item-successfully-removed'),
              $filter('translate')('app.mobile_content')
            )
          )
        );
      });
    }

    function contentIsActive(content) {
      var startDate = new Date(content.startDate),
          endDate = new Date(content.endDate),
          currentDate = new Date();

      return startDate <= currentDate && currentDate <= endDate;
    }
  }
}());
