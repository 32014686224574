(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosSessionFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PosSessionFactory', PosSessionFactory);

  function PosSessionFactory(
    $cookies,
    LogService,
    $rootScope,
    $filter,
    PosGroupFactory,
    hwproxy,
    UtilService,
    SaleFactory,
    CurrentPosInstanceFactory,
    RestUtilService,
    Restangular,
    PaymentMethodsFactory,
    ToastrNotificationService,
    _,
    SettingsService
  ) {
    var posSessionService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'posSession'});
    })
    .service('points_of_sale/sessions');

    return {
      getList: function (params) {
        return posSessionService.getList(params).then(function (sessions) {
          angular.forEach(sessions, function (session) {
            session.wasTallied = session.hasOwnProperty('endAmount');
            session.posGroupId = session.pointOfSaleInstance.pointOfSaleGroup.id;
            session.posInstanceId = session.pointOfSaleInstance.id;
          });
          return sessions;
        });
      },
      startNewPosSession: function (posGroupId, posInstanceId, startAmount) {
        var params = {
          startAmount: startAmount
        };

        LogService.log('preparing to start new pos session', 'debug');

        return PosGroupFactory.one(posGroupId)
        .get()
        .then(function (selectedGroup) {
          return selectedGroup.one('instances').one(posInstanceId)
          .get()
          .then(function (selectedInstance) {
            // Post the new pos session
            return PosGroupFactory.one(posGroupId)
            .one('instances').one(posInstanceId)
            .post('sessions', params)
            .then(function (session) {
              LogService.log('Created pos session' + session.id, 'debug');
              $cookies.put('currentPosSessionId', session.id);
            }, function () {
              LogService.log('Could not create new pos session', 'debug');
              return Promise.reject();
            })
            .then(function () {
              // Open connection to HWproxy if required
              if (selectedInstance.hardwareProxyUrl) {
                hwproxy.options.posPrinter.type = selectedInstance.hardwareProxyPosPrinterType || null;
                hwproxy.options.posPrinter.receiptFooter = selectedGroup.pointOfSalePrinterReceiptFooter || null;
                hwproxy.options.posDisplay.standbyMessage = selectedGroup.pointOfSalePoleDisplayStandbyMessage || null;
                hwproxy.connect(selectedInstance.hardwareProxyUrl);
              } else {
                hwproxy.disconnect();
              }
            })
            .then(function () {
              LogService.log('broadcasting refresh cookies message', 'debug');
              $rootScope.$broadcast('refreshCookiesBroadcast');
            });
          }, function () {
            LogService.log('could not retrieve instance' + posInstanceId, 'debug');
            return Promise.reject();
          });
        }, function () {
          LogService.log('could not retrieve group' + posGroupId, 'debug');
          return Promise.reject();
        });
      },
      getActiveSessions: function (onlyMine) {
        var params = {
          limit: 99,
          sort: 'createdAt,DESC',
          'filter[]': [
            'endAmount,NULL'
          ]
        };
        // look for sessions from this user context
        if (UtilService.isNotEmpty(onlyMine) && onlyMine) {
          params['filter[]'].push('userContext.id,' + $cookies.get('currentContextId'));
        }

        return RestUtilService.getFullList(posSessionService, params);
      },
      getSessionsFromPosInstance: function (posGroupId, posInstanceId, onlyMine, onlyActive) {
        var sessions = [],
            params = {
              limit: 99
            },
            posInstanceAdministrativePropertyEnabled = SettingsService.get('posInstanceAdministrativePropertyEnabled', false);

        params['filter[]'] = [];

        // look for sessions from this user context
        if (UtilService.isNotEmpty(onlyMine) && onlyMine) {
          params['filter[]'].push('userContext.id,' + $cookies.get('currentContextId'));
        }

        // look for active sessions
        if (UtilService.isNotEmpty(onlyActive) && onlyActive) {
          params['filter[]'].push('endAmount,NULL');
        }

        if (posInstanceAdministrativePropertyEnabled) {
          params['filter[]'].push('pointOfSaleInstance.administrative,0');
        }

        return RestUtilService.getFullList(
          PosGroupFactory.one(posGroupId).one('instances').one(posInstanceId),
          params,
          'sessions'
        )
        .then(function (result) {
          // Do some additional tagging on the session objects to make them easier to use
          angular.forEach(result, function (currentSession) {
            currentSession.wasTallied = currentSession.hasOwnProperty('endAmount');
            currentSession.posGroupId = posGroupId;
            currentSession.posInstanceId = posInstanceId;
            sessions.push(currentSession);
          });
          return sessions;
        }, function () {
          LogService.log('could not get posgroup' + posGroupId, 'debug');
          return Promise.reject();
        });
      },
      takeOverPosSession: function (posInstanceId, posSessionId) {
        var params = {},
            posCookies,
            posMergeSalesEnabled = SettingsService.get('pos.mergeSalesEnabled', true),
            that = this;

        LogService.log('Attempting to take over session' + posSessionId, 'debug');

        if (CurrentPosInstanceFactory.validatePosCookies()) {
          posCookies = CurrentPosInstanceFactory.getInstanceCookies();

          if (CurrentPosInstanceFactory.isInstanceSelected()) {
            // Get the old session and retrieve the start amount
            return PosGroupFactory.one(posCookies.posGroupId)
            .one('instances').one(posInstanceId)
            .one('sessions').one(posSessionId)
            .get()
            .then(function (retrievedSession) {
              params.startAmount = retrievedSession.startAmount;
              // create a new pos session for this instance (with startAmount = old session startamount)
              return PosGroupFactory.one(posCookies.posGroupId)
              .one('instances').one(posCookies.posInstanceId)
              .post('sessions', params)
              .then(function (newSession) {
                LogService.log('Created the new session', 'debug');
                $cookies.put('currentPosSessionId', newSession.id);
                if (posMergeSalesEnabled) {
                  LogService.log('Created the new session, transferring sales', 'debug');
                  // transfer from instance to instance, from session to session, delete old session after remove
                  return that.transferSessionSales(posSessionId, newSession.id)
                  .then(that.forcePosSessionClose(posInstanceId, posSessionId));
                }
                return that.forcePosSessionClose(posInstanceId, posSessionId);
              }, function () {
                LogService.log('Something went wrong while creating the new session!', 'debug');
                return Promise.reject();
              });
            }, function () {
              LogService.log('Could not find the session!', 'debug');
              return Promise.reject();
            });
          }

          // the pos cookies were valid, but no instance was selected
          LogService.log('Cannot take over session because no instance is selected.', 'debug');
          return Promise.reject();
        }

        // the pos cookies were not valid
        return Promise.reject();
      },
      forcePosSessionClose: function (instanceId, sessionId) {
        var groupId = CurrentPosInstanceFactory.getInstanceCookies().posGroupId,
            posMergeSalesEnabled = SettingsService.get('pos.mergeSalesEnabled', true),
            takeOverText = '',
            useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false),
            approvalFlowVersion4Enabled = (SettingsService.get('pos.session.approvalFlowVersion') === '4'),
            sessionForClose = null,
            that = this;
        LogService.log('PosSessionFactory::forcePosSessionClose() -> Closing session' + sessionId, 'debug');

        return PosGroupFactory.one(groupId)
        .one('instances', CurrentPosInstanceFactory.getInstanceCookies().posInstanceId)
        .get()
        .then(function (currentInstance) {
          if (posMergeSalesEnabled) {
            takeOverText = $filter('uconlyfirst')($filter('sprintf')(
              $filter('translate')('pos.session_resumed_on_instance'),
              currentInstance.label
            ));
          }

          return PosGroupFactory.one(groupId)
          .one('instances', instanceId)
          .get()
          .then(function (instance) {
            return instance.one('sessions', sessionId)
            .get()
            .then(function (session) {
              sessionForClose = session;
              return session.one('balance')
              .get()
              .then(function (balance) {
                var endAmount = UtilService.isNotEmpty(balance.projectedAmount.EUR) ? balance.projectedAmount.EUR : 0;
                return session.patch({
                  endAmount: endAmount,
                  comments: takeOverText
                })
                .then(function () {
                  // log as send to order engine if need
                  if (approvalFlowVersion4Enabled && useExternalOrderEngine && sessionForClose && !sessionForClose.pointOfSaleInstance.approvalFlowVersion4) {
                    sessionForClose.posInstanceId = sessionForClose.pointOfSaleInstance.id;
                    session.posGroupId = sessionForClose.pointOfSaleInstance.pointOfSaleGroup.id;
                    return that.logAsSentToOrderEngine(sessionForClose).then(function () {
                      LogService.log('PosSessionFactory::forcePosSessionClose() -> Closed session with projected amount' + endAmount, 'debug');
                    });
                  }
                  LogService.log('PosSessionFactory::forcePosSessionClose() -> Closed session with projected amount' + endAmount, 'debug');
                });
              });
            });
          });
        });
      },
      getPaymentMethodByCode: function (code) {
        var params = {
          limit: 99
        };
        params['filter[]'] = 'code,' + code.toUpperCase();

        return PaymentMethodsFactory.getList(params)
        .then(function (result) {
          if (result.count) {
            return _.first(result);
          }

          return Promise.reject('Unknown payment method code passed to PosSessionFactory.getPaymentMethodByCode().');
        });
      },
      persistSessionPaymentTotals: function (posGroupId, posInstanceId, sessionId, paymentTotals, startAmount) {
        var that = this,
            endAmount = 0,
            promises = [];

        LogService.log('Preparing to persist POS session payment totals.', 'debug');
        // start counting for startAmount if it was supplied as a parameter
        if (UtilService.isNotEmpty(startAmount) && angular.isNumber(startAmount)) {
          endAmount = parseFloat(startAmount);
        }

        // persist payments asynchronously, then persist the end amount
        angular.forEach(paymentTotals, function (paymentTotal) {
          endAmount += parseFloat(paymentTotal.amount);
          promises.push(that.persistSessionPaymentObject(posGroupId, posInstanceId, sessionId, paymentTotal));
        });
        return Promise.all(promises).then(function () {
          return that.persistPosSessionEndAmount(posGroupId, posInstanceId, sessionId, endAmount);
        }, function () {
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.conflict',
            'app.conflict-message'
          );
          return Promise.reject();
        });
      },
      persistPosSessionEndAmount: function (posGroupId, posInstanceId, sessionId, endAmount) {
        LogService.log('PosSessionFactory::postSessionPaymentObject() -> Posting end amount' + endAmount + 'to session' + sessionId, 'debug');
        return PosGroupFactory.one(posGroupId)
          .one('instances').one(posInstanceId)
          .one('sessions').one(sessionId)
          .patch({
            endAmount: endAmount
          });
      },
      persistSessionPaymentObject: function (posGroupId, posInstanceId, sessionId, paymentObject) {
        var id = null;
        LogService.log('PosSessionFactory::postSessionPaymentObject() -> Preparing to persist session payment object' + paymentObject, 'debug');
        // persist a session payment, selecting patch or post where appropriate
        if (UtilService.isNotEmpty(paymentObject.id)) {
          LogService.log('id found, using patch method.', 'debug');
          id = paymentObject.id;
          delete paymentObject.id;
          return this.patchSessionPaymentObject(posGroupId, posInstanceId, sessionId, id, paymentObject);
        }

        LogService.log('no id found, using post method.', 'debug');
        return this.postSessionPaymentObject(posGroupId, posInstanceId, sessionId, paymentObject);
      },
      patchSessionPaymentObject: function (posGroupId, posInstanceId, sessionId, paymentId, originalObject) {
        var that = this,
            paymentObject = Object.assign({}, originalObject);

        LogService.log('PosSessionFactory::postSessionPaymentObject() -> Patching payment' + paymentId, 'debug');

        function patch(object) {
          return PosGroupFactory.one(posGroupId)
          .one('instances').one(posInstanceId)
          .one('sessions').one(sessionId)
          .one('payments').one(paymentId)
          .patch(object);
        }

        // set currency to EUR by default
        if (!paymentObject.hasOwnProperty('currency')) {
          paymentObject.currency = 'EUR';
        }

        // set amount as float
        if (paymentObject.hasOwnProperty('amount')) {
          paymentObject.amount = parseFloat(paymentObject.amount);
        }

        // translate payment method code to id
        if (paymentObject.hasOwnProperty('code')) {
          return that.getPaymentMethodByCode(paymentObject.code)
          .then(function (paymentMethod) {
            delete paymentObject.code;
            paymentObject.paymentMethod = paymentMethod.id;

            return patch(paymentObject);
          });
        }

        // no translation needed
        return patch(paymentObject);
      },
      postSessionPaymentObject: function (posGroupId, posInstanceId, sessionId, originalObject) {
        var that = this,
            paymentObject = Object.assign({}, originalObject);

        LogService.log('PosSessionFactory::postSessionPaymentObject() -> Posting payment.', 'debug');
        // we can't send an id field to the backend
        if (paymentObject.hasOwnProperty('id')) {
          delete paymentObject.id;
        }

        function post(object) {
          return PosGroupFactory.one(posGroupId)
          .one('instances').one(posInstanceId)
          .one('sessions').one(sessionId)
          .post('payments', object);
        }

        // set currency to EUR by default
        if (!paymentObject.hasOwnProperty('currency')) {
          paymentObject.currency = 'EUR';
        }

        // set amount as float
        if (paymentObject.hasOwnProperty('amount')) {
          paymentObject.amount = parseFloat(paymentObject.amount);
        }

        // translate payment method code to id
        if (paymentObject.hasOwnProperty('code')) {
          return that.getPaymentMethodByCode(paymentObject.code)
          .then(function (paymentMethod) {
            delete paymentObject.code;
            paymentObject.paymentMethod = paymentMethod.id;

            return post(paymentObject);
          });
        }

        // no translation needed
        return post(paymentObject);
      },
      transferSessionSales: function (fromSessionId, toSessionId) {
        var params = {
          limit: 99
        };

        LogService.log('Preparing take over sales from session' + fromSessionId, 'debug');

        // collect sales from the old session
        params['filter[]'] = ['pointOfSaleSession.id,' + fromSessionId];

        return RestUtilService.getFullList(SaleFactory, params)
        .then(function (sessionSales) {
          LogService.log('Found ' + sessionSales.length + ' sales.', 'debug');

          return UtilService.promiseLoop(sessionSales,
            function (sale) {
              return sale.patch({
                pointOfSaleSession: toSessionId
              });
            }
          )
          .then(function () {
            LogService.log('Sales transfer complete.', 'debug');
          });
        });
      },
      getActivePosSession: function (checkOtherInstances) {
        var that = this;

        // default to true if parameter was not supplied
        if (!(UtilService.isNotEmpty(checkOtherInstances))) {
          checkOtherInstances = true;
        }

        return new Promise(function (resolve) {
          var cookies = CurrentPosInstanceFactory.getInstanceCookies(),
              posInstanceId = cookies.posInstanceId,
              posGroupId = cookies.posGroupId,
              foundSessions = [];

          function checkForExistingPosSession() {
            var params = {
              limit: 99
            };

            LogService.log('PosSessionFactory::getActivePosSession() -> Searching in current instance.', 'debug');
            // get sessions from this posInstance (only mine && only active)
            that.getSessionsFromPosInstance(posGroupId, posInstanceId, true, true)
            .then(function (activeSessionsCurrentInstance) {
              if (activeSessionsCurrentInstance.length) {
                LogService.log('PosSessionFactory::getActivePosSession() -> Found active session(s) for the current instance', 'debug');
                if (activeSessionsCurrentInstance.length > 1) {
                  LogService.log('PosSessionFactory::getActivePosSession() -> FOUND ' + activeSessionsCurrentInstance.length + ' SESSIONS FOR THE CURRENT INSTANCE!', 'debug');
                }
                activeSessionsCurrentInstance[0].posGroupId = posGroupId;
                activeSessionsCurrentInstance[0].posInstanceId = posInstanceId;
                resolve(activeSessionsCurrentInstance[0]);
              } else if (checkOtherInstances) {
                LogService.log('PosSessionFactory::getActivePosSession() -> No session(s) found for current instance, checking other instances', 'debug');
                foundSessions = [];

                // collect the instances for this posgroup and the active sessions attached to them
                PosGroupFactory.one(posGroupId)
                .getList('instances', params)
                .then(function (groupInstances) {
                  UtilService.promiseLoop(groupInstances, function (currentInstance) {
                    return that.getSessionsFromPosInstance(posGroupId, currentInstance.id, true, true)
                      .then(function (activeSessionsOtherInstance) {
                        activeSessionsOtherInstance.posGroupId = posGroupId;
                        activeSessionsOtherInstance.posInstanceId = currentInstance.id;
                        foundSessions = foundSessions.concat(activeSessionsOtherInstance);
                      });
                  })
                  .then(function () {
                    // when we're doing scouring the backend for active sessions
                    // in this posgroup, either take over the session or create a new one
                    LogService.log('PosSessionFactory::getActivePosSession() -> Done checking other instances.', 'debug');
                    if (foundSessions.length > 0) {
                      LogService.log('PosSessionFactory::getActivePosSession() -> Found active session(s) for another instance.', 'debug');
                      if (foundSessions.length > 1) {
                        LogService.log('PosSessionFactory::getActivePosSession() -> FOUND ' + foundSessions.length + ' SESSIONS FOR ANOTHER INSTANCE!', 'debug');
                      }
                      resolve(foundSessions[0]);
                    } else {
                      LogService.log('PosSessionFactory::getActivePosSession() -> No active sessions found.', 'debug');
                      resolve(null);
                    }
                  });
                });
              } else {
                LogService.log('PosSessionFactory::getActivePosSession() -> No active sessions found for this instance and checkOtherInstances flag set to false.', 'debug');
                resolve(null);
              }
            });
          }

          if (CurrentPosInstanceFactory.isInstanceSelected()) {
            checkForExistingPosSession();
          } else {
            LogService.log('PosSessionFactory::getActivePosSession() -> No POS-instance selected, cannnot check for active session.', 'debug');
            resolve(null);
          }
        });
      },
      getPosSessionPayments: function (posGroupId, posInstanceId, sessionId, params) {
        if (!UtilService.isNotEmpty(params)) {
          params = {
            limit: 99
          };
        }

        return PosGroupFactory
          .one(posGroupId)
          .one('instances').one(posInstanceId)
          .one('sessions').one(sessionId)
          .getList('payments', params);
      },
      remove: function (session) {
        return Restangular
          .one('points_of_sale')
          .one('groups')
          .one(session.posGroupId)
          .one('instances')
          .one(session.posInstanceId)
          .one('sessions')
          .one(session.id)
          .remove();
      },
      setCashierStatus: function (session) {
        return Restangular
          .one('points_of_sale')
          .one('groups')
          .one(session.posGroupId)
          .one('instances')
          .one(session.posInstanceId)
          .one('sessions')
          .one(session.id)
          .post('set_cashier_status');
      },
      logAsSentToOrderEngine: function (session) {
        return Restangular
          .one('points_of_sale')
          .one('groups')
          .one(session.posGroupId)
          .one('instances')
          .one(session.posInstanceId)
          .one('sessions')
          .one(session.id)
          .post('log_as_sent_to_order_engine');
      }
    };
  }
}());
