(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name calendar.controller:EventDetailCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('calendar')
    .controller('EventDetailCtrl', EventDetailCtrl);

  function EventDetailCtrl(
    $modalInstance,
    eventInstance,
    EventInstanceService
  ) {
    var vm = this;
    vm.cancel = cancel;
    vm.eventInstance = eventInstance;
    vm.duration = EventInstanceService.getDuration(vm.eventInstance);
    if (vm.eventInstance.event.fitnessCourses[0].tags.length > 0) {
      vm.color = vm.eventInstance.event.fitnessCourses[0].tags[0].color;
    } else {
      vm.color = null;
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
