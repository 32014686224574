(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosScreenViewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosScreenViewCtrl', PosScreenViewCtrl);

  function PosScreenViewCtrl(
    screen,
    _,
    $filter
  ) {
    var vm = this;
    vm.items = [];
    vm.rows = [];
    vm.screen = screen;

    _.each(_.sortBy(screen.pointOfSaleItems, ['row', 'column']), function (item) {
      if (angular.isUndefined(vm.rows[--item.row])) {
        vm.rows[item.row] = {items: []};
      }
      item.label = $filter('uconlyfirst')($filter('translate')('pos.destinations.blank'));
      item.type = 'blank';
      item.color = null;
      if (angular.isDefined(item.tags[0])) {
        item.color = item.tags[0];
      }
      if (_.isObject(item.targetScreen)) {
        item.label = item.targetScreen.label;
        item.type = 'screen';
      }
      if (_.isObject(item.product)) {
        item.label = item.product.translatedLabel;
        item.type = 'product';
      }
      vm.rows[item.row].items.push(item);
    });
  }
}());
