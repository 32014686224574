(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ReportsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('ReportsCtrl', ReportsCtrl);

  function ReportsCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    ToastrNotificationService,
    PARAMS
  ) {
    var vm = this,
        sessionsTab = {
          label: 'app.pos.sessions',
          template: 'reports/views/reports.sessions.tab.tpl.html',
          active: false
        },
        digipolisOrdersTab = {
          label: 'accountancy.sale_order_engine_transactions',
          template: 'reports/views/reports.digipolis_orders.tab.tpl.html',
          active: false
        },
        digipolisIndividualOrderTab = {
          label: 'accountancy.individually_forwared_orders_invoice',
          template: 'reports/views/reports.digipolis_orders.tab.tpl.html',
          active: false
        },
        digipolisRePaymentsTab = {
          label: 'accountancy.sale_order_engine_re_payments',
          template: 'reports/views/reports.digipolis_re_payments.tab.tpl.html',
          active: false
        },
        digipolisBankTransfersTab = {
          label: 'accountancy.sale_order_engine_bank_transfers',
          template: 'reports/views/reports.digipolis_bank_transfers.tab.tpl.html',
          active: false
        },
        generateTab = {
          label: 'app.generate_reports',
          template: 'reports/views/reports.generate.tab.tpl.html',
          active: false
        },
        scheduledReportsTab = {
          label: 'app.scheduled-reports',
          template: 'reports/views/reports.scheduled_reports.tab.tpl.html',
          active: false
        },
        generateTabFirst = true;

    vm.generateReport = generateReport;
    vm.reportSelected = reportSelected;
    vm.billingDivisions = [];
    vm.reportType = null;
    vm.currentUserContext = CurrentUserContextFactory.getUserContextCookies();
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.enableRequestInvoiceCheckbox = SettingsService.get('pos.enableRequestInvoiceCheckbox', false);
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3' || SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.newDesignVersion = PARAMS.designVersion === 2;
    vm.showReportNotification = showReportNotification;

    vm.reloadBillingDivisions = function () {
      LogService.log('ReportsCtrl::reloadBillingDivisions() -> Loading full list of billing divisions.', 'debug');
      RestUtilService.getFullList(BillingDivisionFactory)
        .then(function (results) {
          vm.billingDivisions = results;
        });
    };

    vm.tabs = {};
    generateTabFirst = !vm.useExternalOrderEngine && PermissionsUtilService.userHasPermission('access report section');
    if (generateTabFirst) {
      vm.tabs.generate = generateTab;
    }
    if (PermissionsUtilService.userHasPermission('manage reports')) {
      vm.tabs.sessions = sessionsTab;
    }
    if (vm.useExternalOrderEngine && PermissionsUtilService.userHasPermission('notify order engine') && !vm.automaticApprovalFlow) {
      vm.tabs.digipolisOrders = digipolisOrdersTab;
    }
    if (vm.useExternalOrderEngine && PermissionsUtilService.userHasPermission('notify order engine') && vm.enableRequestInvoiceCheckbox) {
      vm.tabs.digipolisIndividualOrderTab = digipolisIndividualOrderTab;
    }
    if (vm.useExternalOrderEngine && PermissionsUtilService.userHasPermission('manage accountancy') && !vm.automaticApprovalFlow) {
      vm.tabs.digipolisRePayments = digipolisRePaymentsTab;
      vm.tabs.digipolisBankTransfers = digipolisBankTransfersTab;
    }
    if (vm.useExternalOrderEngine && PermissionsUtilService.userHasPermission('access report section')) {
      vm.tabs.generate = generateTab;
    }
    if (PermissionsUtilService.userHasPermission('access report section') && PermissionsUtilService.userHasPermission('manage reports')) {
      vm.tabs.scheduledReports = scheduledReportsTab;
    }

    if ($stateParams.tab && vm.tabs[$stateParams.tab]) {
      vm.tabs[$stateParams.tab].active = true;
    } else if (generateTabFirst) {
      vm.tabs.generate.active = true;
    } else if (angular.isDefined(vm.tabs.sessions)) {
      vm.tabs.sessions.active = true;
    }

    vm.form = {
      startdate: {
        val: null,
        format: 'yyyy-MM-dd',
        isOpen: false,
        open: function () {
          vm.form.startdate.isOpen = true;
        }
      },
      enddate: {
        val: null,
        format: 'yyyy-MM-dd',
        isOpen: false,
        open: function () {
          vm.form.enddate.isOpen = true;
        }
      },
      billingDivision: [],
      customerDebtorNumbers: [],
      dueDate: {
        val: null,
        format: 'yyyy-MM-dd',
        isOpen: false,
        open: function () {
          vm.form.dueDate.isOpen = true;
        }
      },
      creditorName: null,
      creditorId: null,
      email: false,
      exportGlobal: false
    };

    vm.reportTypes = {};

    vm.setActiveTab = function (tab) {
      angular.forEach(vm.tabs, function (value, key) {
        value.active = key === tab;
      });

      $state.go($state.current, {tab: tab}, {reload: false});
    };

    vm.dateSet = function () {
      vm.form.enddate.val = vm.form.startdate.val;
    };

    function reportFormatToLabelPostfix(format) {
      if (format === 'csv_txt') {
        format = 'csv';
      }

      return format.toUpperCase();
    }

    if ((angular.isDefined($stateParams.tab) && $stateParams.tab === 'generate') ||
        (angular.isUndefined($stateParams.tab) && generateTabFirst)) {
      init();
      vm.reloadBillingDivisions();
    }

    function init() {
      RestUtilService.getFullList(ReportTypeFactory).then(function (results) {
        var proaccCustomers, proaccSales, sepaDirectDebit;

        angular.forEach(results, function (result) {
          vm.reportTypes[result.type + '_' + result.format] = {
            label: $filter('translate')('reports.type.' + result.type) + ' ' + reportFormatToLabelPostfix(result.format),
            pathPrefix: '/services/reports/',
            path: result.type,
            format: result.format,
            allowGlobal: result.allowGlobal,
            forceMail: result.forceMail
          };
        });

        if (angular.isDefined(vm.reportTypes.proacc_customers_export_csv_txt)) {
          proaccCustomers = vm.reportTypes.proacc_customers_export_csv_txt;
          proaccCustomers.selectExportCustomers = true;
          proaccCustomers.disableEmail = true;
        }

        if (angular.isDefined(vm.reportTypes.proacc_sales_export_csv_txt)) {
          proaccSales = vm.reportTypes.proacc_sales_export_csv_txt;
          proaccSales.selectBillingDivision = true;
          proaccSales.inputInvoices = true;
          proaccSales.inputExcludedInvoices = true;
          proaccSales.disableEmail = true;
          proaccSales.maxRangeDays = 14;
        }

        if (angular.isDefined(vm.reportTypes.sepa_direct_debit_export_xml)) {
          sepaDirectDebit = vm.reportTypes.sepa_direct_debit_export_xml;
          sepaDirectDebit.inputInvoices = true;
          sepaDirectDebit.inputExcludedInvoices = true;
          sepaDirectDebit.inputDueDate = true;
          sepaDirectDebit.inputCreditorName = true;
          sepaDirectDebit.inputCreditorId = true;
          sepaDirectDebit.disableEmail = true;
          sepaDirectDebit.maxRangeDays = 14;
        }
      });
    }

    function generateReport() {
      /*global moment, URI */

      var report = vm.reportType,
          path = new URI(Restangular.configuration.baseUrl + (angular.isDefined(report.pathPrefix) ? report.pathPrefix : '') + report.path),
          link = null,
          object = {},
          maxRangeDays = 32,
          startDate = moment(vm.form.startdate.val),
          endDate = moment(vm.form.enddate.val),
          maxRangeDaysReached = false;

      if (report.maxRangeDays) {
        maxRangeDays = report.maxRangeDays;
      }
      maxRangeDaysReached = endDate.diff(startDate, 'd') > maxRangeDays;
      if (!report.disableEmail && (maxRangeDaysReached || vm.form.email)) {
        object.userContext = CurrentUserContextFactory.getUserContextId();
        object.enabled = true;
        object.parameters = {
          format: report.format,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD')
        };

        if (vm.form.exportGlobal) {
          object.parameters.exportGlobal = 1;
        }

        object.interval = 'P1D';
        object.ephemeral = true;

        vm.showReportNotification();
        if (maxRangeDaysReached && !vm.form.email) {
          // Show message that mail is sended because of maxRangeDays
          ToastrNotificationService.showNotification(
            'info',
            $filter('uconlyfirst')(
              $filter('translate')('app.report-download-mail-due-to-range')
            ),
            $filter('uconlyfirst')(
              $filter('translate')('app.report-download-mail-due-to-range-extended')
            )
          );
        }
        if (!maxRangeDaysReached && vm.form.email) {
          // Show message that mail is sended because of maxRangeDays
          ToastrNotificationService.showNotification(
            'info',
            $filter('uconlyfirst')(
              $filter('translate')('app.report-download-mail')
            ),
            $filter('uconlyfirst')(
              $filter('translate')('app.report-download-mail-extended')
            )
          );
        }
        object.site = CurrentUserContextFactory.getSiteId();
        ScheduledReportFactory.one(report.path).customPOST(object).then(function () {
          return false;
        });
      } else {
        /* eslint-disable camelcase */
        path.addQuery({
          access_token: OAuthToken.getAccessToken(),
          format: report.format,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD')
        });

        if (vm.form.exportGlobal) {
          path.addQuery({exportGlobal: 1});
        }

        if (report.selectBillingDivision && vm.form.billingDivisions && vm.form.billingDivisions.length) {
          path.addQuery({
            'billingDivisions[]': vm.form.billingDivisions
          });
        }

        if (report.selectExportCustomers && vm.form.customerDebtorNumbers && vm.form.customerDebtorNumbers.length) {
          path.addQuery({
            'customerDebtorNumbers[]': vm.form.customerDebtorNumbers.split('\n')
          });
        }

        if (report.inputInvoices && vm.form.invoices && vm.form.invoices.length) {
          path.addQuery({
            'invoices[]': vm.form.invoices.split('\n')
          });
        }

        if (report.inputExcludedInvoices && vm.form.excludedInvoices && vm.form.excludedInvoices.length) {
          path.addQuery({
            'excludeInvoices[]': vm.form.excludedInvoices.split('\n')
          });
        }

        if (report.inputDueDate && vm.form.dueDate && vm.form.dueDate.val) {
          path.addQuery({
            dueDate: moment(vm.form.dueDate.val).format('YYYY-MM-DD')
          });
        }

        if (report.inputCreditorId && vm.form.creditorId && vm.form.creditorId.length) {
          path.addQuery({
            creditorId: vm.form.creditorId
          });
        }

        if (report.inputCreditorName && vm.form.creditorName && vm.form.creditorName.length) {
          path.addQuery({
            creditorName: vm.form.creditorName
          });
        }

        // see #47691
        if (report.path === 'revenue_export' && report.format === 'json') {
          path.addQuery({
            saleStatus: 'all'
          });
        }

        vm.showReportNotification();
        /* eslint-enable camelcase */

        link = path.toString();
        LogService.log('generate report: ' + link, 'debug');

        // open in new tab
        $window.open(link, '_blank');
        return false;
      }
    }

    function reportSelected() {
      if (vm.reportType.forceMail) {
        vm.form.email = vm.reportType.forceMail;
      }
    }

    function showReportNotification() {
      ToastrNotificationService.showTranslatedNotification(
        'success',
        '',
        $filter('uconlyfirst')(
          $filter('translate')('app.report-requested')
        )
      );
    }
  }
}());
