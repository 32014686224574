(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SetAddEditCtrlCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SetAddEditCtrl', SetAddEditCtrl);

  function SetAddEditCtrl(
      JournalTypeFactory,
      SwimCourseSetFactory,
      $cookies,
      $filter,
      $rootScope,
      $modalInstance,
      LogService,
      set,
      CurrentUserContextFactory
    ) {
    var vm = this,
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId();
    vm.currentSet = set;
    vm.setLabel = set === null ? '' : set.label;

    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.getSetRestObject = getSetRestObject;
    vm.saveSet = saveSet;
    vm.deleteSet = deleteSet;
    vm.broadcastRefreshSubscriptionsMatrixEvent = broadcastRefreshSubscriptionsMatrixEvent;

    vm.journalTypes = [];
    vm.setJournalTypes = [];
    vm.getSetJournalTypesIds = getSetJournalTypesIds;
    vm.existingSetJournalTypes = existingSetJournalTypes;

    JournalTypeFactory.getList({limit: 99}).then(function (resultTypes) {
      vm.journalTypes = resultTypes;
      if (isEdit()) {
        vm.setJournalTypes = vm.existingSetJournalTypes(vm.currentSet.journalTypes);
      }
    });

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function getSetRestObject(includeSiteId) {
      var restObject = {
        label: vm.setLabel,
        journalTypes: vm.getSetJournalTypesIds()
      };

      if (angular.isDefined(includeSiteId) && includeSiteId) {
        restObject.site = currentLoggedInUserSiteId;
      }

      return restObject;
    }

    function deleteSet() {
      if (isEdit()) {
        SwimCourseSetFactory.one(vm.currentSet.id).remove().then(function () {
          // Close the modal view
          $modalInstance.close();
        });
      } else {
        // Close the modal view
        $modalInstance.close();
      }
    }

    function saveSet() {
      var reloadOption = 'reloadListData', setObject;

      // Check if we're in Edit-mode
      if (vm.isEdit()) {
        setObject = getSetRestObject();
        // Patch the set
        SwimCourseSetFactory.one(vm.currentSet.id).patch(setObject).then(function () {
          vm.broadcastRefreshSubscriptionsMatrixEvent();
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      } else {
        setObject = getSetRestObject(true);
        // Create the set
        SwimCourseSetFactory.post(setObject).then(function () {
          vm.broadcastRefreshSubscriptionsMatrixEvent();
          // Close the modal view
          $modalInstance.close(reloadOption);
        }, function (errorMsg) {
          console.error('error: ' + errorMsg);
        });
      }
    }

    function broadcastRefreshSubscriptionsMatrixEvent() {
      LogService.log('Broadcasting refresh subscription matrix event', 'debug');
      $rootScope.$broadcast('refreshSubscriptionsMatrix');
    }

    function isEdit() {
      return vm.currentSet !== null;
    }

    function getSetJournalTypesIds() {
      var idList = [];
      angular.forEach(vm.setJournalTypes, function (journalType) {
        idList.push(journalType.id);
      });
      return idList;
    }

    function existingSetJournalTypes(journalTypes) {
      var idList = [];
      angular.forEach(journalTypes, function (journalType) {
        idList.push(journalType.id);
      });

      return $filter('filter')(vm.journalTypes, function (jt) {
        return idList.indexOf(jt.id) !== -1;
      });
    }
  }
}());
