(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SubscriptionDateEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SubscriptionDateEditCtrl', SubscriptionDateEditCtrl);

  function SubscriptionDateEditCtrl(
    SubscriptionFactory,
    $cookies,
    $modalInstance,
    LogService,
    pupil
  ) {
    var vm = this;
    vm.pupil = pupil;
    vm.cancel = cancelModalInstance;
    vm.saveSubscriptionDate = saveSubscriptionDate;
    vm.subscriptionDate = null;
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;

    // vm.getSubscriptionRestObject = getSubscriptionRestObject;
    vm.loadData = loadData;
    vm.loadData();

    function loadData() {
      // set date
      LogService.log('current pupil: ' + vm.pupil, 'debug');
      vm.startTime = new Date();
      if (vm.pupil.subscribedAt) {
        vm.subscriptionDate = new Date(vm.pupil.subscribedAt);
        vm.startTime = new Date(vm.startTime.setMinutes(vm.subscriptionDate.getMinutes()));
        vm.startTime = new Date(vm.startTime.setHours(vm.subscriptionDate.getHours()));
      }
    }
    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveSubscriptionDate() {
      var reloadOption = 'reloadListData',
          patchObject = {};
      if (vm.subscriptionDate) {
        vm.subscriptionDate = new Date(vm.subscriptionDate.setMinutes(vm.startTime.getMinutes()));
        vm.subscriptionDate = new Date(vm.subscriptionDate.setHours(vm.startTime.getHours()));
        patchObject.subscribedAt = vm.subscriptionDate.toISOString();
      } else {
        patchObject.subscribedAt = null;
      }

      SubscriptionFactory.one(pupil.id).patch(patchObject).then(function () {
        // Close the modal view
        $modalInstance.close(reloadOption);
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function openDatePicker(editType) {
      switch (editType) {
        case 'subscriptionDate':
          vm.datePickerIsOpened = true;
          break;

        default:
          break;
      }
    }
  }
}());
