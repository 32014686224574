(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .controller('ProductCtrl', ProductCtrl);

  function ProductCtrl(
      $cookies,
      $localStorage,
      $filter,
      $modal,
      $rootScope,
      $scope,
      $state,
      $stateParams,
      AnalyticalAccountFactory,
      DiscountFactory,
      ImageFactory,
      ImageService,
      LedgerFactory,
      LogService,
      ProductCategoryFactory,
      ProductFactory,
      SettingsService,
      AttachmentUtilService
    ) {
    var vm = this,
        opened = false,
        modalInstance;

    vm.bgComponents = ['photo', 'credits', 'journal', 'expiration', 'start_date', 'ledger', 'analytical_account', 'subscription_contract', 'price', 'products'];
    vm.clearSearch = clearSearch;
    vm.componentSearchQuery = '';
    vm.findProductComponent = findProductComponent;
    vm.getDetails = getDetails;
    vm.isNotBgComponentFilter = isNotBgComponentFilter;
    vm.productHasPhoto = false;
    vm.productPhoto = '';
    vm.productSearchQuery = '';
    vm.reloadListData = reloadListData;
    vm.reloadPageData = reloadPageData;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.searchComponent = searchComponent;
    vm.cloneProduct = cloneProduct;
    vm.selectedCategoryId = '';
    vm.selectedProductCategoryFilter = '';
    vm.selectedExtendedFilter = '';
    vm.setProductsCategoryFilter = setProductsCategoryFilter;
    vm.showModalAddEditView = showModalAddEditView;
    vm.showModalDeleteView = showModalDeleteView;
    vm.showModalView = showModalView;
    vm.selectedProductCategoryFilterLabel = '-';
    vm.extendedFilterLabel = '-';
    vm.activeTab = 'product';

    // define pagination
    vm.pagination = {
      totalItems: '',
      maxPages: 10,
      currentPage: 1,
      itemsPerPage: 15
    };

    vm.productComponents = [
      {
        id: null,
        dirty: false,
        background: true,
        type: 'journal',
        label: $filter('uconlyfirst')($filter('translate')('journal.journal')),
        fields: {
          journalType: null,
          journalAction: null,
          attemptAppend: null
        }
      },
      {
        id: null,
        uuid: null,
        dirty: false,
        background: true,
        type: 'subscription_contract',
        label: $filter('uconlyfirst')($filter('translate')('journal.subscription-contract')),
        fields: {
          duration: null,
          paymentInterval: null,
          paymentMethod: null,
          proRata: null
        }
      },
      {
        id: null,
        dirty: false,
        background: true,
        type: 'expiration',
        label: $filter('uconlyfirst')($filter('translate')('journal.expiration')),
        fields: {
          days: null
        }
      },
      {
        id: null,
        dirty: false,
        type: 'price',
        background: true,
        label: $filter('uconlyfirst')($filter('translate')('app.price')),
        fields: {
          price: null,
          currency: 'EUR',
          vatRate: null,
          static: true
        }
      },
      {
        id: null,
        dirty: false,
        background: true,
        type: 'credits',
        label: $filter('uconlyfirst')($filter('translate')('journal.credits')),
        fields: {
          credits: null
        }
      },
      {
        id: null,
        dirty: false,
        background: true,
        type: 'ledger',
        label: $filter('uconlyfirst')($filter('translate')('product.views.ledger-number')),
        fields: {
          ledger: null
        }
      },
      {
        id: null,
        dirty: false,
        background: true,
        type: 'analytical_account',
        label: $filter('uconlyfirst')($filter('translate')('accountancy.analytical_account')),
        fields: {
          analyticalAccount: null
        }
      },
      {
        id: null,
        dirty: false,
        type: 'facilities',
        label: $filter('uconlyfirst')($filter('translate')('app.facilities')),
        fields: {
          facilities: null
        }
      },
      {
        id: null,
        dirty: true,
        background: true,
        type: 'start_date',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.start_date')),
        fields: {}
      },
      {
        id: null,
        dirty: false,
        background: true,
        type: 'products',
        label: $filter('uconlyfirst')($filter('translate')('app.products')),
        fields: {
          products: null
        }
      },
      {
        id: null,
        dirty: false,
        type: 'fitness_credits',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.fitnesscredits')),
        fields: {
          credits: null
        }
      },
      {
        id: null,
        dirty: false,
        background: true,
        type: 'photo',
        label: $filter('uconlyfirst')($filter('translate')('app.photo')),
        fields: {
          photo: null
        }
      },
      {
        id: null,
        dirty: false,
        type: 'sale_consolidation_code',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.sale_consolidation_code')),
        fields: {
          consolidationCode: null
        }
      },
      {
        id: null,
        dirty: false,
        type: 'article_type_consolidation_code',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.article_type_consolidation_code')),
        fields: {
          consolidationCode: null
        }
      },
      {
        id: null,
        dirty: false,
        type: 'activity_consolidation_code',
        label: $filter('uconlyfirst')($filter('translate')('product.views.component.activity_consolidation_code')),
        fields: {
          consolidationCode: null
        }
      }
    ];

    vm.extendedFilters = [
      {
        id: 'notHavingAnalyticalAccountComponent',
        label: $filter('uconlyfirst')($filter('translate')('product.extendedFilters.notHavingAnalyticalAccountComponent'))
      },
      {
        id: 'notHavingActivityConsolidationCode',
        label: $filter('uconlyfirst')($filter('translate')('product.extendedFilters.notHavingActivityConsolidationCode'))
      }
    ];

    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.useExternalFormTemplateEngine = SettingsService.get('pos.useExternalFormTemplateEngine', false);
    vm.showExtendedFiltering = !SettingsService.get('products.hideExtendedFiltering', false);
    vm.rePaymentProductUUID = SettingsService.get('pos.rePaymentProductUUID', null);
    vm.enableSalesLinking = SettingsService.get('pos.enableSalesLinking', false);
    vm.enableProductLabels = SettingsService.get('products.enableProductLabels', false);
    vm.showBarcode = SettingsService.get('products.showBarcode', false);
    vm.showAccDiscount = SettingsService.get('products.showAccDiscount', false);
    vm.showVisibleInWebshop = SettingsService.get('products.enableProductPropertyVisibleInWebshop', false);
    vm.loadproductLabels = loadproductLabels;

    if ($state.is('productdetail')) {
      vm.reloadPageData($stateParams.id);
    } else {
      vm.reloadListData();
    }

    function showModalView(type, viewObject) {
      if (opened) {
        return;
      }

      switch (type) {
        case 'component':
          modalInstance = $modal.open({
            templateUrl: 'product/views/components/component.modal.view.tpl.html',
            controller: 'ProductAddEditCtrl',
            controllerAs: 'productAddEditCtrl',
            resolve: {
              component: function () {
                return viewObject;
              },
              product: function () {
                return vm.product;
              },
              type: function () {
                return type;
              }
            }
          });
          break;
        default:
          console.error('Unknown showModalAddView type ', type);
      }
      opened = true;
      vm.returnOfModalInstance();
    }

    function showModalAddEditView(type, viewObject) {
      if (opened) {
        return;
      }

      switch (type) {
        case 'productList':
        case 'product':
          modalInstance = $modal.open({
            templateUrl: 'product/views/product.modal.addedit.tpl.html',
            controller: 'ProductAddEditCtrl',
            controllerAs: 'productAddEditCtrl',
            size: 'lg',
            resolve: {
              product: function () {
                return viewObject;
              },
              type: function () {
                return type;
              },
              component: function () {
                return null;
              }
            }
          });
          break;
        case 'component-add':
          modalInstance = $modal.open({
            templateUrl: 'product/views/components/component.modal.add.tpl.html',
            controller: 'ProductAddEditCtrl',
            controllerAs: 'productAddEditCtrl',
            size: 'sm',
            resolve: {
              product: function () {
                // we should only ever launch this modal from the detail product view, so assume
                // vm.product will not be null
                return vm.product;
              },
              type: function () {
                return type;
              },
              component: function () {
                return viewObject;
              }
            }
          });
          break;
        case 'component':
          modalInstance = $modal.open({
            templateUrl: 'product/views/components/component.modal.edit.tpl.html',
            controller: 'ProductAddEditCtrl',
            controllerAs: 'productAddEditCtrl',
            size: 'sm',
            resolve: {
              product: function () {
                // we should only ever launch this modal from the detail product view, so assume
                // vm.product will not be null
                return vm.product;
              },
              type: function () {
                return type;
              },
              component: function () {
                return viewObject;
              }
            }
          });
          break;
        default:
          console.error('Unknown showModalAddView type ', type);
      }
      opened = true;
      vm.returnOfModalInstance();
    }

    function showModalDeleteView(type, viewObject, component) {
      if (opened) {
        return;
      }

      switch (type) {
        case 'productList':
        case 'product':
          modalInstance = $modal.open({
            templateUrl: 'product/views/product.modal.delete.tpl.html',
            controller: 'ProductDeleteCtrl',
            controllerAs: 'productDeleteCtrl',
            resolve: {
              product: function () {
                return viewObject;
              }
            }
          });
          break;
        case 'productPriceComponent':
          modalInstance = $modal.open({
            templateUrl: 'product/views/product_price_component.modal.delete.tpl.html',
            controller: 'ProductComponentDeleteCtrl',
            controllerAs: 'productComponentDeleteCtrl',
            resolve: {
              product: function () {
                return viewObject;
              },
              productComponent: function () {
                return component;
              }
            }
          });
          break;
        case 'component':
          modalInstance = $modal.open({
            templateUrl: 'product/views/components/component.modal.delete.tpl.html',
            controller: 'ProductAddEditCtrl',
            controllerAs: 'productAddEditCtrl',
            resolve: {
              component: function () {
                return viewObject;
              },
              product: function () {
                return vm.product;
              },
              type: function () {
                return 'reloadPageData';
              }
            }
          });
          break;
        default:
          console.error('Unknown showModalAddView type ', type);
      }
      opened = true;
      vm.returnOfModalInstance();
    }

    function returnOfModalInstance() {
      modalInstance.result.then(function (returnValue) {
        opened = false;
        switch (returnValue) {
          case 'reloadPageData':
            vm.reloadPageData(vm.product.uuid);
            break;
          case 'reloadListData':
            vm.reloadListData();
            break;
          default:
            break;
        }
      }, function () {
        opened = false;
      });
    }

    function findProductComponent(componentType) {
      var foundIndex = -1,
          currentIndex = 0;
      if (!vm.product || angular.isUndefined(vm.product.productComponents)) {
        return -1;
      }

      // use native for loop instead of angular.forEach() so we can break when we've found the type
      for (currentIndex = 0; currentIndex < vm.product.productComponents.length; ++currentIndex) {
        if (vm.product.productComponents[currentIndex].type === componentType) {
          foundIndex = currentIndex;
          break;
        }
      }
      return foundIndex;
    }

    // this function is called when we visit the product detail page
    function reloadPageData(productId) {
      var filterVars = {
            limit: 99
          }, productComponentInstance;
      vm.productJournalHasStartDate = false;
      vm.productJournalExpiration = null;
      vm.productJournalCredits = null;
      vm.productJournalType = null;
      vm.productJournalAction = null;
      vm.productJournalAttemptAppend = false;
      vm.productIsJournal = false;
      vm.productAnalyticalAccount = null;
      vm.productLedgerNumber = null;
      ProductFactory.one(productId).get().then(function (resultData) {
        vm.product = resultData.data;

        // find the product image if it exists
        angular.forEach(vm.product.productComponents, function (pc) {
          productComponentInstance = $filter('filter')(vm.productComponents, function (pComp) {
            return pComp.type === pc.type;
          });
          pc.label = productComponentInstance && angular.isDefined(productComponentInstance[0]) ? productComponentInstance[0].label : '';
          switch (pc.type) {
            case 'subscription_contract':
              vm.productJournalIsSubscriptionContract = true;
              vm.productJournalSubscriptionDuration = pc.duration;
              vm.productJournalSubscriptionPaymentInterval = pc.paymentInterval;
              vm.productJournalSubscriptionPaymentMethod = pc.paymentMethod;
              vm.productJournalSubscriptionProRata = pc.proRata;
              break;

            case 'ledger':
              LedgerFactory.one(pc.ledger.id).get().then(function (resultLedger) {
                vm.productLedgerNumber = resultLedger;
              });
              break;
            case 'analytical_account':
              AnalyticalAccountFactory.one(pc.analyticalAccount.id).get().then(function (resultAnalyticalAccount) {
                vm.productAnalyticalAccount = resultAnalyticalAccount;
              });
              break;
            case 'photo':
              ImageService.getImageFromBackend(pc.photo.id).then(function (photo) {
                vm.photo = photo;
                vm.productHasPhoto = true;
              });
              break;

            case 'journal':
              vm.productIsJournal = true;
              vm.productJournalType = pc.journalType;
              vm.productJournalAction = pc.journalAction;
              vm.productJournalAttemptAppend = pc.attemptAppend;
              break;

            case 'credits':
              vm.productJournalCredits = pc.credits;
              break;

            case 'expiration':
              vm.productJournalExpiration = pc.days;
              break;

            case 'start_date':
              vm.productJournalHasStartDate = true;
              break;

            case 'products':
              vm.isGroupProduct = true;
              vm.groupProducts = pc.products;
              angular.forEach(vm.groupProducts, function (groupProduct) {
                ProductFactory.one(groupProduct.id).get().then(function (subResultData) {
                  subResultData.data.productPrice = $filter('filter')(subResultData.data.productComponents, function (spc) {
                    return spc.type === 'price';
                  })[0] || null;
                  groupProduct.product = subResultData.data;
                  LogService.log('subProduct: ' + subResultData, 'debug');
                });
              });
              break;

            default:
              break;
          }
        });
        // retrieve applicable product discounts
        filterVars['filter[]'] = 'product.id,' + vm.product.id;
        DiscountFactory.getList(filterVars).then(function (resultDiscounts) {
          vm.product.discounts = [];
          angular.forEach(resultDiscounts.results, function (currDiscount) {
            vm.product.discounts.push({
              label: currDiscount.label,
              facility: currDiscount.facility.label,
              customer: currDiscount.customer.label,
              startDate: currDiscount.startDate,
              endDate: currDiscount.endDate,
              price: currDiscount.price,
              percentage: currDiscount.percentage
            });
          });
        });
      });
    }

    function reloadListData() {
      var parameters, filterIndex = 2;
      if (!$localStorage.productFilter) {
        parameters = {
          sort: ['label,asc'],
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage
        };
        // add product mostRecent: true
        parameters['filter[]'] = [
          'mostRecent,TRUE',
          'OR,hidden,NULL,hidden,FALSE'
        ];
        $localStorage.$default({productFilter: parameters});
      } else {
        parameters = $localStorage.productFilter;
        parameters.offset = (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage;
        parameters.limit = vm.pagination.itemsPerPage;

        if (angular.isDefined($localStorage.productFilter) && $localStorage.productFilter.productSearchQueryLabel && !vm.productSearchQuery) {
          vm.productSearchQuery = $localStorage.productFilter.productSearchQueryLabel;
        }

        if (angular.isDefined($localStorage.productFilter) && $localStorage.productFilter.extendedFilter && !vm.selectedExtendedFilter) {
          vm.selectedExtendedFilter = $localStorage.productFilter.extendedFilter;
        }

        if (angular.isDefined($localStorage.productFilter) && $localStorage.productFilter.selectedProductCategoryFilter && !vm.selectedProductCategoryFilter) {
          vm.selectedProductCategoryFilter = $localStorage.productFilter.selectedProductCategoryFilter;
        }
      }
      vm.products = [];
      if (vm.selectedProductCategoryFilter !== null && vm.selectedProductCategoryFilter.length !== 0) {
        parameters['filter[]'][2] = 'productCategories.id,LIKE ' + vm.selectedProductCategoryFilter;
        parameters.selectedProductCategoryFilter = vm.selectedProductCategoryFilter;
        filterIndex = 3;
      }
      if (vm.selectedExtendedFilter !== null && vm.selectedExtendedFilter.length !== 0) {
        parameters.extendedFilter = vm.selectedExtendedFilter;
      }

      if (vm.productSearchQuery !== null && vm.productSearchQuery.length !== 0) {
        parameters['filter[]'][filterIndex] = 'label,LIKE ' + vm.productSearchQuery;
        parameters.productSearchQueryLabel = vm.productSearchQuery;
      }
      $localStorage.productFilter = parameters;

      ProductFactory.getList(parameters).then(function (resultData) {
        vm.pagination.totalItems = resultData.data.count;
        angular.forEach(resultData.data, function (productData, key) {
          vm.products[key] = {};
          ProductFactory.one(productData.uuid).get().then(function (resultProduct) {
            resultProduct.data.productPrice = $filter('filter')(resultProduct.data.productComponents, function (pc) {
              return pc.type === 'price';
            })[0] || null;
            resultProduct.data.productJournal = $filter('filter')(resultProduct.data.productComponents, function (pc) {
              return pc.type === 'journal';
            }).length > 0 ? true : false;
            resultProduct.data.isCombiProduct = $filter('filter')(resultProduct.data.productComponents, function (pc) {
              return pc.type === 'products';
            }).length > 0 ? true : false;
            vm.products[key] = resultProduct.data;
          });
        });
      }, function (errorMsg) {
        LogService.log('error: ' + errorMsg, 'debug');
      });

      parameters = {
        limit: 99
      };
      parameters['filter[]'] = ['mostRecent,TRUE'];
      parameters.sort = 'label,ASC';
      ProductCategoryFactory.getList(parameters).then(function (resultData) {
        vm.productCategories = resultData;
      });
    }

    function clearSearch() {
      vm.selectedProductCategoryFilter = '';
      vm.selectedExtendedFilter = '';
      vm.productSearchQuery = '';
      clearSearchDataFromLocalStorage();
      vm.reloadListData();
    }

    function clearSearchDataFromLocalStorage() {
      delete $localStorage.productFilter;
    }

    function setProductsCategoryFilter() {
      LogService.log('setProductsCategoryFilter', 'debug');
      vm.reloadListData();
    }

    function getDetails(type, productId) {
      $state.go('productdetail', {id: productId}, {reload: true});
    }

    function isNotBgComponentFilter(object) {
      // Filter for ng-repeat in template
      var currType = object.type;
      return vm.bgComponents.indexOf(currType) === -1;
    }

    function searchComponent(item) {
      var lcQuery = vm.componentSearchQuery.toLowerCase(),
          valueFound = false,
          prop;
      for (prop in item) {
        if (item.hasOwnProperty(prop) && item[prop] === lcQuery) {
          valueFound = true;
        }
      }

      if (lcQuery.length === 0 ||
          valueFound ||
          item.type.toLowerCase().indexOf(lcQuery) !== -1 ||
          item.hasOwnProperty(lcQuery)) {
        return true;
      }
      return false;
    }

    function cloneProduct(productId) {
      LogService.log('Cloning product with UUID/ID: ' + productId, 'debug');

      ProductFactory.one(productId).post('clone?except[]=price', {}).then(function (result) {
        LogService.log('Cloned product UUID/ID: ' + result.data.uuid, 'debug');
        vm.getDetails('view', result.data.uuid);
      });
    }

    function loadproductLabels(labelVersion) {
      var format;
      format = labelVersion === 3 ? 'jpeg' : 'pdf';
      AttachmentUtilService.download('/products/' + $stateParams.id + '/product_labels', {
        format: format,
        labelVersion: labelVersion
      });
    }

    vm.selectTab = function (tabId) {
      vm.activeTab = tabId;
    };
  }
}());
