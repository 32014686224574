(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name product.factory:ConsolidationCodeTypesFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('product')
    .factory('ConsolidationCodeTypesFactory', ConsolidationCodeTypesFactory);

  function ConsolidationCodeTypesFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'consolidationCodeType'});
    })
    .service('consolidation_codes/types');
  }
}());
