(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name swimcourse.factory:SwimCourseManagementFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .factory('SwimCourseManagementFactory', SwimCourseManagementFactory);

  function SwimCourseManagementFactory(
    SwimCourseSetFactory,
    UtilService,
    AttachmentUtilService,
    SwimCourseParticipantStatusFactory,
    LogService,
    Restangular,
    moment
  ) {
    var subscriptionService =
        Restangular
        .withConfig(function (RestangularConfigurer) {
          RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseSubscription'});
        })
        .service('swim_courses/subscriptions'),

        setsService =
        Restangular
        .withConfig(function (RestangularConfigurer) {
          RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourseSet'});
        })
        .service('swim_courses/sets'),
        // swimCourseService =
        // Restangular
        // .withConfig(function (RestangularConfigurer) {
        //   RestangularConfigurer.setDefaultHeaders({'x-entity': 'swimCourse'});
        // })
        // .service('swim_courses'),
        attendableSessionsService = Restangular.service('services/swim_course_attendable_sessions');

    return {
      getSwimCourseSets: function (params) {
        return setsService.getList(params);
      },
      getSwimCourseSetById: function (id) {
        return setsService.one(id).get();
      },
      getSubscriptions: function (params) {
        if (!UtilService.isNotEmpty(params.limit)) {
          params.limit = 99;
        }

        return subscriptionService.getList(params);
      },
      createNewSubscription: function (contactId, setId, levelId, comments, prioritized, swimCourseSeriesGroupId, adjustSubscriptionDate) {
        var restObject = {
          contact: contactId,
          swimCourseSet: setId,
          swimCourseLevel: levelId,
          comments: comments,
          prioritized: prioritized
        };

        if (!UtilService.isNotEmpty(adjustSubscriptionDate)) {
          adjustSubscriptionDate = true;
        }

        if (adjustSubscriptionDate) {
          restObject.subscribedAt = adjustSubscriptionDate;
        }

        if (UtilService.isNotEmpty(swimCourseSeriesGroupId)) {
          restObject.swimCourseSeriesGroup = swimCourseSeriesGroupId;
        }
        return subscriptionService.post(restObject);
      },
      registerSubscriptionToGroup: function (subscription, setId, levelId, swimCourseSeriesGroupId, adjustSubscriptionDate, adjustLeftWaitingListAt) {
        var now = new Date().toISOString(),
            restObject = {
              swimCourseLevel: levelId,
              swimCourseSet: setId,
              swimCourseSeriesGroup: swimCourseSeriesGroupId,
              journalPaymentStatus: null
            };

        if (!UtilService.isNotEmpty(adjustSubscriptionDate)) {
          adjustSubscriptionDate = true;
        }

        if (!UtilService.isNotEmpty(adjustLeftWaitingListAt)) {
          adjustLeftWaitingListAt = true;
        }

        if (adjustSubscriptionDate) {
          restObject.subscribedAt = now;
        }

        if (adjustLeftWaitingListAt) {
          restObject.leftWaitingListAt = now;
        }

        if (subscription.hasOwnProperty('patch')) {
          LogService.log('Registering subscription ' + subscription.id + ' to group ' + swimCourseSeriesGroupId, 'debug');
          return subscription.patch(restObject);
        }
        LogService.log('Suscribe: subscription not a restangular object!', 'debug');
      },
      registerSubscriptionToWaitingList: function (subscription, setId, levelId, adjustSubscriptionDate) {
        var now = new Date().toISOString(),
            restObject = {
              swimCourseLevel: levelId,
              swimCourseSet: setId,
              swimCourseSeriesGroup: null
            };

        if (!UtilService.isNotEmpty(adjustSubscriptionDate)) {
          adjustSubscriptionDate = true;
        }

        if (adjustSubscriptionDate) {
          restObject.subscribedAt = now;
        }

        if (subscription.hasOwnProperty('patch')) {
          LogService.log('Registering subscription ' + subscription.id + ' to waiting list.', 'debug');
          return subscription.patch(restObject);
        }
        LogService.log('Transfer: subscription not a restangular object!', 'debug');
      },
      getSubscriptionsPage: function (page, limit, filters, sort) {
        var pupilList = [],
            params = {},
            returnObj = {};

        if (!UtilService.isNotEmpty(limit)) {
          limit = 25;
        }

        params.limit = limit;
        params.offset = (page - 1) * limit;

        if (UtilService.isNotEmpty(filters)) {
          params['filter[]'] = filters;
        }

        if (UtilService.isNotEmpty(sort)) {
          params.sort = sort;
        }

        return new Promise(function (resolve) {
          subscriptionService.getList(params)
          .then(function (result) {
            returnObj.total = result.count;
            angular.forEach(result, function (cs) {
              // ++pupilsLeftToLoad;
              // flag as either waiting list or subscribed
              if (cs.hasOwnProperty('swimCourseSeriesGroup')) {
                cs.waitingList = false;
              } else {
                cs.waitingList = true;
              }
              pupilList.push(cs);
            });
            returnObj.pupils = pupilList;
            resolve(returnObj);
          });
        });
      },
      getSwimCoursePupils: function (setId, levelId, seriesGroupId, contactIds) {
        var params = {
              limit: 99
            },
            contactFilterString = '';

        params['filter[]'] = [];

        // Set level id filter if that parameter was passed.
        if (UtilService.isNotEmpty(setId)) {
          params['filter[]'].push('swimCourseSet.id,' + setId);
        } else {
          params['filter[]'].push('swimCourseSet.id,NOT NULL');
        }

        // Set level id filter if that parameter was passed.
        if (UtilService.isNotEmpty(levelId)) {
          params['filter[]'].push('swimCourseLevel.id,' + levelId);
        } else {
          params['filter[]'].push('swimCourseLevel.id,NOT NULL');
        }

        // Set series group id filter if that parameter was passed.
        // If not, make sure the id is not null (otherwise we're getting a waiting list)
        if (UtilService.isNotEmpty(seriesGroupId)) {
          params['filter[]'].push('swimCourseSeriesGroup.id,' + seriesGroupId);
        } else {
          params['filter[]'].push('swimCourseSeriesGroup.id,NOT NULL');
        }

        // set contact id filter is that parameter was passed
        if (UtilService.isNotEmpty(contactIds)) {
          // convert to array if a single id was given
          if (angular.isString(contactIds)) {
            contactIds = [contactIds];
          }

          contactFilterString = 'OR,';
          angular.forEach(contactIds, function (currentContactId) {
            contactFilterString += 'contact.id,' + currentContactId;
          });
          params['filter[]'].push(contactFilterString);
        }

        return subscriptionService.getList(params);
      },
      getSwimCourseWaitingList: function (setId, levelId) {
        var params = {
          limit: 999
        };
        params['filter[]'] = [];
        // load pupils that are on the waiting list (i.e. have not been asigned a swimcourse series group)
        params['filter[]'].push('swimCourseSeriesGroup.id,NULL');
        if (UtilService.isNotEmpty(levelId)) {
          params['filter[]'].push('swimCourseLevel.id,' + levelId);
        }
        if (UtilService.isNotEmpty(setId)) {
          params['filter[]'].push('swimCourseSet.id,' + setId);
        }
        return subscriptionService.getList(params);
      },
      downloadWaitingList: function (setId, levelId) {
        if (!(UtilService.isNotEmpty(setId) && UtilService.isNotEmpty(levelId))) {
          return;
        }

        LogService.log('Dowloading waiting list', 'debug');
        AttachmentUtilService.download('/services/swim_course_waiting_list_export', {
          format: 'xlsx',
          type: 'subscription_contact_information_sheet',
          swimCourseSet: setId,
          swimCourseLevel: levelId
        });
      },
      downloadPupilList: function (seriesIds, groupIds, swimCourseSeriesGroupIds) {
        var options = {
          format: 'xlsx',
          type: 'subscription_contact_information_sheet'
        };

        // convert arrays to strings
        if (angular.isArray(seriesIds)) {
          seriesIds = seriesIds.join(',');
        }
        if (angular.isArray(groupIds)) {
          groupIds = groupIds.join(',');
        }
        if (angular.isArray(swimCourseSeriesGroupIds)) {
          swimCourseSeriesGroupIds = swimCourseSeriesGroupIds.join(',');
        }

        // required parameter (for now)
        options['swimCourseSeries[]'] = seriesIds;

        // attach optional parameters
        if (UtilService.isNotEmpty(groupIds)) {
          options['swimCourseGroups[]'] = groupIds;
        }
        if (UtilService.isNotEmpty(swimCourseSeriesGroupIds)) {
          options['swimCourseSeriesGroups[]'] = swimCourseSeriesGroupIds;
        }

        // proceed with download
        LogService.log('Downloading pupil list', 'debug');
        AttachmentUtilService.download('/services/swim_course_series_groups_export', options);
      },
      noteSessionAttendanceByBoolean: function (setId, seriesId, sessionId, subscriptionId, seriesGroupId, statusBool, participantId) {
        // this function is just a wrapper around noteSessionAttendanceByStatusCode()
        // to prevent the controllers from having to know which status codes to use
        var statusCode = statusBool ? 'attended' : 'accepted';
        return this.noteSessionAttendanceByStatusCode(setId, seriesId, sessionId, subscriptionId, seriesGroupId, statusCode, participantId);
      },
      noteSessionAbsentByBoolean: function (setId, seriesId, sessionId, subscriptionId, seriesGroupId, statusBool, participantId) {
        var statusCode = statusBool ? 'absent' : 'accepted';
        return this.noteSessionAttendanceByStatusCode(setId, seriesId, sessionId, subscriptionId, seriesGroupId, statusCode, participantId);
      },
      noteSessionAttendanceByStatusCode: function (setId, seriesId, sessionId, subscriptionId, seriesGroupId, statusCode, participantId) {
        return new Promise(function (resolve, reject) {
          var statusId = '',
              restObject = {};

          if (!(setId && seriesId && sessionId && subscriptionId && seriesGroupId && statusCode)) {
            reject('SwimCourseManagementFactory::noteSessionAttendanceByStatusCode() -> not all required arguments were passed');
          }

          SwimCourseParticipantStatusFactory.getStatusByCode(statusCode)
          .then(function (statusObject) {
            statusId = statusObject.id;
            restObject = {
              swimCourseSubscription: subscriptionId,
              swimCourseSeriesGroup: seriesGroupId,
              swimCourseParticipantStatus: statusId
            };

            // patch or post, depending on wether we got a participant id
            if (UtilService.isNotEmpty(participantId)) {
              LogService.log('Patching swim course session attendance with statuscode' + statusCode, 'debug');
              // patch participant entity
              SwimCourseSetFactory.one(setId)
              .one('series').one(seriesId)
              .one('sessions').one(sessionId)
              .one('participants').one(participantId)
              .patch(restObject)
              .then(function () {
                resolve();
              });
            } else {
              LogService.log('Posting swim course session attendance with statuscode' + statusCode, 'debug');
              // post participant entity
              SwimCourseSetFactory.one(setId)
              .one('series').one(seriesId)
              .one('sessions').one(sessionId)
              .post('participants', restObject)
              .then(function () {
                resolve();
              });
            }
          });
        });
      },
      getAttendableSwimCourseSessions: function (contactId, customerId, journalTypes, oneHourAgo, onlyToday, oneMonth) {
        var timeOneHourAgo = new Date(),
            params = {
              limit: 99,
              sort: 'startsAt,ASC'
            },
            currentFilterString = null,
            tomorrow = new Date(),
            timeOneMonth = new Date();

        // set the dates and convert to string
        tomorrow.setDate(tomorrow.getDate() + 1);
        // set tomorrow to first hour of the next day
        tomorrow.setHours(24, 0, 0, 0);
        tomorrow = tomorrow.toISOString();
        timeOneHourAgo.setHours(timeOneHourAgo.getHours() - 1);
        timeOneHourAgo = timeOneHourAgo.toISOString();

        timeOneMonth.setDate(timeOneMonth.getDate() + 30);
        timeOneMonth.setHours(24, 0, 0, 0);
        timeOneMonth = timeOneMonth.toISOString();

        params['filter[]'] = [];

        LogService.log('Loading attendable swim course sessions for' + contactId, 'debug');

        // default to showing only sessions from max. an hour ago
        if (!UtilService.isNotEmpty(oneHourAgo) || oneHourAgo) {
          params['filter[]'].push('startsAt,GTE ' + timeOneHourAgo);
        }

        // default to showing only sessions for today
        if (!UtilService.isNotEmpty(onlyToday) || onlyToday) {
          params['filter[]'].push('startsAt,LTE ' + tomorrow);
        }

        // default to showing only sessions of the coming 30 days
        if (!UtilService.isNotEmpty(oneMonth) || oneMonth) {
          params['filter[]'].push('startsAt,LTE ' + timeOneMonth);
        }

        // construct a filter for journal types
        if (UtilService.isNotEmpty(journalTypes)) {
          if (angular.isString(journalTypes)) {
            journalTypes = journalTypes.split(',');
          }
          currentFilterString = 'OR';
          angular.forEach(journalTypes, function (currentTypeId) {
            currentFilterString += ',swimCourseSeries.swimCourseSet.journalTypes.id,' + currentTypeId;
          });

          params['filter[]'].push(currentFilterString);
        }

        return attendableSessionsService
        .one('customer').one(customerId)
        .one('contact').one(contactId).get(params);
      },
      getSeries: function (setId) {
        return setsService.one(setId).getList('series', {limit: 99});
      },
      getSwimCourseGroupLabelWithPeriod: function (group) {
        var firstDate, lastDate, labelWithPeriod, serie;

        serie = group.swimCourseSeries;
        firstDate = serie.startsAt;
        lastDate = firstDate;
        angular.forEach(serie.swimCourseSessions, function (session) {
          if (session.startsAt > lastDate) {
            lastDate = session.startsAt;
          }
          if (session.startsAt < firstDate) {
            firstDate = session.startsAt;
          }
        });
        labelWithPeriod = group.swimCourseGroup.label + ' (' + moment(firstDate).format('DD/MM/YYYY');
        if (firstDate !== lastDate) {
          labelWithPeriod += '-' + moment(lastDate).format('DD/MM/YYYY');
        }
        labelWithPeriod += ')';

        return labelWithPeriod;
      }
    };
  }
}());
