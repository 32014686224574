(function () {
  'use strict';

  angular
    .module('reports')
    .config(config);

  function config($stateProvider) {
    var scheduledReportsReturnState = 'reports',
        scheduledReportsReturnStateParams = {tab: 'scheduledReports'};

    $stateProvider
      .state('reports', {
        url: '/reports?tab&loadFilters?loadCheckboxState',
        templateUrl: 'reports/views/reports.tpl.html',
        controller: 'ReportsCtrl',
        controllerAs: 'reports',
        params: {
          currentPage: null
        }
      })
      .state('sessiondetail', {
        url: '/session/:groId/:insId/:sesId?indexPage?backTab',
        templateUrl: 'reports/views/reports.session.sales.tpl.html',
        controller: 'PosSessionSalesListCtrl',
        controllerAs: 'posSessionSalesListCtrl'
      })
      .state('digipolis_order', {
        url: '/digipolis_orders/:id?backTo?searchOnAllSites',
        templateUrl: 'reports/views/reports.digipolis_order.tpl.html',
        controller: 'DigipolisOrdersCtrl',
        controllerAs: 'digipolisOrdersCtrl'
      })
      .state('scheduled_reports', {
        url: '/scheduled_reports',
        abstract: true
      })
      .state('scheduled_reports.edit', {
        url: '/:id/edit',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'reports/views/scheduled_reports.add.edit.modal.tpl.html',
            controller: 'ScheduledReportCtrl',
            controllerAs: 'scheduledReportCtrl'
          }).result.then(function () {
            $state.go(scheduledReportsReturnState, scheduledReportsReturnStateParams, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go(scheduledReportsReturnState, scheduledReportsReturnStateParams, {reload: true});
            }
          });
        }]
      })
      .state('scheduled_reports.new', {
        url: '/new',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'reports/views/scheduled_reports.add.edit.modal.tpl.html',
            controller: 'ScheduledReportCtrl',
            controllerAs: 'scheduledReportCtrl'
          }).result.then(function () {
            $state.go(scheduledReportsReturnState, scheduledReportsReturnStateParams, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go(scheduledReportsReturnState, scheduledReportsReturnStateParams, {reload: true});
            }
          });
        }]
      })
      .state('scheduled_reports.delete', {
        url: '/:id/delete',
        onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
          $modal.open({
            templateUrl: 'reports/views/scheduled_reports.delete.modal.tpl.html',
            controller: 'ScheduledReportCtrl',
            controllerAs: 'scheduledReportCtrl'
          }).result.then(function () {
            $state.go(scheduledReportsReturnState, scheduledReportsReturnStateParams, {reload: true});
          }, function (reason) {
            if (reason !== 'skip') {
              $state.go(scheduledReportsReturnState, scheduledReportsReturnStateParams, {reload: true});
            }
          });
        }]
      });
  }
}());
