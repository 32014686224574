(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:SaleViewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('SaleViewCtrl',
 SaleViewCtrl);

  function SaleViewCtrl(
                         $modal,
                         $modalInstance,
                         $timeout,
                         CurrentUserContextFactory,
                         HistoryFactory,
                         HistoryService,
                         sale,
                         SaleFactory,
                         SettingsService,
                         TicketFactory,
                         UserFactory,
                         UtilService,
                         LogService,
                         Restangular) {
    var vm = this;
    vm.sale = sale;
    vm.relatedTo = [];
    vm.cancel = cancelModalInstance;
    vm.printDuplicateTicket = printDuplicateTicket;
    vm.showHistoryModal = showHistoryModal;
    vm.historyFilter = historyFilter;
    vm.modalResultHandler = modalResultHandler;
    vm.modalFailureHandler = modalFailureHandler;
    vm.openReference = openReference;
    vm.openUnlinkConfirmationModal = openUnlinkConfirmationModal;
    vm.enableInternalComments = SettingsService.get('pos.saleItem.enableInternalComments');
    vm.enableSalesLinking = SettingsService.get('pos.enableSalesLinking', false);
    vm.enableSaleItemHistory = SettingsService.get('accountancy.enableSaleItemHistory');
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.enableWebshop = SettingsService.get('products.enableProductPropertyVisibleInWebshop', false);
    vm.UtilService = UtilService;
    vm.addLinkedSale = addLinkedSale;
    vm.showLinkSaleModal = showLinkSaleModal;
    vm.loadDetailsForSale = loadDetailsForSale;
    vm.infoForSaleLoaded = false;
    vm.approvalFlow34 = (SettingsService.get('pos.session.approvalFlowVersion') === '3' || SettingsService.get('pos.session.approvalFlowVersion') === '4');
    vm.linkedWithSales = [];
    vm.saleIceSkateRental = SettingsService.get('saleIceSkateRental', false);
    vm.iceSkateRental = iceSkateRental;
    vm.checkRentalLabel = checkRentalLabel;
    vm.loadDetailsForSale();

    function printDuplicateTicket() {
      return TicketFactory.printTicket(vm.sale, true);
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function showHistoryModal(id) {
      // Check if modal view is open
      if (vm.modalOpened) {
        return;
      }

      UtilService.showModal(
        {
          templateUrl: 'utils/views/history.view.modal.tpl.html',
          controller: 'HistoryViewCtrl',
          controllerAs: 'historyViewCtrl',
          resolve: {
            entityId: function () {
              return id;
            },
            entityName: function () {
              return 'app.item';
            },
            keys: function () {
              return [
                'productId',
                'discountId',
                'saleId',
                'price',
                'quantity',
                'comments',
                'parameters',
                'deletedAt',
                'internalComments'
              ];
            }
          }
        },
        modalResultHandler,
        modalFailureHandler,
        true
      );
    }

    function modalResultHandler() {
    }

    function modalFailureHandler() {
    }

    function historyFilter(item) {
      return !(UtilService.isEmpty(item.history));
    }

    function openUnlinkConfirmationModal(item) {
      var removedLinkedSale;
      console.log(item);

      UtilService.showTranslatedConfirmationModal(
        'sale.unlink.confirm_title',
        'sale.unlink.confirm_body',
        function (resolve) {
          if (resolve) {
            vm.linkedWithSales.splice(vm.linkedWithSales.indexOf(item), 1);
            SaleFactory.one(vm.sale.id).patch(
              {
                facility: vm.sale.facility.id,
                customer: angular.isDefined(vm.sale.customer) ? vm.sale.customer.id : null,
                customerContact: angular.isDefined(vm.sale.customerContact) ? vm.sale.customerContact.id : null,
                pointOfSaleSession: vm.sale.pointOfSaleSession.id,
                linkedWithSales: vm.linkedWithSales.map(function (linkSale) {
                  return linkSale.id;
                }),
                invoiceRequested: vm.sale.invoiceRequested,
                saleStatus: vm.sale.saleStatus.id
              }
            ).then(
              function (res) {
                LogService.log('patched sale ' + vm.saleId, 'debug');
                console.log(res);
                removedLinkedSale = vm.relatedTo.filter(function (linkedSale) {
                  return linkedSale.id === item.id;
                });
                if (angular.isDefined(removedLinkedSale[0])) {
                  vm.relatedTo.splice(vm.relatedTo.indexOf(removedLinkedSale[0]), 1);
                }
              });
          }
        });
    }

    function openReference(relatedSale) {
      /* you cannot show two modals with same combination of controller and view
      lets close actual session and create new onw. Timeout to wait till actual windows is properly closed.
       */
      vm.cancel();
      $timeout(function () {
        UtilService.showModal({
          templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',

          controller: 'SaleViewCtrl',

          controllerAs: 'saleViewCtrl',

          resolve: {
            sale: function () {
              return relatedSale;
            }
          }
        },
            function () {
            },
            function () {

            },
            true);
      },
        200);
    }

    function addLinkedSale(linkedSale) {
      var linkedWithSales = [];
      if (angular.isDefined(linkedSale)) {
        vm.linkedWithSales.push(linkedSale);
        linkedWithSales = vm.linkedWithSales.map(function (linkSale) {
          return linkSale.id;
        });
        SaleFactory.one(vm.sale.id).patch({linkedWithSales: linkedWithSales})
          .then(function () {
            LogService.log('patched sale ' + vm.saleId, 'debug');
            vm.loadDetailsForSale();
          }, function (errorMsg) {
            console.error(errorMsg);
          });
      }
    }

    function showLinkSaleModal() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.link-sale.modal.html',
        controller: 'LinkSaleCtrl',
        controllerAs: 'linkSaleCtrl',
        size: 'lg',
        resolve: {}
      }, function (linkedSale) {
        vm.addLinkedSale(linkedSale);
      }, function () {

      },
      true);
    }

    function loadDetailsForSale() {
      SaleFactory.loadSaleDetails(vm.sale).then(function () {
        $timeout(function () {
          if (vm.sale.linkedWithSales.length > 0) {
            vm.relatedTo = vm.sale.linkedWithSales;
            vm.linkedWithSales = vm.sale.linkedWithSales;
          }
          if (vm.sale.linkedToSales.length > 0) {
            angular.forEach(vm.sale.linkedToSales, function (linkedSale) {
              vm.relatedTo.push(linkedSale);
            });
          }
          vm.infoForSaleLoaded = true;
          // load history info
          if (vm.enableSaleItemHistory) {
            angular.forEach(vm.sale.products, function (product) {
              var parsedData,
                  queryParams = {
                    context: CurrentUserContextFactory.getUserContextId(),
                    id: product.id
                  };
              HistoryFactory.getList(queryParams).then(function (history) {
                product.history = [];
                // iterate and filter each item
                angular.forEach(history, function (historyItem) {
                  parsedData = HistoryService.getVersionedData(historyItem.versionedData, ['comments', 'internalComments']);
                  historyItem.parsedData = parsedData;
                  if (UtilService.isNotEmpty(parsedData)) {
                    product.history.push(historyItem);
                    // get user info of user what caused the change
                    UserFactory.one(historyItem.cause).get().then(function (user) {
                      historyItem.cause = user;
                    });
                  }
                });
                // hide history, if there were no updates
                if (product.history.length === 1) {
                  product.history = null;
                }
              });
            });
          }
        });
      });
    }

    function iceSkateRental(action, saleItemId) {
      if (angular.isUndefined(action)) {
        action = 'rent';
      }

      if (angular.isUndefined(saleItemId)) {
        return;
      }

      Restangular.one('access_control_identification').one('sales').one(vm.sale.id).one('items').one(saleItemId).one('ice_skate_rental').patch({action: action}).then(function () {
        LogService.log('patched saleItem ' + saleItemId, 'debug');
        vm.loadDetailsForSale();
      }, function (errorMsg) {
        console.error(errorMsg);
      });
    }

    function checkRentalLabel(productLabel) {
      return productLabel.toLowerCase().search('huur schaatsen') !== -1;
    }
  }
}());
