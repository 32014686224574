(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:GroupColorEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('GroupColorEditCtrl', GroupColorEditCtrl);

  function GroupColorEditCtrl(
    $modalInstance,
    UtilService,
    SwimCourseSetFactory,
    set,
    series,
    seriesGroup
  ) {
    var vm = this;

    vm.set = set;
    vm.series = series;
    vm.seriesGroup = seriesGroup;
    vm.colors = UtilService.getSportoaseColors();
    vm.groupColor = vm.seriesGroup === null || (angular.isUndefined(vm.seriesGroup.tags) || angular.isUndefined(vm.seriesGroup.tags[0])) ? false : vm.seriesGroup.tags[0];
    // fallback to swim course group color
    if (vm.groupColor === false && vm.seriesGroup) {
      vm.groupColor = vm.seriesGroup.swimCourseGroup === null || (angular.isUndefined(vm.seriesGroup.swimCourseGroup.tags) || angular.isUndefined(vm.seriesGroup.swimCourseGroup.tags[0])) ? false : vm.seriesGroup.swimCourseGroup.tags[0];
    }

    vm.setGroupColor = setGroupColor;
    vm.cancel = cancelModalInstance;
    vm.saveGroupColor = saveGroupColor;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function saveGroupColor() {
      // Save color
      SwimCourseSetFactory.one(vm.set.id)
      .one('series')
      .one(vm.series.id)
      .one('groups')
      .one(vm.seriesGroup.id).patch({
        tags: [vm.groupColor]
      }).then(function (result) {
        $modalInstance.close(result);
      });
    }

    function setGroupColor(color) {
      vm.groupColor = color;
    }
  }
}());
