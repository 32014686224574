(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.service:FitnessEventParticipationService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .service('FitnessEventParticipationService', FitnessEventParticipationService);

  function FitnessEventParticipationService(
    $filter,
    LogService,
    $q,
    hwproxy,
    EventFactory,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    PermissionsUtilService,
    RestUtilService
  ) {
    var vm = this;

    // variables
    vm.eventInstance = undefined;
    vm.customerContact = undefined;
    vm.participantStatuses = [];
    vm.participantTypes = [];
    vm.participantObj = null;
    vm.myParticipation = undefined;
    vm.participations = [];

    // methods
    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.init = init;
    vm.participation = participation;
    vm.subscribe = subscribe;
    vm.multipleConfirm = multipleConfirm;

    // run init
    if (hwproxy.enabled()) {
      vm.init();
    }

    function participation(eventInstance, customerContact) {
      var deferred = $q.defer(),
          myParticipation = {
            cancel: function () {
              var participationDeferred = $q.defer();
              vm.cancel(this).then(function (participationObj) {
                participationDeferred.resolve(participationObj);
              });
              return participationDeferred.promise;
            },
            confirm: function () {
              var participationDeferred = $q.defer();
              vm.confirm(this, customerContact).then(function (participationObj) {
                if (angular.isDefined(myParticipation.myParticipation.reservationsCount) && myParticipation.myParticipation.reservationsCount > 0) {
                  myParticipation.myParticipation.reservationsCount -= 1;
                }
                participationDeferred.resolve(participationObj);
              });
              return participationDeferred.promise;
            },
            multipleConfirm: function () {
              var participationDeferred = $q.defer();

              vm.multipleConfirm(this, customerContact).then(function (participationObj) {
                if (angular.isDefined(myParticipation.myParticipation.reservationsCount) && myParticipation.myParticipation.reservationsCount > 0) {
                  myParticipation.myParticipation.reservationsCount -= 1;
                }
                participationDeferred.resolve(participationObj);
              });
              return participationDeferred.promise;
            },
            subscribe: function () {
              var participationDeferred = $q.defer();
              vm.subscribe(this).then(function (participationObj) {
                participationDeferred.resolve(participationObj);
              });
              return participationDeferred.promise;
            }
          };
      LogService.log('new EventParticipationService instance', 'debug');
      LogService.log('EventParticipationService.eventInstanceId' + eventInstance.id, 'debug');
      if (angular.isString(eventInstance)) {
        LogService.log('EventParticipationService.participation eventInstance is string' + eventInstance, 'debug');
      } else {
        myParticipation.eventInstance = eventInstance;
      }
      if (angular.isString(customerContact)) {
        LogService.log('EventParticipationService.participation customerContact is string' + customerContact, 'debug');
      } else {
        myParticipation.customerContact = customerContact;
      }
      myParticipation.myParticipation = eventInstance.eventParticipants.filter(function (participant) {
        if (participant.contact.id === customerContact.contact.id && participant.eventParticipantStatus.code !== 'REJECTED') {
          return participant;
        }
      })[0];
      myParticipation.myParticipations = eventInstance.eventParticipants.filter(function (participant) {
        if (participant.contact.id === customerContact.contact.id && participant.eventParticipantStatus.code !== 'REJECTED') {
          return participant;
        }
      });
      if (angular.isDefined(myParticipation.myParticipation)) {
        myParticipation.myParticipation.reservationsCount = eventInstance.eventParticipants.filter(function (participant) {
          if (participant.contact.id === customerContact.contact.id && participant.eventParticipantStatus.code !== 'REJECTED' && participant.eventParticipantStatus.code !== 'ATTENDED') {
            return participant;
          }
        }).length;
      }

      deferred.resolve(myParticipation);
      return deferred.promise;
    }

    function cancel(participationObj) {
      var deferred = $q.defer(),
          participantObject = {
            eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
              return ps.code === 'REJECTED';
            })[0].id
          };
      LogService.log('EventParticipationService.cancel' + participationObj.myParticipation, 'debug');

      // Set Rejected
      EventParticipantFactory.one(participationObj.myParticipation.id).patch(participantObject).then(function () {
        LogService.log('Rejected', 'debug');
        participationObj.myParticipation = undefined;
        deferred.resolve(participationObj);
      });
      return deferred.promise;
    }

    function confirm(participationObj, customerContact) {
      var deferred = $q.defer(),
          participantObject = {
            eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
              return ps.code === 'ATTENDED';
            })[0].id
          };
      LogService.log('EventParticipationService.confirm' + participationObj.myParticipation, 'debug');

      // Set Atended
      EventParticipantFactory.one(participationObj.myParticipation.id).patch(participantObject).then(function () {
        LogService.log('Attended', 'debug');
        participationObj.myParticipation.eventParticipantStatus.code = 'ATTENDED';

        // print ticket
        hwproxy.sendFitnessAttendanceInfoToPrinter(participationObj, customerContact);

        deferred.resolve(participationObj);
      });
      return deferred.promise;
    }

    function multipleConfirm(participationObj, customerContact) {
      var deferred = $q.defer(),
          currentParticipations,
          participantObject = {
            eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
              return ps.code === 'ATTENDED';
            })[0].id
          };
      LogService.log('EventParticipationService.confirm' + participationObj.myParticipation, 'debug');

      currentParticipations = participationObj.myParticipations.filter(function (participant) {
        if (participant.contact.id === customerContact.contact.id && participant.eventParticipantStatus.code === 'ACCEPTED') {
          return participant;
        }
      });
      if (angular.isDefined(currentParticipations[0])) {
        // Set Atended
        participationObj.myParticipations.filter(function (participant) {
          if (participant.id === currentParticipations[0].id) {
            return participant;
          }
        })[0].eventParticipantStatus.code = 'ATTENDED';
        EventParticipantFactory.one(currentParticipations[0].id).patch(participantObject).then(function () {
          LogService.log('Attended id ' + currentParticipations[0].id, 'debug');
          participationObj.myParticipation.eventParticipantStatus.code = 'ATTENDED';
          // print ticket
          hwproxy.sendFitnessAttendanceInfoToPrinter(participationObj, customerContact);
          deferred.resolve(participationObj);
        }, function () {
          LogService.log('Not attended' + currentParticipations[0], 'debug');
          participationObj.myParticipations.filter(function (participant) {
            if (participant.id === currentParticipations[0].id) {
              return participant;
            }
          })[0].eventParticipantStatus.code = 'ACCEPTED';
          deferred.resolve(participationObj);
        });
        return deferred.promise;
      }
    }

    function init() {
      RestUtilService.getFullList(EventParticipantStatusFactory, {limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
        vm.participantStatuses = resultStatuses;
      });
      EventFactory.one('participants').getList('types', {limit: 99, sort: 'label,asc'}).then(function (resultTypes) {
        vm.participantTypes = resultTypes;
        vm.participantObj = $filter('filter')(vm.participantTypes, function (pt) {
          return pt.code === 'PARTICIPANT';
        })[0];
      });
    }

    function subscribe(participationObj) {
      var deferred = $q.defer(),
          participantObject = {};
      LogService.log('EventParticipationService.subscripbe' + participationObj.myParticipation, 'debug');
      LogService.log('EventParticipationService.eventInstanceId' + participationObj.eventInstance.id, 'debug');

      if (angular.isDefined(participationObj.myParticipation)) {
        // from waitinglist
        participantObject = {
          eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
            return ps.code === 'ACCEPTED';
          })[0].id
        };
        // Set Accepted
        EventParticipantFactory.one(participationObj.myParticipation.id).patch(participantObject).then(function () {
          LogService.log('Accepted', 'debug');
          participationObj.myParticipation.eventParticipantStatus.code = 'ACCEPTED';
          deferred.resolve(participationObj);
        });
      } else {
        // new subscription
        participantObject = {
          eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
            return ps.code === 'PENDING';
          })[0].id,
          eventParticipantType: vm.participantObj.id,
          event: participationObj.eventInstance.event.id,
          eventInstance: participationObj.eventInstance.id,
          contact: participationObj.customerContact.contact.id,
          site: participationObj.eventInstance.event.site.id
        };

        LogService.log('EventParticipationService.subscribe.participantObject' + participantObject, 'debug');
        EventParticipantFactory.post(participantObject).then(function (newParticipation) {
          participationObj.myParticipation = newParticipation;
          if (participationObj.myParticipation.eventParticipantStatus.code === 'TENTATIVE') {
            participantObject = {
              eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
                return ps.code === 'ACCEPTED';
              })[0].id
            };
            // Set Accepted
            EventParticipantFactory.one(newParticipation.id).patch(participantObject).then(function () {
              LogService.log('Accepted:' + newParticipation, 'debug');
              participationObj.myParticipation.eventParticipantStatus.code = 'ACCEPTED';
              deferred.resolve(participationObj);
            }, function () {
              LogService.log('Not Accepted' + newParticipation, 'debug');
              deferred.resolve(false);
            });
          } else {
            LogService.log('Waiting List', 'debug');
            deferred.resolve(participationObj);
          }
        });
      }
      return deferred.promise;
    }

    return vm;
  }
}());
