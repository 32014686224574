(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.service:EventService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('event')
    .factory('EventInstanceService', EventInstanceService);

  function EventInstanceService(
    moment
  ) {
    return {
      getDuration: function (eventInstance) {
        var startsAt = moment(eventInstance.startsAt),
            endsAt = moment(eventInstance.endsAt);
        return moment.duration(endsAt.diff(startsAt)).asMinutes();
      }
    };
  }
}());
