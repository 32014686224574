(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:PosPaymentCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('PosPaymentCtrl', PosPaymentCtrl);

  function PosPaymentCtrl($cookies,
                          $filter,
                          $modalInstance,
                          $rootScope,
                          $scope,
                          $state,
                          $timeout,
                          $translate,
                          $interval,
                          billTotal,
                          billCustomer,
                          billItems,
                          $q,
                          hwproxy,
                          saleId,
                          CurrentUserContextFactory,
                          CustomerManagementFactory,
                          DirectDebitFactory,
                          LogService,
                          PaymentEngineFactory,
                          PaymentMethodsFactory,
                          PosSaleService,
                          ProductUtilService,
                          Restangular,
                          SaleFactory,
                          SaleStatusFactory,
                          SurveyQueryService,
                          SettingsService,
                          TicketFactory,
                          ToastrNotificationService,
                          $modal,
                          UtilService,
                          PARAMS,
                          CurrentPosInstanceFactory) {
    var vm = this,
        expDate,
        today = new Date(),
        oldPaymentAmount = billTotal,
        paymentsParams = {
          limit: 99,
          sort: 'weight,asc'
        };
    vm.settingsService = SettingsService;
    vm.isFirstOpening = true;
    vm.amountToPay = billTotal;
    vm.paymentAmount = billTotal;
    vm.setPaymentAmount = setPaymentAmount;
    vm.pay = pay;
    vm.payments = [];
    vm.paymentMethods = [];
    vm.setPaymentMethods = setPaymentMethods;
    vm.queuedPayment = null;
    vm.createPayment = createPayment;
    vm.paymentsTotal = paymentsTotal;
    vm.changeTotal = changeTotal;
    vm.cancelModalInstance = cancelModalInstance;
    vm.selectNewInvoiceCustomer = selectNewInvoiceCustomer;
    vm.removeNewInvoiceCustomer = removeNewInvoiceCustomer;
    vm.isElectronicPayment = isElectronicPayment;
    vm.paymentIsDone = paymentIsDone;
    // payment is finished when it's done and after all finishing tasks are completed/failed
    // we bind the enabling of pos.views.payment.start-new-sale to it
    vm.paymentIsFinished = false;
    vm.printTicket = printTicket;
    vm.finishPayment = finishPayment;
    vm.billItems = billItems;
    vm.billCustomer = billCustomer;
    vm.saleId = saleId;
    vm.addToPaymentAmount = addToPaymentAmount;
    vm.addToScreen = addToScreen;
    vm.setDecimals = setDecimals;
    vm.resetScreen = resetScreen;
    vm.removeDigit = removeDigit;
    vm.screenTouched = false;
    vm.setInvoiceRequestedOnSale = setInvoiceRequestedOnSale;
    vm.lockInvoiceRequestedOnSale = false;
    vm.invoiceRequested = billCustomer ? billCustomer.customer.invoicePreferred : false;
    vm.toggleInvoiceRequested = toggleInvoiceRequested;
    vm.invoiceCustomerIsAllowed = invoiceCustomerIsAllowed;
    vm.customerChecks = 0;
    vm.showCustomer = showCustomer;
    vm.paymentInProgress = false;
    vm.yomaniWaitingForResponse = false;
    vm.yomaniProgressBarStartTime = null;
    vm.yomaniProgressBarInterval = null;
    vm.yomaniProgressBarValue = null;
    vm.kioskPaymentInProgress = false;
    vm.allPaymentMethodsCache = [];
    vm.paymentMethodsCache = [];
    vm.isKeypadDisabled = isKeypadDisabled;
    vm.checkPaymentMethod = checkPaymentMethod;
    // Immediate journal consumption modal
    vm.showConsumeJournalModal = showConsumeJournalModal;
    vm.collectJournalProducts = collectJournalProducts;
    vm.journalProductWasSold = journalProductWasSold;
    vm.noDiscountPaymentMethod = noDiscountPaymentMethod;
    vm.noElectronicPaymentMethod = noElectronicPaymentMethod;
    vm.sendDiscountPayment = sendDiscountPayment;
    vm.openCashDrawer = openCashDrawer;
    vm.initializeYomaniWaitingProcess = initializeYomaniWaitingProcess;
    vm.finishYomaniWaitingProcess = finishYomaniWaitingProcess;
    vm.addYomaniDataToPaymentObjectParameters = addYomaniDataToPaymentObjectParameters;
    vm.addPaymentKioskDataToPaymentObjectParameters = addPaymentKioskDataToPaymentObjectParameters;
    vm.answerSelected = answerSelected;
    vm.signAmount = signAmount;
    vm.enableOpenCashdrawerButton = !SettingsService.get('pos.disableOpenCashdrawerButton', false);
    vm.enableTwoEuroPieceButton = !SettingsService.get('pos.disableTwoEuroPieceButton', false);
    vm.standaloneMode = SettingsService.get('pos.standaloneMode', false);
    vm.useExternalCart = SettingsService.get('pos.useExternalCartEngine', false);
    vm.createNewPOSSessionOnInvoiceRequested = SettingsService.get('pos.createNewPOSSessionOnInvoiceRequested', false);
    vm.invoiceRequestedShowCustomerSelect = false;
    vm.roundingProductCode = SettingsService.get('pos.roundingProductCode', null);
    vm.roundingEnabled = SettingsService.get('pos.roundingEnabled', false);
    vm.withoutRoundingAmountToPay = null;
    // if you need change default value, check pls also OpenSport/DigipolisBundle/Controller/DigipolisNotificationsController::handleAcceptedSale
    vm.atosCardTypeSettingRegex = SettingsService.get('pos.atosCardTypeRegex', '^bancontact|^maestro');
    vm.atosCardTypeRegex = new RegExp(vm.atosCardTypeSettingRegex, 'ig');
    vm.invoicingDisableLinkDifferentCustomer = SettingsService.get('pos.invoicingDisableLinkDifferentCustomer', false);
    vm.posRepaymentBancontactEnabled = SettingsService.get('pos.repaymentBancontactEnabled', false);
    vm.useAlternateCustomerForm = SettingsService.get('pos.useAlternateCustomerForm', false);
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.contactTracingEnabled = SettingsService.get('contactTracingEnabled', false);
    vm.showCustomerMissingModal = showCustomerMissingModal;
    vm.missingCustomerModalClosed = false;
    vm.selectCashIsOpen = false;
    vm.surveyQueries = [];
    vm.posInvoiceRequestedDisableCheckCustomerAddress = SettingsService.get('pos.invoiceRequestedDisableCheckCustomerAddress', false);
    vm. posFinishSalePrintTicketEnabled = SettingsService.get('pos.finishSalePrintTicketEnabled', true);
    vm.posPaymentMethodsInternalCommentsRequired = SettingsService.get('pos.paymentMethodsInternalCommentsRequired', 'empty');
    vm.roundingSales = roundingSales;
    vm.roundingOnlyCash = SettingsService.get('pos.roundingOnlyCash', false);
    vm.isRoundingNeeded = isRoundingNeeded;
    vm.roundingItemPrice = 0;
    vm.newDesignVersion = PARAMS.designVersion === 2;
    vm.payconiqPaymentId = null;
    vm.payconiqPaymentInProgress = false;
    vm.paymentObject = null;
    vm.posEnableWristbandAssign = SettingsService.get('posEnableWristbandAssign', false);
    vm.showWristbandModalForm = showWristbandModalForm;
    vm.paymentKioskVersion = SettingsService.get('payment.kioskVersion', '1');
    vm.validateAntenorPayment = validateAntenorPayment;
    vm.antenorPaymentId = null;
    vm.antenorPaymentInProgress = false;
    vm.sendPaymentEngineRequest = sendPaymentEngineRequest;
    vm.cancelAntenorPayment = cancelAntenorPayment;
    vm.antenorAutomaticValidateEnabled = SettingsService.get('antenor.automaticValidateEnabled', true);
    vm.antenorInterval = null;
    vm.initializeAntenorPaymentStatusWaitingProcess = initializeAntenorPaymentStatusWaitingProcess;
    vm.antenorPaymentsPaid = null;
    vm.payconiqAutomaticValidateEnabled = SettingsService.get('payconiq.automaticValidateEnabled', true);
    vm.initializePayconiqPaymentStatusWaitingProcess = initializePayconiqPaymentStatusWaitingProcess;
    vm.payconiqInterval = null;
    vm.newPosSessionForSaleWithInvoiceRequested = null;
    vm.paymentsPayconiqVersion = SettingsService.get('payments.payconiqVersion', '1');
    vm.cancelPaymentViaPaymentEngine = cancelPaymentViaPaymentEngine;
    vm.posDisplayManualCompleteButton = SettingsService.get('posDisplayManualCompleteButton', false);
    vm.posDisplayManualCompleteButtonAfterSeconds = SettingsService.get('posDisplayManualCompleteButtonAfterSeconds', '60');
    vm.showManualCompleteButton = false;
    vm.manualCompleteSale = manualCompleteSale;
    vm.currentPosInstanceKioskDeviceId = undefined;
    vm.manualButtonTimer = null;
    vm.showManualButtonForCompleteSale = showManualButtonForCompleteSale;
    SurveyQueryService.getActiveQueries()
      .then(function (results) {
        vm.surveyQueries = results;
      });

    SaleFactory.one(vm.saleId).patch({invoiceRequested: false, invoiceCustomer: null});

    vm.postSurveyAnswer = function (query) {
      return SurveyQueryService.postSurveyAnswer(vm.saleId, query.id, query.answer)
        .then(function () {
          query.answered = true;
          vm.surveyQueries.splice(0, 1);
        });
    };

    function answerSelected(index) {
      vm.surveyQueries[0].answer = vm.surveyQueries[0].surveyQueryChoices[index].name;
      vm.postSurveyAnswer(vm.surveyQueries[0]);
    }

    // Get the payment-methods
    if (vm.paymentMethodsCache.length > 0) {
      CurrentPosInstanceFactory.getCurrentPosInstance().then(function (instance) {
        if (instance && instance.paymentDeviceIds.hasOwnProperty('kiosk')) {
          vm.currentPosInstanceKioskDeviceId = instance.paymentDeviceIds.kiosk;
        }
        vm.setPaymentMethods();
      });
    } else {
      CurrentPosInstanceFactory.getCurrentPosInstance().then(function (instance) {
        if (instance && instance.paymentDeviceIds.hasOwnProperty('kiosk')) {
          vm.currentPosInstanceKioskDeviceId = instance.paymentDeviceIds.kiosk;
        }
        paymentsParams['filter[]'] = (!billCustomer) ? ['paymentMethod.code,NEQ INVOICE'] : [];
        paymentsParams['filter[]'].push('site.id,' + CurrentUserContextFactory.getSiteId());
        PaymentMethodsFactory.getPosList(paymentsParams)
          .then(processPaymentMethods);
      });
    }
    if (vm.allPaymentMethodsCache.length === 0) {
      PaymentMethodsFactory.getList({limit: 99}).then(function (result) {
        vm.allPaymentMethodsCache = result;
      }, function () {
        $rootScope.$emit('CancelPayment');
        $modalInstance.dismiss('cancel');
      });
    }

    // this handles a 0 amount sale so that the payment modal is opened directly in finished state
    // see also shopping-cart-controller.js -> payBill() which finishes the sale
    if (vm.amountToPay === 0) {
      vm.printTicket(false);

      vm.finishPayment(true).then(function () {
        LogService.log('emit clearShoppingCartEmit: Sale is completed: finishPayment', 'debug');
        $scope.$emit('clearShoppingCartEmit', {message: 'Sale is completed'});
      });
    }

    // check direct debit sales
    vm.directDebitSold = vm.billItems.filter(function (bi) {
      if (angular.isDefined(bi.subscriptionDuration)) {
        return bi;
      }
    }).length;

    if (vm.directDebitSold > 0) {
      vm.customerChecks = 1;
      vm.billCustomer.customer.directDebitContracts = [];
      vm.billCustomer.customer.externalDebtorNumberError = angular.isUndefined(vm.billCustomer.customer.externalDebtorNumber);

      DirectDebitFactory.getList({'filter[]': 'customer.id,' + vm.billCustomer.customer.id, sort: 'createdAt,DESC'})
        .then(function (contracts) {
          vm.customerChecks = contracts.length;
          vm.billCustomer.customer.directDebitContracts = contracts.filter(function (contract) {
            if (contract.hasOwnProperty('termination')) {
              expDate = new Date(contract.termination);
              expDate.setHours(0, 0, 0, 0);
              if (expDate > today) {
                return contract;
              }
            } else {
              return contract;
            }
          });
          vm.customerChecks--;
        });
    }

    hwproxy.sendSaleTotalInfoToDisplay({
      id: vm.saleId,
      amount: vm.amountToPay,
      currency: 'EUR'
    });

    $rootScope.$on(hwproxy.options.yomaniPaymentTerminal.terminalDataEventName, function (event, data) {
      var statusId;

      if (!data || !data.event || !data.event.id) {
        return;
      }

      statusId = data.event.id;
      if (statusId >= 200 && statusId <= 599 && vm.yomaniWaitingForResponse) {
        vm.finishYomaniWaitingProcess();
      }
    });

    $rootScope.$on(hwproxy.options.yomaniPaymentTerminal.transactionAcceptedEventName, function (event, data) {
      /* eslint-disable */
      var paymentReference = data.event.data.transaction_id.toString();

      if (vm.queuedPayment) {
        if (paymentReference === vm.queuedPayment.reference) {
          vm.queuedPayment = vm.addYomaniDataToPaymentObjectParameters(vm.queuedPayment, data.event.data);
          vm.createPayment(vm.queuedPayment);
          vm.queuedPayment = null;
        } else {
          vm.paymentInProgress = false;
        }
      } else {
        vm.paymentInProgress = false;
      }

      /* eslint-enable */
    });

    // NOTICE: If you adjust this, do the same change in OpenSport\DigipolisBundle\Controller\DigipolisNotificationsController -> parseYomaniParameters()
    function addYomaniDataToPaymentObjectParameters(paymentObject, data, isCTEP) {
      var filteredPaymentMethods;

      if (angular.isDefined(isCTEP) && isCTEP === true) {
        paymentObject.parameters = {
          transactionId: data.transactionId,
          transactionIdentifier: data.data.authorizationCode,
          terminalId: data.terminalId,
          cardDisplayId: data.data.clippedPAN,
          brandName: data.data.cardBrandName,
          amount: data.data.authorizedAmount ? data.data.authorizedAmount : paymentObject.amount,
          dateTime: data.data.timestamp,
          merchantId: data.data.acquirerId,
          brandCode: data.data.cardBrandIdentifier
        };
      } else {
        paymentObject.parameters = {
          transactionId: data.transaction_id,
          transactionIdentifier: data.transaction_identifier,
          terminalId: data.term_id,
          cardDisplayId: data.card_id_disp,
          brandName: data.brand_name,
          amount: data.tx_vic_tx_amt ? (data.tx_vic_tx_amt / 100) : paymentObject.amount,
          currency: data.currency,
          dateTime: data.date_time,
          merchantId: data.merchant,
          period: data.period,
          brandCode: data.brand_code,
          cardSequenceNumber: data.card_sequence,
          authCode: data.auth_code,
          readMethod: data.read_method
        };
      }

      filteredPaymentMethods = $filter('filter')(vm.allPaymentMethodsCache, function (pm) {
        return pm.code === 'DEBIT_CARD';
      });
      if (UtilService.isNotEmpty(filteredPaymentMethods)) {
        paymentObject.paymentMethod = filteredPaymentMethods[0].id;
      }

      if (paymentObject.parameters.brandName && UtilService.isEmpty(paymentObject.parameters.brandName.match(vm.atosCardTypeRegex))) {
        filteredPaymentMethods = paymentObject.paymentMethod = $filter('filter')(vm.allPaymentMethodsCache, function (pm) {
          return pm.code === 'CREDIT_CARD';
        });
        if (UtilService.isNotEmpty(filteredPaymentMethods)) {
          paymentObject.paymentMethod = filteredPaymentMethods[0].id;
        }
      }

      return paymentObject;
    }

    // NOTICE: If you adjust this, do the same change in OpenSport\DigipolisBundle\Controller\DigipolisNotificationsController -> parsePaymentKioskParameters()
    function addPaymentKioskDataToPaymentObjectParameters(paymentObject, data) {
      paymentObject.parameters = data;

      return paymentObject;
    }

    function invoiceCustomerIsAllowed() {
      var returnObject = false,
          parentItems = vm.billItems.filter(function (billItem) {
            return billItem.isSubSaleItem === false;
          });
      if (parentItems) {
        returnObject = true;
      }
      return returnObject;
    }

    function showCustomer() {
      $state.go('customerdetail', {
        id: vm.billCustomer.customer.id
      });
      vm.cancelModalInstance();
    }

    function paymentIsDone() {
      // Check if the paymentChange amount is 0 or less than 0
      return (vm.changeTotal() <= 0 && !vm.isFirstOpening) || (vm.changeTotal() === 0);
    }

    function openCashDrawer() {
      LogService.log('PosPaymentCtrl::openCashDrawer() -> Sending open drawer command to printer.', 'debug');
      hwproxy.sendCashDrawerOpenCommandToPrinter();
    }

    function paymentsTotal() {
      var totalPrice = 0;
      // Loop all payments and count the amounts
      angular.forEach(vm.payments, function (payment) {
        if (payment.paymentMethod.code !== 'DISCOUNT') {
          totalPrice += payment.amount;
        }
      });

      if (vm.paymentKioskVersion === '3' && vm.antenorPaymentsPaid) {
        totalPrice = vm.antenorPaymentsPaid;
      }
      // Return the total payment amount
      return totalPrice;
    }

    function changeTotal() {
      // check how much the change is
      return vm.amountToPay - vm.paymentsTotal();
    }

    function addToPaymentAmount(amount) {
      LogService.log('add ' + amount + ' to payment', 'debug');
      vm.screenTouched = false;
      vm.setPaymentAmount(vm.paymentAmount + amount);
    }

    function addToScreen(amount) {
      if (!vm.screenTouched) {
        vm.setPaymentAmount(0);
        vm.screenTouched = true;
      }
      vm.setPaymentAmount((vm.paymentAmount * 10) + (amount / 100));
    }

    function setDecimals() {
      vm.setPaymentAmount(vm.paymentAmount * 100);
    }

    function resetScreen() {
      vm.screenTouched = false;
      vm.setPaymentAmount(vm.changeTotal());
    }

    function removeDigit() {
      vm.screenTouched = true;
      vm.setPaymentAmount(Math.floor(vm.paymentAmount * 10) / 100);
    }

    function setPaymentAmount(paymentAmount) {
      var reloadPaymentMethods = false;

      vm.paymentAmount = paymentAmount;

      // if the sign of payment amount changes, reload payment methods
      reloadPaymentMethods = (paymentAmount !== 0 && oldPaymentAmount * paymentAmount < 0);
      if (paymentAmount !== 0) {
        oldPaymentAmount = paymentAmount;
      }
      if (reloadPaymentMethods) {
        vm.setPaymentMethods();
      }
    }

    function setPaymentMethods() {
      var methods = vm.paymentMethodsCache.slice(0),
          allowBankTransfer = true;

      // show only RE_PAYMENT, PURCHASING, TRANSFER for re-payments with payment engine service enabled
      if (vm.paymentAmount < 0 && PaymentEngineFactory.enabled()) {
        methods = methods.filter(function (x) {
          return (x.code === 'RE_PAYMENT' || x.code === 'PURCHASING' || x.code === 'TRANSFER' ||
            // add also bancontact see #49885
            (vm.posRepaymentBancontactEnabled && x.code === 'ELECTRONIC_PAYMENT') || x.code === 'CHEQUE_OUT');
        });
      // otherwise show everything except RE_PAYMENT and PURCHASING
      } else {
        methods = methods.filter(function (x) {
          return (x.code !== 'RE_PAYMENT' && x.code !== 'PURCHASING' && x.code !== 'CHEQUE_OUT');
        });
      }

      // show only MANUAL_ELECTRONIC_PAYMENT or CBS or PRODUCTION_ERROR or RE_PAYMENT in standalone mode
      if (vm.standaloneMode) {
        methods = methods.filter(function (x) {
          return x.code === 'MANUAL_ELECTRONIC_PAYMENT' || x.code === 'CBS' || x.code === 'PRODUCTION_ERROR' || x.code === 'RE_PAYMENT';
        });
      }

      // hide BANK_TRANSFER if some product has bankTransferAllowed set to false or if a customer is not linked
      angular.forEach(vm.billItems, function (billItem) {
        if (billItem.product.bankTransferAllowed !== true) {
          allowBankTransfer = false;
        }
      });

      if (!allowBankTransfer) {
        methods = methods.filter(function (x) {
          return x.code !== 'BANK_TRANSFER';
        });
      }

      // hide payment engine handled payments if there's no link to payment service
      if (PaymentEngineFactory.enabled()) {
        console.log('PAYMENT KIOSK ID ' + vm.currentPosInstanceKioskDeviceId);
        angular.forEach(PaymentEngineFactory.engines, function (paymentEngine) {
          if (!PaymentEngineFactory.isConnected(paymentEngine.key) || !PaymentEngineFactory.isDeviceAvailable(paymentEngine.key)) {
            methods = methods.filter(function (x) {
              return (x.code !== paymentEngine.paymentMethodCode || (vm.paymentKioskVersion === '3' && vm.currentPosInstanceKioskDeviceId !== '0' && x.code === 'PAYMENT_KIOSK'));
            });
          }
        });
      }

      // hide MANUAL_ELECTRONIC_PAYMENT if there is link to payment service and yomani is connected
      if (PaymentEngineFactory.enabled() && PaymentEngineFactory.isConnected('yomani') && PaymentEngineFactory.isDeviceAvailable('yomani')) {
        methods = methods.filter(function (x) {
          return x.code !== 'MANUAL_ELECTRONIC_PAYMENT';
        });
      }

      vm.paymentMethod = null;
      vm.paymentMethods = methods;
    }

    function setInvoiceRequestedOnSale(invoiceRequested) {
      vm.patchingSale = true;
      return SaleFactory.one(vm.saleId).patch({invoiceRequested: invoiceRequested}).then(function () {
        vm.patchingSale = false;
      });
    }

    function toggleInvoiceRequested() {
      if (!vm.lockInvoiceRequestedOnSale && vm.invoiceRequested) {
        if (!vm.billCustomer && !vm.selectedNewInvoiceCustomer) {
          vm.invoiceRequested = false;
          vm.invoiceRequestedShowCustomerSelect = true;
          ToastrNotificationService.showNotification(
            'error',
            'Payment info',
            $translate.instant('pos.invoice.no_linked_customer'));
        } else if (!checkCustomerAddress(vm.billCustomer) && !vm.posInvoiceRequestedDisableCheckCustomerAddress) {
          vm.invoiceRequested = false;
          ToastrNotificationService.showNotification(
            'error',
            'Payment info',
            $translate.instant('pos.invoice.no_address_on_linked_customer'));
        }
        //show the customer select if there is a customer and setting is false (sportoase), see #49835
        if (vm.billCustomer && !vm.invoicingDisableLinkDifferentCustomer) {
          vm.invoiceRequestedShowCustomerSelect = true;
        }
      } else {
        vm.invoiceRequestedShowCustomerSelect = false;
        if (vm.selectedNewInvoiceCustomer) {
          vm.removeNewInvoiceCustomer();
        }
      }
    }

    function isElectronicPayment() {
      return vm.paymentMethod && vm.paymentMethod.code === 'ELECTRONIC_PAYMENT';
    }

    function initializeYomaniWaitingProcess() {
      var timeoutMsecs = hwproxy.options.yomaniPaymentTerminal.maxTimeoutSecs * 1000;

      vm.yomaniWaitingForResponse = true;
      vm.yomaniProgressBarStartTime = new Date().getTime();

      vm.yomaniProgressBarInterval = $interval(function () {
        vm.yomaniProgressBarValue = (new Date().getTime() - vm.yomaniProgressBarStartTime) / timeoutMsecs;
        if (vm.yomaniProgressBarValue >= 1) {
          vm.finishYomaniWaitingProcess(true);
        }
      }, 100);
    }

    function finishYomaniWaitingProcess(timeout) {
      vm.yomaniWaitingForResponse = false;
      if (vm.yomaniProgressBarInterval !== null) {
        $interval.cancel(vm.yomaniProgressBarInterval);
        vm.yomaniProgressBarInterval = null;
        vm.yomaniProgressBarValue = null;
      }
      if (angular.isDefined(timeout) && timeout === true) {
        vm.paymentInProgress = false;
      }
    }

    function addInvoiceRequestedToSale() {
      var customer;
      return new Promise(function (resolve, reject) {
        if (vm.invoiceRequested && (vm.billCustomer || vm.selectedNewInvoiceCustomer)) {
          // patch
          customer = (vm.selectedNewInvoiceCustomer) ? vm.selectedNewInvoiceCustomer : vm.billCustomer.customer;
          SaleFactory.one(vm.saleId).patch({invoiceCustomer: customer.id, invoiceRequested: true}).then(function () {
            vm.lockInvoiceRequestedOnSale = true;
            LogService.log('new invoiceCustomer for sale', 'debug');
            // when we add an invoice customer to sale, then remove selectedInvoiceCustomer
            // to prevent remove the invoice_customer from sale, see #66463
            vm.selectedNewInvoiceCustomer = null;
            resolve();
          }, function () {
            LogService.log('Can\'t add invoiceCustomer for sale', 'debug');
            reject();
          });
        } else {
          SaleFactory.one(vm.saleId).patch({
            invoiceRequested: false
          }).then(function () {
            resolve();
          }, function () {
            LogService.log('Can\'t add invoiceRequsted for sale', 'debug');
            reject();
          });
        }
      });
    }

    function pay() {
      var paymentObject,
          yomaniResponse,
          paymentEngine = PaymentEngineFactory.getEngineByPaymentMethod(vm.paymentMethod.code),
          bankAccountNumberSet = false,
          contactDataInfo = [],
          defer = $q.defer();

      // replace this by loading button
      if (vm.paymentInProgress) {
        return false;
      }
      vm.paymentInProgress = true;
      vm.screenTouched = false;
      // manual complete sale button
      vm.showManualButtonForCompleteSale();

      LogService.log('payment in progress', 'debug');
      $scope.$emit('checkDiffBetweenCartsEmit', {billItems: billItems, externalDeffer: defer});
      defer.promise.then(function (out) {
        if (out && out.needReload) {
          vm.cancelModalInstance();
        } else {
          return addInvoiceRequestedToSale().then(function () {
            if (!vm.billCustomer && vm.contactTracingEnabled && !vm.missingCustomerModalClosed) {
              vm.paymentInProgress = false;
              vm.showCustomerMissingModal();
              return;
            }
            // with alternate customer form customer contact has to have bank account number set if sale is negative
            if (vm.useAlternateCustomerForm && billTotal < 0 && !vm.automaticApprovalFlow && vm.billCustomer && vm.paymentMethod.code === 'RE_PAYMENT') {
              contactDataInfo = (angular.isDefined(vm.billCustomer.contact.completeContact) && angular.isDefined(vm.billCustomer.contact.completeContact.contactData)) ?
                vm.billCustomer.contact.completeContact.contactData : vm.billCustomer.contact.contact.contactData;
              angular.forEach(contactDataInfo, function (contactData) {
                if (contactData.contactDataType.code === 'BANK_ACCOUNT_NUMBER') {
                  bankAccountNumberSet = true;
                }
              });
              if (bankAccountNumberSet === false) {
                vm.paymentInProgress = false;
                ToastrNotificationService.showNotification(
                  'error',
                  $filter('uconlyfirst')($filter('translate')('customer.views.no_iban')));
                defer.resolve();
                return;
              }
            }
            SaleFactory.one(vm.saleId).get().then(function (sale) {
              vm.saleReference = sale.reference;
              paymentObject = {
                amount: parseFloat(vm.paymentAmount),
                currency: 'EUR',
                paymentMethod: vm.paymentMethod.id,
                reference: (((Number(sale.reference) + (new Date()).getTime()) % 4294967295) + 1).toString()
              };

              if (vm.paymentKioskVersion === '3' && vm.paymentMethod.code === 'PAYMENT_KIOSK') {
                //antenor
                vm.antenorPaymentInProgress = true;
                vm.paymentObject = paymentObject;
                return vm.sendPaymentEngineRequest('kiosk', vm.saleId, sale.reference, paymentObject.amount, paymentObject.currency);
              } else if (PaymentEngineFactory.enabled() && paymentEngine !== null) {
                if (paymentEngine.key === 'kiosk') {
                  vm.kioskPaymentInProgress = false;
                } else if (paymentEngine.key === 'yomani') {
                  vm.initializeYomaniWaitingProcess();
                }
                return PaymentEngineFactory.subscribeToPayment(
                  paymentEngine.key,
                  vm.saleId,
                  sale.reference,
                  paymentObject.amount,
                  paymentObject.currency
                ).then(function (data) {
                  LogService.logToConsole(paymentObject);
                  LogService.logToConsole(data);
                  console.log(data);
                  if (paymentEngine.key === 'yomani') {
                    yomaniResponse = angular.fromJson(data.raw);
                    // check if VIC or CTEP (because each has a different parameters format in the response data)
                    if (paymentEngine.ctep) {
                      paymentObject = vm.addYomaniDataToPaymentObjectParameters(paymentObject, yomaniResponse, true);
                    } else {
                      paymentObject = vm.addYomaniDataToPaymentObjectParameters(
                        paymentObject,
                        UtilService.isNotEmpty(yomaniResponse.data) ? yomaniResponse.data : {}
                      );
                    }
                    vm.finishYomaniWaitingProcess();
                    LogService.logToConsole(paymentObject);
                    return vm.createPayment(paymentObject);
                  } else if (paymentEngine.key === 'kiosk') {
                    vm.kioskPaymentInProgress = false;

                    return vm.createKioskPayments(paymentObject, data);
                  }
                }, function () {
                  vm.paymentInProgress = false;
                  if (vm.manualButtonTimer) {
                    $timeout.cancel(vm.manualButtonTimer);
                  }

                  if (paymentEngine.key === 'kiosk') {
                    vm.kioskPaymentInProgress = true;
                  } else if (paymentEngine.key === 'yomani') {
                    vm.finishYomaniWaitingProcess();
                  }
                });
              } else if (hwproxy.enabled() && vm.paymentMethod.code === 'ELECTRONIC_PAYMENT') {
                // We'll add a payment if the transaction is accepted
                vm.initializeYomaniWaitingProcess();
                hwproxy.requestYomaniPaymentTerminalPayment(
                  paymentObject.amount,
                  $cookies.get('currentUsername'),
                  paymentObject.reference
                );

                vm.queuedPayment = paymentObject;
              } else if (vm.paymentMethod.code === 'INVOICE' && !vm.invoiceRequested) {
                // first flag sale as invoice requested, then add the payment
                return vm.setInvoiceRequestedOnSale(true)
                  .then(function () {
                    vm.createPayment(paymentObject, true).then(function () {
                      vm.lockInvoiceRequestedOnSale = true;
                    });
                  });
              } else if (vm.paymentMethod.code === 'PAYCONIQ') {
                //PAYCONIQ
                vm.payconiqPaymentInProgress = true;
                vm.paymentObject = paymentObject;
                if (vm.paymentsPayconiqVersion === '2') {
                  return vm.sendPaymentEngineRequest('payconiq', vm.saleId, sale.reference, paymentObject.amount, paymentObject.currency);
                }
                return vm.createPayconiqPaymentRequest(paymentObject);
              } else {
                // Immediately add payment for other payment types
                // and accept external cart manually if it's not a BANK_TRANSFER payment
                //   because BANK_TRANSFER payment does a checkout by itself (see PaymentsController.php)
                //     because it needs to happen before we print the ticket (accepting cart happens after)
                return vm.createPayment(paymentObject, (vm.paymentMethod.code !== 'BANK_TRANSFER'));
              }
            });
          });
        }
      });
    }

    function showConsumeJournalModal() {
      UtilService.showModal({
        templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
        controller: 'HwProxyMemberCardDataCtrl',
        controllerAs: 'HwProxyMemberCardDataCtrl',
        size: 'lg',
        resolve: {
          memberCardData: function () {
            return null;
          },
          customer: function () {
            return vm.billCustomer.customer;
          },
          customerContact: function () {
            return vm.billCustomer.contact;
          }
        }
      }, null, null, true);
      vm.cancelModalInstance(true);
    }

    function journalProductWasSold() {
      var journalsProducts = vm.collectJournalProducts();
      return journalsProducts.length > 0;
    }

    function collectJournalProducts() {
      var cIndex,
          result = [],
          now = new Date();

      angular.forEach(vm.billItems, function (bItem) {
        if (bItem.hasOwnProperty('product') && bItem.product.hasOwnProperty('productComponents')) {
          for (cIndex = 0; cIndex < bItem.product.productComponents.length; ++cIndex) {
            if (bItem.product.productComponents[cIndex].type === 'journal') {
              if (new Date(bItem.startDate) <= now) {
                result.push(bItem);
              }
              break;
            }
          }
        }
      });
      return result;
    }

    function removeNewInvoiceCustomer() {
      // patch remove invoice_customer
      SaleFactory.one(vm.saleId).patch({invoiceCustomer: null}).then(function () {
        vm.selectedNewInvoiceCustomer = null;
        vm.selectedNewInvoiceCustomerContact = null;
        LogService.log('remove invoiceCustomer for sale', 'debug');
      });
    }

    function selectNewInvoiceCustomer() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.customers.list.modal.tpl.html',
        controller: 'PosCustomerListCtrl',
        controllerAs: 'posCustomerListCtrl',
        size: 'lg',
        resolve: {
          customerId: function () {
            return null;
          },
          customerContactTypeId: function () {
            return null;
          }
        }
      }, function (returnValue) {
        if (checkCustomerAddress(returnValue)) {
          vm.selectedNewInvoiceCustomer = returnValue.customer;
          vm.invoiceRequested = true;
          //vm.getCustomerPosDetails(returnValue.customer.id, returnValue.contact.id);
          CustomerManagementFactory.getCustomerDetails(returnValue.customer.id, returnValue.contact.id, ['journals', 'accountancy'])
            .then(function (posDetails) {
              vm.selectedNewInvoiceCustomerContact = posDetails.customerContact;
            }, function () {
              LogService.log('HwProxyMemberCardDataCtrl::getCustomerPosDetails() -> error fetching posDetails', 'debug');
            });
        } else {
          vm.invoiceRequested = false;
          ToastrNotificationService.showNotification(
            'error',
            'Payment info',
            $translate.instant('pos.invoice.no_address_on_linked_customer'));
        }
      }, function (returnValue) {
        LogService.log('Customer Contact Selection Modal Dismissed => reason:' + returnValue, 'debug');
        LogService.logToConsole(returnValue);
      }, true);
    }

    function checkCustomerAddress(info) {
      var contactLocations;
      if (angular.isDefined(info.contact)) {
        contactLocations = (angular.isDefined(info.contact.contact) && angular.isDefined(info.contact.contact.contactLocations)) ?
                            info.contact.contact.contactLocations : info.contact.completeContact.contactLocations;
        return angular.isDefined(contactLocations['0']) && Object.keys(contactLocations['0'].location).length !== 0;
      }
      return false;
    }

    function createPayment(paymentObject, acceptExternalCart) {
      // Add a payment to the list
      return SaleFactory.one(vm.saleId).one('payments').customPOST(paymentObject).then(function () {
        vm.isFirstOpening = false;
        LogService.log('payment complete', 'debug');
        vm.paymentInProgress = false;

        vm.payments.push({
          paymentMethod: vm.paymentMethod,
          amount: vm.paymentAmount
        });

        if (vm.paymentIsDone()) {
          // vm.sendDiscountPayment();
          hwproxy.sendSaleCompleteInfoToDisplay({
            id: vm.saleId,
            currency: paymentObject.currency,
            amount: vm.paymentsTotal(),
            change: vm.changeTotal()
          });

          //see #46614
          if (vm.paymentMethod.code !== 'CBS' && vm.paymentMethod.code !== 'PRODUCTION_ERROR' && vm.posFinishSalePrintTicketEnabled) {
            vm.printTicket(false);
          }

          vm.finishPayment(acceptExternalCart).then(function () {
            LogService.log('emit clearShoppingCartEmit: Sale is completed: finishPayment (acceptExternalCart)', 'debug');
            $scope.$emit('clearShoppingCartEmit', { message: 'Sale is completed' });
          });
        } else {
          hwproxy.sendSalePaymentInfoToDisplay({
            id: vm.saleId,
            amount: paymentObject.amount,
            currency: paymentObject.currency,
            method: vm.paymentMethod.translatedLabel,
            remainder: vm.changeTotal()
          });

          // Reset the input amount and the payment-method
          vm.setPaymentAmount(vm.changeTotal());
          vm.paymentMethod = null;
        }
      }, function (errorMsg) {
        LogService.logToConsole(errorMsg);
        vm.paymentInProgress = false;
        console.error('error: ' + errorMsg);
      });
    }

    vm.createKioskPayments = function (paymentObject, kioskResponseData) {
      var paymentData = angular.fromJson(kioskResponseData.raw),
          returnPaymentObject = {},
          paymentPromises = [];

      // inform about overpayment and missing change
      if (kioskResponseData.type === 'overpaid' && angular.isDefined(paymentData.changeMissing)) {
        ToastrNotificationService.showNotification(
          'warning',
          'Payment info',
          $translate.instant('app.not_enough_change') + ': ' + ProductUtilService.roundPrice(paymentData.changeMissing) + ' ' + paymentObject.currency);
      }

      // set the entered amount to primary payment
      paymentObject.amount = ProductUtilService.roundPrice(paymentData.entered);

      // add kiosk payment data to primary payment
      vm.addPaymentKioskDataToPaymentObjectParameters(paymentObject, paymentData);

      // persist primary payment
      paymentPromises.push(SaleFactory.one(vm.saleId).one('payments').customPOST(paymentObject).then(function () {
        vm.payments.push({
          paymentMethod: vm.paymentMethod,
          amount: paymentObject.amount
        });
      }));

      // create a return payment if some change was returned
      if (angular.isDefined(paymentData.changeReturned) && paymentData.changeReturned > 0) {
        returnPaymentObject.amount = -ProductUtilService.roundPrice(paymentData.changeReturned);
        returnPaymentObject.currency = paymentObject.currency;
        returnPaymentObject.paymentMethod = paymentObject.paymentMethod;
        returnPaymentObject.reference = (parseInt(paymentObject.reference, 10) + 1).toString();

        // add kiosk payment data also to return payment
        vm.addPaymentKioskDataToPaymentObjectParameters(returnPaymentObject, paymentData);

        // persist return payment
        paymentPromises.push(SaleFactory.one(vm.saleId).one('payments').customPOST(returnPaymentObject).then(function () {
          vm.payments.push({
            paymentMethod: vm.paymentMethod,
            amount: returnPaymentObject.amount
          });
        }));
      }

      return Promise.all(paymentPromises).then(function () {
        var saleFinishPromises = [];

        vm.isFirstOpening = false;
        LogService.log('payment complete', 'debug');

        hwproxy.sendSaleCompleteInfoToDisplay({
          id: vm.saleId,
          currency: paymentObject.currency,
          amount: vm.paymentsTotal(),
          change: vm.changeTotal()
        });
        if (vm.posFinishSalePrintTicketEnabled) {
          saleFinishPromises.push(vm.printTicket(false));
        }
        // finish sale even if there is missing change
        saleFinishPromises.push(PosSaleService.saleIsCompleted(vm.saleId).then(function (isCompleted) {
          if (!isCompleted) {
            return SaleStatusFactory.getStatusByCode('COMPLETED').then(function (completedStatus) {
              return SaleFactory.one(vm.saleId).patch({
                saleStatus: completedStatus.id,
                completedAt: (new Date()).toISOString()
              });
            });
          }
        }));

        LogService.log('emit clearShoppingCartEmit: Sale is completed: payment promises', 'debug');
        $scope.$emit('clearShoppingCartEmit', { message: 'Sale is completed' });

        return Promise.all(saleFinishPromises);
      }).finally(function () {
        $timeout(function () {
          vm.paymentInProgress = false;
          vm.paymentIsFinished = true;
        });
      });
    };

    function sendDiscountPayment() {
      var discountAmount = 0,
          paymentObject = {
            currency: 'EUR',
            paymentMethod: $filter('filter')(vm.allPaymentMethodsCache, function (pm) {
              return pm.code === 'DISCOUNT';
            })[0].id,
            reference: (((Number(vm.saleReference) + (new Date()).getTime()) % 4294967295) + 1).toString()
          };
      angular.forEach(vm.billItems, function (billItem) {
        if (billItem.discount) {
          if (billItem.discountType.code === 'AMOUNT') {
            discountAmount += billItem.amount * billItem.discountAmount;
          } else {
            discountAmount += Math.floor(billItem.amount * (billItem.discountPercentage / 100 * billItem.productPrice) * 100) / 100;
          }
        }
      });
      paymentObject.amount = discountAmount;
      if (paymentObject.amount > 0) {
        SaleFactory.one(vm.saleId).one('payments').customPOST(paymentObject).then(function () {
          LogService.log('discount payment added', 'debug');
        });
      }
    }

    function finishPayment(acceptExternalCart) {
      var paymentObject,
          promiseQueue = [];

      // automatically create a cash-back payment if customer paid more
      if (vm.changeTotal() !== 0) {
        paymentObject = {
          amount: Math.round(parseFloat(vm.changeTotal()) * 100) / 100,
          currency: 'EUR',
          paymentMethod: vm.allPaymentMethodsCache.filter(function (pm) {
            return pm.code === 'CASH';
          })[0].id,
          reference: (((Number(vm.saleReference) + (new Date()).getTime()) % 4294967295) + 1).toString()
        };
        promiseQueue.push(SaleFactory.one(vm.saleId).one('payments').customPOST(paymentObject));
      }

      $rootScope.$emit('CancelPayment');

      // accept external cart if used and requested
      if (vm.useExternalCart && !vm.standaloneMode && angular.isDefined(acceptExternalCart) && acceptExternalCart === true) {
        LogService.log('Accepting external cart', 'debug');
        promiseQueue.push(Restangular.one('active-cart').one(vm.saleId).one('accept').customGET().then(function (cartData) {
          LogService.log(cartData, 'debug');
          LogService.logToConsole(cartData);
        }));
      }

      return Promise.all(promiseQueue).then(function () {}, function (errorMsg) {
        LogService.logToConsole(errorMsg);
        console.error('error: ' + errorMsg);
      }).finally(function () {
        $timeout(function () {
          vm.paymentIsFinished = true;
        });
      });
    }

    vm.cancelKioskPayment = function () {
      return PaymentEngineFactory.cancelPayment(vm.saleReference).then(function () {
        vm.kioskPaymentInProgress = false;
        vm.paymentInProgress = false;
        console.log('cancelled');
      }, function () {
        vm.kioskPaymentInProgress = false;
        vm.paymentInProgress = false;
        // Get the payment-methods
        vm.setPaymentMethods();
        console.log('refresh modal');
      });
    };

    function printTicket(duplicate) {
      if (SettingsService.get('pos.enableNetworkTicketPrint', false)) {
        return TicketFactory.printSaleTicketViaNetwork(vm.saleId);
      }

      if (SettingsService.get('pos.downloadPdfRecipe', false)) {
        return TicketFactory.downloadPdfRecipe(vm.saleId, duplicate);
      }

      return SaleFactory.one(vm.saleId).get()
        .then(function (sale) {
          hwproxy.sendSaleInfoToPrinter({
            sale: sale,
            saleItems: vm.billItems,
            // payments: vm.payments.filter(function (pm) {
            //   return pm.paymentMethod.code !== 'DISCOUNT';
            // }),
            customer: vm.billCustomer,
            paidTotal: vm.paymentsTotal(),
            changeTotal: Math.abs(vm.changeTotal()),
            amountToPay: vm.amountToPay,
            duplicate: duplicate
          });
        });
    }

    function cancelModalInstance(clearShoppingCart, goToHomeScreen, checkExternalCart, removeRoundingProduct) {
      var roundItem;
      if (clearShoppingCart) {
        LogService.log('emit clearShoppingCartEmit: cancelModalInstance', 'debug');
        $scope.$emit('clearShoppingCartEmit', { message: 'Sale is completed' });
      }
      if (goToHomeScreen) {
        $rootScope.$emit('posGoToHomeScreenBroadcast');
      }
      if (checkExternalCart && vm.useExternalCart && !vm.standaloneMode) {
        $scope.$emit('posCheckExternalCartEmit');
      }
      $rootScope.$emit('CancelPayment');

      if (vm.selectedNewInvoiceCustomer) {
        vm.removeNewInvoiceCustomer();
      }

      //remove rounding saleItem if it exist when we close the modal, see #47992
      if (removeRoundingProduct && vm.roundingEnabled && vm.roundingProductCode) {
        SaleFactory.one(vm.saleId).get().then(function (sale) {
          roundItem = sale.saleItems.filter(function (saleItem) {
            return saleItem.product.code === vm.roundingProductCode;
          });
          if (angular.isDefined(roundItem[0])) {
            SaleFactory.one(vm.saleId).one('items').one(roundItem[0].id).remove();
          }
        });
      }
      // Dismiss the modal instance
      $modalInstance.dismiss('cancel');
    }

    function noDiscountPaymentMethod(paymentMethod) {
      return paymentMethod.code !== 'DISCOUNT';
    }

    function noElectronicPaymentMethod(paymentMethod) {
      return (paymentMethod.code !== 'DEBIT_CARD') && (paymentMethod.code !== 'CREDIT_CARD');
    }

    function isKeypadDisabled() {
      return (vm.paymentInProgress || SettingsService.get('pos.disableKeypad', false));
    }

    function processPaymentMethods(result) {
      var methods = result;

      // add a <br> before "(" character to enforce a line-break
      methods.forEach(function (method) {
        var label = method.translatedLabel || '',
            i = label.indexOf('(');
        if (i > 0) {
          method.translatedLabel = label.substr(0, i) + '<br>' + label.substr(i);
        }
      });

      vm.paymentMethodsCache = methods;
      vm.setPaymentMethods();
    }

    function signAmount() {
      return Math.sign(vm.amountToPay);
    }

    function checkPaymentMethod() {
      var saleItemWithInternalCommentExist = vm.billItems.filter(function (bi) {
            if (angular.isDefined(bi.internalComments) && bi.internalComments) {
              return bi;
            }
          }).length, loadingModal;
      $timeout(function () {
        if (vm.roundingEnabled && (vm.isRoundingNeeded() || vm.roundingItemPrice)) {
          loadingModal = $modal.open({
            template: '<div class="modal-body">\n' +
              '  {{ "app.loading" | translate | uconlyfirst }}\n' +
              '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
              '          <span class="icons">\n' +
              '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
              '          </span>\n' +
              '      </span>\n' +
              '</div>',
            size: 'sm'
          });
          vm.roundingSales(vm.paymentMethod).then(function (price) {
            if (price !== 0) {
              vm.amountToPay += price;
              vm.paymentAmount += price;
            } else {
              vm.amountToPay -= vm.roundingItemPrice;
              vm.paymentAmount -= vm.roundingItemPrice;
              vm.roundingItemPrice = 0;
            }

            loadingModal.close();
          });
        }
        if (vm.paymentMethod && vm.paymentMethod.code === 'CASH' && SettingsService.get('pos.cashRoundingEnabled', false)) {
          // if (!vm.billCustomer) {
          //   ToastrNotificationService.showTranslatedNotification('error', 'app.error', 'app.pos.no_customer_selected');
          //   vm.paymentMethod = null;
          // }
          selectCash();
        }
        if (vm.withoutRoundingAmount && vm.paymentMethod.code !== 'CASH') {
          vm.paymentAmount = vm.withoutRoundingAmount;
          vm.amountToPay = vm.withoutRoundingAmountToPay;
        }
        if (vm.paymentMethod && vm.paymentMethod.code === 'BANK_TRANSFER') {
          if (!vm.billCustomer) {
            ToastrNotificationService.showTranslatedNotification('error', 'app.error', 'app.pos.no_customer_selected');
            vm.paymentMethod = null;
          }
        }

        if (vm.posPaymentMethodsInternalCommentsRequired !== 'empty' &&
          vm.posPaymentMethodsInternalCommentsRequired.indexOf(vm.paymentMethod.code) !== -1 &&
          !saleItemWithInternalCommentExist
        ) {
          ToastrNotificationService.showTranslatedNotification('error', 'app.error', 'app.pos.internal_comments_needed');
          vm.paymentMethod = null;
        }
      });
    }

    function selectCash() {
      SaleFactory.one(vm.saleId).one('rounding').post().then(function (result) {
        if (angular.isUndefined(result)) {
          console.log('rounding is not necessary');
          return;
        }
        if (vm.selectCashIsOpen) {
          return;
        }
        vm.selectCashModalInstance = $modal.open({
          templateUrl: 'pos/views/pos.payment.cash.warning.modal.tpl.html'
        });
        vm.selectCashIsOpen = true;
        vm.selectCashModalInstance.result.then(function () {
          console.log('OK pos.payment.cash.warning.modal');
          SaleFactory.one(vm.saleId).one('rounding').post().then(function (roundedPrice) {
            if (!vm.withoutRoundingAmount && angular.isDefined(roundedPrice)) {
              vm.withoutRoundingAmount = vm.paymentAmount;
              vm.withoutRoundingAmountToPay = vm.amountToPay;
            }
            if (angular.isDefined(roundedPrice)) {
              console.log('isDefined ' + roundedPrice);
              vm.paymentAmount = roundedPrice;
              vm.amountToPay = roundedPrice;
            }
          });
          vm.selectCashIsOpen = false;
        }, function () {
          vm.selectCashIsOpen = false;
          console.log('close pos.payment.cash.warning.modal');
        });
      });
    }

    function showCustomerMissingModal() {
      var customerModalInstance = $modal.open({
        templateUrl: 'pos/views/pos.customer.missing.modal.tpl.html'
      });
      customerModalInstance.result.then(function () {}, function () {
        vm.missingCustomerModalClosed = true;
        vm.pay();
      });
    }

    function roundingSales(paymentMethod) {
      var defer = $q.defer(), roundItem, roundedItem;
      //check if sale has rounding product
      if (vm.roundingEnabled && vm.roundingProductCode && (vm.isRoundingNeeded() || vm.roundingItemPrice)) {
        SaleFactory.one(vm.saleId).get().then(function (sale) {
          roundedItem = sale.saleItems.filter(function (saleItem) {
            return saleItem.product.code === vm.roundingProductCode;
          });
          if (paymentMethod.code === 'CASH' || paymentMethod.code === 'PAYMENT_KIOSK' || !vm.roundingOnlyCash) {
            if (angular.isUndefined(roundedItem[0])) {
              SaleFactory.one(vm.saleId).one('round').get().then(function (roundedSale) {
                roundItem = roundedSale.saleItems.filter(function (saleItem) {
                  return saleItem.product.code === vm.roundingProductCode;
                });
                vm.roundingItemPrice = angular.isDefined(roundItem[0]) && (roundItem[0].price) ? roundItem[0].quantity * roundItem[0].price : 0;
                defer.resolve(vm.roundingItemPrice);
              });
            } else {
              vm.roundingItemPrice = 0;
              defer.resolve(0);
            }
          } else if (angular.isDefined(roundedItem[0])) {
            SaleFactory.one(vm.saleId).one('items').one(roundedItem[0].id).remove().then(function () {
              defer.resolve(0);
            });
          } else {
            defer.resolve(0);
          }
        });
      } else {
        defer.resolve(0);
      }
      return defer.promise;
    }

    function isRoundingNeeded() {
      var roundNum = 0, diff = 0;
      roundNum = Math.round(vm.paymentAmount / 0.05) * 0.05;
      diff = (vm.paymentAmount - roundNum) * 100;

      return Math.round(diff);
    }

    vm.createPayconiqPaymentRequest = function (paymentObject) {
      Restangular.one('sales').one(vm.saleId).one('payment-payconiq-request').customPOST({
        amount: paymentObject.amount
      }).then(function (data) {
        LogService.log(data, 'debug');
        vm.payconiqPaymentId = data.paymentId;
        if (vm.payconiqAutomaticValidateEnabled) {
          vm.initializePayconiqPaymentStatusWaitingProcess();
        }
      });
    };

    vm.validatePayconiqPayment = function () {
      if (vm.payconiqPaymentId) {
        return Restangular.one('payment-payconiq-details').get({paymentId: vm.payconiqPaymentId}).then(function (data) {
          LogService.log(data, 'debug');
          if (angular.isDefined(data.status) && (data.status === 'SUCCEEDED' || data.status === 'accepted')) {
            //create the normal payment and complete sale
            vm.payconiqPaymentInProgress = false;
            if (vm.payconiqAutomaticValidateEnabled && vm.payconiqInterval !== null) {
              $interval.cancel(vm.payconiqInterval);
            }
            return vm.createPayment(vm.paymentObject, (vm.paymentMethod.code !== 'BANK_TRANSFER'));
          } else if (angular.isDefined(data.status) && (data.status === 'cancel' || data.status === 'cancelled')) {
            //create the normal payment and complete sale
            vm.payconiqPaymentInProgress = false;
            vm.paymentInProgress = false;
            if (vm.payconiqAutomaticValidateEnabled && vm.payconiqInterval !== null) {
              $interval.cancel(vm.payconiqInterval);
            }

            ToastrNotificationService.showNotification(
              'warning',
              'Payment info',
              $translate.instant('pos.payment-cancelled'));
          }
          return Promise.resolve();
        });
      }
    };

    vm.cancelPayconiqPayment = function () {
      if (vm.payconiqPaymentId) {
        if (vm.paymentsPayconiqVersion === '2') {
          return vm.cancelPaymentViaPaymentEngine('payconiq', vm.payconiqPaymentId, 'payconiq');
        }
        return Restangular.one('payment-payconiq-cancel').get({paymentId: vm.payconiqPaymentId}).then(function (data) {
          LogService.log(data, 'debug');
          vm.payconiqPaymentInProgress = false;
          vm.paymentInProgress = false;
          if (vm.payconiqAutomaticValidateEnabled && vm.payconiqInterval !== null) {
            $interval.cancel(vm.payconiqInterval);
          }
          return Promise.resolve();
        });
      }

      vm.payconiqPaymentInProgress = false;
    };

    function showWristbandModalForm() {
      UtilService.showModal({
        templateUrl: 'pos/views/pos.assign.wristband.modal.tpl.html',
        controller: 'PosAssignWristbandCtrl',
        controllerAs: 'posAssignWristbandCtrl',
        size: 'lg',
        resolve: {
          saleId: function () {
            return vm.saleId;
          },
          wristbandId: function () {
            return null;
          }
        }
      }, null, null, true);
    }

    function validateAntenorPayment() {
      if (vm.antenorPaymentId) {
        return Restangular.one('payment-antenor-details').get({paymentId: vm.antenorPaymentId}).then(function (data) {
          LogService.log(data, 'debug');

          if (angular.isDefined(data.paidAmount) && data.paidAmount !== 0 && vm.antenorPaymentInProgress) {
            vm.antenorPaymentsPaid = data.paidAmount;
          }

          if (angular.isDefined(data.status) && data.status === 'accepted' && vm.antenorPaymentInProgress) {
            //create the normal payment and complete sale
            vm.antenorPaymentInProgress = false;
            if (vm.antenorAutomaticValidateEnabled && vm.antenorInterval !== null) {
              $interval.cancel(vm.antenorInterval);
            }

            return vm.createPaymentsForSale(vm.paymentObject, Restangular.stripRestangular(data));
          }
          return Promise.resolve();
        });
      }
    }

    function sendPaymentEngineRequest(engineKey, idSale, saleReference, amount, currency) {
      saleReference = parseInt(saleReference, 10);
      // request the payment via backend
      LogService.log('PaymentEngine: Requesting payment via backend using payment flow version ' + SettingsService.get('payment.flowVersion', '1'));
      return new Promise(function (resolve, reject) {
        SaleFactory.one(idSale).one('payment-engine-request').customPOST({
          amount: amount,
          currency: currency,
          reference: saleReference,
          engineKey: engineKey
        }).then(function (response) {
          LogService.log('PaymentEngine: Backend responded with:');
          LogService.log(response);
          LogService.logToConsole(response);
          console.log(response);
          if (engineKey === 'kiosk') {
            if (angular.isDefined(response.payment.paymentInfo.status) && response.payment.paymentInfo.status === 'error') {
              vm.antenorPaymentInProgress = false;
              vm.paymentInProgress = false;
              ToastrNotificationService.showNotification('error', 'Payment info', 'Payment request failed.');
              vm.paymentMethod = null;
              resolve();
              return;
            }
            vm.antenorPaymentId = response.payment.paymentInfo.paymentId;
            if (vm.antenorAutomaticValidateEnabled) {
              vm.initializeAntenorPaymentStatusWaitingProcess();
            }
          } else if (engineKey === 'payconiq') {
            vm.payconiqPaymentId = response.payment.paymentInfo.paymentId;
            if (vm.payconiqAutomaticValidateEnabled) {
              vm.initializePayconiqPaymentStatusWaitingProcess();
            }
          }
          resolve(response);
        }, function (error) {
          LogService.logToConsole(error);
          if (engineKey === 'kiosk') {
            vm.antenorPaymentInProgress = false;
          } else if (engineKey === 'payconiq') {
            vm.payconiqPaymentInProgress = false;
          }
          vm.paymentInProgress = false;
          reject(error);
        });
      });
    }

    function cancelAntenorPayment() {
      if (vm.antenorPaymentId) {
        // request payment cancellation via backend
        return SaleFactory.one(vm.saleId).one('payment-cancel-request').customPOST({
          engineKey: 'kiosk',
          paymentId: vm.antenorPaymentId
        }).then(function (response) {
          LogService.log('PaymentEngine: Backend responded with:');
          LogService.log(response);
          LogService.logToConsole(response);
          console.log(response);
          vm.antenorPaymentInProgress = false;
          vm.paymentInProgress = false;
          if (vm.antenorAutomaticValidateEnabled && vm.antenorInterval !== null) {
            $interval.cancel(vm.antenorInterval);
          }
          console.log('cancelled');
          return Promise.resolve();
        }, function (error) {
          LogService.logToConsole(error);
          vm.antenorPaymentInProgress = false;
          vm.paymentInProgress = false;
          // Get the payment-methods
          vm.setPaymentMethods();
          console.log('refresh modal');
          return Promise.reject(error);
        });
      }
    }

    function initializeAntenorPaymentStatusWaitingProcess() {
      //cancel current interval
      if (vm.antenorInterval !== null) {
        $interval.cancel(vm.antenorInterval);
        vm.antenorInterval = null;
      }

      vm.antenorInterval = $interval(function () {
        vm.validateAntenorPayment();
      }, 1000);
    }

    function initializePayconiqPaymentStatusWaitingProcess() {
      //cancel current interval
      if (vm.payconiqInterval !== null) {
        $interval.cancel(vm.payconiqInterval);
        vm.payconiqInterval = null;
      }

      vm.payconiqInterval = $interval(function () {
        vm.validatePayconiqPayment();
      }, 1000);
    }

    function cancelPaymentViaPaymentEngine(engineKey, paymentId, paymentKey) {
      // request payment cancellation via backend
      return SaleFactory.one(vm.saleId).one('payment-cancel-request').customPOST({
        engineKey: engineKey,
        paymentId: paymentId
      }).then(function (response) {
        LogService.log('PaymentEngine: Backend responded with:');
        LogService.log(response);
        LogService.logToConsole(response);
        console.log(response);
        if (angular.isDefined(paymentKey) && paymentKey === 'antenor') {
          vm.antenorPaymentInProgress = false;
          if (vm.antenorAutomaticValidateEnabled && vm.antenorInterval !== null) {
            $interval.cancel(vm.antenorInterval);
          }
        } else if (angular.isDefined(paymentKey) && paymentKey === 'payconiq') {
          vm.payconiqPaymentInProgress = false;
          if (vm.payconiqAutomaticValidateEnabled && vm.payconiqInterval !== null) {
            $interval.cancel(vm.payconiqInterval);
          }
        }
        vm.paymentInProgress = false;
        console.log('cancelled');
        return Promise.resolve();
      }, function (error) {
        LogService.logToConsole(error);
        if (angular.isDefined(paymentKey) && paymentKey === 'antenor') {
          vm.antenorPaymentInProgress = false;
          if (vm.antenorAutomaticValidateEnabled && vm.antenorInterval !== null) {
            $interval.cancel(vm.antenorInterval);
          }
        } else if (angular.isDefined(paymentKey) && paymentKey === 'payconiq') {
          vm.payconiqPaymentInProgress = false;
          if (vm.payconiqAutomaticValidateEnabled && vm.payconiqInterval !== null) {
            $interval.cancel(vm.payconiqInterval);
          }
        }
        vm.paymentInProgress = false;
        // Get the payment-methods
        vm.setPaymentMethods();
        console.log('refresh modal');
        return Promise.reject(error);
      });
    }

    function manualCompleteSale() {
      var paymentObject;
      return PosSaleService.saleIsCompleted(vm.saleId).then(function (isCompleted) {
        if (!isCompleted) {
          SaleFactory.one(vm.saleId).get().then(function (sale) {
            vm.saleReference = sale.reference;
            paymentObject = {
              amount: parseFloat(vm.paymentAmount),
              currency: 'EUR',
              paymentMethod: vm.paymentMethod.id,
              reference: (((Number(sale.reference) + (new Date()).getTime()) % 4294967295) + 1).toString()
            };

            return vm.createPayment(paymentObject).then(function () {
              SaleFactory.one(vm.saleId).one('send-status-to-order-engine').get().then(function (response) {
                console.log(response);
                vm.yomaniWaitingForResponse = false;
                return Promise.resolve();
              });
            });
          });
        }
      });
    }

    function showManualButtonForCompleteSale() {
      // manual complete sale button
      vm.showManualCompleteButton = false;
      if (vm.posDisplayManualCompleteButton && PaymentEngineFactory.enabled() && vm.paymentMethod && vm.paymentMethod.code && PaymentEngineFactory.getEngineByPaymentMethod(vm.paymentMethod.code) !== null) {
        vm.manualButtonTimer = $timeout(function () {
          vm.showManualCompleteButton = true;
        }, parseInt(vm.posDisplayManualCompleteButtonAfterSeconds, 10) * 1000);
      }
    }

    vm.createPaymentsForSale = function (paymentObject, data) {
      var paymentData = data,
          returnPaymentObject = {},
          paymentPromises = [];

      // set the entered amount to primary payment
      paymentObject.amount = ProductUtilService.roundPrice(paymentData.paidAmount);

      // add kiosk payment data to primary payment
      vm.addPaymentKioskDataToPaymentObjectParameters(paymentObject, paymentData);

      // persist primary payment
      paymentPromises.push(SaleFactory.one(vm.saleId).one('payments').customPOST(paymentObject).then(function () {
        vm.payments.push({
          paymentMethod: vm.paymentMethod,
          amount: paymentObject.amount
        });
      }));

      // create a return payment if some change was returned
      if (angular.isDefined(paymentData.changeAmount) && paymentData.changeAmount > 0) {
        returnPaymentObject.amount = -ProductUtilService.roundPrice(paymentData.changeAmount);
        returnPaymentObject.currency = paymentObject.currency;
        returnPaymentObject.paymentMethod = paymentObject.paymentMethod;
        returnPaymentObject.reference = (parseInt(paymentObject.reference, 10) + 1).toString();

        // add kiosk payment data also to return payment
        vm.addPaymentKioskDataToPaymentObjectParameters(returnPaymentObject, paymentData);

        // persist return payment
        paymentPromises.push(SaleFactory.one(vm.saleId).one('payments').customPOST(returnPaymentObject).then(function () {
          vm.payments.push({
            paymentMethod: vm.paymentMethod,
            amount: returnPaymentObject.amount
          });
        }));
      }

      return Promise.all(paymentPromises).then(function () {
        var saleFinishPromises = [];

        vm.isFirstOpening = false;
        LogService.log('payment complete', 'debug');

        hwproxy.sendSaleCompleteInfoToDisplay({
          id: vm.saleId,
          currency: paymentObject.currency,
          amount: vm.paymentsTotal(),
          change: vm.changeTotal()
        });
        if (vm.posFinishSalePrintTicketEnabled) {
          saleFinishPromises.push(vm.printTicket(false));
        }
        // finish sale even if there is missing change
        saleFinishPromises.push(PosSaleService.saleIsCompleted(vm.saleId).then(function (isCompleted) {
          if (!isCompleted) {
            return SaleStatusFactory.getStatusByCode('COMPLETED').then(function (completedStatus) {
              return SaleFactory.one(vm.saleId).patch({
                saleStatus: completedStatus.id,
                completedAt: (new Date()).toISOString()
              });
            });
          }
        }));

        LogService.log('emit clearShoppingCartEmit: Sale is completed: payment promises', 'debug');
        $scope.$emit('clearShoppingCartEmit', { message: 'Sale is completed' });

        return Promise.all(saleFinishPromises);
      }).finally(function () {
        $timeout(function () {
          vm.paymentInProgress = false;
          vm.paymentIsFinished = true;
        });
      });
    };
  }
}());
