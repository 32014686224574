(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accountancy.factory:AccountancyPeriods
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .factory('AccountancyPeriodFactory', AccountancyPeriodFactory);

  function AccountancyPeriodFactory(Restangular) {
    var service = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'accountancyPeriod'});
      })
      .service('accountancy_periods');

    return {
      getList: function (params) {
        return service.getList(params);
      },
      one: function (fragment) {
        return service.one(fragment);
      },
      post: function (params) {
        return service.post(params);
      },
      patch: function (params) {
        return service.patch(params);
      },
      put: function (params) {
        return service.put(params);
      },
      getAllOpened: function (siteId) {
        var params = [];
        params['filter[]'] = [];
        params['filter[]'].push('siteId,' + siteId);
        params['filter[]'].push('isOpened,' + true);
        params.limit = 99;
        return service.getList(params);
      },
      getOpenedForDate: function (siteId, date) {
        var params = [];
        params['filter[]'] = [];
        params['filter[]'].push('siteId,' + siteId);
        params['filter[]'].push('isOpened,' + true);
        params['filter[]'].push('startDate,LTE ' + date.toISOString());
        params['filter[]'].push('endDate,GTE ' + date.toISOString());

        return service.getList(params);
      },
      // vm.completedAtDate >= item.startDate && vm.completedAtDate <= item.endDate)
      convertDatesToObject: function (item) {
        item.startDate = new Date(item.startDate);
        item.endDate = new Date(item.endDate);
      }
    };
  }
}());
