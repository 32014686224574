(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name fitness.controller:FitnessInstanceCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('fitness')
    .controller('FitnessInstanceCtrl', FitnessInstanceCtrl);

  function FitnessInstanceCtrl($cookies,
                               LogService,
                               $q,
                               $scope,
                               $filter,
                               $state,
                               $stateParams,
                               ContactFactory,
                               JournalFactory,
                               EventFactory,
                               EventParticipantFactory,
                               EventParticipantStatusFactory,
                               CurrentUserContextFactory,
                               EventTypeFactory,
                               FitnessCourseFactory,
                               SettingsService,
                               ToastrNotificationService,
                               $modal,
                               UtilService) {
    var vm = this;
    vm.reloadPageData = reloadPageData;
    vm.showModalView = showModalView;
    vm.showEventInstanceModalView = showEventInstanceModalView;
    vm.modalInstance = null;
    vm.createEventParticipant = createEventParticipant;
    vm.acceptParticipation = acceptParticipation;
    vm.confirmParticipation = confirmParticipation;
    vm.cancelParticipation = cancelParticipation;
    vm.canAddParticipants = false;
    vm.canManageParticipant = false;
    vm.getTimeToEventInstance = getTimeToEventInstance;
    vm.getEndTimeToEventInstance = getEndTimeToEventInstance;
    vm.getConfirmationTimeToEventInstance = getConfirmationTimeToEventInstance;
    vm.eventParticipants = [];
    // select contact modal
    vm.showingSelectContactModal = false;
    vm.showSelectContactModal = showSelectContactModal;
    vm.selectContactModalInstance = null;
    vm.showCustomerPosModal = showCustomerPosModal;
    vm.eventsShowCustomerPosModal = SettingsService.get('events.showCustomerPosModal', false);
    EventParticipantStatusFactory.getList({limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
      vm.participantStatuses = resultStatuses;
    });
    EventFactory.one('participants').getList('types', {limit: 99, sort: 'label,asc'}).then(function (resultTypes) {
      vm.participantTypes = resultTypes;
    });

    // Check the state of the customer page
    if ($state.is('fitnessinstance')) {
      // Reload page data
      vm.courseId = $stateParams.courseId;
      vm.eventId = $stateParams.eventId;
      vm.instanceId = $stateParams.id;
      vm.reloadPageData();
    }

    function reloadPageData() {
      // Get eventInstance
      EventFactory.one(vm.eventId).one('instances').customGET(vm.instanceId).then(function (resultEventInstance) {
        vm.fitnessEventInstance = resultEventInstance;
        vm.eventParticipants = [];
        FitnessCourseFactory.one(vm.courseId).get().then(function (resultCourse) {
          vm.fitnessCourse = resultCourse;
          vm.getTimeToEventInstance();
          vm.getEndTimeToEventInstance();
          vm.getConfirmationTimeToEventInstance();
          angular.forEach(vm.fitnessEventInstance.eventParticipants, function (participant) {
            if (participant.eventParticipantType.code === 'PARTICIPANT') {
              vm.eventParticipants.push(participant);
            }
          });
        });
      });
    }

    function showEventInstanceModalView(returnType, type, eventInstance) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.instance.modal.addedit.single.tpl.html',
              controller: 'FitnessInstanceAddEditCtrl',
              controllerAs: 'fitnessInstanceAddEditCtrl',
              resolve: {
                eventInstance: function () {
                  return eventInstance;
                },
                fitnessEvent: function () {
                  return vm.eventId;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          UtilService.showModal(
            {
              templateUrl: 'fitness/views/fitness.instance.modal.delete.tpl.html',
              controller: 'FitnessInstanceDeleteCtrl',
              controllerAs: 'fitnessInstanceDeleteCtrl',
              resolve: {
                eventInstance: function () {
                  return eventInstance;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            },
            modalResultHandler);
          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function showModalView(participant) {
      var deferred = $q.defer();
      ContactFactory.one(participant.contact.id).get().then(function (contact) {
        // Open modal view with specific template and controller, Add a customer and contact object to that controller
        UtilService.showModal(
          {
            templateUrl: 'contact/views/contact.modal.view.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contactCtrl',
            size: 'lg',
            resolve: {
              contact: function () {
                return contact;
              },
              customer: function () {
                return null;
              },
              showOnlineAccessInfo: function () {
                return true;
              }
            }
          },
          modalResultHandler);
        deferred.resolve();
      });

      return deferred.promise;
    }

    function modalResultHandler(returnValue) {
      // Check the type of the returnValue (= reloadOption) and fire specific reload-method
      switch (returnValue) {
        case 'page':
          vm.reloadPageData();
          break;
        case 'list':
          vm.reloadListData();
          break;

        default:
          break;
      }
    }

    // SELECT CONTACT
    function showSelectContactModal() {
      UtilService.showModal(
        {
          templateUrl: 'pos/views/pos.customers.list.modal.tpl.html',
          controller: 'PosCustomerListCtrl',
          controllerAs: 'posCustomerListCtrl',
          size: 'lg',
          resolve: {
            customerId: function () {
              return null;
            },
            customerContactTypeId: function () {
              return null;
            }
          }
        },
        salesContactModalResultHandler
      );
    }

    function salesContactModalResultHandler(returnValue) {
      LogService.log('Customer Contact Selection Modal: Closed => reason: ' + returnValue, 'debug');
      vm.createEventParticipant(returnValue);
    }

    function createEventParticipant(customer) {
      var participantStatus = $filter('filter')(vm.participantStatuses, function (ps) {
            return ((ps.code === 'ACCEPTED' && vm.fitnessEventInstance.numberOfParticipants < vm.fitnessEventInstance.maximumMembers) ||
                    (ps.code === 'PENDING' && vm.fitnessEventInstance.numberOfParticipants >= vm.fitnessEventInstance.maximumMembers));
             // Check if eventInstance.maximumMembers === eventInstance.eventParticipants
          }),
          participantType = $filter('filter')(vm.participantTypes, function (pt) {
            return pt.code === 'PARTICIPANT';
          }),
          participantObject = {
            event: vm.eventId,
            eventInstance: vm.fitnessEventInstance.id,
            contact: customer.contact.contact.id,
            site: CurrentUserContextFactory.getSiteId()
          },
          currentISODate = (new Date(vm.fitnessEventInstance.startsAt)).toISOString().substr(0, 10),
          journalParams = {
            limit: 99
          },
          journalTypeIdList = [];

      angular.forEach(vm.fitnessCourse.journalTypes, function (journalType) {
        journalTypeIdList.push(journalType.id);
      });
      // check if participant has valid journal
      vm.journals = [];

      journalParams['filter[]'] = [
        'customer.id,' + customer.customer.id,
        'OR,termination,NULL,termination,GTE ' + currentISODate,
        'OR,expiration,NULL,expiration,GTE ' + currentISODate,
        'OR,credits,null,credits,GTE 0'
      ];
      ContactFactory.one(customer.contact.contact.id).customGET('fitness_credits').then(function (contactCredits) {
        if (angular.isArray(contactCredits)) {
          // Custom notification change to 'no credits '
          ToastrNotificationService.showNotification(
            'error',
            $filter('uconlyfirst')(
              $filter('sprintf')(
                $filter('translate')('app.does-not-have-credits'),
                $filter('translate')('app.fitness.event.participant')
              )
            )
          );
        } else {
          LogService.log('customer has credits', 'debug');
          if (contactCredits.credits > 0) {
            JournalFactory.getList(journalParams).then(function (journalResults) {
              vm.journals = journalResults;

              if (($filter('filter')(vm.journals, function (journal) {
                return journalTypeIdList.indexOf(journal.journalType.id) !== -1 && journal.valid === true;
              })).length > 0) {
                participantObject.eventParticipantStatus = participantStatus[0].id;
                participantObject.eventParticipantType = participantType[0].id;

                EventParticipantFactory.post(participantObject).then(function () {
                  ToastrNotificationService.showNotification(
                    'success',
                    $filter('uconlyfirst')(
                      $filter('sprintf')(
                        $filter('translate')('app.item-saved'),
                        $filter('translate')('app.fitness.event.participant')
                      )
                    ),
                    $filter('uconlyfirst')(
                      $filter('sprintf')(
                        $filter('translate')('app.item-successfully-saved'),
                        $filter('translate')('app.fitness.event.participant')
                      )
                    )
                  );
                  vm.reloadPageData();
                }, function (result) {
                  console.log(result);
                  angular.forEach(result.data.errors.errors, function (error) {
                    ToastrNotificationService.showNotification(
                      'error',
                      $filter('uconlyfirst')(
                        $filter('sprintf')(
                          $filter('translate')(error)
                        )
                      ),
                      $filter('uconlyfirst')(
                        $filter('translate')('app.fitness.reload_page')
                      )
                    );
                  });
                });
              } else {
                // ERROR
                ToastrNotificationService.showNotification(
                  'error',
                  $filter('uconlyfirst')(
                    $filter('sprintf')(
                      $filter('translate')('app.does-not-have-valid-journal'),
                      $filter('translate')('app.fitness.event.participant')
                    )
                  )
                );
              }
            });
          } else {
            // Custom notification change to 'no valid credits '
            ToastrNotificationService.showNotification(
              'error',
              $filter('uconlyfirst')(
                $filter('sprintf')(
                  $filter('translate')('app.does-not-have-enough-credits'),
                  $filter('translate')('app.fitness.event.participant')
                )
              )
            );
          }
        }
      });
    }

    function cancelParticipation(eventParticipant) {
      var deferred = $q.defer(),
          participantObject = {
            eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
              return ps.code === 'REJECTED';
            })[0].id
          };
      // Set Rejected
      EventParticipantFactory.one(eventParticipant.id).patch(participantObject).then(function () {
        vm.reloadPageData();
      });

      return deferred.promise;
    }

    function confirmParticipation(eventParticipant) {
      var participantObject = {
        eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
          return ps.code === 'ATTENDED';
        })[0].id
      };
      // Set Rejected
      return EventParticipantFactory.one(eventParticipant.id).patch(participantObject).then(function () {
        vm.reloadPageData();
      });
    }

    function acceptParticipation(eventParticipant) {
      var participantObject = {
        eventParticipantStatus: $filter('filter')(vm.participantStatuses, function (ps) {
          return ps.code === 'ACCEPTED';
        })[0].id
      };
      // Set Rejected
      return EventParticipantFactory.one(eventParticipant.id).patch(participantObject).then(function () {
        vm.reloadPageData();
      }, function (e) {
        e.data.errors.errors.forEach(function (message) {
          ToastrNotificationService.showNotification(
            'error',
            $filter('uconlyfirst')(message)
          );
        });
      });
    }

    function getConfirmationTimeToEventInstance() {
      var eventStart = new Date(vm.fitnessEventInstance.startsAt.toString()),
          difference = 0;
      eventStart.setHours(eventStart.getHours() - vm.fitnessCourse.defaultConfirmationHours);
      difference = Math.round((eventStart - new Date()) / 1000);
      if (difference > 0) {
        vm.timeToEventInstance = difference;
      } else {
        vm.timeToEventInstance = 0;
      }
    }

    function getTimeToEventInstance() {
      var eventStart = new Date(vm.fitnessEventInstance.startsAt.toString()),
          difference = Math.round((eventStart - new Date()) / 1000);
      if (difference > -900) {
        vm.canAddParticipants = true;
      } else {
        vm.canAddParticipants = false;
      }
    }

    function getEndTimeToEventInstance() {
      var eventEnd = new Date(vm.fitnessEventInstance.endsAt.toString()),
          difference = Math.round((eventEnd - new Date()) / 1000);
      if (difference > 0) {
        vm.canManageParticipant = true;
      } else {
        vm.canManageParticipant = false;
      }
    }

    function showCustomerPosModal(participant) {
      var deferred = $q.defer(), customerContact, that = this;
      ContactFactory.one(participant.contact.id).get().then(function (contact) {
        customerContact = $filter('filter')(contact.customerContacts, function (cc) {
          return cc.customerContactType.code === 'USER';
        })[0];
        that.modalInstance = $modal.open({
          templateUrl: 'hwproxy/views/hwproxy.member_card_data.modal.tpl.html',
          controller: 'HwProxyMemberCardDataCtrl',
          controllerAs: 'HwProxyMemberCardDataCtrl',
          size: 'lg',
          resolve: {
            memberCardData: function () {
              return null;
            },
            customer: function () {
              return customerContact.customer;
            },
            customerContact: function () {
              return customerContact;
            }
          }
        });

        that.modalInstance.result.then(function (returnValue) {
          LogService.log('HWProxy: Card Data Modal: Closed => reason: ' + returnValue, 'debug');
          that.modalInstance = null;
          vm.reloadPageData();
          deferred.resolve();
        }, function (returnValue) {
          LogService.log('HWProxy: Card Data Modal: Dismissed => reason: ' + returnValue, 'debug');
          that.modalInstance = null;
          vm.reloadPageData();
          deferred.resolve();
        });
      });

      return deferred.promise;
    }
  }
}());
