(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('user')
    .controller('UserCtrl', UserCtrl);

  function UserCtrl(_,
                    $filter,
                    $rootScope,
                    LogService,
                    RoleFactory,
                    SiteFactory,
                    UserFactory,
                    UtilService,
                    ToastrNotificationService) {
    var vm = this;
    vm.pagination = {
      totalItems: '',
      maxPages: 10,
      currentPage: 1,
      itemsPerPage: 20
    };
    vm.filters = {
      username: {
        val: '',
        field: 'username',
        cond: 'LIKE'
      },
      email: {
        val: '',
        field: 'email',
        cond: 'LIKE'
      },
      roles: {
        val: '',
        field: 'userContexts.roles.label',
        cond: ''
      },
      sites: {
        val: $rootScope.site,
        field: 'userContexts.site.label',
        cond: ''
      },
      locked: {
        val: false,
        field: 'locked',
        cond: ''
      }
    };
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.item = {};
    vm.entities = [];
    vm.resendActivationMail = resendActivationMail;
    vm.toggleUserLock = toggleUserLock;
    vm.init = init;
    vm.loadEntities = loadEntities;
    vm.delete = remove;
    vm.isValid = isValid;
    vm.openUserAddEditModal = openUserAddEditModal;
    vm.openUserContextAddEditModal = openUserContextAddEditModal;
    vm.openUserContextDeleteModal = openUserContextDeleteModal;
    vm.contextIsActive = contextIsActive;
    vm.openUserContextActivateModal = openUserContextActivateModal;

    init();

    function init() {
      RoleFactory.getRolesForFilter()
        .then(function (result) {
          vm.roles = result;
        });
      SiteFactory.getList({
        limit: 99
      }).then(function (result) {
        vm.sites = result;
      });
    }

    function loadEntities(reload) {
      var queryParams = '';
      if (angular.isUndefined(reload)) {
        reload = false;
      }
      if (angular.isUndefined(vm.entities[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage,
          sort: 'username,ASC'
        };

        _.each(vm.filters, function (filter, index) {
          var condition = '';

          if (angular.isDefined(filter.val) && (filter.val || filter.val === false)) {
            if (UtilService.isNotEmpty(filter.cond)) {
              condition = filter.cond + ' ';
            }

            queryParams['filter[' + index + ']'] = [filter.field + ',' + condition + filter.val];
          }
        });
        UserFactory.getList(queryParams).then(function (results) {
          vm.entities[vm.pagination.currentPage] = results;
          vm.pagination.totalItems = results.count;
        });
      }
    }

    function remove() {
      var headText = ($filter('uconlyfirst')($filter('translate')('app.users'))),
          bodyText = ($filter('sprintf')($filter('uconlyfirst')($filter('translate')('confirm.delete_item')), ($filter('translate')('app.this-user'))));
      UtilService.showConfirmationModal(headText, bodyText, function (resolve) {
        if (resolve) {
          UserFactory.one(vm.item.id).remove().then(function () {
            vm.close();
          });
        }
      });
    }

    function isValid() {
      return angular.isDefined(vm.item.username) && vm.item.username !== '' &&
        angular.isDefined(vm.item.email) && vm.item.email !== '';
    }

    function resendActivationMail(user) {
      var id;

      if (angular.isDefined(user)) {
        id = user.id;
      } else {
        id = vm.item.id;
      }

      /* resending activation mail */
      UserFactory.one(id).one('resend_activation').get().then(function () {
        // Toaster success
        ToastrNotificationService.showTranslatedNotification(
          'success',
          'app.activation-mail-sended',
          ''
        );
      }, function (error) {
        // Something went wrong
        LogService.log('User Resend activation error:' + error, 'debug');

        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.error_occured',
          ''
        );
      });
    }

    function toggleUserLock(user) {
      UserFactory.one(user.id).patch({locked: !user.locked}).then(function () {
        ToastrNotificationService.showTranslatedNotification(
          'success',
          user.locked ? 'app.user_unlocked' : 'app.user_locked',
          ''
        );

        vm.loadEntities(true);
      }, function () {
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.error_occured',
          ''
        );
        vm.loadEntities(true);
      });
    }

    function openUserAddEditModal(id, edit) {
      UtilService.showModal({
        templateUrl: 'user/views/users.add.edit.modal.tpl.html',
        controller: 'UserAddEditCtrl',
        controllerAs: 'userAddEditCtrl',
        size: 'lg',
        resolve: {
          isEdit: function () {
            return edit;
          },
          userId: function () {
            return id;
          }
        }
      }, function () {
        vm.loadEntities(true);
      },
        function () {
          vm.loadEntities(true);
        }
      );
    }

    function openUserContextAddEditModal(userId, userContextId, isEdit) {
      UtilService.showModal({
        templateUrl: 'user/views/user_contexts.add.edit.modal.tpl.html',
        controller: 'UserContextCtrl',
        controllerAs: 'userContextCtrl',
        resolve: {
          isEdit: function () {
            return isEdit;
          },
          userId: function () {
            return userId;
          },
          userContextId: function () {
            return userContextId;
          }
        }
      },
        function () {
          vm.loadEntities(true);
        },
        function () {
          vm.loadEntities(true);
        });
    }

    function openUserContextDeleteModal(userId, userContextId) {
      UtilService.showModal({
        templateUrl: 'user/views/user_contexts.delete.modal.tpl.html',
        controller: 'UserContextCtrl',
        controllerAs: 'userContextCtrl',
        resolve: {
          isEdit: function () {
            return true;
          },
          userId: function () {
            return userId;
          },
          userContextId: function () {
            return userContextId;
          }
        }
      },
        function () {
          vm.loadEntities(true);
        },
        function () {
          vm.loadEntities(true);
        });
    }

    function contextIsActive(userContext) {
      return userContext.active || angular.isUndefined(userContext.active);
    }

    function openUserContextActivateModal(userId, userContextId) {
      UtilService.showModal({
        templateUrl: 'user/views/user_contexts.activate.modal.tpl.html',
        controller: 'UserContextCtrl',
        controllerAs: 'userContextCtrl',
        resolve: {
          isEdit: function () {
            return true;
          },
          userId: function () {
            return userId;
          },
          userContextId: function () {
            return userContextId;
          }
        }
      },
      function () {
        vm.loadEntities(true);
      },
      function () {
        vm.loadEntities(true);
      });
    }
  }
}());
