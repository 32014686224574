(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('analyticalAccount')
    .factory('AnalyticalAccountFactory', AnalyticalAccountFactory);

  function AnalyticalAccountFactory(Restangular) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'analyticalAccount'});
    }).service('analytical_accounts');
  }
}());
