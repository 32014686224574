(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosScreenDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosScreenDeleteCtrl', PosScreenDeleteCtrl);

  function PosScreenDeleteCtrl(screen, PosScreenFactory, $modalInstance) {
    var vm = this;
    vm.screen = screen;
    vm.cancel = cancelModalInstance;
    vm.delete = deleteScreen;
    vm.ctrlName = 'PosScreenDeleteCtrl';

    function deleteScreen() {
      PosScreenFactory.one(screen.id).remove().then(function () {
        $modalInstance.close('done');
      });
    }
    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }
  }
}());
