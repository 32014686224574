(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ledger.controller:LedgerCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('ledger')
    .controller('LedgerCtrl', LedgerCtrl);

  function LedgerCtrl($stateParams, $modalInstance, LedgerFactory, UtilService) {
    var vm = this;
    vm.title = 'app.add';
    vm.isEdit = false;
    vm.item = [];

    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.init = init;
    vm.save = save;
    vm.delete = remove;
    vm.isValid = isValid;

    function save() {
      var ledgerObj = {
        code: vm.item.code,
        label: vm.item.label,
        frLabel: vm.item.frLabel
      };

      if (angular.isDefined(vm.item.comments)) {
        ledgerObj.comments = vm.item.comments;
      }
      if (vm.isEdit === true) {
        LedgerFactory.one(vm.item.id).customPUT(ledgerObj).then(function () {
          vm.close();
        });
      } else {
        LedgerFactory.post(ledgerObj).then(function () {
          vm.close();
        });
      }
    }

    function remove() {
      LedgerFactory.one(vm.item.id).remove().then(function () {
        vm.close();
      });
    }

    function init() {
      if (angular.isDefined($stateParams.id)) {
        vm.title = 'app.edit';
        vm.isEdit = true;
      }
      if (vm.isEdit === true) {
        LedgerFactory.one($stateParams.id).get().then(function (item) {
          vm.item = item;
        });
      }
    }

    function isValid() {
      return UtilService.isNotEmpty(vm.item.label) &&
             UtilService.isNotEmpty(vm.item.code);
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance() {
      $modalInstance.close();
    }
  }
}());
