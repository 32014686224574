(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:PosSessionSalesListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('reports')
    .controller('PosSessionSalesListCtrl', PosSessionSalesListCtrl);

  function PosSessionSalesListCtrl(
    $filter,
    $modal,
    _,
    $state,
    $stateParams,
    PaymentMethodsFactory,
    PermissionsUtilService,
    PosGroupFactory,
    ProductUtilService,
    SaleFactory,
    SettingsService,
    ToastrNotificationService,
    UtilService
  ) {
    var vm = this;
    vm.session = null;
    vm.sales = [];
    vm.paymentTotals = [];
    vm.goBack = goBack;
    vm.loadData = loadData;

    vm.modalOpened = false;
    vm.modalInstance = null;
    vm.sessionComments = null;
    vm.showSaleModalView = showSaleModalView;
    vm.canBeApproved = canBeApproved;
    vm.canBeRejected = canBeRejected;
    vm.useExternalOrderEngine = SettingsService.get('pos.useExternalOrderEngine', false);
    vm.standaloneMode = SettingsService.get('pos.standaloneMode', false);
    vm.hideStartAmount = SettingsService.get('pos.hideStartAmount', false);
    vm.extendedApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '2');
    vm.automaticApprovalFlow = (SettingsService.get('pos.session.approvalFlowVersion') === '3');
    vm.enableInternalComments = SettingsService.get('pos.saleItem.enableInternalComments');
    vm.loadData();

    function showSaleModalView(viewObject) {
      // Check if modal view is open
      if (vm.modalOpened) {
        return;
      }

      vm.modalInstance = $modal.open({
        templateUrl: 'accountancy/views/accountancy.sale.view.modal.tpl.html',
        controller: 'SaleViewCtrl',
        controllerAs: 'saleViewCtrl',
        resolve: {
          sale: function () {
            return viewObject;
          }
        }
      });
      vm.modalOpened = true;

      // Check if dismiss or close action of modal view was called
      vm.modalInstance.result.then(function () {
        vm.modalOpened = false;
      }, function () {
        vm.modalOpened = false;
      });
    }

    vm.showSaleAddEditModal = function (viewObject) {
      UtilService.showModal(
        {
          templateUrl: 'accountancy/views/accountancy.sale.addedit.modal.tpl.html',
          controller: 'SaleAddEditCtrl',
          controllerAs: 'saleAddEditCtrl',
          size: 'lg',
          resolve: {
            sale: function () {
              return angular.isUndefined(viewObject) ? null : viewObject;
            }
          }
        },
        function () {
          vm.loadData();
        }
      );
    };

    function loadData() {
      var saleParams = {
            limit: 99,
            sort: 'updatedAt,ASC'
          },
          sessionEnded = false,
          paymentMethodMapping = {},
          saleStatus = '';

      PaymentMethodsFactory.getCodeLabelMapping().then(function (mapping) {
        paymentMethodMapping = mapping;
      });

      saleParams['filter[]'] = ['pointOfSaleSession.id,' + $stateParams.sesId];
      if (SettingsService.get('pos.session.showOnlyCompletedSales', false)) {
        saleStatus = 'saleStatus.code,COMPLETED';
      }

      if (SettingsService.get('pos.saleStatusPaymentRequestedEnabled', false)) {
        // check if saleStatus already exist
        saleStatus = (saleStatus.length) ? 'OR,' + saleStatus + ',saleStatus.code,PAYMENT_REQUESTED' : 'saleStatus.code,PAYMENT_REQUESTED';
      }
      //add saleStatus filter
      if (saleStatus.length) {
        saleParams['filter[]'].push(saleStatus);
      }

      if (UtilService.isNotEmpty($stateParams.groId) &&
          UtilService.isNotEmpty($stateParams.insId) &&
          UtilService.isNotEmpty($stateParams.sesId)) {
        PosGroupFactory.one($stateParams.groId).one('instances').one($stateParams.insId).one('sessions')
        .one($stateParams.sesId).get().then(function (session) {
          vm.session = session;
          vm.sessionComments = session.comments;
          if (angular.isDefined(vm.session.endedAt) && vm.session.endedAt !== null) {
            sessionEnded = true;
          }
          PosGroupFactory.one($stateParams.groId)
            .one('instances').one($stateParams.insId)
            .one('sessions').one($stateParams.sesId)
            .one('balance')
            .get()
            .then(function (balance) {
              vm.paymentTotals = [];
              angular.forEach((sessionEnded ? balance.enteredPerPaymentMethod : balance.projectedPerPaymentMethod), function (amount, code) {
                if (amount.EUR || balance.balancePerPaymentMethod[code].EUR) {
                  vm.paymentTotals.push({
                    label: angular.isDefined(paymentMethodMapping[code]) ? paymentMethodMapping[code] : $filter('uconlyfirst')(code),
                    entered: amount.EUR,
                    balance: (sessionEnded ? balance.balancePerPaymentMethod[code].EUR : '-')
                  });
                }
              });
              if (!vm.hideStartAmount) {
                vm.paymentTotals.push({
                  label: $filter('uconlyfirst')($filter('translate')('app.start_amount')),
                  entered: balance.startAmount.EUR,
                  balance: '-'
                });
              }
              vm.paymentTotals.push({
                label: $filter('uconlyfirst')($filter('translate')('app.total')),
                entered: balance.projectedAmount.EUR,
                balance: balance.balanceAmount.EUR
              });
            });
        });

        if (vm.useExternalOrderEngine && PermissionsUtilService.userHasPermission('manage pos session')) {
          saleParams.details = 'true';
        }
        SaleFactory.getList(saleParams).then(function (sales) {
          _.each(sales, function (sale) {
            _.each(sale.saleItems, function (product) {
              var priceComponent;

              priceComponent = _.findWhere(product.product.productComponents, {type: 'price'});
              if (UtilService.isNotEmpty(priceComponent)) {
                if (UtilService.isNotEmpty(product.price)) {
                  product.vatPrice = ProductUtilService.calculateInclPrice(product.price, priceComponent.vatRate.percentage).vatPrice;
                  product.priceInclVat = ProductUtilService.calculateInclPrice(product.price, priceComponent.vatRate.percentage).inclPrice;
                } else {
                  product.vatPrice = ProductUtilService.calculateInclPrice(priceComponent.price, priceComponent.vatRate.percentage).vatPrice;
                  product.priceInclVat = ProductUtilService.calculateInclPrice(priceComponent.price, priceComponent.vatRate.percentage).inclPrice;
                }
              }
              product.totalOwed = product.priceInclVat * product.quantity;
            });
          });
          vm.sales = sales;
        });
      }
    }

    vm.approvePosSession = function () {
      if (!vm.session.endedAt || vm.session.isApproved) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'error',
          'app.error',
          '',
          'pos.session_not_approved'
        );
        return Promise.reject();
      }

      return PosGroupFactory.one($stateParams.groId)
        .one('instances').one($stateParams.insId)
        .one('sessions').one($stateParams.sesId)
        .post('approve')
        .then(function () {
          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'success',
            'app.success',
            '',
            'pos.session_approved'
          );

          // save comments
          if (vm.session.comments !== vm.sessionComments) {
            return PosGroupFactory.one($stateParams.groId)
              .one('instances').one($stateParams.insId)
              .one('sessions').one($stateParams.sesId)
              .patch({comments: vm.sessionComments})
              .then(function () {
                loadData();
              });
          }

          loadData();
        });
    };

    vm.rejectPosSession = function () {
      if (!vm.session.endedAt || vm.session.isApproved) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
          'error',
          'app.error',
          '',
          'pos.session_not_rejected'
        );
        return Promise.reject();
      }

      return PosGroupFactory.one($stateParams.groId)
        .one('instances').one($stateParams.insId)
        .one('sessions').one($stateParams.sesId)
        .post('reject')
        .then(function () {
          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'success',
            'app.success',
            '',
            'pos.session_rejected'
          );

          // save comments
          if (vm.session.comments !== vm.sessionComments) {
            return PosGroupFactory.one($stateParams.groId)
              .one('instances').one($stateParams.insId)
              .one('sessions').one($stateParams.sesId)
              .patch({comments: vm.sessionComments})
              .then(function () {
                loadData();
              });
          }

          loadData();
        });
    };

    function canBeApproved() {
      if (vm.session !== null && !vm.automaticApprovalFlow) {
        return !vm.session.isApproved && !vm.session.isCashierEmpty && vm.session.numberOfSales > 0;
      }
      return false;
    }

    function canBeRejected() {
      if (vm.session !== null) {
        return vm.extendedApprovalFlow && vm.session.numberOfSales > 0;
      }
    }

    function goBack() {
      $state.go('reports', {
        tab: ($stateParams.backTab || 'sessions'),
        loadFilters: true,
        currentPage: ($stateParams.indexPage) ? $stateParams.indexPage : 1,
        loadCheckboxState: true
      });
    }
  }
}());
