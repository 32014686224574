(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:PosStateFactory
   *
   * @description This factory provides storage for state shared between
   *              payment, PoS, shopping cart, etc.. controllers.
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PosState', PosStateFactory);

  function PosStateFactory() {
    var PosStateBase = {};
    PosStateBase.state = null;

    /**
     * Initialize/Constructor.
     *
     * @return {object} PosStateBase Factory.
     */
    PosStateBase.init = function () {
      PosStateBase.reset();

      return PosStateBase;
    };

    /**
     * Reset the state of the object.
     *
     * @return {object} PosStateBase Factory.
     */
    PosStateBase.reset = function () {
      PosStateBase.state = {};

      return PosStateBase;
    };

    /**
     * Get a value by key, optionally specifying a
     * default value to be returned if the given key
     * does not exist.
     *
     * @param  {string} key          Key to return value for.
     * @param  {mixed} defaultValue  Default value to return.
     * @return {mixed} value         Value.
     */
    PosStateBase.get = function (key, defaultValue) {
      var value = defaultValue || null;

      if (key in PosStateBase.state) {
        value = PosStateBase.state[key];
      }

      return value;
    };

    /**
     * Set a value for the given key.
     *
     * @param {string} key    Key to set value for.
     * @param {mixed} value   Value to set for key.
     */
    PosStateBase.set = function (key, value) {
      PosStateBase.state[key] = value;
    };

    /**
     * Remove a key.
     *
     * @param {string} key Key to remove.
     */
    PosStateBase.remove = function (key) {
      if (key in PosStateBase.state) {
        delete PosStateBase.state[key];
      }
    };

    /**
     * Return a boolean indicating whether or not
     * the given key exists.
     *
     * @param  {string} key    Key to check for.
     * @return {boolean} value Boolean indicating whether key exists.
     */
    PosStateBase.has = function (key) {
      return (key in PosStateBase.state);
    };

    return PosStateBase.init();
  }
}());
