(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:NewsItemDeleteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('dashboard')
    .controller('NewsItemDeleteCtrl', NewsItemDeleteCtrl);

  function NewsItemDeleteCtrl(
    NewsItemFactory,
    $modalInstance,
    $filter,
    LogService,
    newsItem,
    reloadType,
    ToastrNotificationService
    ) {
    var vm = this;
    vm.newsItem = newsItem;
    vm.cancel = cancelModalInstance;
    vm.remove = remove;
    vm.showDeleteNotification = showDeleteNotification;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function remove() {
      NewsItemFactory.one(vm.newsItem.id).remove().then(function (data) {
        LogService.log('Removed data: ' + data, 'debug');
        // Close the modal view
        vm.showDeleteNotification();
        $modalInstance.close(reloadType);
      });
    }

    function showDeleteNotification() {
      // Show notification at successful remove
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-removed',
        'app.news_item',
        'app.item-successfully-removed',
        'app.news_item'
      );
    }
  }
}());
