(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name posSettings.controller:PosScreenNewCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('posSettings')
    .controller('PosScreenNewCtrl', PosScreenNewCtrl);

  function PosScreenNewCtrl(
      $modalInstance,
      PosGroupFactory,
      PosScreenFactory,
      RestUtilService,
      CurrentUserContextFactory,
      _
    ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.close = closeModalInstance;
    vm.submit = createScreen;
    vm.screen = [];
    vm.posGroups = [];

    RestUtilService.getFullList(PosGroupFactory, {limit: 10}).then(function (resultPosGroups) {
      _.each(resultPosGroups, function (posGroup) {
        vm.posGroups.push(posGroup);
      });
    });

    function createScreen() {
      PosScreenFactory.post({
        label: vm.screen.label,
        master: vm.screen.master,
        pointOfSaleGroups: vm.screen.pointOfSaleGroups,
        site: CurrentUserContextFactory.getSiteId()
      }).then(function (data) {
        vm.close({sId: data.id});
      });
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function closeModalInstance(data) {
      $modalInstance.close(data);
    }
  }
}());
