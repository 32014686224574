(function () {
  'use strict';

  /* @ngdoc object
   * @name kiosk
   * @description
   *
   */
  angular
    .module('kiosk', [
      'ui.router',
      'ngIdle'
    ])
    .config(function (IdleProvider, TitleProvider) {
      IdleProvider.idle(20);
      IdleProvider.timeout(5);
      TitleProvider.enabled(false);
    });
}());
