(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:SurveyAnswersCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('SurveyAnswersCtrl', SurveyAnswersCtrl);

  function SurveyAnswersCtrl($stateParams, SurveyQueryService, ToastrNotificationService, UtilService) {
    var vm = this;
    vm.queryId = $stateParams.queryId;
    vm.surveyAnswers = [];
    vm.surveySearchQuery = '';

    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    vm.loadSurveyAnswers = function (reload) {
      var queryParams;

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.surveyAnswers[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage
        };

        if (UtilService.isNotEmpty(vm.surveySearchQuery)) {
          queryParams['filter[]'] = 'answer,LIKE ' + vm.surveySearchQuery;
        }

        SurveyQueryService.getSurveyAnswers(vm.queryId, queryParams)
        .then(function (data) {
          vm.surveyAnswers[vm.pagination.currentPage] = data;
          vm.pagination.totalItems = data.count;
        });
      }
    };

    vm.init = function () {
      vm.loadSurveyAnswers();
    };

    vm.init();
  }
}());
