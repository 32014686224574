(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:SavingsCampaignSubscriptionsRegistrationsAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('SavingsCampaignSubscriptionsAddEditCtrl', SavingsCampaignSubscriptionsAddEditCtrl);

  function SavingsCampaignSubscriptionsAddEditCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    SavingsCampaignFactory,
    MobileContentFactory,
    MobileLanguageFactory,
    savingsCampaignSubscription,
    customer,
    $modalInstance
  ) {
    var vm = this;
    vm.savingsCampaignSubscription = savingsCampaignSubscription;
    vm.customer = customer;
    vm.isEdit = isEdit;
    vm.savingsCampaigns = [];
    vm.saveSavingsCampaignSubscriptions = saveSavingsCampaignSubscriptions;
    vm.showSavedNotification = showSavedNotification;
    vm.savingsCampaign = null;
    vm.subscriptionPrice = null;
    vm.savingsCampaignPrices = [];
    vm.loadSavingsCampaign = loadSavingsCampaign;
    vm.reloadPrices = reloadPrices;

    vm.loadSavingsCampaign();

    function isEdit() {
      return angular.isDefined(vm.savingsCampaignSubscription) && vm.savingsCampaignSubscription !== null;
    }

    function loadSavingsCampaign() {
      SavingsCampaignFactory.getList({limit: 99}).then(function (result) {
        var savingsCampaigns = {};
        console.log(result);
        result.forEach(function (savingCampaign) {
          savingsCampaigns[savingCampaign.id] = savingCampaign;
        });

        vm.savingsCampaigns = savingsCampaigns;
      }, function () {
        return {};
      });
    }

    function saveSavingsCampaignSubscriptions() {
      if (vm.savingsCampaign) {
        SavingsCampaignFactory.one(vm.savingsCampaign.id).one('subscribe').one(vm.customer.id).customPOST({ price: vm.subscriptionPrice })
          .then(function () {
            vm.showSavedNotification();
            $modalInstance.close('');
          });
      }
    }

    function reloadPrices() {
      vm.savingsCampaignPrices = angular.isDefined(vm.savingsCampaign) && UtilService.isNotEmpty(vm.savingsCampaign) && angular.isDefined(vm.savingsCampaign.prices) ? vm.savingsCampaign.prices : [];
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.savings_campaign_subscription')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.savings_campaign_subscription')
          )
        )
      );
    }
  }
}());
