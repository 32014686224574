(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:CollectionPeriodsCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('CollectionPeriodsCtrl', CollectionPeriodsCtrl);

  function CollectionPeriodsCtrl(CurrentUserContextFactory,
                                 DebitCollectionPeriodFactory,
                                 UtilService,
                                 LogService) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.periods = [];
    vm.modalInstance = null;
    vm.modalOpened = false;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      maxPages: 10,
      itemsPerPage: 20
    };
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.loadPeriods = loadPeriods;
    vm.showPeriodModalAddView = showPeriodModalAddView;
    /* ----- END FUNCTIONS ----- */

    /* ----- PERIOD MODAL ----- */
    function showPeriodModalAddView() {
      // Check if modal view is open
      if (vm.modalOpened) {
        return;
      }
      LogService.log('opening invoice modal', 'debug');
      UtilService.showModal({
        templateUrl: 'accountancy/views/accountancy.collection-periods.add.modal.tpl.html',
        controller: 'CollectionPeriodsAddCtrl',
        controllerAs: 'collectionPeriodsAddCtrl'
      },
        modalResultHandler,
        modalFailureHandler
      );
    }

    function modalResultHandler() {
      vm.reloadItems();
    }

    function modalFailureHandler() {
    }
    /* ----- END PERIOD MODAL ----- */

    /* ----- OTHER ----- */
    function loadPeriods(reload) {
      var queryParams;

      if (angular.isUndefined(reload)) {
        reload = false;
      }

      if (angular.isUndefined(vm.periods[vm.pagination.currentPage]) || (reload === true)) {
        queryParams = {
          context: CurrentUserContextFactory.getUserContextId(),
          offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
          limit: vm.pagination.itemsPerPage,
          sort: 'createdAt,DESC'
        };

        DebitCollectionPeriodFactory.getList(queryParams).then(function (data) {
          vm.periods[vm.pagination.currentPage] = data;
          vm.pagination.totalItems = data.count;
        });
      }
    }

    vm.reloadItems = function () {
      vm.loadPeriods(true);
    };
    /* ----- END OTHER ----- */
  }
}());
