(function () {
  'use strict';

  /* @ngdoc object
   * @name sportoase
   * @description
   *
   */
  angular.module('sportoase', [
    'accountancy',
    'analyticalAccount',
    'angular-repeat-n',
    'angular.filter',
    'angularMoment',
    'auth',
    'base64',
    'bcQrReader',
    'billingDivision',
    'calendar',
    'cfp.hotkeys',
    'checklist-model',
    'consolidationCode',
    'contact',
    'customer',
    'dashboard',
    'dndLists',
    'duScroll',
    'emguo.poller',
    'event',
    'facility',
    'filters',
    'fitness',
    'hwproxy',
    'image',
    'journal',
    'kiosk',
    'l42y.sprintf',
    'ledger',
    'memberCard',
    'mm.iban',
    'navigation',
    'ng-currency',
    'ngAnimate',
    'ngCookies',
    'ngDialog',
    'ngFileSaver',
    'ngSanitize',
    'ngSentry',
    'ngStorage',
    'pascalprecht.translate',
    'pos',
    'posSettings',
    'product',
    'reports',
    'restangular',
    'settings',
    'promotion',
    'site',
    'sportoase.config',
    'sportoase.run',
    'subscriptionContract',
    'swimcourse',
    'timer',
    'tmh.dynamicLocale',
    'toastr',
    'toastrNotification',
    'ui.bootstrap',
    'ui.mask',
    'ui.router',
    'ui.select',
    'ui.tree',
    'ui.utils.masks',
    'underscore',
    'user',
    'utils',
    'webcam',
    'wysiwyg.module',
    'documentation',
    'mobile'
  ])
    .constant('LOCALES', {
      locales: {
        EN_US: 'locale.en_us',
        NL_BE: 'locale.nl_be',
        FR_FR: 'locale.fr_fr'
      },
      preferredLanguage: getPreferredLocale()
    })
    .config(config)
    .run(
      function ($rootScope,
                ToastrNotificationService,
                $filter,
                $compile,
                $modalStack,
                amMoment,
                PARAMS,
                $window) {
        $rootScope.preferredLanguage = getPreferredLocale();
        if (angular.isDefined(PARAMS)) {
          if (angular.isDefined(PARAMS.environment)) {
            $rootScope.environment = PARAMS.environment;
          }
          if (angular.isDefined(PARAMS.applicationTitle)) {
            $rootScope.applicationTitle = PARAMS.applicationTitle + ' - ';
          }
          if (angular.isDefined(PARAMS.version)) {
            $rootScope.version = PARAMS.version;
          }
        }
        $rootScope.$on('$stateChangeStart', function () {
          $rootScope.ui = {
            navigation: true,
            header: true,
            login: false,
            loading: false
          };
          $modalStack.dismissAll('skip');
          $window.history.forward();
        });
        // Show errorMsg when a call to backend fails
        $rootScope.$on('showErrorMsgToastrBroadcast', function (event, errorMsg) {
          event.preventDefault();
          console.log('errorMsg: ', errorMsg);
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.error',
            'app.general-error'
          );
        });
        // Show errorMsg when a call to backend fails
        $rootScope.$on('showCustomErrorMsgToastrBroadcast', function (event, errorMsg) {
          event.preventDefault();
          console.log('errorMsg: ', errorMsg);
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.error',
            errorMsg
          );
        });
        $rootScope.$on('showMethodNotAllowedMsgToastrBroadcast', function (event) {
          event.preventDefault();
          ToastrNotificationService.showTranslatedNotification(
            'error',
            'app.error',
            'app.method-not-allowed'
          );
        });
      }
    )
    .directive('datepickerTimezone', function () {
      var date;
      return {
        restrict: 'A',
        priority: 1,
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
          ctrl.$formatters.push(function (value) {
            if (angular.isDate(value) && !isNaN(value.getDate()) && value.toISOString().length > 0) {
              date = new Date(Date.parse(value));
              date = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
            } else {
              date = null;
            }
            return date;
          });

          ctrl.$parsers.push(function (value) {
            if (angular.isDate(value) && !isNaN(value.getDate()) && value.toISOString().length > 0) {
              date = new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
            } else {
              date = null;
            }
            return date;
          });
        }
      };
    })
    .directive('formGroup', function () {
      return {
        restrict: 'C',
        priority: 1000,
        link: function (scope, element) {
          if (element.find('*:required').length || element.find('*[required=required]').length) {
            element.addClass('required');
          }
        }
      };
    })
    .directive(
      'requirePermissions',
      function (PermissionsUtilService, UtilService, ngIfDirective) {
        // wrap around ng-if directive to prohibit html rendering rather than using post-render css hiding.
        // see also stackoverflow.com/questions/20325480/angularjs-whats-the-best-practice-to-add-ngif-to-a-directive-programmatically
        var ngIf = ngIfDirective[0];
        return {
          transclude: ngIf.transclude,
          // priority: ngIf.priority - 1,
          priority: 99,
          terminal: ngIf.terminal,
          restrict: ngIf.restrict,
          link: function (scope, element, attributes) {
            // find any initial ng-if attributes set on the current element
            // (so that this directive takes the additional ng-if requirements into account)
            var initialNgIf = attributes.ngIf,
                requirementsSet = UtilService.isNotEmpty(attributes.requirePermissions),
                ifEvaluator,
                selectionTypeSet = UtilService.isNotEmpty(attributes.permissionRequirementType),
                requirePermissions = attributes.requirePermissions;

            // convert permissions to array if opening bracket is found
            if (requirementsSet && requirePermissions.indexOf('[') > -1) {
              requirePermissions = scope.$eval(requirePermissions);
            }

            function arePermissionsGranted() {
              if (requirementsSet) {
                if (selectionTypeSet) {
                  return PermissionsUtilService.userHasPermission(requirePermissions, attributes.permissionRequirementType);
                }
                return PermissionsUtilService.userHasPermission(requirePermissions);
              }
              // no permission requirements set - return true
              return true;
            }

            // if it exists, evaluates ngIf && ifAuthenticated
            if (initialNgIf) {
              ifEvaluator = function () {
                // if (requirementsSet) {
                //   // return scope.$eval(initialNgIf) && PermissionsUtilService.userHasPermission(attributes.requirePermissions);
                //   return scope.$eval(initialNgIf) && arePermissionsGranted();
                // } else {
                //   return scope.$eval(initialNgIf);
                // }
                return scope.$eval(initialNgIf) && arePermissionsGranted();
              };
            } else {
              // if there's no ng-if, process normally
              ifEvaluator = function () {
                // if (requirementsSet) {
                //   return PermissionsUtilService.userHasPermission(attributes.requirePermissions);
                // } else {
                //   // no permission requirements set - return true
                //   return true;
                // }
                return arePermissionsGranted();
              };
            }
            attributes.ngIf = ifEvaluator;
            ngIf.link.apply(ngIf, arguments);
          }
        };
      }
    );

  function config(tmhDynamicLocaleProvider, RestangularProvider, OAUTH_CONFIG, PARAMS, $locationProvider, datepickerPopupConfig, toastrConfig, $modalProvider, $logProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('locales/angular-locale_{{locale}}.js');
    RestangularProvider.setBaseUrl(OAUTH_CONFIG.baseUrl + '/api');
    datepickerPopupConfig.datepickerPopup = 'dd/MM/yyyy';
    datepickerPopupConfig.pickTime = false;

    $logProvider.debugEnabled(false);
    if (angular.isDefined(PARAMS)) {
      if (angular.isDefined(PARAMS.debug)) {
        $logProvider.debugEnabled(PARAMS.debug);
      }
    }
    // Extend toast
    angular.extend(toastrConfig, {
      allowHtml: true
    });

    // add a custom response interceptor to deal with non-array (i.e. json object) return values for getList() calls
    // to the backend
    RestangularProvider.addResponseInterceptor(function (data, operation) {
      var extractedData;
      // .. to look for getList operations
      if (operation === 'getList' && data.hasOwnProperty('results')) {
        // .. and handle the data and meta data
        extractedData = data.results;
        extractedData.error = data.error;
        extractedData.count = data.count;
      } else {
        extractedData = data;
      }
      return extractedData;
    });
    $modalProvider.options = {
      backdrop: 'static',
      keyboard: false
    };
    $locationProvider.html5Mode(true);
  }

  function getPreferredLocale() {
    var userLocale, userLanguage = null;

    /* eslint-disable */
    userLocale = (navigator.language || navigator.userLanguage);
    /* eslint-enable */

    userLanguage = userLocale;
    if (userLanguage.indexOf('-') > -1) {
      userLanguage = userLanguage.substring(0, userLanguage.indexOf('-')).toLowerCase();
    }

    switch (userLanguage) {
      case 'en':
        return 'EN_US';
      case 'nl':
        return 'NL_BE';
      case 'fr':
        return 'FR_FR';
      default:
        return 'NL_BE';
    }
  }
}());
