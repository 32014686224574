(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name site.controller:SiteCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('site')
    .controller('SiteCtrl', SiteCtrl);

  function SiteCtrl(
      LogService,
      $modal,
      $scope,
      $state,
      $stateParams,
      SiteFactory
    ) {
    var vm = this;
    vm.reloadPageData = reloadPageData;
    vm.reloadListData = reloadListData;
    vm.showModalView = showModalView;
    vm.modalInstance = null;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.pagination = {
      totalItems: '',
      currentPage: 1,
      itemsPerPage: 15
    };

    // Check the state of the customer page
    if ($state.is('sitedetail')) {
      // Reload page data
      vm.reloadPageData($stateParams.id);
    } else {
      // Reload List Data
      vm.reloadListData();
      LogService.log('reload List Data', 'debug');
    }

    function reloadPageData(siteId) {
      // Get facility
      SiteFactory.one(siteId).get().then(function (resultSite) {
        vm.currentSite = resultSite;
      });
    }

    function reloadListData() {
      var params = {
        offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
        limit: vm.pagination.itemsPerPage,
        sort: ['label,asc']
      };
      // Get all Facilities
      SiteFactory.getList(params).then(function (resultSites) {
        vm.sites = resultSites;
        vm.pagination.totalItems = resultSites.count;
      });
    }

    function showModalView(returnType, type, site) {
      LogService.log('showing Modal', 'debug');
      switch (type) {
        case 'add':
        case 'edit':
          LogService.log('is edit or add', 'debug');
          if (vm.modalInstance === null) {
            vm.modalInstance = $modal.open({
              templateUrl: 'site/views/site.modal.addedit.tpl.html',
              controller: 'SiteAddEditCtrl',
              controllerAs: 'siteAddEditCtrl',
              resolve: {
                site: function () {
                  return site;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            });
            vm.returnOfModalInstance();
          }
          break;

        case 'view':
          LogService.log('is view', 'debug');
          break;

        case 'delete':
          LogService.log('is delete', 'debug');
          if (vm.modalInstance === null) {
            vm.modalInstance = $modal.open({
              templateUrl: 'site/views/site.modal.delete.tpl.html',
              controller: 'SiteDeleteCtrl',
              controllerAs: 'siteDeleteCtrl',
              resolve: {
                site: function () {
                  return site;
                },
                reloadType: function () {
                  return returnType;
                }
              }
            });
            vm.returnOfModalInstance();
          }
          break;

        default:
          LogService.log('is default' + type, 'debug');
          break;
      }
    }

    function returnOfModalInstance() {
      // Check result of modal view
      vm.modalInstance.result.then(function (returnValue) {
        vm.modalInstance = null;
        // Check the type of the returnValue (= reloadOption) and fire specific reload-method
        switch (returnValue) {
          case 'page':
            vm.reloadPageData(vm.currentSite.id);
            break;

          case 'list':
            vm.reloadListData();
            break;

          default:
            break;
        }
      }, function (returnValue) {
        LogService.log('reason of closing: ' + returnValue, 'debug');
        vm.modalInstance = null;
      });
    }
  }
}());
