(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:Discount
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('PaymentMethodsFactory', PaymentMethodsFactory);

  function PaymentMethodsFactory($filter, PaymentMethodSiteFactory, Restangular) {
    var paymentMethodsService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'paymentMethod'});
    })
    .service('sales/payments/types');

    paymentMethodsService.getPosList = function (params) {
      return PaymentMethodSiteFactory.getList(params).then(function (result) {
        var methods = [];
        result.forEach(function (method) {
          method.paymentMethod.weight = method.weight;
          methods.push(method.paymentMethod);
        });
        return methods;
      });
    };

    paymentMethodsService.getAccountancyList = function (params) {
      return paymentMethodsService.getPosList(params).then(function (methods) {
        var cardMethods = [],
            electronicPaymentMethodKey = null,
            electronicPaymentMethodWeight = 0;

        methods.forEach(function (method, key) {
          if (method.code === 'ELECTRONIC_PAYMENT') {
            electronicPaymentMethodKey = key;
            electronicPaymentMethodWeight = method.weight;
          }
        });

        if (electronicPaymentMethodKey !== null) {
          return paymentMethodsService.getList({limit: 2, 'filter[]': ['OR,code,CREDIT_CARD,code,DEBIT_CARD']})
          .then(function (subResult) {
            subResult.forEach(function (subMethod) {
              subMethod.weight = electronicPaymentMethodWeight;
              electronicPaymentMethodWeight += 0.1;
              cardMethods.push(subMethod);
            });
            methods.splice(electronicPaymentMethodKey, 1, cardMethods[0], cardMethods[1]);
            methods = methods.filter(angular.isDefined);
            return methods;
          });
        }

        return methods;
      });
    };

    paymentMethodsService.getClosePosSessionList = function (params) {
      return paymentMethodsService.getAccountancyList(params).then(function (methods) {
        return paymentMethodsService.getList({limit: 1, 'filter[]': ['code,DISCOUNT']})
        .then(function (subResult) {
          subResult.forEach(function (subMethod) {
            methods.push(subMethod);
          });
          return methods;
        });
      });
    };

    paymentMethodsService.getCodeLabelMapping = function () {
      return paymentMethodsService.getList({limit: 99}).then(function (result) {
        var mapping = {};
        result.forEach(function (paymentMethod) {
          mapping[paymentMethod.code] = $filter('uconlyfirst')(paymentMethod.translatedLabel);
        });
        return mapping;
      }, function () {
        return {};
      });
    };

    return paymentMethodsService;
  }
}());
