(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SubscriptionAddEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SubscriptionAddEditCtrl', SubscriptionAddEditCtrl);

  function SubscriptionAddEditCtrl(
    LevelFactory,
    SwimCourseSetFactory,
    $modalInstance,
    subscription,
    set,
    level,
    contact,
    SwimCourseManagementFactory,
    ServiceFactory,
    UtilService
  ) {
    var vm = this;
    vm.contact = contact;
    vm.sets = [];
    vm.levels = [];
    vm.currentSubscription = subscription;
    vm.selectedSetIds = [];
    vm.selectedLevel = null;
    vm.comments = '';
    vm.prioritized = false;
    vm.subscriptionDate = new Date();
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.startTime = new Date();
    vm.isEdit = isEdit;
    vm.cancel = cancelModalInstance;
    vm.getAsyncSet = getAsyncSet;
    vm.getAsyncLevel = getAsyncLevel;
    vm.getAsyncGroup = getAsyncGroup;
    vm.saveSubscription = saveSubscription;
    vm.deleteSubscription = deleteSubscription;
    // vm.getSubscriptionRestObject = getSubscriptionRestObject;
    vm.loadData = loadData;
    vm.loadData();
    vm.setChange = setChange;

    function setChange() {
      ServiceFactory.getSwimCourseSetsByLevel(vm.selectedLevel.id).then(function (result) {
        vm.sets = result;
        vm.selectedSetIds = [];
        angular.forEach(vm.sets, function (value) {
          vm.selectedSetIds[value.id] = false;
        }, vm.selectedSetIds);
      });
    }

    function loadData() {
      var params = {
        limit: 99
      };
      LevelFactory.getList(params).then(function (result) {
        vm.levels = result;
      });
    }
    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    // function getSubscriptionRestObject() {
    //   var restObject = null;
    //   if (vm.selectedSet !== null && vm.selectedLevel !== null) {
    //     restObject = {
    //       swimCourseSet: vm.selectedSet.id,
    //       swimCourseLevel: vm.selectedLevel.id,
    //       contact: vm.contact.contact.id
    //     };
    //   }
    //   return restObject;
    // }

    // this function retrieves sets for the typeahead field
    function getAsyncSet(viewValue) {
      var params = {
        limit: 99
      };
      params['filter[]'] = ['label,LIKE ' + viewValue];
      params['filter[]'].push('mostRecent,TRUE');
      return SwimCourseSetFactory.getList(params);
    }

    // this function retrieves levels for the typeahead field
    function getAsyncLevel(viewValue) {
      var params = {
        limit: 99
      };
      params['filter[]'] = ['label,LIKE ' + viewValue];
      params['filter[]'].push('mostRecent,TRUE');
      return LevelFactory.getList(params);
    }

    function getAsyncGroup(viewValue) {
      var foundGroupIds = [],
          result = [],
          params = {
            limit: 99
          };
      params['filter[]'] = ['label,LIKE ' + viewValue];

      // get all active groups for this set
      if (vm.selectedLevel !== null && vm.selectedSet !== null) {
        return SwimCourseSetFactory.one(vm.selectedSet.id).get().then(function (resultSet) {
          angular.forEach(resultSet.swimCourseSeries, function (scs) {
            angular.forEach(scs.swimCourseSeriesGroups, function (scsg) {
              // check if the group belongs to this level
              // and was not known before
              // and matches our search query
              if (scsg.swimCourseGroup.swimCourseLevel.id === vm.selectedLevel.id &&
                foundGroupIds.indexOf(scsg.swimCourseGroup.id) === -1 &&
                scsg.swimCourseGroup.label.toLowerCase().indexOf(viewValue.toLowerCase()) > -1) {
                foundGroupIds.push(scsg.id);
                result.push(scsg.swimCourseGroup);
              }
            });
          });
          return result;
        });
      }
      return null;
    }

    function deleteSubscription() {
      if (isEdit()) {
        vm.currentSubscription.remove();
      }
      // Close the modal view
      $modalInstance.close();
    }

    function saveSubscription() {
      var reloadOption = 'reloadListData',
          selectedSets = [],
          subscribedAt = '';
      vm.subscriptionDate = new Date(vm.subscriptionDate.setMinutes(vm.startTime.getMinutes()));
      vm.subscriptionDate = new Date(vm.subscriptionDate.setHours(vm.startTime.getHours()));
      subscribedAt = vm.subscriptionDate.toISOString();

      angular.forEach(vm.sets, function (retrievedSet) {
        if (vm.selectedSetIds[retrievedSet.id]) {
          selectedSets.push(retrievedSet);
        }
      });

      UtilService.promiseLoop(selectedSets,
          function (currentSet) {
            return SwimCourseManagementFactory.createNewSubscription(
                vm.contact.contact.id,
                currentSet.id,
                vm.selectedLevel.id,
                vm.comments,
                vm.prioritized,
                null,
                subscribedAt
            );
          }
      )
      .then(function () {
        $modalInstance.close(reloadOption);
      }, function (errorMsg) {
        console.error('error: ' + errorMsg);
      });
    }

    function isEdit() {
      return vm.currentSubscription !== null;
    }

    function openDatePicker(editType) {
      switch (editType) {
        case 'subscriptionDate':
          vm.datePickerIsOpened = true;
          break;

        default:
          break;
      }
    }
  }
}());
