(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name auth.controller:PasswordChangeCtrl
   */
  /* @ngInject */
  angular.module('auth').controller('PasswordChangeCtrl', function PasswordChangeCtrl(
    $modalInstance,
    $scope,
    ToastrNotificationService,
    UserMeFactory
  ) {
    $scope.save = function (pw) {
      return UserMeFactory.one().patch({ plainPassword: { first: pw, second: pw } }).then(function () {
        ToastrNotificationService.showTranslatedNotification(
          'success',
          'password.changed',
          'password.changed-successfully'
        );
        $modalInstance.close();
      });
    };
  });
}());
