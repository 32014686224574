(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pos.factory:SurveyQuery
   *
   * @description This factory provides a common interface for creating survey query objects.
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .factory('SurveyQueryFactory', SurveyQueryFactory);

  function SurveyQueryFactory(
    UtilService,
    CurrentUserContextFactory
  ) {
    function Template() {
      this.id = '';
      this.label = '';
      this.active = '';
      this.question = '';
      this.site = '';
      this.startDate = new Date();
      this.endDate = '';
      this.surveyQueryChoices = [];
    }

    function Filters() {
      this.label = {
        val: '',
        field: 'label',
        cond: 'LIKE'
      };

      this.question = {
        val: '',
        field: 'question',
        cond: 'LIKE'
      };
    }

    return {
      createSurveyQuery: function (options, setDefaultValues) {
        var surveyQueryObject = new Template();

        if (typeof setDefaultValues !== 'boolean') {
          setDefaultValues = true;
        }

        if (UtilService.isNotEmpty(options)) {
          // overwrite with provided options
          Object.assign(surveyQueryObject, options);

          if (UtilService.isNotEmpty(surveyQueryObject.startDate)) {
            surveyQueryObject.startDate = new Date(surveyQueryObject.startDate);
          }

          if (UtilService.isNotEmpty(surveyQueryObject.endDate)) {
            surveyQueryObject.endDate = new Date(surveyQueryObject.endDate);
          }
        }

        if (setDefaultValues) {
          this.setDefaultValues(surveyQueryObject);
        }

        return surveyQueryObject;
      },

      // TODeloO this function belongs in restUtils
      createRestObject: function (surveyQuery) {
        var restObject = new Template(), value;

        if (UtilService.isNotEmpty(restObject.startDate)) {
          restObject.startDate = restObject.startDate.toISOString().substr(0, 10);
        }

        if (UtilService.isNotEmpty(restObject.endDate)) {
          restObject.endDate = restObject.endDate.toISOString().substr(0, 10);
        }

        angular.forEach(Object.keys(restObject), function (key) {
          if (UtilService.isNotEmpty(surveyQuery[key])) {
            value = surveyQuery[key];

            if (key === 'surveyQueryChoices') {
              surveyQuery[key].map(function (a) {
                return angular.forEach(Object.keys(a), function (inKey) {
                  if (inKey !== 'name') {
                    delete a[inKey];
                  }
                });
              });
            }

            // convert value to an id if it's an object
            if (angular.isObject(surveyQuery[key]) && UtilService.isNotEmpty(surveyQuery[key].id)) {
              value = surveyQuery[key].id;
            }
            restObject[key] = value;
          } else {
            delete restObject[key];
          }
        });

        return restObject;
      },
      setDefaultValues: function (surveyQuery) {
        // site id is only set upon initial creation (i.e. 'post'-call) of the object in the backend
        // (So only when the surveyQuery does not an id yet, otherwise we intend to patch)
        if (UtilService.isEmpty(surveyQuery.id) && UtilService.isEmpty(surveyQuery.site)) {
          surveyQuery.site = CurrentUserContextFactory.getSiteId();
        }
      },
      createFilters: function () {
        return new Filters();
      }
    };
  }
}());
