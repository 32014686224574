(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:StandaloneOverviewCtrl.
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .controller('StandaloneOverviewCtrl', StandaloneOverviewCtrl);

  function StandaloneOverviewCtrl(
    $timeout,
    $q,
    CurrentUserContextFactory,
    HistoryFactory,
    SettingFactory,
    SettingsService,
    SiteFactory,
    UserContextFactory,
    UtilService
  ) {
    var vm = this;
    vm.sites = {};
    vm.isSuperAdmin = false;
    vm.standaloneDefault = false;

    vm.loadSitesInfo = loadSitesInfo;
    vm.showSettingHistoryModal = showSettingHistoryModal;
    vm.toggleStandaloneMode = toggleStandaloneMode;
    vm.toggleGlobally = toggleGlobally;

    loadSitesInfo();

    function loadSitesInfo() {
      return new Promise(function (resolve) {
        SettingFactory.getList({
          'filter[]': [
            'code,pos.standaloneMode',
            'site.id,NULL'
          ],
          limit: 99
        }).then(function (result) {
          if (result.length > 0) {
            vm.standaloneDefault = getSetting(result[0].value);
          }

          SiteFactory.getList({
            limit: 99
          }).then(function (siteResults) {
            angular.forEach(siteResults, function (site) {
              vm.sites[site.id] = site;
              vm.sites[site.id].standaloneMode = vm.standaloneDefault;
              vm.sites[site.id].editable = false;
            });

            SettingFactory.getList({
              'filter[]': [
                'code,pos.standaloneMode',
                'site.id,NOT NULL'
              ],
              limit: 99
            }).then(function (settingResults) {
              angular.forEach(settingResults, function (setting) {
                vm.sites[setting.site.id].standaloneMode = getSetting(setting.value);
                vm.sites[setting.site.id].settingId = setting.id;
              });

              UserContextFactory.getList({
                'filter[]': [
                  'OR,roles.label,LIKE ADMINISTRATOR,roles.label,LIKE Administrator',
                  'user.username,' + CurrentUserContextFactory.getUserName()
                ],
                limit: 99
              }).then(function (contextResults) {
                var contextCounter = 0;
                angular.forEach(contextResults, function (userContext) {
                  if (!vm.sites[userContext.site.id].editable) {
                    vm.sites[userContext.site.id].editable = true;
                    contextCounter++;
                  }
                });
                if (Object.keys(vm.sites).length === contextCounter) {
                  vm.isSuperAdmin = true;
                }
                resolve();
              });
            });
          });
        });
      });
    }

    function showSettingHistoryModal(id) {
      UtilService.showModal(
        {
          templateUrl: 'utils/views/history.view.modal.tpl.html',
          controller: 'HistoryViewCtrl',
          controllerAs: 'historyViewCtrl',
          resolve: {
            entityId: function () {
              return id;
            },
            entityName: function () {
              return 'app.standalone_mode';
            },
            keys: function () {
              return ['code', 'value', 'deletedAt'];
            }
          }
        }, null, null, true);
    }

    function toggleGlobally(status) {
      return new Promise(function (resolve) {
        var bodyMessage = status ? 'settings.sureToEnableStandalone' : 'settings.sureToDisableStandalone', edited = false;
        UtilService.showTranslatedConfirmationModal('app.standalone_mode', bodyMessage, function (result) {
          if (result) {
            SettingFactory.getList({
              limit: 99,
              'filter[]': [
                'code,pos.standaloneMode'
              ]
            }).then(function (results) {
              var requestQueue = [], value;
              value = status ? 'true' : 'false';

              angular.forEach(results, function (setting) {
                if (angular.isUndefined(setting.site)) {
                  edited = true;
                }
                requestQueue.push(SettingFactory.one(setting.id).patch({
                  value: value
                }));
              });

              // if ther is no global setting, create it
              if (!edited) {
                requestQueue.push(SettingFactory.post({
                  code: 'pos.standaloneMode',
                  value: value
                }));
              }

              $q.all(requestQueue).then(function () {
                SettingsService.reloadSettings(undefined, false).then();
                loadSitesInfo().then(resolve);
              });
            });
          } else {
            resolve();
          }
        });
      });
    }

    function toggleStandaloneMode(site) {
      var bodyMessage = site.standaloneMode ? 'settings.sureToDisableStandalone' : 'settings.sureToEnableStandalone';
      return new Promise(function (resolve) {
        UtilService.showTranslatedConfirmationModal(site.label, bodyMessage, function (result) {
          if (result) {
            return SettingsService.set('pos.standaloneMode', (site.standaloneMode ? 'false' : 'true'), site.id)
              .then(function () {
                if (site.id === CurrentUserContextFactory.getSiteId()) {
                  SettingsService.reloadSettings(undefined, false).then(resolve);
                }
                loadSitesInfo().then(resolve);
              });
          }
          resolve();
        });
      });
    }

    function getSetting(value) {
      if (value.toLowerCase() === 'true') {
        return true;
      }

      return false;
    }
  }
}());
