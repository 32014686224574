(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name event.factory:EventTypeFactory
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('event')
    .factory('EventTypeFactory', EventTypeFactory);

  function EventTypeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventType'});
    })
    .service('events/types');
  }
}());
