(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:JournalTypesCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('JournalTypesCtrl', JournalTypesCtrl);

  function JournalTypesCtrl(
    $modal,
    JournalTypeFactory,
    $state,
    $stateParams,
    PermissionsUtilService) {
    var vm = this,
        opened = false,
        modalInstance = null;
    vm.journalTypeSearchQuery = '';
    vm.journalTypes = [];
    vm.showModalAddEditView = showModalAddEditView;
    vm.showModalDeleteView = showModalDeleteView;
    vm.searchJournalTypes = searchJournalTypes;
    vm.returnOfModalInstance = returnOfModalInstance;
    vm.reloadList = reloadList;
    vm.activateTab = activateTab;
    // define pagination
    vm.pagination = {
      totalItems: '',
      maxPages: 10,
      currentPage: 1,
      itemsPerPage: 15
    };
    vm.reloadList();
    vm.tabs = {
      journal_types: {
        label: 'app.settings.journals.types',
        active: false,
        template: 'journal/views/journal-types.tab.tpl.html',
        permissions: ['access journal section'],
        disabled: true
      },
      accessControl: {
        label: 'app.settings.journals.control',
        active: false,
        template: 'journal/views/journal-access-control.tab.tpl.html',
        permissions: ['access access control'],
        disabled: true
      },
      squash: {
        label: 'app.settings.journals.squash',
        active: false,
        template: 'journal/views/journal-access-control.tab.tpl.html',
        permissions: ['access squash control'],
        disabled: true
      },
      tanningBed: {
        label: 'app.settings.journals.tanning_bed',
        active: false,
        template: 'journal/views/journal-access-control.tab.tpl.html',
        permissions: ['access tanning bed control'],
        disabled: true
      }
    };


    Object.keys(vm.tabs).forEach(function (key) {
      if (PermissionsUtilService.userHasPermission(vm.tabs[key].permissions)) {
        vm.tabs[key].disabled = false;
      }
    });


    if ($stateParams.tab && vm.tabs.hasOwnProperty($stateParams.tab)) {
      vm.tabs[$stateParams.tab].active = true;
    } else {
      vm.tabs.journal_types.active = true;
    }

    function searchJournalTypes(item) {
      var lcQuery = vm.journalTypeSearchQuery.toLowerCase();
      // Check if isEmpty, indexOf is -1 if not found
      if (lcQuery.length === 0 ||
          item.label.toLowerCase().indexOf(lcQuery) !== -1) {
        return true;
      }
      return false;
    }

    function showModalAddEditView(journalType) {
      // Check if modal view is open
      if (opened) {
        return;
      }
      // Check the type of the sended Object and open modal view with template and controller and add objects to that controller
      modalInstance = $modal.open({
        templateUrl: 'journal/views/journal-type.modal.addedit.tpl.html',
        controller: 'JournalTypeAddEditCtrl',
        controllerAs: 'journalTypeAddEditCtrl',
        resolve: {
          journalType: function () {
            return journalType;
          }
        }
      });
      opened = true;
      vm.returnOfModalInstance();
    }

    function activateTab(tab) {
      angular.forEach(vm.tabs, function (value, key) {
        value.active = key === tab;
      });
      $state.go($state.current, {tab: tab}, {reload: false});
    }

    function showModalDeleteView(journalType) {
      // Check if modal view is open
      if (opened) {
        return;
      }
      // Check the type of the sended Object and open modal view with template and controller and add objects to that controller
      modalInstance = $modal.open({
        templateUrl: 'journal/views/journal-type.modal.delete.tpl.html',
        controller: 'JournalTypeDeleteCtrl',
        controllerAs: 'journalTypeDeleteCtrl',
        resolve: {
          journalType: function () {
            return journalType;
          }
        }
      });
      opened = true;
      vm.returnOfModalInstance();
    }

    function returnOfModalInstance() {
      // Check result of modal view
      modalInstance.result.then(function () {
        opened = false;
        // reload-method
        vm.reloadList();
      }, function () {
        opened = false;
      });
    }

    function reloadList() {
      var parameters = {
        sort: ['label,asc'],
        offset: (vm.pagination.currentPage - 1) * vm.pagination.itemsPerPage,
        limit: vm.pagination.itemsPerPage
      };
      if (!$stateParams.tab || $stateParams.tab === 'journal_types') {
        if (vm.journalTypeSearchQuery !== null && vm.journalTypeSearchQuery.length !== 0) {
          parameters['filter[]'] = 'label,LIKE ' + vm.journalTypeSearchQuery;
        }
        JournalTypeFactory.getList(parameters).then(function (resultJournalTypes) {
          vm.pagination.totalItems = resultJournalTypes.count;
          vm.journalTypes = resultJournalTypes;
        });
      }
    }
  }
}());
