(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pos.controller:TakeOverSessionPromptCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('pos')
    .controller('TakeOverSessionPromptCtrl', TakeOverSessionPromptCtrl);

  function TakeOverSessionPromptCtrl(
    $modalInstance
  ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.returnAnswer = returnAnswer;

    function cancelModalInstance() {
      // Dismiss the modal view
      $modalInstance.dismiss('cancel');
    }

    function returnAnswer(answer) {
      $modalInstance.close(answer);
    }
  }
}());
