(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accountancy.controller:CollectionPeriodsAddCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('accountancy')
    .controller('CollectionPeriodsAddCtrl', CollectionPeriodsAddCtrl);

  function CollectionPeriodsAddCtrl(DebitCollectionPeriodFactory,
                                    ToastrNotificationService,
                                    CurrentUserContextFactory,
                                    $state,
                                    $cookies,
                                    $filter,
                                    $scope,
                                    $modal,
                                    $modalInstance) {
    var vm = this,
        currentLoggedInUserSiteId = CurrentUserContextFactory.getSiteId();

    /* ----- PARAMS ----- */
    vm.startDate = new Date();
    vm.endDate = new Date((new Date()).setMonth((new Date()).getMonth() + 1));
    vm.datePickerIsOpened = false;
    vm.endDatePickerIsOpened = false;
    vm.minDate = new Date();
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.openEndDatePicker = openEndDatePicker;
    vm.openDatePicker = openDatePicker;
    /* ----- END FUNCTIONS ----- */

    /* ----- DATE PICKER ----- */
    function openEndDatePicker() {
      vm.endDatePickerIsOpened = true;
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    /* ----- END DATE PICKER ----- */

    /* ----- OTHER ----- */
    vm.saveCollectionPeriod = function () {
      var periodObject = {
        endDate: vm.endDate.toISOString().substring(0, 10),
        startDate: vm.startDate.toISOString().substring(0, 10),
        site: currentLoggedInUserSiteId
      };

      return DebitCollectionPeriodFactory.post(periodObject)
        .then(function () {
          ToastrNotificationService.showTranslatedAndFormattedNotification(
            'success',
            'app.item-saved',
            'app.collection-period',
            'app.item-successfully-saved',
            'app.collection-period'
          );

          $modalInstance.close('reloadList');
        });
    };
    /* ----- END OTHER ----- */
  }
}());
