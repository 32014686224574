(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name hwproxy.controller:HwProxyCustomerListCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('hwproxy')
    .controller('HwProxyCustomerListCtrl', HwProxyCustomerListCtrl);

  function HwProxyCustomerListCtrl($rootScope,
                                   $modalInstance,
                                   CustomerFactory) {
    var vm = this;
    vm.customers = [];
    vm.customerSearchQuery = null;

    vm.reloadCustomers = reloadCustomers;
    vm.selectCustomer = selectCustomer;
    vm.cancel = cancelModalInstance;

    vm.reloadCustomers();

    function reloadCustomers() {
      var customerParams = {
        limit: 99,
        sort: 'label,ASC',
        'filter[]': []
      };

      if (vm.customerSearchQuery) {
        customerParams['filter[]'].push('label,LIKE ' + vm.customerSearchQuery);
      }

      customerParams['filter[]'].push('hidden,false');

      CustomerFactory.getList(customerParams).then(function (customers) {
        vm.customers = customers;
      });
    }

    function selectCustomer(customer) {
      $rootScope.$broadcast('customers.selectCustomer', customer);

      vm.cancel();
    }

    function cancelModalInstance() {
      // Dismiss the modal instance
      $modalInstance.dismiss('cancel');
    }
  }
}());
