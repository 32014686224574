(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mobile.controller:MobileContentCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('mobile')
    .controller('MobileCategoryAddEditCtrl', MobileCategoryAddEditCtrl);

  function MobileCategoryAddEditCtrl(
    $filter,
    LogService,
    $window,
    $state,
    $stateParams,
    OAuthToken,
    Restangular,
    RestUtilService,
    ScheduledReportFactory,
    BillingDivisionFactory,
    CurrentUserContextFactory,
    PermissionsUtilService,
    ReportTypeFactory,
    SettingsService,
    MobileFactory,
    $q,
    $modal,
    ToastrNotificationService,
    UtilService,
    MobileCategoryFactory,
    MobileContentFactory,
    MobileLanguageFactory,
    mobileCategory,
    $modalInstance
  ) {
    var vm = this;
    vm.mobileCategory = mobileCategory;
    vm.mobileCategoryLabel = '';
    vm.mobileCategoryComments = null;
    vm.mobileCategoryType = null;
    vm.mobileCategoryTypes = [
      {id: 1, label: 'type 1'},
      {id: 2, label: 'type 2'},
      {id: 3, label: 'type 3'}
    ];
    vm.isEdit = isEdit;
    vm.setMobileCategoryData = setMobileCategoryData;
    vm.getMobileCategoryRestObject = getMobileCategoryRestObject;
    vm.saveMobileCategory = saveMobileCategory;
    vm.showSavedNotification = showSavedNotification;

    if (vm.isEdit()) {
      vm.setMobileCategoryData();
    }

    function getMobileCategoryRestObject() {
      return {
        label: vm.mobileCategoryLabel,
        comments: vm.mobileCategoryComments,
        type: vm.mobileCategoryType ? vm.mobileCategoryType.id : null
      };
    }

    function setMobileCategoryData() {
      var categoryType;
      vm.mobileCategoryLabel = vm.mobileCategory.label;
      vm.mobileCategoryComments = vm.mobileCategory.comments;
      categoryType = vm.mobileCategoryTypes.filter(function (type) {
        return type.id === vm.mobileCategory.type;
      });
      vm.mobileCategoryType = angular.isDefined(categoryType[0]) ? categoryType[0] : null;
    }

    function isEdit() {
      return angular.isDefined(vm.mobileCategory) && vm.mobileCategory !== null;
    }

    function saveMobileCategory() {
      var mobileCategoryRestObject = vm.getMobileCategoryRestObject();

      if (vm.isEdit()) {
        MobileCategoryFactory.one(vm.mobileCategory.id).patch(mobileCategoryRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      } else {
        MobileCategoryFactory.post(mobileCategoryRestObject)
        .then(function () {
          vm.showSavedNotification();
          $modalInstance.close('');
        });
      }
    }

    function showSavedNotification() {
      ToastrNotificationService.showNotification(
        'success',
        $filter('uconlyfirst')(
          $filter('sprintf')(
            $filter('translate')('app.item-saved'),
            $filter('translate')('app.mobile_content')
          )
        ),
        $filter('uconlyfirst')(
          $filter('sprintf')(
            vm.isEdit() ?
              $filter('translate')('app.item-successfully-updated') :
              $filter('translate')('app.item-successfully-saved'),
            $filter('translate')('app.mobile_content')
          )
        )
      );
    }
  }
}());
