(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name journal.controller:CustomerJournalEditCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('journal')
    .controller('CustomerJournalEditCtrl', CustomerJournalEditCtrl);

  function CustomerJournalEditCtrl(
    $cookies,
    $filter,
    LogService,
    $modalInstance,
    journal,
    JournalActionFactory,
    JournalFactory,
    ToastrNotificationService,
    CurrentUserContextFactory,
    JournalManagementFactory,
    type
  ) {
    var vm = this;
    vm.cancel = cancelModalInstance;
    vm.saveJournal = saveJournal;
    vm.showSavedNotification = showSavedNotification;
    vm.openDatePicker = openDatePicker;
    vm.facility = {
      id: CurrentUserContextFactory.getSiteFacilityId()
    };
    switch (type) {
      case 'credits':
        vm.journalCreditsInput = angular.copy(journal.credits);
        vm.saveCredits = saveCredits;
        break;

      case 'expiration':
        vm.datePickerIsOpened = false;
        vm.journalExpirationInput = new Date(journal.expiration);
        vm.minDate = new Date();
        vm.saveExpiration = saveExpiration;
        break;

      // case 'suspension':
      //   vm.allowAppend = false;
      //   vm.suspensionEndDatePickerIsOpened = false;
      //   vm.suspensionStartDatePickerIsOpened = false;
      //   vm.journalSuspensionStartInput = new Date();
      //   vm.journalSuspensionEndInput = null;
      //   vm.maxDate = new Date(journal.expiration);
      //   vm.minDate = new Date();
      //   vm.resumptionMinDate = vm.journalSuspensionStartInput;
      //   vm.saveSuspension = saveSuspension;
      //   break;

      case 'resumption':
        vm.resumptionDatePickerIsOpened = false;
        vm.journalResumptionInput = new Date();
        vm.maxDate = new Date(journal.expiration);
        vm.minDate = new Date();
        vm.saveResumption = saveResumption;
        break;

      case 'termination':
        vm.terminationDatePickerIsOpened = false;
        vm.journalTerminationInput = new Date();
        vm.maxDate = new Date(journal.expiration);
        vm.minDate = new Date();
        vm.saveTermination = saveTermination;
        break;

      case 'startdate':
        vm.startDatePickerIsOpened = false;
        vm.startDateInput = new Date(journal.startDate);
        vm.maxStartDate = vm.startDateInput;
        vm.saveStartDate = saveStartDate;
        break;
      default:
        break;
    }

    function cancelModalInstance() {
      $modalInstance.dismiss('cancel');
    }

    function saveJournal() {
      switch (type) {
        case 'credits':
          vm.saveCredits();
          break;

        case 'expiration':
          vm.saveExpiration();
          break;

        // case 'suspension':
        //   vm.saveSuspension();
        //   break;

        case 'resumption':
          vm.saveResumption();
          break;

        case 'termination':
          vm.saveTermination();
          break;

        case 'startdate':
          vm.saveStartDate();
          break;

        default:
          break;
      }
    }

    function showSavedNotification(itemSaved) {
      // Show notification at successful save
      ToastrNotificationService.showTranslatedAndFormattedNotification(
        'success',
        'app.item-saved',
        itemSaved,
        'app.item-successfully-saved',
        itemSaved
      );
      $modalInstance.close('reloadJournals');
    }

    function openDatePicker(editType) {
      switch (editType) {
        case 'expiration':
          vm.datePickerIsOpened = true;
          break;

        case 'suspension-start':
          vm.suspensionStartDatePickerIsOpened = true;
          break;

        case 'suspension-end':
          vm.suspensionEndDatePickerIsOpened = true;
          break;

        case 'resumption':
          vm.resumptionDatePickerIsOpened = true;
          break;

        case 'termination':
          vm.terminationDatePickerIsOpened = true;
          break;

        case 'startdate':
          vm.startDatePickerIsOpened = true;
          break;

        default:
          break;
      }
    }

    function saveTermination() {
      // Termination of journal
      // Remove 1 day of date
      vm.journalTerminationInput.setDate(vm.journalTerminationInput.getDate() - 1);
      JournalManagementFactory.terminateJournal(journal.id, vm.journalTerminationInput)
      .then(function () {
        vm.showSavedNotification('journal.termination');
      });
    }

    function saveStartDate() {
      // Start date modification of journal
      JournalManagementFactory.modifyJournalStartDate(journal, vm.startDateInput)
      .then(function () {
        vm.showSavedNotification('app.start_date');
      });
    }

    function saveResumption() {
      // resumption of journal
      var journalItemForm,
          journalActionParams = {
            limit: 1
          };
      journalActionParams['filter[]'] = 'code,RESUMPTION';
      JournalActionFactory.getList(journalActionParams).then(function (journalActions) {
        journalItemForm = {
          journalAction: journalActions[0].id,
          date: vm.journalResumptionInput.toISOString()
        };
        LogService.log('journalItem: ' + journalItemForm, 'debug');
        JournalFactory.one(journal.id).post('items', journalItemForm)
        .then(function (journalItem) {
          LogService.log('journalItem resumption: ' + journalItem, 'debug');
          vm.showSavedNotification('journal.resumption');
        });
      });
    }

    function saveExpiration() {
      var journalItem = {
        expiration: vm.journalExpirationInput.toISOString()
      };
      JournalFactory.one(journal.id).patch(journalItem)
      .then(function (resultJournalItem) {
        LogService.log('journal: ' + resultJournalItem, 'debug');
        vm.showSavedNotification('journal.expiration');
      });
    }

    function saveCredits() {
      // Recharge or Consumption of journal
      var journalItemForm,
          journalActionParams = {
            limit: 1
          },
          creditDifference = journal.credits - vm.journalCreditsInput;

      if (vm.isCreditsCorrection) {
        // we need to switch the +/- sign here
        // and we allow to send negative credits with correction
        creditDifference *= -1;
        journalActionParams['filter[]'] = 'code,CREDITS_CORRECTION';
      } else if (creditDifference < 0) {
        journalActionParams['filter[]'] = 'code,RECHARGE';
        creditDifference *= -1;
      } else {
        journalActionParams['filter[]'] = 'code,CONSUMPTION';
      }

      LogService.log('creditDifference: ' + creditDifference, 'debug');
      JournalActionFactory.getList(journalActionParams).then(function (journalActions) {
        journalItemForm = {
          journalAction: journalActions[0].id,
          credits: creditDifference,
          facility: vm.facility.id
        };
        LogService.log('journalItem: ' + journalItemForm, 'debug');
        JournalFactory.one(journal.id).post('items', journalItemForm).then(function (journalItem) {
          vm.showSavedNotification('journal.credits');
          LogService.log('Member Card Modal: Added item to journal ' + journal.id + ' => journalItem: ' + journalItem, 'debug');
        });
      });
    }
  }
}());
