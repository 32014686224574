/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {
  'use strict';

      /**
       * @ngdoc object
       * @name accountancy.controller:StuckSaleSelectCtrl
       *
       * @description
       *
       */
      /* @ngInject */
  angular
          .module('accountancy')
          .controller('StuckSaleSelectCtrl', StuckSaleSelectCtrl);

  function StuckSaleSelectCtrl($filter,
                              $timeout,
                              CurrentUserContextFactory,
                              StuckSalesLoadSaleByReferenceFactory,
                              ToastrNotificationService,
                              FilterUtilService,
                              SaleFactory,
                              $modalInstance) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.saleReference = null;
    vm.sales = null;
    vm.selectPosRow = selectPosRow;
    vm.comment = '';
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.searchSaleOnReference = searchSaleOnReference;
    vm.selectPosRow();
    /* ----- END FUNCTIONS ----- */

    /* ----- DATE PICKER ----- */

    /* ----- END DATE PICKER ----- */

    /* ----- OTHER ----- */
    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    // Select pos session logic
    vm.selectedRow = null;
    vm.saleRef = null;
    function selectPosRow(index, saleRef, force) {
      if (!force && vm.selectedRow === index) {
        vm.selectedRow = null;
        vm.saleRef = null;
      } else {
        vm.selectedRow = index;
        vm.saleRef = saleRef;
      }
    }

    function searchSaleOnReference() {
      vm.filters = {
        reference: {
          val: vm.saleReference,
          field: 'reference',
          cond: 'LIKE'
        }
      };

      // eslint-disable-next-line vars-on-top
      var queryParams = {
        sort: 'createdAt,DESC',
        limit: '10'
      };

      queryParams = angular.merge(queryParams, FilterUtilService.createQueryParamsFromFilters(vm.filters));

      SaleFactory.getList(queryParams).then(function (data) {
        $timeout(function () {
          console.log(data);
          vm.sales = data;
          // auto select sale if there is only one found sale
          if (vm.sales.length === 1) {
            selectPosRow(0, vm.sales[0].reference, true);
          }
        });
      });
    }

    // Save stuck sale logic
    vm.saveCollectionPeriod = function () {
      // eslint-disable-next-line vars-on-top
      var queryParamsStuckSalesLoad;
      // No sale selected
      if (vm.selectedRow === null) {
        ToastrNotificationService.showTranslatedAndFormattedNotification(
              'error',
              'app.error',
              '',
              'accountancy.stuck-sale.selectPos'
            );
        return Promise.reject();
      }

      // see refs #47882 - 2)
      // if (vm.comment === '') {
      //   ToastrNotificationService.showTranslatedAndFormattedNotification(
      //     'error',
      //     'app.error',
      //     '',
      //     'accountancy.stuck-sale.remarks-error'
      //   );
      //   return Promise.reject();
      // }

      queryParamsStuckSalesLoad = vm.saleRef +
          '?context=' + CurrentUserContextFactory.getUserContextId() +
          '&comment=' + vm.comment;

      return StuckSalesLoadSaleByReferenceFactory.one().customGET(queryParamsStuckSalesLoad)
          .then(function (newSale) {
            ToastrNotificationService.showTranslatedAndFormattedNotification(
              'success',
              'app.item-loaded',
              'app.sale',
              'app.item-successfully-loaded',
              'app.stuck-sale.success-loaded'
              );

            $modalInstance.close(newSale);
          });
    };
    /* ----- END OTHER ----- */
  }
}());


