(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name swimcourse.controller:SwimcourseCtrl
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('swimcourse')
    .controller('SwimcourseCtrl', SwimcourseCtrl);

  function SwimcourseCtrl($scope) {
    var vm = this;

    vm.reloadCalendar = reloadCalendar;

    function reloadCalendar() {
      $scope.$broadcast('reloadSwimcourseCalendarOnTabSelection', null);
    }
  }
}());
